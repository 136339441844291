import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLanguage } from 'Context/LanguageContext';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import useValidatePermission from 'FielderUtils/Hooks/useValidatePermission';
import assetConst from 'FielderUtils/roleManagementConst/assetConst';
import formConst from 'FielderUtils/roleManagementConst/formConst';
import inventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import roleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser, selectAccountPreferences } from '../../../../core/store/modules/session/sessionSelectors';
import useValidateRoles from '../../../../core/utils/roles/useValidateRoles';
import SubMenu from '../SubMenu';

const ManagementAndControlSideMenu = () => {
	const user = useSelector(selectUser);
	const accountPreferences = useSelector(selectAccountPreferences);
	const lan = useLanguage();
	const { isRoleValid } = useValidateRoles();

	const [menuOptions, setMenuOptions] = useState([]);
	const [hasAnyModule, setHasAnyModule] = useState(false);
	const [hasForm, setHasForm] = useState(false);
	const [hasAsset, setHasAsset] = useState(false);
	const [hasInventory, setHasInventory] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const [formsAllowed, validFormsPermission] = useValidatePermission(user, Permission.FORMS);
	const [assetAllowed, validAssetPermission] = useValidatePermission(user, Permission.ASSET_MANAGEMENT);
	const [inventoryAllowed, validInventoryPermission] = useValidatePermission(user, Permission.INVENTORY);

	const title = lan.billingManagementControl;
	const navigate = useNavigate();

	useEffect(() => {
		setHasAnyModule(
			accountPreferences &&
				(accountPreferences.showForms || accountPreferences.showAsset || accountPreferences.showInventory)
		);
		setHasForm(accountPreferences && accountPreferences.showForms);
		setHasAsset(accountPreferences && accountPreferences.showAsset);
		setHasInventory(accountPreferences && accountPreferences.showInventory);
	}, [accountPreferences]);

	useEffect(() => {
		const isAssetPermitted = isRoleValid(assetConst.ASSETS, assetConst.VIEW);
		const isInventoryPermitted = isRoleValid(inventoryConst.INVENTORY, inventoryConst.VIEW);
		const isFormsPermitted = isRoleValid(formConst.FORMS, formConst.VIEW_TEMPLATES);

		setMenuOptions([
			{
				isValid: isAssetPermitted && assetAllowed && hasAsset,
				goToPage: goToPage(ENDPOINTS.assetManagement),
				title: lan.assetManagement,
				img: `${process.env.AWS_FIELDER_ICONS}assets-icon.svg`,
			},
			{
				isValid: isInventoryPermitted && inventoryAllowed && hasInventory,
				goToPage: goToPage(ENDPOINTS.inventory),
				title: lan.inventory,
				img: `${process.env.AWS_FIELDER_ICONS}inventory-icon.svg`,
			},
			{
				isValid: isFormsPermitted && formsAllowed && hasForm,
				goToPage: goToPage(ENDPOINTS.forms),
				title: lan.forms,
				img: `${process.env.AWS_FIELDER_ICONS}form-icon.svg`,
			},
		]);
	}, [user, formsAllowed, assetAllowed, inventoryAllowed]);

	useEffect(() => {
		validAssetPermission(user, Permission.ASSET_MANAGEMENT);
		validInventoryPermission(user, Permission.INVENTORY);
		validFormsPermission(user, Permission.FORMS);
	}, [user, validAssetPermission, validInventoryPermission, validFormsPermission]);

	const openMenu = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	const goToPage = (page) => () => {
		handleClose();
		navigate(page);
	};

	const isManager = roleUtils.isUserManager(user);

	return (
		<>
			{hasAnyModule && !isManager ? (
				<>
					<Tooltip title={title} placement='right'>
						<ListItemButton onClick={openMenu}>
							<ListItemIcon>
								<img className='icon' src={`${process.env.AWS_FIELDER_ICONS}control-icon.svg`} alt='analytics' />
								<ChevronRightIcon color='disabled' />
							</ListItemIcon>
							<ListItemText primary={title} />
						</ListItemButton>
					</Tooltip>
					<SubMenu anchor={anchor} onClose={handleClose}>
						{menuOptions}
					</SubMenu>
				</>
			) : null}
		</>
	);
};

export default ManagementAndControlSideMenu;
