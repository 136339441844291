import React from 'react';

import enabledAccount from '@assets/icons/account-enable-icon.svg';
import disabledAccount from '@assets/icons/block-user-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import { Image } from 'react-bootstrap';

class AccountStatusFormatter extends React.Component {
	render() {
		const type = this.props.value;
		const icon = type == 0 ? enabledAccount : disabledAccount;
		const title = type == 0 ? this.props.lan.activeAccount : this.props.lan.accountDeactivated;

		return (
			<div className='row no-padding'>
				<Tooltip title={title}>
					<Image src={icon} alt='' className='SmartTable-icon-image' />
				</Tooltip>
			</div>
		);
	}
}

export default AccountStatusFormatter;
