import React, { Component } from 'react';

import { TreeSelect } from 'antd';
import '../MyOperation.css';
import FielderConst from 'FielderUtils/fielderConst';
import { listToTree, getTaskTypeListFilteredBy, hasTaskTypeFunctionality } from 'FielderUtils/taskType/taskType';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Checkbox from '~/Checkbox/Checkbox.component';

import DatePickerFielder from '../../DatePickerFielder/DatePickerFielder';
import Popup from '../../Popup';
import { duplicateTasksRequest } from '@core/api/task/task.service';

class DuplicateTaskPopup extends Component {
	constructor(props) {
		super(props);
		var taskDetails = null;
		var taskIds = '',
			taskIdCustom = '';
		var branchClientName;
		var idBranch;
		var idClient;
		var duplicateSuccess = true;
		var duplicateFail = false;
		const session = this.props.session;
		var dateSelected = new Date();

		let taskTypeFunctionality = hasTaskTypeFunctionality(session.account.funcionalityPurchases);
		var idTasks = this.props.idTasks;

		for (var j = 0; j < this.props.tasks.length; j++) {
			var task = this.props.tasks[j];
			if (j == 0) {
				if (task.branch) {
					branchClientName = task.branch;
				} else {
					branchClientName = task.taskUser;
				}
			}
			if (j > 0 && j < 10) {
				taskIds += ', ';
				taskIdCustom += ', ';
			}
			if (j <= 10) {
				taskIds += task.taskId;
				taskIdCustom += task.customId;
			} else {
				taskIds += ' ...';
				taskIdCustom += ' ...';
				break;
			}
		}

		taskDetails = {
			taskIds: taskIds,
			taskIdCustom: taskIdCustom,
		};

		this.state = {
			dateSelected: dateSelected,
			minTime: new Date(),
			idTasks: idTasks,
			taskDetails: taskDetails,
			showErrorMessage: false,
			showErrorMessageText: '',
			showErrorMessageIcon: null,
			taskTypeFunctionality: taskTypeFunctionality,
			duplicateSuccess: duplicateSuccess,
			duplicateFail: duplicateFail,
			taskTypeSelected: 1,
			idBranch: idBranch,
			idClient: idClient,
			branchClientName: branchClientName,
			duplicateTaskWithUpdates: true,
			duplicateTaskAddAutomatedForms: true,
			taskTypes: [
				{
					id: 1,
					name: this.props.lan.pickup,
					children: [],
					title: this.props.lan.pickup,
					value: 1,
					key: 1,
				},
				{
					id: 2,
					name: this.props.lan.workforce,
					children: [],
					title: this.props.lan.workforce,
					value: 2,
					key: 2,
				},
				{
					id: 3,
					name: this.props.lan.delivery,
					children: [],
					title: this.props.lan.delivery,
					value: 3,
					key: 3,
				},
				{
					id: FielderConst.TASK_REMOTE_TYPE_ID,
					name: this.props.lan.remote,
					children: [],
					title: this.props.lan.remote,
					value: FielderConst.TASK_REMOTE_TYPE_ID,
					key: FielderConst.TASK_REMOTE_TYPE_ID,
				},
			],
		};
	}

	componentDidMount = () => {
		if (this.state.taskTypeFunctionality) {
			getTaskTypeListFilteredBy(
				(formatTypes) => {
					let types = listToTree(formatTypes);
					let state = this.state;
					state.taskTypes = types;
					this.setState(state);
				},
				this.props,
				this.state.idBranch,
				this.state.idClient
			);
		}
	};

	setTaskTypeValue = (e) => {
		let state = this.state;
		state.taskTypeSelected = e;
		this.setState(state);
	};

	duplicateTask = (e) => {
		e.preventDefault();

		var dateProgramDate = this.state.dateSelected;
		const body = {
			idTasks: this.state.idTasks,
			taskType: this.state.taskTypeSelected,
			year: dateProgramDate.getFullYear(),
			month: dateProgramDate.getMonth(),
			day: dateProgramDate.getDate(),
			hour: dateProgramDate.getHours(),
			minute: dateProgramDate.getMinutes(),
			includeUpdatesInDuplicatedTask: this.state.duplicateTaskWithUpdates,
			formsModified: !this.state.duplicateTaskAddAutomatedForms,
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		const onSuccess = () => this.props.onClose(this.state.duplicateSuccess, this.state.duplicateFail);
		const onError = () => {
			let state = this.state;
			state.duplicateSuccess = false;
			state.duplicateFail = true;
			this.setState(state);
		};

		duplicateTasksRequest(body, onSuccess, onError, loadingConfig);
	};

	handleProgramDate = (date) => {
		var minTime = new Date();
		if (this.state.dateSelected !== date) {
			if (
				date.getDate() !== new Date().getDate() ||
				date.getMonth() !== new Date().getMonth() ||
				date.getFullYear() !== new Date().getFullYear()
			) {
				minTime = new Date().setHours(0, 0);
			}
			this.setState({
				dateSelected: date,
				minTime: minTime,
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<Popup
					title={this.props.lan.duplicateTask + ' (' + this.props.tasks.length + ') '}
					icon='detalle-task-icon.svg'
					show
					closeButton
					onClose={this.props.onClose}
					buttonText={this.props.lan.duplicateTask}
					requiredFields={this.props.lan.requiredFields}
					buttonOnClick={this.duplicateTask}
					size={'auto'}
				>
					<Row>
						<Col sm={12}>
							{/* Task Type */}
							<div className='mt-1'>
								<Row>
									<Col className='small-popup-label'>{this.props.lan.idTask}</Col>
								</Row>
								<Row>
									<Col className='popup-label my-2 ml-2'>{this.state.taskDetails.taskIds}</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<div className=''>
								<Row>
									<Col className='small-popup-label'>{this.props.lan.idCustom}</Col>
								</Row>
								<Row>
									<Col className='popup-label my-2 ml-2'>{this.state.taskDetails.taskIdCustom}</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							{this.state.idBranch ? (
								<div className=''>
									<Row>
										<Col className='small-popup-label'>{this.props.lan.branch}</Col>
									</Row>
									<Row>
										<Col className='popup-label my-2 ml-2'>{this.state.branchClientName}</Col>
									</Row>
								</div>
							) : (
								<div className=''>
									<Row>
										<Col className='small-popup-label'>{this.props.lan.client}</Col>
									</Row>
									<Row>
										<Col className='popup-label my-2 ml-2'>{this.state.branchClientName}</Col>
									</Row>
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col sm={12} className='ml-3 mb-3 title-padding text-title'>
							<span> {this.props.lan.typeOfTask} </span>
						</Col>
						<Col className='col-10 ml-3 mb-3 create-task-dropdown-size' key='col-task-taskType'>
							<TreeSelect
								className='full-width'
								treeData={this.state.taskTypes}
								dropdownStyle={{ maxHeight: '400px', overflow: 'auto' }}
								onChange={(e) => this.setTaskTypeValue(e)}
								placeholder={this.props.lan.taskType}
								defaultValue={this.state.taskTypeSelected}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<span className='title-padding text-title'>{this.props.lan.startWay}</span>

							<DatePickerFielder
								id='executionDate-red-input'
								selected={this.state.dateSelected}
								handleDate={this.handleProgramDate}
								minDate={new Date()}
								timeFormat='HH:mm'
								minTime={this.state.minTime}
								maxTime={new Date().setHours(23, 59)}
								dateFormat='dd/MM/yyyy h:mm aa' //Formato de fecha
								timeIntervals={30} //Intervalo de tiempo en minutos
								classNamePicker='MyOperation-eschedule form-control mt-1 text-left'
								imgId='executionDate-alert-icon'
								classNameImg='CreateTask-dir-desc-fieldContainer-icon'
							/>
						</Col>
					</Row>
					<Row className='ml-1 mt-2'>
						<Col sm>
							<div className=''>
								<Row>
									<Checkbox
										label={this.props.lan.duplicateTaskWithUpdates}
										checked={this.state.duplicateTaskWithUpdates}
										onChange={(e) => {
											this.setState({
												duplicateTaskWithUpdates: !this.state.duplicateTaskWithUpdates,
											});
										}}
									/>
								</Row>
							</div>
						</Col>
						<Col sm>
							<div className=''>
								<Row>
									<Checkbox
										label={this.props.lan.duplicateTaskAddAutomatedForms}
										checked={this.state.duplicateTaskAddAutomatedForms}
										onChange={(e) => {
											this.setState({
												duplicateTaskAddAutomatedForms: !this.state.duplicateTaskAddAutomatedForms,
											});
										}}
									/>
								</Row>
							</div>
						</Col>
					</Row>
				</Popup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(DuplicateTaskPopup);
