module.exports = {
	//Create task with execution time
	getAllIsochronous: function (idTeam) {
		return {
			type: 'GET_ALL_ISOCHRONOUS',
		};
	},
	getTasksToResourceAllocation: function (data) {
		data.type = 'SEND_INFO_TO_RESOURCE_ALLOCATION_SNS';
		return data;
	},
	importTasks: function () {
		return {
			type: 'DO_ACTION_IMPORT_TASKS',
		};
	},
	updateTasks: function () {
		return {
			type: 'DO_ACTION_UPDATE_TASK_BY_CSV',
		};
	},
	updateForms: function () {
		return {
			type: 'DO_ACTION_UPDATE_FORM_BY_CSV',
		};
	}
};
