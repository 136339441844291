import { REQUEST_TYPES } from '@core/constants/route/route.request';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// PUT
export const reassignAgentToRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.REASSIGN_AGENT,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
}

export const resetCancelledTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.RESET_CANCELED_TASKS,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
}

/** GET ROUTE LIST * */
export const getRouteListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

/** GET ROUTE BY ID * */
export const getRouteByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** REORDER ROUTE * */
export const doActionReorderRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** CREATE ROUTE * */
export const doActionCreateRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

/** RESCHEDULE ROUTE * */
export const doActionRescheduleRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_RESCHEDULE_ROUTE,
		idUser: user.idUser,
		idAccount: user.idAccount,
		idRoute: body.data.idRoute,
		toYear: body.data.toYear,
		toMonth: body.data.toMonth,
		toDay: body.data.toDay,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** CANCEL ROUTE * */
export const doActionCancelRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.CANCEL_ROUTES,
		idUser: user.idUser,
		idAccount: user.idAccount,
		idRoutes: body.data.idRoutes,
		idAgents: body.data.idAgents,
		comment: body.data.comment,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** RESET ROUTE TASK * */
export const doActionResetRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.RESET_ROUTES_TASKS,
		idUser: user.idUser,
		idAccount: user.idAccount,
		idRoutes: body.idRoutes,
		idAgents: body.idAgents,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** RESTART ROUTE * */
export const doActionRestartRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.RESTART_ROUTE,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** GET TASK ID * */
export const getTaskByIdRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** TRASH ROUTE * */
export const doActionTrashRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** GET ROUTE PREFERENCES * */
export const getRoutePreferencesRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_ROUTE_PREFERENCES,
		idUser: user.idUser,
		idAccount: user.idAccount
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getRouteManagerRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_VIEW_ROUTE_EXPENSES_PREFERENCE,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateRouteManagerRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

/** GET ROUTE EXPENSES * */
export const getRouteExpensesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	
	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getRouteTaskToShowRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	
	const requestBody = {
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getRouteTaskFolioRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	
	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASK_BY_ID_AND_FOLIO,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.routeManager, requestBody, onSuccess, onError, loadingConfig);
};