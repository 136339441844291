import Agent0Tasks from '@assets/icons/map_icons/pin-agent-0-tasks-2.png';
import Agent1Task from '@assets/icons/map_icons/pin-agent-1-tasks-2.png';
import Agent2Tasks from '@assets/icons/map_icons/pin-agent-2-tasks-2.png';
import Agent3Tasks from '@assets/icons/map_icons/pin-agent-3-tasks-2.png';
import Agent4Tasks from '@assets/icons/map_icons/pin-agent-4-tasks-2.png';
import Agent5Tasks from '@assets/icons/map_icons/pin-agent-5-tasks-2.png';
import AgentPin from '@assets/icons/map_icons/pin-agent-active.gif';
import AgentAwayPin from '@assets/icons/map_icons/pin-agent-away-filled.png';
import AgentDefaultPin from '@assets/icons/map_icons/pin-agent-default.png';
import AgentInactivePin from '@assets/icons/map_icons/pin-agent-inactive-filled.png';
import InactiveAgentPin from '@assets/icons/map_icons/pin-agent-inactive.png';
import AgentMultipleTasks from '@assets/icons/map_icons/pin-agent-multiple-tasks-2.png';
import AgentNoDataPin from '@assets/icons/map_icons/pin-agent-noData-filled.png';
import AgentOfflinePin from '@assets/icons/map_icons/pin-agent-offline-filled.png';
import AgentOnlinePin from '@assets/icons/map_icons/pin-agent-online-filled.png';
import EmergencyPin from '@assets/icons/map_icons/pin-emergency.svg';
import GreenPin from '@assets/icons/map_icons/pin-green.png';
import IsochronousPin from '@assets/icons/map_icons/pin-isochronous.png';
import PinkPin from '@assets/icons/map_icons/pin-pink.png';
import HighPriorityPin from '@assets/icons/map_icons/pin-priority-high.svg';
import LowPriorityPin from '@assets/icons/map_icons/pin-priority-low.svg';
import MediumPriorityPin from '@assets/icons/map_icons/pin-priority-medium.svg';
import TaskArrivedPin from '@assets/icons/map_icons/pin-task-arrived.svg';
import TaskAssignedPartnerPin from '@assets/icons/map_icons/pin-task-assigned-partner.svg';
import TaskAssignedTeamPin from '@assets/icons/map_icons/pin-task-assigned-team.svg';
import TaskAutoAssignFailedPin from '@assets/icons/map_icons/pin-task-auto-assign-failed.svg';
import TaskAutoAssignSuccessPin from '@assets/icons/map_icons/pin-task-auto-assign-success.svg';
import TaskAutoAssigning from '@assets/icons/map_icons/pin-task-auto-assigning.svg';
import TaskCancelledByPartnerPin from '@assets/icons/map_icons/pin-task-cancelled-by-partner.svg';
import TaskCancelledPin from '@assets/icons/map_icons/pin-task-cancelled.svg';
import TaskDeletedPin from '@assets/icons/map_icons/pin-task-deleted.svg';
import TaskEndRoutePin from '@assets/icons/map_icons/pin-task-end-route.png';
import TaskExecutingPin from '@assets/icons/map_icons/pin-task-executing.svg';
import TaskFinishedPin from '@assets/icons/map_icons/pin-task-finished.svg';
import TaskInProgressPin from '@assets/icons/map_icons/pin-task-in-progress.svg';
import TaskManagingPin from '@assets/icons/map_icons/pin-task-managing.svg';
import TaskPausedPin from '@assets/icons/map_icons/pin-task-paused.svg';
import TaskStartRoutePin from '@assets/icons/map_icons/pin-task-start-route.svg';
import TaskWaitingPin from '@assets/icons/map_icons/pin-task-waiting-agent.svg';
import WarningPin from '@assets/icons/map_icons/pin-warning.gif';

import TaskStatus from '../../constants/task/TaskStatus.constants';

// TODO: Change Key Names
const MapPins = {
	agent: AgentPin,
	inactiveAgent: InactiveAgentPin,
	agentOnline: AgentOnlinePin,
	agentOffline: AgentOfflinePin,
	agentAway: AgentAwayPin,
	agentInactive: AgentInactivePin,
	agentNoData: AgentNoDataPin,
	agent0Tasks: Agent0Tasks,
	agent1Tasks: Agent1Task,
	agent2Tasks: Agent2Tasks,
	agent3Tasks: Agent3Tasks,
	agent4Tasks: Agent4Tasks,
	agent5Tasks: Agent5Tasks,
	agentMultipleTasks: AgentMultipleTasks,
	AgentDefault: AgentDefaultPin,
	iso: IsochronousPin,
	yellow: EmergencyPin,
	TaskManaging: TaskManagingPin,
	TaskWaitingAgent: TaskWaitingPin,
	TaskInProgress: TaskInProgressPin,
	TaskExecuting: TaskExecutingPin,
	TaskFinished: TaskFinishedPin,
	TaskCancelled: TaskCancelledPin,
	TaskStartRoute: TaskStartRoutePin,
	TaskCancelledByPartner: TaskCancelledByPartnerPin,
	TaskAssignedToPartner: TaskAssignedPartnerPin,
	TaskAssignedToTeam: TaskAssignedTeamPin,
	TaskArrived: TaskArrivedPin,
	TaskDeleted: TaskDeletedPin,
	TaskAutoAssigning,
	TaskAutoAssignSuccess: TaskAutoAssignSuccessPin,
	TaskAutoAssignFailed: TaskAutoAssignFailedPin,
	TaskPaused: TaskPausedPin,
	high: HighPriorityPin,
	low: LowPriorityPin,
	medium: MediumPriorityPin,
	warning: WarningPin,
	'end-route': TaskEndRoutePin,
	green: GreenPin,
	pink: PinkPin,
};

const TaskStatusCodes = {
	[TaskStatus.MANAGING]: 'TaskManaging',
	[TaskStatus.WAITING_FOR_AGENT]: 'TaskWaitingAgent',
	[TaskStatus.IN_PROGRESS]: 'TaskInProgress',
	[TaskStatus.EXECUTING]: 'TaskExecuting',
	[TaskStatus.FINISH]: 'TaskFinished',
	[TaskStatus.CANCEL]: 'TaskCancelled',
	[TaskStatus.START_ROUTE]: 'TaskStartRoute',
	[TaskStatus.CANCEL_BY_PARTNER]: 'TaskCancelledByPartner',
	[TaskStatus.ASSIGNED_TO_PARTNER]: 'TaskAssignedToPartner',
	[TaskStatus.ASSIGNED_TO_TEAM]: 'TaskAssignedToTeam',
	[TaskStatus.ARRIVED]: 'TaskArrived',
	[TaskStatus.DELETED]: 'TaskDeleted',
	[TaskStatus.AUTO_ASSIGNING]: 'TaskAutoAssigning',
	[TaskStatus.AUTO_ASSIGNED_SUCCESS]: 'TaskAutoAssignSuccess',
	[TaskStatus.AUTO_ASSIGNED_FAILED]: 'TaskAutoAssignFailed',
	[TaskStatus.PAUSED]: 'TaskPaused',
};

export const getPinIcon = (pin) => {
	if (pin) {
		if (pin in MapPins) {
			return MapPins[pin];
		}

		if (pin.startsWith('data:image')) {
			return pin;
		}

		return pin
	}

	return MapPins.pink;
};

export const getTaskPinCode = (status, pin) => {
	if (pin) {
		return pin;
	}

	if (status !== null && status in TaskStatusCodes) {
		return TaskStatusCodes[status];
	}

	return null;
};
