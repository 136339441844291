import { useState } from 'react';

import { selectSession } from '@core/store/modules/session/sessionSelectors';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import { useSelector } from 'react-redux';

import RoleUtils from '../roles/roleUtils';

const isAllowed = (user, permissionInfo) => {
	let allowSuperAdminViewer = false;
	let allowAdmin = false;
	let allowManager = false;
	let allowClient = false;
	let allowSuperAdmin = false;

	if ('superAdminAndViewer' in permissionInfo && permissionInfo.superAdminAndViewer === true) {
		allowSuperAdminViewer = RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user);
	}

	if ('admin' in permissionInfo && permissionInfo.admin === true) {
		allowAdmin = RoleUtils.isUserAdministrator(user);
	}

	if ('manager' in permissionInfo && permissionInfo.manager === true) {
		allowManager = RoleUtils.isUserManager(user);
	}

	if ('client' in permissionInfo && permissionInfo.client === true) {
		allowClient = RoleUtils.isUserClient(user);
	}

	if ('superAdmin' in permissionInfo && permissionInfo.superAdmin === true) {
		allowSuperAdmin = RoleUtils.isUserSuperAdministrator(user);
	}

	return allowSuperAdminViewer || allowAdmin || allowManager || allowClient || allowSuperAdmin;
};

export default function useValidatePermission() {
	const session = useSelector(selectSession);
	const { accountPreferences } = session;
	const [isPermissionAllowed, setPermissionAllowed] = useState(false);

	const validatePermission = (user, functionality) => {
		let permissionInfo;
		let allowed = false;

		switch (functionality) {
			case Permission.ANALYTICS:
				permissionInfo = {
					admin: true,
					manager: true && accountPreferences.allowManagerAccessAnalytics,
					superAdminAndViewer: true,
				};
				break;
			case Permission.ISOCHRONOUS:
				permissionInfo = {
					admin: true,
					manager: true && accountPreferences.allowManagerAccessIso,
					superAdminAndViewer: true,
				};
				break;
			case Permission.CREATE_TASK:
				permissionInfo = {
					manager: true && accountPreferences.allowManagerCreate,
					superAdminAndViewer: true,
					admin: true,
					client: true,
				};
				break;
			case Permission.ROUTE:
				permissionInfo = {
					manager: true && accountPreferences.allowManagerAccessRoute,
					superAdminAndViewer: true,
					admin: true,
				};
				break;
			case Permission.ROUTE_EXPENSES:
			case Permission.DYNAMIC_ALLOCATION:
			case Permission.SKILLS:
				permissionInfo = {
					superAdmin: true,
					admin: true,
					manager: true,
				};
				break;
			case Permission.RESOURCE:
				permissionInfo = {
					manager: true && accountPreferences.allowManagerAccessResource,
					superAdminAndViewer: true,
					admin: true,
				};
				break;
			case Permission.BILLING:
				permissionInfo = {
					superAdminAndViewer: true,
				};
				break;
			case Permission.ASSET_MANAGEMENT:
			case Permission.INVENTORY:
			case Permission.AUTO_ASSIGN:
			case Permission.TASK_TYPES:
			case Permission.FORMS:
				permissionInfo = {
					superAdmin: true,
					admin: true,
				};
				break;
			default:
				permissionInfo = null;
		}

		if (permissionInfo !== null) {
			allowed = isAllowed(user, permissionInfo);
		}

		setPermissionAllowed(allowed);
	};

	return [isPermissionAllowed, validatePermission];
}
