// ROLE MANAGEMENT
const PAGE_NAME = 'ROUTE_OPTIMIZATION_PREFERENCES';
const NAME = 'routeoptimization';
const ROUTE_OPTIMIZATION = 'routeoptimization';
const ACCOUNT_PREFERENCES = 'showRouteOpt';
const GENERATE = 0;

export default {
	PAGE_NAME,
	NAME,
	ROUTE_OPTIMIZATION,
	ACCOUNT_PREFERENCES,
	GENERATE,
};
