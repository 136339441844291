import React from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import Graph from './Graph';

class PieGraph extends Graph {
	constructor(data, props) {
		super(props);
		this.data = data;
	}

	createGraph(params) {
		let chart = am4core.create(params.id ? params.id : 'pie-graph', am4charts.PieChart);

		let category = params.category;
		let value = params.series[0].value;
		let color = params.color;
		let labels = params.labels;
		let ticks = params.ticks;
		let tooltip = params.tooltip;
		let legendPosition = params.legendPosition;

		chart.data = this.data;

		super.setPieSeriesParams(value, category, chart, color, labels, ticks, tooltip, legendPosition);

		var legend = params.legend;
		super.setChartProperties(chart, legend, false, false);

		this.chart = chart;
	}

	getGraph() {
		return this.chart;
	}
}

export default PieGraph;
