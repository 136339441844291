import React, { useState, useEffect } from 'react';

import { TreeSelect } from 'antd';
import TaskTypes from 'Constants/TaskTypes';
import { useLanguage } from 'Context/LanguageContext';

import '../CreateTask.css';

const SelectTaskType = ({ selectedType, taskTypeOptions, updateTaskType, dontUseMargin }) => {
	const lan = useLanguage();

	const INITIAL_TASK_TYPES = [
		{
			id: TaskTypes.PICKUP,
			name: lan.pickup,
			children: [],
			title: lan.pickup,
			value: TaskTypes.PICKUP,
			key: TaskTypes.PICKUP,
		},
		{
			id: TaskTypes.WORKFORCE,
			name: lan.workforce,
			children: [],
			title: lan.workforce,
			value: TaskTypes.WORKFORCE,
			key: TaskTypes.WORKFORCE,
		},
		{
			id: TaskTypes.DELIVERY,
			name: lan.delivery,
			children: [],
			title: lan.delivery,
			value: TaskTypes.DELIVERY,
			key: TaskTypes.DELIVERY,
		},
		{
			id: TaskTypes.REMOTE,
			name: lan.remote,
			children: [],
			title: lan.remote,
			value: TaskTypes.REMOTE,
			key: TaskTypes.REMOTE,
		},
	];

	const [taskTypes, setTaskTypes] = useState(INITIAL_TASK_TYPES);
	const [taskTypeList, setTaskTypeList] = useState(INITIAL_TASK_TYPES);

	useEffect(() => {
		handleChangeTaskType(1);
	}, []);

	useEffect(() => {
		if (taskTypeOptions) {
			for (let i = 0; i < taskTypeOptions.taskTypes.length; i++) {
				if (taskTypeOptions.taskTypes[i].id === TaskTypes.REMOTE) {
					taskTypeOptions.taskTypes[i].title = lan.remote;
				}
			}
			setTaskTypes([...taskTypeOptions.taskTypes]);
			setTaskTypeList(taskTypeOptions.taskTypeList);
		}
	}, [taskTypeOptions]);

	const handleChangeTaskType = (selectedType) => {
		const selected = taskTypeList.find((type) => type.id === selectedType);
		updateTaskType(selected);
	};

	return (
		<div className={dontUseMargin ? '' : 'mx-3 my-3' }>
			<>
				<span className='title-padding text-title'>{lan.taskType}</span>
				<TreeSelect
					id='task-type-tree-select'
					className='full-width border-radius-10'
					treeData={taskTypes}
					popupClassName='taskType-treeSelect'
					onChange={handleChangeTaskType}
					placeholder={lan.taskType}
					value={selectedType && taskTypes.length > 0 ? selectedType.id : null}
					size='large'
				/>
			</>
		</div>
	);
};

export default SelectTaskType;
