import React from 'react';

import { Paper, Typography } from '@mui/material';
import UnderlinedButton from 'FielderComponents/UnderlinedButton';
import { styled } from '@mui/material/styles'

const LoginSecondaryText = styled('span')`
	width: 470px;
	height: 15px;
	opacity: 0.5;
	font-family: 'Lato';
	font-size: 12px;
	color: #354052;
`;

const TermsAndServiceButton = styled(UnderlinedButton)`
	width: 470px;
	height: 15px;
	opacity: 0.5;
	font-family: 'Lato';
	font-size: 12px;
	color: #354052;
	text-decoration-line: underline;
`;

const LoginPaper = styled((props) => <Paper elevation={2} {...props} />)(({ theme }) => ({
	width: '100%',
	padding: theme.spacing(2),
	textAlign: 'center',
	paddingTop: '15%',
	paddingBottom: '15%',
}));


const Icon = styled('img')`
	width: 16px;
	height: 16px;
	object-fit: contain;
`;

const FielderLogo = styled('img')`
	width: 35%;
`;

const LoginLabel = styled(Typography)`
	font-family: 'Lato';
	font-size: 25px;
	color: #354052;
`

export { FielderLogo, TermsAndServiceButton, LoginSecondaryText, LoginPaper, Icon, LoginLabel };
