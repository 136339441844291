import API_LINKS from 'FielderAPILinks';

/**
 *
 * @param {string} filter       - Filter to search the teams by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the teams found (returns empty if error)
 */
export const getAccountsByFilter = (filter, role, userName, idToken) => {
	let body = {
		requestType: 'GET_ACOUNTS_BY_FILTER',
		filter: filter,
		user: {
			role: role,
			userName: userName,
		},
	};

	return fetch(API_LINKS.accountManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.accounts;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the team by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all teams by the filter send and sets the information and format for the asyncSelect component
 */
export const getAccountsByFilterAsyncSelect = async (filter, role, userName, idToken) => {
	let accounts = await getAccountsByFilter(filter, role, userName, idToken);
	let accountsList = [];

	accounts.forEach((account) => {
		accountsList.push({
			label:
				account.id +
				' ' +
				account.contactName +
				' (' +
				account.contactEmail +
				') ' +
				account.user.name +
				' ' +
				account.user.name,
			value: account,
		});
	});

	return accountsList;
};
