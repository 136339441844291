import React, {Component} from 'react';
import { Row, Col} from 'react-bootstrap';

// Custom components
import Popup from '../../Popup';
import '../MyOperation.css';
import { connect } from 'react-redux';
import redAlertIcon from "@assets/icons/redAlert-icon.svg";
import alertIcon from '@assets/icons/danger-icon.svg'
import SuccessIcon from '@assets/icons/success-alert-icon.svg';

/***** SERVERLESS IMPORT  *****/
import { doActionResetRouteRequest } from '@core/api/route/route.service';

class ResetRouteTaskPopup extends Component {

    constructor (props) {
        super(props);

        let idUserAssigned=[];
        let resetSuccess=true;
        let resetFail = false;

        let idRoutes = [];
        let idsSelected = [];

        for (var r = 0; r < this.props.routes.length; r++){
            idRoutes.push(this.props.routes[r].id)
        }

        if(props.routes!==undefined && props.routes!==[]) {
            for(var i=0;i < props.routes.length;i++) {
                for (var j=0;j < props.myIdRoutes.length;j++) {
                    if(props.routes[i].id == props.myIdRoutes[j]){
                        idsSelected.push(props.idAgents[j]);
                        break
                    }
                }

            }

        }

        this.state = {
            idAgents: idsSelected,
            idRoutes:idRoutes,
            idUserAssigned:idUserAssigned,
            showErrorMessage:false,
            showErrorMessageText:"",
            showErrorMessageIcon:null,
            resetSuccess:resetSuccess,
            resetFail:resetFail,
        }
    }

    showMessage = () => {
        this.props.onClose();
    }

    showPopupErrorMessage(){
        if(this.state.rescheduleSuccess){
            let state = this.state;
            state.showErrorMessageText = this.props.lan.savedCorrectly;
            state.showErrorMessage = true;
            state.showErrorMessageIcon = SuccessIcon;
            this.setState(state);
        }else{
            let state = this.state;
            state.showErrorMessageText = this.props.lan.errorGralFail;
            state.showErrorMessage = true;
            state.showErrorMessageIcon = alertIcon;
            this.setState(state);
        }
    }

    manageResetRoutesTasks = (res) => {
        let state = this.state;

        let request =res;

        if(request.success == true){
            let state = this.state;
            state.resetSuccess = true;
            state.resetFail = false;
            this.setState(state);
        }

        this.props.onClose(this.state.resetSuccess, this.state.resetFail);
    }

    resetRoutesTasks = e => {
        let body = {
            idRoutes: this.state.idRoutes,
            idAgents: this.state.idAgents
        }

        const onSuccess = (response) => {
			this.manageResetRoutesTasks(response)
		}

		const onError = (response) => {
			let state = this.state;
            state.resetSuccess = false;
            state.resetFail = true;
            this.setState(state);
            this.props.onClose(this.state.resetSuccess, this.state.resetFail);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		doActionResetRouteRequest(body, onSuccess, onError, loadingConfig);

    }

    render() {
        return (
            <Popup
                closeButton
                onClose={this.props.onClose}
                requiredFields={this.props.lan.requiredFields}
                buttonText={this.props.lan.accept}
                buttonOnClick={this.resetRoutesTasks}
                size={'ps2'}
                >
                    <Row>
                        <Col sm={12}>
                            <div className='mt-1'>
                                <Row className='d-flex justify-content-center mb-4'>
                                    <img src={alertIcon} className="danger" ></img>
                                </Row>

                            </div>
                            <div className='mt-1 '>
                                <Row >
                                    <Col className='confirmation-text'>
                                        {this.props.lan.resetRouteTasksConfirmation}
                                        <br/><br/>
                                        {this.props.lan.resetRouteTasksMessage}
                                    </Col>
                                </Row>
                            </div>

                            <div className='mt-3'>
                                <Row >
                                    <img src={redAlertIcon} alt="" id="comment-alert-icon" className="field-alert-icon"></img>
                                </Row>

                            </div>

                        </Col>
                    </Row>

                </Popup>
            );
    }
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(ResetRouteTaskPopup);