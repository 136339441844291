export enum REQUEST_TYPES {
	GET_INVENTORY_CONFIG = 'GET_INVENTORY_CONFIG',
	DO_UPDATE_INVENTORY_CONFIG = 'DO_UPDATE_INVENTORY_CONFIG',
	GET_VIEW_INVENTORY_CATEGORIES = 'GET_VIEW_INVENTORY_CATEGORIES',
	GET_VIEW_INVENTORIES= 'GET_VIEW_INVENTORIES',
	GET_VIEW_INVENTORIES_TABLE='GET_VIEW_INVENTORIES_TABLE',
	DO_CREATE_INVENTORY = 'DO_CREATE_INVENTORY',
	DO_UPDATE_INVENTORY = 'DO_UPDATE_INVENTORY',
	DO_REMOVE_INVENTORY_IMAGE = 'DO_REMOVE_INVENTORY_IMAGE',
	DO_REMOVE_INVENTORY_IMAGE_FROM_TASK = 'DO_REMOVE_INVENTORY_IMAGE_FROM_TASK',
	CHANGE_INVENTORY_STATUS = 'CHANGE_INVENTORY_STATUS',
	DO_DELETE_INVENTORY = 'DO_DELETE_INVENTORY',
	DO_DELETE_INVENTORY_CATEGORY = 'DO_DELETE_INVENTORY_CATEGORY',
	GET_SPECIFIC_INVENTORY = 'GET_SPECIFIC_INVENTORY',
	DO_IMPORT_CATEGORY_INVENTORY = 'DO_IMPORT_CATEGORY_INVENTORY',
	GET_INVENTORIES_TO_EXPORT = 'GET_INVENTORIES_TO_EXPORT',
	DO_CREATE_INVENTORY_CATEGORIES = 'DO_CREATE_INVENTORY_CATEGORIES',
	DO_UPDATE_INVENTORY_CATEGORY = 'DO_UPDATE_INVENTORY_CATEGORY',
	GET_ALL_INVENTORIES = 'GET_ALL_INVENTORIES',
	GET_INVENTORIES_FROM_TASKS = 'GET_INVENTORIES_FROM_TASKS',
	DO_ACTION_GENERATE_INVENTORY_REPORT = 'DO_ACTION_GENERATE_INVENTORY_REPORT',
	DO_UPLOAD_INVENTORY_CATEGORY_TEMPLATE = 'DO_UPLOAD_INVENTORY_CATEGORY_TEMPLATE',
	DO_ACTION_GET_INVENTORY_HTML = 'DO_ACTION_GET_INVENTORY_HTML',
	DO_UPDATE_TASK_INVENTORY = 'DO_UPDATE_TASK_INVENTORY',
	DO_ACTION_GET_INVENTORY_XLSX_OR_HTML_FROM_FILE = 'DO_ACTION_GET_INVENTORY_XLSX_OR_HTML_FROM_FILE',
	GET_INVENTORY_CATEGORY_BY_ID = 'GET_INVENTORY_CATEGORY_BY_ID'
}