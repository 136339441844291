import React, { useState } from 'react';

import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Col } from 'react-bootstrap';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { IconButton, Fade } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import  ListIcon  from '@assets/icons/list-icon.svg';
import EmailIcon from '@assets/icons/message-icon.svg';
import Operation from '@assets/icons/operation-icon.svg';
import DisabledIcon from '@assets/icons/disabled-icon.svg';
import FormIcon from '@assets/icons/form-icon.svg';

const SELECTION_TASK = 1;

const TaskMenu = (props) => {

	const lan = useLanguage();
	const user = useSelector(selectUser);
	const [menuAnchor, setMenuAnchor] = useState(false);

	return (
		<>
			{/* SHOW DISABLE TASK AND EMAIL TASK MENU */}
			{props.options == SELECTION_TASK &&
			(RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
			RoleUtils.isUserAdministrator(user) ||
			(RoleUtils.isUserManager(user))) && (
				<Col sm={'auto'} className='ml-auto mt-1'>
					<Badge color='primary' variant='dot' invisible={props.showMailCount}>
						<IconButton
							className='my-auto iconSubMenuSize'
							onClick={(e) => setMenuAnchor(e.currentTarget)}
						>
							<img
								src={ListIcon}
								id='MyOperation-menu-icon'
								className='icon Fielder-icon-button'
								alt=''
							/>
						</IconButton>

						<Menu
							anchorEl={menuAnchor}
							keepMounted
							open={Boolean(menuAnchor)}
							onClose={() => setMenuAnchor(false) }
							TransitionComponent={Fade}
							PaperProps={{
								style: { maxHeight: '300px' },
							}}
						>
							<MenuItem onClick={() => {
								setMenuAnchor(false)
								props.changeTasksNormal(false)
							}
							}>
								<ListItemIcon>
									<img
										src={Operation}
										id='MyOperation-tasks-icon'
										className='icon Fielder-icon-button'
										alt=''
									/>
								</ListItemIcon>
								<ListItemText primary={lan.tasks} />
							</MenuItem>

							{(RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
								RoleUtils.isUserAdministrator(user) ||
								(RoleUtils.isUserManager(user) &&
									props.allowManagerToSeeTaskByMail)) &&
									props.hasMailIntegrationFunctionality && (
								<MenuItem onClick={() => {
									setMenuAnchor(false)
									props.changeTasksToByEmail(true)

								}
								}>
										<ListItemIcon>
											<Badge color='primary' variant='dot' invisible={props.showMailCount}>
												<img
													src={EmailIcon}
													id='MyOperation-email-task-icon'
													className='icon Fielder-icon-button'
													alt=''
												/>
											</Badge>
										</ListItemIcon>
										<ListItemText primary={lan.emailTask} />
									</MenuItem>
								)}

							{RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) && (
								<MenuItem onClick={() => {
									setMenuAnchor(false)
									props.changeTasksShowing(true)

									}
								}>
									<ListItemIcon>
										<img
											src={DisabledIcon}
											id='MyOperation-disabled-task-icon'
											className='icon Fielder-icon-button'
											alt=''
										/>
									</ListItemIcon>
									<ListItemText primary={lan.disabledTask} />
								</MenuItem>
							)}
							
							<MenuItem onClick={() => {
								setMenuAnchor(false)
								props.changeSearchByForms(true)

								}
							}>
								<ListItemIcon>
									<img
										src={FormIcon}
										id='MyOperation-search-by-form-tasks-icon'
										className='icon Fielder-icon-button'
										alt=''
									/>
								</ListItemIcon>
								<ListItemText primary={lan.searchFormsByTasks} />
							</MenuItem>
						
						</Menu>
					</Badge>
				</Col>
			)}

		</>
	)
}
export default TaskMenu;