import React from "react";
import OfflineIcon from "@assets/icons/offline-agent-icon.svg";
import {Button} from "react-bootstrap";

class AgentFormatter extends React.Component {

  render() {
    if(this.props.value!==null && this.props.value!==undefined && this.props.value.localeCompare("")!==0){
        var agentList=this.props.value.split(";");
        if(agentList[1] && agentList[1].localeCompare("1")==0)
            return (<div align="center" className="text-center" ><img src={OfflineIcon}/>{agentList[0]}</div>);
        else
            return (<div align="center" className="text-center" >{agentList[0]}</div>);
    }else{
        return (<div align="center" className="text-center" >{this.props.value}</div>);
    }
  }
}

export default AgentFormatter;
