import React, { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import Status from 'FielderUtils/autoassign/processTaskStatus';
import { Col } from 'react-bootstrap';

//Utility
import 'FielderUtils/style.css';
import './style.css';

const AutoAssignTaskStatus = ({ value }) => {
	const lan = useLanguage();
	const [text, setText] = useState('');
	const [color, setColor] = useState('');

	useEffect(() => {
		switch (value) {
			case Status.EXECUTING:
				setColor('Fielder-task-autoassigning');
				setText(lan.executingAutoassign);
				break;
			case Status.FAILED_NO_AGENTS_AVAILABLE:
			case Status.FAILED_NO_SKILLS:
			case Status.FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS:
				setColor('Fielder-task-autoassigning-failed');
				setText(lan.unsuccessful);
				break;
			case Status.RETRYING:
				setColor('Fielder-task-arrived');
				setText(lan.retrying);
				break;
			case Status.SUCCESSFULL:
				setColor('Fielder-task-finish');
				setText(lan.successful);
				break;
			case Status.WAITING:
				setColor('Fielder-task-managing');
				setText(lan.waiting);
				break;
			default:
				return;
		}
	}, []);

	return (
		<Col className={'mx-auto status-square text-center status-' + color}>
			<div>
				<span className='status-text'>{text}</span>
			</div>
		</Col>
	);
};

export default AutoAssignTaskStatus;
