const ExportTask = {
	ID_FIELDER: 0,
	FOLIO: 1,
	CREATE_DATE: 2,
	STARTED_DATE: 3,
	ON_WAY_DATE: 4,
	EXECUTION_DATE: 5,
	END_AGENT: 6,
	AGENT: 7,
	BRANCH: 8,
	COMPANY: 9,
	CLIENT: 10,
	STATUS: 11,
	SEVERITY: 12,
	TASK_TYPE: 13,
	DESCRIPTION: 14,
	ADRESS: 15,
	COMMENTS_AGENT: 16,
	COMMENTS_USER: 17,
	SCORE: 18,
	RESCHEDULE_DATE: 19,
	RESCHEDULE_COMMENT: 20,
	CANCEL_REASON: 21,
	CANCEL_BY: 22,
	CANCEL_COMMENT: 23,
	TIME_ZONE: 24,
	KMS_DISTANCE: 25,
	EXECUTION_MODE: 26,
	ID_CUSTOM_GROUP_TASK: 27,
	GROUP_TASK: 28,
	TAGS: 29,
	AGENT_START_LOCATION: 30,
	AGEMT_END_LOCATION: 31,
	ID_ROUTE: 32,
	ROUTE_KMS: 33,
	ROUTE_DURATION: 34,
	STARTED_HOUR: 35,
	END_HOUR: 36,
	EXECUTION_HOUR: 37,
	TEAM: 38,
	MANAGER: 39,
	ARRIVAL_DATE: 40,
	ARRIVAL_HOUR: 41,
	CREATE_HOUR: 42,
	ON_WAY_HOUR: 43,
	RESCHEDULE_HOUR: 44,
	COMMENTS_USER_DATE: 45,
	RESCHEDULE_COMMENT_DATE: 46,
	PAUSE_COUNT: 47,
	RESUME_COUNT: 48,
	PAUSE_DEPENDENCY: 49,
	PAUSE_REASON: 50,
	PAUSE_COMMENT: 51,
	REJECT_DATE: 52,
	REJECT_TIME: 53,
	REJECT_DEPENDENCY: 54,
	REJECT_REASON: 55,
	REJECT_COMMENT: 56,
	PARTNER: 57,
	TASK_STATUS_MODIFIED_DATE: 58,
	TASK_STATUS_MODIFIED_HOUR: 59,
	TASK_STATUS_MODIFIED_COMMENT: 60,
	TASK_STATUS_MODIFIED_BY_ADMINISTRATOR: 61,
	TASK_STATUS_MODIFIED_BY_MANAGER: 62,
};

export default ExportTask;
