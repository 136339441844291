import * as React from 'react';

import AddAgentsIcon from '@assets/icons/add-agent-icon.svg';
import AssetsIcon from '@assets/icons/assets-icon.svg';
import AssingGroupTask from '@assets/icons/assing-group-task-icon.svg';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import CancelTaskIcon from '@assets/icons/cancel-task-icon.svg';
import FormIcon from '@assets/icons/form-icon.svg';
import InventoryIcon from '@assets/icons/inventory-icon.svg';
import PartnersIcon from '@assets/icons/partners-icon.svg';
import AutoAssignTaskIcon from '@assets/icons/autoassign-icon.svg';
import Reload from '@assets/icons/reload.svg';
import TeamsIcon from '@assets/icons/teams-icon.svg';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import assetConst from 'FielderUtils/roleManagementConst/assetConst';
import formConst from 'FielderUtils/roleManagementConst/formConst';
import groupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import inventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { hasGroupTasKFunctionality } from 'FielderUtils/groupTask/groupTaskColumn';
import { useSelector } from 'react-redux';
import { getAccountPreferences } from '../../core/services/customHooks/accountPreferencesUtils';
import { selectSession, selectUser } from '../../core/store/modules/session/sessionSelectors';
import useValidateRoles from '../../core/utils/roles/useValidateRoles';
import POPUPNAME from 'FielderUtils/popupName/popupName';
import Status from 'FielderUtils/status/taskStatus';
import { autoAssignTaskStatus } from 'FielderUtils/task/modifyStatus';
import { useDispatch } from 'react-redux';
import { reAutoAssignedRequest } from '@core/api/task/task.service';
const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));
export default function TaskDetailActions(props) {
  const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const session = useSelector(selectSession);
	const groupTaskFunctionality = hasGroupTasKFunctionality(session.account.funcionalityPurchases)
	const accountPreferences = getAccountPreferences(session);
	const user = useSelector(selectUser);
	const { isRoleValid } = useValidateRoles();
	const canAssignForms = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_FORMS);
	const canViewForms = isRoleValid(formConst.FORMS, formConst.VIEW_TEMPLATES);
	const canAssignInventory = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_INVENTORY);
	const canViewInventory = isRoleValid(inventoryConst.INVENTORY, inventoryConst.VIEW);
	const canAssignAssets = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_ASSETS);
	const canViewAssets = isRoleValid(assetConst.ASSETS, assetConst.VIEW);
	const canAssignPartners = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_PARTNERS);
	const canAddTasksToGroup = isRoleValid(
		groupTaskConst.GROUPTASK,
		groupTaskConst.ADD_TASKS_TO_GROUP
	);
	const canAssignTeams = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_TEAMS);
	const canAssignAgent = isRoleValid(taskConst.TASKS, taskConst.ASSIGN_AGENT);
	const canCancelTasks = isRoleValid(taskConst.TASKS, taskConst.CANCEL);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleAction = (popupName) => {
		props.changeOpacity();
		props.executePopupAction(popupName);
		setAnchorEl(null);
	};
  const validateAutoAssignPermissions = () => {
    if (props.taskData.hasGroup || props.taskData.hasRoute) {
      return { isValid: false, message: props.lan.msgAutoAssignNotAllowHasGroupRoute };
    }
    const invalidStatuses = [Status.CANCEL, Status.FINISH, Status.WAITING_FOR_AGENT, Status.IN_PROGRESS, 
      Status.EXECUTING, Status.START_ROUTE, Status.CANCEL_BY_PARTNER, Status.ARRIVED, Status.DELETED,
      Status.AUTOASSIGNED, Status.PAUSED, Status.REVIEW];
    if (invalidStatuses.includes(props.taskData.status)) {
      return { isValid: false, message: props.lan.msgAutoAssignNotAllowTaskStatus };
    }
    if (RoleUtils.isUserClient(session.user)) {
      return { isValid: false, message: props.lan.msgAutoAssignNotAllowClientUsers };
    }
    if (RoleUtils.isUserSuperAndAdministrator(session.user)) {
      return { isValid: true };
    }
    if (session.accountPreferences?.allowManagerToGenerateAutoAssignTasks && RoleUtils.isUserManager(session.user)) {
      return { isValid: true };
    }
    return { isValid: false, message: props.lan.msgAutoAssignNotAllowedPermissions };
  };
  const handleReAutoAssignedRequest = () => {
    const body = {
      tasks: [props.taskData.idFielder],
    };
    const loadingConfig = { dispatch }
    const onSuccess = () => {
      props.onCloseModal();
    };
    const onError = ({ status }) => {
      const message = autoAssignTaskStatus(status, lan);
      props.fielderMsg({
        message: message,
        variant: 'error',
        closeButton: true,
        duration: 5000
      });
    }
    reAutoAssignedRequest(body, onSuccess, onError, loadingConfig);
  }
  const clickAutoAssignTask = () => {
    const hasPermissions = validateAutoAssignPermissions();
    if (hasPermissions.isValid) {
      props.fielderMsg({
        message: props.lan.msgAutoAssignInitiateProcess,
        variant: 'success',
        closeButton: true,
        duration: 5000
      });
      handleReAutoAssignedRequest();
    } else {
      props.fielderMsg({
        message: hasPermissions.message,
        variant: 'error',
        closeButton: true,
        duration: 5000
      });
    }
    handleClose();
	};
	return (
		<div>
			<Button
				id='action-menu'
				aria-controls='action-customized-menu'
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				className='button-color-action'
				variant='contained'
				disableElevation
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
				style={{ backgroundColor: '#db2059 !important' }}
			>
				{props.lan.actions}
			</Button>
			<StyledMenu
				id='action-customized-menu'
				MenuListProps={{
					'aria-labelledby': 'action-menu',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{!RoleUtils.isUserClient(user) && canAssignAgent && (
					<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_AGENT)} disableRipple>
						<img
							id='MyOperation-assign-agent-icon'
							src={AddAgentsIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.agents}
					</MenuItem>
				)}
				{!RoleUtils.isUserClient(user) && canAssignTeams && (
					<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_TEAM)} disableRipple>
						<img
							id='MyOperation-assign-team-icon'
							src={TeamsIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.teams}
					</MenuItem>
				)}
				{!RoleUtils.isUserClient(user) && canAddTasksToGroup && groupTaskFunctionality && (
					<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_GROUP_TASK)} disableRipple>
						<img
							id='MyOperation-assign-group-task-icon'
							src={AssingGroupTask}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.titleIconAssignGroupAction}
					</MenuItem>
				)}
				{props.showDisabledTasks === false && props.showTaskByEmail === false && (
					<MenuItem onClick={() => handleAction(POPUPNAME.RESCHEDULE_TASK)} disableRipple>
						<img
							id='MyOperation-calendar-icon'
							src={CalendarIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.titleIconCalendar}
					</MenuItem>
				)}
				{canCancelTasks && (
					<MenuItem onClick={() => handleAction(POPUPNAME.CANCEL_TASK)} disableRipple>
						<img
							id='MyOperation-cancelTask-icon'
							src={CancelTaskIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.cancel}
					</MenuItem>
				)}
				{!RoleUtils.isUserClient(user) && props.showTaskByEmail === false && (
					<MenuItem onClick={() => handleAction(POPUPNAME.DUPLICATE_TASK)} disableRipple>
						<ControlPointDuplicateIcon
							style={{ cursor: 'pointer' }}
							fontSize='medium'
							color='disabled'
						/>
						{props.lan.duplicateTask}
					</MenuItem>
				)}
				<Divider sx={{ my: 0.5 }} />
				{!RoleUtils.isUserClient(user) && canAssignPartners && (
					<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_PARTNER)} disableRipple>
						<img
							id='MyOperation-assign-partner-icon'
							src={PartnersIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.partners}
					</MenuItem>
				)}
				{RoleUtils.isUserSuperAndAdministrator(user) && canAssignPartners && (
					<MenuItem onClick={() => handleAction(POPUPNAME.RE_ASSIGN_PARTNER)} disableRipple>
						<img
							id='MyOperation-assign-partner-icon'
							src={Reload}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.reassign + ' ' + props.lan.partner}
					</MenuItem>
				)}
				{(accountPreferences.showAsset ||
					accountPreferences.showInventory ||
					accountPreferences.showForms) && (
					<div>
						<Divider sx={{ my: 0.5 }} />
						{!RoleUtils.isUserClient(user) &&
							accountPreferences.showAsset &&
							canAssignAssets &&
							canViewAssets && (
								<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_ASSETS)} disableRipple>
									<img
										id='MyOperation-assign-asset-icon'
										src={AssetsIcon}
										className='icon Fielder-icon-button'
										alt=''
									/>
									{props.lan.assets}
								</MenuItem>
							)}
						{!RoleUtils.isUserClient(user) &&
							accountPreferences.showInventory &&
							canAssignInventory &&
							canViewInventory && (
								<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_INVENTORIES)} disableRipple>
									<img
										id='MyOperation-assign-inventory-icon'
										src={InventoryIcon}
										className='icon Fielder-icon-button'
										alt=''
									/>
									{props.lan.inventories}
								</MenuItem>
							)}

						{!RoleUtils.isUserClient(user) &&
							accountPreferences.showForms &&
							canAssignForms &&
							canViewForms && (
								<MenuItem onClick={() => handleAction(POPUPNAME.ASSIGN_FORMS)} disableRipple>
									<img
										id='MyOperation-assign-forms-icon'
										src={FormIcon}
										className='icon Fielder-icon-button'
										alt=''
									/>
									{props.lan.form}
								</MenuItem>
							)}
					</div>
				)}
        <Divider sx={{ my: 0.5 }} />
				{RoleUtils.isUserSuperAndAdministrator(user) || RoleUtils.isUserManager(user) && canAssignAgent && (
					<MenuItem onClick={() => clickAutoAssignTask()} disableRipple>
						<img
							id='MyOperation-assign-partner-icon'
							src={AutoAssignTaskIcon}
							className='icon Fielder-icon-button'
							alt=''
						/>
						{props.lan.autoassign}
					</MenuItem>
				)}
			</StyledMenu>
		</div>
	);
}
