import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

var THEMEONE = "THEMEONE";
var THEMETWO = "THEMETWO";

function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color("#242C39"), //9013fe
			am4core.color("#8A989C"),
			am4core.color("#6D8F97"),
			am4core.color("#B6C3D0"), //ce2b66
            am4core.color("#4A5865"),
		];
	}
}

//am4core.useTheme(am4themes_myTheme);


function am4themes_millenial(target) {

  if (target instanceof am4core.ColorSet) {

      target.list = [
          am4core.color("#9013fe"),
          am4core.color("#51a5de"),
          am4core.color("#f52394"),
          am4core.color("#ce2b66"),
          am4core.color("#dc5c87")

      ];

  }

}



class StackedColumnAgent extends Graph{
    constructor(data,props){
        super(props);
        this.props = props;
        this.data = data;
    }

    createGraph(params){

        let theme = params.theme
        am4core.unuseAllThemes();

        if(theme == THEMEONE ){
            am4core.useTheme(am4themes_empresarial);
          }
        else if(theme == THEMETWO ){
            am4core.useTheme(am4themes_millenial);
        }

        let chart = am4core.create(params.id, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;
        chart.colors.step = 2;
        chart.padding(30, 30, 10, 30);


        let category = params.category;

        let value1= params.value1;
        let value2= params.value2;
        let value3= params.value3;
        let value4= params.value4;
        let value5= params.value5;
        let value6= params.value6;
        let value7= params.value7;
        let value8= params.value8;
        let value9= params.value9;
        let value10= params.value10;
        let value11= params.value11;
        let value12= params.value12;
        let value13= params.value13;

        let percent1= params.percent1;
        let percent2= params.percent2;
        let percent3= params.percent3;
        let percent4= params.percent4;
        let percent5= params.percent5;
        let percent6= params.percent6;
        let percent7= params.percent7;
        let percent8= params.percent8;
        let percent9= params.percent9;
        let percent10= params.percent10;
        let percent11= params.percent11;
        let percent12= params.percent12;
        let percent13= params.percent13;

        let serie1 = params.serie1
        let serie2 = params.serie2
        let serie3 = params.serie3
        let serie4 = params.serie4
        let serie5 = params.serie5
        let serie6 = params.serie6
        let serie7 = params.serie7
        let serie8 = params.serie8
        let serie9 = params.serie9
        let serie10 = params.serie10
        let serie11 = params.serie11
        let serie12 = params.serie12
        let serie13 = params.serie13

        let textTypeOfTask = params.textTypeOfTask;
        let dateFromDateTo = params.dateFromDateTo;
        let textTotalTask = params.textTotalTask;
        let total= params.total

        chart.data = this.data;


		// Create axes
		super.setAxesStackedColumnAgent(chart, category,textTypeOfTask,dateFromDateTo,textTotalTask,total);

        //Series

		super.setStackedColumnSeriesParamsAgent(chart,category,value1,value2,value3,value4,value5,value6,value7,value8,value9,value10,value11,value12,value13,serie1,serie2,serie3,serie4,serie5,serie6,serie7,serie8,serie9,serie10,serie11,serie12,serie13,percent1,percent2,percent3,percent4,percent5,percent6,percent7,percent8,percent9,percent10,percent11,percent12,percent13);


		var legend = params.legend;
		super.setChartProperties(chart, legend, true,false);

        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default StackedColumnAgent