import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

const BISideMenu = ({ goToPage }) => {
    const lan = useLanguage();
    const title = lan.businessIntelligence;

    return (
        <>
            <Tooltip title={title} placement='right'>
                <ListItemButton
                    onClick={() => window.open(process.env.FIELDER_BI_LINK)}
                >
                    <ListItemIcon>
                        <img
                            className=''
                            src={`${process.env.AWS_FIELDER_ICONS}bi-icon.svg`}
                            alt='bi'
                            style={{ width: '20px' }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                </ListItemButton>
            </Tooltip>
        </>
    );
};
export default BISideMenu;