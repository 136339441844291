import { DeepSpaceGrey, LightGreySubtitleColor, GhostWhite, Magnolia } from "@core/theme/baseTheme";
import { TableCell, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

export const HeaderCell = styled(TableCell)`
	font-family: 'Lato';
	color: ${DeepSpaceGrey}
	font-size: 1rem;
	line-height: 1rem;
`

export const NoItemsInTableTitle = styled(Typography)`
	font-family: 'Lato';
	color: ${LightGreySubtitleColor};
	font-size: 1.4rem;
`

export const TreeTableCell = styled(TableCell)`
	padding-bottom: 0.25rem;
	padding-top: 0.25rem;
	border-bottom: 1px solid #F2F2F7;
`;

export const RowBackground = ['white', GhostWhite, Magnolia];