import TaskTypes from 'Constants/TaskTypes';
import ErrorCodes from 'FielderUtils/ErrorCodes';
import Status from 'FielderUtils/status/taskStatus';

export const addFromStatusArray = (lan) => [
	{
		name: lan.taskStatus_4,
		value: Status.IN_PROGRESS, // acepto la tarea
		key: 'inProgress',
		checked: false,
	},
	{
		name: lan.taskStatus_6,
		value: Status.START_ROUTE,
		key: 'startRoute',
		checked: false,
	},
	{
		name: lan.taskStatus_10,
		value: Status.ARRIVED,
		key: 'arrived',
		checked: false,
	},
	{
		name: lan.taskStatus_5,
		value: Status.EXECUTING,
		key: 'executing',
		checked: false,
	},
	{
		name: lan.taskStatus_15,
		value: Status.PAUSED,
		key: 'paused',
		checked: false,
	},
	{
		name: lan.taskStatus_2,
		value: Status.FINISH,
		key: 'finish',
		checked: false,
	},
];

export const getModifyStatus = (lan, statusTask, taskTypeId, remoteTaskIds) => {
	const allStatus = addFromStatusArray(lan);
	const modifyStatus = [];
	let arrayIndexes = [];

	if (remoteTaskIds.includes(taskTypeId) && statusTask !== Status.EXECUTING && statusTask !== Status.PAUSED) {
		arrayIndexes = [3, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.MANAGING) {
		arrayIndexes = [3, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.ASSIGNED_TO_PARTNER) {
		arrayIndexes = [4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.WAITING_FOR_AGENT) {
		arrayIndexes = [0, 1, 2, 3, 4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.ASSIGNED_TO_TEAM) {
		arrayIndexes = [0, 1, 2, 3, 4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.IN_PROGRESS) {
		arrayIndexes = [1, 2, 3, 4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.START_ROUTE) {
		arrayIndexes = [2, 3, 4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.ARRIVED) {
		arrayIndexes = [3, 4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.EXECUTING) {
		arrayIndexes = [4, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	} else if (statusTask === Status.PAUSED) {
		arrayIndexes = [3, 5];

		for (let i = 0; i < arrayIndexes.length; i++) {
			modifyStatus.push(allStatus[arrayIndexes[i]]);
		}
	}

	return modifyStatus;
};

export const getTaskRemoteType = (taskTypes) => {

	let arrayOfRemotes = [TaskTypes.REMOTE];
	if (taskTypes) {
		const remoteTasks = taskTypes.find((remote) => remote.id === TaskTypes.REMOTE);
		
		if (remoteTasks && remoteTasks.children) {
			getRemoteChildren(remoteTasks.children, arrayOfRemotes)
		}
	}

	return arrayOfRemotes;
};


const getRemoteChildren = (remoteTasks, arrayOfRemotes) => {

	remoteTasks.map((remoteTask) => {
		arrayOfRemotes.push(remoteTask.id)

		if (remoteTask.hasOwnProperty('children') && remoteTask.children.length > 0) {
			getRemoteChildren(remoteTask.children, arrayOfRemotes)
		} 
	 })
}

export const validateModifyStatus = (errorCode, lan) => {
	if (errorCode == ErrorCodes.TASK_NOT_FOUND) {
		return lan.theTaskWasNotFound;
	}
	if (errorCode == ErrorCodes.TASK_STATUS_NOT_ALLOWED) {
		return lan.taskStatusNotAllowed;
	}
	if (errorCode == ErrorCodes.TASK_WAS_CANCELED) {
		return lan.canceledTask;
	}
	if (errorCode == ErrorCodes.TASK_INACTIVE) {
		return lan.taskIsInactive;
	}
	return lan.tryAgainUpdateStatus;
};

export const autoAssignTaskStatus = (errorCode, lan) => {
	if (errorCode == ErrorCodes.NO_TASKS_WERE_AUTO_ASSIGNED) {
		return lan.msgNoTasksWereAutoAssigned;
	}
	return lan.tryAgainAutoAssignTasks;
};
