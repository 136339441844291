import React from 'react';

import TreeTable from '~/TreeTable/TreeTable.component';
import { Grid } from '@mui/material';
import CompanyCell from './components/CompanyCell';
import TaskTypeCell from './components/TaskTypeCell';
import PercentageCell from './components/PercentageCell';
import Card from '~/Card/Card.component';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import BranchCell from './components/BranchCell';
import TotalIncomeCell from './components/TotalIncomeCell';
import ToggleIcon from './components/ToggleIcon';
import ProgressCell from './components/ProgressCell';
import TaskTypePriceCell from './components/TaskTypePriceCell';

const CellComponents = {
	toggle: ToggleIcon,
	company: CompanyCell,
	branch: BranchCell,
	taskType: TaskTypeCell,
	pricePerTask: TaskTypePriceCell,
	taskQuantity: ({ row }) => row.taskCount.toLocaleString('en'),
	totalIncome: TotalIncomeCell,
	progress: ProgressCell,
	percentageOfTotal: PercentageCell,
};

const TaskToCash = ({ tasksToCash }) => {
	const lan = useLanguage();
	const TaskToCashTableColumns = [
		{ id: 'toggle', label: '', width: '5%' },
		{ id: 'company', label: lan.company, width: '20%' },
		{ id: 'branch', label: lan.branch, width: '22%' },
		{ id: 'taskType', label: lan.taskType, width: '13%' },
		{ id: 'taskQuantity', label: lan.taskQuantity, width: '3%' },
		{ id: 'pricePerTask', label: lan.pricePerTask, width: '6%' },
		{ id: 'totalIncome', label: lan.amountToCash, width: '10%' },
		{ id: 'progress', label: lan.progressOfTheProject, width: '15%' },
		{ id: 'percentageOfTotal', label: lan.contributionPercentage, width: '20%' },
	];

	return (
		<Grid item xs={12} sx={{ overflowX: 'scroll' }}>
			<Card sx={{ overflowX: 'scroll' }}>
				<TreeTable data={tasksToCash} columns={TaskToCashTableColumns} CellComponent={CellComponents} width='1000px' />
			</Card>
		</Grid>
	);
};

export default TaskToCash;
