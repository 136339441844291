/* eslint-disable no-param-reassign */
import { defaultCenter } from '@core/utils/map/googleMaps.config';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	config: {
		center: defaultCenter,
		zoom: 4,
	},
	markers: [],
	route: null,
	isochronous: [],
};

const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setCenter: (state, action) => {
			state.config.center = action.payload;
		},
		setRestartCenter: (state) => {
			state.config.center = {
				lat: 28.6659874,
				lng: -41.0840058,
			};
		},
		setZoom: (state, action) => {
			state.config.zoom = action.payload;
		},
		setMapConfig: (state, action) => {
			state.config = action.payload;
		},
		setRoute: (state, action) => {
			state.route = action.payload;
		},
		addRoute: (state, action) => {
			state.route = action.payload;
		},
		setMapClean: (state) => {
			state.editableMarker = null;
			state.isochronous = [];
			state.markers = [];
			state.routes = null;
		},
	},
});

export const { setCenter, setRestartCenter, setZoom, setMapConfig, setRoute, setMapClean } = mapSlice.actions;

export default mapSlice.reducer;
