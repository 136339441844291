import { PersianGreen, Verdigris, White, CedarChest } from '@core/theme/baseTheme';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const SelectAvatar = styled(Avatar)`
	font-size: 9pt;
	margin-right: 8px;
	background: ${CedarChest};
	color: white !important;
`;

export const SelectChip = styled(Chip)`
	background-color: ${Verdigris}1c;
	color: ${PersianGreen};
	margin: 2px;
`;

export const ChipAnimationVariants = {
	exit: { opacity: 0, height: 0 },
	initial: { opacity: 0 },
	animate: { opacity: 1 },
};

export const ChipAnimationTransition = { duration: 0.2 };
