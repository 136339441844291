import React, { useState } from 'react';
import { FielderTextInputField, FielderSelectField, FielderTextInputFieldLogin,
  StyleFielderRootContainerXXL, StyleFielderDefaultContainerXXL,
  StyleFielderDefaultContainerXL } 
  from './FielderElements.styles';
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";
import { MenuItem, IconButton, InputAdornment, FormControl } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber  } from 'react-phone-number-input';

export function FielderTextComponent({
  label, type, required = false, id, value, setValue = () => {}, error = '', 
  setError = () => {}, lan, disabled = false, rules = [], ...props }) {

  const validateInput = (value) => {
    if (!value && required) {
      return `${label} ${lan.isRequired}`;
    }
    for (const rule of rules) {
      const error = rule(value, lan);
      if (error) {
        return error;
      }
    }
    return '';
  };

  return (
    <FielderTextInputField
      fullWidth
      label={label}
      type={type}
      variant="outlined"
      required={required}
      id={id}
      value={value}
      error={!!error}
      helperText={error}
      disabled={disabled}
      onChange={(e) => {
        const val = e.target.value;
        setValue?.(val);
        const errorMessage = validateInput(val);
        setError?.(errorMessage);
      }}
      {...props}
    />
  );
}

export function FielderSelectComponent({
  labelId = '', id, label = '', value, setValue = () => {}, 
  options = [], disabled = false,  ...props }) {
  return (
    <FielderSelectField
      labelId={labelId}
      id={id}
      label={label}
      value={value}
      disabled={disabled}
      onChange={(event) => setValue(event.target.value)}
      {...props}
    >
      {options.map((option) => (
        <MenuItem disabled={option.disabled} key={option.value} value={option.value}>{option.label}</MenuItem>
      ))}
    </FielderSelectField>
  );
}

export function FielderFormField({
  name, label, id, control, rules, defaultValue = '', error, type = 'text', ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <FielderTextInputField
          {...field}
          {...props}
          label={label}
          id={id}
          error={!!error}
          helperText={error?.message}
          fullWidth
          variant="outlined"
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          InputProps={type === 'password' ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          } : {}}
        />
      )}
    />
  );
}


export function FielderFormFieldLogin({
  name, label, id, control, rules, defaultValue = '', error, type = 'text', ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <FielderTextInputFieldLogin
          {...field}
          {...props}
          label={label}
          id={id}
          error={!!error}
          helperText={error?.message}
          fullWidth
          variant="outlined"
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          InputProps={type === 'password' ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          } : {}}
        />
      )}
    />
  );
}

export function FielderPhoneField({ id, label, onChange, lan, isRequired, 
  disabled, externalError, ...props 
}) {
  const [countryIsoName, setCountryIsoName] = useState(undefined);
  const [countryCallingCode, setCountryCallingCode] = useState(lan.phoneNumberInput);
  const [error, setError] = useState(false);

  const manageCountry = (value) => {
    if (value) {
      setCountryCallingCode(getCountryCallingCode(value));
    } else {
      setCountryCallingCode(lan.phoneNumberInput);
    }
    setCountryIsoName(value);
  };

  const handlePhoneNumberChange = (value) => {
    if (isRequired) {
        if (!value) {
            setError(label + ' ' + lan.isRequired);
        } else if (!isValidPhoneNumber(value)) {
            setError(lan.messageErrorPhone);
        } else {
            setError(null);
        }
    } else if (value && !isValidPhoneNumber(value)) {
        setError(lan.messageErrorPhone);
    } else {
        setError(null);
    }
    onChange(value);
  }
  const displayError = externalError !== ''? externalError : error;
  return (
    <FormControl fullWidth>
      <PhoneInput
        id={id}
        label={label}
        variant="outlined"
        fullWidth
        required = {isRequired? true : false }
        disabled={disabled}
        onChange={handlePhoneNumberChange}
        onCountryChange={manageCountry}
        inputComponent={PhoneField}
        international
        {...props}
        error={Boolean(displayError)}
        helperText={displayError}
      />
    </FormControl>

  );
}

const PhoneField = React.forwardRef((props, ref) => {
  const { id, label, onChange, isRequired, disabled, ...rest } = props;
  return (
    <FielderTextInputField
        id={id}
        label={label}
        variant="outlined"
        fullWidth
        required = {isRequired? true : false }
        disabled={disabled}
        onChange={e => {
            e.persist();
            onChange(e.target.value);
        }}
        inputRef={ref}
        {...rest}
    />
  );
});

export function FielderRootContainerXXL({ children, ...props 
}) {
  return (
    <StyleFielderRootContainerXXL {...props }>
        {children}
    </StyleFielderRootContainerXXL>
  );
}

export function FielderDefaultContainerXXL({ children, ...props 
}) {
  return (
    <StyleFielderDefaultContainerXXL {...props }>
        {children}
    </StyleFielderDefaultContainerXXL>
  );
}

export function FielderDefaultContainerXL({ children, ...props 
}) {
  return (
    <StyleFielderDefaultContainerXL {...props }>
        {children}
    </StyleFielderDefaultContainerXL>
  );
}

FielderFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  error: PropTypes.object,
  type: PropTypes.oneOf(['text', 'password']),
};

FielderFormField.defaultProps = {
  name: '',
	label: '',
	id: '',
	defaultValue: '',
	error: '',
};

FielderPhoneField.defaultProps = {
	id: '',
	label: '',
	isRequired: false,
  disabled: false,
  externalError: ''
};
