import React, { useState } from 'react';
import ChatbotContainer from './bot/ChatbotContainer';

const ChatBotFielder = () => {
  return (
    <div style={{ position: 'fixed', bottom: '0%', right: '0.5%', zIndex: '1001' }}>
      <ChatbotContainer />
    </div>
  );
};
export default ChatBotFielder;