import React from 'react';

import { LoadingContainer, BouncingCircle } from './LoadingBouncingDots.styles';

const loadingContainerVariants = {
	start: {
		transition: {
			staggerChildren: 0.2,
		},
	},
	end: {
		transition: {
			staggerChildren: 0.2,
		},
	},
};

const loadingCircleVariants = {
	start: {
		y: '0%',
	},
	end: {
		y: '100%',
	},
	exit: {
		opacity: 0,
	},
};

const loadingCircleTransition = {
	duration: 0.5,
	yoyo: Infinity,
	ease: 'easeInOut',
};

function LoadingBouncingDots() {
	return (
		<LoadingContainer variants={loadingContainerVariants} initial='start' animate='end' exit='exit'>
			<BouncingCircle index={0} variants={loadingCircleVariants} transition={loadingCircleTransition} />
			<BouncingCircle index={1} variants={loadingCircleVariants} transition={loadingCircleTransition} />
			<BouncingCircle index={2} variants={loadingCircleVariants} transition={loadingCircleTransition} />
		</LoadingContainer>
	);
}

export default LoadingBouncingDots;
