import React, { Component } from 'react';
import moment from 'moment';
import {Row, Col} from 'react-bootstrap';

// Custom components
import Popup from '../../Popup';
import model from "Object/model/model.js";

import '../MyOperation.css';

import CalendarIcon from '@assets/icons/calendar-icon.svg';
import alertIcon from '@assets/icons/danger-icon.svg'
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { doActionRescheduleRouteRequest } from '@core/api/route/route.service';


/***** SERVERLESS IMPORT  *****/

class RescheduleRoutePopup extends Component {

    constructor(props) {
        super(props);

        let idUserAssigned = [];
        let rescheduleSuccess = true;
        let rescheduleFail = false;
        let dateSelected = new Date();

        if (this.props.currentDate && this.props.currentDate !== undefined && this.props.currentDate.localeCompare("") !== 0) {
            let date = this.props.currentDate.split(" ");
            let currentMoment = moment()

            let day = date[0].split("-")[0];
            let month = date[0].split("-")[1];
            let year = date[0].split("-")[2];

            dateSelected = new Date(year, month-1, day, currentMoment.hour(), currentMoment.minute());
            if(currentMoment.isAfter(dateSelected)){

                var mom = moment().format('YYYY-MM-DDTHH:MM');
                mom = moment().subtract(1, 'second')

                var offset = (dateSelected.getTimezoneOffset()*(-1))
                var abs = Math.abs(offset)

                dateSelected= new Date(mom.year(), mom.month(), mom.date(), mom.hour(), mom.minute(), 15)
            } else {
                var mom = moment(dateSelected).format('YYYY-MM-DDTHH:MM');
                mom = moment(dateSelected).subtract(1, 'second')

                var offset = (dateSelected.getTimezoneOffset()*(-1))
                var abs = Math.abs(offset)

                dateSelected= new Date(mom.year(), mom.month(), mom.date(), mom.hour(), mom.minute(), 15)
            }

        } else {
            var mom = moment(dateSelected).format('YYYY-MM-DDTHH:MM');
            mom = moment(dateSelected).subtract(1, 'second')

            var offset = (dateSelected.getTimezoneOffset()*(-1))
            var abs = Math.abs(offset)

            dateSelected= new Date(mom.year(), mom.month(), mom.date(), mom.hour(), mom.minute(), 15)
        }

        this.state = {
            dateSelected: dateSelected,
            minTime: new Date(),
            offset: offset,
            abs: abs,
            idUserAssigned: idUserAssigned,
            showErrorMessage: false,
            showErrorMessageText: "",
            showErrorMessageIcon: null,
            rescheduleSuccess: rescheduleSuccess,
            rescheduleFail: rescheduleFail,
        }

    }

    showMessage = () => {
        this.props.onClose();
    }

    getType(type) {
        return this.props.lan["tasktype" + type];
    }

    showPopupErrorMessage() {
        if (this.state.rescheduleSuccess) {
            let state = this.state;
            state.showErrorMessageText = this.props.lan.savedCorrectly;
            state.showErrorMessage = true;
            state.showErrorMessageIcon = SuccessIcon;
            this.setState(state);
        } else {
            let state = this.state;
            state.showErrorMessageText = this.props.lan.errorGralFail;
            state.showErrorMessage = true;
            state.showErrorMessageIcon = alertIcon;
            this.setState(state);
        }
    }


    manageGetRescheduleRoute = (res) => {
        this.props.onClose(this.state.rescheduleSuccess, this.state.rescheduleFail);
    }

    rescheduleRoute = (e) => {
        e.preventDefault();

        var mom =  moment(this.state.dateSelected).format('YYYY-MM-DDTHH:MM');
        mom = moment(this.state.dateSelected).subtract(1, 'second')

        if(this.state.offset<0){
            mom = moment(this.state.dateSelected).subtract(this.state.abs, 'minutes')
        } else {
            mom = moment(this.state.dateSelected).add(this.state.abs, 'minutes')
        }

        var dateSelected = new Date(mom.year(), (mom.month()), mom.date(), mom.hour(), mom.minute(), 15)

        let body = model.getRequestRescheduleRoute(
            this.props.idRoute,
            mom.year(),
            mom.month()+1,
            mom.date()
            );

        const onSuccess = (response) => {
            this.manageGetRescheduleRoute(response)
		}

		const onError = (response) => {
			if(response.errorCode==950){
                let state = this.state;
                state.rescheduleSuccess = false;
                state.rescheduleFail = false;
                this.setState(state);

                this.props.onClose(this.state.rescheduleSuccess, this.state.rescheduleFail);
            }
            else {
                let state = this.state;
                state.rescheduleSuccess = false;
                state.rescheduleFail = true;
                this.setState(state);

                this.props.onClose(this.state.rescheduleSuccess, this.state.rescheduleFail);
            }
		}

        const loadingConfig = { dispatch: this.props.dispatch }
		doActionRescheduleRouteRequest(body, onSuccess, onError, loadingConfig);

    }

    handleProgramDate = (date) => {
        let minTime = new Date();

        if (date.getDate() !== (new Date().getDate()) || date.getMonth() !== (new Date().getMonth()) || date.getFullYear() !== (new Date().getFullYear())) {
            minTime = new Date().setHours(0,0);
        }

        this.setState({
            dateSelected: date,
            minTime: minTime
        });
    }

    render() {
        return (
            <React.Fragment>

                <Popup
                    title={this.props.lan.rescheduleRoute}
                    icon='calendar-icon.svg'
                    show
                    closeButton
                    onClose={this.props.onClose}
                    buttonText={this.props.lan.reschedule}
                    requiredFields={this.props.lan.requiredFields}
                    buttonOnClick={this.rescheduleRoute}
                    size={'md'}
                >
                    <Row>
                        <Col sm={12}>
                            {/* Task Type */}
                            <div className='mt-1'>
                                <Row >
                                    <Col className='small-popup-label'> {this.props.lan.routeNumber}</Col>
                                </Row>
                                <Row >
                                    <Col className='popup-label my-2 ml-2'>{this.props.idRoute}</Col>
                                </Row>
                            </div>

                            {/* Task Description */}
                            <div className='mt-3'>
                                <Row>
                                    <Col className='small-popup-label'>{this.props.lan.routeName}</Col>
                                </Row>
                                <Row >
                                    <Col className='popup-label my-2 ml-2'>{this.props.routeName}</Col>
                                </Row>
                            </div>
                            <div className='mt-3'>
                                <Row >
                                    <Col className='small-popup-label'> {this.props.lan.agentName}</Col>
                                </Row>
                                <Row >
                                    <Col className='popup-label my-2 ml-2'>{this.props.agent}</Col>
                                </Row>
                            </div>

                            {/* Date Picker */}
                            <div className='mt-3'>
                                <Row >
                                    <Col className='small-popup-label'> {this.props.lan.selectRescheduledDate}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6} className='ml-3'>
                                        <Row className='Fielder-input-border '>
                                            <Col sm={'auto'}>
                                                <img src={CalendarIcon} className='icon my-2' alt='' />
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    selected={this.state.dateSelected}//seleccionada fecha
                                                    onChange={this.handleProgramDate}
                                                    minDate={new Date()}//fecha minima para deshabilitar dias
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    showMonthDropdown
                                                    showYearDropdow
                                                    minTime={this.state.minTime}
                                                    maxTime={(new Date()).setHours(23, 59)}
                                                    dateFormat="dd/MM/yyyy h:mm aa"//Formato de fecha
                                                    timeIntervals={30}//Intervalo de tiempo en minutos
                                                    className="MyOperation-eschedule form-control mt-1 text-left"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>

                </Popup>
            </React.Fragment>
        );

    }
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(RescheduleRoutePopup);