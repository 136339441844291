import AccountConfigurationConst from 'FielderUtils/roleManagementConst/accountConfigurationConst';
import AdminConst from 'FielderUtils/roleManagementConst/adminConst';
import AgentConst from 'FielderUtils/roleManagementConst/agentConst';
import AnalyticsConst from 'FielderUtils/roleManagementConst/analyticsConst';
import AssetConst from 'FielderUtils/roleManagementConst/assetConst';
import AutoAssignConst from 'FielderUtils/roleManagementConst/autoassignConst';
import BillingConst from 'FielderUtils/roleManagementConst/billingConst';
import BranchConst from 'FielderUtils/roleManagementConst/branchConst';
import CalendarConst from 'FielderUtils/roleManagementConst/calendarConst';
import ClientConst from 'FielderUtils/roleManagementConst/clientConst';
import CompanyConst from 'FielderUtils/roleManagementConst/companyConst';
import DashboardConst from 'FielderUtils/roleManagementConst/dashboardConst';
import DynamicAllocationConst from 'FielderUtils/roleManagementConst/dynamicAllocationConst';
import FormConst from 'FielderUtils/roleManagementConst/formConst';
import GroupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import InventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import IsochronousConst from 'FielderUtils/roleManagementConst/isochronousConst';
import ManagerConst from 'FielderUtils/roleManagementConst/managerConst';
import PartnerConst from 'FielderUtils/roleManagementConst/partnerConst';
import ResourceAllocationConst from 'FielderUtils/roleManagementConst/resourceAllocationConst';
import RouteConst from 'FielderUtils/roleManagementConst/routeConst';
import RouteOptimizationConst from 'FielderUtils/roleManagementConst/routeOptimizationConst';
import SkillsConst from 'FielderUtils/roleManagementConst/skillsConst';
import SmsConst from 'FielderUtils/roleManagementConst/smsConst';
import TaskConst from 'FielderUtils/roleManagementConst/taskConst';
import TaskTypePreferencesConst from 'FielderUtils/roleManagementConst/taskTypePreferencesConst';
import TeamConst from 'FielderUtils/roleManagementConst/teamConst';

export const getUserRoles = (roles) => {
	let userRoles = {};

	if (roles) {
		// Iterate through all the roles set to the user
		roles.forEach((role) => {
			// Each Role has different Permission
			role.permissions.forEach((permission) => {
				// Each Policy contains the sectional permission
				permission.policies.forEach((policy) => {
					userRoles[policy.name] = getPolicyPermissions(policy.name, policy.permissions);
				});
			});
		});
	}

	return userRoles;
};

const getPermissionActions = (policyName) => {
	switch (policyName) {
		case AccountConfigurationConst.NAME:
			return AccountConfigurationConst;
		case AdminConst.NAME:
			return AdminConst;
		case AgentConst.NAME:
			return AgentConst;
		case AnalyticsConst.NAME:
			return AnalyticsConst;
		case AssetConst.NAME:
			return AssetConst;
		case AutoAssignConst.NAME:
			return AutoAssignConst;
		case BillingConst.NAME:
			return BillingConst;
		case BranchConst.NAME:
			return BranchConst;
		case CalendarConst.NAME:
			return CalendarConst;
		case ClientConst.NAME:
			return ClientConst;
		case CompanyConst.NAME:
			return CompanyConst;
		case DashboardConst.NAME:
			return DashboardConst;
		case DynamicAllocationConst.NAME:
			return DynamicAllocationConst;
		case FormConst.NAME:
			return FormConst;
		case GroupTaskConst.NAME:
			return GroupTaskConst;
		case InventoryConst.NAME:
			return InventoryConst;
		case IsochronousConst.NAME:
			return IsochronousConst;
		case ManagerConst.NAME:
			return ManagerConst;
		case PartnerConst.NAME:
			return PartnerConst;
		case ResourceAllocationConst.NAME:
			return ResourceAllocationConst;
		case RouteConst.NAME:
			return RouteConst;
		case RouteOptimizationConst.NAME:
			return RouteOptimizationConst;
		case SkillsConst.NAME:
			return SkillsConst;
		case SmsConst.NAME:
			return SmsConst;
		case TaskConst.NAME:
			return TaskConst;
		case TaskTypePreferencesConst.NAME:
			return TaskTypePreferencesConst;
		case TeamConst.NAME:
			return TeamConst;
		default:
			return null;
	}
};

const getPolicyPermissions = (policyName, permissions) => {
	const permissionActions = getPermissionActions(policyName);
	let policyPermissions = {};

	if (permissionActions == null) {
		return null;
	}

	permissions.forEach((policyPermission) => {
		for (var action in permissionActions) {
			if (
				permissionActions[action] === policyPermission.type &&
				!isNaN(permissionActions[action])
			) {
				policyPermissions[policyPermission.type] = policyPermission.allowed;
			}
		}
	});

	return policyPermissions;
};
