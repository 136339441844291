import React, { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import { getFailure } from 'FielderUtils/autoassign/autoassign';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TEMPLATE_STATUS } from '@core/constants/autoAssign/processTaskTemplate.constants';
import 'FielderUtils/style.css';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import AutoAssignTaskStatus from 'OldComponents/Status/AutoAssignTaskStatus';
import { Grid } from '@mui/material';
import { DeleteIcon, TemplateRemovedText } from '../AutoAssignDetail.styles';
import { StatusContainer } from '~/Status/styled';
import { AliceBlue, CadetBlueCrayola, DeepSpaceGrey } from '@core/theme/baseTheme';
import FielderButton from 'FielderComponents/FielderButton';
import { binarySearch } from '@core/utils/searchAlgorithms/binarySearch';
import TextField from '~/TextField/TextField.component';
import TextDetail from '~/TextDetail/TextDetail.component';

export default function AutoassignInfo({ processTask, taskTypes }) {
	const lan = useLanguage();
	const navigate = useNavigate();

	const [template, setTemplate] = useState('');
	const [templateStatus, setTemplateStatus] = useState(null);
	const [task, setTask] = useState('');
	const [status, setStatus] = useState('');
	const [taskId, setTaskId] = useState('');
	const [taskType, setTaskType] = useState('');
	const [origin, setOrigin] = useState('');
	const [customId, setCustomId] = useState('');
	const [priority, setPriority] = useState('');

	useEffect(() => {
		if (processTask) {
			const { template, task, status, taskId, templateStatus } = processTask;
			setTemplate(template);
			setTemplateStatus(templateStatus);
			setStatus(status);
			setTaskId(taskId);
			setTask(task);

			const {idCustom, serviceTypeId, branch, user} = task

			if (idCustom) {
				setCustomId(idCustom);
			}

			const taskType = binarySearch(taskTypes, serviceTypeId)
			setTaskType(taskType);

			if (branch) {
				setOrigin(branch.name);
			} else if (task.user) {
				setOrigin(`${user.name} ${user.lastName}`);
			}
		}
	}, [processTask]);

	useEffect(() => {
		if (task?.severity) {
		 	setPriority(lan[`severity_${task.severity}`])
		}
	}, [task, lan])

	const getTime = () => {
		if (template) {
			let pri;
			if (task.severity == 1) {
				pri = template.serviceLevel.high;
			} else if (task.severity == 2) {
				pri = template.serviceLevel.med;
			} else {
				pri = template.serviceLevel.low;
			}

			if (pri.immediateResponse) {
				return lan.immediateResponse;
			}
			const hours = Math.floor(pri.time / 3600);
			const minutes = Math.floor((pri.time - hours * 3600) / 60);

			return `${hours} hrs ${minutes} min`;
		}
		return '';
	};

	const showTemplate = () => navigate(`${ENDPOINTS.autoAssign}/${template.id}`);

	const ViewTemplate = () => {
		if (templateStatus === TEMPLATE_STATUS.DELETED) {
			return (
				<StatusContainer color={CadetBlueCrayola} background={AliceBlue}>
					<Grid container direction='row' alignItems='center' alignContent='center' spacing={1}>
						<Grid item xs='auto'>
							<DeleteIcon />
						</Grid>
						<Grid item xs>
							<TemplateRemovedText>{lan.autoAssignTemplateDeleted} </TemplateRemovedText>
						</Grid>
					</Grid>
				</StatusContainer>
			);
		}

		return (
			<>
				<Grid container direction='row' alignItems='center' alignContent='center' justifyContent='center' spacing={1}>
					<Grid item xs={12}>
						<FielderButton outlined onClick={showTemplate} className='p-1'>
							{lan.viewAutoassignTemplate}
						</FielderButton>
					</Grid>
					<Grid item xs='auto'>
						{(templateStatus === TEMPLATE_STATUS.INACTIVE || true) && (
							<TemplateRemovedText>{lan.autoAssignTemplateIsInactive}</TemplateRemovedText>
						)}
					</Grid>
				</Grid>
			</>
		);
	};

	const LabelText = (props) => {
		const text = Array.isArray(props.text) ? props.text.join(' > ') : props.text;

		return (
			<Col sm={12} className={props.className}>
				 <TextDetail label={props.label} text={text} />
			</Col>
		);
	};

	const LabelArea = (props) => {
		const { text } = props;
		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={`td-label-input ${props.labelClass}`}>{props.label}</Col>
				</Row>
				<Row>
					<Col className={`td-default-text align-left ${props.textClass}`}>
						<textarea
							rows='4'
							cols='20'
							className='taskinfo-scrollTextarea task-details-input'
							value={` ${text}`}
							disabled
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
			</Col>
		);
	};

	const LeftColumn = () => {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Row>
						<Col sm={5}>
							<LabelText label={lan.idTask} text={taskId} className='my-3' textClass='td-task-number' />
						</Col>
						<Col sm={7} className='my-auto'>
							{status && <AutoAssignTaskStatus value={status} />}
						</Col>
					</Row>
				</Grid>

				<Grid item xs={12}>
					<LabelText label={lan.idCustom} text={customId} className='my-3' />
				</Grid>

				<Grid item xs={12}>
					<LabelText label={lan.folio} text={task.folio ? task.folio : ''} className='my-3' />
				</Grid>
				<Grid item xs={12}>
					<LabelText label={`${lan.company}/${lan.client}`} text={origin} className='my-3' />
				</Grid>
				<Grid item xs={12}>
					<LabelText label={lan.templateName} text={template ? template.name : ''} className='my-3' />
				</Grid>
			</Grid>
		);
	};

	return (
		<Grid container direction='row'>
			<Grid item xs>
				<LeftColumn />
			</Grid>
			<Grid item xs>
				<Grid container>
					<Grid item xs={6} className='mx-auto'>
						<ViewTemplate />
					</Grid>
					<Grid item xs={12}>
						<LabelText label={lan.typeTask} text={taskType ? taskType.name : ''} className='my-3' />
					</Grid>
					<Grid item xs={12}>
						<LabelText label={lan.priority} text={priority} className='my-3' />
					</Grid>
					<Grid item xs={12}>
						<LabelText label={lan.responseTime} text={getTime()} className='my-3' />
					</Grid>
					<Grid item xs={12}>
						<LabelText label={lan.failureReason} text={getFailure(status, lan)} className='my-3' />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
