import React from 'react';

import PreviewIcon from '@mui/icons-material/Preview';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const AccountReview = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.accountReview;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={() => goToPage(ENDPOINTS.accountReview)}>
				<ListItemIcon>
					<PreviewIcon alt='Account Review' style={{ width: '20px' ,fill: '#bcbcbc' }} />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default AccountReview;
