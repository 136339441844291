import ky from 'ky';
import { pipe } from 'lodash/fp';
import { buildSession, getIdToken } from '../../client/utils/session/Session';
import { ERROR_CODE_STATUS_UNAUTHORIZED } from '../constants/errorCodes.constants';
import { refreshSessionToken } from './user/user.service';
import { LoadingDispatcherConfig } from '@core/models/loading.types';
import { loadingStart, loadingStop } from '../store/modules/loading/loading.slice';

type Body = {
	type?: string;
	requestType?: string;
	typeOfRequest?: string;
	request?: string;

	// Keys can be strings, numbers, or symbols.
	// If you know it to be strings only, you can also restrict it to that.
	// For the value you can use any or unknown,
	// with unknown being the more defensive approach.
	[x: string]: unknown;
};

function isLoadingDispatcherConfig(loadingDispatcherConfig: any): loadingDispatcherConfig is LoadingDispatcherConfig {
	return loadingDispatcherConfig && (loadingDispatcherConfig as LoadingDispatcherConfig).dispatch !== undefined;
}

const getLoadingDispatcherConfig = (
	loadingDispatcherConfig: LoadingDispatcherConfig,
	body: Body
): LoadingDispatcherConfig => {
	if (!body) {
		return loadingDispatcherConfig;
	}

	if (!('name' in loadingDispatcherConfig)) {
		let name: string = 'request';

		if ('type' in body && typeof body.type === 'string') {
			name = body.type;
		} else if ('requestType' in body && typeof body.requestType == 'string') {
			name = body.requestType;
		} else if ('typeOfRequest' in body && typeof body.typeOfRequest == 'string') {
			name = body.typeOfRequest;
		} else if ('request' in body && typeof body.request == 'string') {
			name = body.request;
		}

		if (isLoadingDispatcherConfig(loadingDispatcherConfig)) {
			loadingDispatcherConfig['name'] = name;
		}

		return loadingDispatcherConfig;
	}

	return loadingDispatcherConfig;
};

type SuccessFunction = (response: any) => any;
type ErrorFunction = (response: any) => any;

export function handlePostRequest(
	path: string,
	body: Body,
	onSuccess: SuccessFunction | void,
	onError: ErrorFunction | void,
	loadingDispatcherConfig: LoadingDispatcherConfig | void
) {
	if (isLoadingDispatcherConfig(loadingDispatcherConfig)) {
		loadingDispatcherConfig = getLoadingDispatcherConfig(loadingDispatcherConfig, body);
	}

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loadingDispatcherConfig));
	return doRequest(path, body, {});
}

export function handleGetRequest(
	path: string,
	queryParams: URLSearchParams,
	onSuccess: SuccessFunction | void,
	onError: ErrorFunction | void,
	loadingDispatcherConfig: LoadingDispatcherConfig | void
) {
	// if (loadingDispatcherConfig) {
	// 	loadingDispatcherConfig = getLoadingDispatcherConfig(loadingDispatcherConfig);
	// }

	const doRequest = pipe(get, requestHandler(onSuccess, onError, loadingDispatcherConfig));
	return doRequest(path, null, queryParams, {});
}

const loadingHandler = (loadingDispatcherConfig: LoadingDispatcherConfig | void, isLoading: boolean) => {
	if (loadingDispatcherConfig) {
		const { dispatch, name, config } = loadingDispatcherConfig;

		if (isLoading) {
			dispatch(loadingStart({ name, config }));
		} else {
			dispatch(loadingStop(name));
		}
	}
};

const getAuthorizationHeaders = () => {
	return {
		Authorization: getIdToken(),
	};
};

const getHookRefreshToken = (loadingDispatcherConfig: LoadingDispatcherConfig | void) => {
	return {
		afterResponse: [
			async (request: any, options: any, response: any) => {
				if (response?.status === ERROR_CODE_STATUS_UNAUTHORIZED) {
					console.log('UNAUTHORIZED ', response);
					if (loadingDispatcherConfig) {
						loadingHandler(loadingDispatcherConfig, true);
					}

					// Retry with the token
					const { idToken, accessToken, refreshToken } = await refreshSessionToken();
					console.log('refreshed token', idToken, accessToken);
					buildSession(idToken, accessToken, refreshToken);

					const newRequest = request.clone();
					newRequest.headers.set('Authorization', idToken);
					return ky(newRequest);
				}
			},
		],
	};
};

const errorHandler = (onError: ErrorFunction, error: any) => {
	// Handle the errors from the request
	console.log('errorHandler', error);
	if (error?.response && error.response.status !== ERROR_CODE_STATUS_UNAUTHORIZED) {
		// If user defined the error function, call it
		if (onError) {
			const errorResponse = error.response;
			return onError({
				status: errorResponse.status,
				message: errorResponse?.statusText ? errorResponse.statusText : errorResponse.message,
			});
		} else {
			console.error('[Fielder Request Error]', error);
		}
	} else {
		console.error('[Fielder Error - unauthorized]', error);
	}
	return error;
};

export const requestHandler =
	(
		onSuccess: SuccessFunction | void,
		onError: ErrorFunction | void,
		loadingDispatcherConfig: LoadingDispatcherConfig | void
	) =>
	(request: any) => {
		loadingHandler(loadingDispatcherConfig, true);
		return request
			.json()
			.then((response: any) => {
				if (response?.success) {
					if (onSuccess) {
						return onSuccess(response);
					} else {
						return response;
					}
				} else {
					if (onError) {
						const error = {
							response: {
								status: response.errorCode,
								message: response.errorMessage,
							},
						};
						return errorHandler(onError, error);
					}
				}
			})
			.catch((error: Error) => {
				return (onError ? errorHandler(onError, error) : null)
			})
			.finally(() => loadingHandler(loadingDispatcherConfig, false));
	};

/**
 * @param {string} url - request url
 * @param {object} options - request options
 */
export const get = (
	url: string,
	endpoint: string | null,
	queryString: URLSearchParams,
	options: any,
	loadingDispatcherConfig: LoadingDispatcherConfig | void
) => {
	if (!options) {
		options = {};
	}

	options.headers = getAuthorizationHeaders();
	options.timeout = 30000;
	options.hooks = { ...getHookRefreshToken(loadingDispatcherConfig) };

	if (endpoint) {
		return ky.get(`${url}/${endpoint}?${queryString.toString()}`, options);
	}

	return ky.get(`${url}?${queryString}`, options);
};

/**
 * @param {string} url - request url
 * @param {string} id - id to search
 * @param {object} options - request options
 */
export const getSingle = (
	url: string,
	id: number,
	options: any,
	loadingDispatcherConfig: LoadingDispatcherConfig | void
) => {
	return ky.get(`${url}/${id}`, options);
};

/**
 * @param {string} url - request url
 * @param {object} body - request body
 * @param {object} options - request options
 */
export const post = (url: string, body: any, options: any, loadingDispatcherConfig: LoadingDispatcherConfig | void) => {
	if (!options) {
		options = {};
	}

	options.headers = getAuthorizationHeaders();
	options.json = body;
	options.timeout = 30000;
	options.hooks = { ...getHookRefreshToken(loadingDispatcherConfig) };
	return ky.post(url, options);
};

/**
 * @param {string} url - request url
 * @param {object} body - request body
 * @param {object} options - request options
 */
export const put = (url: string, body: any, options: any) => {
	if (!options) {
		options = {};
	}

	options.json = body;
	return ky.put(url, options);
};

/**
 * @param {string} url - request url
 * @param {string} id - id to search
 * @param {object} options - request options
 */
export const remove = (url: string, id: number, options: any) => {
	return ky.delete(`${url}/id`, options);
};

export const baseQueryGet = (args: any) => async (requestArgs: any, reduxArgs: any) => {
	const queryArgs = new URLSearchParams(requestArgs.params);

	const onSuccess = (result: any) => ({ data: result });
	const onError = (error: any) => ({ error: error });

	const doRequest = pipe(get, requestHandler(onSuccess, onError, args.loading));
	return doRequest(args.baseUrl, requestArgs.url, queryArgs, args.options);
};
