import React, { useState, useEffect, useRef } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import Popup from 'OldComponents/Popup';
import { Row, Col} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Alert } from '@mui/material'
import { useDispatch } from 'react-redux';
import Types from 'FielderUtils/forms/types';
import {
	getFormByIdRequest,
} from '@core/api/form/form.service';


const DowloadFormTemplate = ({setViewImport, viewImport, idFormTemplate, nameFormTemplate}) => {

	const lan = useLanguage();
	const dispatch = useDispatch();
	const [importCsvData, setImportCsvData] = useState([]);
	const csvImportInstance = useRef();

	const QUESTIONS_INFO = [
		{
			name: lan.checkbox,
			value: lan.optionToSelect,
		},
		{
			name: lan.radioButton,
			value: lan.optionsToSelect,
		},
		{
			name: lan.textFieldQuestion,
			value: lan.text,
		},
		{
			name: lan.numericRating,
			value: lan.numberInsideRatingValue,
		},
		{
			name: lan.binaryRating,
			value: lan.positiveOrNegative,
		},
		{
			name: lan.starsRating,
			value: lan.starsRatingRange,
		},
		{
			name: lan.photograph,
			value: lan.notAvailableForUpdate,
		},
		{
			name: lan.barcode,
			value: lan.insertBarcode,
		},
		{
			name: lan.dateHour,
			value: lan.insertDateInFormFormat,
		},
		{
			name: lan.textFile,
			value: lan.notAvailableForUpdate,
		},
		{
			name: lan.eSignature,
			value: lan.notAvailableForUpdate,
		},
		{
			name: lan.qrCode,
			value: lan.notAvailableForUpdate,
		},
		{
			name: lan.catalogue,
			value: lan.optionsCatalogue,
		},
	];

	const getFieldType = (field) => {
		switch (parseInt(field.type)) {
			case Types.CHECKBOX:
			case Types.MULTIPLE_CHOICE:
				return field.singleAnswer ? lan.radioButton : lan.checkbox;
			case Types.TEXT_FIELD:
				return lan.textFieldQuestion;
			case Types.NUMERIC_RATING:
				return lan.numericRating;
			case Types.BINARY_RATING:
				return lan.binaryRating;
			case Types.STARS_RATING:
				return lan.starsRating;
			case Types.CODEBAR_TEXT:
				return lan.barcode;
			case Types.DATE_HOUR:
				return lan.dateHour +' '+field.dateFormat;
			case Types.CATALOGUE:
				return lan.catalogue;
		}
	};



	const getCsvHeaders = (formFields) => {
		const csvData = [];
		csvData.push(lan.idTaskName);
		csvData.push(`${lan.idForm}: (${idFormTemplate}) ${nameFormTemplate}`);

		formFields.forEach((field) => {
			const question = field.question;
			if (parseInt(question.type) != Types.ELECTRONIC_SIGNATURE && parseInt(question.type) != Types.FILES &&
					parseInt(question.type) != Types.CODEBAR_IMG && parseInt(question.type) != Types.TEXT_FILE) {
				csvData.push(`${question.id} - ${question.title} [${getFieldType(question)}]`);
			}	
		});

		return csvData;
	};

	const handleExportCSVTemplate = () => {

		let body = {
			formId: idFormTemplate
		}

		const onSuccess = (response) => {
			const formFields = response.template.fields;
			try { 
				const headers = getCsvHeaders(formFields);
				setImportCsvData(headers);
			} catch (error) {
				console.log("error")
				console.log(error)
			}
		};


		const loadingConfig = { dispatch }
		getFormByIdRequest(body, onSuccess, null, loadingConfig)
	};

	useEffect(() => {
		if (importCsvData !== null && importCsvData.length > 0 && csvImportInstance.current && csvImportInstance.current.link) {
			setTimeout(() => {
				csvImportInstance.current.link.click();
				setImportCsvData([]);
			});
		}
	}, [importCsvData]);

	const ExampleLabel = ({ name, value }) => (
		<Row>
			<Col sm={4}>
				<span className='Fielder-label-input'>{name}</span>
			</Col>
			<Col sm='auto'>
				<span className='itallic Fielder-label-input Fielder-grey-color'>{value}</span>
			</Col>
		</Row>
	);


	return (
	<>
		{viewImport && (
			<Popup
				icon='excel-logo-icon.png'
				show
				width='lg'
				closeButton
				title={lan.updateFormInTaskByCsv}
				onClose={() => setViewImport(false)}
			>
				<Row>
					<Col>{lan.updateFormInfo}</Col>
				</Row>

				<Row className='mt-2 mb-4'>
					{<Col>
						{QUESTIONS_INFO.map((element) => (
							<ExampleLabel {...element} />
						))}
					</Col>
					}
				</Row>

				<Row className='my-2'>
					<Col className='mx-auto'>
						<Alert severity='warning'>
							<Col>
								<Row className='mb-2'>
									<span className='small-popup-label '>{lan.rememberCsvFile}</span>
								</Row>
								<Row>
									<span className='small-popup-label '>{lan.useCorrectCsvUpdateTemplate}</span>
								</Row>
								<Row>
									<span className='small-popup-label '>{lan.cellsLeftBlankInfo}</span>
								</Row>
							</Col>
						</Alert>
					</Col>
				</Row>

				<button
					className='myoperation-link my-2'
					style={{ background: 'none', border: 'none' }}
					onClick={handleExportCSVTemplate}
				>
					<u>{lan.downloadCSVTemplateCategory}</u>
				</button>
			</Popup>
		)}
		
			<CSVLink headers={importCsvData} data={[]} ref={csvImportInstance} filename={`${nameFormTemplate}-sample.csv`} />
		
	</>
	);

}

export default DowloadFormTemplate;