import React from 'react';

import { Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { LightGreySubtitleColor } from '@core/theme/baseTheme';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import PartnersIcon from '@mui/icons-material/ZoomInMap';
import TeamsIcon from '@mui/icons-material/Group';
import RejectedIcon from '@mui/icons-material/PersonAddDisabled';
import RouteIcon from '@mui/icons-material/Route';
import ImageIcon from '@mui/icons-material/Image';
import FileIcon from '@mui/icons-material/AttachFile';
import CommentsIcon from '@mui/icons-material/Comment';
import RescheduleIcon from '@mui/icons-material/Update';
import OutOfScheduleIcon from '@mui/icons-material/AlarmOff';
import MissingSignatureIcon from '@mui/icons-material/RateReview';
import ModifiedStatusIcon from '@mui/icons-material/TrendingUp';
import ManagerIcon from '@mui/icons-material/SupportAgent';
import RecurrentIcon from '@mui/icons-material/Cached';
import GroupIcon from '@mui/icons-material/Assignment';
import VolumeIcon from '@mui/icons-material/DynamicFeed';
import StarIcon from '@mui/icons-material/Star';

const TaskDetailIconsContainer = styled(Grid)`
	color: ${LightGreySubtitleColor};
`;

const TaskDetailIcons = ({ icons, ...props }) => {
	const {
		rejected,
		volumeTask,
		recurrentTask,
		group,
		route,
		team,
		manager,
		document,
		partner,
		image,
		comments,
		reschedule,
		outOfSchedule,
		modified,
		missingFormsSignature,
		rating,
	} = icons;

	const lan = useLanguage();
	const [taskDetailIcons, setTaskDetailIcons] = React.useState([]);

	React.useEffect(() => {
		let taskIcons = [];

		if (rejected) {
			taskIcons.push({ icon: <RejectedIcon />, text: lan.DO_REJECT_TASK_AGENT });
		}

		if (volumeTask) {
			taskIcons.push({ icon: <VolumeIcon />, text: lan.volumeTask });
		}

		if (recurrentTask) {
			taskIcons.push({ icon: <RecurrentIcon />, text: lan.recurrentTask });
		}

		if (group) {
			taskIcons.push({ icon: <GroupIcon />, text: `${lan.groupTask}: ${group}` });
		}

		if (route) {
			taskIcons.push({ icon: <RouteIcon />, text: `${lan.route}: ${route}` });
		}

		if (team) {
			taskIcons.push({ icon: <TeamsIcon />, text: `${lan.team}: ${team}` });
		}

		if (manager) {
			taskIcons.push({ icon: <ManagerIcon />, text: `${lan.manager}: ${manager}` });
		}

		if (partner) {
			taskIcons.push({ icon: <PartnersIcon />, text: `${lan.partner}: ${partner}` });
		}

		if (image) {
			taskIcons.push({ icon: <ImageIcon />, text: `${lan.images}: ${image}` });
		}

		if (document) {
			taskIcons.push({ icon: <FileIcon />, text: `${lan.documents} ${document}` });
		}

		if (comments) {
			taskIcons.push({ icon: <CommentsIcon />, text: lan.commentTitle });
		}

		if (reschedule) {
			taskIcons.push({ icon: <RescheduleIcon />, text: lan.rescheduledDate });
		}

		if (outOfSchedule) {
			taskIcons.push({ icon: <OutOfScheduleIcon />, text: lan.outOfBranchOrClientSchedule });
		}

		if (modified) {
			taskIcons.push({ icon: <ModifiedStatusIcon />, text: `${lan.statusModifiedByUser}: ${modified}` });
		}

		if (missingFormsSignature) {
			taskIcons.push({ icon: <MissingSignatureIcon />, text: lan.missingFormsToSign });
		}

		if (rating) {
			taskIcons.push({ icon: <StarIcon />, text: `${lan.rating}: ${rating}` });
		}

		setTaskDetailIcons(taskIcons);
	}, [icons]);

	return (
		<TaskDetailIconsContainer container spacing={1}>
			{taskDetailIcons.map(({ text, icon }) => (
				<Grid item lg={3}>
					<Tooltip title={text}>{icon}</Tooltip>
				</Grid>
			))}
		</TaskDetailIconsContainer>
	);
};

export default React.memo(TaskDetailIcons);
