import React from 'react';

import AssetsIcon from '@assets/icons/assets-icon.svg';
import FormsIcon from '@assets/icons/form-icon.svg';
import InventoriesIcon from '@assets/icons/inventory-icon.svg';
import { Image } from 'react-bootstrap';

class iconTaskInformationFormatter extends React.Component {
	render() {
		const type = this.props.value;
		let iconInventory = null;
		let iconForm = null;
		let iconAssets = null;

		if (type.inventory == true) {
			iconInventory = InventoriesIcon;
		}
		if (type.forms == true) {
			iconForm = FormsIcon;
		}
		if (type.assets == true) {
			iconAssets = AssetsIcon;
		}

		return (
			<div className='row height-row no-padding'>
				{iconInventory !== null && (
					<div className='pl-1 pr-1'>
						<Image src={iconInventory} alt='' className='SmartTable-icon-image-info' />
					</div>
				)}
				{iconForm !== null && (
					<div className='pl-1 pr-1'>
						<Image src={iconForm} alt='' className='SmartTable-icon-image-info' />
					</div>
				)}

				{iconAssets !== null && (
					<div className='pl-1 pr-1'>
						<Image src={iconAssets} alt='' className='SmartTable-icon-image-info' />
					</div>
				)}
			</div>
		);
	}
}

export default iconTaskInformationFormatter;
