// ROLE MANAGEMENT
const PAGE_NAME = 'CLIENTS';
const CLIENTS = 'clients';
const NAME = 'clients';
const CREATE = 0;
const VIEW = 1;
const EDIT = 2;
const VIEW_DISABLED = 3;
const DISABLE = 4;
const DELETE = 5;
const IMPORT = 6;
const EXPORT = 7;

export default {
	PAGE_NAME,
	NAME,
	CLIENTS,
	CREATE,
	VIEW,
	EDIT,
	VIEW_DISABLED,
	DISABLE,
	DELETE,
	IMPORT,
	EXPORT,
};
