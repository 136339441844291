import {
	checkTaskTypeFunctionalityPartnerRequest,
	checkTaskTypeFunctionalityRequest,
	getTaskTypesPartnerRequest,
	getTaskTypesRequest,
	getAllTaskTypesRequest,
	getTableTaskTypesRequest,
} from '@core/api/taskTypes/taskTypes.service';
import { arrayToTree } from 'performant-array-to-tree';

const request = require('FielderRequestTypes');
const LTT = require('list-to-tree');

export const hasTaskTypeFunctionality = (functionalities) =>
	functionalities.some((func) => {
		if (func.functionality.customCode === 'TASK_TYPE') {
			return true;
		}
	});

export const hasTaskTypeDynamoTable = async () => {
	const onSuccess = ({ tableExist }) => tableExist;
	const onError = () => false;
	return await checkTaskTypeFunctionalityRequest(onSuccess, onError);
};

export const hasTaskTypeDynamoTablePartner = async (idAccountPartner, idUserPartner) => {
	const body = {
		idAccount: idAccountPartner,
		userId: idUserPartner,
	};

	const onSuccess = ({ tableExist }) => tableExist;
	const onError = () => false;
	return await checkTaskTypeFunctionalityPartnerRequest(body, onSuccess, onError);
};

/**
 * This Function makes the request to get the list of task types
 * from a specific account
 */
export const getAllTaskTypeList = (callback, props) => {


	//let body = request.getRequestGetAllTaskTypes();

	const onSuccess = (response) => {
		let { taskTypes } = response;

		if (taskTypes.length > 0) {
			let formatTypes = [];

			taskTypes.forEach((element) => {
				const hours = Math.floor(element.time / 3600);
				const minutes = Math.floor((element.time - hours * 3600) / 60);
				if (element.id <= 4) {
					let type = element.name.toLowerCase().replace(/\s+/g, '');
					if (type === 'simple') {
						type = 'groupTask';
						element.name = 'groupTask';
					}

					formatTypes.push({
						id: element.id,
						name: props.lan[type],
						hours: `${hours} hrs`,
						minutes: `${minutes} min`,
						status: element.status,
						parent: element.parent,
					});
				} else {
					formatTypes.push({
						id: element.id,
						name: element.name,
						hours: `${hours} hrs`,
						minutes: `${minutes} min`,
						status: element.status,
						parent: element.parent,
					});
				}
			});
			formatTypes = formatTypes.sort((a, b) => a.id - b.id);
			taskTypes = taskTypes.sort((a, b) => a.id - b.id);

			callback(formatTypes, taskTypes);
		}
	}

	const onError = (error) => {
		console.log(error)
	}

 	getAllTaskTypesRequest(null, onSuccess, onError,null)
};

export const getAllTaskTypeListPartner = (callback, props, idAccountPartner, idUserPartner) => {
	const body = {
		idAccount: idAccountPartner,
		userId: idUserPartner,
	};

	const onSuccess = ({ taskTypes }) => {
		if (taskTypes.length > 0) {
			let formatTypes = [];

			taskTypes.forEach((element) => {
				const hours = Math.floor(element.time / 3600);
				const minutes = Math.floor((element.time - hours * 3600) / 60);
				if (element.id <= 4) {
					let type = element.name.toLowerCase().replace(/\s+/g, '');
					if (type === 'simple') {
						type = 'groupTask';
						element.name = 'groupTask';
					}

					formatTypes.push({
						id: element.id,
						name: props.lan[type],
						hours: `${hours} hrs`,
						minutes: `${minutes} min`,
						status: element.status,
						parent: element.parent,
					});
				} else {
					formatTypes.push({
						id: element.id,
						name: element.name,
						hours: `${hours} hrs`,
						minutes: `${minutes} min`,
						status: element.status,
						parent: element.parent,
					});
				}
			});
			formatTypes = formatTypes.sort((a, b) => a.id - b.id);
			taskTypes = taskTypes.sort((a, b) => a.id - b.id);

			callback(formatTypes, taskTypes);
		}
	};

	const onError = (error) => {
		console.log(error)
	}

	getTaskTypesPartnerRequest(body, onSuccess, onError);
};

const getTaskTypes = (callback, props, body, all) => {
	const onSuccess = ({ taskTypes }) => {
		if (taskTypes.length > 0) {
			let formatTypes = [];
			taskTypes.forEach((element) => {
				const hours = Math.floor(element.time / 3600);
				const minutes = Math.floor((element.time - hours * 3600) / 60);

				if (all || element.status) {
					if (element.id <= 3) {
						const type = element.name.toLowerCase().replace(/\s+/g, '');
						formatTypes.push({
							id: element.id,
							name: props.lan[type],
							hours: `${hours} hrs`,
							minutes: `${minutes} min`,
							status: element.status,
							hideToAgent: element.hideToAgent,
							hideToClient: element.hideToClient,
							parent: element.parent,
							routeVariables: element.routeVariables,
						});
					} else if (element.id != 4) {
						formatTypes.push({
							id: element.id,
							name: element.name,
							hours: `${hours} hrs`,
							minutes: `${minutes} min`,
							status: element.status,
							hideToAgent: element.hideToAgent,
							hideToClient: element.hideToClient,
							parent: element.parent,
							routeVariables: element.routeVariables,
							origins: element.origins,
							originIds: element.originIds,
						});
					}
				}
			});

			formatTypes = formatTypes.sort((a, b) => a.id - b.id);
			taskTypes = taskTypes.sort((a, b) => a.id - b.id);
			callback(formatTypes, taskTypes);
		}
	};
	getTaskTypesRequest(body, onSuccess);
};

const formatTaskTypes = (taskTypes, props, all) => {
	if (!taskTypes) {
		return [];
	}

	let formatTypes = [];
	taskTypes.forEach((element) => {
		const hours = Math.floor(element.time / 3600);
		const minutes = Math.floor((element.time - hours * 3600) / 60);
		if (all || element.status) {
			if (element.id <= 3) {
				const type = element.name.toLowerCase().replace(/\s+/g, '');
				formatTypes.push({
					id: element.id,
					name: props.lan[type],
					hours: `${hours} hrs`,
					minutes: `${minutes} min`,
					status: element.status,
					hideToAgent: element.hideToAgent,
					hideToClient: element.hideToClient,
					parent: element.parent,
					routeVariables: element.routeVariables,
					price: element.price
				});
			} else if (element.id != 4) {
				formatTypes.push({
					id: element.id,
					name: element.name,
					hours: `${hours} hrs`,
					minutes: `${minutes} min`,
					status: element.status,
					hideToAgent: element.hideToAgent,
					hideToClient: element.hideToClient,
					parent: element.parent,
					routeVariables: element.routeVariables,
					origins: element.origins,
					originIds: element.originIds,
					price: element.price
				});
			}
		}
	});

	return { formatTypes, taskTypes };
};

export const getTaskTypeList = (status, props, all) => {
	const onSuccess = ({ taskTypes }) => formatTaskTypes(taskTypes, props, all);
	const body = { status };
	return getTaskTypesRequest(body, onSuccess);
};

export const getTableTaskTypeList = (status, props, all) => {
	const onSuccess = ({ taskTypes }) => formatTaskTypes(taskTypes, props, all);
	const body = { status };
	return getTableTaskTypesRequest(body, onSuccess);
};

export const getTaskTypeListFilteredBy = (callback, props, idBranch, idClient) => {
	let body = {
		requestType: 'GET_FILTERED_TASK_TYPE_ELEMENTS',
		idBranch: idBranch,
		idClient: idClient,
	}

	getTaskTypes(callback, props, body, false);
};

/**
 * This function converts a task type List (retrieved from dynamo)
 * into a JSON hierarchy tree
 *
 * @param {array} list - List of tasktypes to be converted
 *
 * @returns - JSON with hierarchy
 */
export const listToTree = (list) => {
	list.forEach((el) => {
		if (el.parent === 0) {
			el.parent = null;
		}
		el.key = el.id;
		el.value = el.id;
		el.title = el.name;
	});

	let tree = arrayToTree(list, {
		id: 'id',
		parentId: 'parent',
		childrenField: 'children',
		dataField: null,
	});

	tree.forEach((element) => {
		element.parent = 0;
	});

	return tree;
};

export const listToTreeTable = (list) => {
	let map = {};
	let node;
	let roots = [];
	let i;

	for (i = 0; i < list.length; i++) {
		map[list[i].id] = i; // initialize the map
		list[i].children = []; // initialize the children
	}

	for (i = 0; i < list.length; i++) {
		node = list[i];

		const new_node = {};
		const tempData = {};
		for (const key in node) {
			if (key != 'children') {
				tempData[key] = node[key];
			}
		}

		new_node.data = tempData;
		new_node.children = node.children;
		new_node.height = 55;

		if (node.parent !== null) {
			// if you have dangling branches check that map[node.parentId] exists
			list[map[node.parent]].children.push(new_node);
		} else {
			roots.push(new_node);
		}
	}
	return roots;
};

export const cutTreeLevel = (tree, level) => {
	const x = cutTree(tree, level, 0);
	return x;
};

const cutTree = (tree, level, current) => {
	tree.forEach((leave) => {
		if (current >= level || !leave.children) {
			leave.children = [];
		} else {
			leave.children = cutTree(leave.children, level, current + 1);
		}
		return leave;
	});
};

export const getTaskTypeParents = (taskTypeId, taskTypes) => {
	let parentArray;

	taskTypes.some((type) => {
		const result = getParent(type, taskTypeId);

		if (Array.isArray(result)) {
			parentArray = [`${type.name} `].concat(result);
			return true;
		}
	});

	return parentArray;
};

const getParent = (node, taskTypeId) => {
	if (node.id === taskTypeId) {
		return [];
	}

	if (node.children.length > 0) {
		for (let child of node.children) {
			const childResult = getParent(child, taskTypeId);
			if (Array.isArray(childResult)) {
				return [child.name].concat(childResult);
			}
		}
	}
};

export const getTaskTypeParentIds = (taskTypeId, taskTypes) => {
	let parentArray;

	taskTypes.some((type) => {
		const result = getParentId(type, taskTypeId);

		if (Array.isArray(result)) {
			parentArray = [type.id].concat(result);
			return true;
		}
	});

	return parentArray;
};

const getParentId = (node, taskTypeId) => {
	if (node.id === taskTypeId) {
		return [];
	}

	if (node.children.length > 0) {
		for (let child of node.children) {
			const childResult = getParent(child, taskTypeId);
			if (Array.isArray(childResult)) {
				return [child.id].concat(childResult);
			}
		}
	}
};

export const getAccountTaskTypes = async (accountPreferences, idBranch, idClient, setLoading, lan, callback) => {
	getTaskTypeListFilteredBy(
		(formatTypes, taskTypes) => {
			const types = listToTree(formatTypes);
			const taskTypeSelected = taskTypes[0];
			const hasRouteVariables = checkTaskTypeRouteVariables(accountPreferences, taskTypes, taskTypeSelected);

			const accountTaskTypes = {
				taskTypes: types,
				taskTypeList: taskTypes,
				taskTypeSelected: taskTypeSelected,
			};

			callback(accountTaskTypes, hasRouteVariables);
		},
		{ setLoading, lan },
		idBranch,
		idClient
	);
};

export const checkTaskTypeRouteVariables = (accountPreferences, taskTypes, taskTypeSelected) => {
	let hasRoute = false;
	if (accountPreferences.showDynamicAllocation || accountPreferences.showResourceAlloc) {
		taskTypes.some((type) => {
			if (type.id === taskTypeSelected.id) {
				hasRoute = type.routeVariables != null && type.routeVariables.length > 0;
				return true;
			}
			return false;
		});
	}
	return hasRoute;
};
