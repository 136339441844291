import React, { useEffect, useState } from 'react';

import ErrorIcon from '@assets/icons/alert-icon.svg';
import WarningIcon from '@assets/icons/alert-icon.svg';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import { Grid, Typography }from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import Popup from 'OldComponents/Popup';
import { useSelector, useDispatch } from 'react-redux';

import { selectAlertMessage } from '../../core/store/modules/message/messageSelectors';
import { setRemoveMessages } from '../../core/store/modules/message/messageSlice';

const AlertMessage = () => {
	const lan = useLanguage();
	const alertMessage = useSelector(selectAlertMessage);
	const dispatch = useDispatch();

	const [variant, setVariant] = useState('warn');
	const [icon, setIcon] = useState(null);
	const [onClose, setOnClose] = useState(null);
	const [text, setText] = useState(null);

	useEffect(() => {
		setText(alertMessage?.text ? alertMessage.text : null);
		setIcon(alertMessage?.icon ? alertMessage.icon : null);
		if (alertMessage?.variant && !['success', 'warn', 'error'].includes(alertMessage.variant)) {
			setVariant('warn');
			console.warn(
				'FIELDER WARNING',
				'Alert message should only include one of [success, warn, error] variants'
			);
		} else {
			setVariant(alertMessage?.variant ? alertMessage?.variant : 'warn');
		}

		setOnClose(alertMessage.onClose);
	}, [alertMessage]);

	const defaultIcons = {
		warn: <img src={WarningIcon} alt='' />,
		success: <img src={SuccessIcon} alt='' />,
		error: <img src={ErrorIcon} alt='' />,
	};

	const MessageIcon = () => {
		if (variant || variant in defaultIcons) {
			return defaultIcons[variant];
		}

		return icon;
	};

	const closeMessage = () => {
		if (onClose) {
			onClose();
		} else {
			dispatch(setRemoveMessages());
		}
	};

	return (
		<Popup
			centered
			width='sm'
			onClose={closeMessage}
			closeButton
			buttonText={lan.accept}
			buttonOnClick={closeMessage}
		>
			<Grid container direction='column' justifyContent='center' spacing={3}>
				<MessageIcon />
				<Grid item>
					<Typography align='center'>{text}</Typography>
				</Grid>
			</Grid>
		</Popup>
	);
};

export default AlertMessage;
