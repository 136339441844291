// ROLE MANAGEMENT
const GROUPTASK = 'grouptasks';
const PAGE_NAME = 'GROUP_TASKS';
const NAME = 'grouptasks';
const CREATE = 0;
const VIEW = 1;
const ADD_TASKS_TO_GROUP = 2;
const UNLINK = 3;
const EDIT = 4;
const DOWNLOAD_TASK_IMAGES = 5;
const EXPORT_TASKS = 6;
const DELETE = 7;

export default {
	PAGE_NAME,
	NAME,
	GROUPTASK,
	CREATE,
	VIEW,
	ADD_TASKS_TO_GROUP,
	UNLINK,
	EDIT,
	DOWNLOAD_TASK_IMAGES,
	EXPORT_TASKS,
	DELETE,
};
