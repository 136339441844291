export const isOwner = (user, task) => user.idAccount === task.idAccount;

export const isClient = (user, task) => user.idUser === task.idUser;

export const isExecutor = (user, task) => {
	if (task.partnerTaskRelations && hasPartnerRelation(task)) {
		const lastElement = task.partnerTaskRelations.length - 1;
		const lastRelation = task.partnerTaskRelations[lastElement];
		return lastRelation.idAccountPartner === user.idAccount;
	}

	return false;
};

export const isLastOwner = (user, task) => {
	if (task.partnerTaskRelations && hasPartnerRelation(task)) {
		const lastElement = task.partnerTaskRelations.length - 1;
		const lastRelation = task.partnerTaskRelations[lastElement];
		return lastRelation.idAccountOwner === user.idAccount;
	}

	return false;
};

export const hasPartnerRelation = (task) => task?.partnerTaskRelations?.length > 0;
