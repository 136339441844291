const ACTIVITY = {
	DO_ACTION_UNKNOWN_TASK: 0,  		
	DO_ACTION_CREATE_TASK: 1,			
	DO_ACTION_UPDATE_TASK: 2,			
	DO_ACTION_UPDATE_ADDRESS_TASK: 3, 	
	DO_ACTION_RESCHEDULE_TASK: 4,		
	DO_ACTION_ASSIGN_AGENT_TASK: 5, 	
	DO_ACTION_ASSIGN_TEAM_TASK: 6,  	
	DO_ACTION_ASSIGN_PARTNER_TASK: 7,  	
	DO_ACTION_CANCEL_TASK: 8,				
	DO_ACTION_CANCEL_PARTNER_TASK: 9,		
	DO_ACTION_RATE_TASK: 10,					
	DO_ACTION_RESTART_TASK: 11,			
	DO_ACTION_TRASH_TASK: 12,			
	DO_ACTION_DELETE_TASK: 13,			
	DO_ACTION_CANCEL_TRASH_TASK: 14,	
	DO_START_TASK_AGENT: 15,			
	DO_ARRIVE_TASK_AGENT: 16,			
	DO_ON_WAY_TASK_AGENT: 17,			
	DO_REJECT_TASK_AGENT: 18,			
	DO_FINISH_TASK_AGENT: 19,			
	DO_CANCEL_TASK_AGENT: 20,			
	DO_ACCEPT_TASK_AGENT: 21,			
	DO_ADD_COMMENT: 22,					
	DO_ACTION_ASSIGN_GROUP: 23,			
	DO_ACTION_ASSIGN_ASSET: 25,			
	DO_ACTION_ASSIGN_INVENTORY: 26,		
	DO_ACTION_AUTOASSIGNING_STARTED: 27, 
	DO_ACTION_AUTOASSIGNING_FAILED: 29,
	DO_ACTION_PAUSED_TASK: 30,			
	DO_ACTION_REFUSE_GROUP: 31,			// Agregar nombre del grupo Nota: aparece dos veces
	DO_ACTION_RESET_EXECUTION_TASK: 32,	
	DO_ACTION_RESET_CANCELED_TASK: 33,		
	DO_ACTION_UNLINK_TASK: 34,				 
	DO_ACTION_REMOVE_TASK_FROM_ROUTE: 35,	 
	DO_ACTION_ADD_TASK_TO_ROUTE: 36,	

	DO_ACTION_UPLOAD_TASK_DOCUMENT: 37,		
	DO_ACTION_DELETE_TASK_DOCUMENT: 38,		
	DO_ACTION_UPDATE_TASK_DOCUMENT: 39,		

	DO_ACTION_UPLOAD_TASK_LINK_DOCUMENT: 40,  
	DO_ACTION_DELETE_TASK_LINK__DOCUMENT: 41,
	DO_ACTION_UPDATE_TASK_LINK__DOCUMENT: 42,

	DO_ACTION_UPLOAD_TASK_PDF_FORM: 43,			
	DO_ACTION_UPDATE_TASK_PDF_FORM: 44,			

	DO_ACTION_FINISH_FORM:48,			
	DO_ACTION_REVIEW_FORM: 49,
	DO_ACTION_APPROVED_FORM:57,

	// Forms Activity
	DO_ACTION_ASSIGN_FORM: 24, 	
	DO_ACTION_UPDATED_FORM: 50, // Agregar nombre del formulario  ISSTE-123 [1,5,6 ...]	

	// Asset Activity
	DO_ACTION_UPDATED_ASSET: 51, // Agregar nombre del formulario  ISSTE-123 [1,5,6 ...]
	// Inventory Activity
	DO_ACTION_UPDATED_INVENTORY: 52,// Agregar nombre del formulario  ISSTE-123 [1,5,6 ...]
	
	DO_ACTION_ADMIN_MODIFY_TASK:53,
	DO_ACTION_MANAGER_MODIFY_TASK:54,

	DO_TAKE_TASK_ON_GROUP:55  
};

export default ACTIVITY;
