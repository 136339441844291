import CookieName from '@core/constants/cookie/cookieNameConst';

export const getObjectCookie = (cookieName, cookieObject) => {
	const body = {
		filterCookie: {
			cookieName,
		},
	};
	switch (cookieName) {
		case CookieName.MY_OPERATION_TASK_TABLE:
		case CookieName.DASHBOARD_TASK_SLA_TABLE:
			const cookiesRequest = [];
			Object.keys(cookieObject).map((key) => {
				cookiesRequest.push({ label: key, active: cookieObject[key] });
			});
			body.filterCookie.cookieTable = cookiesRequest;
			break;
		case CookieName.MY_OPERATION_TASK_TABLE_AVANCED_FILTER:
			body.filterCookie.avancedFiltersCookie = cookieObject;
			break;
		case CookieName.DASHBOARD_TASK_SUMMARY_FILTER:
			body.filterCookie.dashboardSummaryTaskCookie = cookieObject;
			break;
		default:
			break;
	}
	return body;
};
