export const exportPopupList = () => {
	return {
		rescheduleTask: false,
		taskdetail: false,	
		cancelTask: false,	
		createGroupTask: false,
		editGroupTask: false,
		assignGroupTask: false,
		routedetail: false,
		assignAgent: false,
		assignTeam: false,
		assignPartner: false,
		reAssignPartner: false,
		assignAssets: false,
		assignInventories: false,
		assignForms: false,
		rateTask: false,
		generateRoute: false,
		duplicateTask: false,
		parenttaskdetail: false,
		cancelRoute: false,
		resetRouteTask: false,
		resetCanceledTask: false,
		restartRoute: false,
		reassignRouteAgent: false,
		rescheduleRoute: false,		
		assignResources: false,	
		importTask: false,
		errormessage: false,
		successmessage: false,
		editTask: false,
		agentdetail: false,
		
		
	}
};