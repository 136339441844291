/* eslint-disable max-lines */

import { getCommandCenterAgents } from '@core/api/agents/agents.commandCenter.service';
import { getCommandCenterTask } from '@core/api/task/task';
import RealTime from '@core/constants/realTime/actions.constants';
import {
	taskActions as TaskRealTime,
	agentActions as AgentRealTime,
} from '@core/constants/realTime/pages/commandCenter';
import { sortAgentsByStatus, updateAgentsStatusCount } from '@core/utils/agent/agentCommandCenter';
import insertSorted from '@core/utils/insertionAlgorithms/insertSorted';
import { binarySearchIndex } from '@core/utils/searchAlgorithms/binarySearch';
import { getTasksQueryParams } from '@core/utils/task/taskCommandCenter';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Adds the requested task from the complete task list, and adjusts the
 * number of tasks, and status analytics
 *
 * @param {int} idTask - Id of the task to remove
 * @param {*} commandCenter - Command Center state
 * @param {*} user - User information for the request
 * @returns {array} Updated array of tasks or  "-1" if shouldn't update
 *
 */
const taskAdd = (idTask, commandCenter, user) => {
	const { tasks, view, filters } = commandCenter;
	const { selectedAgents, assignTask } = view;
	const newTasks = cloneDeep(tasks);

	const binarySearchOptions = {
		customComparisonValue: (element) => element.idTask,
		reversed: true,
	};

	// Binary Search for the Task, if exist, dont add, just update the old tasks
	const updatedTaskIndex = binarySearchIndex(tasks.list, idTask, binarySearchOptions);
	if (updatedTaskIndex !== -1) {
		taskInfoUpdate(idTask, commandCenter, user);
		return;
	}

	const onSuccess = (res) => {
		// If not returned task, maybe not in the filter, do nothing
		if (res.task !== null) {
			// Search if should add at the beginning
			if (tasks.list.length === 0 || idTask > tasks.list[0].idTask) {
				newTasks.list = [res.task, ...newTasks.list];
			} else {
				// Case Task is enabled
				const options = {
					reversed: true,
					customComparisonValue: (el) => el.idTask,
				};

				// Add at position (binary Search tasks)
				newTasks.list = insertSorted(res.task, newTasks.list, options);
			}
		}

		// Add the required values for the command center
		newTasks.commandCenterTasks = newTasks.list;
		newTasks.tasksAnalytics = res.tasksAnalytics;
		newTasks.totalCount = res.totalCount;
		return newTasks;

		// Search if should render (first and last)
	};
	const onError = () => {};

	// If it goes more than the last element, dont create
	if (tasks.list.length > 0 && idTask < tasks.list[tasks.list.length - 1].idTask) {
		return -1;
	}

	const requestFilters = getTasksQueryParams(filters, selectedAgents, assignTask, user);
	return getCommandCenterTask(idTask, requestFilters, onSuccess, onError);
};

/**
 * Removes the requested task from the complete task list, and adjusts the
 * number of tasks, and status analytics
 *
 * @param {int} idTask - Id of the task to remove
 * @param {*} commandCenter - Command Center state
 * @returns {array} Updated array of tasks or  "-1" if shouldn't update
 *
 */
const taskDelete = (idTask, commandCenter) => {
	const { tasks } = commandCenter;

	const binarySearchOptions = {
		customComparisonValue: (element) => element.idTask,
		reversed: true,
	};

	// Binary Search for the Task
	const updatedTaskIndex = binarySearchIndex(tasks.list, idTask, binarySearchOptions);
	if (updatedTaskIndex === -1) return -1;

	const newTasks = cloneDeep(tasks);
	newTasks.status[newTasks.list[updatedTaskIndex].status] -= 1;
	newTasks.list.splice(updatedTaskIndex, 1);

	// Add the required values for the command center
	newTasks.commandCenterTasks = newTasks.list;
	newTasks.tasksAnalytics = newTasks.status;
	newTasks.totalCount -= 1;
	return newTasks;
};

/**
 * Updates the requested task inside the task list
 *
 * @param {int} idTask - Id of the task to remove
 * @param {*} commandCenter - Command Center state
 * @param {*} user - User information for the request
 *
 * @returns {array} Updated array of tasks or  "-1" if shouldn't update
 *
 */
const taskInfoUpdate = (idTask, commandCenter, user) => {
	const { tasks, view, filters } = commandCenter;
	const { selectedAgents, assignTask } = view;

	const binarySearchOptions = {
		customComparisonValue: (element) => element.idTask,
		reversed: true,
	};

	// Binary Search for the Task
	const updatedTaskIndex = binarySearchIndex(tasks.list, idTask, binarySearchOptions);
	if (updatedTaskIndex === -1 && !(view.showAgentDetail && view.selectedAgents.length === 1)) return -1;

	const newTasks = cloneDeep(tasks);
	// Request single task
	const onSuccess = (res) => {
		if (res.task === null) {
			// If task doesn't exist, it must not be present on the filter, therefore delete
			newTasks.list.splice(updatedTaskIndex, 1);
		} else {
			const { task } = res;

			// if rescheduled to other date
			if (
				filters.startDate > task.scheduledDate ||
				filters.endDate < task.scheduledDate ||
				filters.startDate > task.startExecutionPeriod ||
				filters.endDate < task.startExecutionPeriod
			) {
				taskDelete(idTask, commandCenter);
			}

			if (updatedTaskIndex === -1) {
				newTasks.list.push(task);
			} else {
				newTasks.list[updatedTaskIndex] = task;
			}
		}

		// Add the required values for the command center
		newTasks.commandCenterTasks = newTasks.list;
		newTasks.tasksAnalytics = res.tasksAnalytics;
		newTasks.totalCount = res.totalCount;
		return newTasks;
	};

	const onError = (error) => {
		console.error('error', error);
		return -1;
	};

	const requestFilters = getTasksQueryParams(filters, selectedAgents, assignTask, user);
	return getCommandCenterTask(idTask, requestFilters, onSuccess, onError);
};

const taskRescheduleUpdate = (idTask, commandCenter, user) => {
	const { tasks, view, filters } = commandCenter;
	const { selectedAgents, assignTask } = view;

	const binarySearchOptions = {
		customComparisonValue: (element) => element.idTask,
		reversed: true,
	};

	// Binary Search for the Task
	const updatedTaskIndex = binarySearchIndex(tasks.list, idTask, binarySearchOptions);

	const newTasks = cloneDeep(tasks);
	// Request single task
	const onSuccess = (res) => {
		const { task } = res;

		if (task === null) {
			if (updatedTaskIndex === -1) return -1;
			// If task doesn't exist, it must not be present on the filter, therefore delete
			newTasks.list.splice(updatedTaskIndex, 1);
		} else if (tasks.list.length === 0 || idTask > tasks.list[0].idTask) {
			newTasks.list = [task, ...newTasks.list];
		} else {
			// Case Task is enabled
			const options = {
				reversed: true,
				customComparisonValue: (el) => el.idTask,
			};

			// Add at position (binary Search tasks)
			newTasks.list = insertSorted(task, newTasks.list, options);
		}

		// Add the required values for the command center
		newTasks.commandCenterTasks = newTasks.list;
		newTasks.tasksAnalytics = res.tasksAnalytics;
		newTasks.totalCount = res.totalCount;
		return newTasks;
	};

	const onError = (error) => {
		console.error('error', error);
		return -1;
	};

	const requestFilters = getTasksQueryParams(filters, selectedAgents, assignTask, user);
	return getCommandCenterTask(idTask, requestFilters, onSuccess, onError);
};

export const taskHandler = async (message, commandCenter, user) => {
	const { action } = message;

	// If new Task was added
	if (TaskRealTime.add.has(action)) {
		return taskAdd(message.idTask, commandCenter, user);
	}

	// Task Info updated
	if (TaskRealTime.update.has(action)) {
		if (action === RealTime.TASK_RESCHEDULE) {
			return taskRescheduleUpdate(message.idTask, commandCenter, user);
		}
		return taskInfoUpdate(message.idTask, commandCenter, user);
	}

	// Task was removed
	if (TaskRealTime.delete.has(action)) {
		return taskDelete(message.idTask, commandCenter);
	}

	return -1;
};

const searchAndUpdateAgent = (agents, filters, agentId) => {
	const agentBinarySearchOptions = {
		customComparisonValue: (element) => element.id,
	};

	// Binary Search for the Agent
	const agentIndex = binarySearchIndex(agents.orderedList, agentId, agentBinarySearchOptions);

	// If agent not found, ignore
	if (agentIndex === -1) return -1;

	const newAgents = cloneDeep(agents);

	// Request single task
	const onSuccess = ({ agent }) => {
		if (agent === null) {
			// If task doesn't exist, it must not be present on the filter, therefore delete
			newAgents.orderedList.splice(agentIndex, 1);
			newAgents.totalCount -= 1;
		} else {
			newAgents.orderedList[agentIndex] = agent;
		}

		// Add the required values for the command center
		newAgents.status = updateAgentsStatusCount(
			newAgents.status,
			newAgents.list[agentIndex],
			agent,
			newAgents.totalCount
		);
		newAgents.list = sortAgentsByStatus(newAgents.orderedList);

		if (newAgents?.singleAgent?.id === agent.id) {
			newAgents.singleAgent = agent
		}

		return newAgents;
	};

	const onError = (error) => {
		console.error('error', error);
		return -1;
	};

	const { startDate, endDate } = filters;
	return getCommandCenterAgents({ startDate, endDate }, agentId, onSuccess, onError);
};

const searchAndUpdateAgents = (agents, filters, agentIds) => {
	/* eslint-disable no-param-reassign */
	const agentBinarySearchOptions = {
		customComparisonValue: (element) => element.id,
	};

	// Request single task
	const onSuccess = (agentIndex) => (res) => {
		if (res.agent === null) {
			// If agent doesn't exist, it must not be present on the filter, therefore delete
			agents.orderedList.splice(agentIndex, 1);
			agents.totalCount -= 1;
		} else {
			const orderedList = JSON.parse(JSON.stringify(agents.orderedList));
			orderedList[agentIndex] = res.agent;
			agents.orderedList = orderedList;
		}

		// Add the required values for the command center
		agents.status = updateAgentsStatusCount(agents.status, agents.list[agentIndex], res.agent, agents.totalCount);
	};

	const onError = (error) => {
		console.error('error', error);
		return -1;
	};

	return new Promise((res) => {
		agentIds.forEach(async (agentId, i) => {
			// Binary Search for the Agent
			const agentIndex = binarySearchIndex(agents.orderedList, agentId, agentBinarySearchOptions);

			// If agent not found, ignore
			if (agentIndex === -1) return;

			const { startDate, endDate } = filters;
			await getCommandCenterAgents({ startDate, endDate }, agentId, onSuccess(agentIndex), onError);
			agents.list = sortAgentsByStatus(agents.orderedList);

			if (i === agentIds.length - 1) res(agents);
		});
	});
};

/**
 * Updates the requested task inside the task list
 *
 * @param {int} idTask - Id of the task to remove
 * @param {*} commandCenter - Command Center state
 * @param {*} user - User information for the request
 *
 * @returns {array} Updated array of tasks or  "-1" if shouldn't update
 *
 */
const agentUpdate = (agentId, commandCenter, action) => {
	const { agents, view, filters } = commandCenter;

	// If not in agent detail, dont show realtime location update
	if (RealTime.AGENT_POSITION_UPDATE === action && !view.showAgentDetail && view.selectedAgents.length !== 1) {
		return -1;
	}

	return searchAndUpdateAgent(cloneDeep(agents), filters, agentId);
};

const agentsUpdate = async (agentsId, commandCenter) => {
	const { agents, filters } = commandCenter;
	return searchAndUpdateAgents(cloneDeep(agents), filters, agentsId);
};

const agentAddTask = async (agentId, taskId, previousAgentId, commandCenter) => {
	const { agents, filters } = commandCenter;

	let updatedAgents = cloneDeep(agents);

	// if the task had agent, remove the task from that agent
	if (previousAgentId && previousAgentId > 0) {
		const tempUpdatedAgents = await searchAndUpdateAgent(agents, filters, previousAgentId);

		if (tempUpdatedAgents !== -1) {
			updatedAgents = tempUpdatedAgents;
		}
	}

	// Update the assigned agent count
	const agentsWithAssignedAgentUpdated = searchAndUpdateAgent(updatedAgents, filters, agentId);

	if (agentsWithAssignedAgentUpdated === -1) {
		return updatedAgents;
	}

	return agentsWithAssignedAgentUpdated;
};

const agentRemoveTask = async (agentId, commandCenter) => {
	const { agents, filters } = commandCenter;

	// Update the assigned agent count
	return searchAndUpdateAgent(agents, filters, agentId);
};

export const agentHandler = (message, commandCenter, user) => {
	const { action } = message;

	// Agent Info updated
	if (AgentRealTime.update.has(action)) {
		return agentUpdate(message.idUser, commandCenter, action);
	}

	if (AgentRealTime.updateSeveral.has(action)) {
		return agentsUpdate(message.idAgents, commandCenter);
	}

	if (AgentRealTime.taskAdd.has(action)) {
		return agentAddTask(message.idAgent, message.idTask, message.previousIdAgent, commandCenter);
	}

	if (AgentRealTime.taskRemove.has(action)) {
		const agentId = message.idAgent ? message.idAgent : message.idUser;
		return agentRemoveTask(agentId, commandCenter);
	}

	return -1;
};
