import React, { useState } from 'react';
import AddAgentsIcon from '@assets/icons/add-agent-icon.svg';
import AssetsIcon from '@assets/icons/assets-icon.svg';
import AssingGroupTask from '@assets/icons/assing-group-task-icon.svg';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import CancelTaskIcon from '@assets/icons/cancel-task-icon.svg';
import CreateGroupTask from '@assets/icons/create-group-tasks.svg';
import DetailTaskIcon from '@assets/icons/detalle-task-icon.svg';
import EditGroupTaskIcon from '@assets/icons/edit-group-tasks-icon.svg';
import FormIcon from '@assets/icons/form-icon.svg';
import InventoryIcon from '@assets/icons/inventory-icon.svg';
import ParentTaskIcon from '@assets/icons/parent-task-icon.svg';
import PartnersIcon from '@assets/icons/partners-icon.svg';
import Reload from '@assets/icons/reload.svg';
import reviewTaskIcon from '@assets/icons/review-task-icon.svg';
import RouteIcon from '@assets/icons/route-icon.svg';
import TeamsIcon from '@assets/icons/teams-icon.svg';
import UndoIcon from '@assets/icons/undo-icon.svg';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import AutoAssignTaskIcon from '@assets/icons/autoassign-icon.svg';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import FormConst from 'FielderUtils/roleManagementConst/formConst';
import InventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import AssetConst from 'FielderUtils/roleManagementConst/assetConst';
import RouteOptimizationConst from 'FielderUtils/roleManagementConst/routeOptimizationConst';
import GroupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import TaskConst from 'FielderUtils/roleManagementConst/taskConst';
import { isRoleValid } from '../../../core/utils/roles/validateRoles';
import { useSelector } from 'react-redux';
import { selectSession } from '../../../core/store/modules/session/sessionSelectors';
import { useLanguage } from 'Context/LanguageContext';
import { validatePermissions } from 'FielderUtils/validatePermissions';
import POPUPNAME from 'FielderUtils/popupName/popupName';
import { useDispatch } from 'react-redux';
import { reAutoAssignedRequest } from '@core/api/task/task.service';
import { autoAssignTaskStatus } from 'FielderUtils/task/modifyStatus';
const TaskActionPopup = (props) => {
  const dispatch = useDispatch();
	const lan = useLanguage();
	const session = useSelector(selectSession);
	const showForms = session.accountPreferences ? session.accountPreferences.showForms : false;
	const showInventory = session.accountPreferences ? session.accountPreferences.showInventory : false;
	const showAsset = session.accountPreferences ? session.accountPreferences.showAsset : false;
	const showRouteOpt = session.accountPreferences ? session.accountPreferences.showRouteOpt : false; 
	const canAddTasksToGroup = isRoleValid(GroupTaskConst.GROUPTASK, GroupTaskConst.ADD_TASKS_TO_GROUP, session.roles);
	const canRescheduleTask = isRoleValid(TaskConst.TASKS,	TaskConst.RESCHEDULE, session.roles);
	const canCancelTasks = isRoleValid(TaskConst.TASKS, TaskConst.CANCEL, session.roles);
	const canCreateGroupTasks = isRoleValid(GroupTaskConst.GROUPTASK, GroupTaskConst.CREATE, session.roles);
	const canEditGroupTasks = isRoleValid(GroupTaskConst.GROUPTASK, GroupTaskConst.EDIT, session.roles);
	const canGenerateRouteOptimization = isRoleValid(RouteOptimizationConst.ROUTE_OPTIMIZATION,	RouteOptimizationConst.GENERATE, session.roles);
	const canAssignAgent = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_AGENT, session.roles);
	const canAssignTeams = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_TEAMS, session.roles);
	const canAssignPartners = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_PARTNERS, session.roles);
	const canAssignAssets = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_ASSETS, session.roles);
	const canViewAssets = isRoleValid(AssetConst.ASSETS, AssetConst.VIEW, session.roles);
	const canAssignInventory = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_INVENTORY, session.roles);
	const canViewInventory = isRoleValid(InventoryConst.INVENTORY, InventoryConst.VIEW, session.roles);
	const canAssignForms = isRoleValid(TaskConst.TASKS, TaskConst.ASSIGN_FORMS, session.roles);
	const canViewForms = isRoleValid(FormConst.FORMS, FormConst.VIEW_TEMPLATES, session.roles);
  const getSelectedId = (rows) => {
		if (!rows) return [];
		return rows.map((row) => row.taskId);
	};
  const handleReAutoAssignedRequest = () => {
    var idTasks = getSelectedId(props.state.markers);
    const body = {
      tasks: idTasks,
    };
    console.log('body: ', body)
    const loadingConfig = { dispatch }
    const onSuccess = () => {
      props.closeTaskAction();
    };
    const onError = ({ status }) => {
      const message = autoAssignTaskStatus(status, lan);
      props.fielderMsg({
        message: message,
        variant: 'error',
        closeButton: true,
        duration: 5000
      });
    }
    reAutoAssignedRequest(body, onSuccess, onError, loadingConfig);
  }
  const clickAutoAssignTask = () => {
    console.log('selectedRows action Bar: ', props.state);
    props.fielderMsg({
      message: props.lan.msgAutoAssignInitiateProcess,
      variant: 'success',
      closeButton: true,
      duration: 5000
    });
    handleReAutoAssignedRequest();
  }
	return (
		<React.Fragment>
			<div className='taskAction-container-fix mb-4'>
				<div className='d-flex justify-content-center'>
					<div className='row taskAction-background'>
						<div className='col-auto no-padding taskAction-red-rectangle'></div>
						<div className='col-auto mt-1'>
							<span className='taskAction-number-selected'>{props.selectedTaskCount}</span>
						</div>
						<div className='col-auto my-auto'>
							<span className='taskAction-text-selected'>{lan.selectedElements}</span>
						</div>

						{/******** RESCHEDULE TASK********/}
						{props.showDisabledTasks == false && props.showTaskByEmail == false && (
							<React.Fragment>
								{canRescheduleTask && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-calendar-icon'
													src={CalendarIcon}
													className='icon Fielder-icon-button'
													title={lan.titleIconCalendar}
													onClick={() => props.changePopupState(POPUPNAME.RESCHEDULE_TASK)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding d-flex justify-content-center'>
												<span className='taskAction-text-icon'>
													{lan.titleIconCalendar}
												</span>
											</div>
										</div>
									</div>
								)}
							</React.Fragment>
						)}
						{/******** DETAIL TASK********/}
						<div className='col-auto my-auto pl-1 pr-1'>
							<div className='row-auto'>
								<div className='col-auto'>
									<img
										src={DetailTaskIcon}
										className='icon Fielder-icon-button'
										id='MyOperation-detail-task-icon'
										title={lan.titleIconDetail}
										onClick={() => props.changePopupState(POPUPNAME.TASK_DETAIL)}
										alt=''
									/>
								</div>
							</div>
							<div className='row-auto'>
								<div className='col-auto no-padding  d-flex justify-content-center'>
									<span className='taskAction-text-icon'>{lan.titleIconDetail}</span>
								</div>
							</div>
						</div>
						{/******** DUPLICATE TASK********/}
						{!RoleUtils.isUserClient(session.user) && props.showTaskByEmail == false && (
							<div className='col-auto my-auto pl-1 pr-1'>
								<div className='row-auto'>
									<div className='col-auto'>
										<ControlPointDuplicateIcon
											style={{ cursor: 'pointer' }}
											fontSize='medium'
											color='disabled'
											onClick={() => props.changePopupState(POPUPNAME.DUPLICATE_TASK)}
											title={lan.duplicateTask}
										/>
									</div>
								</div>
								<div className='row-auto'>
									<div className='col-auto no-padding  d-flex justify-content-center'>
										<span className='taskAction-text-icon'>{lan.duplicateTask}</span>
									</div>
								</div>
							</div>
						)}
						{/******** PARENT TASK********/}
						{!RoleUtils.isUserClient(session.user) &&
							props.selectedTaskCount == 1 &&
							props.showTaskByEmail == false && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto'>
											<img
												src={ParentTaskIcon}
												className='icon Fielder-icon-button'
												id='MyOperation-parent-task-icon'
												title={lan.parentTask}
												onClick={() => props.changePopupState(POPUPNAME.PARENT_TASK_DETAIL)}
												alt=''
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{lan.parentTask}</span>
										</div>
									</div>
								</div>
							)}
						{/******** UNDO TASK********/}
						{props.showDisabledTasks == true && props.showTaskByEmail == false && (
							<React.Fragment>
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto'>
											<img
												src={UndoIcon}
												className='icon Fielder-icon-button'
												id='MyOperation-detail-task-icon'
												title={lan.enableTask}
												onClick={props.restoreTask}
												alt=''
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{lan.enableTask}</span>
										</div>
									</div>
								</div>
							</React.Fragment>
						)}

						{props.showDisabledTasks == false && props.showTaskByEmail == false && (
							<React.Fragment>
								{/******** CANCEL TASK********/}
								{canCancelTasks && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-cancelTask-icon'
													src={CancelTaskIcon}
													className='icon Fielder-icon-button'
													title={lan.cancelTask}
													onClick={() => props.changePopupState(POPUPNAME.CANCEL_TASK)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding  d-flex justify-content-center'>
												<span className='taskAction-text-icon'>{lan.cancel}</span>
											</div>
										</div>
									</div>
								)}

								{/******** RATE TASK********/}
								{RoleUtils.isUserClient(session.user) && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-rateTask-icon'
													src={reviewTaskIcon}
													className='icon Fielder-icon-button'
													title={lan.rateTask}
													onClick={() => props.changePopupState(POPUPNAME.RATE_TASK)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding  d-flex justify-content-center'>
												<span className='taskAction-text-icon'>
													{lan.rateTaskAction}
												</span>
											</div>
										</div>
									</div>
								)}

								{/******** CREATE GROUP TASK********/}

									<React.Fragment>
										{props.groupTaskFunctionality && !RoleUtils.isUserClient(session.user) &&
											canCreateGroupTasks && (
												<div className='col-auto my-auto pl-1 pr-1'>
													<div className='row-auto'>
														<div className='col-auto'>
															<img
																id='MyOperation-create-group-task-icon'
																src={CreateGroupTask}
																className='icon Fielder-icon-button'
																title={lan.titleIconGroup}
																onClick={() => props.changePopupState(POPUPNAME.CREATE_GROUP_TASK)}
																alt=''
															/>
														</div>
													</div>
													<div className='row-auto'>
														<div className='col-auto no-padding  d-flex justify-content-center'>
															<span className='taskAction-text-icon'>
																{lan.createGroupTaskAction}
															</span>
														</div>
													</div>
												</div>
											)}
										{/******** EDIT GROUP TASK********/}
										{!RoleUtils.isUserClient(session.user) && canEditGroupTasks && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-edit-group-task-icon'
															src={EditGroupTaskIcon}
															className='icon Fielder-icon-button'
															title={lan.titleIconEditGroup}
															onClick={() => props.changePopupState(POPUPNAME.EDIT_GROUP_TASK)}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>
															{lan.titleIconEditGroupAction}
														</span>
													</div>
												</div>
											</div>
										)}

										{/******** ASSING GROUP TASK********/}
										{props.groupTaskFunctionality && !RoleUtils.isUserClient(session.user) && canAddTasksToGroup && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-assign-group-task-icon'
															src={AssingGroupTask}
															className='icon Fielder-icon-button'
															title={lan.titleIconAssignGroup}
															onClick={() => props.changePopupState(POPUPNAME.ASSIGN_GROUP_TASK)}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>
															{lan.titleIconAssignGroupAction}
														</span>
													</div>
												</div>
											</div>
										)}
									</React.Fragment>


								{/******** ROUTE ********/}
								{showRouteOpt &&
									validatePermissions(session.user, props, 'route') &&
									canGenerateRouteOptimization && (
										<React.Fragment>
											{!RoleUtils.isUserClient(session.user) && (
												<div className='col-auto my-auto pl-1 pr-1'>
													<div className='row-auto'>
														<div className='col-auto'>
															<img
																id='MyOperation-generate-route-icon'
																src={RouteIcon}
																className='icon Fielder-icon-button'
																title={lan.route}
																onClick={() => props.changePopupState(POPUPNAME.GENERATE_ROUTE)}
																alt=''
															/>
														</div>
													</div>
													<div className='row-auto'>
														<div className='col-auto no-padding  d-flex justify-content-center'>
															<span className='taskAction-text-icon'>{lan.route}</span>
														</div>
													</div>
												</div>
											)}
										</React.Fragment>
									)}

								{/******** AGENTS ********/}
								{!RoleUtils.isUserClient(session.user) && canAssignAgent && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-assign-agent-icon'
													src={AddAgentsIcon}
													className='icon Fielder-icon-button'
													title={lan.agents}
													onClick={() => props.changePopupState(POPUPNAME.ASSIGN_AGENT)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding  d-flex justify-content-center'>
												<span className='taskAction-text-icon'>{lan.agents}</span>
											</div>
										</div>
									</div>
								)}
                {/******** RE-AUTO-ASSIGN-TASKS ********/}
                {RoleUtils.isUserSuperAndAdministrator(session.user) || RoleUtils.isUserManager(session.user) && canAssignAgent && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-assign-forms-icon'
															src={AutoAssignTaskIcon}
															className='icon Fielder-icon-button'
															title={lan.autoassign}
															onClick={() => clickAutoAssignTask()}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>{lan.autoassign}</span>
													</div>
												</div>
											</div>
										)}
								{/******** TEAMS ********/}
								{!RoleUtils.isUserClient(session.user) && canAssignTeams && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-assign-team-icon'
													src={TeamsIcon}
													className='icon Fielder-icon-button'
													title={lan.teams}
													onClick={() => props.changePopupState(POPUPNAME.ASSIGN_TEAM)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding  d-flex justify-content-center'>
												<span className='taskAction-text-icon'>{lan.teams}</span>
											</div>
										</div>
									</div>
								)}
								{/******** PARTNERS ********/}
								{!RoleUtils.isUserClient(session.user) && canAssignPartners && (
									<div className='col-auto my-auto pl-1 pr-1'>
										<div className='row-auto'>
											<div className='col-auto'>
												<img
													id='MyOperation-assign-partner-icon'
													src={PartnersIcon}
													className='icon Fielder-icon-button'
													title={lan.partners}
													onClick={() => props.changePopupState(POPUPNAME.ASSIGN_PARTNER)}
													alt=''
												/>
											</div>
										</div>
										<div className='row-auto'>
											<div className='col-auto no-padding  d-flex justify-content-center'>
												<span className='taskAction-text-icon'>{lan.partners}</span>
											</div>
										</div>
									</div>
								)}

								{/******** REASSIGN PARTNERS ********/}
								{RoleUtils.isUserSuperAndAdministrator(session.user) &&
									canAssignPartners && (
										<div className='col-auto my-auto pl-1 pr-1'>
											<div className='row-auto'>
												<div className='col-auto'>
													<img
														id='MyOperation-assign-partner-icon'
														src={Reload}
														className='icon Fielder-icon-button'
														title={lan.reassign + ' ' + lan.partner}
														onClick={() => props.changePopupState(POPUPNAME.RE_ASSIGN_PARTNER)}
														alt=''
													/>
												</div>
											</div>
											<div className='row-auto'>
												<div className='col-auto no-padding  d-flex justify-content-center'>
													<span className='taskAction-text-icon'>
														{lan.reassign + ' ' + lan.partner}
													</span>
												</div>
											</div>
										</div>
									)}

								{/******** ASSETS ********/}
								<React.Fragment>
									{!RoleUtils.isUserClient(session.user) &&
										showAsset &&
										canAssignAssets &&
										canViewAssets && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-assign-asset-icon'
															src={AssetsIcon}
															className='icon Fielder-icon-button'
															title={lan.assets}
															onClick={() => props.changePopupState(POPUPNAME.ASSIGN_ASSETS)}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>{lan.assets}</span>
													</div>
												</div>
											</div>
										)}

									{/******** INVENTORY ********/}
									{!RoleUtils.isUserClient(session.user) &&
										showInventory &&
										canAssignInventory &&
										canViewInventory && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-assign-inventory-icon'
															src={InventoryIcon}
															className='icon Fielder-icon-button'
															title={lan.inventory}
															onClick={() => props.changePopupState(POPUPNAME.ASSIGN_INVENTORIES)}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>
															{lan.inventories}
														</span>
													</div>
												</div>
											</div>
										)}

									{/******** FORMS ********/}
									{!RoleUtils.isUserClient(session.user) &&
										showForms &&
										canAssignForms &&
										canViewForms && (
											<div className='col-auto my-auto pl-1 pr-1'>
												<div className='row-auto'>
													<div className='col-auto'>
														<img
															id='MyOperation-assign-forms-icon'
															src={FormIcon}
															className='icon Fielder-icon-button'
															title={lan.form}
															onClick={() => props.changePopupState(POPUPNAME.ASSIGN_FORMS)}
															alt=''
														/>
													</div>
												</div>
												<div className='row-auto'>
													<div className='col-auto no-padding  d-flex justify-content-center'>
														<span className='taskAction-text-icon'>{lan.form}</span>
													</div>
												</div>
											</div>
										)}
								</React.Fragment>
							</React.Fragment>
						)}

						<div className='col-auto my-auto pl-1 pr-1 colsize-cross'>
							<div className='row-auto'>
								<div className='col-auto'>
									<button className='closeTaskAction' onClick={() => props.closeTaskAction()} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}


export default TaskActionPopup;
