import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

var THEMEONE = "THEMEONE";
var THEMETWO = "THEMETWO";

function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color("#242C39"), //9013fe
			am4core.color("#8A989C"),
			am4core.color("#6D8F97"),
			am4core.color("#B6C3D0"), //ce2b66
            am4core.color("#4A5865"),
		];
	}
}

//am4core.useTheme(am4themes_myTheme);


function am4themes_millenial(target) {

  if (target instanceof am4core.ColorSet) {

      target.list = [
          am4core.color("#9013fe"),
          am4core.color("#51a5de"),
          am4core.color("#f52394"),
          am4core.color("#ce2b66"),
          am4core.color("#dc5c87")

      ];

  }

}

class Puntuality extends Graph{
    constructor(data,props){
        super(props);
        this.props = props;
        this.data = data;
    }

    createGraph(params){

        let theme = params.theme
        am4core.unuseAllThemes();

        if(theme == THEMEONE ){
            am4core.useTheme(am4themes_empresarial);
          }
        else if(theme == THEMETWO ){
            am4core.useTheme(am4themes_millenial);
        }

        let chart = am4core.create(params.id, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;
        chart.paddingRight = 30;
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

        let category = params.category;

        let openDateX = params.openDateX;

        let dateX = params.dateX;

        let legend = params.legend;

        let firstTime= params.firstTime;

        let textTitleGrah=params.textTitleGrah;

        let dateFromDateTo=params.dateFromDateTo;

        let total=params.total;

        let textTotalTask= params.textTotalTask;

        let titleAgent= params.titleAgent;

        chart.data = this.data;

		// Create axes
        super.setAxesPuntuality(chart, category,textTitleGrah,dateFromDateTo,total,textTotalTask,titleAgent);

        // Create date axes
        super.setDateAxesPuntuality(chart, firstTime);

        //Series

		super.setPuntualitySeries(chart,category,openDateX,dateX);

		super.setChartProperties(chart, legend, true,false);

        chart.durationFormatter.durationFormat = "d'"+"d"+"' h'"+"h"+"' m'"+"m"+"' s'"+"s";
        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default Puntuality