export const REQUEST_TYPES = {
	GET_DASHBOARD_DAILY_COMPANY_TASK: 'GET_DASHBOARD_DAILY_COMPANY_TASK',
	GET_DASHBOARD_DAILY_BRANCH_TASK: 'GET_DASHBOARD_DAILY_BRANCH_TASK',
	GET_DASHBOARD_DAILY_COMPANY_TASK_SLA: 'GET_DASHBOARD_DAILY_COMPANY_TASK_SLA',
	GET_DASHBOARD_AGENTS: 'GET_DASHBOARD_AGENTS',
	GET_DASHBOARD_STATUS_DAILY: 'GET_DASHBOARD_STATUS_DAILY',
	GET_DASHBOARD_MONTH_TASKS: 'GET_DASHBOARD_MONTH_TASKS',
	GET_DASHBOARD_TASK_TO_CASH: 'GET_DASHBOARD_TASK_TO_CASH',
	GET_TASK_TO_CASH_FLAT: 'GET_TASK_TO_CASH_FLAT',
};
