import React,{ Component } from 'react';

//Library
import {Modal,Button} from 'react-bootstrap';

//Icons
import DangerIcon from '@assets/icons/danger-icon.svg';

//Css
import './PermanenDeleteMessage.css'
import 'FielderUtils/style.css';

class PermanenDeleteMessage  extends Component{

    constructor(props, context) {
        super(props, context);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.closeErrorMessage();
    }

    render() {
        return(

            <React.Fragment >
               <Modal size="sm" show={this.props.showErrorMessage} onHide={this.handleClose} className="Fielder-modals-header-border Fielder-modals-content-border" >
                    <Modal.Header className="Fielder-modals-create-edit-detail-header-border" />

                    <Modal.Body className="error-message-modal-body text-center">
                        {this.props.icon==null?(
                            <img src={DangerIcon} className="error-message-ceter-icon"  alt=""/>
                        ):(
                            <img src={this.props.icon} className="error-message-ceter-icon"  alt=""/>
                        )}

                        <div className="col mb-2 mt-2"> <span className="color-numberSuccess"> {this.props.numberSuccess} </span>{this.props.elementName} {this.props.lan.deletedSuccessfullyText}</div>
                        <div className="col">  <span className="color-numberLinked"> {this.props.numberLinked} </span>{this.props.elementName} {this.props.messageElement}</div>
                        <div className="col">{this.props.lan.note} <img src={DangerIcon} className="error-message-ceter-icon error-icon-size"  alt=""/>  </div>
                    </Modal.Body>

                    <Modal.Footer >
                    <div className='mt-3'>
                        <button id="id-button-deleted" type="submit" className ="btn btn-outline-primary btn-success Fielder-modal-btnsave-size error-message-btn-margin" onClick={this.handleClose}>
                            {this.props.lan.show}
                        </button>
                    </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>

        );
    }
}

export default PermanenDeleteMessage;
