import React from 'react';

import ErrorIcon from '@assets/icons/alert-icon.svg';
import TextField from '~/TextField/TextField.component';
import { useLanguage } from 'Context/LanguageContext';
import { Modal } from 'react-bootstrap';
import 'FielderUtils/style.css';

export default function UpdateDocumentLinkMessage(props) {
	const lan = useLanguage();
	return (
		<>
			<Modal
				id='ErrorMessage-modal'
				size='sm'
				show
				onHide={props.handleClose}
				dialogClassName='shadow-lg'
				className='Fielder-modals-header-border Fielder-modals-content-border shadow-lg'
			>
				<Modal.Header className='Fielder-modals-create-edit-detail-header-border'>
					<Modal.Title />
				</Modal.Header>

				<Modal.Body className='error-message-modal-body text-center'>
					<img src={ErrorIcon} className='error-message-center-icon' alt='' />

					<div id='ErrorMessage-message' className='text-center ml-2 mr-2 mt-3 mb-3 error-message-margin'>
						{props.message}
					</div>

					<div className='col-12 mt-2 mb-3'>
						<TextField
							id='txt-task-folio'
							value={props.urlFileLink}
							label={lan.linkDocument}
							onChange={(e) => props.handleChangeLinkInput(e.target.value)}
							fullWidth
						/>
					</div>

					<div className='row mt-2'>
						<div className='col ml-1'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success buttonUpdateFileSise error-message-btn-margin'
								onClick={props.updateFileConfirm}
							>
								{lan.accept}
							</button>
						</div>
						<div className='col ml-1'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success buttonUpdateFileSise error-message-btn-margin'
								onClick={props.handleClose}
							>
								{lan.cancel}
							</button>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer />
			</Modal>
		</>
	);
}
