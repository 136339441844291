const mobileRegex = /^7\d{7}$/;
const phoneRegex = /^4\d{6}$/;
export const FIELDER = {
	idElement: {
		txt: {
			name: 'txt-name',
			lastName: 'txt-last-name',
			email: 'txt-email',
			emailConfirm: 'txt-email-confirm',
			phone: 'txt-phone',
			mobile: 'txt-mobile-phone',
			username: 'txt-username',
			idAccount: 'txt-account-id',
			companyName: 'txt-company-name',
			companyPhone: 'txt-company-phone',
			companyRfc: 'txt-company-rfc',
			companyCountry: 'txt-company-country',
			companyAddress: 'txt-company-address',
			idUser: 'txt-user-id',
			emailIntegrationAccount: 'txt-email-integration-account',
			createTask: 'txt-create-task',
			password: 'txt-password',
			filterName: 'txt-filter-name',
			formFilterName:'txt-form-filter-name',
			filterCondition: 'txt-filter-condition',
			filterValue: 'txt-filter-value',
			licensePlate: 'txt-license-plate',
			jobPosition: 'txt-job-position',
		},
		dtpckr: {
			workingHoursFrom: 'dtpckr-working-hours-from',
			workingHoursTo: 'dtpckr-working-hours-to',
		},
		txta: {
			configMail: 'config-mail'
		},
		ddl: {
			jobPosition: 'ddl-job-position',
			currency: 'ddl-currency',
		},
		swi: {
			pt_BR: 'swi-pt-br',
			es_MX: 'swi-es-mx',
			en_US: 'swi-en-us',
			allowManagerCreate: 'swi-allow-manager-create',
			allowManagerAccessAnalytics: 'swi-allow-manager-access-analytic',
			allowManagerAccessRoute: 'swi-allow-manager-access-route',
			allowManagerAccessResource: 'swi-allow-manager-access-resource',
			allowManagerAccessIsochronous: 'swi-allow-manager-access-isochronous',
			allowManagerAccessDownloadTasks: 'swi-allow-manager-access-download-tasks',
			allowManagerAccessImportTasks: 'swi-allow-manager-access-import-tasks',
			allowManagerModifyTasksStatus: 'swi-allow-manager-modify-tasks-status',
			allowManagerEditFormTaskDetail: 'swi-allow-manager-edit-form-task-detail',
			allowManagerSeeTaskByMail: 'swi-allow-manager-see-task-by-mail',
			allowManagerToGeneratePdf: 'swi-allow-manager-generate-pdf',
			allowManagerToApproveForms: 'swi-allow-manager-approve-forms',
			allowManagerToGenerateAutoAssignTasks: 'swi-allow-manager-generate-auto-assign-tasks',
			allowAdminAccessAnalytics: 'swi-allow-admin-access-analytics',
			allowAdminModifyTasksStatus: 'swi-allow-admin-modify-tasks-status',
			allowAdminViewTaskCash: 'swi-allow-admin-view-task-cash',
			allowAgentAllwaysExecuteTasks: 'swi-allow-agent-always-execute-tasks',
			allowAgentCreateTasks: 'swi-allow-agent-create-tasks',
			allowAgentDuplicateTasks: 'swi-allow-agent-duplicate-tasks',
			allowAgentChangeTypeTasks: 'swi-allow-agent-change-tasks',
			allowAgentRescheduleTasks: 'swi-allow-agent-reschedule-tasks',
			allowAccessClient: 'swi-allow-client-access',
			allowClientImport: 'swi-allow-client-import',
			allowClientExport: 'swi-allow-client-export',

		},
		btn: {
			save: 'btn-save',
			send: 'btn-send',
			resendPasssword: 'btn-resend-password',
			updateProfile: 'btn-update-profile',
			updateAccount: 'btn-update-account',
			addFilterCondition: 'btn-add-filter-condition',
			filterApply: 'btn-filter-apply',
			filterFormApply: 'btn-filter-form-apply',
			filterSave: 'btn-filter-save',
			filterFormSave: 'btn-filter-form-save',
			filterAdd: 'btn-filter-add',
			filterRemove: 'btn-filter-remove',
			create: 'btn-create',
			update: 'btn-update',
			add: 'btn-add',
			remove: 'btn-remove',
			cancel: 'btn-cancel',
			edit: 'btn-edit',

		},
		fup: {
			img: 'fup-image'
		},
		img: {
			companyLogo: 'img-company-logo'
		},
		chk: {
			configNotification: 'chk-{0}-notification-{1}-{2}',
		},
		pop: {
			globalSearch: 'pop-global-search-{0}',
			myOperation: 'pop-my-operation-{0}',
			routeDetail: 'pop-route-detail-{0}',
			userModal: 'pop-user-modal-{0}',
			agentMap: 'pop-agent-map-{0}',
			formClient: 'pop-form-client-{0}',
			formBranch: 'pop-form-branch-{0}',
			formManager: 'pop-form-manager-{0}',
			formAdmin: 'pop-form-admin-{0}',
			formCompany: 'pop-form-company-{0}',
			formPartner: 'pop-form-partner-{0}',
			formTeam: 'pop-form-team-{0}',
			formAgent: 'pop-form-agent-{0}',
		}
	},
	regex: {
		mobile: mobileRegex,
		phone: phoneRegex,
	},
	lang: {
		pt_BR: 'pt_BR',
		es_MX: 'es_MX',
		en_US: 'en_US'
	}
};
export const validatePhone = (phone) => phoneRegex.test(phone);
