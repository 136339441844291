import { Container, Select, TextField, Typography, Grid, 
		IconButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FielderTextInputField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
	  borderRadius: '12px',
	},
});
  
export const FielderTextInputFieldLogin = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		borderRadius: '12px',
		height: '63px',
	},
});
  
export const FielderSelectField = styled(Select)(() => ({
	borderRadius: '12px !important',
}));

export const SnackBarIconButton = styled(IconButton)({
	marginRight: '0px',
});

export const SnackBarTypoTitle = styled(Typography)({
	fontSize: '14px',
	fontWeight: 'bold',
	wordWrap: 'break-word',
});

export const SnackBarTypoDescription = styled(Typography)({
	fontSize: '13px',
	wordWrap: 'break-word',
});

export const SnackBarGridContainer = styled(Grid)({
	display: 'flex',
	maxWidth: '400px',
	minWidth: '80px',
	borderRadius: '8px'
});

export const SnackBarListItem = styled(ListItemText)({
	fontFamily: 'Lato',
	marginTop: '0px',
	marginLeft: '8px',
	marginRight: '1px',
});


export const StyleFielderRootContainerXXL = styled(Container)(({ theme }) => ({
	"&.MuiContainer-root": {
		maxWidth: '100%',
		padding: theme.spacing(3),
		paddingBottom: theme.spacing(7)
	  }
}));

export const StyleFielderDefaultContainerXXL = styled(Container)(({ theme }) => ({
	"&.MuiContainer-root": {
		maxWidth: '100%',
		padding: theme.spacing(3),
	  }
}));

export const StyleFielderDefaultContainerXL = styled(Container)(({ theme }) => ({
	"&.MuiContainer-root": {
		maxWidth: theme.breakpoints.values.xl,
		padding: theme.spacing(3),
	  }
}));
