// ROLE MANAGEMENT
const ACCOUNTCONFIGURATION = 'accountconfiguration';
const NAME = 'accountconfiguration';
const VIEW = 0;
const EDIT = 1;

export default {
	NAME,
	ACCOUNTCONFIGURATION,
	VIEW,
	EDIT,
};
