import { useSelector, useDispatch } from 'react-redux';

import { selectRoles } from '../../store/modules/session/sessionSelectors';
import { setLogOutUser } from '../../store/modules/session/sessionSlice';

const useValidateRoles = () => {
	const dispatch = useDispatch(setLogOutUser);
	const roles = useSelector(selectRoles);

	const isRoleValid = (name, type) => {
		if (!roles) {
			dispatch(setLogOutUser());
			return false;
		}

		if (name in roles && type in roles[name]) {
			return roles[name][type];
		}

		return false;
	};

	return { isRoleValid };
};

export default useValidateRoles;
