import { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';

import { selectUser } from '../../store/modules/session/sessionSelectors';
import { getAgentMarker } from '../../utils/agent/agentMarkers';
import { getTaskStatusStructure } from '../../utils/map/taskMarkers';
import Status from '../../constants/task/TaskStatus.constants';
import { getTaskMarker } from '../../utils/task/taskMarkers';

const insert = (element, array) => {
	const sortArray = (e1, e2) => e1 - e2;

	if (array.length > 0) {
		array.push(element);
		array.sort(sortArray);
	} else {
		array.push(element);
	}
};

const taskStructure = getTaskStatusStructure();
/**
 *
 * 	Tasks and agents -- do not append only set the received Values
 *
 *
 * @param {*} param0
 * @returns
 */
const useCreateMarkers = ({
	tasks,
	agents,
	onTaskClick,
	onAgentClick,
	hideFinished,
	hideCancelled,
}) => {
	const lan = useLanguage();
	const user = useSelector(selectUser);

	const [taskMarkers, setTaskMarkers] = useState(taskStructure);
	const [agentMarkers, setAgentMarkers] = useState({
		config: { draggable: false, opacity: 0.95 },
		markers: [],
	});


	useEffect(() => {
		let agentsFromTasks = null;

		if (tasks && tasks.length > 0) {
			const { markers, taskAgents } = getTaskMarkers(tasks, onTaskClick);
			setTaskMarkers(markers);
			agentsFromTasks = taskAgents;
		}

		if (agents) {
			getTaskAgents(agents, onAgentClick);
		} else if (agentsFromTasks != null) {
			getTaskAgents(agentsFromTasks, onAgentClick);
		} else {
			setAgentMarkers({ markers: [] });
		}
	}, [tasks, agents, user]);

	const getTaskId = (task) => {
		if('idTask' in task) {
			return task.idTask;
		} else if ('taskId' in task) {
			return task.taskId;
		}
		return null;
	}

	const getTaskMarkers = (tasks, onClick) => {
		let markers = getTaskStatusStructure();
		let taskAgents = {};

		tasks.forEach((task) => {
			// Hide Finished Task Agents
			if (hideFinished && task.status === Status.FINISH) {
				return;
			} else if (hideCancelled && task.status === Status.CANCEL) {
				return;
			} else {
				const taskMarker = getTaskMarker(task, onClick, lan);

				// Add Marker to the status
				markers[task.status].markers.push(taskMarker);

				if (task.agent && task.agent.geolocation) {
					const idAgent = task.agent.idAgent;

					if (idAgent in taskAgents) {
						let agentTasks = taskAgents[idAgent].tasks;
						insert(getTaskId(task), agentTasks);
					} else {
						taskAgents[idAgent] = {
							agentInfo: task.agent,
							tasks: [getTaskId(task)],
						};
					}
				}
			}
		});

		return { markers, taskAgents };
	};

	const getTaskAgents = (taskAgents, onClick) => {
		let newAgentMarkers = [];
		const isClient = RoleUtils.isUserClient(user);

		for (const idAgent in taskAgents) {
			const agent = taskAgents[idAgent];
			if (!isClient || (isClient && agent.agentInfo && agent.agentInfo.showToClient) ) {
				const agentMarker = getAgentMarker(agent, onClick, false, lan);
				newAgentMarkers.push(agentMarker);
			}
		}

		/*for (const idAgent in taskAgents) {
			let agent = taskAgents[idAgent];
			agent.tasks = taskAgents?.tasks;
			const agentMarker = getAgentMarker(agent, onClick, lan);
			newAgentMarkers.push(agentMarker);
		}*/

		setAgentMarkers({
			...agentMarkers,
			markers: newAgentMarkers,
		});
	};

	return { taskMarkers, agentMarkers };
};

export default useCreateMarkers;
