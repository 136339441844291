import API_LINKS from 'FielderAPILinks';
import JSZipUtils from 'jszip-utils';

const s3Link = 'https://s3.amazonaws.com/';

export const toDataURL = (url, callback) => {
	const proxy = `${API_LINKS.awsS3Link}/`;
	const index = s3Link.length;
	const urlParsed = url.substring(index, url.length);
	const urlArray = urlParsed.split('/');
	let urlWithProxy = `${proxy + urlArray[0]}/`;

	for (let index = 1; index < urlArray.length; index++) {
		const element = urlArray[index];
		if (index > 1) {
			urlWithProxy += '%2F';
		}
		urlWithProxy += element;
	}

	fetch(urlWithProxy)
		.then((res) => res.blob())
		.then((blob) => {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				callback(reader.result);
			});
			reader.readAsDataURL(blob);
		});
};

const getImageName = (url) => {
	const separated = url.split('/');
	let imageName = '';
	const lastIndex = separated.length - 1;

	if (lastIndex > 0) {
		imageName = separated[lastIndex];
	}
	return imageName;
};

export const toDataURLWithJsZip = async (image, zip) => {
	const imageAux = image.split(process.env.FIELDER_CONTENT_LINK);
	const imageUrl = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${imageAux[1]}`;
	await new Promise((resolve, reject) => {
		JSZipUtils.getBinaryContent(imageUrl, (err, data) => {
			// Add File To Zip
			zip.file(`${getImageName(image)}.jpeg`, data, { binary: true });

			resolve();
		});
	});
	return zip;
};
