import { pipe } from 'lodash/fp';

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { post, requestHandler } from '../request';
import { handlePostRequest, handleGetRequest } from '../request';
import { REQUEST_TYPES } from '@core/constants/company/request.constants';

/**
 *
 * @param {string} filter       - Filter to search the companies by
 *
 * @description
 *      Gets all companies by the filter
 * @returns {array} - contains the companies found (returns empty if error)
 */

export const getCompanyTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_COMPANIES_TABLE,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const changeCompanyStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CHANGE_COMPANY_STATUS,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getBranchesByCompanies = async (companies, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		type: REQUEST_TYPES.GET_BRANCHES_BY_COMPANIES,
		idAccount: user.idAccount,
		idCompanies: companies,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getCompaniesByFilter = async (filter, onSuccess, onError, loading) => {
	const path = API_LINKS.taskManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_ALL_COMPANIES_BY_FILTER,
		idAccount: user.idAccount,
		idUser: user.idUser,
		filter,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};

export const getCompanyByIdRequest = async (id, onSuccess, onError, loading) => {
	const path = API_LINKS.apiUserManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_COMPANY_BY_ID,
		idAccount: user.idAccount,
		idUser: user.idUser,
		idCompany: id,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};

export const getCompaniesByFilterCreateTask = async (filter, onSuccess, onError, loading) => {
	const path = API_LINKS.taskManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_ALL_COMPANIES_BY_FILTER_SELECT,
		idAccount: user.idAccount,
		idUser: user.idUser,
		filter,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};

/*export const getCompaniesPartnerByFilter = async (filter, onSuccess, onError, loading) => {
	const path = API_LINKS.taskManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_COMPANIES_BY_FILTER,
		idAccount: user.idAccount,
		idUser: user.idUser,
		filter,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};*/

export const getCompaniesPartnerByFilter = async (filter, onSuccess, onError, loading) => {
	const path = API_LINKS.apiUserManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_COMPANIES_BY_FILTER,
		filter,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};

export const getCompaniesInitialOptions = async (onSuccess, onError, loading) => {
	const path = API_LINKS.taskManager;
	const user = await getSelectUser();

	const body = {
		type: REQUEST_TYPES.GET_COMPANIES_DEFAULT_OPTIONS,
		idAccount: user.idAccount,
		idUser: user.idUser,
	};

	const doRequest = pipe(post, requestHandler(onSuccess, onError, loading));
	return doRequest(path, body, {});
};


/** CREATE COMPANY * */
export const createCompanyRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	body.company.idAccount = user.idAccount;

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CREATE_COMPANY,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

/** UPDATE COMPANY * */
export const updateCompanyRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	body.company.idAccount = user.idAccount;

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_COMPANY,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


/** DELETE COMPANY * */
export const deleteCompanyRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.company.idAccount = user.idAccount;

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_DELETE_COMPANY,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getExportCompanyCount = async (params, onSuccess, onError) => {
	const user = await getSelectUser();

	const queryParams = new URLSearchParams(params);
	queryParams.append('idUser', user.idUser)

	return handleGetRequest(API_LINKS.exportCompanies, queryParams, onSuccess, onError, null);
};


// Get export task data
export const getExportCompanyList = async (body, onSuccess, onError) => {
	const user = await getSelectUser();

	const queryParams = new URLSearchParams(body);
	queryParams.append('idUser', user.idUser)

	return handleGetRequest(API_LINKS.exportCompanies, queryParams, onSuccess, onError, null);
};