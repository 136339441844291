import React from 'react';

import { Mandarin, YellowOrange } from '@core/theme/baseTheme';
import { ToggleButtonGroup, ToggleButton, Grid, Paper, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

export const CategoryButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		margin: theme.spacing(0.5),
		border: 0,
		'&.Mui-disabled': {
			border: 0,
		},
		'&:not(:first-of-type)': {
			borderRadius: theme.shape.borderRadius,
		},
		'&:first-of-type': {
			borderRadius: theme.shape.borderRadius,
		},
	},
}));

export const CategoryButton = styled(ToggleButton)`
	'& .Mui-selected': {
		backgroundColor: ${Mandarin};
	};
	'& .MuiToggleButton-standard': {
		backgroundColor: ${YellowOrange};
	};
`

export const DashboardPaper = styled((props) => <Paper elevation={0} {...props} />)`
 	border-radius: 15px;
`;

export const MotionDashboardPaper = styled(motion(Grid))`
	border-radius: 15px;
	background-color: white;
`

export const MotionGrid = motion(Grid);

export const TotalNumberText = styled(Typography)`
	font-size: 55px;
	font-weight: bold;
`;

export const AgentStatusChart = styled('div')`
	width: 100%;
	height: 15rem;
	margin-left: auto;
	margin-right: auto;
`

export const StatusContainer = styled(motion.div)`
	font-size: 1em;
	position: relative;
	width: 81% !important;
	border-radius: 20px;
	padding: 7%;
	justify-content: center;
	cursor: default;
	color: ${(props) => props.color};
	background-color: ${(props) => props.background};
	text-align: center;
`;

export const LastUpdateText = styled(Typography)`
	font-size: 1em;
	cursor: default;
	text-align: left;
	margin-left: 0.5rem !important;
`;
