import React, { useEffect, useState } from 'react';

import { Icon, IconButton } from '@mui/material'
import { useLanguage } from 'Context/LanguageContext';
import assetConst from 'FielderUtils/roleManagementConst/assetConst';
import inventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import useValidateRoles from '@core/utils/roles/useValidateRoles';

const AssetInventoryTitles = ({
	assetInventories,
	activeAssetInventory,
	changeActiveAssetInventory,
	client,
	editAssetInventoryVisibility,
	isAsset,
	isInventory,
}) => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const [canEdit, setCanEdit] = useState(false);
	const { isRoleValid } = useValidateRoles();
	useEffect(() => {
		let hasRole = false;
		let isAllowed =
			RoleUtils.isUserAdministrator(user) || RoleUtils.isUserSuperAdministrator(user) || client;

		if (isAsset) {
			hasRole = isRoleValid(assetConst.ASSETS, assetConst.EDIT_ASSET);
		} else if (isInventory) {
			hasRole = isRoleValid(inventoryConst.INVENTORY, inventoryConst.EDIT_INVENTORY);
		}

		setCanEdit(hasRole && isAllowed);
	}, [isAsset, isInventory, client, user]);

	const Title = ({ assetInventory, i }) => {
		let titleClass = '';
		if (activeAssetInventory === i) {
			titleClass = 'active form-active';
		} else {
			titleClass = 'nav-link form-inactive ';
		}

		return (
			<button
				data-toggle='pill'
				role='tab'
				aria-controls='v-pills-home'
				key={'assetInventory-' + i}
				aria-selected={(activeAssetInventory === i) + ''}
				className={'nav-link ' + titleClass}
				onClick={() => changeActiveAssetInventory(i)}
			>
				<Row>
					<Col>{assetInventory.name}</Col>

					<Col sm={'auto'} className='pl-0'>
						{canEdit && (
							<IconButton className='p-0' onClick={() => editAssetInventoryVisibility(true)}>
								<Icon color='disabled' title={lan.edit}>
									edit
								</Icon>
							</IconButton>
						)}
					</Col>
				</Row>
			</button>
		);
	};

	return (
		<div className='nav flex-column nav-pills mt-4 ml-3' role='tablist' aria-orientation='vertical'>
			{assetInventories.map((assetInventory, i) => (
				<Title assetInventory={assetInventory} i={i} />
			))}
		</div>
	);
};

export default AssetInventoryTitles;
