import { REQUEST_TYPES as USER_REQUEST_TYPES } from '@core/constants/users/users.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';
import { Client } from 'FielderUtils/roles/roles';

// GET
export const getClients = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.GET_USERS_TABLE,
		...body,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

// PUT
export const updateClientRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_UPDATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateClientLocation = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_UPDATE_LOCATION_UPDATE,
		idAccount: user.idAccount,
		idClientUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const changeClientStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_CHANGE_USER_STATUS,
		role: Client.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};



// DELETE
export const deleteClientRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_DELETE_USER,
		role: Client.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getClientSelectRequest = async (onSuccess, onError, loadingConfig) => {

	const requestBody = {
		type: USER_REQUEST_TYPES.GET_CLIENT_SELECT,
		userDisabled: false,
		role: Client.name,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const createClientRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_CREATE_USER,
		...body
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};