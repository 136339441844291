import React from 'react';

import { MarkerClustererF } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import FielderMarker from './FielderMarker';
import Markers from './Markers';

const options = {
	imageExtension: 'png',
	imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
};

const MAX_ZOOM = 8;
const MINIMAL_CLUSTER_SIZE = 3;

const PinCluster = ({ marker, markers, enableAnimation }) => (
	<MarkerClustererF options={options} maxZoom={MAX_ZOOM} minimumClusterSize={MINIMAL_CLUSTER_SIZE} onClick={null}>
		{(clusterer) => (
			<>
				{markers && markers.length > 0 && <Markers markers={markers} clusterer={clusterer} enableAnimation={enableAnimation} />}
				{marker && <FielderMarker {...marker} clusterer={clusterer} enableAnimation={enableAnimation} />}
			</>
		)}
	</MarkerClustererF>
);

PinCluster.prototype = {
	children: PropTypes.node,
};

export default React.memo(PinCluster);
