import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUser, selectValidPages } from '../../../../core/store/modules/session/sessionSelectors';
import SubMenu from '../SubMenu';
import { getNotManagerNorClientItems, getNotClientItems, getSuperAdminAdminOrViewerItems } from './utils';

const AutomationSideMenu = () => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const validPages = useSelector(selectValidPages);

	const [anchor, setAnchor] = useState(null);
	const [menuOptions, setMenuOptions] = useState([]);

	const title = lan.users;
	const navigate = useNavigate();

	useEffect(() => {
		const managerOrClient = RoleUtils.isUserManager(user) || RoleUtils.isUserClient(user);
		const superAdminAdminOrViewer = RoleUtils.isUserSuperAndSuperViewerAndAdministrator(user);
		const client = RoleUtils.isUserClient(user);

		let items = [];

		if (!managerOrClient) {
			items = items.concat(getNotManagerNorClientItems(lan, goToPage, validPages));
		}

		if (superAdminAdminOrViewer) {
			items = items.concat(getSuperAdminAdminOrViewerItems(lan, goToPage, validPages));
		}

		if (!client) {
			items = items.concat(getNotClientItems(lan, goToPage, validPages));
		}
		setMenuOptions(items);
	}, [user]);

	const openMenu = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	const goToPage = (page) => () => {
		handleClose();
		navigate(page);
	};

	return (
		<>
			<Tooltip title={title} placement='right'>
				<ListItemButton onClick={openMenu}>
					<ListItemIcon>
						<img className='icon' src={`${process.env.AWS_FIELDER_ICONS}agents-icon.svg`} alt='users' />
						<ChevronRightIcon color='disabled' />
					</ListItemIcon>
					<ListItemText primary={title} />
				</ListItemButton>
			</Tooltip>
			<SubMenu anchor={anchor} onClose={handleClose}>
				{menuOptions}
			</SubMenu>
		</>
	);
};

export default AutomationSideMenu;
