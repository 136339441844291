import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';

// ROLE MANAGEMENT
const PAGE_NAME = 'AGENTS';
const NAME = 'agents';
const AGENTS = 'agents';
const ENDPOINT = ENDPOINTS.agents;
const CREATE = 0;
const VIEW = 1;
const EDIT = 2;
const VIEW_DISABLED = 3;
const DISABLE = 4;
const DELETE = 5;
const IMPORT = 6;
const EXPORT = 7;
const CONGRATULATE_AGENTS = 8;

export default {
	PAGE_NAME,
	NAME,
	AGENTS,
	ENDPOINT,
	CREATE,
	VIEW,
	EDIT,
	VIEW_DISABLED,
	DISABLE,
	DELETE,
	IMPORT,
	EXPORT,
	CONGRATULATE_AGENTS,
};
