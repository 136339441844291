import { REQUEST_TYPES } from '@core/constants/task/task.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// POST
export const createTaskGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.CREATE_TASK_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}
// PUT
export const assignTaskToGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.ASSIGN_TASK_TO_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const editTaskGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.groupTask.idAccount = user.idAccount;

	const requestBody = {
		type: REQUEST_TYPES.EDIT_TASK_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const unlinkTaskFromTaskGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.REFUSE_TASK_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// GET
export const getTasksInGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASKS_IN_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const getTotalTaskFromGroup = async (body) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASKS_IN_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	const onSuccess = (response) => response.totalTasks;
	const onError = () => 0;
	
	const totalBranches = await handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, null);
	return totalBranches;
}


export const getTasksFormFilterRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASKS_BY_FILTER_FORM,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const getTaskGroupsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_GROUP_TASKS,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const getTaskGroupRealTimeRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_GROUP_INFO_REALTIME,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const getTaskGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_GROUP_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// DELETE
export const disableTaskGroupRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DISABLE_TASK_GROUP,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}