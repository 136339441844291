import React, { Component } from 'react';

// Custom components
import AlertIcon from '@assets/icons/alert-icon.svg';
import { Row } from 'react-bootstrap';

import Popup from '../../Popup';

/**
 * Repeated Skill Popup
 */
class ErrorPopup extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Popup show closeButton onClose={() => this.props.hidePopup('showErrorPopup')}>
				<div>
					<Row className='justify-content-center'>
						<div className='mx-auto'>
							<img src={AlertIcon} className='mx-auto' alt='' />
						</div>
					</Row>
					<Row className='justify-content-center mt-5'>
						<div className='mx-auto'>
							<span className='mx-auto'>{this.props.lan.unexpectedErrorOcurred}</span>
						</div>
					</Row>
				</div>
			</Popup>
		);
	}
}

export default ErrorPopup;
