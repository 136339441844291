// ROLE MANAGEMENT
const PAGE_NAME = 'RESOURCE_ALLOCATION_PREFERENCES';
const RESOURCEALLOCATION = 'resourceallocation';
const NAME = 'resourceallocation';
const ACCOUNT_PREFERENCES = 'showResourceAlloc';
const GENERATE = 0;

export default {
	PAGE_NAME,
	NAME,
	RESOURCEALLOCATION,
	ACCOUNT_PREFERENCES,
	GENERATE,
};
