import React, { useRef, useCallback, useEffect } from 'react';

import { convertGoogleLatLng } from '@core/utils/location/addressHandler.utils';
import { PolygonF as PolygonGoogleMaps } from '@react-google-maps/api';

const Polygon = ({ value, onClick, globalVisible, globalEditable, defaultShow }) => {
	const { id, info, options } = value;
	const polygonRef = useRef();
	const listenersRef = useRef([]);

	const clickHandler = options.onClick ? options.onClick : onClick;
	const isVisible = options.visible ? options.visible : globalVisible.has(id) || defaultShow;
	const isEditable = options.editable ? options.editable : globalEditable.has(id);

	const handleClick = () => clickHandler(info, options);

	const onEdit = useCallback(() => {
		if (options.onChangeVertices && polygonRef.current) {
			const path = polygonRef.current.getPath().getArray().map(convertGoogleLatLng);
			options.onChangeVertices(path);
		}
	}, [options.onChangeVertices]);

	// Bind refs to current Polygon and listeners
	const onLoad = useCallback(
		(polygon) => {
			polygonRef.current = polygon;

			listenersRef.current.push(
				polygon.addListener('mousedown', () => {
					const path = polygon.getPath();
					listenersRef.current.push(path.addListener('set_at', onEdit));
					listenersRef.current.push(path.addListener('insert_at', onEdit));
					listenersRef.current.push(path.addListener('remove_at', onEdit));
				})
			);
		},
		[onEdit]
	);

	// Clean up refs
	const onUnmount = useCallback(() => {
		listenersRef.current.forEach((lis) => lis.remove());
		polygonRef.current = null;
	}, []);

	return (
		<PolygonGoogleMaps
			editable={isEditable}
			draggable={isEditable}
			onDragEnd={onEdit}
			onClick={handleClick}
			onLoad={onLoad}
			options={options}
			visible={isVisible}
			onUnmount={onUnmount}
			// ref={polygonRef}
		/>
	);
};

const Polygons = ({ polygonData, ...props }) => {
	if (polygonData) {
		const { polygons, onClick, defaultShow, visible, editable } = polygonData;
		const { onPolygonClick } = { ...props };

		return Object.keys(polygons).map((key) => (
			<Polygon
				key={`polygon-${key}`}
				value={polygons[key]}
				defaultShow={defaultShow}
				onClick={onClick || onPolygonClick}
				globalVisible={visible}
				globalEditable={editable}
			/>
		));
	}

	return null;
};

export default React.memo(Polygons);
