import moment from 'moment';

import { getTaskPinCode } from '../../utils/map/pinIcon';
import TaskStatusToTakeActionWhenLate from '@core/constants/task/TaskStatusToTakeActionWhenLate.constants'

export const getTaskMarker = (task, onClick, lan) => {
	let lat;
	let lng;

	if ('startLocation' in task) {
		lat = task.startLocation.latitude;
		lng = task.startLocation.longitud;
	} else if ('location' in task) {
		lat = task.location.latitude;
		lng = task.location.longitud;
	}

	let taskId = 'taskId' in task ? task.taskId : task.idTask;

	return ({
		id: task.idTask,
		position: { lat: parseFloat(lat), lng: parseFloat(lng) },
		title: getTaskMarkerTitle(task, lan),
		icon: getTaskPinCode(task.status, task.pin || task.taskPin || null),
		onClick: () => onClick(task.idTask || task.taskId),
		animation: shouldAnimateTask(task),
	})
};

const shouldAnimateTask = (task) => {
	const now = moment();
	const scheduledDate = moment(task.scheduledDate);

	if (now.isAfter(scheduledDate) && TaskStatusToTakeActionWhenLate.includes(task.status)) {
		return window.google.maps.Animation.BOUNCE;
	}
	return null;
};

const getDetaultNameOrNormal = (task, priority, lan) => {
	if (priority == 1 || priority == 2 || priority == 3) {
		return  `\n- ${lan.priority}: ${lan['severity_' + priority]}`;
	} else {
		return task?.severityName ? `\n- ${lan.priority}: ${task.severityName}` :`\n- ${lan.priority}: ${task.priorityName} `;
	}
}

const getTaskMarkerTitle = (task, lan) => {
	const priority = task?.severity ? task.severity : task.priority;
	const taskPriority = getDetaultNameOrNormal(task, priority, lan);
	
	const taskFolio = task.folio ? `[${task.folio}]` : '';
	const taskInfo = `- ${lan.task}: ${task.idTask} ${taskFolio}`;
	let taskBranchClient = '\n- ';

	if (task.branch) {
		taskBranchClient += `${lan.branch}: ${task.branch?.name ? task.branch.name : task.branch}`;
	} else if (task.user && task.user.branch) {
		taskBranchClient += `${lan.branch}: ${task.user.branch.name}`;
	} else if (task.user) {
		taskBranchClient += `${lan.client}: ${task.user.name}`;
	}

	return `${taskInfo} ${taskBranchClient} ${taskPriority}`;
};
