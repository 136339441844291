import {  CadetBlueCrayola, LightGreySubtitleColor, FielderPink } from '@core/theme/baseTheme';
import FormControlLabel from '@mui/material/FormControlLabel';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

export const Switch = styled('div')`
	width: 50px;
	height: 28px;
	background-color: ${({ isActive }) => (isActive ? FielderPink : CadetBlueCrayola)};
	display: flex;
	justify-content: ${({ isActive }) => (isActive ? 'flex-end' : 'flex-start')};
	border-radius: 20px;
	padding: 4px;
	cursor: pointer;
	margin: 8px;
`;

export const Handle = styled(motion.div)`
	width: 20px;
	height: 20px;
	background-color: white;
	border-radius: 50%;
`;

export const SwitchLabel = styled(FormControlLabel)`
	color: ${LightGreySubtitleColor};
	font-size: 1rem;
	font-family: "Lato" !important;
`;
