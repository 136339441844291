import React, { useContext, useEffect, useState } from 'react';

import { ConfigProvider } from 'antd';
import languages from 'FielderUtils/languages/languages';
import getMomentLocale from 'FielderUtils/languages/momentLocale';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';

import { selectUser } from '../../../core/store/modules/session/sessionSelectors';

const LanguageContext = React.createContext();
const LanguageUpdateContext = React.createContext();

export function useLanguage() {
	return useContext(LanguageContext);
}
export function useLanguageUpdate() {
	return useContext(LanguageUpdateContext);
}

const langStrings = new LocalizedStrings(languages());

export const LanguageProvider = ({ children }) => {
	const user = useSelector(selectUser);

	const [language, setLanguage] = useState(user ? user.language : 'en_US');
	const momentLocale = React.useMemo(() => getMomentLocale(user ? user.language : 'en_US'), [language]);

	useEffect(() => {
		if (user && (user.language != language || langStrings.getLanguage() != user.language)) {
			setLanguage(user.language);
			langStrings.setLanguage(user.language);
		}
		// TODO: Else, get Browser default lang
	}, [user]);

	const getContextValue = React.useCallback(() => langStrings, [langStrings])

	return (
		<LanguageContext.Provider value={getContextValue()}>
			<LanguageUpdateContext.Provider value={setLanguage}>
				<ConfigProvider locale={momentLocale}>{children}</ConfigProvider>
			</LanguageUpdateContext.Provider>
		</LanguageContext.Provider>
	);
};
