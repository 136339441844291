import React from 'react';

import AddIcon from '@assets/icons/add-user-icon.svg';
import { ListItemButton, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const AddGlobalUserSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.addGlobalUser;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={() => goToPage(ENDPOINTS.globalUser)}>
				<ListItemIcon>
					<img src={AddIcon} alt='globalUser' style={{ width: '20px' }} />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default AddGlobalUserSideMenu;
