export const green = {
	background: '#85DA4E2E',
	text: '#67C12D',
};

export const pink = {
	background: '#EC60A33D',
	text: '#EC60A3',
};

export const purple = {
	background: '#BAA0EAA6',
	text: '#7A44DC',
};

export const grey = {
	background: '#D1D9E1',
	text: '#707579',
};

export const blue = {
	background: '#5A84EA2B',
	text: '#4575EC',
};

export const yellow = {
	background: '#FDE36373',
	text: '#E8AD4D',
};

export const orange = {
	background: '#FFDFBA',
	text: '#F2962D',
};

export const wineRed = {
	background: '#FFB9B9',
	text: '#A31818',
};
