import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import useValidatePermission from 'FielderUtils/Hooks/useValidatePermission';
import analyticsConst from 'FielderUtils/roleManagementConst/analyticsConst';
import { useSelector } from 'react-redux';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser, selectValidPages } from '../../../../core/store/modules/session/sessionSelectors';

const AnalyticsSideMenu = ({ goToPage }) => {
	const user = useSelector(selectUser);
	const validPages = useSelector(selectValidPages);
	const lan = useLanguage();
	const title = lan.analytics;

	const [isAllowedToSee, setAllowedToSee] = useState(false);
	const [isAllowed, validatePermission] = useValidatePermission(user, Permission.ANALYTICS);

	useEffect(() => {
		validatePermission(user, Permission.ANALYTICS);
		setAllowedToSee(
			validPages && validPages[analyticsConst.PAGE_NAME] && validPages[analyticsConst.PAGE_NAME].isAllowed && isAllowed
		);
	}, [user, isAllowed, validatePermission, validPages]);

	return (
		<>
			{isAllowedToSee ? (
				<Tooltip title={title} placement='right'>
					<ListItemButton onClick={goToPage(ENDPOINTS.analytics)}>
						<ListItemIcon>
							<img className='' src={`${process.env.AWS_FIELDER_ICONS}analytics-icon.svg`} alt='analytics' />
						</ListItemIcon>
						<ListItemText primary={title} />
					</ListItemButton>
				</Tooltip>
			) : null}
		</>
	);
};

export default AnalyticsSideMenu;
