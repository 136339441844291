import React, { useMemo } from 'react';

import { FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

import { Label, FielderCheckbox } from './Checkbox.styles';

const Checkbox = ({ label, checked, toggle, ...props }) => {
	const CheckboxControl = useMemo(
		() => <FielderCheckbox checked={checked} onChange={toggle} {...props} />,
		[checked, toggle, props.disabled]
	);
	const CheckboxLabel = useMemo(() => <Label variant='body2'>{label}</Label>, [label]);

	return <FormControlLabel control={CheckboxControl} label={CheckboxLabel} onChange={toggle} />;
};

Checkbox.propTypes = {
	label: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
	checked: PropTypes.bool,
};

Checkbox.defaultProps = {
	checked: false,
};

export default Checkbox;
