import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

class LocationFormatter extends React.Component {
	render() {
		const type = this.props.value;
		let icon = false;

		if (type == true) {
			icon = true;
		}

		return (
			<div className='row no-padding'>
				{icon != false && (
					<Tooltip title={this.props.lan.newLocationAvailable}>
						<NotListedLocationIcon color='disabled' />
					</Tooltip>
				)}
			</div>
		);
	}
}

export default LocationFormatter;
