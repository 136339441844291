import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const MyOperationSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.myOperation;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={goToPage(ENDPOINTS.myOperation)}>
				<ListItemIcon>
					<img className='' src={`${process.env.AWS_FIELDER_ICONS}operation-icon.svg`} alt='myOperation' />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default MyOperationSideMenu;
