import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { TableCell as MuiTableCell, TableSortLabel } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { styled } from '@mui/material/styles';

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	MozUserSelect: 'none',
	margin: `0 4px 0 0`,
	...draggableStyle,
});

const TableCell = styled(MuiTableCell)`
	font-size: 12px;
	font-family: 'Lato', sans-serif;
`;

const ColumnCell = ({
	column,
	onRequestSort,
	index,
	titleColBig,
	cookie,
	cookieCols,
	dragDisabled,
	orderBy,
	order,
}) => {
	const lan = useLanguage();
	const { id, name, empty, align, noOrdering } = column;

	const ColumnName = React.useMemo(() => {
		if (empty) {
			return '';
		}

		if (name) {
			if (name in lan) {
				return lan[name];
			}

			return name;
		}

		return lan[id];
	}, [lan]);

	if (cookie && !(cookie && cookieCols[id])) {
		return null;
	}

	const createSortHandler = (property) => (event) => onRequestSort(event, property);

	return (
		<TableCell
			component={titleColBig && id == 0 ? 'th' : null}
			scope={titleColBig && id == 0 ? 'row' : null}
			key={`col-${id}`}
			style={{ padding: '6px' }}
			align={align ? align : 'center'}
			padding='normal'
			sortDirection={orderBy === id ? order : false}
		>
			<Draggable index={index} draggableId={`${id}-draggable`} isDragDisabled={dragDisabled}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className='table-width-td'
						style={getItemStyle(snapshot.isDragging, {
							...provided.draggableProps.style,
							paddingTop: '.25rem',
							zIndex: 999999,
							display: 'block',
						})}
					>
						{!noOrdering ? (
							<TableSortLabel
								active={orderBy === id}
								direction={orderBy === id ? order : 'asc'}
								onClick={createSortHandler(id)}
								style={{ overflow: 'hidden' }}
								className='ml-2'
							>
								{ColumnName}
							</TableSortLabel>
						) : (
							ColumnName
						)}
					</div>
				)}
			</Draggable>
		</TableCell>
	);
};

export default React.memo(ColumnCell);
