import React from 'react';

import InputBase from '@mui/material/InputBase';
import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Input = styled(InputBase)({
	background: '#efefef',
	borderRadius: '8px',
	transition: 'width 0.5s',
	paddingTop: '0.2%',
	paddingBottom: '0.2%',
});

export const TextFieldSelect = styled(Select)({
	'& .MuiOutlinedInput-notchedOutline': {
		border: '0 !important',
	},
	'& .MuiOutlinedInput-input': {
		color: 'gray',
		fontSize: '14px',
		padding: '9px 14px'
	}
})
