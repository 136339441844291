import RoleUtils from './roles/roleUtils';

export function validatePermissions(user, states, functionality) {
	let response = false;

	if (functionality === 'analytics') {
		if (
			(RoleUtils.isUserAdministrator(user) && states.allowAdminAccessAnalytics) ||
			(RoleUtils.isUserManager(user) && states.allowManagerAccessAnalytics) ||
			RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user)
		) {
			response = true;
		}
	} else if (functionality === 'iso') {
		if (
			(RoleUtils.isUserManager(user) && states.allowManagerAccessIso) ||
			RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
			RoleUtils.isUserAdministrator(user)
		) {
			response = true;
		}
	} else if (functionality === 'createTask') {
		if (
			(RoleUtils.isUserManager(user) && states.allowManagerCreate) ||
			RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
			RoleUtils.isUserAdministrator(user) ||
			RoleUtils.isUserClient(user)
		) {
			response = true;
		}
	} else if (functionality === 'route') {
		if (
			(RoleUtils.isUserManager(user) && states.allowManagerAccessRoute) ||
			RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
			RoleUtils.isUserAdministrator(user)
		) {
			response = true;
		}
	} else if (functionality === 'resource') {
		if (
			(RoleUtils.isUserManager(user) && states.allowManagerAccessResource) ||
			RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
			RoleUtils.isUserAdministrator(user)
		) {
			response = true;
		}
	} else if (functionality === 'billing') {
		if (RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user)) {
			response = true;
		}
	}
	// SIDEBAR VALIDATION
	else if (functionality === 'dynamicAllocation') {
		if (
			RoleUtils.isUserSuperAdministrator(user) ||
			RoleUtils.isUserAdministrator(user) ||
			RoleUtils.isUserManager(user)
		) {
			response = true;
		}
	} else if (functionality === 'assetManagement') {
		response = isAssetPermissionValid(user);
	} else if (functionality === 'inventory') {
		response = isInventoryPermissionValid(user);
	} else if (functionality === 'forms') {
		response = isFormsPermissionValid(user);
	} else if (functionality === 'autoassign') {
		if (RoleUtils.isUserSuperAdministrator(user) || RoleUtils.isUserAdministrator(user)) {
			response = true;
		}
	} else if (functionality === 'skillsConfig') {
		if (
			RoleUtils.isUserSuperAdministrator(user) ||
			RoleUtils.isUserAdministrator(user) ||
			RoleUtils.isUserManager(user)
		) {
			response = true;
		}
	} else if (functionality === 'taskTypes') {
		if (RoleUtils.isUserSuperAdministrator(user) || RoleUtils.isUserAdministrator(user)) {
			response = true;
		}
	} else if (functionality === 'routeExpenses') {
		if (
			RoleUtils.isUserSuperAdministrator(user) ||
			RoleUtils.isUserAdministrator(user) ||
			RoleUtils.isUserManager(user)
		) {
			response = true;
		}
	}

	return response;
}

export function isClientToImport(user, allowClientToImport) {
	if (
		RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
		(RoleUtils.isUserClient(user) && allowClientToImport)
	) {
		return true;
	}
	return false;
}

export function isClientToExport(user, allowClientToExport) {
	if (
		RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
		(RoleUtils.isUserClient(user) && allowClientToExport)
	) {
		return true;
	}
	return false;
}

export function isManagerToImport(user, allowManagerToImport) {
	if (
		RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
		(RoleUtils.isUserManager(user) && allowManagerToImport)
	) {
		return true;
	}
	return false;
}

export function isManagerToExport(user, allowManagerToExport) {
	if (
		RoleUtils.isUserSuperAdministratorAndSuperAdminViewer(user) ||
		(RoleUtils.isUserManager(user) && allowManagerToExport)
	) {
		return true;
	}
	return false;
}

export const isFormsPermissionValid = (user) =>
	RoleUtils.isUserSuperAdministrator(user) || RoleUtils.isUserAdministrator(user);

export const isAssetPermissionValid = (user) =>
	RoleUtils.isUserSuperAdministrator(user) || RoleUtils.isUserAdministrator(user);

export const isInventoryPermissionValid = (user) =>
	RoleUtils.isUserSuperAdministrator(user) || RoleUtils.isUserAdministrator(user);
