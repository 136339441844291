import React from 'react';

import { Fade, Menu } from '@mui/material';

import SubMenuItem from './SubMenuItem';

const ForwardSubMenu = React.forwardRef((props, ref) => <Menu {...props} ref={ref} />);

const SubMenu = ({ children, anchor, onClose }) => {
	return (
		<ForwardSubMenu anchorEl={anchor} open={!!anchor} onClose={onClose} TransitionComponent={Fade}>
			{children.map((element, i) => {
				if (element.isValid) {
					return (
						<SubMenuItem
							key={`SubMenuItem-${element.title}-${i}`}
							image={element.img}
							goToPage={element.goToPage}
						>
							{element.title}
						</SubMenuItem>
					);
				}
				return null;
			})}
		</ForwardSubMenu>
	);
};

export default SubMenu;
