import { StatusColor } from '../../constants/task/colorCodes.constants';
import Status from '../../constants/task/TaskStatus.constants';

const managingStatus = () => ({
	text: 'taskStatus_' + Status.MANAGING,
	...StatusColor[Status.MANAGING],
});

const waitingForAgentStatus = (ownership) => {

	if (ownership.isClient) {
		return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
	} else if (!ownership.hasPartnerRelation) {
		return {
			text: 'taskStatus_' + Status.WAITING_FOR_AGENT,
			...StatusColor[Status.WAITING_FOR_AGENT],
		};
	} else if (ownership.isOwner || !ownership.isExecutor) {
		return {
			text: 'taskStatus_' + Status.ASSIGNED_TO_PARTNER,
			...StatusColor[Status.ASSIGNED_TO_PARTNER],
		};
	}
	return {
		text: 'taskStatus_' + Status.WAITING_FOR_AGENT,
		...StatusColor[Status.WAITING_FOR_AGENT],
	};
};

const inProgressStatus = () => ({
	text: 'taskStatus_' + Status.IN_PROGRESS,
	...StatusColor[Status.IN_PROGRESS],
});

const executingStatus = () => ({
	text: 'taskStatus_' + Status.EXECUTING,
	...StatusColor[Status.EXECUTING],
});

const finishStatus = () => ({ text: 'taskStatus_' + Status.FINISH, ...StatusColor[Status.FINISH] });

const cancelStatus = (ownership) => {
	if (ownership.hasPartnerRelation && ownership.isClient) {
		return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
	}
	return { text: 'taskStatus_' + Status.CANCEL, ...StatusColor[Status.CANCEL] };
};

const startRouteStatus = () => ({
	text: 'taskStatus_' + Status.START_ROUTE,
	...StatusColor[Status.START_ROUTE],
});

const cancelByPartnerStatus = (ownership) => {
	if (ownership.hasPartnerRelation && ownership.isClient) {
		return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
	} else if (!ownership.isOwner) {
		return {
			text: 'taskStatus_' + Status.CANCEL_BY_PARTNER,
			...StatusColor[Status.CANCEL_BY_PARTNER],
		};
	} else {
		return { text: 'taskStatus_' + Status.CANCEL, ...StatusColor[Status.CANCEL] };
	}
};

const assignedToPartnerStatus = (ownership) => {
	if (ownership.hasPartnerRelation && ownership.isClient) {
		return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
	}

	let text = '';

	if (ownership.isOwner || ownership.isLastOwner) {
		text = 'taskStatus_' + Status.ASSIGNED_TO_PARTNER;
	} else {
		text = 'taskStatus_8_1';
	}

	return {
		text: text,
		...StatusColor[Status.ASSIGNED_TO_PARTNER],
	};
};

const assignedToTeamStatus = (ownership) => {
	if (!ownership.hasPartnerRelation || (ownership.hasPartnerRelation && ownership.isExecutor)) {
		return {
			text: 'taskStatus_' + Status.ASSIGNED_TO_TEAM,
			...StatusColor[Status.ASSIGNED_TO_TEAM],
		};
	}
	return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
};

const arrivedStatus = () => ({
	text: 'taskStatus_' + Status.ARRIVED,
	...StatusColor[Status.ARRIVED],
});

const deletedStatus = () => ({
	text: 'taskStatus_' + Status.DELETED,
	...StatusColor[Status.DELETED],
});

const autoAssigningStatus = (ownership) => {
	if (!ownership.hasPartnerRelation || (ownership.hasPartnerRelation && ownership.isExecutor)) {
		return {
			text: 'taskStatus_' + Status.AUTO_ASSIGNING,
			...StatusColor[Status.AUTO_ASSIGNING],
		};
	}
	return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
};

const autoAssignFailed = (ownership) => {
	if (!ownership.hasPartnerRelation || (ownership.hasPartnerRelation && ownership.isExecutor)) {
		return {
			text: 'taskStatus_' + Status.AUTO_ASSIGNED_FAILED,
			...StatusColor[Status.AUTO_ASSIGNED_FAILED],
		};
	}
	return { text: 'taskStatus_' + Status.MANAGING, ...StatusColor[Status.MANAGING] };
};

const pausedStatus = () => ({
	text: 'taskStatus_' + Status.PAUSED,
	...StatusColor[Status.PAUSED],
});

const reviewStatus = () => ({
	text: 'review',
	...StatusColor[Status.REVIEW],
});

export const getStatus = (status, ownership) => {
	switch (status) {
		case Status.MANAGING:
			return managingStatus();
		case Status.WAITING_FOR_AGENT:
			return waitingForAgentStatus(ownership);
		case Status.IN_PROGRESS:
			return inProgressStatus();
		case Status.EXECUTING:
			return executingStatus();
		case Status.FINISH:
			return finishStatus();
		case Status.CANCEL:
			return cancelStatus(ownership);
		case Status.START_ROUTE:
			return startRouteStatus();
		case Status.CANCEL_BY_PARTNER:
			return cancelByPartnerStatus(ownership);
		case Status.ASSIGNED_TO_PARTNER:
			return assignedToPartnerStatus(ownership);
		case Status.ASSIGNED_TO_TEAM:
			return assignedToTeamStatus(ownership);
		case Status.ARRIVED:
			return arrivedStatus();
		case Status.DELETED:
			return deletedStatus();
		case Status.AUTO_ASSIGNING:
			return autoAssigningStatus(ownership);
		case Status.AUTO_ASSIGNED_FAILED:
			return autoAssignFailed(ownership);
		case Status.PAUSED:
			return pausedStatus();
		case Status.REVIEW:
			return reviewStatus();
		default:
			return null;
	}
};
