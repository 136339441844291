import { createNextState } from '@reduxjs/toolkit';
import { rejects } from 'assert';
import WebSocket from 'isomorphic-ws';
import { resolve } from 'path';

let WSService = null;

class WebSocketService {
	constructor() {
		this.idUser = 0;
		this.idAccount = 0;
		this.page = '';
		this.websocket = null;
		this.messageListeners = [];
		this.isOpen = false;
	}

	getWs = () => this.websocket;

	/**
	 *  Set up WebSocket connection for a new user and
	 *  basic listeners to handle events
	 */
	initSocket = () => {
		this.websocket = new WebSocket(process.env.WEBSOCKET_URL);
		this.websocket.onopen = this.onConnOpen;
		this.websocket.onmessage = this.onMessage;
		this.websocket.reconnectInterval = 1000;
		this.websocket.onclose = this.onConnClose;
		this.websocket.onerror = (c) => console.log('ERROR', c);
	};

	waitForConnection = () => {
		if (!this.isOpen) {
			return new Promise((res, rej) => {
				const maxAttempts = 20;
				const intervalTime = 400; // ms

				let currentAttempt = 0;

				const interval = setInterval(() => {
					if (currentAttempt > maxAttempts - 1) {
						clearInterval(interval);
						this.initSocket();
						rej(new Error('Maximum number of attempts exceeded to connect RealTime'));
					} else if (this.websocket.readyState === 1) {
						clearInterval(interval);
						res();
					}
					currentAttempt++;
				}, intervalTime);
			});
		}
	};

	/**
	 *  Show connection status to us in the log
	 */
	onConnOpen = () => {
		this.isOpen = true;
	};

	/**
	 *  Call the route that will update the connection table info,
	 *  this will create the dynamoDB tables
	 **/
	updateConnectionUserInfo = async (routeKey, idUser, idAccount, page) => {
		await this.waitForConnection();

		if (this.websocket && this.isOpen) {
			this.idUser = idUser;
			this.idAccount = idAccount;
			this.page = page;

			this.websocket.send(
				JSON.stringify({
					action: routeKey,
					message: JSON.stringify({ idUser, idAccount, page }),
				})
			);
		} else {
			console.error(`Realtime connection not found!!`);
			this.initSocket();
			setTimeout(() => {
				this.updateConnectionUserInfo(routeKey, idUser, idAccount, page);
			}, 3000);
		}
	};

	/**
	 *  This function will execute whe the connection
	 *  is lost or this.websocket.close is executed
	 */
	onConnClose = async (e) => {
		this.isOpen = false;
		this.websocket.close();
		if (e.code == 1001 && e.reason == 'Going away') {
			this.initSocket();
			await this.waitForConnection();
			this.updateConnectionUserInfo('updateConnectionUserInfo', this.idUser, this.idAccount, this.page);
		}
		console.warn('Websocket closed!', e);
	};

	/**
	 *  Call the route that will delete the connection table info
	 **/
	deleteConnectionUserInfo = (routeKey, idUser, idAccount, page) => {
		try {
			this.websocket.send(
				JSON.stringify({
					action: routeKey,
					message: JSON.stringify({ idUser, idAccount, page }),
				})
			);
		} catch (error) {
			console.error(error);
		}

		WSService = null;
		console.log('removing', routeKey);
		this.websocket.close();
	};

	/**
	 *  Used by application to send message to the WebSocket API Gateway
	 *  @param routeKey The route key for WebSocket API Gateway
	 *  @param message String message
	 *  message {
	 *    idAccount,
	 *    idUser,
	 *    etc
	 *  }
	 */
	myOperationAction = async (routeKey, idUser, idAccount, idTask) => {
		await this.waitForConnection();

		if (this.websocket && this.isOpen) {
			this.websocket.send(
				JSON.stringify({
					action: routeKey,
					message: JSON.stringify({ idUser, idAccount, idTask }),
				})
			);
		} else {
			console.error(`Websocket connection not found!!`);
		}
	};

	/**
	 *  Used by application to send message to the WebSocket API Gateway
	 *  @param routeKey The route key for WebSocket API Gateway
	 *  @param message String message
	 *  message {
	 *    idAccount,
	 *    idUser,
	 *    etc
	 *  }
	 */
	sendMessage = async (routeKey, message) => {
		await this.waitForConnection();

		if (this.websocket && this.isOpen) {
			this.websocket.send(
				JSON.stringify({
					action: routeKey,
					message: JSON.stringify(message),
				})
			);
		} else {
			console.error(`Websocket connection not found!!`);
		}
	};

	/**
	 *  Used by application to register different listeners for
	 *  different message types
	 */
	addRealTimeListener = (listener) => {
		if (typeof listener !== 'function') {
			return;
		}

		this.messageListeners.push({ listener });
	};

	/**
	 * Handler that receives the actual messages from the WebSocket API
	 * For now it simply returns the parsed message body to the appropriate
	 * registered handler
	 * @param data Message body received from WebSocket
	 */
	onMessage = (data) => {
		if (data) {
			const message = JSON.parse(data.data);
			if (this.messageListeners) {
				this.messageListeners.forEach((element) => {
					if (element && typeof element.listener === 'function') {
						element.listener(message);
					}
				});
			}
		}
	};

	/**
	 * Initialize the Websocket
	 **/
	static initWSService() {
		if (!WSService) {
			WSService = new WebSocketService();
			WSService.initSocket();
		}

		console.log('init WS');

		return WSService;
	}
}

export const getWSService = WebSocketService.initWSService;
