import React, { useContext, useState } from 'react';

const SideBarContext = React.createContext();
const SideBarUpdateContext = React.createContext();

export function useSideBar() {
	return useContext(SideBarContext);
}

export function useSideBarUpdate() {
	return useContext(SideBarUpdateContext);
}

export const SideBarProvider = ({ children }) => {
	const [sideBarInfo, setSideBarInfo] = useState({
		drawerWidth: 240,
		open: false,
	});

	function updateSideBarInfo(open) {
		setSideBarInfo((prevState) => ({
			...prevState,
			open,
		}));
	}

	return (
		<SideBarContext.Provider value={sideBarInfo}>
			<SideBarUpdateContext.Provider value={updateSideBarInfo}>{children}</SideBarUpdateContext.Provider>
		</SideBarContext.Provider>
	);
};
