import React, {Fragment} from 'react';
import {SnackbarKey, useSnackbar, VariantType} from 'notistack';
import {Grid, Paper, Slide} from '@mui/material';
import {
    SnackBarGridContainer,
    SnackBarIconButton,
    SnackBarListItem,
    SnackBarTypoDescription,
    SnackBarTypoTitle
} from './FielderElements.styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    ErrorBgColor,
    ErrorIcolor,
    ErrorIcon,
    ErrorTxtColor,
    InfoBgColor,
    InfoIcolor,
    InfoIcon,
    InfoTxtColor,
    SuccessBgColor,
    SuccessIcolor,
    SuccessIcon,
    SuccessTxtColor,
    WarnigBgColor,
    WarnigIcolor,
    WarnigTxtColor,
    WarningIcon
} from '../../core/theme/baseTheme';

export function useFielderSnackBar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const icons = {
        default: <InfoIcon />,
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
    };
    const variantColors = {
        default: { background: InfoBgColor, text: InfoTxtColor, icon: InfoIcolor },
        success: { background: SuccessBgColor, text: SuccessTxtColor, icon: SuccessIcolor },
        error: { background: ErrorBgColor, text: ErrorTxtColor, icon: ErrorIcolor },
        warning: { background: WarnigBgColor, text: WarnigTxtColor, icon: WarnigIcolor },
        info: { background: InfoBgColor, text: InfoTxtColor, icon: InfoIcolor },
    };
    interface ArgumentsInterface {
        title?: string;
        message?: string;
        variant?: VariantType | 'default' | 'error' | 'success' | 'warning' | 'info';
        closeButton?: boolean;
        duration?: number | null;
    }

    return ({title = 'Fielder', message = '', variant = 'default', 
            closeButton = true, duration = null
            }: ArgumentsInterface = {}) => {
        const action = (key: SnackbarKey) => (
            <SnackBarIconButton size="small" onClick={() => closeSnackbar(key)}
                                style={{color: variantColors[variant].icon}}><CloseIcon fontSize="small"/>
            </SnackBarIconButton>
        );
        enqueueSnackbar(message, {
            variant,
            action: closeButton ? action : undefined,
            content: (key, message) => (
                <Paper elevation={2} style={{backgroundColor: variantColors[variant].background}}>
                    <SnackBarGridContainer container p={1} spacing={0} wrap="nowrap">
                        <Grid item xs={1} style={{color: variantColors[variant].icon}}>
                            {icons[variant]}
                        </Grid>
                        <Grid item xs={10}>
                            <SnackBarListItem
                                primary={
                                    <Fragment>
                                        <SnackBarTypoTitle style={{color: variantColors[variant].text}}>
                                            {title}
                                        </SnackBarTypoTitle>
                                    </Fragment>
                                }
                                secondary={
                                    <Fragment>
                                        <SnackBarTypoDescription style={{color: variantColors[variant].text}}>
                                            {message}
                                        </SnackBarTypoDescription>
                                    </Fragment>
                                }
                            />
                        </Grid>
                        <Grid item xs={1} style={{color: variantColors[variant].icon}}>
                            {closeButton && action(key)}
                        </Grid>
                    </SnackBarGridContainer>
                </Paper>
            ),
            autoHideDuration: duration,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            TransitionComponent: Slide,
        });
    };
}
