export const SuperAdminViewer = {
	name: 'Fielder Super Administrator Viewer',
	pages: [
		'Demo',
		'Agents',
		'Support',
		'MyOperation',
		'Profile',
		'Dashboard',
		'CommandCenter',
		'CreateTask',
		'AutoAssign',
		'Companies',
		'Branches',
		'Clients',
		'Administrators',
		'Managers',
		'Partners',
		'Teams',
		'Analytics',
		'Routes',
		'Configuration',
		'ChangePassword',
		'Calendar',
		'Routes',
		'Skills',
		'Forms',
		'Isochronous',
		'AssetManagement',
		'Inventory',
		'Marketplace',
		'TaskTypes',
		'SMS',
		'GlobalSearch',
		'ResourceAllocationPreferences',
		'ApiPreferences',
		'RouteOptimizationPreferences',
		'DynamicAllocation',
		'BillingSummary',
		'RoleManagement',
		'Expenses',
	],
};

export const SuperAdmin = {
	name: 'Fielder Super Administrator',
	pages: [
		'Demo',
		'Agents',
		'Support',
		'MyOperation',
		'Profile',
		'Dashboard',
		'CommandCenter',
		'CreateTask',
		'AutoAssign',
		'Companies',
		'Branches',
		'Clients',
		'Administrators',
		'Managers',
		'Partners',
		'Teams',
		'Analytics',
		'Routes',
		'Configuration',
		'ChangePassword',
		'Calendar',
		'Routes',
		'Skills',
		'Forms',
		'Isochronous',
		'AssetManagement',
		'Inventory',
		'Marketplace',
		'TaskTypes',
		'SMS',
		'GlobalSearch',
		'ResourceAllocationPreferences',
		'ApiPreferences',
		'RouteOptimizationPreferences',
		'DynamicAllocation',
		'BillingSummary',
		'RoleManagement',
		'Expenses',
	],
};

export const Admin = {
	name: 'Fielder Administrator',
	pages: [
		'Demo',
		'Agents',
		'Support',
		'MyOperation',
		'CommandCenter',
		'Profile',
		'Dashboard',
		'CreateTask',
		'AutoAssign',
		'Companies',
		'Branches',
		'Clients',
		'Managers',
		'Administrators',
		'Partners',
		'Teams',
		'Analytics',
		'ChangePassword',
		'Calendar',
		'Routes',
		'Skills',
		'Forms',
		'Isochronous',
		'AssetManagement',
		'Inventory',
		'Marketplace',
		'TaskTypes',
		'SMS',
		'Configuration',
		'GlobalSearch',
		'ResourceAllocationPreferences',
		'ApiPreferences',
		'RouteOptimizationPreferences',
		'DynamicAllocation',
		'RoleManagement',
		'Expenses',
	],
};

export const Manager = {
	name: 'Fielder Manager',
	pages: [
		'Demo',
		'Agents',
		'Support',
		'MyOperation',
		'Profile',
		'Dashboard',
		'CommandCenter',
		'CreateTask',
		'Companies',
		'Branches',
		'Clients',
		'Analytics',
		'ChangePassword',
		'Calendar',
		'Routes',
		'Skills',
		'Isochronous',
		'GlobalSearch',
		'DynamicAllocation',
		'RoleManagement',
		'Expenses',
		'Configuration',
	],
};

export const Client = {
	name: 'Fielder Client User',
	pages: [
		'Demo',
		'Support',
		'MyOperation',
		'Profile',
		'Dashboard',
		'CreateTask',
		'ChangePassword',
		'Calendar',
		'GlobalSearch',
		'Configuration',
	],
};

export const Agent = {
	name: 'Fielder Agent',
	pages: [],
};

export const RootGlobalAccountUser = {
	name: 'Fielder Root Global Account',
	pages: ['DashboardGlobalAccount', 'ChangePassword'],
};

export const ClientGlobalAccountUser = {
	name: 'Fielder Client Global Account',
	pages: ['DashboardClientGlobalAccount', 'ChangePassword'],
};

export const InternalAdmin = {
	name: 'Fielder Administrator Funcionality',
	pages: ['DashboardInternalAdmin', 'AccountsInternalAdmin', 'Records', 'GlobalUser'],
};
