import { getFielderSession } from 'FielderUtils/session/Session';
import { pipe } from 'lodash/fp';

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import ModulesNames from 'FielderUtils/module/moduleNamesConst.js';
import { post, get, requestHandler, handlePostRequest } from '../request';

const getRequestOption = () => {
	const options = {};
	return options;
};


const getCommandCenterQueryParams = (user, filters, page) => {
	const queryParams = new URLSearchParams();
	queryParams.set('userId', user.idUser);
	queryParams.set('startDate', filters.startDate);
	queryParams.set('endDate', filters.endDate);

	if (filters.page != null) {
		queryParams.set('page', page);
	}

	if (filters.priority) {
		queryParams.set('priority', filters.priority);
	}

	if (filters.status) {
		queryParams.set('status', filters.status);
	}

	if (filters.companyIds) {
		queryParams.set('companyIds', filters.companyIds);
	}

	if (filters.branchIds) {
		queryParams.set('branchIds', filters.branchIds);
	}

	if (filters.teamIds) {
		queryParams.set('teamIds', filters.teamIds);
	}

	if (filters.idAgents) {
		queryParams.set('idAgents', filters.idAgents);
	}

	if (filters.search) {
		queryParams.set('search', filters.search);
	}

	return queryParams;
};

export const getCommandCenterTasks = async (filters, page, onSuccess, onError, loading) => {
	const path = API_LINKS.commandCenterTasks;
	const user = await getSelectUser();

	const queryParams = getCommandCenterQueryParams(user, filters, page);

	const doRequest = pipe(get, requestHandler(onSuccess, onError, loading));
	return doRequest(path, null, queryParams.toString(), {});
};

export const getCommandCenterTask = async (taskId, filters, onSuccess, onError) => {
	const path = API_LINKS.commandCenterTasks;

	const queryParams = new URLSearchParams(filters);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, taskId, queryParams.toString(), {});
};

export const doAssignAgentToTasks = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: 'DO_ACTION_ASSIGN_AGENT_TASKS',
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

// Get export count
export const getExportTaskCount = async (params, onSuccess, onError) => {
	const path = API_LINKS.exportTasks;

	const queryParams = new URLSearchParams(params);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

//Get export count task with forms
export const getExportTaskWithFormsCount = async (params, onSuccess, onError) => {
	const path = API_LINKS.exportTaskWithForms;

	const queryParams = new URLSearchParams(params);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

// Get export count group task
export const getExportGroupTaslCount = async (params, onSuccess, onError) => {
	const path = API_LINKS.exportGroupTask;

	const queryParams = new URLSearchParams(params);
	
	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

// Get export task data
export const getExportTaskList = async (body, onSuccess, onError, idGroup) => {
	let path = API_LINKS.exportTasks;
	if (idGroup) {
		path = API_LINKS.exportGroupTask;
	}
	const session = await getFielderSession();

	body.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(body);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

// Get export task modules count
export const getExportTaskModulesCount = async (params, onSuccess, onError, moduleName) => {
	//const path = API_LINKS.exportModules;
	let path = API_LINKS.exportModules;
	if (moduleName === ModulesNames.APPROVED_FORMS) {
		path = API_LINKS.exportApprovedForms;
	}

	const queryParams = new URLSearchParams(params);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

// Get export task modules data
export const getExportTaskModulesOrFormsApprovedList = async (body, onSuccess, onError, moduleName) => {
	let path = API_LINKS.exportModules;
	if (moduleName === ModulesNames.APPROVED_FORMS) {
		path = API_LINKS.exportApprovedForms;
	}
	
	const session = await getFielderSession();

	body.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(body);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};


export const getTaskWithForm = async (body, onSuccess, onError) => {
	let path = API_LINKS.exportTaskWithForms;
	const session = await getFielderSession();
	body.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(body);
	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};




// Get export task for generate report data
export const getExportTaskIdList = async (body, onSuccess, onError) => {
	const path = API_LINKS.exportReport;
	const session = await getFielderSession();

	body.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(body);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};