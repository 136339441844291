import React, { Component } from 'react';

import CalendarIcon from '@assets/icons/calendar-icon.svg';
import editImageIcon from '@assets/icons/edit-icon.svg';
import Request from 'Object/request/myoperation/resource_allocation';
import { Row, Col } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import { connect } from 'react-redux';
import Popup from '../../Popup';
import EditRouteEndPoint from '../../RouteDetails/EditRouteEndPoint';
import { resourceAllocationMyOperationRequest } from '@core/api/resourceAllocation/resourceAllocation.service';
import '../MyOperation.css';
import TextDetail from '~/TextDetail/TextDetail.component';

class AssignResourcesPopup extends Component {
	constructor(props) {
		super();
		this.state = {
			daypickers: {
				daypickerone: false,
				daypickertwo: false,
			},
			from: null,
			to: null,
			showEditRouteEndPoint: false,
			endLocation: null,
		};
	}

	componentDidMount() {
		this.getIsochronoes();
	}

	getIsochronoes = () => {
		let body = Request.getAllIsochronous();

		const onSuccess = (response) => {
			this.renderIsochronoes(response);
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		resourceAllocationMyOperationRequest(body, onSuccess, null, loadingConfig);
	};

	renderIsochronoes = (response) => {
		if (response.isochronous) {
			this.buildIsochronous(response.isochronous);
		}
	};

	buildIsochronous = (isochronous) => {
		let isochronousSelect = [];
		for (let i = 0; i < isochronous.length; i++) {
			const iso = isochronous[i];
			const agents = iso.agents ? iso.agents.length : 0;
			isochronousSelect.push({
				label: iso.name + ' - #' + this.props.lan.agents + ': ' + agents,
				value: iso,
			});
		}
		this.setState({ isochronous: isochronousSelect, originalIsochronous: isochronous });
	};

	validateData = () => {
		if (this.state.isochrone && this.state.from && this.state.to && this.state.from <= this.state.to) {
			this.setState({ showErrorMessage: false });
			this.assignResources();
		} else {
			this.setState({ showErrorMessage: true });
		}
	};

	assignResources = () => {
		let data = {
			idIsochronous: this.state.isochrone.id,
			fromYear: this.state.from.getFullYear(),
			fromMonth: this.state.from.getMonth(),
			fromDay: this.state.from.getDate(),
			toYear: this.state.to.getFullYear(),
			toMonth: this.state.to.getMonth(),
			toDay: this.state.to.getDate(),
			endLocation: this.state.endLocation,
		};

		let body = Request.getTasksToResourceAllocation(data);

		const onSuccess = (response) => {
			let statusCode = 200;
			this.props.onClose({ statusCode: statusCode, response: response });
		};

		const onError = (response) => {
			this.setState({ showErrorMessage: true });
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		resourceAllocationMyOperationRequest(body, onSuccess, onError, loadingConfig);
	};

	handleFromDate = (date) => {
		this.setState({
			from: date,
		});
	};

	handleToDate = (date) => {
		this.setState({
			to: date,
		});
	};

	render() {
		return (
			<Popup
				title={this.props.lan.allocateResources}
				icon='assign-resources-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.allocateResources}
				buttonOnClick={this.validateData}
			>
				<Row>
					<Col sm={12}>
						<Row>
							<Col className='popup-label'>{this.props.lan.periodTime}</Col>
						</Row>
						<Row className='Fielder-input-border '>
							<Col sm={'auto'}>
								<img
									src={CalendarIcon}
									className='icon my-2'
									alt=''
									onClick={() => this.showDayPickerInput('daypickerone')}
								/>
							</Col>
							<Col>
								{this.state.daypickers.daypickerone && (
									<DayPickerInput
										showOverlay={true}
										onDayChange={this.handleFromDate}
										placeholder={this.props.lan.from}
										inputProps={{ className: ' day-picker-input my-2', id: 'input-date-from' }}
									/>
								)}
								{!this.state.daypickers.daypickerone && (
									<DayPickerInput
										showOverlay={false}
										onDayChange={this.handleFromDate}
										placeholder={this.props.lan.from}
										inputProps={{ className: ' day-picker-input my-2', id: 'input-date-from' }}
									/>
								)}
							</Col>
							<Col sm={'auto'}>
								<img
									src={CalendarIcon}
									className='icon my-2 MyOperation-img-input'
									onClick={() => this.showDayPickerInput('daypickertwo')}
									alt=''
								/>
							</Col>
							<Col>
								{this.state.daypickers.daypickertwo && (
									<DayPickerInput
										showOverlay={true}
										onDayChange={this.handleToDate}
										placeholder={this.props.lan.to}
										inputProps={{ className: ' day-picker-input my-2' }}
									></DayPickerInput>
								)}
								{!this.state.daypickers.daypickertwo && (
									<DayPickerInput
										showOverlay={false}
										onDayChange={this.handleToDate}
										placeholder={this.props.lan.to}
										inputProps={{ className: ' day-picker-input my-2' }}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col>
						<Select
							className='react-select__control full-width mt-1 mb-3'
							options={this.state.isochronous}
							onChange={(event) => {
								this.setState({ isochrone: event.value });
							}}
							placeholder={this.props.lan.isochronous}
							key='select_agent'
						/>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col sm>
						<TextDetail
							label={this.props.lan.routeEndPointTitle}
							text={this.state.endLocation?.address || this.state.routeEndPointDefault}
						/>
					</Col>
					<Col sm='auto' className='my-auto'>
						<img
							src={editImageIcon}
							className='icon'
							alt=''
							onClick={() => this.setState({ showEditRouteEndPoint: !this.state.showEditRouteEndPoint })}
						/>
					</Col>
				</Row>

				{this.state.showErrorMessage && (
					<Row>
						<Col sm={12} className='MyOperation-form-hint'>
							<p>{this.props.lan.errorEnsureInformationCorrect}</p>
						</Col>
					</Row>
				)}

				{this.state.showEditRouteEndPoint && (
					<EditRouteEndPoint
						{...this.props}
						onClose={() => {
							this.setState({
								showEditRouteEndPoint: false,
							});
						}}
						onSaveEndPoint={(endPoint) => {
							this.setState({
								endLocation: endPoint,
								showEditRouteEndPoint: false,
							});
						}}
					/>
				)}
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(AssignResourcesPopup);
