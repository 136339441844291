import React, { Component } from 'react';

import { getAssetsByFilterAsyncSelect } from 'FielderUtils/assets/assets';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import '../MyOperation.css';
import AsyncSearch from '../../AsyncSearch/AsyncSearch';
import Popup from '../../Popup';
import {
	assignAssetsToTaskRequest,
	getTaskAssetsRequest,
	addAssetsToTaskRequest,
} from '@core/api/task/taskModules.service';
import { Chip, Grid, IconButton } from '@mui/material';
import CardComponent from '~/Card/Card.component';
import TextDetail from '~/TextDetail/TextDetail.component';
import SubtitleFormInput from '~/SubtitleFormInput/SubtitleFormInput.component';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

class AssignAssetPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			selectedAssetIds: [],
			selectedAssets: [],
			assignedAssets: [],
			assetIndex: 0,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	componentDidMount() {
		this.getInfo();
	}

	getInfo = () => {
		if (this.props.tasks.length === 1) {
			const body = { idTask: this.props.tasks[0].taskId };
			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = ({ assets }) => {
				if (assets) {
					this.setState({ assignedAssets: assets });
				}
			};

			getTaskAssetsRequest(body, onSuccess, null, loadingConfig);
		} else {
			let tasks = [];

			this.props.tasks.forEach((task) => {
				const body = { idTask: task.taskId };
				const loadingConfig = { dispatch: this.props.dispatch };
				const onSuccess = ({ assets }) => {
					if (assets) {
						tasks = tasks.concat(assets);
						this.setState({ assignedAssets: tasks });
					}
				};

				getTaskAssetsRequest(body, onSuccess, null, loadingConfig);
			});
		}
	};

	getAssetsList = async (event) => {
		if (event.length > 0 || event != null) {
			let assetList = await getAssetsByFilterAsyncSelect(
				event,
				this.state.idAccount,
				getIdToken(),
				true,
				this.props.lan
			);

			return assetList;
		}
	};

	setFieldAssets = (assets) => {
		if (!assets) {
			this.setState({ selectedAssets: [], selectedAssetIds: [] });
			return;
		}

		let wasAssetAdded = assets.length > this.state.selectedAssetIds.length;

		let state = this.state;
		state.selectedAssetIds = [];
		state.selectedAssets = [];

		assets.forEach(({ value: asset }) => {
			state.selectedAssets.push({
				id: asset.id,
				name: asset.name,
				brand: asset.brand,
				model: asset.model,
				type: asset.type,
				serialNumber: asset.serialNumber,
				contract: asset.contract,
			});

			state.selectedAssetIds.push(asset.id);
		});

		let isAssetIndexGreaterThanSelectedAssets =
			this.state.assetIndex > 0 && this.state.assetIndex >= state.selectedAssetIds.length;

		if (isAssetIndexGreaterThanSelectedAssets || wasAssetAdded) {
			state.assetIndex = state.selectedAssetIds.length - 1;
		}

		this.setState(state);
	};

	assignAsset = () => {
		let assetsToAddAux = [];
		let assetsToAdd = [];
		let assignedAssets = [];

		if (this.props.tasks.length === 1) {
			let task = this.props.tasks[0];

			this.state.assignedAssets.forEach((asset) => assignedAssets.push(asset.id));
			this.state.selectedAssets.forEach((asset) => assetsToAddAux.push(asset.id));

			assetsToAdd = assetsToAddAux.concat(assignedAssets);

			const body = {
				idAssets: assetsToAdd,
				idTask: task.taskId,
			};

			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = () => this.props.onClose(true);
			assignAssetsToTaskRequest(body, onSuccess, null, loadingConfig);
		}
		// Append
		else if (this.state.assignedAssets.length + this.state.selectedAssets.length > 0) {
			this.props.tasks.forEach((task) => {
				this.state.assignedAssets.forEach((asset) => {
					assignedAssets.push(asset.id);
				});

				assetsToAdd = this.state.selectedAssets.concat(assignedAssets);

				const body = {
					idAssets: assetsToAdd,
					idTask: task.taskId
				}

				const loadingConfig = { dispatch: this.props.dispatch };
				const onSuccess = () => this.props.onClose(true);

				addAssetsToTaskRequest(body, onSuccess, null, loadingConfig);

			});
		}
	};

	AssignedAssets = () => {
		return this.state.assignedAssets.map((asset, i) => (
			<Grid item xs='auto'>
				<Chip
					id={`chp-assigned-asset-${asset.id}`}
					label={`${asset.id} - ${asset.name}`}
					onDelete={() => this.removeAsset(asset.id)}
				/>
			</Grid>
		));
	};

	removeAsset = (idToRemove) => {
		this.setState((prevState) => ({ assignedAssets: prevState.assignedAssets.filter(({ id }) => id !== idToRemove) }));
	};

	advanceAsset = () => this.updateAssetIndex(1);
	regressAsset = () => this.updateAssetIndex(-1);
	updateAssetIndex = (count) => {
		this.setState(({ assetIndex: index }) => ({
			assetIndex: index + count,
		}));
	};

	AssetCard = () => {
		if (this.state.selectedAssetIds.length === 0) return null;
		const currentAsset = this.state.selectedAssets[this.state.assetIndex];

		return (
			<>
				<Grid item xs={1} sx={{ my: 'auto' }}>
					{this.state.assetIndex > 0 && (
						<IconButton onClick={this.regressAsset}>
							<ChevronLeft />
						</IconButton>
					)}
				</Grid>
				<Grid item xs>
					<CardComponent elevation={2} sx={{ p: 3 }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<SubtitleFormInput>{this.props.lan.asset}</SubtitleFormInput>
							</Grid>
							<Grid item xs={4} md={3}>
								<TextDetail label={this.props.lan.id} text={currentAsset.id} />
							</Grid>
							<Grid item xs={8} md={9}>
								<TextDetail label={this.props.lan.name} text={currentAsset.name} />
							</Grid>

							{currentAsset.brand && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.brand} text={currentAsset.brand} />
								</Grid>
							)}
							{currentAsset.model && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.model} text={currentAsset.model} />
								</Grid>
							)}
							{currentAsset.type && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.type} text={currentAsset.type} />
								</Grid>
							)}
							{currentAsset.serialNumber && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.serialNumber} text={currentAsset.serialNumber} />
								</Grid>
							)}
							{currentAsset.contract && (
								<Grid item xs={12} md={6}>
									<TextDetail label={this.props.lan.contractNumber} text={currentAsset.contract} />
								</Grid>
							)}
						</Grid>
					</CardComponent>
				</Grid>

				<Grid item xs={1} sx={{ my: 'auto' }}>
					{this.state.assetIndex < this.state.selectedAssets.length - 1 && (
						<IconButton onClick={this.advanceAsset}>
							<ChevronRight />
						</IconButton>
					)}
				</Grid>
			</>
		);
	};

	render() {
		return (
			<Popup
				title={this.props.lan.assignAsset}
				icon='add-form-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.save}
				buttonOnClick={this.assignAsset}
			>
				<div>
					<div className='row no-padding'>
						<div className='col mx-2'>
							<SubtitleFormInput>{this.props.lan.assets}</SubtitleFormInput>

							<AsyncSearch
								id='aslct-task-assets'
								className='react-select__control full-width mt-1 mb-3 borderNone'
								getOptions={this.getAssetsList}
								multiple
								onChange={this.setFieldAssets}
								label={this.props.lan.search}
								lan={this.props.lan}
							/>
						</div>
					</div>

					<Grid container>
						<this.AssetCard />
					</Grid>

					{this.props.tasks.length === 1 && (
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<span className='small-popup-label'>{this.props.lan.selectedAssets}</span>
							</Grid>
							<Row>
								<this.AssignedAssets />
							</Row>
						</Grid>
					)}
				</div>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignAssetPopup);
