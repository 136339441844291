import React, { Component } from 'react';

import EditorTask from '../../CreateTask/EditorTask';
import Popup from '../../Popup';

// Custom components

import '../MyOperation.css';

class EditTaskPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agentsListArray: null,
			idAgentSelected: null,
		};
	}

	render() {
		return (
			<Popup show closeButton onClose={this.props.onClose} width='xl' style='margin-top: 0%;'>
				<EditorTask {...this.props} />
			</Popup>
		);
	}
}

export default EditTaskPopup;
