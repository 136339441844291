import React from 'react';

//Library
import ReactDataGrid from 'react-data-grid';
import Grid from '@mui/material/Grid'

//Components
import RouteStatus from '../Status/RouteStatus';
import AgentFormatter from './AgentFormatter';
import AssetStatusFormatter from './AssetStatusFormatter';
import HeaderCheckboxes from './HeaderCheckboxes';
import IconFormatter from './IconFormatter';
import IconFormatterInvitation from './IconFormatterInvitation';
import IconFormatterWarning from './IconFormatterWarning';
import iconTaskInformationFormatter from './iconTaskInformationFormatter';
import InvitationFormatter from './InvitationFormatter';
import RowMenuOptions from './RowMenuOptions';
import IsochronousTypeFormatter from './IsochronousTypeFormatter';
import LocationFormatter from './LocationFormatter';
import AccountStatusFormatter from './AccountStatusFormatter';
import Pagination from './Pagination';
import ScoreFormatter from './ScoreFormatter';
import StatusFormatter from './StatusFormatter';
import TaskGroupStatusFormatter from './TaskGroupStatusFormatter';
import ViewFormatter from './ViewFormatter';

//CSS
import './SmartTable.css';

const {
	DraggableHeader: { DraggableContainer },
} = require('react-data-grid-addons');

let tableHeaders;
var col = [];

class SmartTable extends React.Component {
	constructor(props) {
		super(props);

		var columns = this.getColumns();
		var reorderColumns = this.obtainNewColums();

		let rC = [];
		if (reorderColumns) {
			rC = reorderColumns;
		} else {
			rC = columns;
		}

		this.handleClick = this.handleClick.bind(this);
		this.handleCheck = this.handleCheck.bind(this);

		this.state = {
			columns: rC,
			totalColums: columns,
			rows: [...this.props.elements],
			selectedIndexes: [],
			selectedRows: [],
			cookieTable: this.props.cookieTable,
			cookieName: this.props.cookieName,
			showCheckboxComponent: this.props.showCheckboxComponent,
		};
	}

	getColumns = () => {
		if (this.props.elements && this.props.elements.length > 0) {
			col = [];
			let headers = Object.keys(this.props.elements[0]);

			headers.map((key, index) => {
				//Change the key in both getColumns and obtainNewColums
				var tag = this.props.lan[key];

				if (key === 'icon') {
					col.push({
						key: key,
						name: '',
						width: 90,
						draggable: false,
						sortable: false, //Order
						formatter: IconFormatter,
					});
				} else if (key === 'iconTaskInformation') {
					col.push({
						key: key,
						name: tag,
						width: 110,
						draggable: false,
						sortable: false, //Order
						formatter: iconTaskInformationFormatter,
					});
				} else if (key === 'showLocationIcon') {
					col.push({
						key: key,
						name: '',
						width: 50,
						draggable: false,
						sortable: false, //Order
						formatter: <LocationFormatter {...this.props} />,
					});
				} else if (key === 'accountStatus') {
					col.push({
						key: key,
						name: '',
						width: 50,
						draggable: false,
						sortable: false, //Order
						formatter: <AccountStatusFormatter {...this.props} />,
					});
				} else if (key === 'iconInvitation') {
					col.push({
						key: key,
						name: '',
						width: 50,
						draggable: false,
						sortable: false, //Order
						formatter: IconFormatterInvitation,
					});
				} else if (key === 'iconWarning') {
					col.push({
						key: key,
						name: '',
						width: 40,
						draggable: false,
						sortable: false, //Order
						formatter: <IconFormatterWarning {...this.props} />,
					});
				} else if (key === 'status') {
					col.push({
						key: key,
						name: tag,
						width: 190,
						//resizable:true,
						draggable: false,
						sortable: true, //Order
						formatter: <StatusFormatter {...this.props} />,
						resizable: true,
					});
				} else if (key === 'routeStatus') {
					col.push({
						key: key,
						name: tag,
						width: 190,
						resizable: true,
						draggable: false,
						sortable: true, //Order
						formatter: <RouteStatus {...this.props} />,
					});
				} else if (key === 'taskGroupStatusLowerCase') {
					col.push({
						key: key,
						name: tag,
						width: 180,
						draggable: false,
						sortable: true, //Order
						formatter: <TaskGroupStatusFormatter {...this.props} />,
					});
				} else if (key === 'isoType') {
					col.push({
						key: key,
						name: tag,
						width: 90,
						draggable: false,
						sortable: true, //Order
						formatter: <IsochronousTypeFormatter />,
					});
				} else if (key === 'score') {
					col.push({
						key: key,
						name: tag,
						width: 130,
						draggable: false,
						sortable: true, //Order
						formatter: ScoreFormatter,
					});
				} else if (key == 'view') {
					col.push({
						key: key,
						name: tag,
						//width:90,
						draggable: false,
						sortable: true, //Order
						formatter: ViewFormatter,
					});
				} else if (key === 'assetStatus') {
					col.push({
						key: key,
						name: tag,
						width: 90,
						draggable: true,
						sortable: true, //Order
						formatter: AssetStatusFormatter,
					});
				} else if (key === 'agent') {
					col.push({
						key: key,
						name: tag,
						width: 90,
						resizable: true,
						draggable: false,
						sortable: true, //Order
						formatter: AgentFormatter,
					});
				} else if (key === 'statusInvitation') {
					col.push({
						key: key,
						name: tag,
						//width: 90,
						draggable: false,
						sortable: true, //Order
						formatter: <InvitationFormatter {...this.props} />,
					});
				}else if (key === 'agentTeamList') {
					col.push({
						key: key,
						name: tag,
						width: 190,
						draggable: false,
						sortable: true, //Order
						formatter: <RowMenuOptions {...this.props} />,
						events: () => { console.log()}
					});
				} else if (
					key === 'isOwner' ||
					key === 'isClient' ||
					key === 'isExecutor' ||
					key === 'hasPartnerRelation' ||
					key === 'isoColor' ||
					key === 'isIdGroup' ||
					key === 'partnerTaskRelations' ||
					key === 'validateAreadyRate' ||
					key === 'allGlobalAccountInfo'
				) {
					//TODO do nothing
				} else {
					col.push({
						key: key,
						name: tag,
						resizable: true,
						draggable: true,
						sortable: true,
					});
				}
			});
		}

		return col;
	};

	rowGetter = (i) => {
		return this.state.rows[i];
	};

	onHeaderDrop = (source, target) => {
		const stateCopy = Object.assign({}, this.state);
		const columnSourceIndex = this.state.columns.findIndex((i) => i.key === source);
		const columnTargetIndex = this.state.columns.findIndex((i) => i.key === target);

		stateCopy.columns.splice(
			columnTargetIndex,
			0,
			stateCopy.columns.splice(columnSourceIndex, 1)[0]
		);

		const emptyColumns = Object.assign({}, this.state, { columns: [] });
		this.setState(emptyColumns);

		const reorderedColumns = Object.assign({}, this.state, {
			columns: stateCopy.columns,
		});

		let allHeaders = '';
		for (const element of reorderedColumns.columns) {
			if (
				element.key != 'iconWarning' &&
				element.key != 'icon'
			) {
				let individualheader = element.key;
				allHeaders = allHeaders.concat(individualheader + ' ');
				this.props.cookieTable.set(this.props.cookieName, allHeaders.trimRight(), { path: '/' });
			}
		}

		this.setState(reorderedColumns);
	};

	sortRows = (initialRows, sortColumn, sortDirection) => {
		const comparer = (a, b) => {
			if (sortDirection === 'ASC') {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === 'DESC') {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		return sortDirection === 'NONE' ? initialRows : [...this.state.rows].sort(comparer);
	};

	myGridSort = (sortColumn, sortDirection) => {
		let newRows = this.sortRows(this.props.elements, sortColumn, sortDirection);
		this.setState({ rows: [...newRows] });
	};

	onRowsSelected = (rows) => {
		let state = this.state;
		state.selectedRows = this.state.selectedRows.concat(rows.map((r) => r.row));
		state.selectedIndexes = this.state.selectedIndexes.concat(rows.map((r) => r.rowIdx));
		this.setState(state);
		this.handleCheck(state.selectedIndexes, state.selectedRows);
	};

	onRowsDeselected = (rows) => {
		let rowIndexes = rows.map((r) => r.rowIdx);
		let rowIds = rows.map((r) => r.row);

		let state = this.state;
		state.selectedRows = this.state.selectedRows.filter((i) => rowIds.indexOf(i) === -1);
		state.selectedIndexes = this.state.selectedIndexes.filter((i) => rowIndexes.indexOf(i) === -1);
		this.setState(state);
		this.handleCheck(state.selectedIndexes, state.selectedRows);
	};

	obtainNewColums = () => {
		let headers = Object.keys(this.props.elements[0]);
		let newColumns = [];

		headers.map((key, index) => {
			if (key === 'iconWarning') {
				newColumns.push({
					key: key,
					name: '',
					width: 40,
					draggable: false,
					sortable: false, //Order
					formatter: <IconFormatterWarning {...this.props} />,
				});
			} else if (key === 'icon') {
				newColumns.push({
					key: key,
					name: '',
					width: 90,
					draggable: false,
					sortable: false, //Order
					formatter: IconFormatter,
				});
			} else if (key === 'showLocationIcon') {
				newColumns.push({
					key: key,
					name: '',
					width: 50,
					draggable: false,
					sortable: false, //Order
					formatter: <LocationFormatter {...this.props} />,
				});
			}  else if (key === 'iconInvitation') {
				newColumns.push({
					key: key,
					name: '',
					width: 50,
					draggable: false,
					sortable: false, //Order
					formatter: IconFormatterInvitation,
				});
			} else if (
				key === 'isOwner' ||
				key === 'isClient' ||
				key === 'isExecutor' ||
				key === 'hasPartnerRelation' ||
				key === 'isoColor' ||
				key === 'isIdGroup' ||
				key === 'partnerTaskRelations' ||
				key === 'validateAreadyRate' ||
				key === 'allGlobalAccountInfo'
			) {
				//TODO do nothing
			}
		});

		let res = [];
		tableHeaders = this.props.cookieTable.get(this.props.cookieName);

		if (tableHeaders === undefined) {
			return undefined;
		}

		res = tableHeaders.split(' ');

		for (const element of res) {
			var tag = this.props.lan[element];

			if (element === 'status') {
				newColumns.push({
					key: element,
					name: tag,
					width: 190,
					draggable: true,
					sortable: true,
					formatter: <StatusFormatter {...this.props} />,
				});
			} else if (element === 'iconTaskInformation') {
				newColumns.push({
					key: element,
					name: tag,
					width: 110,
					draggable: false,
					sortable: false, //Order
					formatter: iconTaskInformationFormatter,
				});
			} else if (element === 'routeStatus') {
				newColumns.push({
					key: element,
					name: tag,
					width: 90,
					draggable: true,
					sortable: true, //Order
					formatter: RouteStatus,
				});
			} else if (element === 'taskGroupStatusLowerCase') {
				col.push({
					key: element,
					name: tag,
					width: 150,
					draggable: false,
					sortable: true, //Order
					formatter: <TaskGroupStatusFormatter {...this.props} />,
				});
			} else if (element === 'isoType') {
				newColumns.push({
					key: element,
					name: tag,
					width: 90,
					draggable: false,
					sortable: true, //Order
					formatter: <IsochronousTypeFormatter />,
				});
			} else if (element === 'score') {
				newColumns.push({
					key: element,
					name: tag,
					width: 130,
					draggable: true,
					sortable: true, //Order
					formatter: ScoreFormatter,
				});
			} else if (element === 'view') {
				newColumns.push({
					key: element,
					name: tag,
					//width:90,
					draggable: true,
					sortable: true, //Order
					formatter: ViewFormatter,
				});
			} else if (element === 'assetStatus') {
				newColumns.push({
					key: element,
					name: tag,
					width: 90,
					draggable: true,
					sortable: true, //Order
					formatter: AssetStatusFormatter,
				});
			} else if (element === 'agent') {
				newColumns.push({
					key: element,
					name: tag,
					width: 90,
					draggable: true,
					sortable: true, //Order
					formatter: AgentFormatter,
				});
			}else if (element === 'accountStatus') {
				col.push({
					key: element,
					name: '',
					width: 50,
					draggable: false,
					sortable: false, //Order
					formatter: <AccountStatusFormatter {...this.props} />,
				});
			}
			else if (element === 'statusInvitation') {
				newColumns.push({
					key: element,
					name: tag,
					// width: 90,
					draggable: false,
					sortable: true, //Order
					formatter: <InvitationFormatter {...this.props} />,
				});
			} else if (element === 'agentTeamList') {
				col.push({
					key: element,
					name: tag,
					width: 190,
					draggable: false,
					sortable: true, //Order
					formatter: <RowMenuOptions {...this.props} />,
					events: () => { console.log("")}
				});
			}else if (
				element === 'isOwner' ||
				element === 'isClient' ||
				element === 'isExecutor' ||
				element === 'hasPartnerRelation' ||
				element === 'isoColor' ||
				element === 'isIdGroup' ||
				element === 'partnerTaskRelations' ||
				element === 'validateAreadyRate' ||
				element === 'allGlobalAccountInfo'
			) {
				//TODO do nothing
			} else {
				newColumns.push({
					key: element,
					name: tag,
					resizable: true,
					draggable: true,
					sortable: true,
				});
			}
		}

		return newColumns;
	}

	handleClick = () => {
		let newColumns = this.obtainNewColums();
		this.setState({ columns: newColumns });
	}

	handleCheck = (selectedIndexes, rows) => {
		if (this.props.onCheckBoxSelected) {
			this.props.onCheckBoxSelected(selectedIndexes, rows);
		}
	}

	getState() {
		return this.state;
	}

	isEquivalent(a, b) {
	
			// Create arrays of property names
			var aProps = Object.getOwnPropertyNames(a);
			var bProps = Object.getOwnPropertyNames(b);

			// If number of properties is different,
			// objects are not equivalent
			if (aProps.length != bProps.length) {
				return false;
			}

			for (const element of aProps) {
				var propName = element;

				// If values of same property are not equal,
				// objects are not equivalent
				if (a[propName] !== b[propName]) {
					return false;
				}
			}

			// If we made it this far, objects
			// are considered equivalent
			return true;
		
	
	}

	componentWillUpdate(nextProps, nextState) {
		if (!this.isEquivalent(this.props.elements, nextProps.elements)) {
			var columns = this.getColumns();
			this.setState({
				rows: [...nextProps.elements],
				columns: columns,
			});
		}
		if (nextProps.elements.length != nextState.rows.length) {
			this.setState({ rows: [...nextProps.elements] });
		}
	}

	render() {
		return (
			<React.Fragment>
				<Grid container fluid className='SmartTable-font mb-3'>
					{this.state.showCheckboxComponent ? (
						<HeaderCheckboxes
							{...this.props}
							totalColums={this.state.totalColums}
							icon={this.props.icon}
							action={() => this.props.deleteAction()}
							customActions={this.props.customActions}
							updateColums={() => this.handleClick()}
							showArrangeOptions={this.props.showArrangeOptions}
							cookieTable={this.props.cookieTable}
							cookieName={this.props.cookieName}
						/>
					) : null}
				</Grid>

				<div className='table-smart-table-margin SmartTable-font'>
					<DraggableContainer onHeaderDrop={this.onHeaderDrop}>
						<ReactDataGrid
							columns={this.state.columns}
							rowGetter={this.rowGetter}
							rowsCount={this.state.rows.length}
							ref={(node) => (this.grid = node)}
							rowHeight={55}
							headerRowHeight={40}
							onRowClick={(rowId, row, column) => this.props.onRowClick(rowId, row, column)}
							onGridSort={this.myGridSort}
							enableCellSelect={true }
							enableRowSelect
							rowSelection={{
								showCheckbox: true,
								enableShiftSelect: true,
								onRowsSelected: this.onRowsSelected,
								onRowsDeselected: this.onRowsDeselected,
								selectBy: {
									indexes: this.state.selectedIndexes,
								},
							}}
							{...this.props}
						/>
					</DraggableContainer>

					{this.props.paginationHidden ? (
						''
					) : (
						<Pagination
							{...this.props}
							onClick={this.props.onClick}
							noPrev={this.props.noPrev}
							noNext={this.props.noNext}
							selected={this.props.selected}
							onClickPrev={this.props.onClickPrev}
							onClickNext={this.props.onClickNext}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default SmartTable;
