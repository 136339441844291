export const SuperAdminViewer = {
	name: 'Fielder Super Administrator Viewer',
	pages: [
		'DEMO',
		'AGENTS',
		'SUPPORT',
		'MY_OPERATION',
		'PROFILE',
		'DASHBOARD',
		'COMMAND_CENTER',
		'CREATE_TASK',
		'AUTO_ASSIGN',
		'COMPANIES',
		'BRANCHES',
		'CLIENTS',
		'ADMINISTRATORS',
		'MANAGERS',
		'PARTNERS',
		'TEAMS',
		'ANALYTICS',
		'ROUTES',
		'CONFIGURATION',
		'CHANGE_PASSWORD',
		'CALENDAR',
		'SKILLS',
		'FORMS',
		'ISOCHRONOUS',
		'ASSET_MANAGEMENT',
		'INVENTORY',
		'MARKETPLACE',
		'TASK_TYPES',
		'SMS',
		'GLOBAL_SEARCH',
		'RESOURCE_ALLOCATION_PREFERENCES',
		'API_PREFERENCES',
		'ROUTE_OPTIMIZATION_PREFERENCES',
		'DYNAMIC_ALLOCATION',
		'BILLING_SUMMARY',
		'ROLE_MANAGEMENT',
		'EXPENSES',
	],
};

export const SuperAdmin = {
	name: 'Fielder Super Administrator',
	pages: [
		'DEMO',
		'AGENTS',
		'SUPPORT',
		'MY_OPERATION',
		'PROFILE',
		'DASHBOARD',
		'COMMAND_CENTER',
		'CREATE_TASK',
		'AUTO_ASSIGN',
		'COMPANIES',
		'BRANCHES',
		'CLIENTS',
		'ADMINISTRATORS',
		'MANAGERS',
		'PARTNERS',
		'TEAMS',
		'ANALYTICS',
		'ROUTES',
		'CONFIGURATION',
		'CHANGE_PASSWORD',
		'CALENDAR',
		'SKILLS',
		'FORMS',
		'ISOCHRONOUS',
		'ASSET_MANAGEMENT',
		'INVENTORY',
		'MARKETPLACE',
		'TASK_TYPES',
		'SMS',
		'GLOBAL_SEARCH',
		'RESOURCE_ALLOCATION_PREFERENCES',
		'API_PREFERENCES',
		'ROUTE_OPTIMIZATION_PREFERENCES',
		'DYNAMIC_ALLOCATION',
		'BILLING_SUMMARY',
		'ROLE_MANAGEMENT',
		'EXPENSES',
		'TASK_FILES_MASSIVE_DOWNLOADER',
	],
};

export const Admin = {
	name: 'Fielder Administrator',
	pages: [
		'DEMO',
		'AGENTS',
		'SUPPORT',
		'MY_OPERATION',
		'COMMAND_CENTER',
		'PROFILE',
		'DASHBOARD',
		'CREATE_TASK',
		'AUTO_ASSIGN',
		'COMPANIES',
		'BRANCHES',
		'CLIENTS',
		'MANAGERS',
		'ADMINISTRATORS',
		'PARTNERS',
		'TEAMS',
		'ANALYTICS',
		'CHANGE_PASSWORD',
		'CALENDAR',
		'ROUTES',
		'SKILLS',
		'FORMS',
		'ISOCHRONOUS',
		'ASSET_MANAGEMENT',
		'INVENTORY',
		'MARKETPLACE',
		'TASK_TYPES',
		'SMS',
		'CONFIGURATION',
		'GLOBAL_SEARCH',
		'RESOURCE_ALLOCATION_PREFERENCES',
		'API_PREFERENCES',
		'ROUTE_OPTIMIZATION_PREFERENCES',
		'DYNAMIC_ALLOCATION',
		'ROLE_MANAGEMENT',
		'EXPENSES',
		'TASK_FILES_MASSIVE_DOWNLOADER',
	],
};

export const Manager = {
	name: 'Fielder Manager',
	pages: [
		'DEMO',
		'AGENTS',
		'SUPPORT',
		'MY_OPERATION',
		'PROFILE',
		'DASHBOARD',
		'COMMAND_CENTER',
		'CREATE_TASK',
		'COMPANIES',
		'BRANCHES',
		'CLIENTS',
		'ANALYTICS',
		'CHANGE_PASSWORD',
		'CALENDAR',
		'ROUTES',
		'SKILLS',
		'ISOCHRONOUS',
		'GLOBAL_SEARCH',
		'DYNAMIC_ALLOCATION',
		'ROLE_MANAGEMENT',
		'EXPENSES',
		'CONFIGURATION',
	],
};

export const Client = {
	name: 'Fielder Client User',
	pages: [
		'DEMO',
		'SUPPORT',
		'MY_OPERATION',
		'PROFILE',
		'DASHBOARD',
		'CREATE_TASK',
		'CHANGE_PASSWORD',
		'CALENDAR',
		'GLOBAL_SEARCH',
		'CONFIGURATION',
	],
};

export const Agent = {
	name: 'Fielder Agent',
	pages: [],
};

export const RootGlobalAccountUser = {
	name: 'Fielder Root Global Account',
	pages: ['DASHBOARD_GLOBAL_ACCOUNT', 'CHANGE_PASSWORD'],
};

export const ClientGlobalAccountUser = {
	name: 'Fielder Client Global Account',
	pages: ['DASHBOARD_CLIENT_GLOBAL_ACCOUNT', 'CHANGE_PASSWORD'],
};

export const InternalAdmin = {
	name: 'Fielder Administrator Funcionality',
	pages: ['DASHBOARD_INTERNAL_ADMIN', 'ACCOUNTS_INTERNAL_ADMIN', 'RECORDS', 'GLOBAL_USER', 'ACCOUNT_REVIEW'],
};
