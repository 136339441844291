import React, { useState, useEffect } from 'react';

import ErrorAlertIcon from '@assets/icons/alert-icon.svg';
import { Chip, Typography } from '@mui/material';
import Types from 'FielderUtils/forms/types';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { useLanguage } from 'Context/LanguageContext';
import { saveAs } from 'file-saver';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import { getExportTaskIdList } from '@core/api/task/task';
import { getTemplateFormsListRequest, getTaskFormsReport, generateFormReportRequest } from '@core/api/form/form.service';
import { getTemplateAssetsListRequest, getTaskAssetsReport, generateAssetReportRequest} from '@core/api/asset/asset.service';
import { getTemplateInventoriesListRequest, getTaskInventoriesReport, generateInventoryReportRequest } from '@core/api/inventory/inventory.service';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import LoadingConst from '../../Loading/LoadingConst';
import MainTable from '../../MainTable';
import Popup from '../../Popup';
import moment from 'moment-timezone';
import '../MyOperation.css';

let globalTask = [];
let reportingTemplates = [];

export default function GenerateFormsReportPopup({ isForm, isAsset, isInventory, ...props }) {
	const dispatch = useDispatch();

	const lan = useLanguage();
	const user = useSelector(selectUser);
	const [forms, setForms] = useState([]);
	const [formsComplete, setFormsComplete] = useState([]);
	const [selectedForm, setSelectedForm] = useState(null);
	const [showAlertTextFilter, setShowAlertTextFilter] = useState(false);
	const [textFilter, setTextFilter] = useState(lan.savedFilter);
	const [assets, setAssets] = useState([]);
	const [assetsComplete, setAssetsComplete] = useState([]);
	const [selectedAsset, setSelectedAsset] = useState(null);
	const [inventories, setInventories] = useState([]);
	const [inventoriesComplete, setInventoriesComplete] = useState([]);
	const [selectedInventory, setSelectedInventory] = useState(null);
	// const [reportingTemplates, setReportingTemplates] = useState([]);
	const [updateView, setUpdateView] = useState(false);
	const [title, setTitle] = useState('');
	const [tasks, setTasks] = useState([]);
	const [description, setDescription] = useState('');
	const [errorMessage, setErrorMessage] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowQuestions, setRowQuestions] = useState([]);
	const [from, setFrom] = useState(props.from);
	const [to, setTo] = useState(props.to);

	const [taskFilter, setTaskFilter] = useState(props.taskFilter);
	const [tagsFilter, setTagsFilter] = useState(props.tagsFilter);
	const [datesFilterType, setDatesFilterType] = useState(props.datesFilterType);
	const [avancedFilter, setAvancedFilter] = useState(props.avancedFilter);

	const TASKS_FORMS_LIMIT = 400;
	const XLSX_EXTENSION = 'xlsx';

	const columns = [
		{ id: 'id', padding: 'none', align: 'center' },
		{ id: 'name', name: 'question', padding: 'none', align: 'left' },
		{ id: 'stringType', name: 'type', padding: 'none', align: 'center' },
	];
	let selectedQuestions = [];
	let selectedFields = [];

	useEffect(() => {
		dispatch(loadingStart({ name: props.pageName }));
		getTasks();
		getData();
	}, []);

	useEffect(() => {
		setFrom(props.from);
	}, [props.from]);

	useEffect(() => {
		setTo(props.to);
	}, [props.to]);

	const getTasks = () => {
		if (from && to) {
			const onSuccess = (response) => {
				setTasks(response.tasks);
				globalTask = response.tasks;
				dispatch(loadingStop({ name: props.pageName }));
			}

			const onError = (error) => {
				dispatch(loadingStop({ name: props.pageName }));
				console.log(error)
			}
			const queryExportParams = generateParams(true);
			return getExportTaskIdList(queryExportParams, onSuccess, onError);
		}
	};

	const generateParams = (bringTasks) => {
		const queryFilter = {
			fromDay: from.getDate(),
			fromMonth: from.getMonth()+1,
			fromYear: from.getFullYear(),
			toDay: to.getDate(),
			toMonth: to.getMonth()+1,
			toYear: to.getFullYear(),
			bringTasks: bringTasks,
		}

		if (tagsFilter) {
			queryFilter.tagsFilter = tagsFilter;
		}
		if (datesFilterType) {
			queryFilter.datesFilterType = datesFilterType;
		}

		if (taskFilter) {
			queryFilter.filter = taskFilter;
		}
		if (avancedFilter) {
			queryFilter.avancedFilter = JSON.stringify(avancedFilter);
		}

		return queryFilter
	};


	const getData = () => {
		if (isForm) {
			setTitle(lan.generateFormsReport);
			setDescription(lan.generateFormsReportDescription);

			const onSuccess = manageGetAllFormTemplates;
			const onError = (error) => {
				console.log("error", error);
			} 
			const loadingConfig = { dispatch }
			let body = {bringPartnerForms: true	}
			getTemplateFormsListRequest(body, onSuccess, onError, loadingConfig)

		} else if (isAsset) {
			setTitle(lan.generateAssetReport);
			setDescription(lan.generateAssetReportDescription);

			const loadingConfig = { dispatch }
			const onSuccess = manageGetAllAssets
			const onError = (error) => {
				console.log("error", error);
			} 
			getTemplateAssetsListRequest(onSuccess, onError, loadingConfig)
		} else if (isInventory) {
			setTitle(lan.generateInventoryReport);
			setDescription(lan.generateInventoryReportDescription);

			const onSuccess = manageGetAllInventories;
			const onError = (error) => {
				console.log("error", error);
			} 
			const loadingConfig = { dispatch }
			getTemplateInventoriesListRequest(onSuccess, onError, loadingConfig)
		}
	};

	const binarySearch = (arr, id) => {
		const mid = Math.floor(arr.length / 2);

		if (id == arr[mid].id) {
			return arr[mid];
		}
		if (id < arr[mid].id && arr.length > 1) {
			return binarySearch(arr.splice(0, mid), id);
		}
		if (id > arr[mid].id && arr.length > 1) {
			return binarySearch(arr.splice(mid, arr.length), id);
		}
		return -1;
	};

	const getStringType = (type) => {
		switch (type) {
			case Types.MULTIPLE_CHOICE:
				return lan.multipleChoice;
			case Types.TEXT_FIELD:
				return lan.textFieldQuestion;
			case Types.BINARY_RATING:
				return lan.binaryRating;
			case Types.STARS_RATING:
				return lan.starsRating;
			case Types.CODEBAR_TEXT:
				return lan.barcode;
			case Types.DATE_HOUR:
				return lan.dateHour;
			case Types.NUMERIC_RATING:
				return lan.numericRating;
			case Types.CATALOGUE:
				return lan.catalogue;
			default:
				return lan.unknown;
		}
	};

	const searchTemplate = (template) => {
		let templates;
		let foundTemplate;
		const filteredFields = [];
		const filteredQuestions = [];

		if (isForm) {
			templates = JSON.parse(JSON.stringify(formsComplete));
			foundTemplate = binarySearch(templates, template.value.id);

			foundTemplate.fields.forEach((field) => {
				if (
					field.question.type != Types.FILES &&
					field.question.type != Types.ELECTRONIC_SIGNATURE &&
					field.question.type != Types.CODEBAR_IMG &&
					field.question.type != Types.TEXT_FILE
				) {
					field.id = parseInt(field.question.id);
					field.stringType = getStringType(parseInt(field.question.type));
					filteredFields.push(field);
				}
			});
		} else if (isAsset) {
			templates = JSON.parse(JSON.stringify(assetsComplete));
			foundTemplate = binarySearch(templates, template.value.id);

			if (foundTemplate.fields) {
				foundTemplate.category.fields.forEach((field) => {
					if (
						field.type != Types.FILES &&
						field.type != Types.ELECTRONIC_SIGNATURE &&
						field.type != Types.CODEBAR_IMG &&
						field.type != Types.TEXT_FILE
					) {
						field.id = parseInt(field.id);
						if (field.id < 0) {
							field.stringType = lan.default;
							field.type = Types.CODEBAR_TEXT;

							switch (field.title) {
								case 'Name':
									field.value = foundTemplate.name;
									break;
								case 'Brand':
									field.value = foundTemplate.brand;
									break;
								case 'Model':
									field.value = foundTemplate.model;
									break;
								case 'Type':
									field.value = foundTemplate.type;
									break;
								case 'Serial Number':
								case 'serialNumber':
									field.value = foundTemplate.serialNumber;
									break;
								case 'Contract':
									field.value = foundTemplate.contract;
									break;
							}
						} else {
							field.stringType = getStringType(parseInt(field.type));
						}

						field.name = field.title;
						filteredFields.push(field);
					}
				});
			}

			if (foundTemplate.questions) {
				foundTemplate.questions.forEach((question) => {
					if (
						question.type != Types.FILES &&
						question.type != Types.ELECTRONIC_SIGNATURE &&
						question.type != Types.CODEBAR_IMG &&
						question.type != Types.TEXT_FILE
					) {
						question.id = parseInt(question.id);
						question.stringType = getStringType(parseInt(question.type));
						question.name = question.title;
						filteredQuestions.push(question);
					}
				});
			}
		} else if (isInventory) {
			templates = JSON.parse(JSON.stringify(inventoriesComplete));
			foundTemplate = binarySearch(templates, template.value.id);

			if (foundTemplate.fields) {
				foundTemplate.category.fields.forEach((field) => {
					if (
						field.type != Types.FILES &&
						field.type != Types.ELECTRONIC_SIGNATURE &&
						field.type != Types.CODEBAR_IMG &&
						field.type != Types.TEXT_FILE
					) {
						field.id = parseInt(field.id);
						if (field.id < 0) {
							field.stringType = lan.default;
							field.type = Types.CODEBAR_TEXT;

							switch (field.title) {
								case 'Name':
									field.value = foundTemplate.name;
									break;
								case 'Brand':
									field.value = foundTemplate.brand;
									break;
								case 'Model':
									field.value = foundTemplate.model;
									break;
								case 'Type':
									field.value = foundTemplate.type;
									break;
								case 'Serial Number':
								case 'serialNumber':
									field.value = foundTemplate.serialNumber;
									break;
								case 'Contract':
									field.value = foundTemplate.contract;
									break;
							}
						} else {
							field.stringType = getStringType(parseInt(field.type));
						}

						field.name = field.title;
						filteredFields.push(field);
					}
				});
			}

			if (foundTemplate.questions) {
				foundTemplate.questions.forEach((question) => {
					if (
						question.type != Types.FILES &&
						question.type != Types.ELECTRONIC_SIGNATURE &&
						question.type != Types.CODEBAR_IMG &&
						question.type != Types.TEXT_FILE
					) {
						question.id = parseInt(question.id);
						question.stringType = getStringType(parseInt(question.type));
						question.name = question.title;
						filteredQuestions.push(question);
					}
				});
			}
		} else {
			return;
		}

		foundTemplate.fields = filteredFields;

		setRows(filteredFields);
		setRowQuestions(filteredQuestions);
		setSelectedForm(foundTemplate);
		setSelectedAsset(foundTemplate);
		setSelectedInventory(foundTemplate);
	};

	const setSelectedQuestions = (arr) => {
		selectedQuestions = arr;
	};

	const setSelectedFields = (arr) => {
		selectedFields = arr;
	};

	const isTemplateSelected = () => {
		let id;
		if (isForm) {
			id = selectedForm.id;
		} else if (isAsset) {
			id = selectedAsset.id;
		} else if (isInventory) {
			id = selectedInventory.id;
		}

		const selectedTemplates = JSON.parse(JSON.stringify(reportingTemplates));
		if (selectedTemplates.length > 0) {
			const template = binarySearch(selectedTemplates, id);

			if (template != -1) {
				return true;
			}
		}

		return false;
	};

	const addTemplate = () => {
		const templatesToReport = reportingTemplates;
		const questions = [];
		const fields = [];

		if (isForm) {
			selectedForm.fields.forEach((question, i) => {
				if (selectedQuestions.includes(question.id)) {
					questions.push(question);
				}
			});

			templatesToReport.push({
				id: selectedForm.id,
				form: selectedForm,
				questions,
			});
		} else if (isAsset || isInventory) {
			const selected = isAsset ? selectedAsset : selectedInventory;

			selected.category.fields.forEach((field, i) => {
				if (selectedFields.includes(field.id)) {
					fields.push(field);
				}
			});

			selected.questions.forEach((question, i) => {
				if (selectedQuestions.includes(question.id)) {
					questions.push(question);
				}
			});

			if (isAsset) {
				templatesToReport.push({
					id: selectedAsset.id,
					asset: selectedAsset,
					questions,
					fields,
				});
			} else {
				templatesToReport.push({
					id: selectedInventory.id,
					inventory: selectedInventory,
					questions,
					fields,
				});
			}
		}

		reportingTemplates = templatesToReport;
		// setReportingTemplates(templatesToReport);
		setSelectedForm(null);
		setSelectedAsset(null);
		setSelectedInventory(null);
	};

	const removeSelected = (id) => {
		const newReportingTemplates = reportingTemplates.filter((template) => id != template.id);
		reportingTemplates = newReportingTemplates;
		setUpdateView(!updateView);
		// setReportingTemplates(newReportingTemplates);
	};

	const TemplateDetails = () => (
		<>
			{selectedForm || selectedAsset || selectedInventory ? (
				<>
					{!isTemplateSelected() ? (
						<>
							{isAsset || isInventory ? <AssetInventoryDetailsTables /> : <FormsDetailsTable />}

							<Row className='justify-content-center mt-4'>
								<Col sm={4}>
									<button className='Fielder-button full-width' onClick={addTemplate}>
										{lan.add}
									</button>
								</Col>
							</Row>
						</>
					) : (
						<Row className='justify-content-center'>
							<Col sm='auto' className='mx-auto my-4'>
								<span className='full-width'>{lan.selected}</span>
							</Col>
						</Row>
					)}
				</>
			) : (
				<>
					<Row style={{ height: '420px' }} />
				</>
			)}

			<AddedElements />
		</>
	);

	const loadCookieConfigForm = () => {
		if (
			localStorage.getItem('formConfigPreference') !== undefined &&
			localStorage.getItem('formConfigPreference') !== 'empty'
		) {
			const value = localStorage.getItem('formConfigPreference');
			const cookieReportingTemplates = JSON.parse(value);
			if (cookieReportingTemplates.length > 0) {
				reportingTemplates = cookieReportingTemplates;
				// setReportingTemplates(cookieReportingTemplates);
				setShowAlertTextFilter(true);
				setTextFilter(lan.loadedFilter);
			}
		}
		setUpdateView(!updateView);
	};

	const removeCookieConfigForm = () => {
		if (
			localStorage.getItem('formConfigPreference') !== undefined &&
			localStorage.getItem('formConfigPreference') !== 'empty'
		) {
			setShowAlertTextFilter(true);
			setTextFilter(lan.removedFilter);
			localStorage.setItem('formConfigPreference', 'empty');
		}
	};

	const saveCookieConfigForm = () => {
		localStorage.setItem('formConfigPreference', JSON.stringify(reportingTemplates));
		setShowAlertTextFilter(true);
		setTextFilter(lan.savedFilter);
	};

	const closeFilterAlert = () => {
		setShowAlertTextFilter(false);
	};

	const TemplateSaveConfiguration = () => (
		<>
			<div className='row d-flex justify-content-end mt-2 mx-3'>
				<div className='col'>
					{showAlertTextFilter && (
						<div className='alert alert-success alert-dismissible fade show' role='alert'>
							{textFilter}
							<button
								type='button'
								className='close'
								data-dismiss='alert'
								aria-label='Close'
								onClick={closeFilterAlert}
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
					)}
				</div>

				<div className='col-auto '>
					<button
						type='submit'
						className='btn btn-outline-primary btn-success fielder-button-filter-size '
						onClick={loadCookieConfigForm}
					>
						{lan.loadFilter}
					</button>
				</div>

				<div className='col-auto'>
					<button
						type='submit'
						className='btn btn-outline-primary btn-success fielder-button-filter-size '
						onClick={removeCookieConfigForm}
					>
						{lan.removeFilter}
					</button>
				</div>

				<div className='col-auto'>
					<button
						type='submit'
						className='btn btn-outline-primary btn-success fielder-button-filter-size '
						onClick={saveCookieConfigForm}
					>
						{lan.saveFilter}
					</button>
				</div>
			</div>
		</>
	);

	const FormsDetailsTable = () => (
		<>
			<Row className='mt-4'>
				<Col sm={11} className='mx-auto'>
					<Typography variant='overline'>{lan.fields}</Typography>
				</Col>
			</Row>
			<Row className='my-2'>
				<Col sm={10} className='mx-auto'>
					<MainTable
						dense
						selectAllRowsInitially
						updateSelected={(i, selectedRows) => setSelectedQuestions(selectedRows.map((row) => row.id))}
						height={300}
						hideTooltips
						cols={columns}
						rows={rows}
						{...props}
					/>
				</Col>
			</Row>
		</>
	);

	const AssetInventoryDetailsTables = () => (
		<>
			<Row className='mt-4'>
				<Col sm={11} className='mx-auto'>
					<Typography variant='overline'>{lan.fields}</Typography>
				</Col>
			</Row>
			<Row className='my-2'>
				<Col sm={10} className='mx-auto'>
					<MainTable
						dense
						selectAllRowsInitially
						updateSelected={(i, selectedRows) => setSelectedFields(selectedRows.map((row) => row.id))}
						height={200}
						hideTooltips
						cols={columns}
						rows={rows}
						{...props}
					/>
				</Col>
			</Row>

			<Row className='mt-4'>
				<Col sm={11} className='mx-auto'>
					<Typography variant='overline'>{lan.questions}</Typography>
				</Col>
			</Row>
			<Row className='my-2'>
				<Col sm={10} className='mx-auto'>
					<MainTable
						dense
						selectAllRowsInitially
						updateSelected={(i, selectedRows) => setSelectedQuestions(selectedRows.map((row) => row.id))}
						height={200}
						hideTooltips
						cols={columns}
						rows={rowQuestions}
						{...props}
					/>
				</Col>
			</Row>
		</>
	);

	const manageGetAllFormTemplates = (formsListParse) => {
		const formOptionSelected = [];
		const formList = formsListParse.forms;

		formList.forEach((form) => {
			formOptionSelected.push({
				value: form,
				label: `${form.name} - ${form.originType}`+''+(form.accountId != user.idAccount  ? ` [${lan.partner}: ${form.accountId} ]`: '') ,
			});
		});

		setFormsComplete(formList);
		setForms(formOptionSelected);
	};

	const manageGetAllAssets = (assetListParse) => {
		const assetOptionsSelected = [];
		const assetList = assetListParse.assets;

		assetList.forEach((asset) => {

				assetOptionsSelected.push({
				value: asset,
				label: `${asset.id} - ${asset.name} (${asset.category ? asset.category.name : '' })`,
			});
		});

		setAssetsComplete(assetList);
		setAssets(assetOptionsSelected);
	};

	const manageGetAllInventories = (inventoryListParse) => {
		const inventoryOptionsSelected = [];
		const inventoryList = inventoryListParse.inventories;

		inventoryList.forEach((inventory) => {
			inventoryOptionsSelected.push({
				value: inventory,
				label: `${inventory.id} - ${inventory.name} (${inventory.category ? inventory.category.name : '' })`,
			});
		});

		setInventoriesComplete(inventoryList);
		setInventories(inventoryOptionsSelected);
	};

	const AddedElements = () => {
		let templateTitle = '';
		let type;

		if (isForm) {
			templateTitle = lan.selectedForms;
			type = 'form';
		} else if (isAsset) {
			templateTitle = lan.selectedAssets;
			type = 'asset';
		} else if (isInventory) {
			templateTitle = lan.selectedInventories;
			type = 'inventory';
		}

		return (
			<Row className='my-2 py-2'>
				<Col sm={11} className='mx-auto'>
					<Row>
						<Col>
							<Typography variant='caption'>{templateTitle}</Typography>
						</Col>
					</Row>
					<Row className='pb-3'>
						<Col>
							{reportingTemplates.map((template) => (
								<Chip
									label={`${template.id} - ${template[type].name}`}
									onDelete={() => removeSelected(template.id)}
									className='mx-2 mt-3'
									key={template.id}
								/>
							))}
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	const getFormsWithCorrectFields = (form, formToReport) => {
		form.fields = form.fields.filter((f1) => formToReport.questions.some((f2) => f1.question.id === f2.question.id));
		return form;
	};

	const getAssetInventoryWithCorrectFields = (assetInventory, assetInventoryToReport) => {
		const filteredFields = [];
		const filteredQuestions = [];

		assetInventoryToReport.fields.forEach((f1) => {
			assetInventory.fields.forEach((f2) => {
				if (f1.id == f2.id) {
					filteredFields.push(f2);
				}
			});
			if (assetInventory.category && assetInventory.category.fields) {
				assetInventory.category.fields.forEach((f2) => {
					if (parseInt(f1.id) < 0 && f1.id == f2.id) {
						filteredFields.push(f1);
					}
				});
			}
		});

		assetInventoryToReport.questions.forEach((f1) => {
			assetInventory.questions.forEach((f2) => {
				if (f1.id == f2.id) {
					filteredQuestions.push(f2);
				}
			});
		});

		assetInventory.fields = filteredFields;
		assetInventory.questions = filteredQuestions;

		return assetInventory;
	};

	const generateReport = () => {
		if (!globalTask || globalTask.length === 0) {
			setErrorMessage(lan.noTaskSelected);
			return;
		}

		if (reportingTemplates.length > 0) {
			if (isForm) {
				generateFormsReport();
			} else if (isAsset) {
				generateAssetReport();
			} else if (isInventory) {
				generateInventoryReport();
			}
		} else {
			setErrorMessage(lan.selectMoreThanOneForm);
		}
	};

	const generateAssetReport = () => {
		dispatch(loadingStart({ name: props.pageName }));

		let body = {
			tasks: globalTask,
		};

		const onSuccess = (response) => {
			const { tasksAssets } = response;

			for (const taskId in tasksAssets) {
				const newAssets = [];
				const assetsFromTask = tasksAssets[taskId];

				assetsFromTask.forEach((asset) => {
					reportingTemplates.forEach((assetToReport) => {
						if (assetToReport.id == asset.id) {
							const filteredAsset = getAssetInventoryWithCorrectFields(asset, assetToReport);
							newAssets.push(filteredAsset);
						}
					});
				});

				if (newAssets.length > 0) {
					tasksAssets[taskId] = newAssets;
				} else {
					delete tasksAssets[taskId];
				}
			}

			let maxLength = 0;
			reportingTemplates.forEach((asset) => {
				if (asset.fields.length > maxLength) {
					maxLength = asset.fields.length;
				}
			});

			if (tasksAssets && Object.keys(tasksAssets).length) {
				body = {
					tasksAssets: tasksAssets,
					lan: lan._language,
					formatExcel: reportingTemplates.length === 1,
					max_question_length: maxLength
				}

				if (body.formatExcel) {
					body.base_fields = reportingTemplates[0].fields;
					body.base_questions = reportingTemplates[0].questions;
				}

				const onSuccess = (response) => {
					reportingTemplates = [];
					window.open(response.link);
					dispatch(loadingStop({ name: props.pageName }));
					props.onClose();
				}

				const onError = (error) => {
					console.log(error)
					setErrorMessage(lan.tryAgainLater);
					dispatch(loadingStop({ name: props.pageName }));
					props.onClose();
				}

				const loadingConfig = { dispatch }
				generateAssetReportRequest(body, onSuccess, onError, loadingConfig)

			} else {
				dispatch(loadingStop({ name: props.pageName }));
				setErrorMessage(lan.noTaskAssets);
			}
		}

		const onError = (error) => {
			console.log(error)
		}

		getTaskAssetsReport(body, onSuccess, onError, null)
	};

	const generateInventoryReport = () => {
		dispatch(loadingStart({ name: props.pageName }));

		let body = {
			tasks: globalTask,
		};

		let tasksInventories;

		const onSuccess = (response) => {
			tasksInventories = response.tasksInventories;

			for (const taskId in tasksInventories) {
				const newInventories = [];
				const inventoriesFromTask = tasksInventories[taskId];

				inventoriesFromTask.forEach((inventory) => {
					reportingTemplates.forEach((inventoriesToReport) => {
						if (parseInt(inventoriesToReport.id) == parseInt(inventory.id)) {
							const filteredInventory = getAssetInventoryWithCorrectFields(inventory, inventoriesToReport);
							newInventories.push(filteredInventory);
						}
					});
				});

				if (newInventories.length > 0) {
					tasksInventories[taskId] = newInventories;
				} else {
					delete tasksInventories[taskId];
				}
			}

			let maxLength = 0;
			reportingTemplates.forEach((inventory) => {
				if (inventory.fields.length > maxLength) {
					maxLength = inventory.fields.length;
				}
			});

			if (tasksInventories && Object.keys(tasksInventories).length) {
				body = {
					tasksInventories: tasksInventories,
					lan: lan._language,
					formatExcel: reportingTemplates.length === 1,
					max_question_length: maxLength,
				}

				if (body.formatExcel) {
					body.base_fields = reportingTemplates[0].fields;
					body.base_questions = reportingTemplates[0].questions;
				}

				const onSuccess = (response) => {
					reportingTemplates = [];
					window.open(response.link);
					dispatch(loadingStop({ name: props.pageName }));
					props.onClose();
				}

				const onError = (error) => {
					console.log(error)
					setErrorMessage(lan.tryAgainLater);
					dispatch(loadingStop({ name: props.pageName }));
					props.onClose();
				}

				const loadingConfig = { dispatch }
				generateInventoryReportRequest(body, onSuccess, onError, loadingConfig)

			} else {
				dispatch(loadingStop({ name: props.pageName }));
				setErrorMessage(lan.noTaskHasInventories);
			}

		}

		const onError = (error) => {
			console.log(error)
		}

		getTaskInventoriesReport(body, onSuccess, onError, null)

	};


	const splitObject = (tasksForms) => {
		const formsArray = [];
		let formsObject = {};
		let count = 0;
	  
		for (const key in tasksForms) {
		  if (count >= TASKS_FORMS_LIMIT) {
			formsArray.push(formsObject);
			formsObject = {};   
			count = 0;
		  }
		  formsObject[key] = tasksForms[key];
		  count++;
		}
		
		if (Object.keys(formsObject).length > 0) {
			formsArray.push(formsObject);
		}
	  
		return formsArray;
	}

	const setFileToZip = async (fileLink, fileName, zip, extension) => {
		await new Promise((resolve, reject) => {
			JSZipUtils.getBinaryContent(fileLink, (err, data) => {
				const newFileName = `${fileName}.${extension}`;

				// Add File To Zip
				zip.file(newFileName, data, { binary: true });
				resolve();
			});
		});
	};


	const generateFormsReport = async () => {
		try {
			
		
			dispatch(
				loadingStart({
					name: props.pageName,
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportMessage,
						progress: 0,
					},
				})
			);

			const NUMBER_OF_TASKS = 100;

			let tempTasks;
			let tasksToReport = globalTask;
		
			let loadingFactor = (NUMBER_OF_TASKS * 100) / tasksToReport.length;
			if (loadingFactor < 1) {
				Math.ceil(loadingFactor)
			}
			else {
				Math.round(loadingFactor) 
			}
			//let loadingFactor = Math.round(100 / (globalTask.length / NUMBER_OF_TASKS));
			let progressCount = 0;
			

			let body = {
				singleAccount: true,
				formsToReport: reportingTemplates.map((element) => element.id),
			};

			let tasksForms = {};

			while (tasksToReport.length > 0) {

				if (tasksToReport.length > NUMBER_OF_TASKS) {
					tempTasks = tasksToReport.slice(0, NUMBER_OF_TASKS);
					tasksToReport = tasksToReport.slice(NUMBER_OF_TASKS, tasksToReport.length);
				} else {
					tempTasks = tasksToReport;
					tasksToReport = [];
				}

				body.tasks = tempTasks;

		
				await new Promise((resolve, reject) => {

					progressCount++;
					
					dispatch(
						loadingStart({
							name: props.pageName,
							config: {
								icon: LoadingConst.EXPORT,
								text: lan.exportMessage,
								progress: progressCount * loadingFactor ,
							},
						})
					);

					const onSuccess = (response) => {
						tasksForms = {
							...tasksForms,
							...response.tasksForms,
						};
						resolve();
					}

					const onError = (error) => {
						console.log(error)
						reject();
					}

					getTaskFormsReport(body, onSuccess, onError, null)

				});
			}

			progressCount = 0;
			dispatch(
				loadingStart({
					name: props.pageName,
					config: {
						icon: LoadingConst.EXPORT,
						text: lan.exportMessage,
						//progress: (progressCount * 100) /tasksFormsLength,
					},
				})
			);
			//loadingFactor = Math.round(20 / (globalTask.length / Object.keys(tasksForms).length));

			for (const taskId in tasksForms) {
				const newForms = [];
				const formsFromTask = tasksForms[taskId];

				formsFromTask.forEach((form) => {
					const newForm = getFormsWithCorrectFields(
						form,
						reportingTemplates.find((f) => form.id == f.id)
					);
					newForms.push(newForm);
				});

				tasksForms[taskId] = newForms;
							
			}

			

			if (tasksForms && Object.keys(tasksForms).length) {

				const zip = new JSZip();			
				const splitForms = splitObject(tasksForms);
				let countProgress = 0;
				
				dispatch(
					loadingStart({
						name: props.pageName,
						config: {
							icon: LoadingConst.EXPORT,
							text: lan.exportMessage,
							progress: 0,
						},
					})
				);

				for (const formElement of splitForms) {
					
					countProgress++
					body = {
						tasksForms: formElement,
						lan: lan._language,
						formatExcel: reportingTemplates.length === 1
					}

					if (body.formatExcel) {
						body.base_fields = reportingTemplates[0].questions;
					}



					const  onSuccess = async (response) => {
						const fileLink = response.link;
						const auxArray = fileLink.split("/forms/");
						const nameAux = auxArray[1];
						const fileName = nameAux.replace(/\.xlsx$/, '');
						await setFileToZip(fileLink, fileName, zip, XLSX_EXTENSION);
						
						dispatch(
							loadingStart({
								name: props.pageName,
								config: {
									icon: LoadingConst.EXPORT,
									text: lan.exportMessage,
									progress: ((countProgress * 100) / splitForms.length ),
								},
							})
						);
						
						//props.onClose();
					}

					const onError = (error) => {
						console.log(error)
						setErrorMessage(lan.tryAgainLater);
						dispatch(loadingStop({ name: props.pageName }));
						props.onClose();
					}

					const loadingConfig = { dispatch }
					await generateFormReportRequest(body, onSuccess, onError, loadingConfig)
				}

				reportingTemplates = [];
				zip.generateAsync({ type: 'blob' }).then((content) => {
					props.onClose();
					saveAs(content, lan.formsReport+"-"+moment().format('DD-MM-YYYY')+".zip");
					dispatch(loadingStop({ name: props.pageName }));
				});

			} else {
				setErrorMessage(lan.noTaskHasForms);
			}

			dispatch(loadingStop({ name: props.pageName }));
			
		} catch (error) {
			console.log("error")
			console.log(error)
		}
	};

	const closePopup = () => {
		reportingTemplates = [];
		props.onClose();
	};

	return (
		<>
			{errorMessage && (
				<ErrorMessage
					{...props}
					icon={ErrorAlertIcon}
					closeErrorMessage={() => setErrorMessage(null)}
					message={errorMessage}
					showErrorMessage
				/>
			)}

			<Popup
				title={title}
				size='Fielder-ultra-wide'
				width='lg'
				show
				closeButton
				onClose={closePopup}
				buttonText={title}
				buttonOnClick={generateReport}
			>
				<Row>
					<Col>
						<span className='label mt-4'>{description}</span>
					</Col>
				</Row>

				<Row>
					<Col sm={11} className='mx-auto'>
						{isForm && (
							<Select
								className='mt-1'
								onChange={searchTemplate}
								options={forms}
								placeholder={lan.placeHolderForm}
								isClearable={false}
								isSearchable
							/>
						)}

						{isAsset && (
							<Select
								className='mt-1'
								onChange={searchTemplate}
								options={assets}
								placeholder={lan.assets}
								isClearable={false}
								isSearchable
							/>
						)}

						{isInventory && (
							<Select
								className='mt-1'
								onChange={searchTemplate}
								options={inventories}
								placeholder={lan.inventories}
								isClearable={false}
								isSearchable
							/>
						)}
					</Col>
				</Row>

				<TemplateDetails />
				{isForm && <TemplateSaveConfiguration />}
			</Popup>
		</>
	);
}
