import React, { Component } from 'react';

import TaskImage from '@assets/images/tasks.svg';
import loadingLottie from '@assets/lotties/loadingFielder.json';
import TaskStatus from 'FielderUtils/status/taskStatus';
import { Image } from 'react-bootstrap';
import Lottie from 'react-lottie';
import TaskDetailActions from './TaskDetailActions';
import './TaskDetails.css';
import 'FielderUtils/style.css';

class TaskDetailHeader extends Component {
	render() {
		return (
			<div className='row full-width mx-5'>
				<div className='col-auto'>
					<div className='row'>
						<Image src={TaskImage} className='mr-2' />
						<h6 className='my-auto'> {this.props.lan.taskDetail}</h6>
					</div>
				</div>

				<div className='col-auto mt-3'>
					{this.props.showMenuActions &&
						this.props.statusTask != TaskStatus.REVIEW &&
						this.props.session.user.role != 'Fielder Client User' && (
							<TaskDetailActions
								executePopupAction={(popupName) => this.props.executePopupAction(popupName)}
								lan={this.props.lan}
								changeOpacity={this.props.changeOpacity}
								showTaskByEmail={this.props.showTaskByEmail}
								showDisabledTasks={this.props.showDisabledTasks}
                session={this.props.session}
                taskData={this.props.taskData}
                fielderMsg={this.props.fielderMsg}
                onCloseModal={this.props.onCloseModal}
							/>
						)}
				</div>

				{this.props.showLoadingDetail && (
					<div className='col d-flex justify-content-start'>
						<div className='row'>
							<div className='col-12 d-flex justify-content-center no-padding'>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: loadingLottie,
									}}
									height='40px'
									width='40px'
								/>
							</div>
							<div className='col d-flex justify-content-center no-padding'>
								{this.props.lan.loading}
							</div>
						</div>
					</div>
				)}

				<div className='col'>
					<div className='row justify-content-end row-info-height'>
						{this.props.tab == 'info' && (
							<>
								<div className='col-auto no-padding'>
									<span className='textFormat-taskDetail-labels-2'>

										{this.props.lan.idFielder}:
									</span>
								</div>
								<div className='col-auto'>
									<span className='textFormat-taskDetail-info-2'>

										{this.props.taskData.idFielder}
									</span>
								</div>
							</>
						)}
						{this.props.tab != 'info' && (
							<>
								<div className='col-auto no-padding'>
									<span className='textFormat-taskDetail-labels'>

										{this.props.lan.idFielder}:
									</span>
								</div>
								<div className='col-auto'>
									<span className='textFormat-taskDetail-info'>
										{this.props.taskData.idFielder}
									</span>
								</div>
							</>
						)}
						{this.props.tab != 'info' && this.props.tab != 'exe' && this.props.tab != 'route' && (
							<>
								<div className='col-auto no-padding'>
									<span className='textFormat-taskDetail-labels'> {this.props.lan.idCustom}: </span>
								</div>
								<div className='col-auto'>
									<span className='textFormat-taskDetail-info'> {this.props.taskData.number} </span>
								</div>
							</>
						)}
					</div>
					{this.props.tab != 'info' && (
						<div className='row justify-content-end row-info-height'>
							<div className='col-auto  no-padding'>
								<span className='textFormat-taskDetail-labels'> {this.props.lan.folio}: </span>
							</div>
							<div className='col-auto'>
								<span className='textFormat-taskDetail-info'>

									{this.props.taskData.folio
										? this.props.taskData.folio
										: this.props.lan.noFolio}
								</span>
							</div>
							{!this.props.taskData.showClientFields ? (
								<>
									<div className='col-auto no-padding'>
										<span className='textFormat-taskDetail-labels'>

											{this.props.lan.company}:
										</span>
									</div>
									<div className='col-auto'>
										<span className='textFormat-taskDetail-info'>

											{this.props.taskData.company}
										</span>
									</div>
								</>
							) : (
								<>
									<div className='col-auto no-padding'>
										<span className='textFormat-taskDetail-labels'> {this.props.lan.client}: </span>
									</div>
									<div className='col-auto'>
										<span className='textFormat-taskDetail-info'>

											{this.props.taskData.client.name}
										</span>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default TaskDetailHeader;
