
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor } from '@amcharts/amcharts4/charts';
import { create, percent, color } from '@amcharts/amcharts4/core';
import { AquaMarine, SelectiveYellow, Sunglow } from '@core/theme/baseTheme';

function createSeries(chart, field, name, c) {
	const series = chart.series.push(new ColumnSeries());
	series.dataFields.valueY = field;
	series.dataFields.categoryX = 'status';
	series.name = name;
	series.tooltipText = '{name}: {valueY}';
	series.columns.template.fill = color(c);
	series.columns.template.stroke = color(c);
	series.columns.template.width = percent(50);
}

function createStackedSeries(chart, field, name, c) {
	const series = chart.series.push(new ColumnSeries());
	series.dataFields.valueY = field;
	series.dataFields.categoryX = 'status';
	series.name = name;
	series.tooltipText = '{name}: {valueY}';
	series.columns.template.width = percent(50);
	series.columns.template.fill = color(c);
	series.columns.template.stroke = color(c);
	series.stacked = true;
}

export function createChartWithData(data) {
	const chart = create('taskCompletenessChart', XYChart);
	chart.data = data;
	return chart;
}

export function addChartCursor(chart) {
	chart.cursor = new XYCursor();
	chart.cursor.behavior = 'zoomY';
}

export function addChartCategoryAndValueAxis(chart) {
	addCategoryAxisToChart(chart);
	addValueAxisToChart(chart);
}

export function addCategoryAxisToChart(chart) {
	let categoryAxis = chart.xAxes.push(new CategoryAxis());
	categoryAxis.dataFields.category = 'status';
	categoryAxis.renderer.grid.template.location = 0;
	categoryAxis.renderer.minGridDistance = 30;
}

export function addValueAxisToChart(chart) {
	let valueAxis = chart.yAxes.push(new ValueAxis());
	valueAxis.strokeOpacity = 0.1;
	valueAxis.min = 0;
}

export function addSeriesToChart(chart) {
	createSeries(chart, 'total', 'Total', AquaMarine + 'ca');
	createStackedSeries(chart, 'totalClientsOperation', 'Total from clients operation', SelectiveYellow);
	createStackedSeries(chart, 'totalMyOperation', 'Total from my operation', Sunglow);
}