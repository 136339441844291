import React, { Component } from 'react';

import loadingLottie from '@assets/lotties/loadingFielder.json';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactExport from 'react-export-excel';
import Lottie from 'react-lottie';
import Cookies from 'universal-cookie';
import { DashboardColums } from 'FielderUtils/dashboard/dashboardColums';
import SlaMenuDashboard from './SlaMenuDashboard';
import MainTable from 'OldComponents/MainTable';
import { Container } from 'react-bootstrap';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const tableCookie = {
	tableCookieName: 'DashboardTaskSlaTable',
	cookie: new Cookies(),
};

class TaskSlaComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			columns: DashboardColums,
		};
	}

	setColumns = (cols) => {
		let state = this.state;
		state.columns = cols;
		this.setState(state);
	};

	render() {
		return (
			<div
				className={
					this.props.showAgentsTable
						? 'Dashboard-taskSlaCompanyBranch-open Dashboard-box mt-3'
						: 'Dashboard-taskSlaCompanyBranch  Dashboard-box mt-3'
				}
			>
				<div className='Dashboard-box-content'>
					<div className='row-auto'>
						<div className='row'>
							<div className='col-auto'>
								<p className='Dashboard-box-title'>{this.props.lan.summaryTaskSla}</p>
							</div>

							<ExcelFile
								filename={this.props.lan.taskCompanyDashboardSla}
								element={<button id='myExcellCompanySla' hidden />}
							>
								<ExcelSheet data={this.props.exportableDataSlaTaskCompany} name={this.props.lan.task}>
									{this.props.exportableDataSlaTaskCompanyHeaders.map((col) => (
										<ExcelColumn key={col.label} label={col.label} value={col.key} />
									))}
								</ExcelSheet>
							</ExcelFile>

							{this.props.renderDailyCompanyTaskSla && (
								<div className='col'>
									<div className='row'>
										<div className='col-auto mt-1 pt-4'>
											<Tooltip title={this.props.lan.slaInformation}>
												<InfoOutlinedIcon color='primary' />
											</Tooltip>
										</div>
										<div className='col-auto mt-1 pt-4'>
											<img
												src={`${process.env.AWS_FIELDER_ICONS}import-icon.svg`}
												className='icon Fielder-icon-button'
												alt='Exportar'
												title={this.props.lan.export}
												style={{ marginTop: '-5px' }}
												onClick={this.props.downloadExcelDailyCompanySla}
												id='id-export-agent'
											/>
										</div>
										<div className='col-auto mt-1 pt-3 d-flex justify-content-end'>
											<SlaMenuDashboard handleTaskSLA={this.props.handleTaskSLA} slaTime={this.props.slaTime} />
										</div>
									</div>
								</div>
							)}
						</div>

						{this.props.renderDailyCompanyTaskSla ? (
						<React.Fragment>
							<Container fluid style={{ overflow: 'none' }}>
							<div className="row">
								<MainTable
									cookie={tableCookie}
									getDynamoCookies={true}
									reorderCols={this.setColumns}
									cols={this.state.columns}
									rows={this.props.dailyTaskCompany}
									hideTooltips={true}
									disabled={true}
									dragDisabled={false}
									stickyHeader
									height={290}
									{...this.props}
								/>
								</div>
							</Container>
						</React.Fragment>
						) : (
							<div className='row'>
								<div className='col-12 d-flex justify-content-center no-padding marginLoadingTaskBriefSla'>
									<Lottie
										options={{
											loop: true,
											autoplay: true,
											animationData: loadingLottie,
										}}
										height='40px'
										width='40px'
									/>
								</div>
								<div className='col d-flex justify-content-center no-padding'>{this.props.lan.loading}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default TaskSlaComponent;
