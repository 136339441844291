// ROLE MANAGEMENT
const PAGE_NAME = 'AUTO_ASSIGN';
const AUTOASSIGN = 'autoassign';
const NAME = 'autoassign';
const ACCOUNT_PREFERENCES = 'showAutoAssign';
const VIEW_TEMPLATES = 0;
const EDIT_TEMPLATES = 1;
const CREATE_TEMPLATES = 2;
const DELETE_TEMPLATES = 3;

export default {
	PAGE_NAME,
	NAME,
	AUTOASSIGN,
	ACCOUNT_PREFERENCES,
	VIEW_TEMPLATES,
	EDIT_TEMPLATES,
	CREATE_TEMPLATES,
	DELETE_TEMPLATES,
};
