import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
		padding: '20px',
		borderRadius: 9,
        backgroundColor: 'white',
		border: `2px solid ${theme.palette.background.paper}`,
        margin: theme.spacing(4),
    },
    logo: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(4),
    },
    logoBackground: {
        width: 'auto',
        height: 'auto',
        position: 'fixed',
        marginTop: '-550px',
		opacity: '0.5'
    },
    line: {
        marginBottom: theme.spacing(2),
        backgroundColor: '#5e6977',
        height: '7px',
		width: '60%'
    },
	errorCode: {
		color: '#5e6977 !important',
		fontFamily: 'Lato !important',
        fontWeight: '700 !important',
    },
    errorText: {
		color: '#5e6977 !important',
		fontFamily: 'Lato !important',
		width: '70%',
        marginBottom: '20px !important',
    },
    button: {
        justifyContent: 'center',
        marginTop: '18px !important',
        marginBottom: '18px !important',
		width: '45%'
    },
}));

export default useStyles;
