export default {
	TASK_CREATE: 'createTask',
	TASK_DISABLE: 'disableTask',
	TASK_DELETE: 'deleteTask',
	TASK_ENABLE: 'enableTask',
	TASK_CANCEL: 'cancelTask',
	TASK_UPDATE: 'updateTask',
	TASK_FINISH: 'taskFinish',
	TASK_AGENT_ASSIGN: 'assignAgentToTask',
	TASK_TEAM_ASSIGN: 'assignTeamToTask',
	TASK_TAGS_CREATE: 'createTagsToTask',
	TASK_FUNCTIONALITIES_ASSIGN: 'assignFuntionalitiesToTask', // Backend typo
	TASK_EXECUTE_AUTO_ASSIGN: 'executeAutoAssignTask',
	TASK_STATUS_UPDATE_AUTO_ASSIGN: 'autoAssignStatusUpdateToTask',
	TASK_PHOTO_DELETE: 'deletePhotoTask',
	TASK_RESCHEDULE: 'rescheduleTask',
	TASK_FORM_ASSIGN: 'assignFormToTask',
	TASK_FORM_REMOVE: 'removeFormTask',
	TASK_ASSET_ASSIGN: 'assignAssetToTask',
	TASK_INVENTORY_ASSIGN: 'assignInventoryToTask',
	TASK_ROUTE_ADD: 'addTaskToRoute',
	TASK_ROUTE_ASSIGNED: 'taskRouteAssigned',
	ROUTE_CANCELLED: 'routeCancelled',
	ROUTE_FINISHED: 'routeFinished',
	ROUTE_REJECTED: 'routeRejected',
	TASK_PARTNER_ASSIGN: 'assignPartnerToTask',
	TASK_GROUP_ASSIGN: 'assignGroupToTask',
	TASK_GROUP_UPDATE_AGENTS: 'taskGroupUpdateAgents',
	TASK_GROUP_UPDATE: 'updateGroupTask',
	TASK_GROUP_REFUSE: 'refuseGroupTask',
	TASK_GROUP_FINISH: 'taskGroupFinish',
	TASK_GROUP_DELETE: 'taskGroupDelete',
	TASK_GROUP_STATUS_UPDATE: 'updateGroupStatus',
	TASK_MOBILE_STATUS_UPDATE: 'updateTaskStatusMobile',
	TASK_MOBILE_TASK_TYPE_UPDATE: 'updateTaskTypeMobile',
	TASK_MOBILE_RESET_EXECUTION: 'resetExecutionTaskMobile',
	TASK_MOBILE_REJECT: 'rejectTaskMobile',
	TASK_MOBILE_COMMENT_ADD: 'addCommentToTaskMobile',
	TASK_MOBILE_PHOTO_UPLOAD: 'uploadPhotoToTask',
	TASK_MOBILE_DOCUMENT_UPLOAD: 'uploadDocumentToTask',
	TASK_MOBILE_FORM_ASSIGN: 'assignFormToTask',
	TASK_MOBILE_ASSET_ASSIGN: 'assignAssetToTask',
	TASK_MOBILE_INVENTORY_ASSIGN: 'assignInventoryToTask',
	TASK_MOBILE_AGENT_ASSIGN: 'assignAgentToTaskMobile',
	TASK_MOBILE_TEAM_ASSIGN: 'assignTeamToTaskMobile',
	TASK_MOBILE_CANCEL: 'cancelTaskMobile',
	TASK_MOBILE_RESCHEDULE: 'rescheduleTaskMobile',
	TASK_MOBILE_GROUP_ASSIGN: 'assignGroupTaskMobile',
	TASK_MOBILE_UPDATE: 'updateTaskMobile',
	AGENT_POSITION_UPDATE: 'updateAgentPosition',
	AGENT_STATUS_UPDATE: 'updateAgentStatus',
	MAIL_COUNTER_UPDATE: 'mailCounter',
	IMAGE_DELETE: 'deleteImage',
	AGENT_CONDITION_UPDATE: 'updateConditionAgent',
	AGENT_CREATE: 'createAgent',
	CREATE_TASK_GROUP: "createTaskGroup",
	UPDATE_TASK_GROUP: "updateTaskGroup",
	UPDATE_TASK_GROUP_STATUS: "updateTaskGroupStatus",
	UNLINK_TASK_FROM_GROUP: "unlinkTaskFromGroup",
};
