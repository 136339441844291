import Status from './processTaskStatus';
import '../style.css';

export const hasAutoAssignFunctionality = (functionalities) => {
	return functionalities.some((func) => {
		if (func.functionality.customCode === 'AUTO_ASSIGN') {
			return true;
		}
		return false;
	});
};

const getStatusColor = (statusVal, lan) => {
	let status = {};

	switch (statusVal) {
		case Status.EXECUTING:
			status.color = 'Fielder-task-autoassigning';
			status.text = lan.executingAutoassign;
			break;
		case Status.FAILED_NO_AGENTS_AVAILABLE:
			status.color = 'Fielder-task-autoassigning-failed';
			status.text = lan.unsuccessful;
			break;
		case Status.FAILED_NO_SKILLS:
		case Status.FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS:
			status.color = 'Fielder-task-autoassigning-failed';
			status.text = lan.unsuccessful;
			break;
		case Status.RETRYING:
			status.color = 'Fielder-task-arrived';
			status.text = lan.retrying;
			break;
		case Status.SUCCESSFULL:
			status.color = 'Fielder-task-finish';
			status.text = lan.successful;
			break;
		case Status.WAITING:
			status.color = 'Fielder-task-managing';
			status.text = lan.waiting;
			break;
		case Status.FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS:
			status.color = 'Fielder-task-managing';
			status.text = lan.unsuccessful;
			break;
		default:
			status.color = '';
			status.text = lan.unknown;
			break;
	}

	return status;
};

export const ProcessStatus = (status, lan) => {
	let info = getStatusColor(status, lan);

	return (
		<Col className={'mx-auto status-square status-' + color}>
			<div>
				<span className='status-text '>{text}</span>
			</div>
		</Col>
	);
};

export const getFailure = (status, lan) => {
	switch (status) {
		case Status.FAILED_NO_AGENTS_AVAILABLE:
			return lan.noAgentsAvailable;
		case Status.FAILED_NO_SKILLS:
			return lan.noAgentsWithSkills;
		case Status.FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS:
			return lan.agentInMultipleTeams;
		default:
			return '';
	}
};
