import React, {useState, useEffect} from 'react';

import SecurityAnnouncement from '@assets/icons/security-announcement-icon.svg';
import { TreeSelect } from 'antd';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import {
	getImportTasksSampleAdmin,
	getImportTasksSampleClient,
	getImportTaskSampleManager,
	getUpdateTasksSampleAdmin,
	getUpdateTasksSampleManager
} from 'FielderUtils/session/Session';
import { listToTree, getTaskTypeList, hasTaskTypeFunctionality } from 'FielderUtils/taskType/taskType';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import { Typography } from '@mui/material';

// Custom components
import LoadingConst from '../../Loading/LoadingConst';
import Popup from '../../Popup';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import { resourceAllocationMyOperationRequest } from '@core/api/resourceAllocation/resourceAllocation.service';
import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import '../MyOperation.css';

import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectAccount } from '@core/store/modules/session/sessionSelectors';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';


let myoperation = require('Object/request/myoperation/resource_allocation');
const IMPORT_NAME_LOADING ="IMPORT_LOADING"

const ImportTaskPopup = (props) => {

	const lan = useLanguage();
	const dispatch = useDispatch();
	const account = useSelector(selectAccount);
	const user = useSelector(selectUser);
	const taskTypeFunctionality = hasTaskTypeFunctionality(account.funcionalityPurchases);

	const getImportTasksSample = () => {
		if (RoleUtils.isUserClient(user)) {
			return getImportTasksSampleClient(user.language);
		}
		if (RoleUtils.isUserManager(user)) {
			return getImportTaskSampleManager(user.language);
		}
		return getImportTasksSampleAdmin(user.language);
	}
	let linkImport = getImportTasksSample();

	const getUpdateTasksSample = () => {
		if (RoleUtils.isUserManager(user)) {
			return getUpdateTasksSampleManager(user.language);
		} 
		return getUpdateTasksSampleAdmin(user.language);
	}

	let updateImport = getUpdateTasksSample();

	const [tab, setTab] = useState('import');
	const [taskType, setTaskType] = useState(1);
	const [taskTypes, setTaskTypes] = useState([
			{
				id: 1,
				name: lan.pickup,
				children: [],
				title: lan.pickup,
				value: 1,
				key: 1,
			},
			{
				id: 2,
				name: lan.workforce,
				children: [],
				title: lan.workforce,
				value: 2,
				key: 2,
			},
			{
				id: 3,
				name: lan.delivery,
				children: [],
				title: lan.delivery,
				value: 3,
				key: 3,
			}
	]);
	
	useEffect(() => {
		getTaskTypes()
	}, []);

	const getTaskTypes = async () => {
		if (taskTypeFunctionality) {
			const { formatTypes } = await getTaskTypeList(true, { lan:lan });
			const tree = listToTree(formatTypes);
			setTaskTypes(tree);
		}
	};

	const uploadFile = (file) => {
		const data = tab === 'import' ? myoperation.importTasks() : tab === 'update' ? myoperation.updateTasks() : myoperation.updateForms();
		
		data.newTaskInfo = {
			idTaskType: taskType,
			importTasksBytes: file,
			idAccount: user ? user.idAccount : 0,
			userToNotify: {
				language: user ? user.language : 'en_US',
				name: user ? user.name : '',
				lastName: user ? user.lastName : '',
				email:  user ? user.email : '',
			},
		};


		const onSuccess = (response) => {
			dispatch(loadingStop(IMPORT_NAME_LOADING));
			props.onClose({ statusCode: 200, response });
		}

		const onError = (error) => {
			console.log(error)
		}

		const loadingConfig = { dispatch }
		resourceAllocationMyOperationRequest(data, onSuccess, onError, loadingConfig);
	};

	const processTasksFile = (event) => {
		const file = event.target.files[0];

		dispatch(loadingStart({ name: IMPORT_NAME_LOADING, config: {
			icon: LoadingConst.IMPORT,
			text: tab === 'import' ? lan.importMessage : tab === 'update' ? lan.updateMessage : lan.updateMessage,
			progress: 100,
		} }));

		getBase64(file, (data) => {
			if (data) {
				uploadFile(data.split(',')[1]);
			} else {
				dispatch(loadingStop(IMPORT_NAME_LOADING));
			}
		});
	};

	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			dispatch(loadingStop(IMPORT_NAME_LOADING));
		};
	};

	const changeTab = (tabAux) => setTab(tabAux);
	const isActive = (tabAux) => (tab === tabAux ? 'td-active' : '');

	const handleOpenFormClick = () => {
		window.open(ENDPOINTS.forms, '_blank');
	};


	const ImportButtonTasksComponent = () => {
		return (
		  <Row className='d-flex justify-content-center'>
			<div className='import-tasks' id="import-tasks">
			  <label htmlFor='file-input' className='image-pointer'>
				<span className='Fielder-button mx-auto'>{tab === 'import' ? lan.import : tab === 'update' ? lan.updateTasks : lan.updateForms}</span>
			  </label>
			  <input type='file' id='file-input' onChange={processTasksFile} accept='.csv' />
			</div>
		  </Row>
		);
	  };

	
	return (
		<Popup width={'lg'} icon='excel-logo-icon.png' show closeButton onClose={props.onClose}>
			<Navbar className='td-navbar mb-3' style={{ zIndex: 9999, backgroundColor: 'white' }}>
				<Nav.Item
					id='nav-import-task'
					className={'td-nav-item text-center ' + isActive('import')}
					style={{ width: '50%' }}
				>
					<Nav.Link className='td-nav-title' onClick={() => changeTab('import')}>
						{lan.importTask}
					</Nav.Link>
				</Nav.Item>
				{!RoleUtils.isUserClient(user) && (
					<>
					<Nav.Item
						id='nav-update-task'
						className={'td-nav-item text-center ' + isActive('update')}
						style={{ width: '50%' }}
					>
						<Nav.Link className='td-nav-title' onClick={() => changeTab('update')}>
							{lan.updateTasks}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item
						id='nav-update-task'
						className={'td-nav-item text-center ' + isActive('updateForm')}
						style={{ width: '100%' }}
					>
						<Nav.Link className='td-nav-title' onClick={() => changeTab('updateForm')}>
							{lan.updateTaskForms}
						</Nav.Link>
							</Nav.Item>
					</>
				)}
				
			</Navbar>
			
			{tab === 'import' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
						<Col className='mx-3'>
							<Typography fontSize="11px">{lan.maxImportMessage}</Typography>
						</Col>
						<Col className='mx-3'>
							<a href={linkImport}>
								<span className='myoperation-link'>
									<u>{lan.downloadTaskTemplate}</u>
								</span>
							</a>
						</Col>
					</Row>
					
				</div>
			)}
			{tab === 'update' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
						<Col className='mx-3'>
							<Typography fontSize="11px">{lan.maxUpdateMessage}</Typography>
						</Col>
						<Col className='mx-3'>
							<a href={updateImport}>
								<span className='myoperation-link'>
									<u>{lan.downloadUpdateTaskTemplate}</u>
								</span>
							</a>	
						</Col>	
					</Row>			
				</div>
			)}
			{tab === 'updateForm' && (
				<div>
					<Row className='mb-1' style={{ color: '#DC5C87' }}>
					
						<Col className='mx-3'>
							{RoleUtils.isUserManager(user) ? 
								<Row >
									<Col className='mx-3'>
										<Typography fontSize="11px" style={{ display: 'inline-block' }} >{lan.downloadFormTemplateManagerMessage1}</Typography>
									</Col>
								</Row>
								:
								<Row >
									<Col className='mx-3'>
										<Typography fontSize="11px" style={{ display: 'inline-block' }} >{lan.downloadFormTemplateMessage1}</Typography>
									
										<Typography className='ml-2 mr-2' fontSize="11px" style={{ display: 'inline-block', color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleOpenFormClick} >{lan.downloadFormTemplateMessage11}</Typography>
								
										<Typography fontSize="11px" style={{ display: 'inline-block' }}>{lan.downloadFormTemplateMessage12}</Typography>
									</Col>
								</Row>
							}
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage2 :lan.downloadFormTemplateMessage2}</Typography>
								</Col>
							</Row>
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage3  : lan.downloadFormTemplateMessage3}</Typography>
								</Col>
							</Row>
							<Row>
								<Col className='mx-3'>
									<Typography fontSize="11px">{RoleUtils.isUserManager(user) ? lan.downloadFormTemplateManagerMessage4  : lan.downloadFormTemplateMessage4}</Typography>
								</Col>
							</Row>
						</Col>
					</Row>
					
				</div>
			)}

			{(tab === 'import' || tab === 'update') && (
				<div>
					<Row className='mt-2'>
						<Col className='mx-3' sm={6}>
							<TreeSelect
								className='full-width'
								id='taskType-red-input'
								treeData={taskTypes}
								dropdownStyle={{ maxHeight: '400px', overflow: 'auto' }}
								onChange={(e) => setTaskType(e)}
								placeholder={lan.taskType}
								defaultValue={1}
							/>
						</Col>
					</Row>

					<Row className='mt-2 mx-1'>
						<Col sm={'auto'} className='no-padding mt-1 d-flex justify-content-center'>
							<img className='mt-2 ml-3' src={SecurityAnnouncement} alt='' />
						</Col>
						<Col>
							<Row className='mt-2 ml-1'>
								<span className='small-popup-label '>{lan.rememberCsvFile}</span>
							</Row>
							<Row className='ml-1'>
								<span className='small-popup-label '>{lan.useLastCsvTemplate}</span>
							</Row>
						</Col>
					</Row>
				</div>
			)}

			<ImportButtonTasksComponent />
	
		</Popup>
	);
}


export default ImportTaskPopup;
