import { Nav } from 'react-bootstrap';

import { DRAWER_WIDTH } from '../../core/theme/baseTheme';
import { AppBar as MuiAppBar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppBar = styled(MuiAppBar)(({ theme, ...props }) => ({
	backgroundColor: 'white !important',
	zIndex: 1001,
	width: props.open ? `calc(100% - ${DRAWER_WIDTH}px)` : '',
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
}));

const MenuButton = styled(IconButton)((props) => ({
	marginRight: 36,
	transition: 'opacity 3 ease-in-out',
	display: props.open? 'hide' : 'auto',
}))

const BlackNavLink = styled(Nav.Link)`
	color: #dc5c87 !important;
`;

const LogoImage = styled('img')`
	height: 6vh;
	min-height: 50px !important;
	vertical-align: middle;
	object-fit: contain;
	max-width: 10vw !important;
	&:hover {
		cursor: pointer;
	}
`;

export { AppBar, BlackNavLink, MenuButton, LogoImage };
