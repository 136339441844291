import React from 'react';
import moment from 'moment';

import { notifyItemStyled, StatusIconAvatar, ProfileAvatar } from '../StyleComponents';
import { Typography, Box, Grid } from '@mui/material';
import FielderConst from 'FielderUtils/fielderConst';

import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';

import { 
    LightGreySubtitleColor, GhostWhite,
    White, CreateColor, ApproveSuccessColor, EditColor, AssignColor, CancelColor,
    ErrorAttentionColor, InviteColor, OtherColor, ProgressColor, TaskIcon,
    RouteIcon, AgentIcon, PartnerIcon, GroupIcon, AutoAssignIcon, ModuleIcon, OtherIcon
} from '@core/theme/baseTheme';
import FielderLogo from '@assets/icons/map_icons/pin-agent-active.svg';
import tempUser from '@assets/icons/temp-user.svg';

import { useDispatch } from 'react-redux';
import { updateNotification } from '@core/store/modules/notifications/notificationsSlice';

const getTimeDifference = (i) => {
    const duration = moment.duration(moment().diff(i));
    const seconds = duration.asSeconds();
    if (seconds < 60) {
        return Math.round(seconds) + "s";
    } else if (seconds < 3600) {
        return Math.round(duration.asMinutes()) + "m";
    } else if (seconds < 86400) {
        return Math.round(duration.asHours()) + "h";
    } else {
        return Math.round(duration.asDays()) + "d";
    }
}
const NotificationItem = ({
    type,
    folio,
    id,
    task,
    time,
    setIdTaskDetail,
    setShowDetailTask,
    setNotificationOpen,
    lan
}) => {
	const dispatch = useDispatch();
    const classes = notifyItemStyled({ read: task.read });
    const navigate = useNavigate();
	const relativeTime = getTimeDifference(time);
    const getFolio = () => {
        return folio? `${lan.folio}: ${folio}` : '';
    }
   
    const ACTIVITY_CODE_LANGUAGE = {
        [FielderConst.ACTIVITY_CODE_UNKNOWN]: lan.unknownCategory,
        [FielderConst.ACTIVITY_CODE_TASK]: lan.task,
        [FielderConst.ACTIVITY_CODE_MODULE]: lan.module,
        [FielderConst.ACTIVITY_CODE_AGENT]: lan.agent,
        [FielderConst.ACTIVITY_CODE_PARTNER]: lan.partner,
        [FielderConst.ACTIVITY_CODE_ROUTE]: lan.route,
        [FielderConst.ACTIVITY_CODE_TASK_GROUP]: lan.groupTask,
        [FielderConst.ACTIVITY_CODE_AUTO_ASSIGN]: lan.autoassign,
    };
    const ACTIVITY_CODE_ICONS = {
        [FielderConst.ACTIVITY_CODE_UNKNOWN]: <OtherIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_TASK]: <TaskIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_MODULE]: <ModuleIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_AGENT]: <AgentIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_PARTNER]: <PartnerIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_ROUTE]: <RouteIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_TASK_GROUP]: <GroupIcon fontSize="small" />,
        [FielderConst.ACTIVITY_CODE_AUTO_ASSIGN]: <AutoAssignIcon fontSize="small" />,
    };
    const ACTIVITY_CODE_COLORS = [
        { activityCodes: FielderConst.ACTIVITY_STATUS_SUCCESS, color: ApproveSuccessColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_CREATE, color: CreateColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_ASSIGN, color: AssignColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_CANCEL, color: CancelColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_EDIT, color: EditColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_ERROR, color: ErrorAttentionColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_INVITE, color: InviteColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_PROGRESS, color: ProgressColor },
        { activityCodes: FielderConst.ACTIVITY_STATUS_UNKNOWN, color: OtherColor },
    ];
    const getActivityGroup = () => {
        for (const activityCode in ACTIVITY_CODE_LANGUAGE) {
            if (activityCode.includes(type)) {
                return (
                    <Stack direction="row" spacing={2} paddingTop={0.2}>
                        <Chip 
                            label={ACTIVITY_CODE_LANGUAGE[activityCode]}
                            size="small"
                            style={{
                                backgroundColor: statusColorNotification(),
                                fontWeight: 100, paddingTop: 1, opacity: 0.9,
                                color: GhostWhite
                            }}
                        />
                    </Stack>
                );
            }
        }
        return null;
    }
    const redirectPartners = () => {
        let stateParam={
			routes: true 
		}
        navigate(ENDPOINTS.myOperation, { stateParam });
        setIdTaskDetail(id);
        setShowDetailTask(true);
        setNotificationOpen(false);
        dispatch(updateNotification({ 
            notificationId: task.idNotification
        }));
    }
    function profileAvatar() {
        let image = null;
        let name = null;
        if (FielderConst.IMG_AUTO_ASSIGN.includes(type)) {
            return getAvatar(FielderLogo, null);
        }
        if (FielderConst.IMG_CLIENT_USER.includes(type)) {
            if(task.clientUser.name === '') {
                image = task.clientUser.image;
                name = lan.automationUser;
            } else {
                image = task.clientUser.image;
                name = task.clientUser.name;
            }
        } else if (FielderConst.IMG_TECHNICIAN.includes(type)) {
            if(task.technician.name === '') {
                image = tempUser;
                name = lan.automationUser;
            } else {
                image = task.technician.image;
                name = task.technician.name;
            }
        } else if (FielderConst.IMG_CLIENT_OR_TECHNICIAN.includes(type)) {
            if(task.technician.name === '') {
                image = FielderLogo;
                name = task.clientUser.name;
            } else {
                image = task.technician.image;
                name = task.technician.name;
            }
        } else {
            image = tempUser;
            name = lan.automationUser;
        }
    
        return getAvatar(image, name);
    }
    function getAvatar(image, name) {
        let nameParts = name ? name.split(' ') : '';
        let firstPart = name ?? nameParts[0] ? nameParts[0][0] : '';
        let secondPart = name ?? nameParts[1] ? nameParts[1][0] : '';
        if (image) {
            return {
                sx: {
                    bgcolor: White,
                    width: 60,
                    height: 60,
                },
                src: image,
            };
        } else {
            return {
                sx: {
                    bgcolor: LightGreySubtitleColor,
                    width: 60,
                    height: 60,
                },
                children: `${firstPart}${secondPart}`,
            };
        }
    }
    const getName = () =>{
        if (FielderConst.IMG_AUTO_ASSIGN.includes(type)) {
            return `${lan.automationUser} - `;
        } 
        if (FielderConst.IMG_CLIENT_USER.includes(type)) {
            return `${task.clientUser.name} - `;

        } else if (FielderConst.IMG_TECHNICIAN.includes(type)) {
            return `${task.technician.name} - `;

        } else if (FielderConst.IMG_CLIENT_OR_TECHNICIAN.includes(type)) {
            if(task.technician.name === '') {
                return `${lan.automationUser} - `;
            } else {
                return `${task.technician.name} - `;
            }
        } else {
            return `${lan.automationUser} - `;
        }
    }
    const statusColorNotification = () => {
        const activityColor = ACTIVITY_CODE_COLORS.find(activity =>
            activity.activityCodes.includes(type)
        );
        return activityColor ? activityColor.color : LightGreySubtitleColor;
    }
    function statusIconAvatar() {
        for (const activityCodeIcon in ACTIVITY_CODE_ICONS) {
            if (activityCodeIcon.includes(type)) {
                return {
                    sx: {
                        bgcolor: statusColorNotification(),
                        width: 30,
                        height: 30,
                    },
                    children: ACTIVITY_CODE_ICONS[activityCodeIcon],
                };
            }
        }
    }
    return (
        <Box className={classes.rootBox} onClick={redirectPartners}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <StatusIconAvatar {...statusIconAvatar()}/>
                        }
                    >
                        <ProfileAvatar {...profileAvatar()}/>
                    </Badge>
                </Grid>
                <Grid item xs={12} md={10}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Box className={classes.titleBox}>
                                    <Typography sx={{ display: 'inline'}} variant="subtitle2">
                                        <span className={classes.titleId}>{id}</span>
                                    </Typography>
                                    <Typography sx={{ display: 'inline' }} variant="body2">
                                        <span className={classes.titleFolio}>{getFolio()}</span>
                                    </Typography>
                                </Box>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                            <Typography  sx={{ display: 'inline' }} component="span" variant="body2">
                                    <span className={classes.descriptionName}>{getName()}</span>
                            </Typography>
                                <span className={classes.descriptionTask}>{task.description}</span>                            
                            </React.Fragment>
                        }
                    />
                    <Box className={classes.footerBox}>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                                        {`${relativeTime} · ${time}`}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>{getActivityGroup()}</React.Fragment>
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default React.memo(NotificationItem);
