import React from 'react';

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { IconButton, Typography} from '@mui/material';

const WidgetLinks = (props) => {
	const documentationInfo = (props && props.state && props.state.documentationLinks) ? props.state.documentationLinks : [];

	const openLink = (link) => {
		window.open(link, '_blank');
	};

  return (
	  <div className="row justify-content-end mr-1">
		  {documentationInfo.map((documentation, index) => (
			<IconButton className="no-padding" onClick={() => openLink(documentation.link)} key={index}>
				<DriveFileMoveIcon key={'icon-file'+index} style={{ color: '#dc5c87', fontSize: 40 }} />
				<Typography key={'icon-file'+index} noWrap style={{ fontFamily: 'Lato', fontSize: 14 }}>
					{documentation.name}
				</Typography>
			</IconButton>
		))}
    </div>
  );
};

export default WidgetLinks;
