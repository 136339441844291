import React, { lazy } from 'react';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import Pages from 'FielderUtils/pages/pages';

import MyOperation from 'OldComponents/MyOperation/MyOperation-container';
import Dashboard from '@pages/Dashboard/Dashboard.page';

const CommandCenter = lazy(() => import('@pages/CommandCenterPage/CommandCenter.page'))
const AutoAssignPage = lazy(() => import('@pages/AutoAssignPage/AutoAssign.page'))
const Isochronous = lazy(() => import('@pages/IsochronousPage/Isochronous.page'))
const Administrators = lazy(() => import('OldComponents/Administrators/Administrators'))
const Analytics = lazy(() => import('OldComponents/Analitics/AnaliticsComponent'))
const Agents = lazy(() => import('OldComponents/Agents'))
const AssetManagement = lazy(() => import('OldComponents/AssetsManagement'))
const Skills = lazy(() => import('OldComponents/Autoassign/SkillsConfiguration'))
const BillingSummary = lazy(() => import('OldComponents/Billing/BillingSummary'))
const Branches = lazy(() => import('OldComponents/BranchOffices/Branches'))
const Calendar = lazy(() => import('OldComponents/Calendar'))
const ChangePassword = lazy(() => import('OldComponents/ChangePassword'))
const Clients = lazy(() => import('OldComponents/Clients/Clients'))
const Companies = lazy(() => import('OldComponents/Companies/Companies'))
const Configuration = lazy(() => import('@pages/Configuration/Configuration.page'))
const CreateTask = lazy(() => import('OldComponents/CreateTask'))
const DynamicAllocation = lazy(() => import('OldComponents/DynamicAllocation'))
const Expenses = lazy(() => import('OldComponents/Expenses'))
const Forms = lazy(() => import('OldComponents/Forms'))
const DashboardClientGlobalAccount = lazy(() => import('OldComponents/GlobalAccountComponents/DashboardClientGlobalAccount'))
const DashboardGlobalAccount = lazy(() => import('OldComponents/GlobalAccountComponents/DashboardGlobalAccount'))
const GlobalSearch = lazy(() => import('OldComponents/GlobalSearch/GlobalSearchComponent'))
const AccountsInternalAdmin = lazy(() => import('OldComponents/InternalAdminComponents/Accounts'))
const DashboardInternalAdmin = lazy(() => import('OldComponents/InternalAdminComponents/DashboardInternalAdmin'))
const GlobalUser = lazy(() => import('OldComponents/InternalAdminComponents/GlobalUser'))
const ReviewAccount = lazy(() => import('OldComponents/InternalAdminComponents/ReviewAccount'))
const Records = lazy(() => import('OldComponents/InternalAdminComponents/Records'))
const Inventory = lazy(() => import('OldComponents/Inventory'))
const InvoiceComponent = lazy(() => import('OldComponents/Invoice/InvoiceComponent'))
const Managers = lazy(() => import('OldComponents/Managers/Managers'))
const Marketplace = lazy(() => import('OldComponents/Marketplace'))
const Partners = lazy(() => import('OldComponents/Partners/Partners'))
const Profile = lazy(() => import('@pages/Profile/Profile.page'))
const RoleManagement = lazy(() => import('OldComponents/RoleManagement/RoleManagement-container'))
const RoutePreferences = lazy(() => import('OldComponents/Routes'))
const SmsPreferences = lazy(() => import('OldComponents/Sms'))
const Support = lazy(() => import('OldComponents/Support'))
const TaskDetails = lazy(() => import('OldComponents/TaskDetails/TaskDetails-container'))
const TaskTypes = lazy(() => import('OldComponents/TaskTypes'))
const Teams = lazy(() => import('OldComponents/Teams/Teams'))
const MassiveDownload = lazy(() => import('Components/TaskFilesMassiveDownloader/TaskFilesMassiveDownloader'))

export default [
	{
		path: ENDPOINTS.globalSearch,
		pageName: Pages.GlobalSearch,
		Component: GlobalSearch,
	},
	{
		path: ENDPOINTS.invoice,
		pageName: Pages.Dashboard,
		Component: InvoiceComponent,
	},
	{
		path: ENDPOINTS.commandCenter,
		pageName: Pages.CommandCenter,
		Component: CommandCenter,
	},
	{
		path: ENDPOINTS.dashboard,
		pageName: Pages.Dashboard,
		Component: Dashboard,
	},
	{
		path: ENDPOINTS.taskDetails,
		pageName: Pages.MyOperation,
		Component: TaskDetails,
	},
	{
		path: ENDPOINTS.agents,
		pageName: Pages.Agents,
		Component: Agents,
	},
	{
		path: ENDPOINTS.profile,
		pageName: Pages.Profile,
		Component: Profile,
	},
	{
		path: ENDPOINTS.configuration,
		pageName: Pages.Configuration,
		Component: Configuration,
	},
	{
		path: ENDPOINTS.administrators,
		pageName: Pages.Administrators,
		Component: Administrators,
	},
	{
		path: ENDPOINTS.managers,
		pageName: Pages.Managers,
		Component: Managers,
	},
	{
		path: ENDPOINTS.partners,
		pageName: Pages.Partners,
		Component: Partners,
	},
	{
		path: ENDPOINTS.teams,
		pageName: Pages.Teams,
		Component: Teams,
	},
	{
		path: ENDPOINTS.myOperation,
		pageName: Pages.MyOperation,
		Component: MyOperation,
	},
	{
		path: ENDPOINTS.companies,
		pageName: Pages.Companies,
		Component: Companies,
	},
	{
		path: ENDPOINTS.branches,
		pageName: Pages.Branches,
		Component: Branches,
	},
	{
		path: ENDPOINTS.clients,
		pageName: Pages.Clients,
		Component: Clients,
	},
	{
		path: ENDPOINTS.support,
		pageName: Pages.Support,
		Component: Support,
	},
	{
		path: ENDPOINTS.createTask,
		pageName: Pages.CreateTask,
		Component: CreateTask,
	},
	{
		path: ENDPOINTS.changePassword,
		pageName: Pages.ChangePassword,
		Component: ChangePassword,
	},
	{
		path: ENDPOINTS.calendar,
		pageName: Pages.Calendar,
		Component: Calendar,
	},
	{
		path: ENDPOINTS.analytics,
		pageName: Pages.Analytics,
		Component: Analytics,
	},
	{
		path: ENDPOINTS.marketplace,
		pageName: Pages.Marketplace,
		Component: Marketplace,
	},
	{
		path: ENDPOINTS.forms,
		pageName: Pages.Forms,
		Component: Forms,
	},
	{
		path: ENDPOINTS.assetManagement,
		pageName: Pages.AssetManagement,
		Component: AssetManagement,
	},
	{
		path: ENDPOINTS.inventory,
		pageName: Pages.Inventory,
		Component: Inventory,
	},
	{
		path: `${ENDPOINTS.autoAssign}/*`,
		pageName: Pages.AutoAssign,
		Component: AutoAssignPage,
	},
	{
		path: ENDPOINTS.skills,
		pageName: Pages.Skills,
		Component: Skills,
	},
	{
		path: `${ENDPOINTS.isochronous}`,
		pageName: Pages.Isochronous,
		Component: Isochronous,
	},
	{
		path: ENDPOINTS.taskTypes,
		pageName: Pages.TaskTypes,
		Component: TaskTypes,
	},
	{
		path: ENDPOINTS.routeOptimization,
		pageName: Pages.RouteOptimizationPreferences,
		Component: RoutePreferences,
	},
	{
		path: ENDPOINTS.resourceAllocation,
		pageName: Pages.ResourceAllocationPreferences,
		Component: RoutePreferences,
	},
	{
		path: ENDPOINTS.sms,
		pageName: Pages.SMS,
		Component: SmsPreferences,
	},
	{
		path: ENDPOINTS.dynamicAllocation,
		pageName: Pages.DynamicAllocation,
		Component: DynamicAllocation,
	},
	{
		path: ENDPOINTS.expenses,
		pageName: Pages.Expenses,
		Component: Expenses,
	},
	{
		path: ENDPOINTS.globalAccountDashboard,
		pageName: Pages.DashboardGlobalAccount,
		Component: DashboardGlobalAccount,
	},
	{
		path: ENDPOINTS.globalAccountClientDashboard,
		pageName: Pages.DashboardClientGlobalAccount,
		Component: DashboardClientGlobalAccount,
	},
	{
		path: ENDPOINTS.internalAdminDashboard,
		pageName: Pages.DashboardInternalAdmin,
		Component: DashboardInternalAdmin,
	},
	{
		path: ENDPOINTS.accounts,
		pageName: Pages.AccountsInternalAdmin,
		Component: AccountsInternalAdmin,
	},
	{
		path: ENDPOINTS.records,
		pageName: Pages.Records,
		Component: Records,
	},
	{
		path: ENDPOINTS.globalUser,
		pageName: Pages.GlobalUser,
		Component: GlobalUser,
	},
	{
		path: ENDPOINTS.accountReview,
		pageName: Pages.AccountReview,
		Component: ReviewAccount,
	},
	{
		path: ENDPOINTS.billingSummary,
		pageName: Pages.BillingSummary,
		Component: BillingSummary,
	},
	{
		path: ENDPOINTS.roleManagement,
		pageName: Pages.RoleManagement,
		Component: RoleManagement,
	},
	{
		path: ENDPOINTS.massiveDownload,
		pageName: Pages.TaskFilesMassiveDownloader,
		Component: MassiveDownload,
	},
];
