// ROLE MANAGEMENT
const PAGE_NAME = 'DYNAMIC_ALLOCATION';
const DYNAMICALLOCATION = 'dynamicallocation';
const NAME = 'dynamicallocation';
const ACCOUNT_PREFERENCES = 'showDynamicAllocation';
const GENERATE = 0;

export default {
	PAGE_NAME,
	NAME,
	DYNAMICALLOCATION,
	ACCOUNT_PREFERENCES,
	GENERATE,
};
