import AgentProfileImage from '@assets/icons/default-profile-icon.svg';
import moment from 'moment';

import ActivityActions from 'FielderUtils/task/activityActions';
import RoleUtils from '../roles/roleUtils';
import TaskStatus from '../status/taskStatus';

export const canClientViewFunctionality = (user, modules) => {
	if (RoleUtils.isUserClient(user) && modules.forms && modules.forms.length > 0) {
		return true;
	}
	return false;
};

export const returnSeverity = (taskSeverity, taskSeverityName, lan) => {
	let severity = '';
	switch (taskSeverity) {
		case 1:
			severity = lan.high;
			break;
		case 2:
			severity = lan.medium;
			break;
		case 3:
			severity = lan.low;
			break;
		default:
			severity = taskSeverityName;
			break;
	}
	return severity;
};

export const returnAgentVehicleType = (vehicleType, lan) => {
	let agentVehicleType = '';
	switch (Number(vehicleType)) {
		case 1:
			agentVehicleType = lan.bicycle;
			break;
		case 2:
			agentVehicleType = lan.motorcycle;
			break;
		case 3:
			agentVehicleType = lan.car;
			break;
		case 4:
			agentVehicleType = lan.bus;
			break;
		case 5:
			agentVehicleType = lan.truck;
			break;
		default:
			agentVehicleType = '';
			break;
	}
	return agentVehicleType;
};

export const returnValidationStatus = (
	status,
	session,
	agentCancelled,
	cancelByAnyAgent,
	showCancelStatus,
	cancelByAdmin
) => {
	if (
		status &&
		(status == TaskStatus.ASSIGNED_TO_PARTNER ||
			status == TaskStatus.WAITING_FOR_AGENT ||
			status == TaskStatus.ASSIGNED_TO_TEAM) &&
		session &&
		session.user &&
		session.user.role == 'Fielder Client User'
	) {
		status = TaskStatus.IN_PROGRESS;
	}

	if (
		status &&
		status == TaskStatus.MANAGING &&
		agentCancelled &&
		session.user.role == 'Fielder Client User'
	) {
		status = TaskStatus.IN_PROGRESS;
	}

	if (
		status &&
		status == TaskStatus.CANCEL &&
		cancelByAnyAgent &&
		session &&
		session.user &&
		session.user.role == 'Fielder Client User'
	) {
		status = TaskStatus.IN_PROGRESS;
	}

	if (
		status &&
		(status == TaskStatus.CANCEL_BY_PARTNER || status == TaskStatus.CANCEL) &&
		session.user.role != 'Fielder Client User' &&
		!showCancelStatus &&
		!cancelByAdmin
	) {
		status = TaskStatus.IN_PROGRESS;
	}

	return status;
};

export const returnAgentInfo = (
	agent,
	agentImage,
	lan,
	lastPartnerId,
	userLoggedIdAccount,
	session,
	agentTimeTaken,
	agentDistanceTraveled
) => {
	const { idAgent } = agent;
	const agentName = `${agent.name} ${agent.lastName}`;

	let agentProfilePicture = agentImage;

	let agentWorkScheduleFrom = lan.noWorkingHourFrom;
	let agentWorkScheduleTo = lan.noWorkingHourTo;

	if (agent.workScheduleFrom && agent.workScheduleFrom != '') {
		agentWorkScheduleFrom = agent.workScheduleFrom;
	}

	if (agent.workScheduleTo && agent.workScheduleTo != '') {
		agentWorkScheduleTo = agent.workScheduleTo;
	}

	let agentWorkSchedule = `${agentWorkScheduleFrom} - ${agentWorkScheduleTo}`;

	let agentPhone = `${agent.phone}`;
	let agentEmail = agent.email;
	let agentVehicleType = '';
	let agentVehicleId = '';

	if (agent.vehicleType) {
		agentVehicleType = returnAgentVehicleType(agent.vehicleType, lan);
		agentVehicleId = agent.licencePlate;
	}

	// VALIDATE IF LEVEL PARTNER IS WAITING AGENT
	if (
		lastPartnerId &&
		lastPartnerId != userLoggedIdAccount &&
		session.user.role &&
		session.user.role != 'Fielder Client User'
	) {
		// CHANGE AGENT NAME TO ALL EXCEPT EXECUTOR AND CLIENT
		agentPhone = '';
		agentEmail = '';
		agentVehicleType = '';
		agentVehicleId = '';
		agentWorkSchedule = '';
		agentProfilePicture = AgentProfileImage;
	}

	const agentInfo = {
		idAgent,
		name: agentName,
		workSchedule: agentWorkSchedule,
		phone: agentPhone,
		email: agentEmail,
		vehicleType: agentVehicleType,
		vehicleId: agentVehicleId,
		timeTaken: agentTimeTaken,
		distanceTraveled: agentDistanceTraveled,
		profilePicture: agentProfilePicture,
	};

	return agentInfo;
};

export const returnModules = (forms, assets, inventories) => {
	let modules = null;
	let formsTask = null;
	let assetsTask = null;
	let inventoriesTask = null;

	if (forms !== null) formsTask = forms;
	if (assets !== null) assetsTask = assets;
	if (inventories !== null) inventoriesTask = inventories;

	modules = {
		forms: formsTask,
		assets: assetsTask,
		inventories: inventoriesTask,
	};

	return modules;
};

export const returnUserCreateTask = (userCreateTaskActivity) => {
	const userCreateTask = `${userCreateTaskActivity.user.name} ${userCreateTaskActivity.user.lastName}`;
	return userCreateTask;
};

export const returnCancelComments = (taskCancelComments, session) => {
	let cancelComments = {
		cancelCommentsUser: [],
		cancelCommentsAgent: [],
	};

	if (taskCancelComments) {
		let nameAgent = '';
		let lastNameAgent = '';

		let nameClient = '';
		let lastNameClient = '';

		let commentTime = '';

		const cancelCommentsUser = [];
		const cancelCommentsAgent = [];

		for (let k = 0; k < taskCancelComments.length; k++) {
			if (
				taskCancelComments[k].cancelAgent &&
				!RoleUtils.isUserClient(session.user) &&
				taskCancelComments[k].cancelAgent.idAccount == session.user.idAccount
			) {
				nameAgent = taskCancelComments[k].cancelAgent.name
					? taskCancelComments[k].cancelAgent.name
					: '';
				lastNameAgent = taskCancelComments[k].cancelAgent.lastName
					? taskCancelComments[k].cancelAgent.lastName
					: '';
				commentTime = moment(taskCancelComments[k].timeStamp).format('DD-MM-YYYY HH:mm');
				cancelCommentsAgent.push({
					fullAgentName: `${nameAgent} ${lastNameAgent}`,
					comment: taskCancelComments[k].comment,
					commentTime,
				});
			}

			if (
				taskCancelComments[k].cancelUser &&
				taskCancelComments[k].cancelUser.idAccount == session.user.idAccount
			) {
				nameClient = taskCancelComments[k].cancelUser.name
					? taskCancelComments[k].cancelUser.name
					: '';
				lastNameClient = taskCancelComments[k].cancelUser.lastName
					? taskCancelComments[k].cancelUser.lastName
					: '';
				commentTime = moment(taskCancelComments[k].timeStamp).format('DD-MM-YYYY HH:mm');
				cancelCommentsUser.push({
					fullClienttName: `${nameClient} ${lastNameClient}`,
					comment: taskCancelComments[k].comment,
					commentTime,
				});
			}
		}
		cancelComments = {
			cancelCommentsUser,
			cancelCommentsAgent,
		};
	}

	return cancelComments;
};



export const getTypeActionText = (lan, typeAction, typeActionInt, destinationSource, activity) => {
	
	switch (typeActionInt) {
		case ActivityActions.DO_ACTION_ASSIGN_AGENT_TASK:

			let assignBy = "";
			if (destinationSource && destinationSource.valueText) {
				assignBy = lan.by + " " + destinationSource.valueText;
			} else if (activity.agent) { 
				assignBy = lan.by + " " + activity.agent.name + " " + activity.agent.lastName;
			}
		
			return { typeAction: lan[typeAction] + " " + assignBy, showTooltip: false, tooltipInfo: null };
		
		case ActivityActions.DO_ACTION_ASSIGN_TEAM_TASK:
		case ActivityActions.DO_ACTION_ASSIGN_PARTNER_TASK:
		//GroupTask
		case ActivityActions.DO_ACTION_ASSIGN_GROUP:
		case ActivityActions.DO_ACTION_REFUSE_GROUP:
		//Routes
		case ActivityActions.DO_ACTION_RESET_CANCELED_TASK:
		case ActivityActions.DO_ACTION_UNLINK_TASK:
		case ActivityActions.DO_ACTION_REMOVE_TASK_FROM_ROUTE:
		case ActivityActions.DO_ACTION_ADD_TASK_TO_ROUTE:
		//Documents
		case ActivityActions.DO_ACTION_UPLOAD_TASK_DOCUMENT:
		case ActivityActions.DO_ACTION_DELETE_TASK_DOCUMENT:
		case ActivityActions.DO_ACTION_UPDATE_TASK_DOCUMENT:
		//Forms
		case ActivityActions.DO_ACTION_FINISH_FORM:
		case ActivityActions.DO_ACTION_REVIEW_FORM:
		case ActivityActions.DO_ACTION_APPROVED_FORM:
			let assignTo = destinationSource && destinationSource.valueText  ? destinationSource.valueText : "";
			return { typeAction: lan[typeAction] + " " + assignTo, showTooltip: false, tooltipInfo: null };
		
		
		case ActivityActions.DO_ACTION_ASSIGN_ASSET:
		case ActivityActions.DO_ACTION_ASSIGN_INVENTORY:
			let assignIds = destinationSource && destinationSource.valueListLong ? destinationSource.valueListLong.toString() : "";
			return  { typeAction: lan[typeAction] + " " + lan.ids + " " + assignIds, showTooltip: false, tooltipInfo:null  } ;
		
		case ActivityActions.DO_ACTION_ASSIGN_FORM:
			let assignNames = destinationSource &&  destinationSource.valueListString ? destinationSource.valueListString.toString() : "";
			return  { typeAction: lan[typeAction] + " " + assignNames , showTooltip: false, tooltipInfo:null  } ; 
		
		case ActivityActions.DO_ACTION_UPDATED_FORM:
		case ActivityActions.DO_ACTION_UPDATED_ASSET:
		case ActivityActions.DO_ACTION_UPDATED_INVENTORY:
			let assignForm = destinationSource &&  destinationSource.valueText ? destinationSource.valueText : "";
			let showTooltip = destinationSource && destinationSource.valueArray && destinationSource.valueArray.length > 3 ? true : false;
			
			let assignQuestions = "";
			let tooltipInfo = null;
			if (showTooltip) {
				tooltipInfo = destinationSource && destinationSource.valueArray ? destinationSource.valueArray.toString() : "";
			} else {
				assignQuestions = destinationSource && destinationSource.valueArray ? destinationSource.valueArray.toString() : "";
			}
			

			return { typeAction: lan[typeAction] +" "+assignForm+ " " + assignQuestions, showTooltip: showTooltip, tooltipInfo:tooltipInfo };
		
		default:
			return { typeAction: lan[typeAction] , showTooltip: false, tooltipInfo:null  }; 
	}
};