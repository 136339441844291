import React, { useEffect, useState } from 'react';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { Divider, IconButton, List } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useSideBarUpdate } from 'Context/SideBarContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLocalUser } from 'Utils/account/utils';

import { Toolbar } from '../styled';
import {
    DashboardSideMenu,
    MyOperationSideMenu,
    MarketPlaceSideMenu,
    CalendarSideMenu,
    AnalyticsSideMenu,
	DocumentationSideMenu,
	BISideMenu,
    ManagementAndControlSideMenu,
    PlanningAndOptimizingSideMenu,
    AutomationSideMenu,
    UserSideMenu,
    CommandCenterSideMenu,
} from './UserSideMenuComponents';

const SidebarUsers = () => {
	const user = useSelector(selectUser);
	const setSideBar = useSideBarUpdate();
	const navigate = useNavigate();

	const [isPermitted, setPermitted] = useState(false);
	const [isLocal, setLocal] = useState(false);

	useEffect(() => {
		const isPermittedToSee = !RoleUtils.isUserClient(user);
		const isUserLocal = isLocalUser(user);

		setPermitted(isPermittedToSee);
		setLocal(isUserLocal);
	}, [user]);

	const handleDrawerClose = () => setSideBar(false);
	const goToPage = (page) => () => navigate(page);

	return (
		<>
			<Toolbar>
				<IconButton onClick={handleDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
			</Toolbar>
			<Divider />

			{isLocal && (
				<>
					<List>
						<DashboardSideMenu goToPage={goToPage} />
						<MyOperationSideMenu goToPage={goToPage} />
						<CommandCenterSideMenu goToPage={goToPage} />
						<MarketPlaceSideMenu goToPage={goToPage} />
					</List>

					<Divider />

					{isPermitted && (
						<>
							<List>
								<ManagementAndControlSideMenu />
								<PlanningAndOptimizingSideMenu />
								<AutomationSideMenu />
							</List>

							<Divider />
						</>
					)}

					<List>
						{isPermitted && <UserSideMenu />}
						<CalendarSideMenu goToPage={goToPage} />
						<AnalyticsSideMenu goToPage={goToPage} />
						<BISideMenu goToPage={goToPage} />
						<DocumentationSideMenu goToPage={goToPage} />
					</List>
				</>
			)}
		</>
	);
};

export default SidebarUsers;
