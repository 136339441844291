import React, { useState, useEffect } from 'react';

import AutoAssignIcon from '@assets/images/computer.svg';
import { useLanguage } from 'Context/LanguageContext';
import { Modal, Row, Nav, Container, Navbar } from 'react-bootstrap';

import AutoAssignInfo from './components/AutoAssignInfo';
import 'FielderUtils/style.css';
import { getAutoAssignTaskRequest } from '@core/api/autoAssign/autoAssign.service';
import { useDispatch } from 'react-redux';

export default function AutoassignTab(props) {
	const lan = useLanguage();
	const dispatch = useDispatch()

	const [tab, setTab] = useState('info');
	const [processTask, setProcessTask] = useState(null);

	const isActive = (t) => (tab === t ? 'td-active' : '');

	useEffect(() => {
		const body = { taskId: props.idTask };
		const onSuccess = (res) => { setProcessTask(res.processTask)};
		const loadingConfig = { dispatch }
		getAutoAssignTaskRequest(body, onSuccess, null, loadingConfig);
	}, []);

	return (
		<Modal size='xl' aria-labelledby='contained-modal-title-vcenter' show onHide={() => props.setDetail(null)}>
			<Modal.Header closeButton className='no-border'>
				<div className='row mx-5'>
					<img src={AutoAssignIcon} className='mr-2' />
					<h6 className='my-auto'> {lan.autoassignDetail}</h6>
				</div>
			</Modal.Header>

			<Modal.Body className='no-border'>
				<Row className='mx-3'>
					<Navbar className='td-navbar mb-3'>
						<Nav.Item id='nav-info' className={`td-nav-item text-center ${  isActive('info')}`}>
							<Nav.Link className='td-nav-title' onClick={() => setTab('info')}>
								{lan.information}
							</Nav.Link>
						</Nav.Item>
						{/* <Nav.Item id='nav-exe' className={"td-nav-item text-center "+ isActive('coverage')}>
                                <Nav.Link className='td-nav-title' onClick={()=>setTab('coverage')}>{lan.coverage}</Nav.Link>
                            </Nav.Item> */}
					</Navbar>
				</Row>

				{/* Body */}
				<Row>
					<Container style={{ height: '500px' }}>
						{tab === 'info' && <AutoAssignInfo processTask={processTask} taskTypes={props.taskTypes} />}
						{tab === 'coverage' && <div />}
					</Container>
				</Row>
			</Modal.Body>
		</Modal>
	);
}
