import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationContainer from './NotificationContainer';
import {
	NotifyDrawerStyled,
	NotifyDrawerAppBarStyled,
	NotifyDrawerToolbarStyled,
	NotifyDrawerBoxStyled,
	NotifyDrawerBoxLoadingStyled,
	NotifyDrawerIconButtonStyled,
} from '../StyleComponents';

import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '@core/store/modules/notifications/notificationsSlice';
import NotificationItemSkeleton from './NotificationItemSkeleton';

const NotificationDrawer = ({ isOpen, onClose, setIdTaskDetail, setShowDetailTask, setNotificationOpen, lan }) => {
	const dispatch = useDispatch();
	const { allTask, totalNotifications, loading } = useSelector((state) => state.notifications);
	const handleClose = () => {
		onClose();
	};

	const handleLoading = () => {
		dispatch(fetchNotifications());
	};
	useEffect(() => {
		if (!isOpen) return; // If drawer is not open, don't run the request
		dispatch(fetchNotifications());
	}, [isOpen, dispatch]);
	return (
		<NotifyDrawerStyled
			variant='temporary'
			anchor='right'
			open={isOpen}
			onClose={onClose}
			ModalProps={{
				onBackdropClick: (event) => {
					event.stopPropagation();
				},
			}}
		>
			<NotifyDrawerAppBarStyled position='static'>
				<NotifyDrawerToolbarStyled>
					<NotifyDrawerIconButtonStyled edge='start' color='inherit' aria-label='close' onClick={handleClose}>
						<CloseIcon />
					</NotifyDrawerIconButtonStyled>
					<Typography variant='h6'>{lan.notifications}</Typography>
					<NotifyDrawerIconButtonStyled edge='start' color='inherit' aria-label='menu' onClick={handleLoading}>
						<RefreshIcon />
					</NotifyDrawerIconButtonStyled>
				</NotifyDrawerToolbarStyled>
			</NotifyDrawerAppBarStyled>
			<NotifyDrawerBoxStyled>
				{loading ? (
					<NotifyDrawerBoxLoadingStyled>
						<NotificationItemSkeleton />
					</NotifyDrawerBoxLoadingStyled>
				) : (
					typeof allTask === 'object' && (
						<NotificationContainer
							setIdTaskDetail={setIdTaskDetail}
							setShowDetailTask={setShowDetailTask}
							setNotificationOpen={setNotificationOpen}
							lan={lan}
							jsonS={allTask}
							totalNotifications={totalNotifications}
						/>
					)
				)}
			</NotifyDrawerBoxStyled>
		</NotifyDrawerStyled>
	);
};

export default NotificationDrawer;
