import React from 'react';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useSideBar } from 'Context/SideBarContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';

import SidebarInternalAdmin from './components/SidebarInternalAdmin';
import SidebarUsers from './components/SidebarUsers';
import { Drawer } from './styled';

const SideMenu = () => {
	const sideBar = useSideBar();
	const user = useSelector(selectUser);

	return (
		<Drawer
			variant='permanent'
			open={sideBar.open}
		>
			{RoleUtils.isInternalAdminUser(user) ? <SidebarInternalAdmin /> : <SidebarUsers />}
		</Drawer>
	);
};

export default SideMenu;
