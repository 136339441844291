

import FormAdvancedFilterUtilColum from './FormAdvancedFilterUtilColum';
import AdvancedFilterUtilOperator from './FormAdvancedFilterUtilOperator';

export const columnDataAvancedFilter = () => [
	{
		label: "idForm",
		value: FormAdvancedFilterUtilColum.ID_FORM,
		disabled: false,
	},

	{
		label: "name",
		value: FormAdvancedFilterUtilColum.NAME,
		disabled: false,
	},
	{
		label: "textForm",
		value: FormAdvancedFilterUtilColum.TEXT,
		disabled: false,
	}
];


export const operationDataAvancedFilter = (lan) => [
	{
		label: "contain",
	},
];

export const operatorDataAvancedFilter = (lan) => [
	{
		label: "andCapitalLetter",
		value: AdvancedFilterUtilOperator.AND,
	},
];

export const operatorCategoryDataAvancedFilter = (lan) => [
	{
		label: `orCapitalLetter`,
		value: AdvancedFilterUtilOperator.OR,
	},
	{
		label: `andCapitalLetter`,
		value: AdvancedFilterUtilOperator.AND_CATEGORY,
	},
];