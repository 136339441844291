import React, { useState, useEffect } from 'react';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import { selectUser, selectAccountPreferences } from '@core/store/modules/session/sessionSelectors';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLanguage } from 'Context/LanguageContext';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import useValidatePermission from 'FielderUtils/Hooks/useValidatePermission';
import roleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SubMenu from '../SubMenu';

const AutomationSideMenu = () => {
	const user = useSelector(selectUser);
	const accountPreferences = useSelector(selectAccountPreferences);

	const lan = useLanguage();
	const [anchor, setAnchor] = useState(null);
	const [hasAnyModule, setHasAnyModule] = useState(false);
	const [hasAutoAssign, setHasAutoAssign] = useState(false);
	const [hasSkills, setHasSkills] = useState(false);
	const [hasTaskType, setHasTaskType] = useState(false);
	const [autoAssignAllowed, validAutoAssignPermission] = useValidatePermission(user, Permission.AUTOASSIGN);
	const [skillsAllowed, validSkillsPermission] = useValidatePermission(user, Permission.SKILLS);
	const [taskTypesAllowed, validTaskTypesPermission] = useValidatePermission(user, Permission.TASK_TYPES);

	const title = lan.billingAutomation;
	const navigate = useNavigate();

	useEffect(() => {
		setHasAnyModule(
			accountPreferences &&
				(accountPreferences.showAutoAssign || accountPreferences.showSkills || accountPreferences.showTaskType)
		);
		setHasAutoAssign(accountPreferences && accountPreferences.showAutoAssign);
		setHasSkills(accountPreferences && accountPreferences.showSkills);
		setHasTaskType(accountPreferences && accountPreferences.showTaskType);
	}, [accountPreferences]);

	useEffect(() => {
		validAutoAssignPermission(user, Permission.AUTO_ASSIGN);
		validSkillsPermission(user, Permission.SKILLS);
		validTaskTypesPermission(user, Permission.TASK_TYPES);
	}, [user, validAutoAssignPermission, validSkillsPermission, validTaskTypesPermission]);

	const openMenu = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	const goToPage = (page) => () => {
		handleClose();
		navigate(page);
	};

	const isManager = roleUtils.isUserManager(user);

	const menuOptions = [
		{
			isValid: autoAssignAllowed && hasAutoAssign,
			goToPage: goToPage(ENDPOINTS.autoAssign),
			title: lan.autoassignation,
			img: `${process.env.AWS_FIELDER_ICONS}autoassign-icon.svg`,
		},
		{
			isValid: skillsAllowed && hasSkills,
			goToPage: goToPage(ENDPOINTS.skills),
			title: lan.skills,
			img: `${process.env.AWS_FIELDER_ICONS}skills-icon.svg`,
		},
		{
			isValid: taskTypesAllowed && hasTaskType,
			goToPage: goToPage(ENDPOINTS.taskTypes),
			title: lan.taskPersonalization,
			img: `${process.env.AWS_FIELDER_ICONS}custom-task-icon.svg`,
		},
	];

	return (
		<>
			{hasAnyModule && !isManager ? (
				<>
					<Tooltip title={title} placement='right'>
						<ListItemButton onClick={openMenu}>
							<ListItemIcon>
								<img className='icon' src={`${process.env.AWS_FIELDER_ICONS}automation-icon.svg`} alt='analytics' />

								<ChevronRightIcon color='disabled' />
							</ListItemIcon>
							<ListItemText primary={title} />
						</ListItemButton>
					</Tooltip>
					<SubMenu anchor={anchor} onClose={handleClose}>
						{menuOptions}
					</SubMenu>
				</>
			) : null}
		</>
	);
};

export default AutomationSideMenu;
