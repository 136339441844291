import WebSocket from 'isomorphic-ws';
import sendMessageToLex from '@core/utils/aws/lexBotV2/sendMessageToLex';

export class BedorckWsService {

    constructor(setConnectionId, createMessage, session, setCurrentQuestionState) {
		this.websocket = null;
        this.connectionId = null;
        this.setConnectionId = setConnectionId
        this.createMessage = createMessage;
        this.setCurrentQuestionState = setCurrentQuestionState;
        this.currentQuestion = '';
        this.session = session;
	}

    getConnectionId = () => {
        return this.connectionId;
    }

    setCurrentQuestion = (question) => {
        this.currentQuestion = question;
    }

    initSocket = () => {
        this.websocket = new WebSocket(process.env.BEDROCK_WEBSOCKET_URL);
        this.websocket.onopen = this.onConnOpen;
        this.websocket.onmessage = this.onMessage;
        this.websocket.reconnectInterval = 1000;
        this.websocket.onclose = this.onConnClose;
        this.websocket.onerror = (c) => console.log('ERROR', c);
    };

    /**
         *  Show connection status to us in the log
         */
    onConnOpen = (ws) => {
        this.isOpen = true;
        this.triggerConnectionIdResponse()
    };

    /**
         * Handler that receives the actual messages from the WebSocket API
         * For now it simply returns the parsed message body to the appropriate
         * registered handler
         * @param data Message body received from WebSocket
         */
    onMessage = (data) => {
        try {
            const response = JSON.parse(data.data);
            if (response.connectionId) {
                this.setConnectionId(response.connectionId);
                this.connectionId = response.connectionId;
            }
            if (response.message){
                this.createMessage(response.message);
                this.setCurrentQuestionState('')
            }
        } catch (error) {
            console.log("ERROR: ", error)
        }
    };



    /**
         *  This function will execute whe the connection
         *  is lost or this.websocket.close is executed
         */
    onConnClose = async (e) => {
        this.isOpen = false;
        this.websocket.close();
        this.websocket = null;
        if (e.code == 1001 && e.reason == 'Going away') {
            this.initSocket();
            /*
                websocket connection reached it 2hrs limit and a question wasn't answered yet
                so we resend the question to the new websocket connection
            */
            if(this.currentQuestion !== ''){
                console.log('resenting question to new websocket connection')
                await sendMessageToLex(this.currentQuestion, this.session, this.connectionId);
                this.setCurrentQuestionState('')
            }
        }
        console.log('Websocket closed!', e);
    };

    waitForConnection = () => {
        if (!this.isOpen) {
            return new Promise((res, rej) => {
                const maxAttempts = 20;
                const intervalTime = 400; // ms

                let currentAttempt = 0;

                const interval = setInterval(() => {
                    if (currentAttempt > maxAttempts - 1) {
                        clearInterval(interval);
                        this.initSocket();
                        rej(new Error('Maximum number of attempts exceeded to connect RealTime'));
                    } else if (this.websocket.readyState === 1) {
                        clearInterval(interval);
                        res();
                    }
                    currentAttempt++;
                }, intervalTime);
            });
        }
    };

    /**
         * sends a message to the WebSocket API after the connection is established
         * to make the apigateway return the connectionId in a message
         */
    triggerConnectionIdResponse = async () => {
        await this.waitForConnection();
        if (this.websocket && this.isOpen) {
            this.websocket.send(JSON.stringify({ action: 'default', message: 'dummy text to get connectionId back' }));
        }        
    }
}