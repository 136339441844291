

import { chatBotLinks } from './chatBotLinks'

const SPLIT_CONST = "**/+";

export default function validateLinksForDocumentation(botText, lan) {

	const splitArray = botText.split(SPLIT_CONST);
	let arrayLinks = [];
	
	splitArray.forEach(linkName => {
		if (linkName != '') {
			let chatBotObject = chatBotLinks(lan);
			for (let i = 0; i < chatBotObject.length; i++) {
				let chatBotLink = chatBotObject[i];
				if (chatBotLink.text == linkName) {
					arrayLinks.push(chatBotLink)
				}
			}	
		}	
	});
	
	return arrayLinks;
}