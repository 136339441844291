import React from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { StatusColor } from '@core/constants/task/colorCodes.constants';
import Status from '@core/constants/task/TaskStatus.constants';

import Graph from './Graph';

function am4themes_Dashboard(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color(StatusColor[Status.MANAGING].background),
			am4core.color(StatusColor[Status.WAITING_FOR_AGENT].background),
			am4core.color(StatusColor[Status.ASSIGNED_TO_TEAM].background),
			am4core.color(StatusColor[Status.ASSIGNED_TO_PARTNER].background),
			am4core.color(StatusColor[Status.IN_PROGRESS].background),
			am4core.color(StatusColor[Status.START_ROUTE].background),
			am4core.color(StatusColor[Status.ARRIVED].background),
			am4core.color(StatusColor[Status.EXECUTING].background),
			am4core.color(StatusColor[Status.PAUSED].background),
			am4core.color(StatusColor[Status.CANCEL].background),
			am4core.color(StatusColor[Status.FINISH].background),
		];
	}
}

class PieGraphDashboard extends Graph {
	constructor(data, props) {
		super(props);
		this.props = props;
		this.data = data;
	}

	createGraph(params) {
		am4core.unuseAllThemes();
		am4core.useTheme(am4themes_Dashboard);

		const chart = am4core.create(params.id, am4charts.PieChart);

		chart.innerRadius = am4core.percent(40);
		chart.data = this.data;

		const label = chart.seriesContainer.createChild(am4core.Label);
		label.horizontalCenter = 'middle';
		label.verticalCenter = 'middle';
		label.fontSize = 20;
		label.fontFamily = 'Lato';

		const pieSeries = chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = params.value;
		pieSeries.dataFields.category = params.category;
		pieSeries.labels.template.disabled = true;

		if (chart) {
			chart.legend = new am4charts.Legend();
			if (chart.legend) {
				chart.legend.fontSize = 11;
				chart.legend.position = 'top';
				chart.legend.align = 'bottom';
				chart.legend.contentAlign = 'bottom';
				chart.legend.valueLabels.template.align = 'right';
				chart.legend.valueLabels.template.textAlign = 'end';
				const markerTemplate = chart.legend.markers.template;
				if (markerTemplate) {
					markerTemplate.width = 10;
					markerTemplate.height = 10;
					markerTemplate.align = 'right';
					markerTemplate.textAlign = 'end';
				}
			}
		}

		super.setChartProperties(chart, 'false', true, false);

		this.chart = chart;
	}

	getGraph() {
		return this.chart;
	}
}

export default PieGraphDashboard;
