import React from 'react';

import Logo from '@assets/icons/logo-login-icon.svg';
import { Grid, Toolbar } from '@mui/material'
import { useLanguage } from 'Context/LanguageContext';
import { Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { AppBar, BlackNavLink, LogoImage } from './styled';

const LoggedOutMenu = () => {
	const navigate = useNavigate();
	const lan = useLanguage();

	return (
		<AppBar position='fixed'>
			<Toolbar>
				<Navbar.Brand>
					<LogoImage src={Logo} onClick={() => { window.location.href = 'https://www.appfielder.com' }} alt='' />
				</Navbar.Brand>

				<Grid container direction='row' justifyContent='flex-end' alignItems='center'>
					<BlackNavLink id='navbar-login-button' className='mr-3' onClick={() => navigate(ENDPOINTS.login)}>
						{lan.getInto}
					</BlackNavLink>
					{/*<BlackNavLink id='navbar-register-button' className='mr-5' onClick={() => navigate(ENDPOINTS.register)}>
						{lan.signUp}
						</BlackNavLink>*/}
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default LoggedOutMenu;
