import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { motion } from 'framer-motion';

const StatusContainer = styled(motion.div)`
	font-size: 1em
	position: relative;
	width: 90% !important;
	height: 90% !important;
	border-radius: 7px;
	padding: 5%;
	justify-content: center;
	cursor: default;
	color: ${(props) => props.color};
	background-color: ${(props) => props.background};
	text-align: center;
	display: flex;
	align-items: center;
`;


const TaskStatusTooltip = styled((props) => <Tooltip {...props} arrow placement='top' />)`
	background: ${({background}) => background} !important;
	color: ${({color}) => color} !important;
	font-size: 11,
`;

export { StatusContainer, TaskStatusTooltip };
