import React, { useState } from 'react';

import { Box, Fade, Paper, Typography } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { LocationSearchTabs, LocationSearchTab, LocationSearchBody } from '../styled';
import SearchByCoordinates from './SearchByCoordinates';
import SearchByPlace from './SearchByPlace';

function TabPanel(props) {
	const { children, value, index, lan, ...other } = props;
	return (
		<LocationSearchBody hidden={value !== index} id={`location-type-tabPanel-${index}`} {...other}>
			<Fade
				in={value === index}
				style={{ transitionDelay: value === index ? '100ms' : '0ms' }}
				unmountOnExit
			>
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			</Fade>
		</LocationSearchBody>
	);
}

const SEARCH = 0;
const COORDINATES = 1;

const LocationSearch = ({ address, coordinates, selectLocation, disabled, label, error }) => {
	const lan = useLanguage();

	const [searchType, setSearchType] = useState(SEARCH);

	const handleChangeAssignee = (event, newValue) => {
		setSearchType(newValue);
	};

	return (
		<Paper elevation={3}>
			<LocationSearchTabs
				value={searchType}
				onChange={handleChangeAssignee}
				indicatorColor='primary'
				textColor='primary'
				centered
				variant='fullWidth'
			>
				<LocationSearchTab label={lan.byDirection} value={SEARCH} />
				<LocationSearchTab label={lan.byCoordinates} value={COORDINATES} />
			</LocationSearchTabs>
			<TabPanel className='tab-background-color' value={searchType} index={SEARCH} lan={lan}>
				<SearchByPlace
					address={address}
					selectLocation={selectLocation}
					disabled={disabled}
					label={label}
					error={error}
				/>
			</TabPanel>

			<TabPanel className='tab-background-color' value={searchType} index={COORDINATES} lan={lan}>
				<SearchByCoordinates
					updateCoordinates={selectLocation}
					disabled={disabled}
					coordinates={coordinates}
					error={error}
				/>
			</TabPanel>
		</Paper>
	);
};

export default LocationSearch;
