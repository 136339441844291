import React, { Component } from 'react';

import FilterIcon from '@assets/icons/filter-icon.svg';
import AddIcon from '@assets/icons/red-add-icon.svg';
import { Badge as MuiBadge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Row } from 'react-bootstrap';
import { FIELDER } from '@core/constants/base.constants';
import ClearIcon from '@mui/icons-material/Clear';
import FormAdvancedFilterUtilOperator from './FormAdvancedFilterUtilOperator';
import FormAdvancedFilterUtilOperation from './FormAdvancedFilterUtilOperation';
import FormAdvancedFilterUtilColum from './FormAdvancedFilterUtilColum';


import {
	columnDataAvancedFilter,
	operatorDataAvancedFilter,
	operationDataAvancedFilter,
	operatorCategoryDataAvancedFilter
} from './FormAvancedFilterUtil';


import TextField from '~/TextField/TextField.component';
import FielderButton from 'FielderComponents/FielderButton';
import { DeepSpaceGrey, FielderPink } from '@core/theme/baseTheme';


const Badge = styled(MuiBadge)`
	& .MuiBadge-badge {
		background-color: ${FielderPink};
		color: white;
	}
`

const { btn: BTN_ID, txt: TXT_ID } = FIELDER.idElement;

class FormAdvancedFilter extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showMenu: false,
			resetView: true,
			showApplyButton: true,

			auxColumn: columnDataAvancedFilter(this.props.lan),
			filterOperation: operationDataAvancedFilter(this.props.lan),
			filterOperator: operatorDataAvancedFilter(this.props.lan),
			filterOperatorCategory: operatorCategoryDataAvancedFilter(this.props.lan),

			filters: [],
			sendFilters: [],
			cookieStorageFilter: [],
			menuAnchor: null,
		};
		this.menuRef = React.createRef();
	}

	componentDidMount = () => {
		window.addEventListener('click', this.handleOutsideClick);
	};

	componentWillUnmount = () => {
		window.removeEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (event) => {
		if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
		  this.setState({ showMenu: false });
		}
	};


	addFilter = () => {
		let arrayFilter = this.state.filters;
		let operatorValue = FormAdvancedFilterUtilOperator.WHERE;

		if (arrayFilter.length >= 1) {
			operatorValue = FormAdvancedFilterUtilOperator.AND;
		}

		let localAuxColumn = this.state.auxColumn;
		let columnValue = 0;
		let label = '';

		for (let x = 0; x < localAuxColumn.length; x++) {
			if (localAuxColumn[x]) {

			}
			if (localAuxColumn[x].disabled === false) {
				this.state.auxColumn[x].disabled = true;
				columnValue = localAuxColumn[x].value;
				label = localAuxColumn[x].label;
				break;
			}
		}

	
		let setFilter = {
			operator: operatorValue,
			columSelected: columnValue,
			label: label,
			operation: FormAdvancedFilterUtilOperation.CONTAINS,
			valueField: '',
			parent: columnValue,
			isParent: true,
			countCategory: 1,
		};
		
		if (label != '') {

		
		arrayFilter.push(setFilter);
		this.setState({ filters: [...arrayFilter] });
		}
	};

	deltedFilter = (index, columnSelected, isParent, parent, e) => {
		if (e) {
			e.stopPropagation();
		}

		let localAuxColumn =  this.state.auxColumn;
		let filtersValues = this.state.filters;
		let newArray = [];

		for (let x = 0; x < localAuxColumn.length; x++) {
			if (localAuxColumn[x].value == columnSelected && isParent) {
				this.state.auxColumn[x].disabled = false;
			}
		}

		if (isParent) {
			let auxFilter = filtersValues;

			for (let y = 0; y < auxFilter.length; y++) {
				if (parent != auxFilter[y].parent) {
					newArray.push(auxFilter[y]);
				}
			}
		} else {
			filtersValues.splice(index, 1);
			newArray = filtersValues;
		}

		let countCategory = 0;

		for (let x = 0; x < filtersValues.length; x++) {
			if (filtersValues[x].columSelected == columnSelected) {
				countCategory++;
			}
		}

		for (var x = 0; x < filtersValues.length; x++) {
			if (filtersValues[x].columSelected == columnSelected) {
				filtersValues[x].countCategory = countCategory;
			}
		}

		this.setState({ filters: [...newArray] })
	};

	addCategory = (index, parent, label, e) => {
	
		if (e) {
			e.stopPropagation();
		}

		let filtersAux =  this.state.filters;

		const newFilter = {
			label,
			parent,
			columSelected: parent,
			operator: FormAdvancedFilterUtilOperator.OR,
			operation: FormAdvancedFilterUtilOperation.CONTAINS,
			isParent: false,
			countCategory: 1,
		};

		newFilter.valueField = '';
		

		filtersAux.splice(index + 1, 0, newFilter);

		let countCategory = 0;

		for (let x = 0; x < filtersAux.length; x++) {
			if (filtersAux[x].columSelected === parent) {
				countCategory++;
			}
		}

		for (let x = 0; x < filtersAux.length; x++) {
			if (filtersAux[x].columSelected == parent) {
				filtersAux[x].countCategory = countCategory;
			}
		}

		this.setState({
			filters: [...filtersAux],
		});
	};


	changeColumn = (value, valueSelected) => {
		try {
		
			let filtersAux = this.state.filters;
			let auxCol = this.state.auxColumn;
			let colValue = parseInt(value);
			let label = '';

			for (var x = 0; x < auxCol.length; x++) {
				if (auxCol[x].value == colValue) {
					label = auxCol[x].label;
					this.state.auxColumn[x].disabled = true;
				}
				if (auxCol[x].value == valueSelected) {
					this.state.auxColumn[x].disabled = false;
				}
			}

			for (let z = 0; z < filtersAux.length; z++) {
				if (filtersAux[z].columSelected == valueSelected) {
					filtersAux[z].columSelected = parseInt(value);
					filtersAux[z].label = label;
					filtersAux[z].parent = colValue;
					filtersAux[z].valueField = '';					
				}
			}

			this.setState({ filters: [...filtersAux] })
		} catch (error) {
			console.error(error);
		}
	};

	changeInputValue = (event, index, useOnlyNumber) => {
		let state = this.state;
		const value = event.target.value;

		if (useOnlyNumber) {
			if (value === '' || /^[0-9\b]+$/.test(value)) {
				state.filters[index].valueField = value;
			}
		} else {
			state.filters[index].valueField = value;
		}

		this.setState(state);
	};
	
	changeOperation = (value, index) => {
		let filtersAux = filters;

		for (let x = 0; x < filtersAux.length; x++) {
			if (x == index) {
				filtersAux[x].operation = parseInt(value);
				break;
			}
		}
		this.setState({ filters: filters });
	};

	changeOperator = (event, index) => {
		let filters = this.state.filters;
		filters[index].operator = parseInt(event.target.value);

		this.setState({ filters });
	};


	stopPropagation = (event) => {
		event.stopPropagation();
	};

	handleOpenMenuOnClick = () => {
		if (!this.props.disabled) {
			this.setState({ showMenu: !this.state.showMenu });
		}
	}

	executeAdvancedFormFilters = () => {
		this.props.executeAdvancedFormFilters(this.state.filters);
		this.setState({ showMenu: !this.state.showMenu });
	};

	createFilter = () => 
		this.state.filters.map((filter, index) => (
			<React.Fragment>
				{filter.isParent && index !== 0 && <hr className='solid-line' />}

				<Row className={`mt-1 ${(filter.isParent === false && 'pl-4') || ''}`} key={'allRow-' + index}>
					{index === 0 ? (
						<div className='col-auto my-auto' key={'where-' + index}>
							<span key={'span-' + index} style={{ fontSize: '15px' }} aria-hidden='true'>
								{this.props.lan.where}
							</span>
						</div>
					) : (
						<div className='col-auto ml-1'>
							{filter.isParent ? (
								<select
									key={'select-operator' + index}
									value={filter.operator}
									className='custom-select'
									id='operator-id'
									onChange={(e) => this.changeOperator(e, index)}
								>
									{this.state.filterOperator.map((operator, indexOperator) => (
										<option id={"row_" + index + '_operator_' + indexOperator} value={operator.value}>
											{`${this.props.lan[operator.label]}`}
										</option>
									))}
								</select>
							) : (
								<select
									key={'select-operator' + index}
									value={filter.operator}
									className='custom-select'
									id='operator-id'
									onChange={(e) => this.changeOperator(e, index)}
								>
									{this.state.filterOperatorCategory.map((operator, indexOperator) => (
										<option id={"row_" + index + '_operator_' + indexOperator} value={operator.value}>
											{`${this.props.lan[operator.label]}`}
										</option>
									))}
								</select>
							)}
						</div>
					)}

					{filter.isParent && index !== 0 && (
						<div className='col-auto my-auto px-1' key={'where-y-' + index}>
							<span key={'span-' + index} style={{ fontSize: '15px' }} aria-hidden='true'>
								{this.props.lan.where}
							</span>
						</div>
					)}

					<div className='col-auto px-2'>
						{filter.isParent ? (
							<select
								key={'select-colum' + index}
								value={filter.columSelected}
								className='custom-select'
								id='taskType-red-input'
								onChange={(e) => this.changeColumn(e.target.value, filter.columSelected)}
							>
								{this.state.auxColumn.map((column, key) => (
									<option key={'col' + index + '' + key} disabled={column.disabled} value={column.value}>
										{this.props.lan[column.label]}
									</option>
								))}
							</select>
						) : (
							<select
								key={'select-colum-child'+filter.label+index}
								defaultValue={filter.columSelected}
								className='custom-select'
								id={'taskType-red-input' + index}
							>
								<option key={'col'+filter.label+index} disabled={true} value={filter.columSelected}>
									{this.props.lan[filter.label]}
								</option>
							</select>
						)}
					</div>
					<div className='col-auto px-2'>
						<select
							id='taskType-red-input'
							key={'select-operation' + index}
							defaultValue={filter.operation}
							className='custom-select'
							onChange={(e) => this.changeOperation(e.target.value, index)}
						>
							{this.state.filterOperation.map((operation, keyOperation) => (
								<option key={'opera' + index + '' + keyOperation} value={operation.label}>
									{`${this.props.lan[operation.label]}`}
								</option>
							))}
						</select>
					</div>

					{filter.columSelected == FormAdvancedFilterUtilColum.ID_FORM && (
						<div className='col px-2'>
							<TextField
								id={`${TXT_ID.filterCondition}-${index}`}
								key={`${TXT_ID.filterCondition}-${index}`}
								label={this.props.lan.value}
								onChange={(e) => this.changeInputValue(e, index, true)}
								value={filter.valueField}
								size='small'
							/>
						</div>
					)}

					{(filter.columSelected == FormAdvancedFilterUtilColum.NAME ||
						filter.columSelected == FormAdvancedFilterUtilColum.TEXT) && (
							<div className='col px-2'>
								<TextField
									id={`${TXT_ID.filterValue}-${index}`}
									key={`${TXT_ID.filterValue}-${index}`}
									label={this.props.lan.value}
									onChange={(e) => this.changeInputValue(e, index, false)}
									value={filter.valueField}
									size='small'
								/>
							</div>
						)}


					{/* ADD SAME CATEGORY */}
					<div className='col-auto my-auto'>
						<>
							{filter.isParent && (
								<IconButton id={`${BTN_ID.filterAdd}-${index}`} size='small'>
									<img
										style={{ width: '34px' }}
										src={AddIcon}
										onClick={(e) => this.addCategory(index, filter.columSelected, filter.label, e)}
									/>
								</IconButton>
							)}
							<IconButton
								id={`${BTN_ID.filterRemove}-${index}`}
								key={`${BTN_ID.filterRemove}-${index}`}
								size='small'
								onClick={(e) => this.deltedFilter(index, filter.columSelected, filter.isParent, filter.parent, e)}
							>
								<ClearIcon color={DeepSpaceGrey} />
							</IconButton>
						</>
					</div>
				</Row>
			</React.Fragment>
		));






		render() {
			return (
				<div ref={this.menuRef} className='fielder-advancedfiltermenu' style={this.props.style}>
					<Badge badgeContent={this.state.filters.length}>
						<button
							onClick={this.handleOpenMenuOnClick}
							className='fielder-clear-button'
							style={{ display: 'inline-block' }}
							disabled={this.props.disabled}
						>
							<img src={FilterIcon} style={{ marginRight: '7px', width: '18px', height: '18px' }} />
							<span style={{ marginLeft: '7px' }}>{this.props.lan.formFilter}</span>
						</button>
					</Badge>

					{this.state.showMenu && (
						<div className='fielder-avenced-filter'>
							<div className='p-3'>
								{this.state.filters && this.state.filters.length === 0 && (
									<div className='col my-2'>
										<span style={{ marginLeft: '7px', color: 'gray' }}>{this.props.lan.formFilterInfo}</span>
									</div>
								)}

								{this.state.resetView && <this.createFilter />}

								{this.state.showApplyButton && (
									<div className='row mt-2 mb-3'>
										<div className='col-auto'>
											<FielderButton id={BTN_ID.addFilterCondition} onClick={this.addFilter} >
												{this.props.lan.addFilter}
											</FielderButton>
										</div>

										<div className='col-auto'>
											<FielderButton id={BTN_ID.filterFormApply} onClick={this.executeAdvancedFormFilters} >
												{this.props.lan.apply}
											</FielderButton>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			);
	}
}

export default FormAdvancedFilter;
