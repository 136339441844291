import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemText, Tooltip } from '@mui/material';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser, selectAccountPreferences } from '../../../../core/store/modules/session/sessionSelectors';
import { SideMenuItem } from '../../styled';

const CommandCenterSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const accountPreferences = useSelector(selectAccountPreferences);

	const title = lan.commandCenter;

	const [permitted, setPermitted] = useState(false);

	useEffect(() => {
		const isUserPermitted = !RoleUtils.isUserClient(user);
		setPermitted(isUserPermitted && accountPreferences && accountPreferences.showCommandCenter);
	}, [user, accountPreferences]);

	return (
		<>
			{permitted && (
				<Tooltip title={title} placement='right'>
					<ListItemButton onClick={goToPage(ENDPOINTS.commandCenter)}>
						<SideMenuItem>
							<ImportantDevicesIcon />
						</SideMenuItem>
						<ListItemText primary={title} />
					</ListItemButton>
				</Tooltip>
			)}
		</>
	);
};

export default CommandCenterSideMenu;
