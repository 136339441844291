import React from 'react';

import view from '@assets/icons/view.svg';
import { Button } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';

class ViewFormatter extends React.Component {
	render() {
		const { value } = this.props;

		return (
			<StarRatings
				rating={value}
				starSpacing='1px'
				starDimension='15px'
				numberOfStars={5}
				starRatedColor='yellow'
				starHoverColor='yellow'
			/>
		);
	}
}

export default ViewFormatter;
