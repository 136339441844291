import React from 'react';

import ErrorIcon from '@assets/icons/alert-icon.svg';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import { Grid, Typography } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import Popup from 'OldComponents/Popup';
import PropTypes from 'prop-types';

const ResultMessage = ({ onClose, success, text }) => {
	const lan = useLanguage();

	const ResultIcon = () => {
		if (success) {
			return <img src={SuccessIcon} alt='' />;
		}

		return <img src={ErrorIcon} alt='' />;
	};

	const ResultText = () => {
		let resultText;
		if (success && !text) {
			resultText = lan.addedCorrectly;
		} else {
			resultText = text;
		}

		return <Typography align='center'>{resultText}</Typography>;
	};

	return (
		<Popup
			centered
			width='sm'
			onClose={onClose}
			closeButton
			buttonText={lan.accept}
			buttonOnClick={onClose}
		>
			<Grid container direction='column' justifyContent='center' spacing={3}>
				<ResultIcon />
				<Grid item>
					<ResultText />
				</Grid>
			</Grid>
		</Popup>
	);
};

ResultMessage.prototype = {
	onClose: PropTypes.func.isRequired,
	success: PropTypes.bool,
	resultText: PropTypes.string,
	show: PropTypes.bool,
};

ResultMessage.defaultProps = {
	success: false,
};

export default ResultMessage;
