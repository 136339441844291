export const DashboardColums = [
	{ id: 'idFielder', padding: 'none' },
	{ id: 'folio', padding: 'none' },
	{ id: 'company', padding: 'none' },
	{ id: 'branch', padding: 'none' },
	{ id: 'status', padding: 'none' },
	{ id: 'startTime', padding: 'none' },
	{ id: 'description', padding: 'none' },
	{ id: 'agent', padding: 'none' },
	{ id: 'lastAgentComment', padding: 'none' },
	{ id: 'rejectionReason', padding: 'none' },
	{ id: 'pausedReason', padding: 'none' },
];
