import React, { useEffect, useState } from 'react';

import TASK_STATUS from '@core/constants/task/TaskStatus.constants';
import Delete from '@mui/icons-material/Delete';
import { useLanguage } from 'Context/LanguageContext';
import 'FielderUtils/style.css';
import { SuperAdmin, Admin, Manager, Client } from 'FielderUtils/roles/roles';
import MainTable from 'OldComponents/MainTable';
import { Container, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { setRoute } from '@core/store/modules/map/mapSlice';

import { getAgentByIdRequest } from '@core/api/agents/agents.service';
import {
	getTaskRealTimeRequest,
	getTaskRequest
} from '@core/api/task/task.service';
import { getTaskWithForm } from '@core/api/task/task';
import POPUPNAME from 'FielderUtils/popupName/popupName';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import TaskExportImportMenu from './TaskExportImportMenu.js'
import {
	AgentCell,
	DateCell,
	ParentTaskCell,
	PriorityCell,
	StatusCell,
	TaskDetailButton,
	TaskTagsCell,
	TaskIconCell,
	TaskFunctionalitiesCell,
	TaskTypeCell
} from '@pages/MyOperationPage/components/MyOperationTable/TableCells';
import { getTasksWithForms } from 'FielderUtils/exportUtil/exportUtil';
import { getExportTasksQueryParams } from 'FielderUtils/task/exportTaskQueryParams.js';

let selectedRows = [];
let lastMarkersTasks;

const TASK_BATCH_SIZE_WITH_FORMS = 50;
const CHUNK_SIZE = 5;

export default function TaskTabForm(props) {
	const {
		to: toDate,
		from: fromDate,
		filter,
		tagsFilter,
		advancedFilterValues,
		advancedFilterFormValues,
		datesFilterValues,
		showDisable,
		executeLoadMore
	} = props;

	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const lan = useLanguage();
	const fielderMsg = useFielderSnackBar();

	const handleOpenDetail = React.useCallback(
		(task) => props.onRowClickButtonAction(POPUPNAME.TASK_DETAIL, task),
		[props.onRowClickButtonAction]
	);

	const BASE_COLS = React.useMemo(
		() => [
			{ id: 'taskIcons', padding: 'none', empty: true, customCell: TaskIconCell },
			{ id: 'taskId', name: 'idTask', padding: 'none', customCell: TaskDetailButton, onClick: handleOpenDetail },
			{
				id: 'functionalities',
				padding: 'none',
				customCell: TaskFunctionalitiesCell,
			},
			{ id: 'customId', padding: 'none' },
			{ id: 'folio', padding: 'none' },
			{ id: 'priority', padding: 'none', customCell: PriorityCell },
			{ id: 'taskType', padding: 'none', customCell: TaskTypeCell },
			{ id: 'status', name: 'status', padding: 'none', customCell: StatusCell },
			{ id: 'parentTask', name: 'idParentTask', padding: 'none', customCell: ParentTaskCell },
			{ id: 'partner', padding: 'none' },
			{ id: 'tags', padding: 'none', customCell: TaskTagsCell },
			{ id: 'taskUser', name: 'companyClient', padding: 'none' },
			{ id: 'branch', padding: 'none' },
			{ id: 'country', padding: 'none' },
			{ id: 'agent', name: 'agent', padding: 'none', customCell: AgentCell },
			{ id: 'startTime', padding: 'none', customCell: DateCell },
			{ id: 'endTime', name: 'endAgent', padding: 'none', customCell: DateCell },
		],
		[handleOpenDetail, lan]
	);

	const [markers, setMarkers] = useState([]);
	const [resetFlagOffset, setResetFlagOffset] = useState(false);
	const [selectedTask, setSelectedTask] = useState(null);
	const [rows, setRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	const [columns, setColumns] = useState(BASE_COLS);

	const tableCookie = {
		tableCookieName: 'MyOperationTaskTable',
		cookie: new Cookies(),
	};

	useEffect(() => {

		if(toDate) {
			getTaskWithForms();		
		}
		selectedRows = [];
	}, [toDate, executeLoadMore]);

	useEffect(() => {
		if (advancedFilterFormValues && advancedFilterFormValues.length > 0) {
			orderTasksByForm(advancedFilterFormValues);
		} else { 
			setRows(originalRows);
			props.setNumberOfElements(originalRows.length)
		}

	}, [advancedFilterFormValues]);


	useEffect(() => {
		props.setMarkers(markers);
	}, [markers]);

	useEffect(() => {
		selectedRows = [];
	}, [props.refreshSelected]);

	useEffect(() => {
		if (props.rowToUpdate != null) {
			updateRow(props.rowToUpdate);
		}
	}, [props.rowToUpdate]);

	useEffect(() => {
		if (props.rowToUpdateTaskOnGroup != null) {
			updateRowGroup(props.rowToUpdateTaskOnGroup);
		}
	}, [props.rowToUpdateTaskOnGroup]);

	useEffect(() => {
		if (props.rowToAdd != null) {
			addRow(props.rowToAdd);
		}
	}, [props.rowToAdd]);

	useEffect(() => {
		if (props.rowToDisable != null) {
			disableRow(props.rowToDisable);
		}
	}, [props.rowToDisable]);

	useEffect(() => {
		if (props.rowToDelete != null) {
			deleteRow(props.rowToDelete);
		}
	}, [props.rowToDelete]);

	useEffect(() => {
		if (props.rowToEnable != null) {
			addRow(props.rowToEnable);
		}
	}, [props.rowToEnable]);

	useEffect(() => {
		if (props.agentToUpdate != null) {
			updateAgentPosition(props.agentToUpdate);
		}
	}, [props.agentToUpdate]);

	useEffect(() => {
		if (props.agentToUpdateStatus != null) {
			updateAgentStatus(props.agentToUpdateStatus);
		}
	}, [props.agentToUpdateStatus]);

	useEffect(() => {
		setMarkers(rows);
	}, [rows]);

	const shouldRowsBeAddedAtTheEnd = () => {
		if (resetFlagOffset) {
			setResetFlagOffset(false);
			return false;
		}

		return true;
	};

	const updateAgentPosition = (agentToUpdateStatus) => {
		const idAgent = agentToUpdateStatus.idUser;
		const agentInfo = null;
		const enterValidation = true;

		let markersOnTasks = lastMarkersTasks;

		if (selectedRows.length > 0) {
			markersOnTasks = selectedRows;
		}

		if (selectedTask == null && lastMarkersTasks !== undefined) {
			obtainAgentsOnNewMarkerPosition(markersOnTasks, 0, idAgent, agentInfo, enterValidation);
		} else if (selectedTask != null && lastMarkersTasks !== undefined) {
			for (let elem = 0; elem < lastMarkersTasks.length; elem++) {
				if (
					lastMarkersTasks[elem].idTask === selectedTask &&
					lastMarkersTasks[elem].agent &&
					lastMarkersTasks[elem].agent.idAgent === idAgent
				) {
					const body = { idTask: selectedTask };

					const onError = (e) => dispatch(setRoute(null));

					const onSuccess = ({ taskInfo }) => {
						if (taskInfo && taskInfo.task && taskInfo.task.agent && taskInfo.task.status === TASK_STATUS.START_ROUTE) {
							updateRoutePosition(taskInfo.task);
						} else {
							dispatch(setRoute(null));
						}
					};

					getTaskRequest(body, onSuccess, onError);
				}
			}
		}
	};

	const obtainAgentsOnNewMarkerPosition = (markersOnTasks, i, idAgent, agentInfo, enterValidation) => {
		if (i < markersOnTasks.length) {
			if (markersOnTasks[i].agent && markersOnTasks[i].agent.idAgent === idAgent) {
				if (enterValidation) {
					const body = { idAgent };
					const loadingConfig = {
						dispatch,
						name: props.pageName,
						config: { allowTopBar: true },
					};

					const onSuccess = ({ agent }) => {
						if (agent && agent.geolocation) {
							markersOnTasks[i].agent = agent;
							enterValidation = false;
							obtainAgentsOnNewMarkerPosition(markersOnTasks, i + 1, idAgent, agent, enterValidation);
						}
					};

					getAgentByIdRequest(body, onSuccess, null, loadingConfig);
				} else {
					markersOnTasks[i].agent = agentInfo;
					obtainAgentsOnNewMarkerPosition(markersOnTasks, i + 1, idAgent, agentInfo, enterValidation);
				}
			} else {
				obtainAgentsOnNewMarkerPosition(markersOnTasks, i + 1, idAgent, agentInfo, enterValidation);
			}
		} else {
			for (let z = 0; z < markersOnTasks.length; z++) {
				if (
					markersOnTasks[z].status &&
					markersOnTasks[z].status === TASK_STATUS.START_ROUTE &&
					markersOnTasks[z].agent !== null
				) {
					markersOnTasks[z].agent.showToClient = true;
				} else if (
					markersOnTasks[z].status &&
					markersOnTasks[z].status !== TASK_STATUS.START_ROUTE &&
					markersOnTasks[z].agent !== null
				) {
					markersOnTasks[z].agent.showToClient = false;
				}
			}
			setMarkers(markersOnTasks);
		}
	};

	const updateAgentStatus = (agentToUpdateStatus) => {
		const idAgent = agentToUpdateStatus.idUser;

		if (!lastMarkersTasks) return;

		if (selectedTask == null) {
			updateAgentFromTask(lastMarkersTasks, idAgent);
			//obtainAgentsOnNewMarker(lastMarkersTasks, 0, idAgent, agentInfo, enterValidation);
		} else {
			for (let elem = 0; elem < lastMarkersTasks.length; elem++) {
				if (
					lastMarkersTasks[elem].idTask === selectedTask &&
					lastMarkersTasks[elem].agent &&
					lastMarkersTasks[elem].agent.idAgent === idAgent
				) {
					const body = { idTask: selectedTask };

					const onSuccess = ({ taskInfo }) => {
						if (taskInfo && taskInfo.task && taskInfo.task.agent && taskInfo.task.status === TASK_STATUS.START_ROUTE) {
							updateRoutePosition(taskInfo.task);
						}
					};

					getTaskRequest(body, onSuccess, onError);
				}
			}
		}
	};

	const updateAgentFromTask = (tasks, idAgent) => {
		const updatedAgent = null;
		const updatedTasks = tasks.map((task) => {
			if (task?.agent?.idAgent === idAgent) {
				// Validation to only search once for the agent, if stored in cache, just update with the new values,
				// do not perform the request again
				if (updatedAgent === null) {
					const body = { idAgent };
					const onSuccess = ({ agent }) => {
						if (agent && agent.geolocation) {
							updatedAgent = agent;
							task.agent = agent;
						}
					};

					getAgentByIdRequest(body, onSuccess);
				} else {
					task.agent = updatedAgent;
				}
			}

			return task;
		});

		setMarkers(updatedTasks);
	};

	const obtainAgentsOnNewMarker = (tasks, i, idAgent, agentInfo, enterValidation) => {
		if (i < tasks.length) {
			if (tasks[i].agent && tasks[i].agent.idAgent === idAgent) {
				if (enterValidation) {
					const body = { idAgent };
					const loadingConfig = {
						dispatch,
						name: props.pageName,
						config: { allowTopBar: true },
					};

					const onSuccess = ({ agent }) => {
						if (agent && agent.geolocation) {
							tasks[i].agent = agent;
							obtainAgentsOnNewMarker(tasks, i + 1, idAgent, agent, false);
						}
					};

					getAgentByIdRequest(body, onSuccess, null, loadingConfig);
				} else {
					tasks[i].agent = agentInfo;
					obtainAgentsOnNewMarker(tasks, i + 1, idAgent, agentInfo, enterValidation);
				}
			} else {
				obtainAgentsOnNewMarker(tasks, i + 1, idAgent, agentInfo, enterValidation);
			}
		} else {
			setMarkers(tasks);
		}
	};

	const updateRow = (rowToUpdate) => {
		const allRows = rows;
		const { idTask } = rowToUpdate;

		if (props.showTaskUpdates) {
			let updateRow = false;
			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].taskId === idTask) {
					var index = i;
					updateRow = true;
					break;
				}
			}

			if (updateRow) {
				const body = {
					taskInfo: {
						task: {
							idTask,
							idAccount: user.idAccount,
						},
					},
				};

				const onSuccess = ({ taskMyOperation }) => {
					if (taskMyOperation != null) {
						allRows[index] = taskMyOperation;
						setRows([...allRows]);

						if (selectedTask == idTask && taskMyOperation === TASK_STATUS.START_ROUTE) {
							updateRoutePosition(taskMyOperation);
						} else if (selectedTask == idTask && taskMyOperation != TASK_STATUS.START_ROUTE) {
							dispatch(setRoute(null));
						}
					}
				};

				getTaskRealTimeRequest(body, onSuccess);
			}
		} else {
			props.addNotificationCountReal();
		}
	};

	const updateRowGroup = (rowToUpdate) => {
		const allRows = rows;
		const { idTask } = rowToUpdate;

		if (props.showTaskUpdates) {
			let updateRow = false;
			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].taskId === idTask) {
					var index = i;
					updateRow = true;
					break;
				}
			}

			const body = {
				taskInfo: {
					task: {
						idTask,
						idAccount: user.idAccount,
					},
				},
			};

			const onSuccess = ({ taskMyOperation }) => {
				if (taskMyOperation == null) return

				const continueUpdatedRow = validateRowToUpdate(taskMyOperation);

				if (updateRow && continueUpdatedRow) {
					allRows[index] = taskMyOperation;
					setRows([...allRows]);
				} else if (!updateRow && continueUpdatedRow) {
					allRows.unshift(taskMyOperation);
					setRows(allRows);
					setResetFlagOffset(true);
					props.addNumberOfElements();
				}
			};

			getTaskRealTimeRequest(body, onSuccess);
		} else {
			props.addNotificationCountReal();
		}
	};

	const validateRowToUpdate = (task) => {
		if (
			user &&
			(user.role == SuperAdmin.name || user.role == Admin.name || user.role == Client.name) &&
			user.idAccount == task.idAccount
		) {
			return true;
		} else if (user && user.role === Manager.name && user.idTeams.includes(task.group.idTeam)) {
			return true;
		} else if (
			user &&
			(user.role == SuperAdmin.name || user.role == Admin.name) &&
			user.idAccount == task.group.idPartner
		) {
			return true;
		}
		return false;
	};

	const addRow = (rowToAdd) => {
		const allRows = rows;
		const { idTask } = rowToAdd;
		let willAddRow = true;

		for (let i = 0; i < allRows.length; i++) {
			if (allRows[i].taskId === idTask) {
				willAddRow = false;
				break;
			}
		}

		if (willAddRow) {
			const body = {
				taskInfo: {
					task: {
						idTask,
						idAccount: user.idAccount,
					},
				},
			};

			const onSuccess = ({ taskMyOperation: task }) => {
				if (!task) return
				let continueAddRow = false;

				const isTheTaskManager = (user.role &&
					user.role === 'Fielder Manager' &&
					task.taskIcons.manager &&
					task.taskIcons.manager == `${user.name} ${user.lastName}` )

				const isTheClient = (user.role &&
					user.role == 'Fielder Client User' &&
					task.user == user.idUser)

				const isSuperAdmin = (user.role && (user.role == 'Fielder Super Administrator' || user.role == 'Fielder Administrator'))

				if (isSuperAdmin || isTheTaskManager || isTheClient) {
					continueAddRow = true;
				}

				if (
					continueAddRow &&
					props.showTaskUpdates &&
					advancedFilterValues == null &&
					filter == null &&
					toDate == null
				) {
					allRows.unshift(task);
					setRows([...allRows]);
					setResetFlagOffset(true);
					props.addNumberOfElements();
				} else if (continueAddRow && !props.showTaskUpdates) {
					props.addNotificationCountReal();
				}
			};

			getTaskRealTimeRequest(body, onSuccess);
		}
	};

	const disableRow = (rowToDisable) => {
		const allRows = rows;
		const { idTask } = rowToDisable;

		if (props.showTaskUpdates) {
			let quitRow = false;

			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].taskId === idTask) {
					allRows.splice(i, 1);
					quitRow = true;
					break;
				}
			}

			if (quitRow) {
				setResetFlagOffset(true);
				setRows([...allRows]);
			}
		} else {
			props.addNotificationCountReal();
		}
	};

	const deleteRow = (rowToDelete) => {
		const allRows = rows;
		const { idTask } = rowToDelete;

		if (props.showTaskUpdates) {
			let quitRow = false;
			for (let i = 0; i < allRows.length; i++) {
				if (allRows[i].taskId === idTask) {
					allRows.splice(i, 1);
					quitRow = true;
					break;
				}
			}

			if (quitRow) {
				setResetFlagOffset(true);
				setRows([...allRows]);
			}
		} else {
			props.addNotificationCountReal();
		}
	};

	const verifyTaskStatusForRoute = (task) => {
		if (TASK_STATUS.START_ROUTE !== task.status) {
			fielderMsg({title: lan.task, message: lan.taskRouteAvailableForTaskStatus,
    			variant: 'warning', closeButton: true, duration: 7000});
		}

		return true;
	};

	const updateRoutePosition = (task) => {
		setSelectedTask(task.idTask);

		// If Task selected, show the agent Location
		if (task.agent && task.agent.geolocation) {
			let destination = {};
			if (task.startLocation) {
				destination.lat = parseFloat(task.startLocation.latitude);
				destination.lng = parseFloat(task.startLocation.longitud);
			} else {
				destination.lat = parseFloat(task.location.latitude);
				destination.lng = parseFloat(task.location.longitud);
			}

			const routeInfo = {
				destination,
				origin: {
					lat: parseFloat(task.agent.geolocation.latitude),
					lng: parseFloat(task.agent.geolocation.longitud),
				},
				travelMode: task.agent.vehicleType,
				agent: `${lan.agent}: (${task.agent.idAgent}) - ${task.agent.name} ${task.agent.lastName}`,
				task: `${lan.task}: ${task.idTask}`,
			};
			dispatch(setRoute(routeInfo));
		} else {
			// TODO: If task Managing or waiting for partner or something, show ONLY the closest agents
		}
	};

	const onRowClick = (task) => {
		const canSeeRoute = verifyTaskStatusForRoute(task);
		if (!canSeeRoute) {
			return;
		}

		setSelectedTask(task.taskId);

		// If Task selected, show the agent Location
		if (task.agent && task.agent.geolocation) {
			const routeInfo = {
				destination: {
					lat: parseFloat(task.location.latitude),
					lng: parseFloat(task.location.longitud),
				},
				origin: {
					lat: parseFloat(task.agent.geolocation.latitude),
					lng: parseFloat(task.agent.geolocation.longitud),
				},
				travelMode: task.agent.vehicleType,
				agent: `${lan.agent}: (${task.agent.idAgent}) - ${task.agent.name} ${task.agent.lastName}`,
				task: `${lan.task}: ${task.taskId}`,
			};
			dispatch(setRoute(routeInfo));
		} else {
			// TODO: If task Managing or waiting for partner or something, show ONLY the closest agents
		}
	};


	const updateSelected = (newSelected, selected) => {
		if (selected.length > 0) {
			setMarkers([...selected]);
		} else {
			setMarkers([...rows]);
		}
		props.handleCheckTaskTab(selected);
	};

	const onCheckboxClick = (selectedRow, selectAll) => {
		if (selectedRow != null) {
			const { taskId } = selectedRow;
			let addValue = true;

			for (let i = 0; i < selectedRows.length; i++) {
				if (selectedRows[i].taskId === taskId) {
					if (!selectAll) {
						selectedRows.splice(i, 1);
					}
					addValue = false;
				}
			}

			if (addValue) {
				selectedRows.push(selectedRow);
			}

			setMarkers([...selectedRows]);
		} else {
			selectedRows = [];
		}

		if (selectedRows.length > 0) {
			props.handleCheckTaskTab(selectedRows);
		} else {
			props.handleCheckTaskTab(selectedRows);
			setMarkers(rows);
		}
	};

	const reorderModify = () => {
		if (rows != null && rows.length > 0) {
			const trueFirst = rows.sort((a, b) => Number(b.modifyTaskStatusComment) - Number(a.modifyTaskStatusComment));
			setRows([...trueFirst]);
		}
	};

	const reorderFormSignature = () => {
		if (rows != null && rows.length > 0) {
			const trueFirst = rows.sort((a, b) => Number(b.hasAllSignatures) - Number(a.hasAllSignatures));
			setRows([...trueFirst]);
		}
	};

	const generateQueryTaskWithFormParams = (bringTasks, offset, limit) => {
		return getExportTasksQueryParams(
			fromDate.getDate(),
			fromDate.getMonth(),
			fromDate.getFullYear(),
			toDate.getDate(),
			toDate.getMonth(),
			toDate.getFullYear(),
			filter,
			tagsFilter,
			datesFilterValues,
			null,
			advancedFilterValues,
			offset,
			limit,
			user.idUser,
			bringTasks,
			null
		);
	};

	
	const orderTasksByForm = (advancedFilterFormValues) => {	
		const result = originalRows.filter((task) =>
			task.functionalities &&
			task.functionalities.forms &&
			task.functionalities.forms.listForms &&
			task.functionalities.forms.listForms.length > 0 &&
			task.functionalities.forms.listForms.some((form) =>
				advancedFilterFormValues.some((filter) => {
					if (filter.label === 'idForm' && form.id == filter.valueField) {
						return true;
					}
					if (filter.label === 'name' && form.name && form.name.includes(filter.valueField)) {
						return true;
					}
					if (filter.label === 'textForm' && form && JSON.stringify(form).includes(filter.valueField)) {
						return true;
					}
					return false;
				})
			)
		);
	
		setRows([...result]);
		props.setNumberOfElements(result.length)
	};

	


	const getTaskWithForms = async () => {
		setSelectedTask(null);
		

		const queryTasksWithFormParams = generateQueryTaskWithFormParams(false, 0, 0);
		const totalTasks = await getTasksWithForms(queryTasksWithFormParams);

		props.setNumberOfElements(totalTasks)
		const totalRequests = Math.ceil(totalTasks / TASK_BATCH_SIZE_WITH_FORMS);
		const requestsArray = Array.from({ length: totalRequests }, (v, k) => k);
		let data = [];
		
		for (let i = 0; i < requestsArray.length; i += CHUNK_SIZE) {
			const chunk = requestsArray.slice(i, i + CHUNK_SIZE);

			await Promise.all(
				chunk.map((i) => {
					const offset = i * TASK_BATCH_SIZE_WITH_FORMS;
					
					const onSuccess = (res) => {
						data = data.concat(res.tasks);
					};

					const onError = () => {
						console.log("ERROR")
					};
					const queryExportParamsTask = generateQueryTaskWithFormParams(true, offset, TASK_BATCH_SIZE_WITH_FORMS);
					return getTaskWithForm(queryExportParamsTask, onSuccess, onError);
				})
			);
		}
		
		data.sort((a, b) => b.taskId - a.taskId);
		
		setOriginalRows(data);
		setRows(data);
		props.changeLoadingFilter(false)
	
	}

	return (
		<Container fluid style={{ overflow: 'none' }}>
			<TaskExportImportMenu
				timeTo={toDate}
				timeFrom={fromDate}
				filter={filter}
				tagsFilter={tagsFilter}
				datesFilterValues={datesFilterValues}
				advancedFilterValues={advancedFilterValues}
				setDatesError={props.setDatesError}
			/>

			<Row>
				<MainTable
					dontResetSelected
					InfiniteScroll
					returnAllSelectedRows
					onCheckboxClick={onCheckboxClick}
					updateSelected={updateSelected}
					refreshSelected={props.refreshSelected}
					reorderCols={setColumns}
				
					cols={columns}
					rows={rows}
					onRowClick={onRowClick}
					disabledDetailButton={props.disabledDetailButton}
					tooltips={[{
						title: showDisable ? lan.deleteTaskTooltip : lan.disableTaskTooltip,
						action: props.deleteTask,
						icon: <Delete />,
					}]}
					cookie={tableCookie}
					getDynamoCookies
					isMyOperation
					reorderModify={reorderModify}
					reorderFormSignature={reorderFormSignature}
					stickyHeader
					height={500}
					{...props}
				/>
			</Row>
		</Container>
	);
}
