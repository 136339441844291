import React from 'react';

import AddDomainIcon from '@assets/icons/domain-icon.svg';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

const AddDomainSideMenu = ({ showAddDomain }) => {
	const lan = useLanguage();
	const title = lan.assignDomain;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={showAddDomain}>
				<ListItemIcon>
					<img src={AddDomainIcon} alt='addDomain' style={{ width: '20px' }} />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default AddDomainSideMenu;
