import React, { Component } from 'react';
import EditGroupTaskIcon from '@assets/icons/edit-group-tasks-icon.svg';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { connect } from 'react-redux';
import POPUPNAME from 'FielderUtils/popupName/popupName';
class TaskGroupActionPopup extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	changePopupState = (popupName) => {
		this.props.changePopupState(popupName);
	};

	closeTaskGroupAction = () => {
		this.props.closeTaskGroupAction();
	};

	render() {
		return (
			<React.Fragment>
				<div className='taskAction-container full-width mb-4'>
					<div className='d-flex justify-content-center'>
						<div className='row taskAction-background'>
							<div className='col-auto no-padding taskAction-red-rectangle'></div>
							<div className='col-auto mt-1'>
								<span className='taskAction-number-selected'>{this.props.selectedGroupTaskCount}</span>
							</div>
							<div className='col-auto my-auto'>
								<span className='taskAction-text-selected'>{this.props.lan.selectedElements}</span>
							</div>
							{/******** EDIT GROUP TASK ********/}
							{!RoleUtils.isUserClient(this.props.user) && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-edit-group-task-icon'
												src={EditGroupTaskIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.titleIconEditGroup}
												onClick={() => {
													this.changePopupState(POPUPNAME.EDIT_GROUP_TASK);
												}}
											/>
										</div>
									</div>
									<div className='row-auto d-flex justify-content-center'>
										<div className='col-auto no-padding d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.titleIconEditGroup}</span>
										</div>
									</div>
								</div>
							)}

							<div className='col-auto my-auto pl-1 pr-1 colsize-cross'>
								<div className='row-auto'>
									<div className='col-auto'>
										<a href='#' className='closeTaskAction' onClick={() => this.closeTaskGroupAction()} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(TaskGroupActionPopup);
