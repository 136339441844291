const CREATED = 0;
const ON_WAY = 1;
const ARRIVE = 2;
const EXECUTING = 3;
const PAUSE = 4;
const FINISH = 5;
const CANCELED = 6;
const ON_TRASH = 7;

const CREATED_NAME = 'CREATED';
const ON_WAY_NAME = 'ON_WAY';
const ARRIVE_NAME = 'ARRIVE';
const EXECUTING_NAME = 'EXECUTING';
const PAUSE_NAME = 'PAUSE';
const FINISH_NAME = 'FINISH';
const CANCELED_NAME = 'CANCELED';
const ON_TRASH_NAME = 'ON_TRASH';

export default {
	CREATED,
	CREATED_NAME,
	ON_WAY,
	ON_WAY_NAME,
	ARRIVE,
	ARRIVE_NAME,
	EXECUTING,
	EXECUTING_NAME,
	PAUSE,
	PAUSE_NAME,
	FINISH,
	FINISH_NAME,
	CANCELED,
	CANCELED_NAME,
	ON_TRASH,
	ON_TRASH_NAME,
};
