import RealTime from '@core/constants/realTime/actions.constants';

export const updateActions = new Set([
	RealTime.TASK_CREATE,
	RealTime.TASK_UPDATE,
	RealTime.TASK_FINISH,
	RealTime.TASK_CANCEL,
	RealTime.TASK_RESCHEDULE,
	RealTime.TASK_DISABLE,
	RealTime.TASK_ENABLE,
	RealTime.TASK_AGENT_ASSIGN,
	RealTime.TASK_TEAM_ASSIGN,
	RealTime.TASK_PARTNER_ASSIGN,
	RealTime.TASK_STATUS_UPDATE_AUTO_ASSIGN,
	RealTime.TASK_GROUP_ASSIGN,
	RealTime.TASK_GROUP_REFUSE,
	RealTime.TASK_GROUP_UPDATE,
	RealTime.GROUP_STATUS_UPDATE,
	RealTime.TASK_MOBILE_STATUS_UPDATE,
	RealTime.TASK_MOBILE_RESET_EXECUTION,
	RealTime.TASK_MOBILE_UPDATE,
	RealTime.TASK_MOBILE_REJECT,
	RealTime.TASK_MOBILE_CANCEL,
	RealTime.TASK_MOBILE_RESCHEDULE,
	RealTime.TASK_MOBILE_GROUP_ASSIGN,
	RealTime.TASK_MOBILE_AGENT_ASSIGN,
	RealTime.TASK_MOBILE_TEAM_ASSIGN,
]);

export const monthTaskActions = new Set([RealTime.TASK_CREATE]);

export const agentUpdateActions = new Set([
	RealTime.AGENT_STATUS_UPDATE,
	RealTime.AGENT_CONDITION_UPDATE,
	RealTime.AGENT_CREATE,
]);
