import {
	getCompaniesByFilter,
	getCompaniesByFilterCreateTask,
	getCompaniesInitialOptions,
	getCompaniesPartnerByFilter,
} from '@core/api/company/company.service';

/**
 *
 * @param {string} filter       - Filter to search the companies by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all companies by the filter send and sets the information and format for the asyncSelect component
 */
export const getCompaniesByFilterAsyncSelect = async (filter, loading) => {
	const onSuccess = (response) => response.companies;

	const onError = (error) => {
		console.error(error);
		return [];
	};

	const companies = await getCompaniesByFilter(filter, onSuccess, onError, loading);
	return formatCompanies(companies);
};

export const getCompaniesByFilterAsyncSelectCreateTask = async (filter, loading) => {
	const onSuccess = (response) => response.companies;

	const onError = (error) => {
		console.error(error);
		return [];
	};

	const companies = await getCompaniesByFilterCreateTask(filter, onSuccess, onError, loading);
	return formatCompanies(companies);
};

export const getCompaniesInitialOptionsAsyncSelect = async (loading) => {
	const onSuccess = (response) => response.companies;

	const onError = (error) => {
		console.error(error);
		return [];
	};

	const companies = await getCompaniesInitialOptions(onSuccess, onError, loading);
	return formatCompanies(companies);
};

export const getCompaniesPartnerByFilterAsyncSelect = async (filter, loading) => {
	const onSuccess = (response) => response.companies;

	const onError = (error) => {
		console.error(error);
		return [];
	};

	const companies = await getCompaniesPartnerByFilter(filter, onSuccess, onError, loading);
	return formatCompanies(companies);
};

export const formatCompanies = (companies, onlyId) => {
	if (!companies) {
		return [];
	}
	return companies.map((company) => {
		let label = `${company.id} ${company.name}`;
		if (company.idCustom) {
			label += ` (${company.idCustom})`;
		}

		if (onlyId) {
			return {
				label,
				value: company.id,
			};
		}

		return {
			label,
			value: company,
		};
	});
};
