import React, { useState } from 'react';

import { InputAdornment, MenuItem } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { Input, TextFieldSelect } from './TextFieldWithSelect.styles';

const TextFieldWithSelect = ({ options, onChange }) => {
	const lan = useLanguage();

	const [inputValue, setInputValue] = useState(null);
	const [selectValue, setSelectValue] = useState(1);

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			onChange(inputValue, selectValue);
		}
	};

	const Options = React.useMemo(() =>
		options.map(
			({ label, value: val }) => (
				<MenuItem value={val} key={label}>
					{label}
				</MenuItem>
			),
			[options]
		)
	);

	const handleInputChange = (e) => setInputValue(e.target.value);
	const handleSelectChange = (e) => {
		setSelectValue(e.target.value);
		onChange(inputValue, e.target.value);
	};

	return (
		<Input
			placeholder={lan.search}
			onChange={handleInputChange}
			onKeyPress={handleKeyPress}
			value={inputValue}
			startAdornment={
				<InputAdornment position='start' className='ml-2'>
					<img src={`${process.env.AWS_FIELDER_ICONS}search-icon.svg`} alt='' />
				</InputAdornment>
			}
			endAdornment={
				<TextFieldSelect value={selectValue} onChange={handleSelectChange}>
					{Options}
				</TextFieldSelect>
			}
		/>
	);
};

export default TextFieldWithSelect;
