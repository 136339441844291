export const AgentStatus = {
	online: {
		badge: '#44b700',
		chart: '#93EA95',
	},
	away: {
		badge: '#FFAB2A',
		chart: '#F9EA76',
	},
	offline: {
		badge: '#FD4E97',
		chart: '#FDB5BF',
	},
	inactive: {
		badge: '#27233A',
		chart: '#AD97D4',
	},
	noData: {
		badge: '#98AABD',
		chart: '#707579',
	},
};

const x ='#44b700'
