// Templates
export const REQUEST_TYPES = {
	GET_TEMPLATE_BY_ID: 'GET_TEMPLATE_BY_ID',
	GET_AUTO_ASSIGN_TEMPLATES_TABLE: 'GET_AUTO_ASSIGN_TEMPLATES_TABLE',
	DO_UPDATE_ACCOUNT_TEMPLATE: 'DO_UPDATE_ACCOUNT_TEMPLATE',
	DO_ADD_ACCOUNT_TEMPLATES: 'DO_ADD_ACCOUNT_TEMPLATES',
	GET_AUTO_ASSIGN_PROCESS_TASK: 'GET_AUTO_ASSIGN_PROCESS_TASK',
	GET_AUTO_ASSIGN_PROCESS_TASKS: 'GET_AUTO_ASSIGN_PROCESS_TASKS',
	UPDATE_TEMPLATE_STATUS: 'DO_UPDATE_TEMPLATE_STATUS',
	DELETE_TEMPLATE: 'DO_REMOVE_ACCOUNT_TEMPLATES',
	DO_FILTER_AUTO_ASSIGN_TEMPLATES: 'DO_FILTER_AUTO_ASSIGN_TEMPLATES',
};
