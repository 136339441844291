import React from 'react';
import Task from '@mui/icons-material/AssignmentTurnedIn';
import Route from '@mui/icons-material/Route';
import Agent from '@mui/icons-material/Person';
import Partner from '@mui/icons-material/ZoomInMap';
import Group from '@mui/icons-material/Group';
import AutoAssign from '@mui/icons-material/Insights';
import Module from '@mui/icons-material/Feed';
import Other from '@mui/icons-material/InsertDriveFile';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddAgent from '@mui/icons-material/PersonAdd';

/* ICON MESSAGES */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export const DRAWER_WIDTH = 240;

// Colors
export const FielderPink = '#FA7FAB';
export const LightGreySubtitleColor = '#858f9b';
export const DeleteRedColor = '#E3655B';
export const GreyTextDarkBackground = '#B0B0B0';

// Blues
export const UltramarineBlue = '#536CED';
export const Verdigris = '#48A9A6';
export const DeepJungle = '#004643';
export const Glaucous = '#607196';
export const SpaceCadet = '#2B2D42'

// Greys
export const GhostWhite = '#F2F2F7';
export const Magnolia = '#E6E6EF';

export const AliceBlue = '#D7E0EC';
export const CadetBlueCrayola = '#A5B5CB';
export const Gainsboro = '#D3DBDF';
export const Cultured = '#F7F7FA';
export const DarkCultured = '#ECEFEF';
export const DeepSpaceGrey = '#455A64';
export const DeepSpaceGreyDark = '#666666';
export const HoverMenuFielder = '#0000000a';

// Red
export const CedarChest = '#D9594C';
export const RedSalsa = '#F8333C';
export const IndianRed = '#DB5461';

// Green
export const PersianGreen = '#1B998B';
export const Emerald = '#2DD881';
export const AquaMarine = '#68EDC6';

// Orange
export const Mandarin = '#EF8354';
export const YellowOrange = '#FBB13C';
export const CopperCrayola = '#DC8056';
export const BitterSweet = '#F87060';

// Yellow
export const Sunray = '#EDAE49';
export const SelectiveYellow = '#FFBB33'
export const OldGold = '#E0BE36';
export const Xanthous = '#FFC759'
export const Sunglow = '#FFC95C'
export const OrangeYellowCrayola = '#F4D35E';

// Pinks
export const ParadisePink = '#EE4B6A';
export const OrchidCrayola = '#F79AD3';
export const CottonCandy = '#FDC5F5';
export const RosePompadour = '#FF7B9C'
export const CottonCandyLight = '#f5e5f3';

// Purple
export const PurpleNavy = '#655A7C';
export const Mauve = '#D9BBF9';
export const RazzmicBerry = '#8E518D';

export const White = '#FFFFF';
export const Transparent = '#ffffff00';

export const FielderSoft = '#f5e6ed'
export const FielderHard = '#dc5c87'

// Color Status Notifications
export const CreateColor = '#9BC5F1';
export const ApproveSuccessColor = '#0BA25F';
export const EditColor = '#EF8354';
export const AssignColor = '#FFBB33';
export const CancelColor = '#DB5461';
export const ErrorAttentionColor = '#F8333C';
export const InviteColor = '#8E518D';
export const OtherColor = '#455A64';
export const ProgressColor = '#F79AD3';

// Icon Code Notifications
export const TaskIcon = (props) => <Task fontSize="large" {...props} />;
export const RouteIcon = (props) => <Route fontSize="large" {...props} />;
export const AgentIcon = (props) => <Agent fontSize="large" {...props} />;
export const PartnerIcon = (props) => <Partner fontSize="large" {...props} />;
export const GroupIcon = (props) => <Group fontSize="large" {...props} />;
export const AutoAssignIcon = (props) => <AutoAssign fontSize="large" {...props} />;
export const ModuleIcon = (props) => <Module fontSize="large" {...props} />;
export const OtherIcon = (props) => <Other fontSize="large" {...props} />;
export const AddAgentIcon = (props) => <AddAgent fontSize="large" {...props} />;

// Icon Color Notifications
export const SuccessIcolor = '#0BA25F';
export const WarnigIcolor = '#FFBB33';
export const ErrorIcolor = '#F8333C';
export const InfoIcolor = '#2264fd';

// Text Color Notifications
export const SuccessTxtColor = '#1a391c';
export const WarnigTxtColor = '#70411b';
export const ErrorTxtColor = '#4b0808';
export const InfoTxtColor = '#0a0a0a';

// Background Color Notifications
export const SuccessBgColor = '#eaf2ea';
export const WarnigBgColor = '#fdf0e6';
export const ErrorBgColor = '#fbeaea';
export const InfoBgColor = '#f7f9fc';

// Icon Notification System
export const SuccessIcon = () => <CheckCircleOutlineIcon />;
export const WarningIcon = () => <WarningAmberIcon />;
export const ErrorIcon = () => <ReportGmailerrorredIcon />;
export const InfoIcon = () => <InfoOutlinedIcon />;


// Icon Code Fielder
export const EditProfileIcon = (props) => <PhotoCamera fontSize="large" {...props} />;
export const NoProfileIcon = (props) => <Agent fontSize="large" {...props} />;
