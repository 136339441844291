const STATUS = {
	MANAGING: 0,
	WAITING_FOR_AGENT: 1,
	IN_PROGRESS: 2,
	EXECUTING: 3,
	CANCELLED: 4,
	FINISHED: 5,
	REJECTED: 6,
};

export default STATUS;
