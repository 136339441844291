import React, { useState } from 'react';

import { Autocomplete as MuiAutocomplete, CircularProgress, Grid } from '@mui/material'
import { styled } from '@mui/material/styles';
import { useLanguage } from 'Context/LanguageContext';
import PropTypes from 'prop-types';
import TextField from '~/TextField/TextField.component'

const Autocomplete = styled(MuiAutocomplete)`
	background-color: white;
	&.MuiAutocomplete-input {
		border-radius: 12px;
	}
`

/**
 *  Asynchronous search input component enhanced by a suggested options
 *  panel
 *
 *  @param {object} lan - the language object used to translate the texts
 *
 *  @param {function} getOptions - the function to retrieve the data from
 *                                  must return an array of objects with a
 *                                  label (the name to be displayed) and a value
 *                                  keys
 *
 *  @param {?boolean} [multiple=false] - Set the parameter if you want to receive a
 *                                       multiple options selected input
 *
 *  @param {?string} [label=''] - The label to be shown in the input element
 *
 *  @param {?string} [id='asyncSearch'] - The component id
 *
 * @param {?number} [limitTags=3] - Maximum number of elements to be shown for the AsyncSearch element
 */
const AsyncSearch = ({
	id,
	onChange,
	getOptions,
	multiple,
	label,
	className,
	disabled,
	defaultValue,
	limitTags,
	getInitialOptions,
	showInitialOptions,
}) => {
	const lan = useLanguage();

	const [open, setOpen] = useState(false);
	const [lastSearch, setLastSearch] = useState('');
	const [options, setOptions] = useState([]);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [loading, setLoading] = useState(false);

	const getValue = React.useMemo(() => {
		if (Array.isArray(defaultValue)) {
			if (!multiple) {
				return defaultValue[0];
			}

			if (defaultValue.length === 0) return;
		}


		return defaultValue;
	}, [defaultValue, multiple]);

	const search = (event) => {
		let active = true;
		setLoading(true);

		if (event.target.value !== lastSearch) {
			setOptions([]);
		}

		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		const searchInfo = async () => {
			if (event.target.value !== '' && active) {
				let searchingOptions = await getOptions(event.target.value);

				if (!searchingOptions) {
					searchingOptions = [];
				}

				setLastSearch(event.target.value);
				setOptions(searchingOptions);
				setLoading(false);
			}
		};

		setTypingTimeout(setTimeout(searchInfo, 250));

		return () => {
			active = false;
		};
	};

	const getOptionLabel = (option) => {
		if (typeof option === 'object' && option && 'label' in option) {
			return option.label;
		}
		return '';
	};

	const selectOption = (option, val) => val && option.label === val.label;
	const onChangeAutocomplete = (e, newInput) => onChange(newInput);

	const handleShowOptions = async (val) => {
		if (val && showInitialOptions) {
			setLoading(true);
			const searchingInitialOptions = await getInitialOptions();
			setLoading(false);
			setOptions(searchingInitialOptions);
		}
		setOpen(val);
	};

	const handleOpen = () => handleShowOptions(true);
	const handleClose = () => handleShowOptions(false);

	return (
		<Grid item xs className={className}>
			<Autocomplete
				autoComplete
				autoHighlight
				fullWidth
				loadingText={lan.loading}
				noOptionsText={lan.noResultFound}
				multiple={multiple}
				disableCloseOnSelect={multiple}
				limitTags={limitTags}
				id={id}
				open={open}
				disabled={disabled}
				onOpen={handleOpen}
				onClose={handleClose}
				getOptionSelected={selectOption}
				getOptionLabel={getOptionLabel}
				options={options}
				loading={loading}
				defaultValue={getValue}
				onChange={onChangeAutocomplete}
				renderInput={({InputProps, ...params}) => (
					<TextField
						{...params}
						label={label}
						variant='outlined'
						onChange={search}
						InputProps={{
							...InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{InputProps.endAdornment}
								</>
							),
							sx: {borderRadius: '12px !important'}
						}}
					/>
				)}
			/>
		</Grid>
	);
};

AsyncSearch.defaultProps = {
	multiple: false,
	label: '',
	id: 'asyncSearch',
	className: '',
	disabled: false,
	limitTags: 3,
	getOptions: () => [],
};

AsyncSearch.prototype = {
	multiple: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	getOptions: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default React.memo(AsyncSearch);
