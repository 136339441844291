import { defaultCenter } from "./googleMaps.config";

const getMarkerBounds = (pin, bounds) => {
	bounds.extend(pin.position);
};

const getCircleBounds = (center, radius) => {
	return new window.google.maps.Circle({ center, radius }).getBounds();
};

export const fitMapBounds = (googleMap, markers, agentMarkers, marker, polygonData, circleData) => {
	try {

		if (!googleMap || (!marker && !markers && !agentMarkers)) {
			return;
		}

		const bounds = new window.google.maps.LatLngBounds();

		if (markers && markers.length > 0) {
			markers.forEach((mk) => mk.markers.forEach((pin) => getMarkerBounds(pin, bounds)));
		}

		if (agentMarkers && agentMarkers.length > 0) {
			agentMarkers.forEach((mk) => mk.markers.forEach((pin) => getMarkerBounds(pin, bounds)));
		}

		if (marker) {
			getMarkerBounds(marker.marker, bounds);
		}

		if (polygonData?.polygons) {
			let polyArray = [];
			if (polygonData.defaultShow) {
				polyArray = Object.keys(polygonData.polygons);
			} else if(polygonData.visible.size > 0){
				polyArray = Array.from(polygonData.visible)
			}

			polyArray.forEach((id) => {
				const { path } = polygonData.polygons[id].options;
				if (path && path.length > 0) {
					path.forEach((vertex) => bounds.union(new window.google.maps.LatLngBounds(vertex)));
				}
			});
		}

		if (circleData?.circles) {
			let circleArray = [];
			if (circleData.defaultShow) {
				circleArray = Object.keys(circleData.circles)
			} else if (circleData.visible.size > 0) {
				circleArray = Array.from(circleData.visible)
			}

			circleArray.forEach((id) => {
				const { radius, center } = circleData.circles[id].options;
				if (radius && center) {
					bounds.union(getCircleBounds(center, radius));
				}
			});
		}

		if (!bounds.equals(new window.google.maps.LatLngBounds(defaultCenter))) {
			googleMap.fitBounds(bounds);
		}
	} catch (error) {
		console.log(error);
	}
};
