import React from 'react';

import { useLanguage } from 'Context/LanguageContext';
import FielderButton from 'FielderComponents/FielderButton';
import JSZip from 'jszip';
import LoadingConst from 'OldComponents/Loading/LoadingConst';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

import {
	getXLSXOrHTMLFromFormRequest,
	getPdfFromHtmlRequest,
	getFormHTMLRequest
} from '@core/api/form/form.service';

import {
	getXLSXOrHTMLFromInventoryRequest,
	getInventoryHTMLRequest
} from '@core/api/inventory/inventory.service';

import {
	getXLSXOrHTMLFromAssetRequest,
	getAssetHTMLRequest
} from '@core/api/asset/asset.service';

import { getCustomPDFBody, getImagesFromGroup } from '../util';

const TemplateFilesGenerator = ({
	taskData,
	taskAgent,
	asset,
	inventory,
	form,
	customTemplateType,
	hasImages,
	images,
}) => {
	const lan = useLanguage();
	const dispatch = useDispatch();

	const generateXlsx = () => {
		const body = {
			form,
			asset,
			inventory,
			idTask: taskData.idFielder,
			task: taskData,
			agent: taskAgent,
		};

		const loadingConfig = { name: "GENERATE_XLSX", dispatch };

		if (form) {
			const onSuccess = (res) => {
				window.open(res.link);
			}

			const onError = (error) => {
				console.log(error)
			}

			getXLSXOrHTMLFromFormRequest(body, onSuccess, onError, loadingConfig);
		}
		if (asset) {

			const onSuccess = (res) => {
				window.open(res.link);
			}

			const onError = (error) => {
				console.log(error)
			}

			getXLSXOrHTMLFromAssetRequest(body, onSuccess, onError, loadingConfig);
		}
		if (inventory) {
			const onSuccess = (res) => {
				window.open(res.link);
			}

			const onError = (error) => {
				console.log(error)
			}

			getXLSXOrHTMLFromInventoryRequest(body, onSuccess, onError, loadingConfig);
		}
	};

	const generatePDF = async (openAuto) => {
		try {
		let loadingConfig = null

		const templates = {
			form,
			asset,
			inventory,
		};

		const task = {
			task: taskData,
			agent: taskAgent,
		};

		if (openAuto) {
			loadingConfig = { name: "GENERATE_XLSX", dispatch };
		}

		let body = getCustomPDFBody(templates, task, customTemplateType, lan._language);
		const link = await new Promise((resolve, reject) => {

			if (body.form) {
        const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
				const onSuccess = (res) => {
					const bodyHtml = {
						idTask: taskData.idFielder,
						htmlUrlFile: res.link,
						footerHtml: res.footer_link,
						filename: `${taskData.idFielder}-${timestamp}-pdf`
					}


					const onSuccessHtml = (response) => {
						resolve(response.pdf);
					}
					const onErrorHtml = (error) => {
						console.log(error)
						reject();
					}

					getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
				}

				const onError = (error) => {
					console.log(error)
				}

				getFormHTMLRequest(body, onSuccess, onError, loadingConfig);



			} else if (body.asset) {
        const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
				const onSuccess = (res) => {
					const bodyHtml = {
						idTask: taskData.idFielder,
						htmlUrlFile: res.link,
						footerHtml: res.footer_link,
						filename: `${taskData.idFielder}-${timestamp}-pdf`
					}


					const onSuccessHtml = (response) => {
						resolve(response.pdf);
					}
					const onErrorHtml = (error) => {
						console.log(error)
						reject();
					}

					getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
				}

				const onError = (error) => {
					console.log(error)
				}

				getAssetHTMLRequest(body, onSuccess, onError, loadingConfig);


			} else if (body.inventory) {
        const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
				const onSuccess = (res) => {
					
					const bodyHtml = {
						idTask: taskData.idFielder,
						htmlUrlFile: res.link,
						footerHtml: res.footer_link,
						filename: `${taskData.idFielder}-${timestamp}-pdf`
					}


					const onSuccessHtml = (response) => {
						resolve(response.pdf);
					}
					const onErrorHtml = (error) => {
						console.log(error)
						reject();
					}

					getPdfFromHtmlRequest(bodyHtml, onSuccessHtml, onErrorHtml, null);
				}

				const onError = (error) => {
					console.log("error+html")
					console.log(error)
				}

				getInventoryHTMLRequest(body, onSuccess, onError, loadingConfig);
			}


		}).catch(() => {});

		if (openAuto) {
			window.open(link);
		} else {
			return link;
		}
		} catch (error) {
			console.log(error)
		}
	};

	const handlerDownloadImages = () => {
		dispatch(
			loadingStart({
				name:"ZIP_DOWNLOAD",
				config: {
					icon: LoadingConst.EXPORT,
					text: lan.exportMessage,
				},
			})
		);

		let templateType = '';

		if (asset) {
			templateType = lan.asset;
		} else if (inventory) {
			templateType = lan.inventory;
		} else if (form) {
			templateType = lan.forms;
		}

		getImagesFromGroup(images, 0, new JSZip(), templateType, dispatch, taskData);
	};

	return (
		<Col>
			<Row className='justify-content-end mt-2 mr-2'>
				{customTemplateType.xlsx && (
					<Col sm={3}>
						<FielderButton className='mt-3' onClick={generateXlsx}>
							{lan.generateXlsx}
						</FielderButton>
					</Col>
				)}

				{/* TODO: Agregar permisos paas */}
				{/* { validateRoles(assetConst.ASSETS, assetConst.DOWNLOAD_TASK_ASSET) &&
                                                            } */}
				<Col sm={3}>
					<FielderButton className='ml-auto mt-3 TaskModules-button' onClick={generatePDF}>
						{lan.generatePdf}
					</FielderButton>
				</Col>

				{hasImages && (
					<Col sm={3} className='ml-auto'>
						<button className='Fielder-button mt-3 mr-4 TaskModules-button' onClick={handlerDownloadImages}>
							{lan.downloadImages}
						</button>
					</Col>
				)}
			</Row>
		</Col>
	);
};

TemplateFilesGenerator.prototype = {
	type: PropTypes.oneOf(['asset', 'inventory', 'forms']),
	hasImages: PropTypes.bool,
	customTemplateType: PropTypes.object,
};

export default TemplateFilesGenerator;
