import React from 'react';

//Utility
import 'FielderUtils/style.css';

class IsochronousTypeFormatter extends React.Component {
	constructor(props) {
		super(props);
	}

	getIsochronousType = (val) => {
		if (val === 1) {
			return this.getCircle();
		} else {
			return this.getPolygon();
		}
	};

	getCircle = () => {
		return {
			borderRadius: 12,
			background: this.props.row.isoColor.fillColor,
			height: 25,
			width: 25,
			marginLeft: '40%',
		};
	};

	getPolygon = () => {
		return {
			background: this.props.row.isoColor.fillColor,
			borderLeft: 12,
			borderRight: 13,
			borderBottom: 25,
			borderColor: this.props.row.isoColor.fillColor,
			height: 25,
			width: 25,
			marginLeft: '40%',
			transform: 'rotate(45deg)',
		};
	};

	render() {
		let info = this.getIsochronousType(this.props.value);
		return <div style={info}></div>;
	}
}

export default IsochronousTypeFormatter;
