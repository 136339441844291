const WAITING = 1;
const EXECUTING = 2;
const RETRYING = 3;
const SUCCESSFULL = 4;
const FAILED_NO_AGENTS_AVAILABLE = -1;
const FAILED_NO_SKILLS = -2;
const FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS = -3;

export default {
	WAITING,
	EXECUTING,
	RETRYING,
	SUCCESSFULL,
	FAILED_NO_AGENTS_AVAILABLE,
	FAILED_NO_SKILLS,
	FAILED_AVAILABLE_AGENTS_IN_MULTIPLE_TEAMS,
};
