import React, { Component } from 'react';

import ErrorIcon from '@assets/icons/alert-icon.svg';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap';
import './ErrorMessage.css';
import 'FielderUtils/style.css';

class ErrorMessage extends Component {
	constructor(props, context) {
		super(props, context);
	}

	handleClose = () =>{
		this.props.closeErrorMessage();
	}

	render() {
		return (
			<>
				<Modal
					id='ErrorMessage-modal'
					size='sm'
					show={this.props.showErrorMessage}
					onHide={this.handleClose}
					dialogClassName='shadow-lg'
					className='Fielder-modals-header-border Fielder-modals-content-border shadow-lg'
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border'>
						<Modal.Title />
					</Modal.Header>

					<Modal.Body className='error-message-modal-body text-center'>
						{this.props.icon == null ? (
							<img src={ErrorIcon} className='error-message-center-icon' alt='' />
						) : (
							<img src={this.props.icon} className='error-message-center-icon' alt='' />
						)}

						{!this.props.showPdfCancelMessage ? (
							<div id='ErrorMessage-message' className='text-center ml-2 mr-2 mt-3 mb-3 error-message-margin'>
								{this.props.message}
							</div>
						) : (
							<div className='row'>
								<div id='ErrorMessage-message' className='text-center ml-2 mr-2 mt-3 mb-3 error-message-margin'>
									{this.props.message} <EditIcon style={{ height: '20px' }} />
								</div>
							</div>
						)}
					</Modal.Body>

					<Modal.Footer>
						<div className='mt-3'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size error-message-btn-margin'
								onClick={this.handleClose}
							>
								{this.props.lan ? this.props.lan.accept : 'Accept'}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default ErrorMessage;
