import React from 'react';

import lottie from '@assets/lotties/loadingFielder.json';
import propTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Lottie from 'react-lottie';

const Loading = ({ size }) => (
	<Row>
		<Col sm={2} className='mx-auto mt-3'>
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: lottie,
				}}
				height={size}
				width={size}
			/>
		</Col>
	</Row>
);
Loading.propTypes = {
	size: propTypes.string.isRequired,
};

export default Loading;
