import { getRefreshToken, setAccessToken, setIdToken } from 'FielderUtils/session/Session';
import { pipe } from 'lodash/fp';
import { REQUEST_TYPES } from '@core/constants/users/users.request.constants'

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest, handleGetRequest } from '../request';

const getRequestOption = () => {
	const options = {};
	return options;
};

export const login = (body, onSuccess, onError, loadingConfig) => {
	body.requestType = REQUEST_TYPES.LOGIN;
	return handlePostRequest(API_LINKS.loginUserManager, body, onSuccess, onError, loadingConfig);
};

const updateToken = (res) => {
	const { idToken, accessToken, refreshToken } = res;
	setAccessToken(accessToken);
	setIdToken(idToken);
	return res;
};

export const refreshSessionToken = async () => {
	const user = await getSelectUser();
	const body = {
		requestType: REQUEST_TYPES.REFRESH_TOKEN,
		username: user.userName,
		refreshToken: getRefreshToken(),
	};

	return handlePostRequest(API_LINKS.loginUserManager, body);
};

export const getTopMenuInfo = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const body = {
		idAccount: user.idAccount,
		idUser: user.idUser,
	};
	return handlePostRequest(API_LINKS.topMenuInfo, body, onSuccess, onError, loadingConfig);
};

export const doSwitchAccount = (user, onSuccess, onError, loadingConfig) => {
	const body = {
		requestType: REQUEST_TYPES.LOGIN_WITH_ID_USER,
		idUser: user.idUser,
		asGlobalUser: !user.account,
	};

	return handlePostRequest(API_LINKS.accountManager, body, onSuccess, onError, loadingConfig);
};

export const getUserGlobalInfo = (username, onSuccess, onError, loadingConfig) => {
	const body = {
		userName: username,
		type: REQUEST_TYPES.GET_USER_INFO_SWITCH_ACCOUNT,
	};

	return handlePostRequest(API_LINKS.apiUserManager, body, onSuccess, onError, loadingConfig);
};

// GET
export const updateProfile = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getUserInfoByRole = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_USER_INFO_BY_ROLE,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);

};

// Get export count
export const getExportUserCount = async (params, onSuccess, onError) => {
	const user = await getSelectUser();

	const queryParams = new URLSearchParams(params);
	queryParams.append('idUser', user.idUser)

	return handleGetRequest(API_LINKS.exportUsers, queryParams, onSuccess, onError);
};

// Get export task data
export const getExportUserList = async (params, onSuccess, onError) => {
	const user = await getSelectUser();

	const queryParams = new URLSearchParams(params);
	queryParams.append('idUser', user.idUser)

	return handleGetRequest(API_LINKS.exportUsers, queryParams, onSuccess, onError);
};

export const sendUserPassword = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.SEND_USER_PASSWORD,
		idClientUser: body.idUser
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};



export const createUserImportRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.clientUser.idAccount = user.idAccount;
	body.clientUser.idUser = user.idUser;
	body.clientUser.language = user.language;

	if (body.clientUser.location) {
		body.clientUser.location.accountId = user.idAccount;
	}

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CREATE_USER,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};