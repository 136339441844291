import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';
import TimeField from 'react-simple-timefield';
import 'FielderUtils/style.css';
import './InfoNewModules.css';

const _ = require('lodash');

class InfoTaskVariables extends Component {
	VariableField = (varProps) => {
		return (
			<Row>
				{varProps.element.type == 1 ? (
					<Col sm={12} className='mt-2'>
						<span className='title-padding text-title'>{varProps.element.name}</span>
						<input
							type='text'
							key={'input-' + varProps.index + '-'}
							className='form-control mt-1 text-left'
							placeholder={varProps.element.name}
							defaultValue={varProps.element.value ? varProps.element.value : ''}
							name='name'
							onChange={(e) => this.handleVariableFields(e, varProps.index, varProps.element.type)}
						/>
					</Col>
				) : (
					<Col sm={12} className='mt-2'>
						<span className='title-padding text-title'>{varProps.element.name}</span>

						<TimeField
							value={varProps.element.value ? varProps.element.value : '00:00'}
							onChange={(event, value) =>
								this.handleVariableFields(value, varProps.index, varProps.element.type)
							}
							style={{
								width: '100%',
								height: 'calc(1.5em + .75rem + 2px)',
								padding: '5px 8px',
								fontSize: 14,
								borderRadius: 6,
								border: '1px solid #ced4da',
							}}
						/>
					</Col>
				)}
			</Row>
		);
	};

	handleVariableFields = (event, index, type) => {
		const re = /^[0-9\b]+$/;
		let value = null;

		if (type == 1) {
			if (event.target.value === '' || re.test(event.target.value)) {
				value = event.target.value;
			}
		} else {
			value = event;
		}

		if (value) {
			this.props.taskType.routeVariables[index]['value'] = value;
		}
	};

	render() {
		return (
			<Col sm={12}>
				{this.props.taskType.routeVariables.map((element, index) => (
					<this.VariableField
						element={element}
						index={index}
						changeType={this.changeType}
						onChange={this.handleInputFields}
						id={'field-' + index}
						key={'field-' + index}
					/>
				))}
			</Col>
		);
	}
}

export default InfoTaskVariables;
