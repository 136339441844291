import React, { useEffect, useState } from 'react';
import { Switch as SwitchContainer, Handle, SwitchLabel } from './Switch.styles';

const spring = {
	type: 'spring',
	stiffness: 700,
	damping: 30,
};

const Switch = ({ id, label, onChange, defaultValue, value, useRawValue }) => {
	const [isOn, setIsOn] = useState(defaultValue || false);

	useEffect(() => setIsOn(defaultValue), [defaultValue]);
	useEffect(() => setIsOn(value), [value]);

	const toggleSwitch = () => {
		const newValue = !isOn;
		setIsOn(newValue);

		if (onChange) {
			onChange(newValue);
		}
	};

	if (label) {
		return (
			<SwitchLabel
				control={
					<SwitchContainer id={id} isActive={useRawValue != undefined ? useRawValue : isOn } onClick={toggleSwitch}>
						<Handle id={id + "handle"} transition={spring} whileHover={{ scale: 1.1 }} />
					</SwitchContainer>
				}
				label={label}
			/>
		);
	}
	return (
		<SwitchContainer id={id} isActive={useRawValue != undefined ? useRawValue : isOn} onClick={toggleSwitch}>
			<Handle id={id+"handle"}  transition={spring} whileHover={{ scale: 1.1 }} />
		</SwitchContainer>
	);
};

export default Switch;
