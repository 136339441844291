import React from 'react';

import view from '@assets/icons/view.svg';
import languages from 'FielderUtils/languages/languages';
import { Button } from 'react-bootstrap';
import LocalizedStrings from 'react-localization';
import StarRatings from 'react-star-ratings';

const strings = new LocalizedStrings(languages());

class AssetStatusFormatter extends React.Component {
	render() {
		let dot;
		let text;

		switch (parseInt(this.props.value)) {
			case 0:
				dot = 'Fielder-grey-dot';
				text = 'Inactive';
				break;
			case 1:
				dot = 'Fielder-pink-dot';
				text = 'Active'; // strings.lan.active;
				break;
		}

		return (
			<div className='row'>
				<div className='col-1'>
					<div className={`SmartTable-circle-texture text-center ${dot}`} />
				</div>
				<div className='my-auto col-7 pl-1'>{text}</div>
			</div>
		);
	}
}

export default AssetStatusFormatter;
