import { REQUEST_TYPES } from '@core/constants/task/task.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// POST
export const assignAgentToTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_ASSIGN_AGENT_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const assignPartnerTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_ASSIGN_PARTNER_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}


export const reassignPartnerTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_REASSIGN_PARTNER_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}





export const assingTeamTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_ASSIGN_TEAM_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

export const createTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CREATE_TASK,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// PUT
export const modifyTaskStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_MODIFY_TASK_STATUS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const rescheduleTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.RESCHEDULE_TASK,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const duplicateTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DUPLICATE_TASKS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const cancelTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const updateTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_TASK,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const rateTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_RATE_TASK,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const restartTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_RESTART_TASK,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// GET
export const getTaskMyOperationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_DETAIL_TASK_FOR_MY_OPERATION,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_VIEW_DETAIL_TASK,
		idAccount: user.idAccount,
		taskInfo: {
			task: {
				idTask: body.idTask,
				idAccount: user.idAccount,
			}
		},
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		user: {
			idUser: user.idUser,
			idAccount: user.idAccount,
		},
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTaskRealTimeRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_INFO_REALTIME,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTaskPhotosRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_PHOTOS,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const deleteTaskPhotosRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_DELETE_PHOTO_TASK,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}



export const countTasksByMailRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.COUNT_TASKS_BY_MAIL,
		idAccount: user.idAccount,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTasksByBranchSummaryForDashboardRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASKS_BY_BRANCH_SUMMARY_FOR_DASHBOARD,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
}


// DELETE
export const deleteTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DELETE_TASKS,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// RE-AUTO-ASSIGNED
export const reAutoAssignedRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_RE_AUTO_ASSIGN_PROCESS,
		idAccount: user.idAccount,
		...body,
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const disableTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DISABLE_TASKS,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTasksByBranchSummaryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASKS_BY_BRANCH_SUMMARY,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
}

export const getTasksByAccountSummaryRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_TASKS_BY_ACCOUNT_SUMMARY,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
}


export const getTaskIdsByDate = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_IDS_BY_DATE,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};



export const getTaskCalendarXlsxRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskCalendarXlsx, requestBody, onSuccess, onError, loadingConfig);
};




