import React, { Component } from 'react';

import RoleUtils from 'FielderUtils/roles/roleUtils';
import { canClientViewFunctionality } from 'FielderUtils/taskDetail/taskDetailUtil';
import { Row, Nav, Navbar } from 'react-bootstrap';
import './TaskDetails.css';
import 'FielderUtils/style.css';
import { connect } from 'react-redux';

class TaskDetailNavTab extends Component {
	render() {
		return (
			<Row className='mx-3'>
				<Navbar className='td-navbar mb-3'>
					<Nav.Item id='nav-info' className={`td-nav-item text-center ${this.props.isActive('info')}`}>
						<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('info')}>
							{this.props.lan.information}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item id='nav-exe' className={`td-nav-item text-center ${this.props.isActive('exe')}`}>
						<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('exe')}>
							{this.props.lan.execution}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item id='nav-agent' className={`td-nav-item text-center ${this.props.isActive('agent')}`}>
						<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('agent')}>
							{this.props.lan.agent}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item id='nav-pics' className={`td-nav-item text-center ${this.props.isActive('document')}`}>
						<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('document')}>
							{this.props.lan.documents}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item id='nav-pics' className={`td-nav-item text-center ${this.props.isActive('pics')}`}>
						<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('pics')}>
							{this.props.lan.photos}
						</Nav.Link>
					</Nav.Item>

					{!RoleUtils.isUserClient(this.props.user) && (
						<Nav.Item id='nav-act' className={`td-nav-item text-center ${this.props.isActive('act')}`}>
							<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('act')}>
								{this.props.lan.activity}
							</Nav.Link>
						</Nav.Item>
					)}

					{!RoleUtils.isUserClient(this.props.user) && (
						<Nav.Item id='nav-act' className={`td-nav-item text-center ${this.props.isActive('mod')}`}>
							<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('mod')}>
								{this.props.lan.functionality}
							</Nav.Link>
						</Nav.Item>
					)}

					{canClientViewFunctionality(this.props.user, this.props.modules) && (
						<Nav.Item id='nav-act' className={`td-nav-item text-center ${this.props.isActive('modClient')}`}>
							<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('modClient')}>
								{this.props.lan.functionality}
							</Nav.Link>
						</Nav.Item>
					)}
					{!RoleUtils.isUserClient(this.props.user) && (
						<Nav.Item id='nav-act' className={`td-nav-item text-center ${this.props.isActive('route')}`}>
							<Nav.Link className='td-nav-title' onClick={() => this.props.changeTab('route')}>
								{this.props.lan.route}
							</Nav.Link>
						</Nav.Item>
					)}
				</Navbar>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(TaskDetailNavTab);
