export const REQUEST_TYPES = {
	GET_USER_INFO: 'GET_USER_INFO',
	ACCEPT_PRIVACY: 'ACCEPT_PRIVACY',
	RECORD_LOGIN_ACTIVITY: 'RECORD_LOGIN_ACTIVITY',
	UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
	UPDATE_ACCOUNT_LOGO: 'UPDATE_ACCOUNT_LOGO',
	DO_ADD_MAP_LOG: 'DO_ADD_MAP_LOG',
	GET_CHAT_BOT_COUNT_MESSAGE: 'GET_CHAT_BOT_COUNT_MESSAGE',
	UPDATE_CHAT_BOT_COUNT_MESSAGE:'UPDATE_CHAT_BOT_COUNT_MESSAGE'
};
