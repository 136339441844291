import React from 'react';

import DangerAlert from '@assets/images/danger_blank.svg';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Row, Col } from 'react-bootstrap';

const NonPaymentWarning = ({ nonPaymentCounter, user }) => {
	const lan = useLanguage();
	return (
		<>
			{!RoleUtils.isUserClient(user) ? (
				<Row className='TopMenu-alert'>
					<Col sm={12} className='Top-menu-alert-text mx-auto'>
						{nonPaymentCounter === 1 ? (
							<div className='mt3'>
								<img src={DangerAlert} className='Top-menu-alert-img' alt='' />
								{lan.billingAlertNotPaid1}
								{nonPaymentCounter} {lan.day}
								{lan.billingAlertNotPaid2}
							</div>
						) : (
							<div className='mt-3'>
								<img src={DangerAlert} className='Top-menu-alert-img' alt='' />
								{lan.billingAlertNotPaid1}
								{nonPaymentCounter} {lan.days}
								{lan.billingAlertNotPaid2}
							</div>
						)}
					</Col>
				</Row>
			) : null}
		</>
	);
};

export default NonPaymentWarning;
