import React from 'react';

import { Grid } from '@mui/material';

import { CompanyIcon, RowTitle, RowSubtitle } from '../TaskToCashTable.styles';

const CompanyCell = (rowData) => {
	const { row, level } = rowData;
	const { branch, company, taskType } = row;

	const cellStyle = { paddingLeft: `${(level || 0) * 60}px` };

	const AvatarName = () => {
		const splittedName = company.name.split(' ');
		if (splittedName.length == 1) {
			return splittedName[0][0];
		}

		return splittedName[0][0] + splittedName[1][0];
	};

	if (!Array.isArray(branch) || !Array.isArray(taskType)) {
		return <RowSubtitle variant='subtitle'>{company.name}</RowSubtitle>;
	}

	return (
		<Grid container direction='row' style={cellStyle} spacing={1} alignContent='center' alignItems='center'>
			<Grid item md='auto'>
				<CompanyIcon className='mx-1' src={company.image}>
					{AvatarName()}
				</CompanyIcon>
			</Grid>
			<Grid item xs>
				<RowTitle>{company.name}</RowTitle>
			</Grid>
		</Grid>
	);
};

export default CompanyCell;
