import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import FielderButton from 'FielderComponents/FielderButton';
import Popup from '../Popup';

import 'FielderUtils/style.css';

class GeneratePdfFromXlsx extends Component {
	render() {
		const { lan, onClose, urlGeneratePdfFromXlsx, approve, cancel } = this.props;
		return (
			<Popup
				title={lan.generatePDFfromXlsx}
				centered
				width='xl'
				onClose={onClose}
				dialogClassName='centered-modal-pdf'
				propsContainer='pdf-container-size'
				closeButton
			>
				<Container className='no-padding no-margin' style={{ maxWidth:'100%', height: '660px' }}>
					<div style={{ height: '80%' }} className='mt-4'>
						<Scrollbars style={{ height: '100%', overflowY: 'hidden' }}>
							<div className='row-12'>
								<iframe
									src={`https://view.officeapps.live.com/op/embed.aspx?src=${urlGeneratePdfFromXlsx}`}
									width='100%'
									height='500px'
									frameBorder={0}
								/>
							</div>
						</Scrollbars>
						<Row className='my-3'>
							<Col sm={3} className='mx-auto'>
								<FielderButton id='generate-pdf-form-module' onClick={() => approve(urlGeneratePdfFromXlsx)} outlined>
									{lan.approve}
								</FielderButton>
							</Col>
							<Col sm={3} className='mx-auto'>
								<FielderButton id='generate-pdf-form-module' onClick={cancel} outlined>
									{lan.cancel}
								</FielderButton>
							</Col>
						</Row>
					</div>
				</Container>
			</Popup>
		);
	}
}

GeneratePdfFromXlsx.propTypes = {
	lan: PropTypes.shape({
		generatePDFfromXlsx: PropTypes.string.isRequired,
		approve: PropTypes.string.isRequired,
		cancel: PropTypes.string.isRequired,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	urlGeneratePdfFromXlsx: PropTypes.string.isRequired,
	approve: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
};

export default GeneratePdfFromXlsx;
