import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useLanguage } from 'Context/LanguageContext';
import { isLocalUser } from 'Utils/account/utils';

import { TopMenuNotifyIconButtonStyled } from './StyleComponents';
import NotificationDrawer from './NotificationsModal/NotificationDrawer';
import { AnimationLottie } from '../../AnimationLottie/index';
import TaskDetails from '../../../oldComponents/TaskDetails';

const TopMenuNotification = ({ user }) => {
	const lan = useLanguage();
	const [idTaskDetail, setIdTaskDetail] = useState(null);
	const [showDetailTask, setShowDetailTask] = useState(false);
	const [show, setShow] = useState(false);
	const [notificationOpen, setNotificationOpen] = useState(false);

	useEffect(() => {
		setShow(isLocalUser(user));
	}, [user]);
	
	const renderModalDetailTask = () => {
        return (
            <TaskDetails
                onClose={() => {
                    setIdTaskDetail(null);
                    setShowDetailTask(false);
                }}
                onCloseModal={() => {
                    setShowDetailTask(false)
                }}
                idTasks={[idTaskDetail]}
                canNotEdit={true}
                lan={lan}
            />
        )
    }
	return (
		<Grid item>
			{show && (
				<Tooltip title={lan.notifications}>
					<TopMenuNotifyIconButtonStyled
						onClick={() => setNotificationOpen(true)}>
						<AnimationLottie
							type="bell"
							height={56}
							width={56}
							isStopped={false}
							isPaused={false}
							isClickToPauseDisabled={true}
						/>
					</TopMenuNotifyIconButtonStyled>
				</Tooltip>
			)}
			<NotificationDrawer
				isOpen={notificationOpen}
				onClose={() => setNotificationOpen(false)}
				setIdTaskDetail={setIdTaskDetail}
				setShowDetailTask={setShowDetailTask}
				setNotificationOpen={setNotificationOpen}
				lan={lan}
			/>
			{showDetailTask && renderModalDetailTask()}
		</Grid>
	);
};
export default TopMenuNotification;
