import React, { Component } from 'react';

import Popup from '../Popup';
import Checkbox from '~/Checkbox/Checkbox.component';

import 'FielderUtils/style.css';
import './RouteDetails.css';

import { Row, Col } from 'react-bootstrap';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import { getRouteExpensesRequest } from '@core/api/route/route.service';
import { connect } from 'react-redux';

let Request = require('FielderRequestTypes');

class EditExpenses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expenses: [],
			saveClicked: false,
			successMessage: {
				show: false,
				message: '',
			},
		};
	}

	loadExpenses = () => {
		let body = Request.getRouteExpensesPreferences()

		const onSuccess = (response) => {
			this.renderRouteExpensesResponse(response);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		getRouteExpensesRequest(body, onSuccess, null, loadingConfig);

	};

	componentDidMount = () => {
		this.loadExpenses();
	};

	handleVariableFieldsEnable = (event, index, isDefaultType) => {
		let enabled;

		if (event.target != null) {
			enabled = event.target.checked;
		}

		let state = this.state;
		if (isDefaultType) {
			state.expenses.defaultExpenses[index].enabled = enabled;
		} else {
			state.expenses.dynamicExpenses[index].enabled = enabled;
		}

		this.setState(state);
	};

	handleVariableFields = (event, index, isDefaultType) => {
		let value;
		let name;

		if (event.target != null) {
			value = event.target.value;
			name = event.target.name;
		}

		let state = this.state;
		if (isDefaultType) {
			state.expenses.defaultExpenses[index][name] = value;
		} else {
			state.expenses.dynamicExpenses[index][name] = value;
		}

		this.setState(state);
	};

	renderRouteExpensesResponse = (res) => {
		let response = res;
		if (response.routeExpensesPreferences) {
			let preferences = response.routeExpensesPreferences;
			let expenses = preferences;
			if (this.props.route.expenses) {
				expenses = this.props.route.expenses;
			}
			let state = this.state;
			state.expenses = expenses;
			this.setState(state);
		}
	};

	onSaveExpenses = () => {
		let state = this.state;
		state.saveClicked = true;
		this.setState(state);

		this.updateRouteExpenses();
	};

	updateRouteExpenses = () => {
		let body = Request.getRequestDoUpdateRouteExpenses(this.props.route.id);
		body.routeExpenses = this.state.expenses;

		const onSuccess = (response) => {
			let state = this.state;
				state.successMessage = {
					show: true,
					message: this.props.lan.savedCorrectly,
				};
			this.setState(state);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		getRouteExpensesRequest(body, onSuccess, null, loadingConfig);

	};

	closeSuccessMessage = () => {
		let state = this.state;
		state.successMessage.show = false;
		state.successMessage.message = '';
		this.setState(state);
	};

	ExpenseField = (varProps) => {
		var icon = '';
		var border = '';
		var defaultName = null;
		var element = varProps.element;
		var isDefaultType = false;
		if (varProps.type === 'default') {
			isDefaultType = true;
			if (element.name === 'expensesFood') {
				defaultName = this.props.lan.expensesFood;
			} else if (element.name === 'expensesSleeping') {
				defaultName = this.props.lan.expensesSleeping;
			} else if (element.name === 'expensesKm') {
				defaultName = this.props.lan.expensesKm;
			}
		}

		if (varProps.saveClicked) {
			if (element.name == '') {
				icon = <img src={redAlertIcon} alt='' id='checkbox-alert-icon' className='icon' />;
				border = 'AddForm-border-red';
			}
		}
		return (
			<Row className='my-2'>
				<Col sm={6}>
					<Row>
						<Col className='label-input lato-font-label'>{this.props.lan.title}</Col>
					</Row>
					<Row>
						<Col className='mt-1 full-width Form-Question'>
							<input
								className={'form-control ' + border}
								placeholder={this.props.lan.title}
								value={defaultName ? defaultName : element.name}
								name='name'
								key={'input-name-' + varProps.index + '-'}
								disabled
							/>
						</Col>
						{icon}
					</Row>
				</Col>
				<Col sm={2}>
					<Row>
						<Col sm={'auto'} className='label-input my-auto lato-font-label'>
							{this.props.lan.value}
						</Col>
					</Row>
					<Row>
						<Col className='mt-1 full-width Form-Question'>
							<input
								className={'form-control ' + border}
								placeholder={this.props.lan.value}
								value={element.value}
								name='value'
								key={'input-value-' + varProps.index + '-'}
								onChange={(e) => this.handleVariableFields(e, varProps.index, isDefaultType)}
								disabled={!element.enabled}
							/>
						</Col>
					</Row>
				</Col>
				<Col sm={4} className='text-right mt-3'>
					<Checkbox
						label={this.props.lan.enableExpensesRoutes}
						checked={element.enabled}
						onChange={(e) => this.handleVariableFieldsEnable(e, varProps.index, isDefaultType)}
					/>
				</Col>
			</Row>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Popup
					title={this.props.lan.routeExpensesConfig}
					icon='expenses.png'
					show
					width='lg'
					closeButton
					onClose={this.props.onClose}
					buttonText={this.props.lan.save}
					buttonOnClick={this.onSaveExpenses}
					size='lg'
				>
					<Row>
						<Col sm={12} className='Fielder-Preferences-container'>
							{this.state.expenses.defaultExpenses &&
								this.state.expenses.defaultExpenses.map((element, index) => (
									<this.ExpenseField
										element={element}
										index={index}
										saveClicked={this.state.saveClicked}
										id={'field-' + index}
										key={'field-' + index}
										type={'default'}
									/>
								))}

							{this.state.expenses.dynamicExpenses &&
								this.state.expenses.dynamicExpenses.map((element, index) => (
									<this.ExpenseField
										element={element}
										index={index}
										saveClicked={this.state.saveClicked}
										id={'field-' + index}
										key={'field-' + index}
										type={'dynamic'}
									/>
								))}
						</Col>
					</Row>
				</Popup>

				<SuccessMessage
					message={this.state.successMessage.message}
					showSuccessMessage={this.state.successMessage.show}
					onClose={this.closeSuccessMessage}
					{...this.props}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(EditExpenses);
