import React, { useState, useEffect } from 'react';

import { MarkerF } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectCenter } from '../../../core/store/modules/map/mapSelectors';
import { getPinIcon } from '../../../core/utils/map/pinIcon';
import { convertGoogleLatLng } from '@core/utils/location/addressHandler.utils';

const getMarkerKey = (pos) => `pin-${pos.lat}${pos.lng}`;

const getMarkerIcon = (marker, config) => {
	if (marker?.icon) {
		return getPinIcon(marker.icon);
	} else if (config) {
		return getPinIcon(config?.icon);
	} else {
		return getPinIcon();
	}
};

const getMarkerOnClick = (marker, config) => {
	if (marker?.onClick) {
		return marker.onClick;
	} else if (config?.onClick) {
		return config.onClick;
	}
	return null;
};

const FielderMarker = ({ config, marker, clusterer, enableAnimation }) => {
	const center = useSelector(selectCenter);

	const [markerIcon, setMarkerIcon] = useState(null);
	const [markerOnClick, setMarkerOnClick] = useState(null);
	const [markerKey, setMarkerKey] = useState(null);

	useEffect(() => {
		let icon = getMarkerIcon(marker, config);
		setMarkerIcon(icon);

		let onClick = getMarkerOnClick(marker, config);
		setMarkerOnClick(() => onClick);

		let key = getMarkerKey(marker?.position ? marker.position : 'marker');
		setMarkerKey(key);
	}, [marker, config]);

	const onDragEnd = (coordinates) => {
		if (config?.onDragEnd) {
			const { latLng } = coordinates;
			config.onDragEnd(convertGoogleLatLng(latLng));
		}
	};

	return (
		<MarkerF
			id={markerKey}
			key={markerKey}
			clickable={markerOnClick != null}
			onClick={markerOnClick}
			draggable={config?.draggable}
			onDragEnd={onDragEnd}
			title={marker?.title}
			opacity={config?.opacity ? config.opacity : 1}
			icon={markerIcon}
			position={marker?.position ? marker.position : center}
			clusterer={clusterer}
			animation={enableAnimation && marker.animation}
		/>
	);

};

FielderMarker.prototype = {
	config: PropTypes.object,
	marker: PropTypes.object,
};

FielderMarker.defaultProps = {
	config: {},
	marker: null,
};

export default React.memo(FielderMarker);
