import React from 'react';

import { useLanguage } from 'Context/LanguageContext';
import moment from 'moment';
import { DateRangePicker, DatePicker } from './DatePicker.styles';

const getCalendarContainer = (trigger) => {
	return trigger.parentNode; // Set the container to the parent node of the input element
};

const RangePicker = (props) => {
	const { value, getDisabledDates, onChange, range, ...componentProps } = props;

	const lan = useLanguage();

	const handleChangeDates = ([newStartDate, newEndDate]) => onChange(newStartDate, newEndDate);
	const selectedDates = React.useMemo(() => {
		if (value) {
			return [moment(value.startDate), moment(value.endDate)];
		}
	}, [value]);

	return (
		<DateRangePicker
			format='YYYY-MM-DD'
			placeholder={[lan.initialDate, lan.finalDate]}
			disabledDate={getDisabledDates}
			value={selectedDates}
			onChange={handleChangeDates}
			separator='|'
			getCalendarContainer={getCalendarContainer}
			{...componentProps}
		/>
	);
};

const SingleDatePicker = (props) => {
	const { value, getDisabledDates, onChange, range, format, ...componentProps } = props;

	const lan = useLanguage();

	const dateFormat = format ? format : 'YYYY-MM-DD';

	const handleChangeDate = (value) => onChange(value);
	const selectedDate = React.useMemo(() => {
		if (value) {
			return moment(value);
		}
	}, [value]);

	return (
		<DatePicker
			format={dateFormat}
			placeholder={lan.date}
			disabledDate={getDisabledDates}
			value={selectedDate}
			onChange={handleChangeDate}
			getCalendarContainer={getCalendarContainer}
			{...componentProps}
		/>
	);
};

const FielderDatePicker = ({ range, ...props }) => {
	if (range) {
		return <RangePicker {...props} />;
	} else {
		return <SingleDatePicker {...props} />;
	}
};

export default React.memo(FielderDatePicker);
