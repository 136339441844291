import React from 'react';
import Lottie from 'react-lottie';
import bell from '@assets/lotties//bell.json';
import loader from '@assets/lotties/loadingNotification.json';
import { styled } from '@mui/material/styles';

const AnimationLottie = styled(({ type, height, width, isStopped, isPaused, ...other }) => {
	let animationData;

	switch (type) {
		case 'bell':
			animationData = bell;
			break;
		case 'loader':
			animationData = loader;
			break;
		default:
			throw new Error(`Unrecognized Lottie type: ${type}`);
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<Lottie
			options={defaultOptions}
			height={height}
			width={width}
			isStopped={isStopped}
			isPaused={isPaused}
			{...other}
		/>
	);
})({});

export { AnimationLottie };
