import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const FilledFielderButton = styled(Button)(() => ({
	background: '#f5e6ed !important',
	border: '#f5e6ed !important',
	color: '#dc5c87 !important',
	borderRadius: '6px !important',
	textAlign: 'center',
	transition: '0.3s',
	textTransform: 'none !important',
	width: '80%',
	height: '100%',
	maxHeight: '50px',
	padding: '6px, 16px',
	fontWeight: 'normal',
	fontFamily: ['Lato', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto'].join(','),
	'&:hover': {
		background: '#dc5c87 !important',
		border: '#dc5c87 !important',
		color: '#f5e6ed !important',
	},
	'&:active': {
		background: '#dc5c87 !important',
		border: '#dc5c87 !important',
		color: '#f5e6ed !important',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
	},
}));

const OutlinedFielderButton = styled(Button)(() => ({
	background: 'none !important',
	border: '1px solid #dc5c87 !important',
	color: '#dc5c87 !important',
	fontSize: '16px',
	borderRadius: '6px !important',
	padding: '6px, 16px',
	textAlign: 'center',
	transition: '0.3s',
	textTransform: 'none !important',
	fontWeight: 'normal',
	width: '80%',
	height: '50px',
	fontFamily: ['Lato', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto'].join(','),
	'&:hover': {
		background: '#dc5c87 !important',
		border: '1px solid #dc5c87 !important',
		color: '#FFF !important',
	},
	'&:active': {
		background: '#dc5c87 !important',
		border: '#dc5c87 !important',
		color: '#f5e6ed !important',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
	},
}));

export default function FielderButton({ id, onClick, size, outlined, children, type, className, ...props }) {
	return (
		<>
			{!outlined ? (
				<FilledFielderButton
					id={`${id}-fielderButton`}
					onClick={onClick}
					size={size}
					className={className}
					style={{ width: '100%' }}
					type={type}
					{...props}
				>
					{children}
				</FilledFielderButton>
			) : (
				<OutlinedFielderButton
					id={`${id}-fielderButton`}
					onClick={onClick}
					size={size}
					className={className}
					style={{ width: '100%' }}
					type={type}
					{...props}
				>
					{children}
				</OutlinedFielderButton>
			)}
		</>
	);
}

FielderButton.propTypes = {
	id: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	size: PropTypes.string,
	outlined: PropTypes.bool,
	children: PropTypes.node,
	type: PropTypes.string,
	className: PropTypes.string,
};

FielderButton.defaultProps = {
	size: 'medium',
	outlined: false,
	children: '',
	type: 'button',
	className: '',
};
