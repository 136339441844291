import React, { useState, useEffect } from 'react';

import { Autocomplete } from './GoogleMapsAutocomplete.styles';
import TextField from '~/TextField/TextField.component';
import { useLanguage } from 'Context/LanguageContext';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { getGeocode } from 'use-places-autocomplete';

import Options from './components/Options';
import { useJsApiLoader } from '@react-google-maps/api';
import { mapConfig } from '@core/utils/map/googleMaps.config';
import { InputAdornment } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { DeleteRedColor, Gainsboro, Glaucous } from '@core/theme/baseTheme';

const getOptionLabel = (option) => (typeof option === 'string' ? option : option?.description || option?.address);

const autocompleteService = { current: null };

const GoogleMapsAutocomplete = ({ label, onSelect, value: propsValue, error, ...props }) => {
	const lan = useLanguage();
	const [value, setValue] = useState(propsValue);
	const [inputValue, setInputValue] = useState(null);
	const [options, setOptions] = useState([]);

	const { isLoaded } = useJsApiLoader(mapConfig);

	useEffect(() => {
		if (propsValue === '') {
			setValue(null);
		} else {
			setValue(propsValue);
		}
	}, [propsValue]);

	const Input = React.useCallback(
		(params) => {
			const { InputProps } = params;
			InputProps.startAdornment = (
				<InputAdornment>
					<PlaceIcon sx={{ color: error ? DeleteRedColor : Glaucous }} />
				</InputAdornment>
			);

			return <TextField label={label || lan.searchLocation} {...params} InputProps={InputProps} error={error} />;
		},
		[lan, error]
	);

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 300),
		[]
	);

	useEffect(() => {
		let active = true;

		if (!window.google?.maps?.places) {
			return;
		}

		if (!autocompleteService.current) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		const language_code = lan._language.split('_')[0];
		const request = { input: inputValue };
		request.language = language_code;

		fetch(request, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [window.google, value, inputValue, fetch]);

	const handleFilterOptions = (option) => option;
	const handleOnInputChange = (e, newInputValue) => setInputValue(newInputValue);
	const handleOnChange = (e, newValue) => {
		if (!newValue) {
			return;
		}
		const address = newValue.description;
		getGeocode({ address }).then((results) => {
			const lat = results[0].geometry.location.lat();
			const lng = results[0].geometry.location.lng();
			const addressComponents = results[0].address_components;

			const completeAddress = { address, coordinates: { lat, lng } };
			addressComponents.forEach((addressComponent) => {
				if (addressComponent.types[0] === 'administrative_area_level_1') {
					completeAddress.state = addressComponent.long_name;
				}

				if (addressComponent.types[0] === 'country') {
					completeAddress.country = addressComponent.long_name;
				}

				if (addressComponent.types[0] === 'postal_code') {
					completeAddress.postalCode = addressComponent.long_name;
				}
			});

			onSelect(completeAddress);
			setOptions(newValue ? [newValue, ...options] : options);
			setValue(newValue);
		});
	};

	if (isLoaded) {
		return (
			<Autocomplete
				id='google-maps-autocomplete'
				fullWidth
				autoComplete
				includeInputInList
				filterSelectedOptions
				getOptionLabel={getOptionLabel}
				filterOptions={handleFilterOptions}
				options={options}
				value={value}
				onChange={handleOnChange}
				onInputChange={handleOnInputChange}
				renderInput={Input}
				renderOption={Options}
				{...props}
			/>
		);
	}

	return <></>;
};

GoogleMapsAutocomplete.propTypes = {
	label: PropTypes.string,
	onSelect: PropTypes.func,
	value: PropTypes.string,
};

export default GoogleMapsAutocomplete;
