/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	template: null,
	templateCache: {
		companies: [],
		branches: [],
		isochronous: [],
		isochronousUsers: {},
		skills: [],
    taskPriority:[],
	},
};

const autoAssignSlice = createSlice({
	name: 'autoAssign',
	initialState,
	reducers: {
		resetState: () => initialState,
		setTemplateInformation: (state, action) => {
			state.template = action.payload.template;
			state.templateCache.branches = action.payload.branches;
			state.templateCache.companies = action.payload.companies;
			state.templateCache.isochronous = action.payload.isochronous;
			state.templateCache.skills = action.payload.skills;
      state.templateCache.taskPriority = action.payload.taskPriority;
		},
		setClearTemplateCacheBranchesAndCompanies: (state) => {
			if (state.templateCache.branches && state.templateCache.branches.length > 0) {
				state.templateCache.branches = [];
			}

			if (state.templateCache.companies && state.templateCache.companies.length > 0) {
				state.templateCache.companies = [];
			}
		},
		setClearTemplateCacheSkills: (state) => {
			if (state.templateCache.skills && state.templateCache.skills.length > 0) {
				state.templateCache.skills = [];
			}
		},
		setAddIsochronous: (state, action) => {
			state.templateCache.isochronous = [...state.templateCache.isochronous, action.payload];
		},
		setRemoveIsochronous: (state, action) => {
			state.templateCache.isochronous = state.templateCache.isochronous.filter((iso) => iso.id != action.payload);
		},
		setClearIsochronous: (state) => {
			state.templateCache.isochronous = [];
		},
		setAddIsochronousUsers: (state, action) => {
			const { isochronous, users } = action.payload;
			state.templateCache.isochronousUsers[isochronous] = users;
		},
    setAddTaskPriority: (state, action) => {
			state.templateCache.taskPriority = [...state.templateCache.taskPriority, action.payload];
		},
		setRemoveTaskPriority: (state, action) => {
			state.templateCache.taskPriority = state.templateCache.taskPriority.filter((p) => p.id != action.payload);
		},
		setClearTaskPriority: (state) => {
      state.templateCache.taskPriority = [];
		},
	},
});

export const {
	resetState,
	setTemplateInformation,
	setClearTemplateCacheBranchesAndCompanies,
	setClearTemplateCacheSkills,
	setAddIsochronous,
	setRemoveIsochronous,
	setClearIsochronous,
	setAddIsochronousUsers,
  setAddTaskPriority,
	setRemoveTaskPriority,
	setClearTaskPriority,
} = autoAssignSlice.actions;

export default autoAssignSlice.reducer;
