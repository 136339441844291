import { CottonCandy, DeepSpaceGrey, FielderPink } from '@core/theme/baseTheme';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const PasswordButton = styled(Button)`
	background-color: ${FielderPink};
	color: white;
	font-family: 'Lato';
	border: none;
	border: 1px solid white;
	:hover {
		background-color: white;
		color: ${FielderPink};
		border: 1px solid ${FielderPink};
	}
	:disabled {
		color: ${DeepSpaceGrey};
		background-color: ${CottonCandy};
	}
`

export { PasswordButton };