import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { selectUser } from '../../../../core/store/modules/session/sessionSelectors';
import { useSelector } from 'react-redux';

const DocumentationSideMenu = () => {
    const lan = useLanguage();
    const user = useSelector(selectUser);
    const title = lan.documentation;

    const [isPermitted, setPermitted] = useState(false);
    useEffect(() => {
        const isUserPermitted = RoleUtils.isUserSuperAndSuperViewerAndAdministrator(user) || RoleUtils.isUserManager(user);
        setPermitted(isUserPermitted);
    }, [user]);

    return (
        <>
            {isPermitted ? (
                <Tooltip title={title} placement='right'>
                    <ListItemButton
                        onClick={() => window.open(process.env.FIELDER_DOCUMENTATION_LINK)}
                    >
                        <ListItemIcon>
                            <img
                                className=''
                                src={`${process.env.AWS_FIELDER_ICONS}documentation-icon.svg`}
                                alt='documentation'
                                style={{ width: '20px' }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={title} />
                    </ListItemButton>
                </Tooltip>
            ) : null}
        </>
    );
};
export default DocumentationSideMenu;