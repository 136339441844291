import React, { useState, forwardRef } from 'react';

import DropdownLeftIcon from '@assets/icons/drop-rigth-icon.svg';
import { useLanguage } from 'Context/LanguageContext';
import { Row, Col } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import InfoTaskVariablesModal from '../InfoTaskVariablesModal';

const TaskVariables = ({ hasRouteVariables, taskType, isNotAClient }, ref) => {
	const lan = useLanguage();
	const dispatch = useDispatch();

	const [showPopup, setShowPopup] = useState(false);

	const handlePopup = () => {
		setShowPopup(!showPopup);
	};

	return (
		<>
			{showPopup && (
				<InfoTaskVariablesModal
					lan={lan}
					closeModal={handlePopup}
					showModal={showPopup}
					taskTypeId={taskType.id}
					taskType={taskType}
				/>
			)}
			{isNotAClient && hasRouteVariables ? (
				<Row className='mx-3'>
					<Col sm={12} className='mt-3'>
						<button className='btn full-width text-left buttonFunction' onClick={handlePopup}>
							<Row className='my-2'>
								<Col>
									<span className='text-left'> {lan.routeTaskVariables.toUpperCase()}</span>
								</Col>
								<Col className='d-flex mr-3 align-content-end'>
									<img className='CreateTask-padding-button ml-auto' src={DropdownLeftIcon} alt='' />
								</Col>
							</Row>
						</button>
					</Col>
				</Row>
			) : null}
		</>
	);
};

export default forwardRef(TaskVariables);
