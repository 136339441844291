import React from 'react';


import DangerIcon from '@assets/icons/danger-icon.svg';
import WaningToast from '@assets/icons/waning-toast-icon.svg';
import {Image} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './SmartTable.css';

let title = '';
//let code=0

class IconFormatterWarning extends React.Component {
	constructor(props) {
		super(props);

		var elementName = '';
		var idElement = 0;

		if (this.props.row.idBranch) {
			elementName = this.props.lan.branch;
			idElement = this.props.row.idBranch;
		}

		if (this.props.row.idCompany) {
			elementName = this.props.lan.nameCompany;
			idElement = this.props.row.idCompany;
		}

		if (this.props.row.idUser) {
			elementName = this.props.lan.element;
			idElement = this.props.row.idUser;
		}

		if (this.props.row.id) {
			elementName = this.props.lan.route;
			idElement = this.props.row.id;
		}
		if (this.props.row.idAgent) {
			elementName = this.props.lan.agent;
			idElement = this.props.row.idAgent;
		}
		if (this.props.row.idGroupTask) {
			elementName = this.props.lan.groupTask;
			idElement = this.props.row.idGroupTask;
		}

		if (this.props.row.idTeam) {
			elementName = this.props.lan.team;
			idElement = this.props.row.idTeam;
		}

		this.state = {
			elementName: elementName,
			idElement: idElement,
		};
	}

	/************* TOAST CONTAINER*************/
	notify = (code) => {
		var titleNotificaction;
		if (code == 301) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_TASK;
		}
		if (code == 302) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_TEAM;
		}
		if (code == 303) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_SYSTEM;
		}
		if (code == 304) {
			titleNotificaction = this.props.lan.UNKNOWN_ERROR_DELETE_USER;
		}
		if (code == 334) {
			titleNotificaction = this.props.lan.CONFLICT_EMERGENCIES;
		}
		if (code == 335) {
			titleNotificaction = this.props.lan.CONFLICT_ROUTE;
		}
		if (code == 336) {
			titleNotificaction = this.props.lan.CONFLICT_SERVICE_ON_ROUTE;
		}
		if (code == 351) {
			titleNotificaction = this.props.lan.EXIST_CLIENT_ASSOCIATE;
		}
		if (code == 400) {
			titleNotificaction = this.props.lan.EXIST_BRANCHES_BY_COMPANY;
		}
		if (code == 401) {
			titleNotificaction = this.props.lan.EXIST_SUBCOMPANIES_BY_COMPANY;
		}
		if (code == 450) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_TASK_AGENT;
		}
		if (code == 451) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_SYSTEM_AGENT;
		}
		if (code == 452) {
			titleNotificaction = this.props.lan.UNKNOWN_ERROR_DELETE_AGENT;
		}

		if (code == 500) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_AGENT;
		}
		if (code == 501) {
			titleNotificaction = this.props.lan.EXIST_ASSOCIATE_BY_MANAGER;
		}
		if (code == 502) {
			titleNotificaction = this.props.lan.UNKNOWN_ERROR_DELETE_TEAM;
		}

		if (code == 810) {
			titleNotificaction = this.props.lan.FAIL_UPDATE_GROUP_TASKS;
		}
		if (code == 811) {
			titleNotificaction = this.props.lan.FAIL_DELETE_TASKS;
		}
		if (code == 812) {
			titleNotificaction = this.props.lan.WRONG_DELETE_STATUS;
		}
		if (code == 813) {
			titleNotificaction = this.props.lan.GROUP_NOT_FOUND;
		}

		let Img = ({ src }) => (
			<div className='row'>
				<div className='col-auto'>
					{' '}
					<img width={38} src={WaningToast} />
				</div>
				<div className='col-auto d-flex justify-content-center align-self-center'>
					<span className='boldText'>
						{this.state.elementName} {this.state.idElement}
					</span>{' '}
					:{titleNotificaction}
				</div>{' '}
			</div>
		);

		toast(<Img />, {
			position: toast.POSITION.BOTTOM_CENTER,
			hideProgressBar: true,
			bodyClassName: 'toast-font-style',
			autoClose: 4500,
		});
	};

	render() {
		let statusCode = this.props.value;

		return (
			<React.Fragment>
				<div className='row no-padding'>
					{statusCode !== 0 && (
						<Image
							src={DangerIcon}
							onMouseOver={() => this.notify(this.props.value)}
							alt=''
							className='SmartTable-icon-image'
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default IconFormatterWarning;
