import { REQUEST_TYPES } from '@core/constants/account/request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';

export const getProfileUser = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_USER_INFO,
		...body,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const acceptPrivacyPolicyRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		type: REQUEST_TYPES.ACCEPT_PRIVACY,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const recordLoginRequest = async (loadingConfig) => {
	const user = await getSelectUser();
	const requestBody = {
		type: REQUEST_TYPES.RECORD_LOGIN_ACTIVITY,
		idAccount: user.idAccount,
		idUser: user.idUser,
		role: user.role,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, null, null, loadingConfig);
};

export const updateAccountPreferencesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_ACCOUNT,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateAccountLogoRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_ACCOUNT_LOGO,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getAccountViewRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const body = {
		idAccount: user.idAccount,
		idUser: user.idUser,
	};

	return handlePostRequest(API_LINKS.topMenuInfo, body, onSuccess, onError, loadingConfig);
};


export const getAccountViewRequestMasterUser = async (body, onSuccess, onError, loadingConfig) => {
	return handlePostRequest(API_LINKS.topMenuInfo, body, onSuccess, onError, loadingConfig);
};

export const addMapLogs = async (body) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
		pageName: body.pageName,
		requestType: REQUEST_TYPES.DO_ADD_MAP_LOG,
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody);
};


export const getChatBotCountMessageByUser = async () => {
	const user = await getSelectUser();
	const today = new Date();

	const body = {
		type: REQUEST_TYPES.GET_CHAT_BOT_COUNT_MESSAGE,
		day: today.getDate(),
		month: today.getMonth(), 
		year: today.getFullYear(),
	};

	const onSuccess = (response) => response.countMessage;
	const onError = () => 0;

	const countMessage = await handlePostRequest(API_LINKS.apiUserManager, body, onSuccess, onError, null);
	return countMessage
};


export const updateChatBotCountMessage = async () => {
	const user = await getSelectUser();
	const today = new Date();

	const body = {
		type: REQUEST_TYPES.UPDATE_CHAT_BOT_COUNT_MESSAGE,
		day: today.getDate(),
		month: today.getMonth(), 
		year: today.getFullYear(),
	};

	handlePostRequest(API_LINKS.apiUserManager, body, null, null, null);
};
