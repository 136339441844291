import { REQUEST_TYPES } from '@core/constants/password/password.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import { getAccessToken } from 'FielderUtils/session/Session';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';


export const changePasswordRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();
	const accessToken = getAccessToken();

	const requestBody = {
		requestType:  REQUEST_TYPES.CHANGE_PASSWORD_USER,
		changePasswordInfo: {
			idUser: user.idUser,
			oldPassword: body.oldPassword,
			newPassword: body.newPassword,
			accessToken,
		},
	};

	return handlePostRequest(API_LINKS.loginUserManager, requestBody, onSuccess, onError, loadingConfig);
}



export const recoverPasswordRequest = async (body, onSuccess, onError, loadingConfig) => {
	
	const requestBody = {
		requestType:  REQUEST_TYPES.RECOVER_USERS_PASSWORD,
		...body
	};

	return handlePostRequest(API_LINKS.loginUserManager, requestBody, onSuccess, onError, loadingConfig);
}