
import React from 'react';

import Switch from '~/Switch/Switch.component';
import { useLanguage } from 'Context/LanguageContext';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import RoleUtils from 'FielderUtils/roles/roleUtils';

const TaskSwitchHeader = ({ showTaskUpdates, showTaskGroupUpdates, handleTaskShowUpdates, handleTaskGroupShowUpdates,
	groupTaskFunctionality, canViewTaskGroups }) => {

	const lan = useLanguage();
	const user = useSelector(selectUser);

	return(
		<React.Fragment>
			<div className='col d-flex justify-content-end mr-5'>
				<div className='row'>
					<div className='col-auto ml-2'>
						<Switch
							id='switch-show-updates-task'
							value={showTaskUpdates}
							onChange={handleTaskShowUpdates}
							label={lan.showUpdates}
						/>
					</div>

					{!RoleUtils.isUserClient(user) &&
						groupTaskFunctionality &&
						canViewTaskGroups && (
							<Switch
								id='switch-show-updates-task-group'
								value={showTaskGroupUpdates}
								onChange={handleTaskGroupShowUpdates}
								label={lan.showTaskGroupUpdates}
							/>
						)}
				</div>
			</div>
		</React.Fragment>
	)

}

export default React.memo(TaskSwitchHeader);