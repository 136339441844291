import React, { forwardRef } from 'react';

import { Paper } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { useLanguage } from 'Context/LanguageContext';
import './UpdateFileMenu.css';

const ACCEPT_FILES =
	'.xls,.xlsx,.txt,.csv,.pdf,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png,.ods,.odt,.odp,.msg,.mp4,.gif,.tif,.tiff';

const UpdateFileMenu = (props) => {
	const lan = useLanguage();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const [idAccountFile, setIdAccountFile] = React.useState(props.idAccountFile);
	const [idFile, setIdFile] = React.useState(props.idFile);
	const [fileUrl, setFileUrl] = React.useState(props.fileUrl);
	const [originType, setOriginType] = React.useState(props.originType);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleCloseDoc = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleCloseLink = (event) => {
		props.updateLinkHandler(props.idFile, props.fileUrl, props.originType);
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const updateFileHandler = (event) => {
		props.updateFileHandler(event, idAccountFile, idFile, fileUrl, originType);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	};

	return (
		<>
			<div className='row'>
				<div className='col-auto'>
					<Stack direction='row' spacing={2}>
						<div>
							<Button
								ref={anchorRef}
								className='color-attach-button'
								id='composition-button'
								aria-controls={open ? 'composition-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-haspopup='true'
								onClick={handleToggle}
							>
								<AttachmentIcon />
								{lan.update}
							</Button>
							<Popper
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement='bottom-start'
								transition
								disablePortal
								style={{
									zIndex: '10',
								}}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleCloseDoc}>
												<MenuList
													autoFocusItem={open}
													id='composition-menu'
													aria-labelledby='composition-button'
													onKeyDown={handleListKeyDown}
												>
													<MenuItem onClick={handleCloseDoc}>
														<label
															htmlFor={`file-update${props.fileUrl}`}
															style={{
																border: 'none',
															}}
															className='custom-file-update pl-0'
														>
															<CloudUploadIcon /> {lan.updateFile}
														</label>
													</MenuItem>
													{!props.dontShowLink && (
														<MenuItem onClick={handleCloseLink}>
															<LinkIcon /> {lan.updateLink}
														</MenuItem>
													)}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
					</Stack>
				</div>
			</div>

			<input
				id={`file-update${props.fileUrl}`}
				key={`file-update-key${props.fileUrl}`}
				style={{ display: 'none' }}
				className='task-upload-btn-wrapper'
				type='file'
				onChange={(event) => updateFileHandler(event)}
				accept={props.acceptFiles}
			/>
		</>
	);
};

export default UpdateFileMenu;
