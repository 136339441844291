import React, { Component } from 'react';

import EditGruopTaskIcon from '@assets/icons/edit-group-task-popup-icon.svg';
import editImageIcon from '@assets/icons/edit-icon.svg';
import DownloadImagesIcon from '@assets/icons/group-download-images.svg';
import ExportIcon from '@assets/icons/import-icon.svg';
import infoIcon from '@assets/icons/information-icon.svg';
import UnlinkTaskGroupIcon from '@assets/icons/unlink-icon.svg';
import groupTaskConst from 'FielderUtils/roleManagementConst/groupTasksConst';
import GroupStatus from 'FielderUtils/status/groupTaskStatus';
import { Row, Image } from 'react-bootstrap';
import Lottie from 'react-lottie';
import lottie from '@assets/lotties/loadingFielder.json';

import { useLanguage } from 'Context/LanguageContext';
import useValidateRoles from '@core/utils/roles/useValidateRoles';

const EditGroupTaskTitle = (props) => {

	const lan = useLanguage();
	const { isRoleValid } = useValidateRoles();

	const canDownloadGroupTaskImages = isRoleValid(groupTaskConst.GROUPTASK,groupTaskConst.DOWNLOAD_TASK_IMAGES)
	const canEditGroupTask =  isRoleValid(groupTaskConst.GROUPTASK,	groupTaskConst.EDIT)
	const canExportGroupTasks = isRoleValid(groupTaskConst.GROUPTASK, groupTaskConst.EXPORT_TASKS)
	const canUnlinkGroupTask = isRoleValid(groupTaskConst.GROUPTASK, groupTaskConst.UNLINK);


	return (
		<React.Fragment>
			<Row className='full-width ml-2 '>
				<div className='col-auto padding-right'>
					<Image src={EditGruopTaskIcon} alt='' />
				</div>
				<div className='col-auto padding-lef mt-2'>
					<p className='Fielder-modal-popUp-title padding-lef-title '>
						&nbsp; {props.titleBranch}
					</p>
				</div>

				{props.loadingActive ? (
					<div className='col-auto mt-1 mr-2 no-padding'>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: lottie,
							}}
							height={'40px'}
							width={'40px'}
						/>
					</div>
				) : (
				<>
					{props.tab === 'infoTaskGroup' && (
						<React.Fragment>
							{GroupStatus.FINISH_NAME !== props.status && canEditGroupTask && (
								<div className='col-auto pt-1 padding-lef '>
									<a href='#Edit' onClick={props.handleEdit}>
										<Image
											src={editImageIcon}
											className='align-center'
											title={lan.editTaskGroup}
										/>
									</a>
								</div>
							)}

							{canDownloadGroupTaskImages && (
								<div className='col-auto pt-1 padding-lef '>
									<a href='#Edit' onClick={props.handleDownloadImage}>
										<Image
											src={DownloadImagesIcon}
											className='align-center'
											title={lan.downloadTaskGroupImages}
										/>
									</a>
								</div>
							)}
						</React.Fragment>
					)}

				{props.tab === 'taskInfo' && (
					<React.Fragment>
						{canExportGroupTasks && (
							<div className='col-auto padding-lef '>
								<Image
									src={ExportIcon}
									className='align-center cursor-pointer'
									onClick={props.showExportTask}
									title={lan.export}
								/>
							</div>
						)}

						{canUnlinkGroupTask && (
							<div className='col-auto pt-1 padding-lef '>
								<a href='#Edit' onClick={props.handleUnlinkTask}>
									<Image
										src={UnlinkTaskGroupIcon}
										className='align-center'
										title={lan.unlinkTaskGroup}
									/>
								</a>
							</div>
						)}
						{props.showTooltip && (
							<React.Fragment>
								<div className='pl-2 pr-2 col-auto'>
									<div className='tooltipCustomProgram'>
										<img src={infoIcon} alt='' />
										<span className='tooltiptextCustomProgram'>{lan.selectTasks}</span>
									</div>
								</div>
							</React.Fragment>
						)}
						{props.showTooltipFinishTask && (
							<React.Fragment>
								<div className='pl-2 pr-2 col-auto'>
									<span className='text-format-task-finish'>
										{lan.selectTasksFinish}
									</span>
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
				</>
				)}
					<div className='col d-flex justify-content-end'>
						<span className='text-format-task-finished'>
							{lan.taskFinished}: {props.countNumberFinish}
						</span>
					</div>
			</Row>
		</React.Fragment>
	);

}

export default EditGroupTaskTitle;
