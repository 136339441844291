const ACCEPT_PRIVACY_POLICY = true;
const REJECT_PRIVACY_POLICY = false;
const TERMS_OF_SERVICE_LINK = 'https://www.appfielder.com/terms-of-service/';
const PRIVACY_POLICY_LINK = 'https://www.appfielder.com/policy';

export default {
	ACCEPT_PRIVACY_POLICY,
	REJECT_PRIVACY_POLICY,
	TERMS_OF_SERVICE_LINK,
	PRIVACY_POLICY_LINK,
};
