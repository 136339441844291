import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import { MoreInfoIcon } from './MoreInformation.styles';

const MoreInformation = ({ title, ...props }) => {
	return (
		<Tooltip title={title} {...props}>
			<MoreInfoIcon />
		</Tooltip>
	);
};

export default MoreInformation;
