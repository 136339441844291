import { ICoordinates } from '@core/models/coordinates.model';

type BaseMarkerProps = {
	size?: 'tiny' | 'mid' | 'small' | 'normal';
	color?: string;
	icon?: string;
	label?: string;
	anchor?: string;
};

type StaticMarkerProps = {
	location: ICoordinates;
} & BaseMarkerProps;

const defaultProps = {
	size: 'normal',
};

const StaticMarkerGroup = (props: BaseMarkerProps) => null;
StaticMarkerGroup.defaultProps = defaultProps;

const StaticMarker = (props: StaticMarkerProps) => null;
StaticMarker.defaultProps = defaultProps;
StaticMarker.Group = StaticMarkerGroup;

export default StaticMarker;
