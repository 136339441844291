import { AGENT_STATUS } from '@core/constants/agent/agentStatus.constants';
import moment from 'moment';

export const getAgentStatus = (onDuty, offDutyCause, acceptedPrivacyPolicy, isOnline) => {
	const status = {
		agentStatus: 0,
		offDutyCause,
	};

	if (!acceptedPrivacyPolicy) {
		status.agentStatus = AGENT_STATUS.noData;
	} else if (!onDuty) {
		status.agentStatus = AGENT_STATUS.inactive;
	} else if (isOnline) {
		status.agentStatus = AGENT_STATUS.online;
	} else {
		status.agentStatus = AGENT_STATUS.offline;
	}

	return status;
};

export const getAgentLastUpdate = (geolocation) => {
	if (geolocation) {
		return moment(geolocation.dateTime).format('YYYY-MM-DD HH:mm');
	}
	return null;
};
