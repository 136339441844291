import React, { Component } from 'react';
import AlerYellowtIcon from '@assets/icons/alert-icon.svg';

import {
	getOnlyAgentsByFilterAsyncSelect,
	getOnlyAgentsInitialOptionsAsyncSelect,
} from 'FielderUtils/agent/agent';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import AsyncSearch from '../../AsyncSearch/AsyncSearch';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Popup from '../../Popup';

import '../MyOperation.css';

/***** SERVERLESS IMPORT  *****/
import { doActionRestartRouteRequest } from '@core/api/route/route.service';

class ResetRouteDateAgentPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		let idUserAssigned = [];
		let rescheduleSuccess = true;
		let rescheduleFail = false;

		this.state = {
			date: null,
			idUserAssigned: idUserAssigned,
			showErrorMessage: false,
			showErrorMessageText: '',
			showErrorMessageIcon: null,
			rescheduleSuccess: rescheduleSuccess,
			rescheduleFail: rescheduleFail,
			idAgent: null,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			user: session.user,
			errorMessage: false,
		};
	}

	showMessage = () => {
		this.props.onClose();
	};

	closePopup = () => {
		this.setState({ errorMessage: !this.state.errorMessage });
	};

	manageGetRestartRoute = (res) => {
		this.props.onClose(this.state.rescheduleSuccess, this.state.rescheduleFail);
	};

	restartRoute = () => {
		if (this.state.idAgent != null) {
			let selectedDate = this.state.date;

			let body = {
				idRoute: this.props.idRoute,
				idAgent: this.state.idAgent,
				toYear: selectedDate.year(),
				toMonth: selectedDate.month() + 1,
				toDay: selectedDate.date(),
				toHour: selectedDate.hour(),
				toMinute: selectedDate.minutes(),
			};

			const onSuccess = (response) => {
				this.manageGetRestartRoute(response);
			}
	
			const onError = (response) => {
				if (response.errorCode == 950) {
					let state = this.state;
					state.rescheduleSuccess = false;
					state.rescheduleFail = false;
					this.setState(state);

					this.props.onClose(false, false);
				} else {
					let state = this.state;
					state.rescheduleSuccess = false;
					state.rescheduleFail = true;
					this.setState(state);
					this.props.onClose(false, true);
				}
			}
	
			const loadingConfig = { dispatch: this.props.dispatch }
			doActionRestartRouteRequest(body, onSuccess, onError, loadingConfig);

		} else {
			this.setState({ errorMessage: true });
		}
	};

	handleProgramDate = (date) => {
		let state = this.state;
		state.date = date;
		this.setState(state);
	};

	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];

			assignList = await getOnlyAgentsByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.user,
				getIdToken(),
				this.props.lan
			);

			return assignList;
		}
	};

	getInitialOptions = async () => {
		return await getOnlyAgentsInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.user,
			getIdToken(),
			this.props.lan
		);
	};

	setFieldAssign = (event) => {
		this.setState({
			searchAssignValue: event,
		});

		if (event != null) {
			this.setState({ idAgent: event.value.idAgent });
		} else {
			this.setState({ idAgent: null });
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.state.errorMessage && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={() => this.closePopup()}
						message={this.props.lan.messageSelectAgent}
						showErrorMessage={this.state.errorMessage}
						icon={AlerYellowtIcon}
					/>
				)}

				<Popup
					title={this.props.lan.restartRoute}
					icon='calendar-icon.svg'
					show
					closeButton
					onClose={this.props.onClose}
					buttonText={this.props.lan.restart}
					requiredFields={this.props.lan.requiredFields}
					buttonOnClick={this.restartRoute}
					size={'ps2'}
				>
					<Row>
						<Col sm={12}>
							{/* Date Picker */}
							<div className='mt-3'>
								<Row>
									<Col className='small-popup-label'> {this.props.lan.selectRescheduledDate}</Col>
								</Row>
								<Row>
									<Col sm={6} className='ml-3'>
										<DateTimePicker
											onChange={this.handleProgramDate}
											defaultValue={this.state.date}
											lan={this.props.lan}
										/>
									</Col>
								</Row>
							</div>

							<React.Fragment>
								<Col sm={12} className='mt-3'>
									<span className='title-padding text-title'>{this.props.lan.assignTo}</span>
								</Col>
								<Col key='col-task-assing-to'>
									<AsyncSearch
										className='react-select__control full-width'
										getOptions={(e) => this.getAssignList(e)}
										onChange={(e) => this.setFieldAssign(e)}
										label={this.props.lan.roleSearch}
										lan={this.props.lan}
										id='select-add-client'
										showInitialOptions
										getInitialOptions={() => this.getInitialOptions()}
									/>
								</Col>
							</React.Fragment>
						</Col>
					</Row>
				</Popup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(ResetRouteDateAgentPopup);
