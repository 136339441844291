import React, { Component } from 'react';

import DeleteIcon from '@assets/icons/delete-icon.svg';
import ExportIcon from '@assets/icons/import-icon.svg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player';
import LoadingConst from '../Loading/LoadingConst';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Scrollbars } from 'react-custom-scrollbars';
import GoogleDocsViewer from 'react-google-docs-viewer';
import UpdateFileMenu from './UpdateFileMenu';
import UpdateDocumentMessage from './UpdateDocumentMessage';
import UpdateDocumentLinkMessage from './UpdateDocumentLinkMessage';
import UploadFileMenu from 'OldComponents/CreateTask/components/UploadFileMenu';
import { getSourceFile } from 'OldComponents/CreateTask/utils';
import Tooltip from '@mui/material/Tooltip';
import ImageGallery from 'react-image-gallery';
import presignedUrlForUpload from '@core/utils/aws/s3/presignedUrlForUpload';

import './TaskDetails.css';
import 'FielderUtils/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { deleteTaskDocumentRequest, getTaskDocumentsRequest, updateTaskDocumentRequest, uploadTaskDocumentRequest } from '@core/api/task/taskDocuments.service';
import { connect } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

const moment = require('moment');
const BUCKET_NAME_KEY = 'servicesFiles';
const SLASH = '/';
const ACL = 'public-read';
const ACCEPT_FILES =
	'.xls,.xlsx,.txt,.csv,.pdf,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png,.ods,.odt,.odp,.msg,.mp4,.gif,.tif,.tiff';
/**
 * Contains the Gallery content from the {@link TaskDetails} component
 */
class TaskDocument extends Component {
	/**
        Contructor
    */
	constructor(props) {
		super(props);

		this.state = {
			showUpdateMessage: false,
			showUpdateLinkMessage: false,
			message: this.props.lan.updateFileMessage,
			updateFunction: true,

			showDocComponent: false,
			filesTask: [],
			filesTaskUpload: [],
			idFileAux: null,
			fileUrleAux: null,
			originTypeAux: null,
			urlFileLink: null,
		};
	}

	componentDidMount = () => {
		this.refreshDocument();
	};

	refreshDocument = () => {
		this.props.changeLoadingState();

		const body = {
			taskInfo: {
				idTask: this.props.idTask,
			},
		};

		const onSuccess = ({ serviceFiles }) => {
			if (serviceFiles) {
				this.setDocuments(serviceFiles);
			} else {
				this.setState({ showDocComponent: true, filesTask: serviceFiles });
				this.props.changeLoadingState();
			}
		};
		const onError = () => {
			this.setState({ showDocComponent: true });
			this.props.changeLoadingState();
		};

		getTaskDocumentsRequest(body, onSuccess, onError);
	};

	setDocuments = (serviceFiles) => {
		this.setState({ filesTask: serviceFiles }, () => this.setState({ showDocComponent: true }));
		this.props.changeLoadingState();
	};

	fileSelectedHandler = async (event) => {
		let fileArray = this.state.filesTaskUpload;
		let filesInformation = event.target.files;

		for (let i = 0; i < filesInformation.length; i++) {
			const file = filesInformation[i];

			const fileName = filesInformation[i].name;
			const mime = filesInformation[i].type;
			const fileSize = filesInformation[i].size;
			const typeFile = fileName.split('.').pop();

			fileArray.push({
				fileName: fileName,
				fileBin: file,
				fileMime: mime,
				fileSize: fileSize,
				thumbnail: getSourceFile(typeFile),
				originType: false,
			});
		}
		this.setState({ filesTaskUpload: fileArray });
	};

	linkSelectedHandler = () => {
		let fileArray = this.state.filesTaskUpload;
		fileArray.push({
			fileUrl: '',
			originType: true,
		});
		this.setState({ filesTaskUpload: fileArray });
	};

	handleLink = (value, index) => {
		let fileArray = this.state.filesTaskUpload;
		for (var x = 0; x < fileArray.length; x++) {
			if (x == index) {
				fileArray[x].fileUrl = value;
				break;
			}
		}
		this.setState({ filesTaskUpload: fileArray });
	};

	handlerDelete = (index) => {
		let fileArray = this.state.filesTaskUpload;
		fileArray.splice(index, 1);
		this.setState({ filesTaskUpload: fileArray });
	};

	uploadToS3 = (file, accountId, idUser) => {
		const bucket = `${BUCKET_NAME_KEY}/${accountId}/${this.props.idTask}`;
		const key = `document-${new Date().getTime()}`;
		var totalSize = file.fileSize;
		var partSizeAux = 0;

		const onProgress = (progress) => {
			this.props.dispatch(
				loadingStart({
					name: 'TASK_DOCUMENT',
					config: {
						icon: LoadingConst.IMPORT,
						text: this.props.lan.uploadFileMessage,
						progress,
					},
				})
			);
		};

		return presignedUrlForUpload(bucket, key, file.fileBin, { contentType: file.fileMime, acl: ACL }, idUser, onProgress);
	};

	handleDoActionUploadFiles = async () => {
		let fileArray = this.state.filesTaskUpload;

		for (let i = 0; i < fileArray.length; i++) {
			let file = fileArray[i];
			let documentUrl = null;

			if (!file.originType) {
				this.props.dispatch(loadingStart({
					name: 'TASK_DOCUMENT',
					config: {
						icon: LoadingConst.IMPORT,
						text: this.props.lan.uploadFileMessage,
						progress: 0,
					}
				}))

				documentUrl = await this.uploadToS3(file, this.props.idAccountTask, this.props.session.user.idUser);

				if (!documentUrl) {
					this.refreshDocument();
					this.setState({ filesTaskUpload: [] });
					this.props.dispatch(loadingStop('TASK_DOCUMENT'));
					return;
				}
			}

			const body = {
				serviceFile: {
					idService: this.props.idTask,
					originType: file.originType,
					documentName: !file.originType ? file.fileName : null,
					fileUrl: documentUrl ? documentUrl : file.fileUrl,
				},
				idAccount: this.props.user.idAccount,
				idUser: this.props.user.idUser,
			};

			const loadingConfig = { dispatch: this.props.dispatch };
			uploadTaskDocumentRequest(body, null, null, loadingConfig).then(() => {
				this.refreshDocument();
				this.setState({ filesTaskUpload: [] });
				this.props.dispatch(loadingStop('TASK_DOCUMENT'));
			})

		}
	};

	/******************* DELTE FILE ***********************/

	deleteFileHandler = (idFile, fileUrl, originType) => {
		var message = this.props.lan.deleteFileMessage;
		if (originType) {
			message = this.props.lan.deleteLinkMessage;
		}

		this.setState(
			{
				idFileAux: idFile,
				fileUrleAux: fileUrl,
				originTypeAux: originType,
			},
			() =>
				this.setState({
					showUpdateMessage: true,
					message: message,
					updateFunction: false,
				})
		);
	};

	deleteFileConfirm = () => {
		var urlFileKey = null;
		if (!this.state.originTypeAux) {
			var urlSplit = this.state.fileUrleAux;
			var auxUrlFileKey = urlSplit.split(BUCKET_NAME_KEY);
			urlFileKey = BUCKET_NAME_KEY + auxUrlFileKey[1];
		}

		const body = {
			serviceFile: {
				idFile: this.state.idFileAux,
				fileUrl: urlFileKey,
				idService: this.props.idTask,
				originType: this.state.originTypeAux,
			},
		}

		const loadingConfig = { dispatch: this.props.dispatch, name: 'TASK_DOCUMENT'}
		const onSuccess = this.refreshDocument;

		deleteTaskDocumentRequest(body, onSuccess, null, loadingConfig).then(() => {
			this.setState({ showUpdateMessage: false });
		});
	};

	handleCloseDelete = () => {
		this.setState({
			showUpdateMessage: false,
			idFileAux: null,
			fileUrleAux: null,
			originTypeAux: null,
		});
	};

	/******************* UPDATE FILE ***********************/
	updateFileHandler = (event, idAccount, idFile, fileUrl, originType) => {
		const file = event.target.files[0];
		let fileSize = event.target.files[0].size;

		var auxUrlFileKey = '';
		var urlFileKey = null;
		if (originType === false) {
			auxUrlFileKey = fileUrl.split(BUCKET_NAME_KEY);
			urlFileKey = BUCKET_NAME_KEY + auxUrlFileKey[1];
		}

		this.setState(
			{
				fileUpdate: file,
				mimeUpdate: file.type,
				fileNameUpdate: file.name,
				originTypeUpdate: false,
				idAccountFileUpdate: idAccount,
				fileSizeUpdate: fileSize,

				originalIdFile: idFile,
				originalFileKey: urlFileKey,
				originalOriginType: originType,
			},
			() =>
				this.setState({
					showUpdateMessage: true,
					message: this.props.lan.updateFileMessage,
					updateFunction: true,
				})
		);
	};

	updateFileConfirm = async () => {
		let auxFile = {
			fileSize: this.state.fileSizeUpdate,
			fileMime: this.state.mimeUpdate,
			fileBin: this.state.fileUpdate,
		};

		let documentUrl = await this.uploadToS3(auxFile, this.state.idAccountFileUpdate, this.props.session.user.idUser);

		if (documentUrl) {
			const body = {
				originalFileKey: this.state.originalFileKey,
				originalOriginType: this.state.originalOriginType,
				serviceFile: {
					idFile: this.state.originalIdFile,
					documentName: this.state.fileNameUpdate,
					idService: this.props.idTask,
					fileUrl: documentUrl,
					originType: this.state.originTypeUpdate,
					idAccount: this.props.user.idAccount,
					idUser: this.props.user.idUser,
				},
			};

			const loadingConfig = { dispatch: this.props.dispatch, name: 'TASK_DOCUMENT' };
			const onSuccess = this.refreshDocument;
			updateTaskDocumentRequest(body, onSuccess, console.log, loadingConfig).then(() => {
				this.setState({ showUpdateMessage: false });
			});
		}
	};

	handleCloseUpdate = () => {
		this.setState({
			showUpdateMessage: false,
			showUpdateLinkMessage: false,
			fileUpdate: null,
			mimeUpdate: null,
			fileNameUpdate: null,
			originTypeUpdate: null,
			idAccountUpdate: null,
			idAccountFileUpdate: null,

			originalIdFile: null,
			originalFileUrl: null,
			originalOriginType: null,

			urlFileLink: null,
		});
	};

	/********************** UPDATE FILE LINK ****************************/
	updateLinkHandler = (idFile, fileUrl, originType) => {
		var auxUrlFileKey = '';
		var urlFileKey = null;
		if (originType === false) {
			auxUrlFileKey = fileUrl.split(BUCKET_NAME_KEY);
			urlFileKey = BUCKET_NAME_KEY + auxUrlFileKey[1];
		}

		this.setState(
			{
				originTypeUpdate: true,

				originalIdFile: idFile,
				originalFileKey: urlFileKey,
				originalOriginType: originType,
			},
			() =>
				this.setState({
					showUpdateLinkMessage: true,
					messageLink: this.props.lan.updateFileMessage,
				})
		);
	};

	updateLink = () => {
		const body = {
			originalFileKey: this.state.originalFileKey,
			originalOriginType: this.state.originalOriginType,
			serviceFile: {
				idFile: this.state.originalIdFile,
				idService: this.props.idTask,
				fileUrl: this.state.urlFileLink,
				originType: this.state.originTypeUpdate,
				idAccount: this.props.user.idAccount,
				idUser: this.props.user.idUser,
			},
		};

		const loadingConfig = { dispatch: this.props.dispatch, name: 'TASK_DOCUMENT' };
		const onSuccess = this.refreshDocument;
		updateTaskDocumentRequest(body, onSuccess, null, loadingConfig).then(() => {
			this.setState({ showUpdateLinkMessage: false });
		});
	};

	handleChangeLinkInput = (value) => {
		this.setState({ urlFileLink: value });
	};

	render() {
		return (
			<div className='container' style={{ height: '550px' }}>
				{this.state.showUpdateMessage && (
					<UpdateDocumentMessage
						{...this.props}
						handleClose={this.state.updateFunction ? this.handleCloseUpdate : this.handleCloseDelete}
						updateFileConfirm={this.state.updateFunction ? this.updateFileConfirm : this.deleteFileConfirm}
						message={this.state.message}
					/>
				)}

				{this.state.showUpdateLinkMessage && (
					<UpdateDocumentLinkMessage
						{...this.props}
						handleClose={this.handleCloseUpdate}
						updateFileConfirm={this.updateLink}
						message={this.state.messageLink}
						handleChangeLinkInput={this.handleChangeLinkInput}
					/>
				)}

				{this.state.showDocComponent && (
					<Scrollbars style={{ height: '100%', overflowY: 'hidden !important' }}>
						<div className='row-12 mx-3'>
							<UploadFileMenu
								fileSelectedHandler={this.fileSelectedHandler}
								filesTask={this.state.filesTaskUpload}
								linkSelectedHandler={this.linkSelectedHandler}
								handleLink={this.handleLink}
								handlerDelete={this.handlerDelete}
								showButtonAddFiles={true}
								handleDoActionUploadFiles={this.handleDoActionUploadFiles}
							/>
						</div>

						{this.state.filesTask.length <= 0 && (
							<div className='row'>
								<div className='col'>
									<div
										align='center'
										style={{
											zIndex: '1',
											marginTop: '4.5rem',
											fontSize: '20px',
											fontWeight: 'bold',
										}}
									>
										{' '}
										{this.props.lan.noServiceFile}{' '}
									</div>
								</div>
							</div>
						)}

						{this.state.filesTask.map((fileTask, index) => (
							<React.Fragment>
								<div className='row mx-3 mt-3' key={'row-doc' + index}>
									<div className='col-2 mt-2'>
										<div style={{ fontWeight: 'bold' }}>{this.props.lan.fileName + ':'}</div>
									</div>
									<div className='col-10 mt-2'>
										{fileTask.originType == false ? (
											<Tooltip title={fileTask.documentName ? fileTask.documentName : this.props.lan.unnamed}>
												<p className='document-text-overFlow'>
													{fileTask.documentName ? fileTask.documentName : this.props.lan.unnamed}
												</p>
											</Tooltip>
										) : (
											<p>{this.props.lan.externalLink}</p>
										)}
									</div>
								</div>

								<div className='row mx-3' key={'row-doc-update' + index}>
									<div className='col-auto mt-2'>
										<div style={{ fontWeight: 'bold' }}>{this.props.lan.lastUpdateDate + ':'}</div>
									</div>
									<div className='col mt-2'>
										<div>{moment(fileTask.creationDate).format('DD-MM-YYYY HH:mm')}</div>
									</div>

									{fileTask.originType === false ? (
										<img
											className='Fielder-icon-button sizeIconDocumentDownload buttonSizePage'
											src={ExportIcon}
											alt=''
											onClick={() => {
												window.open(fileTask.fileUrl, '_blank').focus();
											}}
										/>
									) : (
										<React.Fragment>
											<div
												onClick={() => {
													window.open(fileTask.fileUrl, '_blank').focus();
												}}
											>
												<ExitToAppIcon className={'Fielder-icon-button sizeIconDocumentDownload buttonSizePage'} />
											</div>
										</React.Fragment>
									)}

									<div className='col'>
										<UpdateFileMenu
											key={'row-docUpdate' + index}
											idAccountFile={fileTask.idAccount}
											idFile={fileTask.idFile}
											fileUrl={fileTask.fileUrl}
											originType={fileTask.originType}
											updateFileHandler={this.updateFileHandler}
											updateLinkHandler={this.updateLinkHandler}
											acceptFiles={ACCEPT_FILES}
										/>
									</div>

									<div className='col-auto mt-1'>
										<img
											className='icon Fielder-icon-button mr-2'
											title={this.props.lan.delete}
											src={DeleteIcon}
											onClick={() => this.deleteFileHandler(fileTask.idFile, fileTask.fileUrl, fileTask.originType)}
											alt=''
										/>
									</div>
								</div>

								<div className='row mx-3 d-flex justify-content-end mb-2' key={'row-doc-user' + index}>
									{fileTask.user && (
										<>
											<div className='col-auto mt-2 d-flex justify-content-end'>
												<div style={{ fontWeight: 'bold' }}>{this.props.lan.user + ':'}</div>
											</div>
											<div className='col-auto mt-2 d-flex justify-content-end'>
												<div>
													{fileTask.user
														? fileTask.user.name + ' ' + (fileTask.user.lastName ? fileTask.user.lastName : '')
														: this.props.lan.noData}
												</div>
											</div>
										</>
									)}
									{fileTask.agent && (
										<>
											<div className='col-auto mt-2 d-flex justify-content-end'>
												<div style={{ fontWeight: 'bold' }}>{this.props.lan.agent + ':'}</div>
											</div>
											<div className='col-auto mt-2 d-flex justify-content-end'>
												<div>
													{fileTask.agent
														? fileTask.agent.name + ' ' + (fileTask.agent.lastName ? fileTask.agent.lastName : '')
														: this.props.lan.noData}
												</div>
											</div>
										</>
									)}
								</div>

								{fileTask.originType === false &&
									fileTask.documentName != undefined &&
									fileTask.documentName != null &&
									!fileTask.documentName.includes('.png') &&
									!fileTask.documentName.includes('.jpg') &&
									!fileTask.documentName.includes('.jpeg') &&
									!fileTask.documentName.includes('.ppt') &&
									!fileTask.documentName.includes('.pptx') &&
									!fileTask.documentName.includes('.mp4') &&
									!fileTask.documentName.includes('.gif') &&
									!fileTask.documentName.includes('.tif') &&
									!fileTask.documentName.includes('.tiff') &&
									!fileTask.documentName.includes('.mp3') && (
										<div className='row-12 mx-3'>
											<GoogleDocsViewer width={'100%'} height={400} fileUrl={fileTask.fileUrl} />
										</div>
									)}

								{fileTask.originType === false &&
									fileTask.documentName != undefined &&
									fileTask.documentName != null &&
									fileTask.documentName.includes('.ppt') &&
									fileTask.documentName.includes('.pptx') && (
										<div className='row-12 mx-3'>
											<iframe
												src={'https://view.officeapps.live.com/op/embed.aspx?src=' + fileTask.fileUrl}
												width='100%'
												height='600px'
												frameBorder='0'
											>
												{' '}
											</iframe>
										</div>
									)}

								{fileTask.originType === false &&
									fileTask.documentName != undefined &&
									fileTask.documentName != null &&
									fileTask.documentName.includes('.mp4') && (
										<div className='row-12 mx-3'>
											<div className='col d-flex justify-content-center'>
												<ReactPlayer url={fileTask.fileUrl} playing={false} controls={true} />
											</div>
										</div>
									)}
								{fileTask.originType === false &&
									fileTask.documentName != undefined &&
									fileTask.documentName != null &&
									fileTask.documentName.includes('.mp3') && (
										<div className='row-12 mx-3'>
											<div className='col d-flex justify-content-center'>
												<AudioPlayer autoPlay={false} src={fileTask.fileUrl} />
											</div>
										</div>
									)}

								{fileTask.originType === false &&
									fileTask.documentName != undefined &&
									fileTask.documentName != null &&
									(fileTask.documentName.includes('.png') ||
										fileTask.documentName.includes('.jpg') ||
										fileTask.documentName.includes('.jpeg') ||
										fileTask.documentName.includes('.gif') ||
										fileTask.documentName.includes('.tif') ||
										fileTask.documentName.includes('.tiff')) && (
										<div className='row-12 mx-3'>
											<div className='col d-flex justify-content-center mx-2'>
												<ImageGallery
													items={[{ original: fileTask.fileUrl, originalClass: 'document-task-gallery' }]}
													showPlayButton={false}
												/>
											</div>
										</div>
									)}
							</React.Fragment>
						))}
					</Scrollbars>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(TaskDocument);
