import React from 'react';

import LoginsRecordsIcon from '@assets/icons/user-login-section.svg';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const AccountsSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.records;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={() => goToPage(ENDPOINTS.records)}>
				<ListItemIcon>
					<img className='' src={LoginsRecordsIcon} alt='records' style={{ width: '20px' }} />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default AccountsSideMenu;
