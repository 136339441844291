import AgentPin from '@assets/icons/map_icons/pin-agent-active.gif';
import InactiveAgentPin from '@assets/icons/map_icons/pin-agent-inactive.png';
import EmergencyPin from '@assets/icons/map_icons/pin-emergency.svg';
import GreenPin from '@assets/icons/map_icons/pin-green.png';
import IsochronousPin from '@assets/icons/map_icons/pin-isochronous.png';
import PinkPin from '@assets/icons/map_icons/pin-pink.png';
import HighPriorityPin from '@assets/icons/map_icons/pin-priority-high.svg';
import LowPriorityPin from '@assets/icons/map_icons/pin-priority-low.svg';
import MediumPriorityPin from '@assets/icons/map_icons/pin-priority-medium.svg';
import TaskArrivedPin from '@assets/icons/map_icons/pin-task-arrived.svg';
import TaskAssignedPartnerPin from '@assets/icons/map_icons/pin-task-assigned-partner.svg';
import TaskAssignedTeamPin from '@assets/icons/map_icons/pin-task-assigned-team.svg';
import TaskAutoAssignFailedPin from '@assets/icons/map_icons/pin-task-auto-assign-failed.svg';
import TaskAutoAssignSuccessPin from '@assets/icons/map_icons/pin-task-auto-assign-success.svg';
import TaskAutoAssigning from '@assets/icons/map_icons/pin-task-auto-assigning.svg';
import TaskCancelledByPartnerPin from '@assets/icons/map_icons/pin-task-cancelled-by-partner.svg';
import TaskCancelledPin from '@assets/icons/map_icons/pin-task-cancelled.svg';
import TaskDeletedPin from '@assets/icons/map_icons/pin-task-deleted.svg';
import TaskEndRoutePin from '@assets/icons/map_icons/pin-task-end-route.png';
import TaskExecutingPin from '@assets/icons/map_icons/pin-task-executing.svg';
import TaskFinishedPin from '@assets/icons/map_icons/pin-task-finished.svg';
import TaskInProgressPin from '@assets/icons/map_icons/pin-task-in-progress.svg';
import TaskManagingPin from '@assets/icons/map_icons/pin-task-managing.svg';
import TaskPausedPin from '@assets/icons/map_icons/pin-task-paused.svg';
import TaskStartRoutePin from '@assets/icons/map_icons/pin-task-start-route.svg';
import TaskWaitingPin from '@assets/icons/map_icons/pin-task-waiting-agent.svg';
import WarningPin from '@assets/icons/map_icons/pin-warning.gif';

export const getPinIcon = (marker) => {
	switch (marker) {
		case 'green':
			return GreenPin;
		case 'pink':
			return PinkPin;
		case 'agent':
			return AgentPin;
		case 'inactiveAgent':
			return InactiveAgentPin;
		case 'iso':
			return IsochronousPin;
		case 'yellow':
			return EmergencyPin;
		case 'managing':
			return TaskManagingPin;
		case 'waiting':
			return TaskWaitingPin;
		case 'in-progress':
			return TaskInProgressPin;
		case 'executing':
			return TaskExecutingPin;
		case 'finish':
			return TaskFinishedPin;
		case 'cancel':
			return TaskCancelledPin;
		case 'start-route':
			return TaskStartRoutePin;
		case 'cancel-by-partner':
			return TaskCancelledByPartnerPin;
		case 'assigned-to-partner':
			return TaskAssignedPartnerPin;
		case 'assigned-to-team':
			return TaskAssignedTeamPin;
		case 'arrived':
			return TaskArrivedPin;
		case 'deleted':
			return TaskDeletedPin;
		case 'autoassigning':
			return TaskAutoAssigning;
		case 'autoassigning-success':
			return TaskAutoAssignSuccessPin;
		case 'autoassigning-failed':
			return TaskAutoAssignFailedPin;
		case 'paused':
			return TaskPausedPin;
		case 'high':
			return HighPriorityPin;
		case 'medium':
			return MediumPriorityPin;
		case 'low':
			return LowPriorityPin;
		case 'warning':
			return WarningPin;
		case 'end-route':
			return TaskEndRoutePin;
		default:
			return PinkPin;
	}
};
