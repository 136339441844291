import RealTime from '@core/constants/realTime/actions.constants';

export const taskActions = {
	update: new Set([
		RealTime.TASK_AGENT_ASSIGN,
		RealTime.TASK_TEAM_ASSIGN,
		RealTime.TASK_EXECUTE_AUTO_ASSIGN,
		RealTime.TASK_STATUS_UPDATE_AUTO_ASSIGN,
		RealTime.TASK_CANCEL,
		RealTime.TASK_RESCHEDULE,
		RealTime.TASK_PARTNER_ASSIGN,
		RealTime.TASK_UPDATE,
		RealTime.TASK_MOBILE_STATUS_UPDATE,
		RealTime.TASK_MOBILE_TASK_TYPE_UPDATE,
		RealTime.TASK_MOBILE_RESET_EXECUTION,
		RealTime.TASK_MOBILE_REJECT,
		RealTime.TASK_MOBILE_AGENT_ASSIGN,
		RealTime.TASK_MOBILE_TEAM_ASSIGN,
		RealTime.TASK_MOBILE_CANCEL,
		RealTime.TASK_MOBILE_RESCHEDULE,
		RealTime.TASK_MOBILE_GROUP_ASSIGN,
		RealTime.TASK_MOBILE_UPDATE,
		RealTime.TASK_MOBILE_GROUP_ASSIGN,
		RealTime.TASK_GROUP_ASSIGN,
		RealTime.TASK_GROUP_REFUSE,
		RealTime.TASK_GROUP_UPDATE,
		RealTime.TASK_GROUP_STATUS_UPDATE,
		RealTime.TASK_FINISH,
		RealTime.TASK_ROUTE_ADD,
	]),
	add: new Set([RealTime.TASK_CREATE, RealTime.TASK_ENABLE]),
	delete: new Set([RealTime.TASK_DELETE, RealTime.TASK_DISABLE, RealTime.TASK_FINISH]),
};
export const agentActions = {
	update: new Set([
		RealTime.AGENT_POSITION_UPDATE,
		RealTime.AGENT_STATUS_UPDATE,
		RealTime.ROUTE_CANCELLED,
		RealTime.ROUTE_FINISHED,
		RealTime.ROUTE_REJECTED,
	]),
	updateSeveral: new Set([
		RealTime.TASK_GROUP_FINISH,
		RealTime.TASK_GROUP_UPDATE_AGENTS,
		RealTime.TASK_GROUP_DELETE,
		RealTime.TASK_ROUTE_ASSIGNED,
	]),
	taskAdd: new Set([RealTime.TASK_AGENT_ASSIGN, RealTime.TASK_ENABLE, RealTime.TASK_MOBILE_AGENT_ASSIGN]),
	taskRemove: new Set([
		RealTime.TASK_DISABLE,
		RealTime.TASK_DELETE,
		RealTime.TASK_MOBILE_CANCEL,
		RealTime.TASK_CANCEL,
		RealTime.TASK_RESCHEDULE,
		RealTime.TASK_FINISH,
		RealTime.TASK_MOBILE_CANCEL,
		RealTime.TASK_MOBILE_REJECT,
	]),
};
