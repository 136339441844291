import React, { Component } from 'react';

import BarGraph from './BarGraph';
import ColumnWithRotated from './ColumnWithRotated';
import ColumnWithRotatedTime from './ColumnWithRotatedTime';
import CurvedColumns from './CurvedColumns';
import CurvedColumnsTime from './CurvedColumnsTime';
import LayeredColumn from './LayeredColumn';
import LayeredColumnTime from './LayeredColumnTime';
import LayeredColumnTimeDate from './LayeredColumnTimeDate';
import LineGraph from './LineGraph';
import PartitionedBar from './PartitionedBar';
import PieGraph from './PieGraph';
import PieGraphAnalitycs from './PieGraphAnalytics';
import PieGraphDashboard from './PieGraphDashboard';
import Puntuality from './Puntuality';
import PyramidGraph from './PyramidGraph';
import StackedColumn from './StackedColumn';
import StackedColumnAgent from './StackedColumnAgent';

import './GraphComponentStyles.css';

class GraphComponent extends Component {
	constructor(props) {
		super(props);
		try {
			this.initalState = {
				type: props.type,
				data: props.data,
				params: props.params,
			};
			this.state = this.initalState;
		} catch (error) {
			console.error(error);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.params !== this.state.params || this.props.data !== this.state.data) {
			const { state } = this;
			state.type = this.props.type;
			state.data = this.props.data;
			state.params = this.props.params;
			this.setState(state);
			this.prepareGraph();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.params !== this.state.params || this.props.data !== this.state.data) {
			const { state } = this;
			state.type = this.props.type;
			state.data = this.props.data;
			state.params = this.props.params;
			this.setState(state);
			this.prepareGraph();
		}
	}

	componentDidMount() {
		this.prepareGraph();
	}

	/** @function prepareGraph
	 *
	 *  depending on the graph type passed to the object via props\n
	 *  creates a graph instance (LineGraph, PieGraph, BarGraph)
	 *
	 *
	 * */
	prepareGraph() {
		let graph;
		if (this.state.type === 'line') graph = new LineGraph(this.state.data, this.props);
		else if (this.state.type === 'pie') graph = new PieGraph(this.state.data, this.props);
		else if (this.state.type === 'pieDashboard') graph = new PieGraphDashboard(this.state.data, this.props);
		else if (this.state.type === 'pieAnalytics') graph = new PieGraphAnalitycs(this.state.data, this.props);
		else if (this.state.type === 'bar') graph = new BarGraph(this.state.data);
		else if (this.state.type === 'partitionedBar') graph = new PartitionedBar(this.state.data, this.props);
		else if (this.state.type === 'curvedColumns') graph = new CurvedColumns(this.state.data, this.props);
		else if (this.state.type === 'curvedColumnsTime') graph = new CurvedColumnsTime(this.state.data, this.props);
		else if (this.state.type === 'columnWithRotated') graph = new ColumnWithRotated(this.state.data, this.props);
		else if (this.state.type === 'columnWithRotatedTime')
			graph = new ColumnWithRotatedTime(this.state.data, this.props);
		else if (this.state.type === 'stackedColumn') graph = new StackedColumn(this.state.data, this.props);
		else if (this.state.type === 'stackedColumnAgent') graph = new StackedColumnAgent(this.state.data, this.props);
		else if (this.state.type === 'layeredColumn') graph = new LayeredColumn(this.state.data, this.props);
		else if (this.state.type === 'layeredColumnTime') graph = new LayeredColumnTime(this.state.data, this.props);
		else if (this.state.type === 'layeredColumnTimeDate')
			graph = new LayeredColumnTimeDate(this.state.data, this.props);
		else if (this.state.type === 'puntualityGraph') graph = new Puntuality(this.state.data, this.props);
		else if (this.state.type === 'pyramid') graph = new PyramidGraph(this.state.data, this.props);

		graph.createGraph(this.state.params, this.state.series);
		this.chart = graph.getGraph();
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		return <div id={this.props.params ? this.props.params.id : null} style={{ width: '100%', height: '100%' }} />;
	}
}

export default GraphComponent;
