import { Gainsboro, RazzmicBerry } from '@core/theme/baseTheme';
import { TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledAutocomplete = styled(Autocomplete)`
	& input {
		border-radius: 12px;
		border-color: ${Gainsboro};
		border-color: red !important;
	}

	& .MuiInputBase-root {
		border-radius: 12px;
		border-color: ${Gainsboro};
		border-color: red !important;
	}

	& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${RazzmicBerry};
	}
`;

export const AutoCompleteInput = styled(TextField)`
	background-color: white;
	border-radius: 12px;

	& .MuiInputLabel-root {
		color: ${Gainsboro};
	}
`;
