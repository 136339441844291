/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	alert: null,
};

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setAlertMessage: (state, action) => {
			state.alert = action.payload;
		},
		setRemoveMessages: (state) => {
			// Add all the message types in here to clear
			state.alert = null;
		},
	},
});

export const { setAlertMessage, setRemoveMessages } = messageSlice.actions;

export default messageSlice.reducer;
