import ActivityActions from 'FielderUtils/task/activityActions';
import TypeUserModify from 'FielderUtils/typeUserModify/typeUserModify';

import TaskStatus from '../status/taskStatus';
import { getTaskStatus } from '../status/TaskStatusForExport';
import ExportTask from './exportTask';

const moment = require('moment-timezone');

const NOT_APPLY = 'n/a';

export const exportTaskColumns = (lan) => {
	const excelColumns = [
		{
			name: lan.idFielder,
			value: ExportTask.ID_FIELDER,
			key: 'idFielder',
		},
		{
			name: lan.folio,
			value: ExportTask.FOLIO,
			key: 'folio',
		},
		{
			name: lan.creationDateExcel,
			value: ExportTask.CREATE_DATE,
			key: 'creationDate',
		},
		{
			name: lan.creationHourExcel,
			value: ExportTask.CREATE_HOUR,
			key: 'creationHour',
		},
		{
			name: lan.programDate,
			value: ExportTask.STARTED_DATE,
			key: 'startDate',
		},
		{
			name: lan.programHour,
			value: ExportTask.STARTED_HOUR,
			key: 'startHour',
		},
		{
			name: lan.onWayDateExcel,
			value: ExportTask.ON_WAY_DATE,
			key: 'onWayDate',
		},
		{
			name: lan.onWayHourExcel,
			value: ExportTask.ON_WAY_HOUR,
			key: 'onWayHour',
		},
		{
			name: lan.startDateExcel,
			value: ExportTask.EXECUTION_DATE,
			key: 'executionDate',
		},
		{
			name: lan.startHourExcel,
			value: ExportTask.EXECUTION_HOUR,
			key: 'executionHour',
		},
		{
			name: lan.arriveFormattedDate,
			value: ExportTask.ARRIVAL_DATE,
			key: 'arrivalDate',
		},
		{
			name: lan.arriveFormattedHour,
			value: ExportTask.ARRIVAL_HOUR,
			key: 'arrivalHour',
		},
		{
			name: lan.endDateExcel,
			value: ExportTask.END_AGENT,
			key: 'endDate',
		},
		{
			name: lan.endHourExcel,
			value: ExportTask.END_HOUR,
			key: 'endHour',
		},
		{
			name: lan.agent,
			value: ExportTask.AGENT,
			key: 'agent',
		},
		{
			name: lan.team,
			value: ExportTask.TEAM,
			key: 'team',
		},
		{
			name: lan.manager,
			value: ExportTask.MANAGER,
			key: 'manager',
		},
		{
			name: lan.branch,
			value: ExportTask.BRANCH,
			key: 'branch',
		},
		{
			name: lan.company,
			value: ExportTask.COMPANY,
			key: 'company',
		},
		{
			name: lan.client,
			value: ExportTask.CLIENT,
			key: 'client',
		},
		{
			name: lan.status,
			value: ExportTask.STATUS,
			key: 'status',
		},
		{
			name: lan.priority,
			value: ExportTask.SEVERITY,
			key: 'priority',
		},
		{
			name: lan.taskType,
			value: ExportTask.TASK_TYPE,
			key: 'taskType',
		},
		{
			name: lan.description,
			value: ExportTask.DESCRIPTION,
			key: 'description',
		},
		{
			name: lan.addressExcel,
			value: ExportTask.ADRESS,
			key: 'address',
		},
		{
			name: lan.commentsAgent,
			value: ExportTask.COMMENTS_AGENT,
			key: 'agentCommentCount',
		},
		{
			name: lan.commentsUser,
			value: ExportTask.COMMENTS_USER,
			key: 'customerComment',
		},
		{
			name: lan.commentUserDate,
			value: ExportTask.COMMENTS_USER_DATE,
			key: 'customerCommentDate',
		},
		{
			name: lan.score,
			value: ExportTask.SCORE,
			key: 'rate',
		},
		{
			name: lan.rescheduledDate,
			value: ExportTask.RESCHEDULE_DATE,
			key: 'rescheduled',
		},
		{
			name: lan.rescheduledHour,
			value: ExportTask.RESCHEDULE_HOUR,
			key: 'rescheduledHour',
		},
		{
			name: lan.rescheduledComment,
			value: ExportTask.RESCHEDULE_COMMENT,
			key: 'rescheduledComment',
		},
		{
			name: lan.rescheduledCommentDate,
			value: ExportTask.RESCHEDULE_COMMENT_DATE,
			key: 'rescheduleDateComment',
		},
		{
			name: lan.cancelReason,
			value: ExportTask.CANCEL_REASON,
			key: 'canceledReason',
		},
		{
			name: lan.cancelBy,
			value: ExportTask.CANCEL_BY,
			key: 'canceledBy',
		},
		{
			name: lan.cancelComment,
			value: ExportTask.CANCEL_COMMENT,
			key: 'canceledComment',
		},
		{
			name: lan.timeZone,
			value: ExportTask.TIME_ZONE,
			key: 'timeZone',
		},
		{
			name: lan.kmsDistance,
			value: ExportTask.KMS_DISTANCE,
			key: 'estimatedKms',
		},
		{
			name: lan.executionMode,
			value: ExportTask.EXECUTION_MODE,
			key: 'executionMode',
		},
		{
			name: lan.idCustomGroupTask,
			value: ExportTask.ID_CUSTOM_GROUP_TASK,
			key: 'idGroupExternal',
		},
		{
			name: lan.group,
			value: ExportTask.GROUP_TASK,
			key: 'groupName',
		},
		{
			name: lan.tags,
			value: ExportTask.TAGS,
			key: 'tags',
		},
		{
			name: lan.agentStartLocationExcel,
			value: ExportTask.AGENT_START_LOCATION,
			key: 'agentStartLocation',
		},
		{
			name: lan.agentEndLocationExcel,
			value: ExportTask.AGEMT_END_LOCATION,
			key: 'agentEndLocation',
		},
		{
			name: lan.routeNumber,
			value: ExportTask.ID_ROUTE,
			key: 'idRoute',
		},
		{
			name: lan.routeDistanceTraveled,
			value: ExportTask.ROUTE_KMS,
			key: 'metersRoute',
		},
		{
			name: lan.routeTimeTraveled,
			value: ExportTask.ROUTE_DURATION,
			key: 'timeRoute',
		},
		{
			name: lan.countPause,
			value: ExportTask.PAUSE_COUNT,
			key: 'pauseCount',
		},
		{
			name: lan.countResume,
			value: ExportTask.RESUME_COUNT,
			key: 'resumeCount',
		},
		{
			name: lan.taskPauseDependency,
			value: ExportTask.PAUSE_DEPENDENCY,
			key: 'pauseDependency',
		},
		{
			name: lan.taskPausedReason,
			value: ExportTask.PAUSE_REASON,
			key: 'pauseReason',
		},
		{
			name: lan.taskPauseComment,
			value: ExportTask.PAUSE_COMMENT,
			key: 'pauseComment',
		},
		{
			name: lan.taskRejectionDate,
			value: ExportTask.REJECT_DATE,
			key: 'rejectDate',
		},
		{
			name: lan.taskRejectTime,
			value: ExportTask.REJECT_TIME,
			key: 'rejectTime',
		},
		{
			name: lan.taskRejectDependency,
			value: ExportTask.REJECT_DEPENDENCY,
			key: 'rejectDependency',
		},
		{
			name: lan.taskRejectionReason,
			value: ExportTask.REJECT_REASON,
			key: 'rejectReason',
		},
		{
			name: lan.taskRejectionComment,
			value: ExportTask.REJECT_COMMENT,
			key: 'rejectComment',
		},
		{
			name: lan.partner,
			value: ExportTask.PARTNER,
			key: 'partner',
		},
		{
			name: lan.taskStatusModifiedDate,
			value: ExportTask.TASK_STATUS_MODIFIED_DATE,
			key: 'dateTaskStatusModify',
		},
		{
			name: lan.taskStatusModifiedHour,
			value: ExportTask.TASK_STATUS_MODIFIED_HOUR,
			key: 'hourTaskStatusModify',
		},

		{
			name: lan.taskStatusModifiedCommnet,
			value: ExportTask.TASK_STATUS_MODIFIED_COMMENT,
			key: 'commetTaskStatusModify',
		},

		{
			name: lan.taskStatusModifiedByAdministrator,
			value: ExportTask.TASK_STATUS_MODIFIED_BY_ADMINISTRATOR,
			key: 'adminTaskStatusModify',
		},
		{
			name: lan.taskStatusModifiedByManager,
			value: ExportTask.TASK_STATUS_MODIFIED_BY_MANAGER,
			key: 'managerTaskStatusModify',
		},
	];

	return excelColumns;
};

const getCompany = (task) => {
	if (task.branch && task.branch.company) {
		return task.branch.company.name;
	}
	if (task.user && task.user.company) {
		return task.user.company;
	}
	return NOT_APPLY;
};

const getTime = (d, lan) => {
	try {
		d = Number(d);
		const h = Math.floor(d / 3600);
		const m = Math.floor((d % 3600) / 60);
		const s = Math.floor((d % 3600) % 60);

		const hDisplay = h > 0 ? h + (h == 1 ? ` ${lan.hour}, ` : ` ${lan.hours} `) : '';
		const mDisplay = m > 0 ? m + (m == 1 ? ` ${lan.minute}, ` : ` ${lan.minutes} `) : '';
		const sDisplay = s > 0 ? s + (s == 1 ? ` ${lan.second}` : ` ${lan.seconds} `) : '';
		return hDisplay + mDisplay + sDisplay;
	} catch (e) {}
};

const getKm = (value) => {
	const km = parseFloat(value) / 1000;
	const result = `${km} km`;

	return result;
};

const getDistanceTraveled = (task) => {
	if (task.distanceTraveled) {
		const distance = parseInt(task.distanceTraveled) / 1000;
		return `${distance}`;
	}
	return NOT_APPLY;
};


const getDetaultNameOrNormal = (lan, id, name) => {
	if (id == 1 || id == 2 || id == 3) {
		return lan['severity_' + id];
	} else {
		return name;
	}
}

export const buildTasksToExcel = (tasks, session, lan) => {
	try {
		const excelTasks = [];
		let tz = '';
		const ft = 'DD-MM-YYYY hh:mm a';

		tasks.forEach((task) => {
			let cancelCommentDate = '';
			let taskTypeName = '';
			const agentCommentDate = '';
			let canceledBy = NOT_APPLY;
			let canceledReason = NOT_APPLY;
			let canceledComment = NOT_APPLY;
			let dateRatingComment = NOT_APPLY;
			let dateCommentReschedule = NOT_APPLY;

			tz = task.startLocation ? task.startLocation.timeZone : 'America/Mexico_City';

			/** *************  STATUS VALIDATION ************** */
			if (
				task.status &&
				(task.status == TaskStatus.ASSIGNED_TO_PARTNER ||
					task.status == TaskStatus.WAITING_FOR_AGENT ||
					task.status == TaskStatus.ASSIGNED_TO_TEAM) &&
				session &&
				session.user &&
				session.user.role == 'Fielder Client User'
			) {
				task.status = TaskStatus.IN_PROGRESS;
			}

			if (
				task.status &&
				task.status == TaskStatus.MANAGING &&
				task.agentCancelled &&
				session.user.role == 'Fielder Client User'
			) {
				task.status = TaskStatus.IN_PROGRESS;
			}

			if (
				task.status &&
				task.status == TaskStatus.CANCEL &&
				task.cancelByAnyAgent &&
				session &&
				session.user &&
				session.user.role == 'Fielder Client User'
			) {
				task.status = TaskStatus.IN_PROGRESS;
			}

			if (
				task.status &&
				(task.status == TaskStatus.CANCEL_BY_PARTNER || task.status == TaskStatus.CANCEL) &&
				session.user.role != 'Fielder Client User' &&
				!task.showCancelStatus &&
				!task.cancelByAdmin
			) {
				task.status = TaskStatus.IN_PROGRESS;
			}

			let partnerName = NOT_APPLY;
			if (task.partnerName != null) {
				partnerName = task.partnerName == "UN_NAME" ? lan.unnamed : task.partnerName;
			}


			if (task.partnerTaskRelations && task.partnerTaskRelations.length > 0) {
				const { partnerTaskRelations } = task;
				const userLoggedIdAccount = session.user.idAccount;

				const lastPartner = partnerTaskRelations[partnerTaskRelations.length - 1];
				const lastPartnerId = lastPartner.idAccountPartner;

				if (userLoggedIdAccount == lastPartnerId) {
					partnerName = `${lan.assignedBy} ${lastPartner.accountOwner.name}`;
				} else {
					partnerName = `${lan.assignedTo} ${lastPartner.accountPartner.name ? lastPartner.accountPartner.name : lan.unnamed}`;
				}
				if (!partnerName) {
					partnerName = lan.unnamed;
				}

				if (session.user.role == 'Fielder Client User') {
					partnerName = lan.partner;
				}

				if (
					lastPartnerId != userLoggedIdAccount &&
					task.idAccount != userLoggedIdAccount &&
					task.status == TaskStatus.ASSIGNED_TO_TEAM
				) {
					task.status = TaskStatus.IN_PROGRESS;
				}

				if (
					(task.idAccount == userLoggedIdAccount || lastPartnerId != userLoggedIdAccount) &&
					task.status == TaskStatus.CANCEL
				) {
					task.status = TaskStatus.CANCEL_BY_PARTNER;
				}

				if (
					task.status &&
					task.status == TaskStatus.ASSIGNED_TO_PARTNER &&
					session.user.role != 'Fielder Client User' &&
					task.agentCancelled &&
					lastPartnerId != userLoggedIdAccount
				) {
					task.agentCancelled = false;
					task.status = TaskStatus.IN_PROGRESS;
				}

				if (
					task.status &&
					task.status == TaskStatus.ASSIGNED_TO_PARTNER &&
					session.user.role != 'Fielder Client User' &&
					task.agentCancelled &&
					lastPartnerId == userLoggedIdAccount
				) {
					task.status = TaskStatus.MANAGING;
				}
			}

			/** *************  STATUS VALIDATION ************** */

			const statusString = getTaskStatus(task.status, lan);

			if (
				task.serviceTypeId !== undefined &&
				task.serviceTypeId !== null &&
				task.taskTypeInfo !== undefined &&
				task.taskTypeInfo !== null
			) {
				taskTypeName = task.taskTypeInfo;
			}

			if (task.taskCancelComments) {
				const { taskCancelComments } = task;

				taskCancelComments.forEach((cancelComment) => {
					if (cancelComment.cancelAgent != null || cancelComment.cancelUser != null) {
						if (cancelComment.reason != null) {
							if (canceledReason != NOT_APPLY) {
								canceledReason = ` - ${canceledReason}`;
							} else {
								canceledReason = '';
							}
							canceledReason = cancelComment.reason + canceledReason;
						}

						if (cancelComment.comment != null) {
							if (canceledComment != NOT_APPLY) {
								canceledComment = ` - ${canceledComment}`;
							} else {
								canceledComment = '';
							}

							const toUTC = new Date(`${cancelComment.timeStamp}Z`);
							cancelCommentDate = moment.tz(toUTC, tz).format(ft);
							canceledComment = `${cancelComment.comment + canceledComment} ${cancelCommentDate}`;
						}

						if (cancelComment.cancelAgent) {
							canceledBy = `${cancelComment.cancelAgent.name} ${cancelComment.cancelAgent.lastName}`;
						} else {
							canceledBy = `${cancelComment.cancelUser.name} ${cancelComment.cancelUser.lastName}`;
						}
					}
				});
			}

			const fd = 'DD-MM-YYYY';
			const fh = 'hh:mm a';

			let startDateFormated = null;
			let startHourFormated = null;
			if (task.startTime !== undefined && task.startTime != null) {
				startDateFormated = moment.tz(task.startTime, tz).format(fd);
				startHourFormated = moment.tz(task.startTime, tz).format(fh);
			}

			let endDateFormated = null;
			let endHourFormated = null;
			if (task.endAgent !== undefined && task.endAgent != null) {
				endDateFormated = moment.tz(task.endAgent, tz).format(fd);
				endHourFormated = moment.tz(task.endAgent, tz).format(fh);
			}

			let executionDateFormated = null;
			let executionHourFormated = null;
			if (task.startAgent !== undefined && task.startAgent != null) {
				executionDateFormated = moment.tz(task.startAgent, tz).format(fd);
				executionHourFormated = moment.tz(task.startAgent, tz).format(fh);
			}

			let agentFullName = NOT_APPLY;
			if (task.agent !== undefined && task.agent != null) {
				const agentLastName = task.agent.lastName ? task.agent.lastName : '';
				agentFullName = `${task.agent.name} ${agentLastName}`;
			}

			let teamName = NOT_APPLY;
			if (task.team !== undefined && task.team != null) {
				teamName = task.team.name;
			}

			let teamManagerName = NOT_APPLY;
			if (task.team !== undefined && task.team != null && task.team.users != null) {
				const users = task.team.users;
				teamManagerName = '';
				for (let i = 0; i < users.length; i++) {
					const lastName = users[i].lastName ? users[i].lastName : '';
					const name = users[i].name ? users[i].name : '';
					const idManager = users[i].idClientUser ? users[i].idClientUser : '';
					teamManagerName += `${idManager} ${name} ${lastName} ${','}`;
				}
			}

			let arrivalDateFormated = null;
			let arrivalHourFormated = null;
			if (task.startRoute !== undefined && task.startRoute != null && task.startRoute.dateArrive != null) {
				arrivalDateFormated = moment.tz(task.startRoute.dateArrive, tz).format(fd);
				arrivalHourFormated = moment.tz(task.startRoute.dateArrive, tz).format(fh);
			}

			let creationDateFormated = null;
			let creationHourFormated = null;
			if (task.createTime !== undefined && task.createTime != null) {
				creationDateFormated = moment.tz(task.createTime, tz).format(fd);
				creationHourFormated = moment.tz(task.createTime, tz).format(fh);
			}

			let onWayDateFormated = null;
			let onWayHourFormated = null;
			if (task.startRoute !== undefined && task.startRoute != null && task.startRoute.dateStart != null) {
				onWayDateFormated = moment.tz(task.startRoute.dateStart, tz).format(fd);
				onWayHourFormated = moment.tz(task.startRoute.dateStart, tz).format(fh);
			}

			let rescheduledDateFormated = null;
			let rescheduledHourFormated = null;
			if (task.rescheduled !== undefined && task.rescheduled != null) {
				rescheduledDateFormated = moment.tz(task.rescheduled, tz).format(fd);
				rescheduledHourFormated = moment.tz(task.rescheduled, tz).format(fh);
			}

			if (task.rating && task.rating.dateRating) {
				dateRatingComment = moment.tz(task.rating.dateRating, tz).format(ft);
			}

			if (task.dateOfReschedule) {
				dateCommentReschedule = moment.tz(task.dateOfReschedule, tz).format(ft);
			}

			/* const pauseHour = '';
			const pauseDate = '';
			const pauseResumeHour = '';
			const pauseResumeDate = '';
			if (task.taskActivities !== undefined && task.taskActivities != null && task.taskActivities.length > 0) {
				task.taskActivities.forEach((activity) => {
					if (activity.typeAction == ActivityActions.DO_ACTION_PAUSED_TASK) {
						pauseHour = `${pauseHour} ${moment.tz(activity.createdTime, tz).format(fh)},`;
						pauseDate = `${pauseDate} ${moment.tz(activity.createdTime, tz).format(fd)},`;
					}
					if (activity.typeAction == ActivityActions.DO_START_TASK_AGENT) {
						pauseResumeHour = `${pauseResumeHour} ${moment.tz(activity.createdTime, tz).format(fh)},`;
						pauseResumeDate = `${pauseResumeDate} ${moment.tz(activity.createdTime, tz).format(fd)},`;
					}
				});
			} else {
				pauseHour = NOT_APPLY;
				pauseDate = NOT_APPLY;
				pauseResumeHour = NOT_APPLY;
				pauseResumeDate = NOT_APPLY;
			} */

			let commetRejectTask = '';
			let rejectReasonName = '';
			let rejectReasonParent = '';
			let rejectReasonDate = '';
			let rejectReasonTime = '';

			if (
				task.rejectTaskComments !== undefined &&
				task.rejectTaskComments != null &&
				task.rejectTaskComments.length > 0
			) {
				task.rejectTaskComments.forEach((commentReject) => {
					if (commentReject.comment) {
						commetRejectTask = `${commetRejectTask + commentReject.comment} / `;
					}

					if (commentReject.dateComment) {
						const toUTCReject = new Date(`${commentReject.dateComment}Z`);
						rejectReasonDate = `${rejectReasonDate} ${moment.tz(toUTCReject, tz).format(fh)},`;
						rejectReasonTime = `${rejectReasonTime} ${moment.tz(toUTCReject, tz).format(fd)},`;
					}

					if (commentReject.rejectTaskReason) {
						rejectReasonName = `${rejectReasonName + commentReject.rejectTaskReason.reasonName} / `;
						rejectReasonParent = `${
							rejectReasonParent +
							(commentReject.rejectTaskReason.parentReason == 0 ? lan.pausedByAgent : lan.pausedByFinalClient)
						} / `;
					}
				});
			}
			let commetPauseTask = '';
			let pauseReasonName = '';
			let pauseReasonParent = '';
			if (task.pauseTaskComments !== undefined && task.pauseTaskComments != null) {
				const commentPause = task.pauseTaskComments;
				if (commentPause.comment) {
					commetPauseTask = commentPause.comment;
				}
				if (commentPause.pauseTaskReason) {
					pauseReasonName = commentPause.pauseTaskReason.reasonName;
					pauseReasonParent =
						commentPause.pauseTaskReason.parentReason == 0 ? lan.pausedByAgent : lan.pausedByFinalClient;
				}
			}

			let commetTaskStatusModify = '';
			let adminTaskStatusModify = '';
			let managerTaskStatusModify = '';
			let dateTaskStatusModify = '';
			let hourTaskStatusModify = '';

			if (
				task.modifyTaskStatusComment !== undefined &&
				task.modifyTaskStatusComment != null &&
				task.modifyTaskStatusComment.length > 0
			) {
				task.modifyTaskStatusComment.forEach((taskStatusModify) => {
					if (taskStatusModify.comment) {
						commetTaskStatusModify = `${commetTaskStatusModify + taskStatusModify.comment} / `;
					}

					if (taskStatusModify.typeUser == TypeUserModify.ADMIN) {
						adminTaskStatusModify = `${adminTaskStatusModify + taskStatusModify.user.name} ${
							taskStatusModify.user.lastName
						}`;
					} else {
						managerTaskStatusModify = `${managerTaskStatusModify + taskStatusModify.user.name} ${
							taskStatusModify.user.lastName
						}`;
					}

					if (taskStatusModify.dateComment) {
						dateTaskStatusModify = `${dateTaskStatusModify} ${moment.tz(taskStatusModify.dateComment, tz).format(fh)},`;
						hourTaskStatusModify = `${hourTaskStatusModify} ${moment.tz(taskStatusModify.dateComment, tz).format(fd)},`;
					}
				});
			}

			let tags = NOT_APPLY;
			if (task.tags && task.tags.length > 0) {
				tags = task.tags.toString();
			}

			excelTasks.push({
				idFielder: task.idTask,
				folio: task.folio ? task.folio : NOT_APPLY,
				creationDate: creationDateFormated || NOT_APPLY,
				creationHour: creationHourFormated || NOT_APPLY,
				executionDate: executionDateFormated || NOT_APPLY,
				executionHour: executionHourFormated || NOT_APPLY,
				onWayDate: onWayDateFormated || NOT_APPLY,
				onWayHour: onWayHourFormated || NOT_APPLY,
				arrivalDate: arrivalDateFormated || NOT_APPLY,
				arrivalHour: arrivalHourFormated || NOT_APPLY,
				startDate: startDateFormated || NOT_APPLY,
				startHour: startHourFormated || NOT_APPLY,
				endDate: endDateFormated || NOT_APPLY,
				endHour: endHourFormated || NOT_APPLY,
				agent: agentFullName,
				team: teamName,
				manager: teamManagerName,
				company: getCompany(task),
				client: task.user ? task.user.name : NOT_APPLY,
				branch: task.branch ? task.branch.name : NOT_APPLY,
				status: statusString,
				priority: task.severity ? getDetaultNameOrNormal(lan, task.severity, task.severityName) : NOT_APPLY,
				taskType: taskTypeName,
				description: task.description ? task.description : NOT_APPLY,
				address: task.startLocation ? task.startLocation.address : NOT_APPLY,
				agentCommentCount: task.agentCommentCount > 0 ? task.agentCommentCount : NOT_APPLY,
				customerComment: task.rating ? task.rating.comment : NOT_APPLY,
				customerCommentDate: dateRatingComment,
				rate: task.rating ? task.rating.rating : NOT_APPLY,
				rescheduled: rescheduledDateFormated || NOT_APPLY,
				rescheduledHour: rescheduledHourFormated || NOT_APPLY,
				rescheduledComment: task.rescheduledDescription ? task.rescheduledDescription : NOT_APPLY,
				rescheduleDateComment: dateCommentReschedule,
				canceledReason,
				canceledBy,
				canceledComment,
				timeZone: task.startLocation ? task.startLocation.timeZone : NOT_APPLY,
				estimatedKms: getDistanceTraveled(task),
				executionMode: task.offlineExecution ? lan.yes : lan.no,
				idGroupExternal: task.group ? task.group.idCustomGroup : NOT_APPLY,
				groupName: task.group ? task.group.name : NOT_APPLY,
				tags,
				agentStartLocation: task.agentStartLocation ? task.agentStartLocation.address : NOT_APPLY,
				agentEndLocation: task.agentEndLocation ? task.agentEndLocation.address : NOT_APPLY,
				idRoute: task.idRoute ? task.idRoute : NOT_APPLY,
				metersRoute: task.metersRoute ? getKm(task.metersRoute) : NOT_APPLY,
				timeRoute: task.timeRoute ? getTime(task.timeRoute, lan) : NOT_APPLY,
				pauseCount: task.pauseCount > 0 ? task.pauseCount : NOT_APPLY,
				resumeCount: task.resumeCount > 0 ? task.resumeCount : NOT_APPLY,

				pauseDependency: pauseReasonParent != '' ? pauseReasonParent : NOT_APPLY,
				pauseReason: pauseReasonName != '' ? pauseReasonName : NOT_APPLY,
				pauseComment: commetPauseTask != '' ? commetPauseTask : NOT_APPLY,
				rejectDate: rejectReasonDate != '' ? rejectReasonDate : NOT_APPLY,
				rejectTime: rejectReasonTime != '' ? rejectReasonTime : NOT_APPLY,
				rejectDependency: rejectReasonParent != '' ? rejectReasonParent : NOT_APPLY,
				rejectReason: rejectReasonName != '' ? rejectReasonName : NOT_APPLY,
				rejectComment: commetRejectTask != '' ? commetRejectTask : NOT_APPLY,
				commetTaskStatusModify: commetTaskStatusModify != '' ? commetTaskStatusModify : NOT_APPLY,
				adminTaskStatusModify: adminTaskStatusModify != '' ? adminTaskStatusModify : NOT_APPLY,
				managerTaskStatusModify: managerTaskStatusModify != '' ? managerTaskStatusModify : NOT_APPLY,
				dateTaskStatusModify: dateTaskStatusModify != '' ? dateTaskStatusModify : NOT_APPLY,
				hourTaskStatusModify: hourTaskStatusModify != '' ? hourTaskStatusModify : NOT_APPLY,
				partner: partnerName,
			});
			// }
		});

		return excelTasks;
	} catch (error) {
		console.error(error);
	}
};
