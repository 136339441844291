import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

class LineGraph extends Graph{
    constructor(data,props){
		super(props);
        this.data = data;
	}

    createGraph(params){
        var chart = am4core.create(params.id, am4charts.XYChart);
		var category = params.category;
		var seriesParams = params.series;

        chart.data = this.data;

		// Create axes
		super.setAxes(chart, category);

		seriesParams.forEach(s => {
			super.setLineSeriesParams(s, chart, category);
		});

        var legend = params.legend;

		super.setChartProperties(chart, legend, true,false);

        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default LineGraph