export const REQUEST_TYPES = {
	GET_ALL_AGENTS: 'GET_ALL_AGENTS_OPTIMIZE',
	GET_AGENTS_LIST_BY_SKILL: 'GET_AGENTS_LIST_BY_SKILL',
	GET_AGENT_FOR_SELECT: 'GET_AGENT_FOR_SELECT',
	CREATE_AGENT: 'CREATE_AGENT',
	UPDATE_AGENT: 'UPDATE_AGENT',
	DISABLE_AGENT: 'DISABLE_AGENT',
	ENABLE_AGENT: 'ENABLE_AGENT',
	GET_AGENT_LAST_LOCATION: 'GET_AGENT_LAST_LOCATION',
	GET_AGENT_START_LOCATION: 'GET_AGENT_START_LOCATION',
	GET_AGENT_LOCATIONS: 'GET_AGENT_LOCATIONS',
	GET_MY_OPERATION_AGENTS: 'GET_AGENT_FOR_MY_OPERATION',
	GET_AGENT_BY_ID: 'GET_AGENT_BY_ID',
	GET_AGENT_BY_ID_OPTIMIZED: 'GET_AGENT_BY_ID_OPTIMIZE',
	SEND_PASSWORD_TO_AGENT: 'SEND_AGENT_PASSWORD',
	GET_AGENT_TASK_ROUTE: 'GET_VIEW_AGENT_ROUTE_TASK',
	HIDE_TASK_TYPE_TO_AGENT: 'DO_HIDE_TASK_TYPE_TO_AGENT',
	SHOW_TASK_TYPE_TO_AGENT: 'DO_SHOW_TASK_TYPE_TO_AGENT',
	SEND_CONGRATULATION: 'SEND_CONGRATULATION',
};
