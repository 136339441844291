import React from 'react';

import CommentsIcon from '@assets/icons/comment-icon.svg';
import RecurrentIcon from '@assets/icons/cycle-icon.svg';
import GroupTaskIcon from '@assets/icons/group-task-table-icon.svg';
import ImageIcon from '@assets/icons/images-icon.svg';
import PartnerIcon from '@assets/icons/partners-icon.svg';
import RoutesIcon from '@assets/icons/route-icon.svg';
import VolumeIcon from '@assets/icons/volume-icon.svg';
import { Image } from 'react-bootstrap';

const ROUTE_ICON = 1;
const VOLUMEN_TASK_ICON = 2;
const RECURRENT_TASK_ICON = 3;
const GROUP_TASK_ICON = 4;

class IconFormatter extends React.Component {
	render() {
		const type = this.props.value;
		let icon = null;
		let iconPartner = null;
		let iconComment = null;
		let iconImage = null;

		if (type.genericIcon === ROUTE_ICON) {
			icon = RoutesIcon;
		} else if (type.genericIcon === GROUP_TASK_ICON) {
			icon = GroupTaskIcon;
		} else if (type.genericIcon === VOLUMEN_TASK_ICON) {
			icon = VolumeIcon;
		} else if (type.genericIcon === RECURRENT_TASK_ICON) {
			icon = RecurrentIcon;
		}

		if (type.partnerTaskRelations == true) {
			iconPartner = PartnerIcon;
		}
		if (type.comments == true) {
			iconComment = CommentsIcon;
		}
		if (type.images == true) {
			iconImage = ImageIcon;
		}

		return (
			<div className='row no-padding'>
				{icon !== null && (
					<div className='pl-1 pr-1'>
						<Image src={icon} alt='' className='SmartTable-more-icon-info' />
					</div>
				)}

				{iconPartner !== null && (
					<div className='pl-1 pr-1'>
						<Image src={PartnerIcon} alt='' className='SmartTable-more-icon-info' />
					</div>
				)}
				{iconComment !== null && (
					<div className='pl-1 pr-1'>
						<Image src={CommentsIcon} alt='' className='SmartTable-more-icon-info' />
					</div>
				)}
				{iconImage !== null && (
					<div className='pl-1 pr-1'>
						<Image src={ImageIcon} alt='' className='SmartTable-more-icon-info' />
					</div>
				)}
			</div>
		);
	}
}

export default IconFormatter;
