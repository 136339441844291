import React, { useEffect, useState } from 'react';

import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { StatusContainer } from '../Dashboard.styles';
import { green, pink, purple, grey } from '@core/theme/pastel.colors';
import SickIcon from '@mui/icons-material/Sick';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import TaskIcon from '@mui/icons-material/Task';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MedicationIcon from '@mui/icons-material/Medication';
import { AGENT_STATUS, OFF_DUTY_CAUSES } from '@core/constants/agent/agentStatus.constants';

const getInactiveReason = (status) => {
	let inactiveReason = {
		text: 'inactive',
		icon: null,
	};

	if (status.offDutyCause === OFF_DUTY_CAUSES.sickness) {
		inactiveReason.icon = <SickIcon className='mr-1' />;
		inactiveReason.text = 'offCause_1';
	} else if (status.offDutyCause === OFF_DUTY_CAUSES.vacation) {
		inactiveReason.icon = <FlightTakeoffIcon className='mr-1' />;
		inactiveReason.text = 'offCause_2';
	} else if (status.offDutyCause === OFF_DUTY_CAUSES.permission) {
		inactiveReason.icon = <TaskIcon className='mr-1' />;
		inactiveReason.text = 'offCause_3';
	} else if (status.offDutyCause === OFF_DUTY_CAUSES.disability) {
		inactiveReason.icon = <MedicationIcon className='mr-1' />;
		inactiveReason.text = 'offCause_4';
	} else if (status.offDutyCause === OFF_DUTY_CAUSES.endOfWorkDay) {
		inactiveReason.icon = <WorkHistoryIcon className='mr-1' />;
		inactiveReason.text = 'offCause_5';
	}

	return inactiveReason;
};

const AgentStatus = ({ status }) => {
	const lan = useLanguage();

	const [statusConfig, setStatusConfig] = useState({
		text: '',
		color: '',
		background: '',
		icon: null,
	});

	const getStatus = () => {
		let text = '';
		let color = '';
		let background = '';
		let icon = null;

		if (status.agentStatus === AGENT_STATUS.noData) {
			text = lan.noData;
			background = grey.background;
			color = grey.text;
		} else if (status.agentStatus === AGENT_STATUS.inactive) {
			background = purple.background;
			color = purple.text;
			const inactiveReason = getInactiveReason(status);
			text = lan[inactiveReason.text];
			icon = inactiveReason.icon;
		} else if (status.agentStatus === AGENT_STATUS.online) {
			text = lan.onlineDashboard;
			background = green.background;
			color = green.text;
		} else {
			text = lan.offlineDashboard;
			background = pink.background;
			color = pink.text;
		}

		return { text, color, background, icon };
	};

	useEffect(() => {
		setStatusConfig(getStatus());
	}, [status]);

	return (
		<StatusContainer background={statusConfig.background} color={statusConfig.color}>
			{statusConfig.icon}
			{statusConfig.text}
		</StatusContainer>
	);
};

export default React.memo(AgentStatus);
