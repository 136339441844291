import React, { useState, useEffect } from 'react';

import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import FilterIcon from '@assets/icons/filter-icon.svg';
import { FIELDER } from '@core/constants/base.constants';
import { Badge as MuiBadge, Menu, MenuItem, ListItemIcon, Checkbox } from '@mui/material';
import DatesFilterUtil from './DatesFilterUtil';;
import { styled } from '@mui/material/styles';
import './DropdownDatesFilter.css';
import { FielderPink } from '@core/theme/baseTheme';

const Badge = styled(MuiBadge)`
	& .MuiBadge-badge {
		background-color: ${FielderPink};
		color: white;
	}
`

const { btn: BTN_ID } = FIELDER.idElement;

const DropdownDatesFilter = (props) => {
	const lan = useLanguage();

	const [values, setValues] = useState([DatesFilterUtil.EXECUTION_PERIOD_TIME, DatesFilterUtil.EXECUTION_DATE]);
	const [anchorEl, setAnchorEl] = useState(null);

	const [filterTimeExecution, setFilterTimeExecution] = useState(true);
	const [filterExecutionDate, setFilterExecutionDate] = useState(true);
	const [filterCreationDate, setFilterCreationDate] = useState(false);

	useEffect(() => props.onChangeValues(values), [values]);

	useEffect(() => {
		const val = [];
		if (filterTimeExecution) val.push(DatesFilterUtil.EXECUTION_PERIOD_TIME);
		if (filterExecutionDate) val.push(DatesFilterUtil.EXECUTION_DATE);
		if (filterCreationDate) val.push(DatesFilterUtil.CREATION_DATE);
		setValues(val);
	}, [filterTimeExecution, filterExecutionDate, filterCreationDate]);

	const handleClose = () => setAnchorEl(null);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleFilter = () => {
		props.executeFilters();
		handleClose();
	};

	const handleChangeTimeExecution = (e) => {
		e.stopPropagation();
		setFilterTimeExecution(!filterTimeExecution);
	};

	const handleChangeExecutionDate = (e) => {
		e.stopPropagation();
		setFilterExecutionDate(!filterExecutionDate);
	};

	const handleChangeCreationDate = (e) => {
		e.stopPropagation();
		setFilterCreationDate(!filterCreationDate);
	};

	return (
		<div style={props.style}>
			<Badge badgeContent={values.length}>
				<button
					onClick={handleClick}
					className='fielder-clear-button'
					style={{ display: 'inline-block' }}
					disabled={props.disabled}
				>
					<img src={FilterIcon} style={{ marginRight: '7px', width: '18px', height: '18px' }} />
					<span style={{ marginLeft: '7px' }}>{lan.filterBy}</span>
				</button>
			</Badge>

			<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} sx={{ p: 3 }}>
				<MenuItem onClick={handleChangeTimeExecution}>
					<ListItemIcon>{ <Checkbox checked={filterTimeExecution }  sx={{
          "&.Mui-checked": {
			  color: "#ed1039 !important",
          }
        }}/>}</ListItemIcon>
					{lan.timeExecution}
				</MenuItem>
				<MenuItem onClick={handleChangeExecutionDate}>
					<ListItemIcon>{ <Checkbox checked={filterExecutionDate }  sx={{
          "&.Mui-checked": {
			  color: "#ed1039 !important",
          }
        }}/>}</ListItemIcon>
					{lan.executionDate}
				</MenuItem>
				<MenuItem onClick={handleChangeCreationDate}>
					<ListItemIcon>{ <Checkbox checked={filterCreationDate }  sx={{
          "&.Mui-checked": {
			  color: "#ed1039 !important",
          }
        }} />}</ListItemIcon>
					{lan.creationDate}
				</MenuItem>

				{props.showApplyButton && (
					<div className='row d-flex jsutify-content-end mx-1'>

					<button
						id={BTN_ID.filterApply}
						type='submit'
						className='btn btn-outline-primary ml-auto mr-3'
						onClick={handleFilter}
						>
						{lan.apply}
					</button>
						</div>
				)}
			</Menu>
		</div>
	);
};

export default DropdownDatesFilter;
