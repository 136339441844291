export const administratorHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idUser' },
	{ label: lan.userName, key: 'userName' },
	{ label: lan.email, key: 'email' },
	{ label: lan.name, key: 'name' },
	{ label: lan.lastName, key: 'lastName' },
	{ label: lan.idCountryCode, key: 'idCountryCode' },
	{ label: lan.mobileNumber, key: 'mobileNumber' },
	{ label: lan.officePhone, key: 'phoneNumber' },
	{ label: lan.jobPosition, key: 'jobPosition' },
];

export const managerHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idUser' },
	{ label: lan.userName, key: 'userName' },
	{ label: lan.email, key: 'email' },
	{ label: lan.name, key: 'name' },
	{ label: lan.lastName, key: 'lastName' },
	{ label: lan.idCountryCode, key: 'idCountryCode' },
	{ label: lan.mobileNumber, key: 'mobileNumber' },
	{ label: lan.officePhone, key: 'phoneNumber' },
	{ label: lan.jobPosition, key: 'jobPosition' },
];

export const agentHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idUser' },
	{ label: lan.userName, key: 'userName' },
	{ label: lan.email, key: 'email' },
	{ label: lan.name, key: 'name' },
	{ label: lan.lastName, key: 'lastName' },
	{ label: lan.idCountryCode, key: 'idCountryCode' },
	{ label: lan.mobileNumber, key: 'phone' },
	{ label: lan.plate, key: 'licencePlate' },
	{ label: lan.workScheduleFrom, key: 'workScheduleFrom' },
	{ label: lan.workScheduleTo, key: 'workScheduleTo' },
	{ label: lan.address, key: 'address' },
	{ label: lan.latitude, key: 'latitude' },
	{ label: lan.longitude, key: 'longitud' },
	{ label: lan.team, key: 'teamName' },
];

export const agentHeadersDashboards = (lan) => [
	{ label: lan.idAgent, key: 'idAgent' },
	{ label: lan.name, key: 'name' },
	{ label: lan.location, key: 'geolocation' },
	{ label: lan.status, key: 'status' },
	{ label: lan.lastDataObtained, key: 'lastDataObtained' },
];

export const companyHeadersDashboard = (lan) => [
	{ label: lan.company, key: 'companyName' },
	{ label: lan.taskStatus_0, key: 'totalManaging' },
	{ label: lan.taskStatus_9, key: 'totalAssignedToTeam' },
	{ label: lan.taskStatus_8, key: 'totalAssignedToPartner' },
	{ label: lan.taskStatus_3, key: 'totalWaitingAgent' },
	{ label: lan.taskStatus_4, key: 'totalInProgress' },
	{ label: lan.taskStatus_6, key: 'totalStartRoute' },
	{ label: lan.taskStatus_10, key: 'totalArrived' },
	{ label: lan.taskStatus_5, key: 'totalExecuting' },
	{ label: lan.taskStatus_15, key: 'totalPause' },
	{ label: lan.taskStatus_2, key: 'totalFinish' },
	{ label: lan.taskStatus_1, key: 'totalCancel' },
	{ label: lan.grandTotal, key: 'totalCount' },
];

export const slaTaskCompanyHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idFielder' },
	{ label: lan.folio, key: 'folio' },
	{ label: lan.company, key: 'company' },
	{ label: lan.branch, key: 'branch' },
	{ label: lan.status, key: 'status' },
	{ label: lan.startTime, key: 'startTime' },
	{ label: lan.description, key: 'description' },
	{ label: lan.agent, key: 'agent' },
	{ label: lan.commentsAgent, key: 'commentsAgent' },
];

export const clientHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idUser' },
	{ label: lan.userName, key: 'userName' },
	{ label: lan.email, key: 'email' },
	{ label: lan.name, key: 'name' },
	{ label: lan.lastName, key: 'lastName' },
	{ label: lan.idCountryCode, key: 'idCountryCode' },
	{ label: lan.mobileNumber, key: 'mobileNumber' },
	{ label: lan.officePhone, key: 'phoneNumber' },
	{ label: lan.linkedToBranch, key: 'linkedToBranches' },
	{ label: lan.idBranch, key: 'idBranches' },
	{ label: lan.address, key: 'address' },
	{ label: lan.latitude, key: 'latitude' },
	{ label: lan.longitude, key: 'longitud' },
	{ label: lan.jobPosition, key: 'jobPosition' },
	{ label: lan.attentionTimeFrom, key: 'attentionTimeFrom' },
	{ label: lan.attentionTimeTo, key: 'attentionTimeTo' },
];

export const companyHeaders = (lan) => [
	{ label: lan.idCompany, key: 'idCompany' },
	{ label: lan.companyIdCustom, key: 'idCustom' },
	{ label: lan.companyName, key: 'name' },
	{ label: lan.businessName, key: 'businessName' },
	{ label: lan.parentCompanyId, key: 'parentCompanyId' },
	{ label: lan.parentCompanyNameText, key: 'parentCompanyName' },
	{ label: lan.address, key: 'address' },
	{ label: lan.contractNumber, key: 'contractNumber' },
];

export const branchHeaders = (lan) => [
	{ label: lan.idBranch, key: 'idBranch' },
	{ label: lan.idCustomBranch, key: 'idCustom' },
	{ label: lan.branchNameText, key: 'name' },
	{ label: lan.address, key: 'address' },
	{ label: lan.latitude, key: 'latitude' },
	{ label: lan.longitude, key: 'longitude' },
	{ label: lan.idCompany, key: 'parentCompanyId' },
	{ label: lan.companyName, key: 'parentCompanyName' },
];

export const exportableDataTaskReasonHeaders = (lan) => [
	{ label: lan.idFielder, key: 'idFielder' },
	{ label: lan.folio, key: 'folio' },
	{ label: lan.status, key: 'status' },
	{ label: lan.company, key: 'company' },
	{ label: lan.branch, key: 'branch' },
	{ label: lan.agent, key: 'agent' },
	{ label: lan.reason, key: 'parentReason' },
	{ label: lan.dependency, key: 'dependency' },
	{ label: lan.comment, key: 'agentComment' },
];
