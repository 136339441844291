import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({});

const Content = styled(Grid)(({ theme }) => ({
	flexGrow: 1,
	boxSizing: 'border-box',
	backgroundColor: '#F8F9FD',
	marginLeft: `${theme.spacing(6)} !important`,
	width: `calc(100% -  ${theme.spacing(6)})`,
	[theme.breakpoints.up('sm')]: {
		marginLeft: `${theme.spacing(8)} !important`,
		width: `calc(100% -  ${theme.spacing(8)}) !important`,
	},
}))

const FielderContainer = styled('div')`
	background-color: #F8F9FD;
	height: 100vh;
	width: 100%;
`;

const ToolbarSpace = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar, // necessary for content to be below app bar
}));

export { useStyles, Content, FielderContainer, ToolbarSpace };
