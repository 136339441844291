import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')({
    width: '24px',
    height: 'auto'
});

export const FlexContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const StyledTitle = styled('span')({
    fontSize: '1.2rem',
});

export const TitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export const IconBox = styled('div')({
    marginRight: '8px',
    width: '24px',
    height: 'auto'
});