import React, { Component } from 'react';

class SelectJobPosition extends Component {
	constructor(props) {
		super(props);

		let defaultValue = '0';
		if (this.props.defaultValue === 'Administrative' || this.props.defaultValue === 'Administrativo') {
			defaultValue = this.props.lan.administrative;
		}
		if (
			this.props.defaultValue === 'Executive' ||
			this.props.defaultValue === 'Ejecutivo' ||
			this.props.defaultValue === 'Executivo'
		) {
			defaultValue = this.props.lan.executive;
		}
		if (this.props.defaultValue === 'Technical' || this.props.defaultValue === 'Técnico') {
			defaultValue = this.props.lan.technical;
		}
		if (this.props.defaultValue === 'Commercial' || this.props.defaultValue === 'Comercial') {
			defaultValue = this.props.lan.trade;
		}
		if (this.props.defaultValue === 'Support' || this.props.defaultValue === 'Soporte') {
			defaultValue = this.props.lan.support;
		}
		if (
			this.props.defaultValue === 'Other' ||
			this.props.defaultValue === 'Otro' ||
			this.props.defaultValue === 'Outro'
		) {
			defaultValue = this.props.lan.other;
		}

		this.state = {
			defaultValue,
		};
	}

	render() {
		return (
			<>
				<select
					key='select-country'
					defaultValue={this.state.defaultValue}
					className='custom-select'
					id='taskType-red-input'
					onChange={(e) => this.props.onChange(e.target.value, 'jobPosition')}
					disabled={this.props.disabled}
				>
					<option disabled value='0'>
						{' '}
						{this.props.lan.jobPosition}
					</option>
					<option value={this.props.lan.administrative}>{this.props.lan.administrative}</option>
					<option value={this.props.lan.executive}>{this.props.lan.executive}</option>
					<option value={this.props.lan.technical}>{this.props.lan.technical}</option>
					<option value={this.props.lan.trade}>{this.props.lan.trade} </option>
					<option value={this.props.lan.support}>{this.props.lan.support}</option>
					<option value={this.props.lan.other}>{this.props.lan.other} </option>
				</select>
			</>
		);
	}
}

export default SelectJobPosition;
