const DAILY = 1;
const WEEKLY = 2;
const MONTHLY = 3;
const ANNUALLY = 4;
const WEEKDAYS = 5;
const QUARTERLY = 6;
const SEMIANNUALLY = 7;

const REPETITION_DATES = {
	DAILY,
	WEEKLY,
	MONTHLY,
	ANNUALLY,
	WEEKDAYS,
	QUARTERLY,
	SEMIANNUALLY,
};

export default REPETITION_DATES;
