import RoleUtils from 'FielderUtils/roles/roleUtils';

export const isLocalUser = (user, session) => {
	if (session) {
		user = session.user;
	}

	if (user) {
		return !(RoleUtils.isRootGlobalAccountUser(user) || RoleUtils.isClientGlobalAccountUser(user));
	}

	return false;
};
