import React, { useState, useEffect } from 'react';

import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Grid, InputAdornment } from '@mui/material'
import ExploreIcon from '@mui/icons-material/Explore';
import { useLanguage } from 'Context/LanguageContext';
import FielderButton from 'FielderComponents/FielderButton';
import TextField from '~/TextField/TextField.component';

const BASE_INPUT_ADORNMENT = (
	<InputAdornment position='start'>
		<ExploreIcon color='disabled' />
	</InputAdornment>
);
const ERROR_INPUT_ADORNMENT = (
	<InputAdornment position='start'>
		<img src={redAlertIcon} alt='' />
	</InputAdornment>
);

const SearchByCoordinates = ({ coordinates, updateCoordinates, disabled, error }) => {
	const lan = useLanguage();

	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [inputAdornment, setInputAdornment] = useState(BASE_INPUT_ADORNMENT);

	useEffect(() => {
		if (error) {
			setInputAdornment(ERROR_INPUT_ADORNMENT);
		} else {
			setInputAdornment(BASE_INPUT_ADORNMENT);
		}
	}, [error]);

	useEffect(() => {
		if (coordinates != null) {
			if (latitude != coordinates.lat) {
				setLatitude(coordinates.lat);
			}

			if (longitude != coordinates.lng) {
				setLongitude(coordinates.lng);
			}
		}
	}, [coordinates]);

	const handleUpdateLat = (e) => setLatitude(e.target.value);
	const handleUpdateLng = (e) => setLongitude(e.target.value);
	const handleUpdateCoordinates = () =>
		updateCoordinates({ coordinates: { lat: parseFloat(latitude), lng: parseFloat(longitude) } });

	return (
		<>
			<Grid container direction='row' spacing={2}>
				<Grid item xs={6}>
					<TextField
						id='txt-latitude'
						type='number'
						label={lan.latitude}
						onChange={handleUpdateLat}
						disabled={disabled}
						value={latitude}
						error={error}
						startAdornment={inputAdornment}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id='txt-longitude'
						type='number'
						label={lan.longitude}
						onChange={handleUpdateLng}
						disabled={disabled}
						value={longitude}
						error={error}
						startAdornment={inputAdornment}
					/>
				</Grid>
			</Grid>
			{!disabled && (
				<Grid container direction='row'>
					<Grid item xs={8} className='mt-3 mx-auto'>
						<FielderButton onClick={handleUpdateCoordinates}>{lan.search}</FielderButton>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default SearchByCoordinates;
