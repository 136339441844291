import React, { useState, useEffect, useRef } from 'react';

import Status from '@core/constants/task/TaskStatus.constants';
import useCreateMarkers from '@core/services/customHooks/useCreateMarkers';

import { MapContainer } from '../../MyOperationPage.styles';
import Map from '~/Map/Map.component';

const MyOperationMap = ({ tasks, onTaskClick, onAgentClick, route }) => {
	const markersInfo = {
		tasks,
		onTaskClick,
		onAgentClick,
		hideFinished: true,
		hideCancelled: true,
	};

	const { taskMarkers, agentMarkers } = useCreateMarkers(markersInfo);
	const mapRef = useRef()
	const [markers, setMarkers] = useState([]);

	useEffect(() => {
		const mapMarkers = [];

		for (const taskStatusGroup in taskMarkers) {
			if (taskMarkers[taskStatusGroup].markers.length > 0 && taskStatusGroup !== Status.FINISH) {
				mapMarkers.push(taskMarkers[taskStatusGroup]);
			}
		}

		if (agentMarkers.markers.length > 0) {
			mapMarkers.push(agentMarkers);
		}

		setMarkers(mapMarkers);
	}, [taskMarkers, agentMarkers]);

	return (
		<MapContainer>
			<Map markers={markers} route={route} autoCenter ref={mapRef} />
		</MapContainer>
	);
};

export default React.memo(MyOperationMap);
