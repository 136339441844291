import moment from 'moment-timezone';

export const getTaskGroupTableWarning = (tasksGroups, listOfErrorDeleteGroupTask) => {
	let totalTaskGroups = [];
	let flagConstruction = false;

	if (tasksGroups && tasksGroups.length > 0) {
		for (let index = 0; index < tasksGroups.length; index++) {
			for (let x = 0; x < listOfErrorDeleteGroupTask.length; x++) {
				flagConstruction = false;

				if (listOfErrorDeleteGroupTask[x].idGroupTask == tasksGroups[index].id) {
					totalTaskGroups.push({
						iconWarning: listOfErrorDeleteGroupTask[x].statusCode,
						idGroupTask: tasksGroups[index].id,
						idCustomGroup: tasksGroups[index].idCustomGroup,
						name: tasksGroups[index].name,
						executionDate: tasksGroups[index].executionDateTime
							? moment(tasksGroups[index].executionDateTime).format('DD-MM-YYYY hh:mm a')
							: '',
						branch: tasksGroups[index].branch ? tasksGroups[index].branch.name : '',
						taskGroupStatusLowerCase: tasksGroups[index].status,
					});
					flagConstruction = true;
				}
				if (flagConstruction) {
					break;
				}
			}
			if (flagConstruction != true) {
				totalTaskGroups.push({
					iconWarning: 0,
					idGroupTask: tasksGroups[index].id,
					idCustomGroup: tasksGroups[index].idCustomGroup,
					name: tasksGroups[index].name,
					executionDate: tasksGroups[index].executionDateTime
						? moment(tasksGroups[index].executionDateTime).format('DD-MM-YYYY hh:mm a')
						: '',
					branch: tasksGroups[index].branch ? tasksGroups[index].branch.name : '',
					taskGroupStatusLowerCase: tasksGroups[index].status,
				});
			}
		}
	}
	return totalTaskGroups;
}


export const hasGroupTasKFunctionality = (functionalities) => {
	return functionalities.some((func) => {
		if (func.functionality.customCode === 'GROUPS') {
			return true;
		}
		return false;
	});
};


export const getBaseColumsGroupTask = () => {
	return [
		{ id: 'idGroupTask', padding: 'none' },
		{ id: 'idCustomGroup', padding: 'none' },
		{ id: 'name', padding: 'none' },
		{ id: 'executionDate',padding: 'none' },
		{ id: 'branch', padding: 'none' },
		{ id: 'taskGroupStatusLowerCase', padding: 'none' },
	]
};