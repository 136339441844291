import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_amchartsdarks from '@amcharts/amcharts4/themes/dataviz';
import ExportIcon from '@assets/icons/import-icon.svg';
import { callbackify } from 'util';

// am4core.useTheme(am4themes_myTheme,am4themes_animated);*/

let yAxis;
let series;

class Graph {
	constructor(props) {
		this.props = props;
		this.exportItems = [
			{
				label: this.props.lan.menu,
				class: 'export-main',
				menu: [
					{
						label: this.props.lan.download,
						menu: [
							{ type: 'png', label: 'PNG', color: '#dc5c87' },
							{ type: 'jpg', label: 'JPG', color: '#dc5c87' },
							{ type: 'gif', label: 'GIF', color: '#dc5c87' },
							{ type: 'svg', label: 'SVG', color: '#dc5c87' },
							{ type: 'pdf', label: 'PDF', color: '#dc5c87' },
						],
					},
					{
						label: this.props.lan.save,
						menu: [
							{ type: 'json', label: 'JSON' },
							{ type: 'csv', label: 'CSV' },
							{ type: 'xlsx', label: 'XLSX' },
						],
					},
					{
						label: this.props.lan.print,
						type: 'print',
					},
				],
			},
		];
	}

	setAxes(chart, category) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.labels.template.fontSize = 11;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	}

	// Curved Colums
	setAxesCurvedColums(
		chart,
		category,
		text1,
		textServiceStatus,
		dateFromDateTo,
		textTotalTask,
		total,
		textTotalFinished
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		// categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.rotation = 270;
		categoryAxis.renderer.labels.template.fontSize = 9;

		// categoryAxis.title.text = "[bold]"+textServiceStatus+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.fontSize = 13;
		// topContainer.fixedWidthGrid=true

		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textServiceStatus}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		if (textTotalFinished != undefined) {
			const axisTitle1 = topContainer.createChild(am4core.Label);
			axisTitle1.text = `[bold]${textTotalFinished}`;
			axisTitle1.fontWeight = 600;
			axisTitle1.align = 'center';
			axisTitle1.paddingTop = 25;
		}

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

		/* chart.events.on("ready", function(ev) {
        valueAxis.min = valueAxis.minZoomed;
        valueAxis.max = valueAxis.maxZoomed;
      }); */

		valueAxis.title.text = text1;
	}

	setCurvedColumsSeriesParams(chart, category, valueY, props) {
		const series = chart.series.push(new am4charts.CurvedColumnSeries());
		series.dataFields.categoryX = category;
		series.dataFields.valueY = valueY;
		// series.tooltipText = "{valueY.value}";

		series.columns.template.strokeOpacity = 0;
		series.columns.template.fillOpacity = 0.75;

		if (props.enableOnClick) {
			const infoData = chart.data;
			series.columns.template.events.on("hit", function (ev) {
				for (let i = 0; i < infoData.length; ++i) {
					if (ev && ev.target && ev.target._dataItem && ev.target._dataItem.categoryX) {
						let categoryGraph = ev.target._dataItem.categoryX;
						if (categoryGraph == infoData[i].label) {
							props.showTaskOnColumn(infoData[i].tasks, categoryGraph)
						}
					}
				}
			}, this);
		}

		const hoverState = series.columns.template.states.create('hover');
		hoverState.properties.fillOpacity = 0.8;
		hoverState.properties.tension = 0.4;

		series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));

		series.columns.template.tooltipText = '{valueY.value}';
		series.columns.template.tooltipY = 0;
		series.tooltip.label.textAlign = 'middle';
		series.tooltip.pointerOrientation = 'down';
		series.columns.alwaysShowTooltip = true;

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = '{valueY.value}';
		bullet.label.fontSize = 10;
		bullet.label.fill = am4core.color('#000000');
		bullet.label.verticalCenter = 'bottom';
		bullet.label.dy = -8;
	}

	// Curved Colums TIME
	setAxesCurvedColumsTime(
		chart,
		category,
		baseUnit,
		textIdFielder,
		textTitleGrah,
		dateFromDateTo,
		textTotalTask,
		total,
		textTotalFinished
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = 'right';
		categoryAxis.renderer.labels.template.verticalCenter = 'middle';

		categoryAxis.renderer.labels.template.fontSize = 10;
		categoryAxis.tooltip.fontSize = 10;

		// categoryAxis.title.text = "[bold]"+textTitleGrah+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTitleGrah}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		if (textTotalFinished != undefined && textTotalFinished != null) {
			const axisTitle1 = topContainer.createChild(am4core.Label);
			axisTitle1.text = `[bold]${textTotalFinished}`;
			axisTitle1.fontWeight = 600;
			axisTitle1.align = 'center';
			axisTitle1.paddingTop = 25;
		}

		categoryAxis.renderer.labels.template.rotation = 270;

		const valueAxis = chart.yAxes.push(new am4charts.DurationAxis());

		chart.events.on('ready', (ev) => {
			valueAxis.min = valueAxis.minZoomed;
			valueAxis.max = valueAxis.maxZoomed;
		});

		valueAxis.baseUnit = baseUnit;
		valueAxis.renderer.labels.template.fontSize = 10;
		valueAxis.tooltip.fontSize = 10;
	}

	setCurvedColumsSeriesParamsTime(chart, category, valueY) {
		const series = chart.series.push(new am4charts.CurvedColumnSeries());
		series.dataFields.categoryX = category;
		series.dataFields.valueY = valueY;

		series.columns.template.tooltipText = '{valueY.formatDuration()}';
		series.columns.template.strokeOpacity = 0;
		series.columns.template.fillOpacity = 0.75;

		const hoverState = series.columns.template.states.create('hover');
		hoverState.properties.fillOpacity = 1;
		hoverState.properties.tension = 0.4;

		// chart.cursor = new am4charts.XYCursor();

		// Add distinctive colors for each column using adapter
		series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = '{valueY.formatDuration()}';
		bullet.label.fontSize = 8;
		bullet.label.fill = am4core.color('#000000');
		bullet.locationY = 1;
		bullet.dy = -50;
		bullet.label.rotation = 270;
	}

	// LayeredColumn
	setAxesLayeredColumn(chart, category, text1, text2) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		// categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.fontSize = 10;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.title.text = `${text1} / ${text2}`;
		valueAxis.title.fontWeight = 600;
	}

	setLayeredColumnSeriesParams(chart, category, valueY1, valueY2, text1, text2) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY1;
		series.dataFields.categoryX = category;

		series.dataItems.template.locations.categoryX = 0.5;

		series.clustered = false;
		series.tooltipText = `${text1} {categoryX} : [bold]{valueY}[/]`;

		const series2 = chart.series.push(new am4charts.ColumnSeries());
		series2.dataFields.valueY = valueY2;
		series2.dataFields.categoryX = category;

		series2.dataItems.template.locations.categoryX = 0.5;

		series2.clustered = false;
		series2.columns.template.width = am4core.percent(50);
		series2.tooltipText = `${text2} {categoryX} : [bold]{valueY}[/]`;
	}

	// LayeredColumn TIME
	setAxesLayeredColumnTime(
		chart,
		category,
		text1,
		text2,
		textIdFielder,
		baseUnit,
		showAnotherUnit,
		textTitleGrah,
		dateFromDateTo,
		textTotalTask,
		total,
		textTotalFinished
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.tooltip.fontSize = 12;
		categoryAxis.renderer.labels.template.fontSize = 10;

		// categoryAxis.title.text = "[bold]"+textTitleGrah+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTitleGrah}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		if (textTotalFinished != undefined) {
			const axisTitle1 = topContainer.createChild(am4core.Label);
			axisTitle1.text = `[bold]${textTotalFinished}`;
			axisTitle1.fontWeight = 600;
			axisTitle1.align = 'center';
			axisTitle1.paddingTop = 25;
		}

		categoryAxis.renderer.labels.template.rotation = 270;

		const valueAxis = chart.yAxes.push(new am4charts.DurationAxis());
		if (showAnotherUnit) {
			valueAxis.title.text = text1;
		} else {
			valueAxis.title.text = `${text1} / ${text2}`;
		}

		chart.events.on('ready', (ev) => {
			valueAxis.min = valueAxis.minZoomed;
			valueAxis.max = valueAxis.maxZoomed;
		});

		valueAxis.title.fontWeight = 600;
		valueAxis.title.fontSize = 10;
		valueAxis.renderer.labels.template.fontSize = 10;

		valueAxis.tooltip.fontSize = 10;
		valueAxis.baseUnit = baseUnit;
	}

	setLayeredColumnSeriesParamsTime(chart, category, valueY1, valueY2, text1, text2, showAnotherUnit) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY1;
		series.dataFields.categoryX = category;

		series.dataItems.template.locations.categoryX = 0.5;

		series.clustered = false;
		// series.tooltipText =  text1+" {categoryX} : [bold]{valueY}[/]";
		series.tooltip.dy = 40;
		series.tooltip.label.fontSize = 12;
		series.columns.template.tooltipText = `${text1}: [bold]{valueY.formatDuration()}`;
		series.columns.template.tooltipY = 0;

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = 'ST: ' + '{valueY.formatDuration()}';
		bullet.label.fontSize = 8;
		bullet.label.fill = am4core.color('#000000');
		bullet.locationY = 1;
		bullet.dy = -50;
		bullet.dx = -15;
		bullet.label.rotation = 270;

		if (showAnotherUnit) {
			const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis2.renderer.opposite = true;
			valueAxis2.title.text = text2;
			valueAxis2.title.fontWeight = 600;
			valueAxis2.title.fontSize = 10;
			valueAxis2.renderer.labels.template.fontSize = 10;

			const series2 = chart.series.push(new am4charts.ColumnSeries());
			series2.dataFields.valueY = valueY2;
			series2.dataFields.categoryX = category;

			series2.dataItems.template.locations.categoryX = 0.5;
			series2.tooltip.label.fontSize = 12;
			series2.clustered = false;

			series2.columns.template.width = am4core.percent(50);
			series2.yAxis = valueAxis2;
			series2.columns.template.tooltipText = `${text2}: [bold]{valueY}`;

			const bullet = series2.bullets.push(new am4charts.LabelBullet());
			bullet.interactionsEnabled = false;
			bullet.label.text = '{valueY.value}';
			bullet.label.fontSize = 10;
			bullet.label.fill = am4core.color('#000000');
			bullet.label.verticalCenter = 'bottom';
			bullet.label.dy = -9;
			bullet.dx = 15;
		} else {
			const series2 = chart.series.push(new am4charts.ColumnSeries());
			series2.dataFields.valueY = valueY2;
			series2.dataFields.categoryX = category;

			series2.dataItems.template.locations.categoryX = 0.5;
			series2.tooltip.label.fontSize = 12;
			series2.tooltip.dy = -10;
			series2.clustered = false;
			series2.columns.template.width = am4core.percent(50);
			series2.columns.template.tooltipText = `${text2}: [bold]{valueY.formatDuration()}`;

			const bullet = series2.bullets.push(new am4charts.LabelBullet());
			bullet.interactionsEnabled = false;
			bullet.label.text = 'RT: ' + '{valueY.formatDuration()}';
			bullet.label.fontSize = 8;
			bullet.label.fill = am4core.color('#000000');
			bullet.locationY = 1;
			bullet.dy = -50;
			bullet.dx = 15;
			bullet.label.rotation = 270;
		}
		series.columns.template.tooltipY = -10;
		// series2.tooltipText = text2+" {categoryX} : [bold]{valueY}[/]";
	}

	/** ************************** AGENT PUNTUALITY ***************************** */
	// LayeredColumn TIME
	setAxesPuntuality(chart, category, textTitleGrah, dateFromDateTo, total, textTotalTask, titleAgent) {
		const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.inversed = true;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTitleGrah}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		const axisTitle1 = topContainer.createChild(am4core.Label);
		axisTitle1.text = `[bold]${titleAgent}`;
		axisTitle1.fontWeight = 600;
		axisTitle1.align = 'center';
		axisTitle1.paddingTop = 25;
	}

	setDateAxesPuntuality(chart, firstTime) {
		const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm';
		dateAxis.renderer.minGridDistance = 50;
		dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };

		dateAxis.min = new Date(firstTime).getTime();
		// dateAxis.strictMinMax = true;
		dateAxis.renderer.tooltipLocation = 0;
	}

	setPuntualitySeries(chart, category, openDateX, dateX) {
		const series1 = chart.series.push(new am4charts.ColumnSeries());
		series1.columns.template.width = am4core.percent(80);
		series1.columns.template.tooltipText = '{name}: {openDateX} - {dateX}';

		series1.dataFields.openDateX = openDateX;
		series1.dataFields.dateX = dateX;
		series1.dataFields.categoryY = category;
		series1.columns.template.strokeOpacity = 1;
	}

	/** ************************** EXECUTION TIME / START DATE ***************************** */
	// LayeredColumn TIME
	setAxesLayeredColumnTimeDate(
		chart,
		category,
		text1,
		text2,
		textIdFielder,
		baseUnit,
		showAnotherUnit,
		textTitleGrah,
		dateFromDateTo,
		textTotalTask,
		total
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.tooltip.fontSize = 12;
		categoryAxis.renderer.labels.template.fontSize = 10;

		// categoryAxis.title.text = "[bold]"+textTitleGrah+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;
		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTitleGrah}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		categoryAxis.renderer.labels.template.rotation = 270;

		const valueAxis = chart.yAxes.push(new am4charts.DurationAxis());
		if (showAnotherUnit) {
			valueAxis.title.text = text1;
		} else {
			valueAxis.title.text = `${text1} / ${text2}`;
		}

		valueAxis.title.fontWeight = 600;
		valueAxis.title.fontSize = 10;
		valueAxis.renderer.labels.template.fontSize = 10;

		valueAxis.tooltip.fontSize = 10;
		valueAxis.baseUnit = baseUnit;
	}

	setLayeredColumnSeriesParamsTimeDate(chart, category, valueY1, valueY2, text1, text2, showAnotherUnit) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = valueY1;
		series.dataFields.categoryX = category;

		series.dataItems.template.locations.categoryX = 0.5;

		series.clustered = false;
		// series.tooltipText =  text1+" {categoryX} : [bold]{valueY}[/]";
		series.tooltip.dy = -15;
		series.tooltip.label.fontSize = 13;
		series.columns.template.tooltipText = `${text1}: [bold]{valueY.formatDuration()}`;
		series.columns.template.tooltipY = 0;

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = 'ST: ' + '{valueY.formatDuration()}';
		bullet.label.fontSize = 8;
		bullet.label.fill = am4core.color('#000000');
		bullet.locationY = 1;
		bullet.dy = -50;
		bullet.dx = -15;
		bullet.label.rotation = 270;

		if (showAnotherUnit) {
			const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis2.renderer.opposite = true;
			valueAxis2.title.text = text2;
			valueAxis2.title.fontWeight = 600;
			valueAxis2.title.fontSize = 10;
			valueAxis2.renderer.labels.template.fontSize = 10;

			const series2 = chart.series.push(new am4charts.ColumnSeries());
			series2.dataFields.valueY = valueY2;
			series2.dataFields.categoryX = category;

			series2.dataItems.template.locations.categoryX = 0.5;
			series2.tooltip.label.fontSize = 13;
			series2.clustered = false;
			series2.columns.template.width = am4core.percent(50);
			series2.yAxis = valueAxis2;
			series2.columns.template.tooltipText = `${text2}: [bold]{valueY}`;

			const bullet = series2.bullets.push(new am4charts.LabelBullet());
			bullet.interactionsEnabled = false;
			bullet.label.text = '{valueY.value}';
			bullet.label.fontSize = 10;
			bullet.label.fill = am4core.color('#000000');
			bullet.label.verticalCenter = 'bottom';
			bullet.label.dy = -9;
			bullet.dx = 15;
		} else {
			const series2 = chart.series.push(new am4charts.ColumnSeries());
			series2.dataFields.valueY = valueY2;
			series2.dataFields.categoryX = category;

			series2.dataItems.template.locations.categoryX = 0.5;
			series2.tooltip.label.fontSize = 13;
			series2.clustered = false;
			series2.columns.template.width = am4core.percent(50);
			series2.columns.template.tooltipText = `${text2}: [bold]{valueY.formatDuration()}`;

			const bullet = series2.bullets.push(new am4charts.LabelBullet());
			bullet.interactionsEnabled = false;
			bullet.label.text = 'RT: ' + '{valueY.formatDuration()}';
			bullet.label.fontSize = 8;
			bullet.label.fill = am4core.color('#000000');
			bullet.locationY = 1;
			bullet.dy = -50;
			bullet.dx = 15;
			bullet.label.rotation = 270;
		}
		series.columns.template.tooltipY = -10;
		// series2.tooltipText = text2+" {categoryX} : [bold]{valueY}[/]";
	}

	/** ************************************************************************************ */
	// StackedColumn
	setAxesStackedColumn(chart, category, textTypeOfTask, dateFromDateTo, textTotalTask, total) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.labels.template.fontSize = 10;
		categoryAxis.renderer.labels.template.rotation = 270;
		// categoryAxis.title.text = "[bold]"+textTypeOfTask+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTypeOfTask}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;
		valueAxis.max = 100;
		// valueAxis.strictMinMax = true;
		valueAxis.calculateTotals = true;
		// valueAxis.renderer.minWidth = 50;

		chart.events.on('ready', (ev) => {
			valueAxis.min = valueAxis.minZoomed;
			valueAxis.max = valueAxis.maxZoomed;
		});

		valueAxis.renderer.labels.template.adapter.add('text', (label, target, key) => `${label}%`);
	}

	setStackedColumnSeriesParams(
		chart,
		category,
		value1,
		value2,
		value3,
		value4,
		serie1,
		serie2,
		serie3,
		serie4
	) {
		const series1 = chart.series.push(new am4charts.ColumnSeries());
		series1.columns.template.width = am4core.percent(80);
		series1.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series1.name = serie1;
		series1.dataFields.categoryX = category;
		series1.dataFields.valueY = value1;
		series1.dataFields.valueYShow = 'totalPercent';

		series1.dataItems.template.locations.categoryX = 0.5;
		series1.stacked = true;
		series1.tooltip.pointerOrientation = 'vertical';

		const bullet1 = series1.bullets.push(new am4charts.LabelBullet());
		bullet1.interactionsEnabled = false;
		bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet1.label.fontSize = 9;
		bullet1.label.fill = am4core.color('#ffffff');
		bullet1.locationY = 0.5;

		const series2 = chart.series.push(new am4charts.ColumnSeries());
		series2.columns.template.width = am4core.percent(80);
		series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series2.name = serie2;
		series2.dataFields.categoryX = category;
		series2.dataFields.valueY = value2;
		series2.dataFields.valueYShow = 'totalPercent';
		series2.dataItems.template.locations.categoryX = 0.5;
		series2.stacked = true;
		series2.tooltip.pointerOrientation = 'vertical';

		const bullet2 = series2.bullets.push(new am4charts.LabelBullet());
		bullet2.interactionsEnabled = false;
		bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet2.label.fontSize = 9;
		bullet2.locationY = 0.5;
		bullet2.label.fill = am4core.color('#ffffff');

		const series3 = chart.series.push(new am4charts.ColumnSeries());
		series3.columns.template.width = am4core.percent(80);
		series3.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series3.name = serie3;
		series3.dataFields.categoryX = category;
		series3.dataFields.valueY = value3;
		series3.dataFields.valueYShow = 'totalPercent';
		series3.dataItems.template.locations.categoryX = 0.5;
		series3.stacked = true;
		series3.tooltip.pointerOrientation = 'vertical';

		const bullet3 = series3.bullets.push(new am4charts.LabelBullet());
		bullet3.interactionsEnabled = false;
		bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet3.label.fontSize = 9;
		bullet3.locationY = 0.5;
		bullet3.label.fill = am4core.color('#ffffff');

		const series4 = chart.series.push(new am4charts.ColumnSeries());
		series4.columns.template.width = am4core.percent(80);
		series4.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series4.name = serie4;
		series4.dataFields.categoryX = category;
		series4.dataFields.valueY = value4;
		series4.dataFields.valueYShow = 'totalPercent';
		series4.dataItems.template.locations.categoryX = 0.5;
		series4.stacked = true;
		series4.tooltip.pointerOrientation = 'vertical';

		const bullet4 = series4.bullets.push(new am4charts.LabelBullet());
		bullet4.interactionsEnabled = false;
		bullet4.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet4.label.fontSize = 9;
		bullet4.locationY = 0.5;
		bullet4.label.fill = am4core.color('#ffffff');
		
	}

	// StackedColumn Agent
	setAxesStackedColumnAgent(chart, category, textTypeOfTask, dateFromDateTo, textTotalTask, total) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.labels.template.fontSize = 10;

		// categoryAxis.title.text = "[bold]"+textTypeOfTask+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTypeOfTask}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		categoryAxis.renderer.labels.template.rotation = 270;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.min = 0;
		valueAxis.max = 100;
		valueAxis.strictMinMax = true;
		valueAxis.calculateTotals = true;
		valueAxis.renderer.minWidth = 50;

		valueAxis.renderer.labels.template.adapter.add('text', (label, target, key) => `${label}%`);
	}

	setStackedColumnSeriesParamsAgent(
		chart,
		category,
		value1,
		value2,
		value3,
		value4,
		value5,
		value6,
		value7,
		value8,
		value9,
		value10,
		value11,
		value12,
		value13,
		serie1,
		serie2,
		serie3,
		serie4,
		serie5,
		serie6,
		serie7,
		serie8,
		serie9,
		serie10,
		serie11,
		serie12,
		serie13,
		percent1,
		percent2,
		percent3,
		percent4,
		percent5,
		percent6,
		percent7,
		percent8,
		percent9,
		percent10,
		percent11,
		percent12,
		percent13
	) {
		const series1 = chart.series.push(new am4charts.ColumnSeries());

		series1.columns.template.width = am4core.percent(80);
		series1.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series1.columns.cursorTooltipEnabled = false;
		// series1.tooltip.pointerOrientation = "left";
		// series1.tooltip.dy = -15;
		// series1.tooltip.dx = -80
		series1.tooltip.fontSize = 10;
		series1.tooltip.propertyFields.pointerOrientation = 'orientation';
		series1.calculatePercent = true;
		series1.legendSettings.valueText = `${percent1}% / {valueY.sum}`;

		series1.name = serie1;
		series1.dataFields.categoryX = category;
		series1.dataFields.valueY = value1;
		series1.dataFields.valueYShow = 'totalPercent';
		series1.dataItems.template.locations.categoryX = 0.5;
		series1.stacked = true;
		series1.tooltip.pointerOrientation = 'vertical';

		const bullet1 = series1.bullets.push(new am4charts.LabelBullet());
		bullet1.interactionsEnabled = false;
		bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet1.label.fontSize = 9;
		bullet1.label.fill = am4core.color('#ffffff');
		bullet1.locationY = 0.5;

		const series2 = chart.series.push(new am4charts.ColumnSeries());

		series2.columns.template.width = am4core.percent(80);
		series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series2.columns.cursorTooltipEnabled = false;
		// series2.tooltip.dy = -5;
		// series2.tooltip.dx = -70

		series2.tooltip.fontSize = 10;
		series2.tooltip.propertyFields.pointerOrientation = 'orientation';
		series2.calculatePercent = true;
		series2.legendSettings.valueText = `${percent2}% / {valueY.sum}`;

		series2.name = serie2;
		series2.dataFields.categoryX = category;
		series2.dataFields.valueY = value2;
		series2.dataFields.valueYShow = 'totalPercent';
		series2.dataItems.template.locations.categoryX = 0.5;
		series2.stacked = true;
		series2.tooltip.pointerOrientation = 'vertical';

		const bullet2 = series2.bullets.push(new am4charts.LabelBullet());
		bullet2.interactionsEnabled = false;
		bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet2.label.fontSize = 9;
		bullet2.locationY = 0.5;
		bullet2.label.fill = am4core.color('#ffffff');

		const series3 = chart.series.push(new am4charts.ColumnSeries());

		series3.columns.template.width = am4core.percent(80);
		series3.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		series3.columns.cursorTooltipEnabled = false;
		// series3.tooltip.dy = 5;
		// series3.tooltip.dx = -60;

		series3.tooltip.fontSize = 10;
		series3.tooltip.propertyFields.pointerOrientation = 'orientation';
		series3.calculatePercent = true;
		series3.legendSettings.valueText = `${percent3}% / {valueY.sum}`;

		series3.name = serie3;
		series3.dataFields.categoryX = category;
		series3.dataFields.valueY = value3;
		series3.dataFields.valueYShow = 'totalPercent';
		series3.dataItems.template.locations.categoryX = 0.5;
		series3.stacked = true;
		series3.tooltip.pointerOrientation = 'vertical';

		const bullet3 = series3.bullets.push(new am4charts.LabelBullet());
		bullet3.interactionsEnabled = false;
		bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet3.label.fontSize = 9;
		bullet3.locationY = 0.5;
		bullet3.label.fill = am4core.color('#ffffff');

		const series4 = chart.series.push(new am4charts.ColumnSeries());

		series4.columns.template.width = am4core.percent(80);
		series4.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series4.columns.cursorTooltipEnabled = false;
		series4.tooltip.fontSize = 10;
		series4.tooltip.propertyFields.pointerOrientation = 'orientation';
		series4.calculatePercent = true;
		series4.legendSettings.valueText = `${percent4}% / {valueY.sum}`;

		series4.name = serie4;
		series4.dataFields.categoryX = category;
		series4.dataFields.valueY = value4;
		series4.dataFields.valueYShow = 'totalPercent';
		series4.dataItems.template.locations.categoryX = 0.5;
		series4.stacked = true;
		series4.tooltip.pointerOrientation = 'vertical';

		const bullet4 = series4.bullets.push(new am4charts.LabelBullet());
		bullet4.interactionsEnabled = false;
		bullet4.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet4.label.fontSize = 9;
		bullet4.locationY = 0.5;
		bullet4.label.fill = am4core.color('#ffffff');

		const series5 = chart.series.push(new am4charts.ColumnSeries());

		series5.columns.template.width = am4core.percent(80);
		series5.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series5.columns.cursorTooltipEnabled = false;
		series5.tooltip.fontSize = 10;
		series5.tooltip.propertyFields.pointerOrientation = 'orientation';
		series5.calculatePercent = true;
		series5.legendSettings.valueText = `${percent5}% / {valueY.sum}`;

		series5.name = serie5;
		series5.dataFields.categoryX = category;
		series5.dataFields.valueY = value5;
		series5.dataFields.valueYShow = 'totalPercent';
		series5.dataItems.template.locations.categoryX = 0.5;
		series5.stacked = true;
		series5.tooltip.pointerOrientation = 'vertical';

		const bullet5 = series5.bullets.push(new am4charts.LabelBullet());
		bullet5.interactionsEnabled = false;
		bullet5.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet5.label.fontSize = 9;
		bullet5.locationY = 0.5;
		bullet5.label.fill = am4core.color('#ffffff');

		const series6 = chart.series.push(new am4charts.ColumnSeries());

		series6.columns.template.width = am4core.percent(80);
		series6.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series6.columns.cursorTooltipEnabled = false;
		series6.tooltip.fontSize = 10;
		series6.tooltip.propertyFields.pointerOrientation = 'orientation';
		series6.calculatePercent = true;
		series6.legendSettings.valueText = `${percent6}% / {valueY.sum}`;

		series6.name = serie6;
		series6.dataFields.categoryX = category;
		series6.dataFields.valueY = value6;
		series6.dataFields.valueYShow = 'totalPercent';
		series6.dataItems.template.locations.categoryX = 0.5;
		series6.stacked = true;
		series6.tooltip.pointerOrientation = 'vertical';

		const bullet6 = series6.bullets.push(new am4charts.LabelBullet());
		bullet6.interactionsEnabled = false;
		bullet6.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet6.label.fontSize = 9;
		bullet6.locationY = 0.5;
		bullet6.label.fill = am4core.color('#ffffff');

		const series7 = chart.series.push(new am4charts.ColumnSeries());

		series7.columns.template.width = am4core.percent(80);
		series7.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series7.columns.cursorTooltipEnabled = false;
		series7.tooltip.fontSize = 10;
		series7.tooltip.propertyFields.pointerOrientation = 'orientation';
		series7.calculatePercent = true;
		series7.legendSettings.valueText = `${percent7}% / {valueY.sum}`;

		series7.name = serie7;
		series7.dataFields.categoryX = category;
		series7.dataFields.valueY = value7;
		series7.dataFields.valueYShow = 'totalPercent';
		series7.dataItems.template.locations.categoryX = 0.5;
		series7.stacked = true;
		series7.tooltip.pointerOrientation = 'vertical';

		const bullet7 = series7.bullets.push(new am4charts.LabelBullet());
		bullet7.interactionsEnabled = false;
		bullet7.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet7.label.fontSize = 9;
		bullet7.locationY = 0.5;
		bullet7.label.fill = am4core.color('#ffffff');

		const series8 = chart.series.push(new am4charts.ColumnSeries());

		series8.columns.template.width = am4core.percent(80);
		series8.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series8.columns.cursorTooltipEnabled = false;
		series8.tooltip.fontSize = 10;
		series8.tooltip.propertyFields.pointerOrientation = 'orientation';
		series8.calculatePercent = true;
		series8.legendSettings.valueText = `${percent8}% / {valueY.sum}`;

		series8.name = serie8;
		series8.dataFields.categoryX = category;
		series8.dataFields.valueY = value8;
		series8.dataFields.valueYShow = 'totalPercent';
		series8.dataItems.template.locations.categoryX = 0.5;
		series8.stacked = true;
		series8.tooltip.pointerOrientation = 'vertical';

		const bullet8 = series8.bullets.push(new am4charts.LabelBullet());
		bullet8.interactionsEnabled = false;
		bullet8.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet8.label.fontSize = 9;
		bullet8.locationY = 0.5;
		bullet8.label.fill = am4core.color('#ffffff');

		const series9 = chart.series.push(new am4charts.ColumnSeries());

		series9.columns.template.width = am4core.percent(80);
		series9.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series9.columns.cursorTooltipEnabled = false;
		series9.tooltip.fontSize = 10;
		series9.tooltip.propertyFields.pointerOrientation = 'orientation';
		series9.calculatePercent = true;
		series9.legendSettings.valueText = `${percent9}% / {valueY.sum}`;

		series9.name = serie9;
		series9.dataFields.categoryX = category;
		series9.dataFields.valueY = value9;
		series9.dataFields.valueYShow = 'totalPercent';
		series9.dataItems.template.locations.categoryX = 0.5;
		series9.stacked = true;
		series9.tooltip.pointerOrientation = 'vertical';

		const bullet9 = series9.bullets.push(new am4charts.LabelBullet());
		bullet9.interactionsEnabled = false;
		bullet9.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet9.label.fontSize = 9;
		bullet9.locationY = 0.5;
		bullet9.label.fill = am4core.color('#ffffff');

		const series10 = chart.series.push(new am4charts.ColumnSeries());

		series10.columns.template.width = am4core.percent(80);
		series10.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series10.columns.cursorTooltipEnabled = false;
		series10.tooltip.fontSize = 10;
		series10.tooltip.propertyFields.pointerOrientation = 'orientation';
		series10.calculatePercent = true;
		series10.legendSettings.valueText = `${percent10}% / {valueY.sum}`;

		series10.name = serie10;
		series10.dataFields.categoryX = category;
		series10.dataFields.valueY = value10;
		series10.dataFields.valueYShow = 'totalPercent';
		series10.dataItems.template.locations.categoryX = 0.5;
		series10.stacked = true;
		series10.tooltip.pointerOrientation = 'vertical';

		const bullet10 = series10.bullets.push(new am4charts.LabelBullet());
		bullet10.interactionsEnabled = false;
		bullet10.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet10.label.fontSize = 9;
		bullet10.locationY = 0.5;
		bullet10.label.fill = am4core.color('#ffffff');

		const series11 = chart.series.push(new am4charts.ColumnSeries());

		series11.columns.template.width = am4core.percent(80);
		series11.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series11.columns.cursorTooltipEnabled = false;
		series11.tooltip.fontSize = 10;
		series11.tooltip.propertyFields.pointerOrientation = 'orientation';
		series11.calculatePercent = true;
		series11.legendSettings.valueText = `${percent11}% / {valueY.sum}`;

		series11.name = serie11;
		series11.dataFields.categoryX = category;
		series11.dataFields.valueY = value11;
		series11.dataFields.valueYShow = 'totalPercent';
		series11.dataItems.template.locations.categoryX = 0.5;
		series11.stacked = true;
		series11.tooltip.pointerOrientation = 'vertical';

		const bullet11 = series11.bullets.push(new am4charts.LabelBullet());
		bullet11.interactionsEnabled = false;
		bullet11.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet11.label.fontSize = 9;
		bullet11.locationY = 0.5;
		bullet11.label.fill = am4core.color('#ffffff');

		const series12 = chart.series.push(new am4charts.ColumnSeries());

		series12.columns.template.width = am4core.percent(80);
		series12.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series12.columns.cursorTooltipEnabled = false;
		series12.tooltip.fontSize = 10;
		series12.tooltip.propertyFields.pointerOrientation = 'orientation';
		series12.calculatePercent = true;
		series12.legendSettings.valueText = `${percent12}% / {valueY.sum}`;

		series12.name = serie12;
		series12.dataFields.categoryX = category;
		series12.dataFields.valueY = value12;
		series12.dataFields.valueYShow = 'totalPercent';
		series12.dataItems.template.locations.categoryX = 0.5;
		series12.stacked = true;
		series12.tooltip.pointerOrientation = 'vertical';

		const bullet12 = series12.bullets.push(new am4charts.LabelBullet());
		bullet12.interactionsEnabled = false;
		bullet12.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet12.label.fontSize = 9;
		bullet12.locationY = 0.5;
		bullet12.label.fill = am4core.color('#ffffff');

		const series13 = chart.series.push(new am4charts.ColumnSeries());

		series13.columns.template.width = am4core.percent(80);
		series13.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% / {valueY}";

		series13.columns.cursorTooltipEnabled = false;
		series13.tooltip.fontSize = 10;
		series13.tooltip.propertyFields.pointerOrientation = 'orientation';
		series13.calculatePercent = true;
		series13.legendSettings.valueText = `${percent13}% / {valueY.sum}`;

		series13.name = serie13;
		series13.dataFields.categoryX = category;
		series13.dataFields.valueY = value13;
		series13.dataFields.valueYShow = 'totalPercent';
		series13.dataItems.template.locations.categoryX = 0.5;
		series13.stacked = true;
		series13.tooltip.pointerOrientation = 'vertical';

		const bullet13 = series13.bullets.push(new am4charts.LabelBullet());
		bullet13.interactionsEnabled = false;
		bullet13.label.text = "{valueY.totalPercent.formatNumber('#.00')}% / {valueY}";
		bullet13.label.fontSize = 9;
		bullet13.locationY = 0.5;
		bullet13.label.fill = am4core.color('#ffffff');
	}

	// Colum With Rotated
	setAxesColumnWithRotated(
		chart,
		category,
		text1,
		textTasksFinished,
		dateFromDateTo,
		textTotalTask,
		total,
		textTotalFinished,
		agentObjective
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = 'right';
		categoryAxis.renderer.labels.template.verticalCenter = 'middle';

		// categoryAxis.title.text = "[bold]"+textTasksFinished+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotalTask;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTasksFinished}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		if (textTotalFinished != undefined) {
			const axisTitle1 = topContainer.createChild(am4core.Label);
			axisTitle1.text = `[bold]${textTotalFinished}`;
			axisTitle1.fontWeight = 600;
			axisTitle1.align = 'center';
			axisTitle1.paddingTop = 25;
		}

		categoryAxis.renderer.labels.template.rotation = 270;
		categoryAxis.renderer.labels.template.fontSize = 10;
		categoryAxis.tooltip.disabled = true;
		categoryAxis.renderer.minHeight = 110;

		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		/* chart.events.on("ready", function(ev) {
        valueAxis.min = valueAxis.minZoomed;
        valueAxis.max = valueAxis.maxZoomed;
      }); */
		// valueAxis.renderer.minWidth = 50;
		valueAxis.title.text = text1;

		if (agentObjective != undefined) {
			const range = valueAxis.axisRanges.create();
			range.value = agentObjective;
			range.endValue = Number(agentObjective) + 1;
			range.axisFill.fill = am4core.color('#dc5c87');
			range.axisFill.fillOpacity = 0.8;
			range.grid.strokeOpacity = 0.1;
		}
	}

	setColumnWithRotatedSeriesParams(chart, category, valueY) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = valueY;
		series.dataFields.categoryX = category;
		series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
		series.columns.template.strokeWidth = 0;

		series.tooltip.pointerOrientation = 'vertical';

		series.columns.template.column.cornerRadiusTopLeft = 10;
		series.columns.template.column.cornerRadiusTopRight = 10;
		series.columns.template.column.fillOpacity = 0.8;

		// on hover, make corner radiuses bigger
		const hoverState = series.columns.template.column.states.create('hover');
		hoverState.properties.cornerRadiusTopLeft = 0;
		hoverState.properties.cornerRadiusTopRight = 0;
		hoverState.properties.fillOpacity = 1;

		series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = '{valueY.value}';
		bullet.label.fontSize = 12;
		bullet.label.fill = am4core.color('#000000');
		bullet.label.verticalCenter = 'bottom';
		bullet.label.dy = -9;
	}

	// Colum With Rotated Time
	setAxesColumnWithRotatedTime(
		chart,
		category,
		text1,
		baseUnit,
		textTitleGrah,
		dateFromDateTo,
		textTotal,
		total,
		textTotalFinished
	) {
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = category;
		// categoryAxis.renderer.grid.template.location = 0 ;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.horizontalCenter = 'right';
		categoryAxis.renderer.labels.template.verticalCenter = 'middle';
		categoryAxis.renderer.labels.template.rotation = 270;
		categoryAxis.renderer.labels.template.fontSize = 9;
		categoryAxis.tooltip.disabled = true;
		// categoryAxis.renderer.minHeight = 50;

		// categoryAxis.title.text = "[bold]"+textTitleGrah+"[/] "+dateFromDateTo+"   -   "+total+" "+textTotal;

		const topContainer = chart.chartContainer.createChild(am4core.Container);
		topContainer.layout = 'absolute';
		topContainer.toBack();
		topContainer.paddingBottom = 15;
		topContainer.width = am4core.percent(100);

		if (textTotalFinished != undefined) {
			const axisTitle1 = topContainer.createChild(am4core.Label);
			axisTitle1.text = `[bold]${textTotalFinished}`;
			axisTitle1.fontWeight = 600;
			axisTitle1.align = 'center';
			axisTitle1.paddingTop = 25;
		}

		const axisTitle = topContainer.createChild(am4core.Label);
		axisTitle.text = `[bold]${textTitleGrah}[/] ${dateFromDateTo}   -   ${total} ${textTotal}`;
		axisTitle.fontWeight = 600;
		axisTitle.align = 'center';
		axisTitle.paddingTop = 10;

		const valueAxis = chart.yAxes.push(new am4charts.DurationAxis());

		// Fix axis scale on load
		chart.events.on('ready', (ev) => {
			valueAxis.min = valueAxis.minZoomed;
			valueAxis.max = valueAxis.maxZoomed;
		});

		valueAxis.title.text = text1;
		valueAxis.baseUnit = baseUnit;
	}

	setColumnWithRotatedSeriesParamsTime(chart, category, valueY) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.sequencedInterpolation = true;
		series.dataFields.valueY = valueY;
		series.dataFields.categoryX = category;
		// series.tooltipText = "[{categoryX}: bold]{valueY}[/]";

		series.columns.template.tooltipText = '{valueY.formatDuration()}[/]';
		series.columns.template.strokeWidth = 0;

		series.tooltip.pointerOrientation = 'vertical';

		series.columns.template.column.cornerRadiusTopLeft = 10;
		series.columns.template.column.cornerRadiusTopRight = 10;
		series.columns.template.column.fillOpacity = 0.8;

		// on hover, make corner radiuses bigger
		const hoverState = series.columns.template.column.states.create('hover');
		hoverState.properties.cornerRadiusTopLeft = 0;
		hoverState.properties.cornerRadiusTopRight = 0;
		hoverState.properties.fillOpacity = 1;

		const bullet = series.bullets.push(new am4charts.LabelBullet());
		bullet.interactionsEnabled = false;
		bullet.label.text = 'ST: ' + '{valueY.formatDuration()}';
		bullet.label.fontSize = 8;
		bullet.label.fill = am4core.color('#000000');
		bullet.locationY = 1;
		bullet.dy = -50;
		bullet.label.paddingTop = 20;

		bullet.label.rotation = 270;

		series.columns.template.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index));
	}

	// Partitioned Bar
	setAxesPartitionedBar(chart, category) {
		const yAAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		yAAxis.dataFields.category = category;
		yAAxis.renderer.grid.template.location = 0;
		yAAxis.renderer.labels.template.fontSize = 10;
		yAAxis.renderer.minGridDistance = 10;
		yAAxis.dataItems.template.text = '{name}';
		const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
		return yAAxis;
	}

	setChartProperties(chart, legend, exportData, onlyExport) {
		if (exportData) {
			chart.exporting.menu = new am4core.ExportMenu();
			chart.exporting.menu.items = this.exportItems;
			chart.exporting.menu.items[0].icon = `${process.env.AWS_FIELDER_ICONS}import-icon.svg`;
		}

		if (!onlyExport) {
			chart.scrollbarX = new am4core.Scrollbar();
			// chart.scrollbarX.dragIconHeight = 11
			if (legend === 'false') {
				chart.cursor = new am4charts.XYCursor();
			}
			if (legend === 'true') {
				chart.legend = new am4charts.Legend();
				chart.legend.position = 'top';
			}
		}
	}

	setLineSeriesParams(s, chart, category) {
		const series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.valueY = s.value;
		series.dataFields.categoryX = category;
		series.name = s.name;
		series.tooltipText = '{valueY}';
		series.stacked = true;

		const bullet = series.bullets.push(new am4charts.Bullet());
		const circle = bullet.createChild(am4core.Circle);
		circle.stroke = am4core.color.next;
		circle.strokeWidth = 2;
		circle.fill = am4core.color('#fff');

		circle.width = 10;
		circle.height = 10;
	}

	setBarSeriesParams(s, chart, category) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = s.value;
		series.dataFields.categoryX = category;
		series.name = s.name;
		series.tooltipText = `{name}\n{categoryX}\n${s.value}: {valueY}`;
		series.stacked = true;
	}

	setPartitionedBarSeriesParams(chart, category, valueX, ranges) {
		const series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueX = valueX;
		series.dataFields.categoryY = category;
		series.columns.template.tooltipText = '{userFullName}: [bold]{valueX}[/]';
		series.columns.template.strokeWidth = 0;
		series.columns.template.adapter.add('fill', (fill, target) => {
			if (target.dataItem) {
				for (let i = 0; i < ranges.length; i++) {
					if (target.dataItem.dataContext.userFullName === ranges[i].region) {
						return chart.colors.getIndex(i);
					}
				}
			}
			return fill;
		});
	}

	addRange(label, start, end, color, yAAxis) {
		const range = yAAxis.axisRanges.create();
		range.category = start;
		range.endCategory = end;
		range.label.text = label;
		range.label.disabled = false;
		range.label.fill = color;
		range.label.location = 0;
		range.label.dx = -130;
		range.label.dy = 12;
		range.label.fontWeight = 'bold';
		range.label.fontSize = 12;
		range.label.horizontalCenter = 'left';
		range.label.inside = true;

		range.grid.stroke = am4core.color('#396478');
		range.grid.strokeOpacity = 1;
		range.tick.length = 200;
		range.tick.disabled = false;
		range.tick.strokeOpacity = 0.6;
		range.tick.stroke = am4core.color('#396478');
		range.tick.location = 0;

		range.locations.category = 1;
	}

	setPieSeriesParams(value, category, chart, color, labels, ticks, tooltip, legendPosition) {
		const pieSeries = chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = value;
		pieSeries.dataFields.category = category;
		if (color) {
			pieSeries.slices.template.propertyFields.fill = 'color';
		}
		pieSeries.labels.template.disabled = !labels;
		pieSeries.ticks.template.disabled = !ticks;

		chart.legend = new am4charts.Legend();
		chart.legend.position = legendPosition;

		const marker = chart.legend.markers.template.children.getIndex(0);
		marker.cornerRadius(12, 12, 12, 12);

		const markerTemplate = chart.legend.markers.template;
		markerTemplate.width = 10;
		markerTemplate.height = 10;

		chart.legend.itemContainers.template.paddingTop = 3;
		chart.legend.itemContainers.template.paddingBottom = 3;

		// Disable tooltips
		if (!tooltip) {
			pieSeries.slices.template.tooltipText = '';
		}
	}

	setPyramidSeriesParams(value, category, chart, color, labels, ticks, tooltip, legendPosition) {
		const series = chart.series.push(new am4charts.PyramidSeries());
		series.dataFields.value = value;
		series.dataFields.category = category;
		series.alignLabels = true;
		series.valueIs = 'height';
		if (color) {
			series.slices.template.propertyFields.fill = 'color';
		}

		series.labels.template.disabled = true;
		series.ticks.template.disabled = true;
	}

	setPieSeriesParamsAnalytics(
		chart,
		category,
		valueY,
		textServiceStatus,
		dateFromDateTo,
		total,
		textTotalTask,
		textTotalFinished,
		props
	) {
		// Add and configure Series
		if (textTotalFinished != undefined) {
			const title = chart.titles.create();
			title.text = `[bold]${textTotalFinished}`;
			title.fontSize = 13;
			title.marginBottom = 20;
		}

	

		const title2 = chart.titles.create();
		title2.text = `[bold]${textServiceStatus}[/] ${dateFromDateTo}   -   ${total} ${textTotalTask}`;
		title2.fontSize = 13;
		title2.marginBottom = 20;

		const pieSeries = chart.series.push(new am4charts.PieSeries());

		pieSeries.dataFields.value = valueY;
		pieSeries.dataFields.category = category;
		pieSeries.slices.template.stroke = am4core.color('#fff');
		pieSeries.slices.template.strokeWidth = 2;
		pieSeries.slices.template.strokeOpacity = 1;

		if (props.enableOnClick) {
			const infoData = chart.data;

			let slice = pieSeries.slices.template;
			slice.states.getKey("hover").properties.scale = 1;
			slice.states.getKey("active").properties.shiftRadius = 0;

			pieSeries.slices.template.events.on("hit", function (ev) {

				for (let i = 0; i < infoData.length; ++i) {
					if (ev && ev.target && ev.target._dataItem && ev.target._dataItem.properties && ev.target._dataItem.properties.category) {
						let categoryGraph = ev.target._dataItem.properties.category;
						if (categoryGraph == infoData[i].label) {
							props.showTaskOnColumn(infoData[i].tasks, categoryGraph)
						}
					}
				}
			}, this);
		}
	}

	getChartLabel = (chartData, label) => {
		
		label.horizontalCenter = 'middle';
		label.verticalCenter = 'middle';
		label.fontSize = 20;
		label.fontFamily = 'Lato';
	
		if (chartData.length > 0) {
			label.text = chartData.reduce(
				(prev, curr) => parseInt(prev?.count ? prev.count : prev) + parseInt(curr.count)
			);
		} else if (chartData.length === 0) {
			label.text = 0;
		} else {
			label.text = '';
		}
	
		if (isNaN(label.text)) {
			label.text = '';
		}
	};


}

export default Graph;
