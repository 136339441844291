import React, { Component } from 'react';

import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import { Modal } from 'react-bootstrap';

import './SuccessMessage.css';
import 'FielderUtils/style.css';

class SuccessMessage extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		this.props.onClose();
	}

	render() {
		return (
			<Modal
				id='SuccessMessage-modal'
				size='sm'
				show={this.props.showSuccessMessage}
				onHide={this.handleClose}
				className='Fielder-modals-header-border Fielder-modals-content-border'
			>
				<Modal.Body className='success-message-modal-body text-center mt-4'>
					{this.props.icon == null ? (
						<img src={SuccessIcon} className='success-message-center-icon' alt='' />
					) : (
						<img src={this.props.icon} className='success-message-center-icon' alt='' />
					)}

					<div
						id='SuccessMessage-message'
						className='text-center ml-2 mr-2 mt-3 mb-3 success-message-margin'
					>
						{this.props.message}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<div className='mt-3'>
						<button
							id='SuccessMessage-btn'
							type='submit'
							className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size success-message-btn-margin'
							onClick={this.handleClose}
						>
							{this.props.lan.accept}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SuccessMessage;
