import { ICoordinates } from '@core/models/coordinates.model';

type BaseRouteProps = {
	weight: string | number;
	color?: string;
	fillColor?: string;
	geodesic: boolean;
};

type SingleRouteProps = {
	points: ICoordinates[];
} & BaseRouteProps;

const defaultProps = {
	weight: 5,
	geodesic: false,
};

const StaticRouteGroup = (props: BaseRouteProps) => null;
StaticRouteGroup.defaultProps = defaultProps;

const StaticRoute = (props: SingleRouteProps) => null;
StaticRoute.defaultProps = defaultProps;
StaticRoute.Group = StaticRouteGroup;

export default StaticRoute;
