import { searchBranchesByFilter } from '@core/api/branch/branch.service';

export const formatBranches = (branches) =>
	branches.map((branch) => {
		let companyName = '';
		let customCompanyId = '';
		let idCustom = '';

		if (branch.company && branch.company.idCustom) {
			customCompanyId = `[${branch.company.idCustom}] - `;
		}
		if (branch.company && branch.company.name) {
			companyName = branch.company.name;
		}

		idCustom = branch.idCustom ? `${branch.idCustom} - ` : '';

		return {
			label: `${idCustom}${branch.name} (${customCompanyId}${companyName})`,
			value: branch,
		};
	});

export const getBranchesByFilter = (filter) => {
	const body = { filter };

	const onError = (error) => [];
	const onSuccess = (res) => res.branches;
	return searchBranchesByFilter(body, onSuccess, onError);
};

export const getBranchesByFilterAsyncSelect = async (filter) => {
	const branches = await getBranchesByFilter(filter);
	return formatBranches(branches);
};
