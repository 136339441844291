import React, { Component } from 'react';

import WaningToast from '@assets/icons/waning-toast-icon.svg';
import {
	getCompaniesByFilterAsyncSelectCreateTask,
	getCompaniesInitialOptionsAsyncSelect,
} from 'FielderUtils/company/company';
import { updateActions, monthTaskActions, agentUpdateActions } from '@core/constants/realTime/pages/dashboard';
import { getCookiesRequest, updateCookiesRequest } from '@core/api/cookie/cookie.service';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { ToastContainer, toast } from 'react-toastify';
import TaskDetails from '../TaskDetails';
import TaskCompanyBranchGraph from './TaskCompanyBranchGraph';
import TaskCompanyBranchResume from './TaskCompanyBranchResume';
import TaskSlaComponent from './TaskSlaComponent';
import TasksMonthComponent from './TasksMonthComponent';
import moment from 'moment';
import pages from '../../client/utils/pages/pages';
import { getObjectCookie } from 'FielderUtils/cookie/cookieUtil';

import { getWSService } from '../../services/WebSocket';
import WS_ROUTE_NAME from '../../services/WebSocketRouteNames';
import { TaskPlan } from '@core/constants/task/colorCodesPlan.constants';
import { agentHeadersDashboards, companyHeadersDashboard, slaTaskCompanyHeaders } from 'FielderUtils/excel/excelHeader';

import './DashboardStyles.css';
import 'react-toastify/dist/ReactToastify.css';
import {
	getDailyBranchesTasksRequest,
	getDailyCompaniesTasksRequest,
	getDailyCompanySLATasks,
	getMonthlyTasksDashboardRequest,
} from '@core/api/dashboard/dashboard.service';
import { getBranchesFromCompany } from '@core/api/branch/branch.service';

var cookieName = 'DashboardTaskSummaryFilter';

class DashboardComponent extends Component {
	constructor(props) {
		super(props);

		let startDate = new Date();
		startDate.setHours(0, 0, 0, 0);

		let endDate = new Date();
		endDate.setHours(23, 59, 59, 59);

		this.state = {
			data: props,

			idCompany: null,
			currentCompany: null,
			currentCompanies: null,

			taskStatusDaily: {
				countTotalTask: null,
				countProgressTask: null,
				countFinishTask: null,
				countRejectedTask: null,
			},
			accountAgents: [],
			originalAgents: [],
			agents: {
				totalAgents: null,
				agentsOnline: null,
				offlineAgents: null,
			},
			tasksMonth: {
				countTotalMonthTask: 0,
				countGeneratedMonthTask: 0,
			},
			showAlertMonthTask: false,
			chartDataPie: [],
			tasksCompanyPieParams: {
				category: 'statusTask',
				id: 'pieTaskDashboard',
				legend: 'false',
				total: 0,
				value: 'totalCount',
			},

			chartData: [],
			averageSatisfaction: 0,
			showAgentsTable: false,

			totalAgentsOriginal: 0,
			totalAgentsTable: 0,
			disableAgentTableButton: false,
			changedMapPosition: false,
			showDetailAgent: false,
			idAgent: null,
			idTask: null,
			renderDailyTask: false,
			renderDailyCompanyTask: false,
			renderDailyCompanyTaskSla: false,
			renderCompletedTask: false,
			renderAgentComponent: false,
			renderMonthTask: false,
			showLoadingRefresh: false,
			filter: null,
			loadingFilter: false,
			idAccount: this.props.session.user.idAccount,
			idUser: this.props.session.user.idUser,
			user: this.props.session.user,
			alertFilterText: '',
			showAlertTextFilter: false,
			showCVS: false,
			exportableData: [],
			exportableDataHeaders: agentHeadersDashboards(this.props.lan),
			exportableDataCompanyHeaders: companyHeadersDashboard(this.props.lan),
			exportableDataSlaTaskCompanyHeaders: slaTaskCompanyHeaders(this.props.lan),
			totalCountGraph: 0,
			updateViewSelect: true,
			cookieStorageFilter: [],
			showCookies: false,

			defaultCompanies: [],
			dailyTaskByCompanies: [],
			branchesByCompany: [],
			showBranches: false,
			idBranches: null,
			idCompanies: null,
			dailyTaskCompanySla: [],
			slaTime: 1,

			filterDates: null,
			statusAgent: 1,

			startDate: startDate.getTime(),
			endDate: endDate.getTime(),

			taskPlanData: {},
		};
	}

	componentDidMount = () => {
		//Initialize the socket
		getWSService();

		//Add socket listener
		getWSService().addRealTimeListener(this.realTimeActionHandler);

		/* GET DAILY TASK BY COMPANY */
		this.executeDailyCompanyTask();

		/* GET DAILY TASK SLA */
		this.execueteDailyCompanyTaskSla();

		/* GET MONTH TASK */
		this.executeDashboardMonthTask();

		//Add connection to dynamo table
		this.updateSessionInfoWebSocket(this.state.idUser, this.state.idAccount);
		scroll.scrollToTop();

		//Get cookies
		this.getCookiesFilter();

		window.addEventListener('beforeunload', this.componentCleanup);
	};

	getCookiesFilter = () => {
		let cookieStorageFilter = [];

		if (
			localStorage.getItem(cookieName) !== undefined &&
			localStorage.getItem(cookieName) !== null &&
			JSON.parse(localStorage.getItem(cookieName)).length > 0
		) {
			let storageValue = localStorage.getItem(cookieName);
			let cookieStorageFilterParse = JSON.parse(storageValue);
			cookieStorageFilter = cookieStorageFilterParse;

			this.setState({ cookieStorageFilter: cookieStorageFilter }, () => this.setState({ showCookies: true }));
		} else {
			const body = {
				filterCookie: {
					cookieName: cookieName,
				},
			};

			const onSuccess = (response) => {
				let dashboardSummaryTaskCookie = response.dashboardSummaryTaskCookie;
				if (dashboardSummaryTaskCookie && dashboardSummaryTaskCookie.length > 0) {
					localStorage.setItem(cookieName, JSON.stringify(dashboardSummaryTaskCookie));
					cookieStorageFilter = dashboardSummaryTaskCookie;
					this.setState({ cookieStorageFilter: cookieStorageFilter }, () => this.setState({ showCookies: true }));
				}
			};

			getCookiesRequest(body, onSuccess, null, null);
		}
	};

	componentCleanup = () => {
		if (this.props.session) {
			const idUser = this.props.session.user.idUser;
			const idAccount = this.props.session.user.idAccount;
			getWSService().deleteConnectionUserInfo(WS_ROUTE_NAME.DELETE_CONNECTION, idUser, idAccount, pages.Dashboard);
		}
	};

	//Remove connection from dynamo table when leaving the page
	componentWillUnmount() {
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup);
	}

	realTimeActionHandler = (message) => {
		const { action } = message;

		if (updateActions.has(action)) {
			this.executeDailyCompanyTask();
			this.execueteDailyCompanyTaskSla();
		} else if (agentUpdateActions.has(action)) {
			let filter = null;
			if (
				document.getElementById('filter') &&
				document.getElementById('filter').value != undefined &&
				document.getElementById('filter').value != null &&
				document.getElementById('filter').value != ''
			) {
				filter = document.getElementById('filter').value;
			}
			this.executeFilterAgent(filter);
		} else if (monthTaskActions.has(action)) {
			this.executeDashboardMonthTask();
		}
	};

	//Add connection to dynamo table
	updateSessionInfoWebSocket = (idUser, idAccount) => {
		setTimeout(() => {
			getWSService().updateConnectionUserInfo(WS_ROUTE_NAME.UPDATE_CONNECTION, idUser, idAccount, pages.Dashboard);
		}, 3000);
	};

	manageGetDashboardMonthTaskRequest = (response) => {
		let showAlertMonthTask = false;
		if (response.countGeneratedMonthTask > response.countTotalMonthTask) {
			showAlertMonthTask = true;
		}

		let remainingTasks = response.countTotalMonthTask - response.countGeneratedMonthTask;

		this.setState({
			taskPlanData: {
				id: 'TaskMonth',
				labels: [this.props.lan.taskPlan],
				datasets: [
					{
						label: this.props.lan.completedTasks,
						backgroundColor: TaskPlan.generated.chart,
						data: [response.countGeneratedMonthTask],
						barPercentage: 0.5,
					},
					{
						label: this.props.lan.remainingTasks,
						backgroundColor: TaskPlan.plan.chart,
						data: [remainingTasks],
						barPercentage: 0.5,
					},
				],
			},
			tasksMonth: {
				countTotalMonthTask: response.countGeneratedMonthTask,
				countGeneratedMonthTask: response.countTotalMonthTask,
			},
			showAlertMonthTask: showAlertMonthTask,
			renderMonthTask: true,
		});
	};

	notify = () => {
		const Img = ({ src }) => (
			<div className='row'>
				<div className='col-auto'>
					{' '}
					<img width={38} src={WaningToast} alt='' />
				</div>
				<div className='col-auto d-flex justify-content-center align-self-center'>
					{this.props.lan.messageAgentDashboard}
				</div>{' '}
			</div>
		);

		toast(<Img />, {
			position: toast.POSITION.BOTTOM_CENTER,
			hideProgressBar: true,
			bodyClassName: 'toast-font-style',
			autoClose: 4500,
		});
	};

	showMessageNoGeolocationAgent = () => this.notify();

	getCompanyList = async (event) => {
		if (event.length > 0) {
			let assignList = [];
			assignList = await getCompaniesByFilterAsyncSelectCreateTask(event);
			this.setState({ companyListArray: assignList });
			return assignList;
		}
	};

	getInitialOptionsCompany = async () =>  await getCompaniesInitialOptionsAsyncSelect();

	handleSelectBranch = (event) => {
		if (event) {
			let idBranches = event.map((element) => element.value.id);

			this.setState(
				{
					idBranches: idBranches,
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		} else {
			this.setState(
				{
					idBranches: null,
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		}
	};

	getBranchSelectElement = (branch) => {
		let companyName;
		let idCompany;
		let idCustomBranch = '';

		if (branch.company) {
			idCompany = branch.company.idCustom ? branch.company.idCustom : '';
			companyName = branch.company.name ? branch.company.name : '';
		}

		if (branch.idCustom) {
			idCustomBranch = branch.idCustom;
		}

		return {
			label: `[${branch.id}] ${idCustomBranch} - ${branch.name} ([${idCompany}] - ${companyName})`,
			value: branch,
		};
	};

	getCompanyBranches = (companyId) => {
		const body = { idCompany: companyId };

		const onSuccess = ({ branches }) => {
			const branchesByCompany = branches.map((branch) => this.getBranchSelectElement(branch));
			this.setState({
				branchesByCompany: branchesByCompany,
				showBranches: true,
			});
		};

		getBranchesFromCompany(body, onSuccess);
	};

	setCompanyIds = (event) => {
		let idCompanies = [];
		let currentCompanies = [];

		if (event && event.length === 1) {
			this.getCompanyBranches(event[0].value.id);
		} else {
			this.setState({
				branchesByCompany: [],
				showBranches: false,
				idBranches: null,
			});
		}

		if (event) {
			for (let i = 0; i < event.length; i++) {
				idCompanies.push(event[i].value.id);

				let company = {
					label: event[i].label,
					value: {
						id: event[i].value.id,
						name: event[i].value.name,
						idCustom: event[i].value.idCustom,
					},
				};

				currentCompanies.push(company);
			}
			this.setState(
				{
					idCompanies: idCompanies,
					currentCompanies: currentCompanies,
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		} else {
			this.setState(
				{
					idCompanies: null,
					currentCompanies: null,
					idBranches: null,
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		}
	};

	execueteDailyCompanyTaskSla = () => {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear();
		var currentMonth = currentDate.getMonth();
		var currentDay = currentDate.getDate();

		const body = {
			currentYear: currentYear,
			currentMonth: currentMonth,
			currentDay: currentDay,
			idCompanies: this.state.idCompanies,
			idBranches: this.state.idBranches,
			slaTime: this.state.slaTime,
		};

		getDailyCompanySLATasks(body, this.manageDashboardDailyCompanyTaskSla);
	};

	manageDashboardDailyCompanyTaskSla = (response) => {
		let dailyTasksByCompany = response.dailyTasksByLimit;
		let dailyTaskCompany = [];

		if (dailyTasksByCompany) {
			dailyTasksByCompany.forEach((task) => {
				const timeZone = task.startLocation ? task.startLocation.timeZone : 'x';

				let agetCommentText = '';
				let agetComment = task.comments;
				if (agetComment && agetComment.length > 0) {
					agetCommentText = agetComment[agetComment.length - 1].comment;
				}
				let rejectReasonText = '';
				let pauseReasonText = '';
				if (task.rejectTaskComments && task.rejectTaskComments.rejectTaskReason) {
					let rejectTaskReason = task.rejectTaskComments.rejectTaskReason;
					let parentReasonReject =
						rejectTaskReason.parentReason == 0 ? this.props.lan.rejectedByAgent : this.props.lan.rejectedByFinalClient;
					rejectReasonText = parentReasonReject + ' / ' + rejectTaskReason.reasonName;
				}
				if (task.pauseTaskComments && task.pauseTaskComments.pauseTaskReason) {
					let pauseTaskReason = task.pauseTaskComments.pauseTaskReason;
					let parentReasonPause =
						pauseTaskReason.parentReason == 0 ? this.props.lan.pausedByAgent : this.props.lan.pausedByFinalClient;
					pauseReasonText = parentReasonPause + ' / ' + pauseTaskReason.reasonName;
				}

				dailyTaskCompany.push({
					idFielder: task.idTask,
					folio: task.folio,
					company: task.company ? task.company.id + ' ' + task.company.name : '',
					branch: task.branch ? task.branch.id + ' ' + task.branch.name : '',
					status: this.props.lan['taskStatus_' + task.status],
					startTime: moment.tz(task.startTime, timeZone).format('DD-MM-YYYY hh:mm a'),
					description: task.description,
					agent: task.agent ? task.agent.idAgent + ' ' + task.agent.name + ' ' + task.agent.lastName : '',
					lastAgentComment: agetCommentText,
					rejectionReason: rejectReasonText,
					pausedReason: pauseReasonText,
				});
			});
		}

		this.setState({
			dailyTaskCompanySla: dailyTaskCompany,
			renderDailyCompanyTaskSla: true,
			exportableDataSlaTaskCompany: dailyTaskCompany,
		});
	};

	executeDailyBranchTask = (idCompany) => {
		const body = {
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			idCompanies: [idCompany],
			idBranches: this.state.idBranches,
		};

		getDailyBranchesTasksRequest(body, this.manageDashboardDailyCompanyTask, console.log);
	};

	executeDailyCompanyTask = () => {
		const body = {
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			idCompanies: this.state.idCompanies,
			idBranches: this.state.idBranches,
		};

		getDailyCompaniesTasksRequest(body, this.manageDashboardDailyCompanyTask, console.log);
	};

	manageDashboardDailyCompanyTask = (response) => {
		
		let dailyTaskByCompanies = response.dailyTaskByCompanies;
		let dailyTaskByBranches = response.dailyTaskByBranches;
		let companyRowsTable = [];

		let grandTotalStartRoute = 0;
		let grandTotalCancel = 0;
		let grandTotalExecuting = 0;
		let grandTotalInProgress = 0;
		let grandTotalWaitingAgent = 0;
		let grandTotalAssignedToTeam = 0;
		let grandTotalAssignedToPartner = 0;
		let grandTotalManaging = 0;
		let grandTotalArrived = 0;
		let grandTotalPause = 0;
		let grandTotalFinish = 0;
		let grandTotalCount = 0;

		if (dailyTaskByCompanies) {
			dailyTaskByCompanies.forEach((company) => {
				if (company.id > 0) {
					grandTotalStartRoute = grandTotalStartRoute + company.totalStartRoute;
					grandTotalCancel = grandTotalCancel + company.totalCancel;
					grandTotalExecuting = grandTotalExecuting + company.totalExecuting;
					grandTotalInProgress = grandTotalInProgress + company.totalInProgress;
					grandTotalWaitingAgent = grandTotalWaitingAgent + company.totalWaitingAgent;
					grandTotalAssignedToTeam = grandTotalAssignedToTeam + company.totalAssignedToTeam;
					grandTotalAssignedToPartner = grandTotalAssignedToPartner + company.totalAssignedToPartner;
					grandTotalManaging = grandTotalManaging + company.totalManaging;
					grandTotalArrived = grandTotalArrived + company.totalArrived;
					grandTotalPause = grandTotalPause + company.totalPause;
					grandTotalFinish = grandTotalFinish + company.totalFinish;

					grandTotalCount =
						grandTotalCount +
						company.totalStartRoute +
						company.totalCancel +
						company.totalExecuting +
						company.totalInProgress +
						company.totalWaitingAgent +
						company.totalAssignedToTeam +
						company.totalAssignedToPartner +
						company.totalManaging +
						company.totalArrived +
						company.totalPause +
						company.totalFinish;

					companyRowsTable.push({
						companyName: company.id + ' ' + company.name,
						totalStartRoute: company.totalStartRoute,
						totalCancel: company.totalCancel,
						totalExecuting: company.totalExecuting,
						totalInProgress: company.totalInProgress,
						totalWaitingAgent: company.totalWaitingAgent,
						totalAssignedToTeam: company.totalAssignedToTeam,
						totalAssignedToPartner: company.totalAssignedToPartner,
						totalManaging: company.totalManaging,
						totalArrived: company.totalArrived,
						totalPause: company.totalPause,
						totalFinish: company.totalFinish,
						totalCount:
							company.totalStartRoute +
							company.totalCancel +
							company.totalExecuting +
							company.totalInProgress +
							company.totalWaitingAgent +
							company.totalAssignedToTeam +
							company.totalAssignedToPartner +
							company.totalManaging +
							company.totalArrived +
							company.totalPause +
							company.totalFinish,
					});
				}
			});
		}

		if (dailyTaskByBranches) {
			dailyTaskByBranches.forEach((branch) => {
				companyRowsTable.push({
					companyName: '*' + branch.id + ' ' + branch.name,
					totalStartRoute: branch.totalStartRoute,
					totalCancel: branch.totalCancel,
					totalExecuting: branch.totalExecuting,
					totalInProgress: branch.totalInProgress,
					totalWaitingAgent: branch.totalWaitingAgent,
					totalAssignedToTeam: branch.totalAssignedToTeam,
					totalAssignedToPartner: branch.totalAssignedToPartner,
					totalManaging: branch.totalManaging,
					totalArrived: branch.totalArrived,
					totalPause: branch.totalPause,
					totalFinish: branch.totalFinish,
					totalCount:
						branch.totalStartRoute +
						branch.totalCancel +
						branch.totalExecuting +
						branch.totalInProgress +
						branch.totalWaitingAgent +
						branch.totalAssignedToTeam +
						branch.totalAssignedToPartner +
						branch.totalManaging +
						branch.totalArrived +
						branch.totalPause +
						branch.totalFinish,
				});
			});
		}

		companyRowsTable.push({
			companyName: this.props.lan.grandTotal,
			totalStartRoute: grandTotalStartRoute,
			totalCancel: grandTotalCancel,
			totalExecuting: grandTotalExecuting,
			totalInProgress: grandTotalInProgress,
			totalWaitingAgent: grandTotalWaitingAgent,
			totalAssignedToTeam: grandTotalAssignedToTeam,
			totalAssignedToPartner: grandTotalAssignedToPartner,
			totalManaging: grandTotalManaging,
			totalArrived: grandTotalArrived,
			totalPause: grandTotalPause,
			totalFinish: grandTotalFinish,
			totalCount: grandTotalCount,
		});

		const chartDataPie = [
			{ statusTask: this.props.lan.taskStatus_0, totalCount: grandTotalManaging },
			{ statusTask: this.props.lan.taskStatus_3, totalCount: grandTotalWaitingAgent },
			{ statusTask: this.props.lan.taskStatus_9, totalCount: grandTotalAssignedToTeam },
			{ statusTask: this.props.lan.taskStatus_8, totalCount: grandTotalAssignedToPartner },
			{ statusTask: this.props.lan.taskStatus_4, totalCount: grandTotalInProgress },
			{ statusTask: this.props.lan.taskStatus_6, totalCount: grandTotalStartRoute },
			{ statusTask: this.props.lan.taskStatus_10, totalCount: grandTotalArrived },
			{ statusTask: this.props.lan.taskStatus_5, totalCount: grandTotalExecuting },
			{ statusTask: this.props.lan.taskStatus_15, totalCount: grandTotalPause },
			{ statusTask: this.props.lan.taskStatus_1, totalCount: grandTotalCancel },
			{ statusTask: this.props.lan.taskStatus_2, totalCount: grandTotalFinish },
		];

		this.setState({
			dailyTaskByCompanies: companyRowsTable,
			chartDataPie: chartDataPie,
			renderDailyCompanyTask: true,
			totalCountGraph: grandTotalCount,
		});
	};

	generateCompanyLabel = (currentCompany) => {
		let label = currentCompany.id + ' ' + currentCompany.name;
		if (currentCompany.idCustom) {
			label += ' (' + currentCompany.idCustom + ')';
		}
		var returnCurrentCompany = [
			{
				label: label,
				value: currentCompany,
			},
		];
		return returnCurrentCompany;
	};

	saveFilterCompay = () => {
		var currentCompanies = this.state.currentCompanies;

		if (currentCompanies && currentCompanies != null) {
			if (
				localStorage.getItem(cookieName) !== undefined &&
				localStorage.getItem(cookieName) !== null &&
				JSON.parse(localStorage.getItem(cookieName)).length > 0
			) {
				let storageValue = localStorage.getItem(cookieName);
				let cookieStorageFilter = JSON.parse(storageValue);

				let filterName = this.props.lan.filter + (cookieStorageFilter.length + 1);
				if (document.getElementById('txt-filter-name').value && document.getElementById('txt-filter-name').value != '') {
					filterName = document.getElementById('txt-filter-name').value;
				}

				var filterStorage = {
					name: filterName,
					currentCompanies: currentCompanies,
					rangeDates: this.state.filterDates,
				};
				cookieStorageFilter.push(filterStorage);
				localStorage.setItem(cookieName, JSON.stringify(cookieStorageFilter));

				const body = getObjectCookie(cookieName, cookieStorageFilter);
				updateCookiesRequest(body);

				this.setState({ cookieStorageFilter: cookieStorageFilter });
			} else {
				let filterName = this.props.lan.filter + '1';
				if (document.getElementById('txt-filter-name').value && document.getElementById('txt-filter-name').value != '') {
					filterName = document.getElementById('txt-filter-name').value;
				}

				var filterStorage = [
					{
						name: filterName,
						currentCompanies: currentCompanies,
						rangeDates: this.state.filterDates,
					},
				];

				localStorage.setItem(cookieName, JSON.stringify(filterStorage));

				const body = getObjectCookie(cookieName, filterStorage);
				updateCookiesRequest(body);

				this.setState({ cookieStorageFilter: filterStorage });
			}

			this.setState({
				alertFilterText: this.props.lan.savedFilter,
				showAlertTextFilter: true,
			});
		}
	};

	removeFilter = (index) => {
		if (localStorage.getItem(cookieName) !== undefined && localStorage.getItem(cookieName) !== null) {
			let storageValue = localStorage.getItem(cookieName);
			let cookieStorageFilter = JSON.parse(storageValue);
			cookieStorageFilter.splice(index, 1);

			localStorage.setItem(cookieName, JSON.stringify(cookieStorageFilter));

			const body = getObjectCookie(cookieName, cookieStorageFilter);
			updateCookiesRequest(body);

			this.setState({ cookieStorageFilter: cookieStorageFilter });
		}
	};

	loadAdvancedFilters = (index) => {
		if (localStorage.getItem(cookieName) !== undefined && localStorage.getItem(cookieName) !== null) {
			this.state.updateViewSelect = false;
			let storageValue = localStorage.getItem(cookieName);
			let cookieStorageFilter = JSON.parse(storageValue);

			let currentCompanyCookie = cookieStorageFilter[index].currentCompanies;

			let rangeDates = null;
			let formatRangeDates = null;

			let startDate = new Date();
			startDate.setHours(0, 0, 0, 0);

			let endDate = new Date();
			endDate.setHours(23, 59, 59, 59);

			if (cookieStorageFilter[index].rangeDates) {
				rangeDates = cookieStorageFilter[index].rangeDates;
				formatRangeDates = [moment(rangeDates[0]), moment(rangeDates[1])];

				startDate = new Date(moment(rangeDates[0].valueOf()));
				startDate.setHours(0, 0, 0, 0);

				endDate = new Date(moment(rangeDates[1].valueOf()));
				endDate.setHours(23, 59, 59, 59);
			}

			let idCompanies = [];
			for (let i = 0; i < currentCompanyCookie.length; i++) {
				idCompanies.push(currentCompanyCookie[i].value.id);
			}

			if (currentCompanyCookie.length === 1) {
				this.getCompanyBranches(currentCompanyCookie[0].value.id);
			} else {
				this.setState({
					branchesByCompany: [],
					showBranches: false,
					idBranches: null,
				});
			}

			this.setState(
				{
					idCompanies: idCompanies,
					defaultCompanies: currentCompanyCookie,
					startDate: startDate.getTime(),
					endDate: endDate.getTime(),
					filterDates: formatRangeDates,
				},
				() => {
					this.setState({ updateViewSelect: true }), this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		}
	};

	downloadExcelDailyCompany = () => {
		let dailyCompanies = this.state.dailyTaskByCompanies;
		this.setState({ exportableDataCompany: dailyCompanies }, () => {
			document.getElementById('myExcellCompany').click();
		});
	};

	downloadExcelDailyCompanySla = () => {
		let dailyCompanies = this.state.dailyTaskCompanySla;
		this.setState({ exportableDataSlaTaskCompany: dailyCompanies }, () => {
			document.getElementById('myExcellCompanySla').click();
		});
	};

	closeFilterAlert = () => this.setState({showAlertTextFilter: false});

	changeDates = (newDates) => {
		if (newDates != null) {
			let startDate = new Date(newDates[0].valueOf());
			startDate.setHours(0, 0, 0, 0);

			let endDate = new Date(newDates[1].valueOf());
			endDate.setHours(23, 59, 59, 59);

			this.setState(
				{
					startDate: startDate.getTime(),
					endDate: endDate.getTime(),
					filterDates: [moment(newDates[0].valueOf()), moment(newDates[1].valueOf())],
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		} else {
			let startDate = new Date();
			startDate.setHours(0, 0, 0, 0);

			let endDate = new Date();
			endDate.setHours(23, 59, 59, 59);

			this.setState(
				{
					startDate: startDate.getTime(),
					endDate: endDate.getTime(),
					filterDates: null,
				},
				() => {
					this.executeDailyCompanyTask();
					this.execueteDailyCompanyTaskSla();
				}
			);
		}
	};

	executeDashboardMonthTask = () => getMonthlyTasksDashboardRequest(this.manageGetDashboardMonthTaskRequest);

	handleTaskSLA = (value) => {
		this.setState(
			{
				renderDailyCompanyTaskSla: false,
				slaTime: value,
			},
			() => this.execueteDailyCompanyTaskSla()
		);
	};

	handleTaskSLA = (value) => {
		this.setState(
			{
				renderDailyCompanyTaskSla: false,
				slaTime: value,
			},
			() => this.execueteDailyCompanyTaskSla()
		);
	};

	render() {
		return (
			<React.Fragment>
				<div className='container-fluid'>
					{this.state.showDetailTask && (
						<TaskDetails
							{...this.props}
							onClose={() => {
								this.setState({ showDetailTask: false, idTask: null });
							}}
							onCloseModal={(res, res1) => this.closeEditTaskPopup(res, res1)}
							idTasks={[this.state.idTask]}
							canNotEdit={true}
						/>
					)}

					<div className='row'>
						<TaskCompanyBranchResume
							lan={this.props.lan}
							renderDailyCompanyTask={this.state.renderDailyCompanyTask}
							dailyTaskByCompanies={this.state.dailyTaskByCompanies}
							setCompanyIds={this.setCompanyIds}
							getCompanyList={this.getCompanyList}
							getInitialOptionsCompany={this.getInitialOptionsCompany}
							saveFilter={this.saveFilterCompay}
							removeFilter={this.removeFilter}
							loadAdvancedFilters={this.loadAdvancedFilters}
							updateViewSelect={this.state.updateViewSelect}
							cookieStorageFilter={this.state.cookieStorageFilter}
							showCookies={this.state.showCookies}
							defaultCompanies={this.state.defaultCompanies}
							alertFilterText={this.state.alertFilterText}
							showAlertTextFilter={this.state.showAlertTextFilter}
							closeFilterAlert={this.closeFilterAlert}
							handleSelectBranch={this.handleSelectBranch}
							branchesByCompany={this.state.branchesByCompany}
							showBranches={this.state.showBranches}
							exportableDataCompanyHeaders={this.state.exportableDataCompanyHeaders}
							exportableDataCompany={this.state.exportableDataCompany}
							downloadExcelDailyCompany={this.downloadExcelDailyCompany}
							filterDates={this.state.filterDates}
							changeDates={this.changeDates}
						/>

						<div className='col-3 mb-2 pl-0'>
							<TaskCompanyBranchGraph
								lan={this.props.lan}
								renderGraphPieComponent={this.state.renderDailyCompanyTask}
								chartDataPie={this.state.chartDataPie}
								tasksCompanyPieParams={this.state.tasksCompanyPieParams}
								totalCountGraph={this.state.totalCountGraph}
								isClient={RoleUtils.isUserClient(this.props.session.user)}
							/>
							{!RoleUtils.isUserManager(this.props.session.user) &&
								!RoleUtils.isUserClient(this.props.session.user) && (
									<TasksMonthComponent
										{...this.props}
										renderMonthTask={this.state.renderMonthTask}
										tasksMonth={this.state.tasksMonth}
										taskPlanData={this.state.taskPlanData}
										showAlertMonthTask={this.state.showAlertMonthTask}
									/>
								)}
						</div>
						{!RoleUtils.isUserClient(this.props.session.user) && (
							<div className='col-12 col-md-9 mb-4 pr-3'>
								<TaskSlaComponent
									lan={this.props.lan}
									renderDailyCompanyTaskSla={this.state.renderDailyCompanyTaskSla}
									dailyTaskCompany={this.state.dailyTaskCompanySla}
									exportableDataSlaTaskCompanyHeaders={this.state.exportableDataSlaTaskCompanyHeaders}
									exportableDataSlaTaskCompany={this.state.exportableDataSlaTaskCompany}
									downloadExcelDailyCompanySla={this.downloadExcelDailyCompanySla}
									showAgentsTable={this.state.showAgentsTable}
									handleTaskSLA={this.handleTaskSLA}
									slaTime={this.state.slaTime}
								/>
							</div>
						)}

					</div>

				</div>
				<ToastContainer />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(DashboardComponent);
