import React, { Component } from 'react';

import AddFormIcon from '@assets/icons/form-icon.svg';
import { Row, Container, Col } from 'react-bootstrap';
import Select from 'react-select';

import Popup from '../../Popup';

import '../MyOperation.css';
import { assignFormsToTaskRequest, getFormsForMyOperationRequest, getTaskFormsRequest, removeFormTaskRequest } from '@core/api/task/taskModules.service';
import { connect } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';


const customStyles = {
	control: (base, state) => ({
		...base,
	}),
};

const ACCOUNT_NOT_ENABLE_FORMS = 702;

class AssignFormPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			agentsListArray: null,
			idFormSelected: null,
			selectedOptionForm: null,
			optionsForm: [],
			showSelect: false,
			idForm: props.tasks[0].form ? props.tasks[0].form.id : null,
			selectedForms: [],
			assignedForms: [],
			errorCodes: [],
		};
	}

	componentDidMount = () => {
		this.getInfo();
	};

	getInfo = async () => {
		const onSuccess = this.manageGetAllFormTemplates;
		const onError = () => this.props.dispatch(loadingStop('GET_FORMS'));
		this.props.dispatch(loadingStart({ name: 'GET_FORMS' }))
		getFormsForMyOperationRequest(onSuccess, onError);
	};

	manageGetAllFormTemplates = ({ forms: formList }) => {
		if (formList !== undefined && formList !== null) {
			var formOptionSelected = [];

			if (this.props.idTasks.length === 1) {
				const body = {
					idTask: this.props.idTasks[0],
					singleAccount: true,
				};

				const loadingConfig = { dispatch: this.props.dispatch }

				const onSuccess = ({ forms }) => {
					if (forms != null) {
						this.setState({ assignedForms: forms });
					}
				}

				getTaskFormsRequest(body, onSuccess, null, loadingConfig).then(() => {
					formList.forEach((form) => {
						formOptionSelected.push({
							value: form,
							label: form.name + ' - ' + form.originType,
						});
					});
					this.props.dispatch(loadingStop('GET_FORMS'))
				});
			} else {
				formList.forEach((form) => {
					if (!this.state.assignedForms.some((foo) => foo.id === form.id)) {
						formOptionSelected.push({
							value: form,
							label: form.name + ' - ' + form.originType,
						});
					}
				});
				this.props.dispatch(loadingStop('GET_FORMS'))
			}

			this.setState({
				optionsForm: formOptionSelected,
				showSelect: true,
			});
		}
	};

	//Handle the select from parent companies
	handleOptionSelectedForm = (selectedOptionForm) => {
		let state = this.state;
		state.selectedForms.push({
			name: selectedOptionForm.value.name,
			id: selectedOptionForm.value.id,
		});
		this.setState(state);
	};

	assignForm = () => {
		if (this.state.selectedForms.length > 0) {
			var ban = 0;

			var errorCodes = [];
			let formsToAdd = this.state.selectedForms.map((form) => form.id);

			this.props.dispatch(loadingStart({name: "ASSIGN_FORMS"}))

			for (let i = 0; i < this.props.idTasks.length; i++) {
				const body = {
					formsToAdd,
					idTask: this.props.idTasks[i]
				}

				const onError = ({ status }) => {
					errorCodes.push({
						errorcode: status,
						idTask: this.props.idTasks[ban],
					});
				}

				assignFormsToTaskRequest(body, null, onError).then(() => {
					if (this.props.idTasks.length === i + 1) {
						if (errorCodes.length > 0) {
							this.setState({ errorCodes: errorCodes });
						} else {
							this.props.onClose(true);
						}

						this.props.dispatch(loadingStop("ASSIGN_FORMS"))
					}
				})
			}
		}
	};

	removeTaskForm = (formIdTaskForm) => {
		const body = {
			idTask: this.props.idTasks[0],
			singleAccount: true,
			idTaskForm: formIdTaskForm,
		};

		const loadingConfig = { dispatch: this.props.dispatch, name: "REMOVE_FORM"}

		const onSuccess = (response) => {
			if (response.forms != null) {
				this.setState({ assignedForms: response.forms });
				this.getInfo();
			}
		}

		removeFormTaskRequest(body, onSuccess, null, loadingConfig);
	};

	/**
	 * This creates the selected forms tag
	 */
	SelectedForms = () =>
		this.state.selectedForms.map((form, i) => {
			return (
				<Col sm={'auto'} className='m-1' key={form.name + '-' + i}>
					<Row className='tag-selection my-auto'>
						<div className='mt-1'>
							<Container key={form.name + '-' + i + '-container'}>
								<span className='mr-3'>{form.name}</span>
								<button
									key={'btn-close-' + form + '-' + i}
									id={'btn-close-' + i}
									className='close forms-close my-auto'
									aria-label='Close'
									onClick={() => this.removeForm(i)}
								>
									<span
										style={{
											fontSize: '22px',
											position: 'relative',
											bottom: '2px',
										}}
										className='close mt-0'
										aria-hidden='true'
									>
										&times;
									</span>
								</button>
							</Container>
						</div>
					</Row>
				</Col>
			);
		});

	AssignedForms = () =>
		this.state.assignedForms.map((form, i) => {
			return (
				<Col sm={'auto'} className='m-1' key={form.name + '-' + i}>
					<Row className='tag-selection my-auto'>
						<div className='mt-1'>
							<Container key={form.name + '-' + i + '-container'}>
								<span className='mr-3'>{form.name}</span>
								<button
									key={'btn-close-' + form + '-' + i}
									id={'btn-close-' + i}
									className='close forms-close my-auto'
									aria-label='Close'
									onClick={() => this.removeTaskForm(form.idTaskForm)}
								>
									<span
										style={{
											fontSize: '22px',
											position: 'relative',
											bottom: '2px',
										}}
										className='close mt-0'
										aria-hidden='true'
									>
										&times;
									</span>
								</button>
							</Container>
						</div>
					</Row>
				</Col>
			);
		});

	removeForm = (index) => {
		let state = this.state;
		let forms = [];

		state.selectedForms.forEach((element, i) => {
			if (i !== index) {
				forms.push(element);
			}
		});

		state.selectedForms = forms;
		this.setState(state);
	};

	Errors = () => {
		return this.state.errorCodes.map((error, i) => {
			return (
				<div className='row' key={error.idTask + '-row'}>
					{error.errorcode === ACCOUNT_NOT_ENABLE_FORMS && (
						<Col sm={'auto'} className='mx-auto mt-3 mr-3'>
							<span className='myop-alert-color'>
								{'* ' + this.props.lan.formTableWasNotCreated + ' ' + error.idTask}
							</span>
						</Col>
					)}
				</div>
			);
		});
	};

	render() {
		return (
			<Popup
				title={this.props.lan.assignForms}
				icon='add-form-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
			>
				<div>
					<Row>
						<span className='small-popup-label mb-2'>{this.props.lan.select}</span>
					</Row>
					<Row className=''>
						<Col sm={'auto'} className='my-auto'>
							<img src={AddFormIcon} className='icon mr-auto' alt='' />
						</Col>
						<Col>
							{this.state.showSelect && (
								<Select
									defaultValue={
										this.state.idForm != null
											? this.state.optionsForm.filter(
													(option) => option.value.id === this.state.idForm
											  )
											: null
									}
									className='mt-1'
									onChange={this.handleOptionSelectedForm}
									options={this.state.optionsForm}
									placeholder={this.props.lan.placeHolderForm}
									isClearable={false}
									isSearchable={true}
									styles={customStyles}
								/>
							)}
						</Col>
					</Row>

					{/* Selected Forms */}
					<Row className='mt-3'>
						<span className='small-popup-label mt-2'>{this.props.lan.selectedForms}</span>
					</Row>
					<Row>
						{this.state.selectedForms.length > 0 ? (
							<this.SelectedForms />
						) : (
							<span style={{ color: '#DC5C87' }} className='small-popup-label mt-2'>
								{this.props.lan.noForm}
							</span>
						)}
					</Row>

					{/* Previously assigned Forms */}
					{this.props.idTasks.length === 1 && (
						<div>
							<Row className='mt-2'>
								<span className='small-popup-label mb-1'>
									{this.props.lan.currentlyAssignedForms}
								</span>
							</Row>
							<Row>
								<this.AssignedForms />
							</Row>
						</div>
					)}

					{this.state.errorCodes && this.state.errorCodes.length > 0 && <this.Errors />}

					{
						<div className='row'>
							<Col sm={8} className='mx-auto mt-3'>
								<div className='mx-auto text-center'>
									<button className='Fielder-button' onClick={this.assignForm}>
										{this.props.lan.assign}
									</button>
								</div>
							</Col>
						</div>
					}
				</div>
			</Popup>
		);
	}
}
export default connect()(AssignFormPopup);
