import React, { Component } from 'react';

import DeleteIcon from '@assets/icons/delete-icon.svg';
import ExportIcon from '@assets/icons/group-download-images.svg';
import request from 'FielderRequestTypes';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import ImageViewer from 'react-simple-image-viewer';
import ImageGallery from 'react-image-gallery';
import JSZipUtils from 'jszip-utils';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LoadingConst from '../Loading/LoadingConst';
import { connect } from 'react-redux';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

import './TaskDetails.css';
import 'FielderUtils/style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { getTaskPhotosRequest, deleteTaskPhotosRequest } from '@core/api/task/task.service';

const moment = require('moment');

/**
 * Contains the Gallery content from the {@link TaskDetails} component
 */
class TaskGallery extends Component {
	/**
        Contructor
    */
	constructor(props) {
		super(props);

		this.state = {
			pictures: [],
			picturesInfo: [],
			errormessage: false,
			message: '',
			isViewerOpen: false,
			currentImage: 0,
		};
	}

	setPhotos = (res) => {
		var pictures = [];
		var picturesInfo = [];
		var photos = res.photos;

		for (var i = 0; i < photos.length; i++) {
			pictures.push(photos[i].imagePath);

			var creationDate = this.props.lan.noDate;
			if (photos[i].creationDate) {
				creationDate = moment.utc(photos[i].creationDate).format('DD-MM-YYYY HH:mm');
			}
			var latitude = this.props.lan.noData;
			if (photos[i].latitude && photos[i].latitude != 'null') {
				latitude = photos[i].latitude;
			}

			var longitude = this.props.lan.noData;
			if (photos[i].longitude && photos[i].longitude != 'null') {
				longitude = photos[i].longitude;
			}

			let imagePathKey = photos[i].imagePath.split(this.props.session.user.idAccount + '/');
			let scalePathKey = photos[i].scalePath.split(this.props.session.user.idAccount + '/');

			picturesInfo.push({
				id: photos[i].id,
				imagePath: photos[i].imagePath,
				scalePath: photos[i].scalePath,
				imagePathKey: this.props.session.user.idAccount + '/' + imagePathKey[1],
				scalePathKey: this.props.session.user.idAccount + '/' + scalePathKey[1],
				isSelected: false,
				creationDate: creationDate,
				latitude: latitude,
				longitude: longitude,
			});
		}
		this.setState({ pictures: pictures, picturesInfo: picturesInfo }, () => {
			this.props.changeLoadingState();
			this.handleuncheckSelectAll();
		});
	};

	componentDidMount = () => {
		this.refreshImages();
	};

	refreshImages = () => {
		this.props.changeLoadingState();

		const body = { taskInfo: { idTask: this.props.idTask }};
		const onSuccess = this.setPhotos;
		const onError = this.changeLoadingState

		getTaskPhotosRequest(body, onSuccess, onError);
	};

	handleuncheckSelectAll = () => {
		for (let i = 0; i < this.state.picturesInfo.length; i++) {
			document.getElementById('checkboxPhoto' + i).checked = false;
		}
	};

	handlerDelete = () => {

		for (var i = 0; i < this.state.picturesInfo.length; i++) {
			this.props.dispatch(loadingStart({ name: "IMAGE_LOADING" }));
			if (this.state.picturesInfo[i].isSelected) {
				const body = request.getRequestDoActionDeletePhotoTask(
					this.state.picturesInfo[i].imagePathKey,
					this.state.picturesInfo[i].scalePathKey,
					this.state.picturesInfo[i].id,
					this.props.idTask,
					this.props.session.user.idAccount,
				);


				const onSuccess = () => {
					this.refreshImages();
					this.props.dispatch(loadingStop({ name: "IMAGE_LOADING" }));
				}

				const onError = (error) => {
					this.setState({
						message: this.props.lan.messageErrorDeletedTask,
						errormessage: true,
					});
					this.props.dispatch(loadingStop({ name: "IMAGE_LOADING"	}));
				}

				deleteTaskPhotosRequest(body, onSuccess, onError);
			}

		}
	};

	handlerSelectedPhotos = (index) => {
		var pictures = this.state.picturesInfo;
		pictures[index].isSelected = !this.state.picturesInfo[index].isSelected;

		this.setState({ picturesInfo: pictures });
	};

	changeErrorMessage = () => {
		this.setState({ errormessage: !this.state.errormessage });
	};

	downloadZipFile = (zip) => {
		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, this.props.idTask + '.zip');
			this.props.dispatch(loadingStop({ name: "IMAGE_LOADING"	}));
		});
	};

	getImageName = (url) => {
		let separeted = url.split('/');
		let imageName = '';
		const lastIndex = separeted.length - 1;

		if (lastIndex > 0) {
				imageName = separeted[lastIndex];
		}
		return imageName;
	};

	getImagesFromGroup = async (images, index, zip) => {
		if (index < images.length) {
			const image = images[index];
			if (image) {

				let imageLink =image
				let bucket = process.env.AWS_BUCKET
				let link_split = image.split('com/')
				let key = link_split[1]

				if (!key.includes(bucket)) {
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com/${key}`;
				} else {
					let auxKey = key.split(process.env.AWS_BUCKET)
					imageLink = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${auxKey[1]}`;
				}
				////const imageAux = image.split(process.env.FIELDER_CONTENT_LINK);
				//const imageUrl = `https://${process.env.AWS_BUCKET}.s3.amazonaws.com${imageAux[1]}`;

				await new Promise((resolve, reject) => {
					JSZipUtils.getBinaryContent(imageLink, (err, data) => {
						zip.file(this.getImageName(image) + '.jpeg', data, { binary: true });
						resolve();
					});
				});
				let newIndex = index + 1;
				this.getImagesFromGroup(images, newIndex, zip);

			} else {
				this.downloadZipFile(zip);
			}
		} else {
			this.downloadZipFile(zip);
		}
	};

	handlerDownloadImg = () => {
		this.props.dispatch(loadingStart({
			name: "IMAGE_LOADING",
			config: {
				icon: LoadingConst.EXPORT,
				text: this.props.lan.exportMessage
			}
		}));

		this.getImagesFromGroup(this.state.pictures, 0, new JSZip());
	};

	openImageViewer = (index) => {
		this.setState({ currentImage: index, isViewerOpen: true });
	};

	closeImageViewer = () => {
		this.setState({ currentImage: 0, isViewerOpen: false });
	};

	render() {
		return (
			<div className='container' style={{ height: '350px' }}>
				{this.state.errormessage && (
					<ErrorMessage
						{...this.props}
						closeErrorMessage={this.changeErrorMessage}
						message={this.state.message}
						showErrorMessage={this.state.errormessage}
					/>
				)}
				<Row className='mt-5 mx-auto' style={{ height: '100%', width: '95%' }}>

					{this.state.pictures.length > 0 && (
						<React.Fragment>
							<div className='col-10 TaskModules-label mb-3'>{this.props.lan.photosGallery}</div>
							<div className='col-1'>
								<img
									className='icon Fielder-icon-button'
									title='Exportar'
									onClick={this.handlerDownloadImg}
									src={ExportIcon}
									alt=''
								/>
							</div>
						</React.Fragment>
					)}

					{this.state.pictures.length > 0 && !RoleUtils.isUserClient(this.props.session.user) && (
						<div className='col-1'>
							<img
								className='icon Fielder-icon-button mr-2'
								title='Eliminar'
								src={DeleteIcon}
								onClick={this.handlerDelete}
								alt=''
							/>
						</div>
					)}

					{this.state.pictures.length > 0 && (
						<div className='col-12 mb-2  photoMessagePermission'>
							{'* ' + this.props.lan.photoMessagePermission}
						</div>
					)}

					{this.state.pictures.length <= 0 && (
						<div className='col'>
							<div
								align='center'
								style={{ zIndex: '1', marginTop: '4.5rem', fontSize: '20px', fontWeight: 'bold' }}
							>
								{' '}
								{this.props.lan.noImages}{' '}
							</div>
						</div>
					)}

					<Scrollbars style={{ height: '93%', overflowY: 'hidden' }}>
						<div className='row '>
							{this.state.picturesInfo.map((picture, index) => (
								<React.Fragment>
									<div className='col-4 mb-3'>
										<div className='row justify-content-center'>
											<div className='col-auto'>
												<input
													className='form-check-input labelCheckbox d-none'
													type='checkbox'
													value={picture.index}
													key={'input' + picture.index}
													id={'checkboxPhoto' + index}
													onClick={() => this.handlerSelectedPhotos(index)}
												/>
												<label
													htmlFor={'checkboxPhoto' + index}
													key={'label' + picture.index}
													className='round-checkbox boderColorImage'
												/>
											</div>
											<div className='col'>
												{/*<img
													src={picture.imagePath}
													onClick={() => this.openImageViewer(index)}
													width='120'
													height='120'
													key={index}
													style={{ margin: '2px' }}
													alt=''
													className='pointerImageGallery'
												/>*/}
												<ImageGallery
													items={[{ original: picture.imagePath , originalClass:"document-task-gallery-pointer", originalHeight:'200px', originalWidth:'200px'}]}
													onClick={() => this.openImageViewer(index) }
													showPlayButton={false}
													showFullscreenButton={false }
												/>
											</div>
										</div>

										<div className='row-12 ml-5'>
											<div className='row mt-2'>
												<div className='col-3 gallery-text'>{this.props.lan.date + ': '}</div>
												<div className='col gallery-text'>{picture.creationDate}</div>
											</div>
											<div className='row'>
												<div className='col-3 gallery-text'>{this.props.lan.latitude + ': '}</div>
												<div className='col gallery-text'>{picture.latitude}</div>
											</div>
											<div className='row'>
												<div className='col-3 gallery-text'>{this.props.lan.longitude + ': '}</div>
												<div className='col gallery-text'>{picture.longitude}</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							))}
						</div>

						{this.state.isViewerOpen && (
							<ImageViewer
								src={this.state.pictures}
								currentIndex={this.state.currentImage}
								onClose={() => this.closeImageViewer()}
								disableScroll={false}
								backgroundStyle={{
									backgroundColor: 'rgba(0,0,0,0.9)',
								}}
								closeOnClickOutside={true}
							/>
						)}
					</Scrollbars>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps, null, null, { forwardRef: true } )(TaskGallery);
