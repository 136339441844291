import {
	SuperAdmin,
	SuperAdminViewer,
	Admin,
	Manager,
	Client,
	RootGlobalAccountUser,
	ClientGlobalAccountUser,
	InternalAdmin,
} from '../../constants/roles/roles.constants';

const getRolePages = (role) => {
	switch (role) {
		case SuperAdmin.name:
			return SuperAdmin.pages;
		case SuperAdminViewer.name:
			return SuperAdminViewer.pages;
		case Admin.name:
			return Admin.pages;
		case Manager.name:
			return Manager.pages;
		case RootGlobalAccountUser.name:
			return RootGlobalAccountUser.pages;
		case ClientGlobalAccountUser.name:
			return ClientGlobalAccountUser.pages;
		case InternalAdmin.name:
			return InternalAdmin.pages;
		case Client.name:
			return Client.pages;
		default:
			return [];
	}
};

const isPageInRole = (role, page) => validateRole(role, page);

const validateRole = (role, page) => {
	const pages = getRolePages(role);

	return {
		isValid: pages.includes(page),
		forbidden: role !== Client.name,
	};
};

export const isUserSuperAdministrator = (user) => user !== null && user !== undefined && user.role === SuperAdmin.name;

export const isUserAdministrator = (user) => user !== null && user !== undefined && user.role === Admin.name;

export const isUserSuperAndAdministrator = (user) => (
		user !== null &&
		user !== undefined &&
		(user.role === Admin.name || user.role === SuperAdmin.name)
	);

export const isManager = (user) => user !== null && user !== undefined && user.role === Manager.name;

export const isClient = (user) => user !== null && user !== undefined && user.role === Client.name;

export const isRootGlobalAccountUser = (user) => user !== null && user !== undefined && user.role === RootGlobalAccountUser.name;

export const isClientGlobalAccountUser = (user) => user !== null && user !== undefined && user.role === ClientGlobalAccountUser.name;

export const isInternalAdminUser = (user) => user !== null && user !== undefined && user.role === InternalAdmin.name;

export const isUserSuperAdministratorAndSuperAdminViewer = (user) => (
		user !== null &&
		user !== undefined &&
		(user.role === SuperAdminViewer.name || user.role === SuperAdmin.name)
	);

export const isUserSuperAdministratorViewer = (user) => user !== null && user !== undefined && user.role === SuperAdminViewer.name;

export const isUserSuperAndSuperViewerAndAdministrator = (user) => (
		user !== null &&
		user !== undefined &&
		(user.role === Admin.name ||
			user.role === SuperAdmin.name ||
			user.role === SuperAdminViewer.name)
	);
