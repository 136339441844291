import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: [],
};

const taskTypesSlice = createSlice({
	name: 'taskTypes',
	initialState,
	reducers: {
		setTaskTypes: (state, action) => {
			state.list = action.payload;
		},
	},
});

export const { setTaskTypes } = taskTypesSlice.actions;

export default taskTypesSlice.reducer;
