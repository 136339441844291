import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';

import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import DatePickerFielder from '../../DatePickerFielder/DatePickerFielder';
import Popup from '../../Popup';
import '../MyOperation.css';
import { rescheduleTaskRequest } from '@core/api/task/task.service';
import { connect } from 'react-redux';
/**
 * TODO:
 *  Popup used to Reschdule a task
 */
class RescheduleTaskPopup extends Component {
	constructor(props) {
		super(props);

		var taskNum = '';
		var taskType = '';
		var rescheduleSuccess = true;
		var rescheduleFail = false;
		var dateSelected = new Date();
		var tasksSize = this.props.tasks.length;

		for (var i = 0; i < tasksSize; i++) {
			var task = this.props.tasks[i];
			for (var j = 0; j < tasksSize; j++) {
				if (task.taskId === parseInt(this.props.idTasks[j])) {
					taskNum += ' [' + task.taskId + ']';
					taskType += ' [' + task.taskType + ']';
				}
			}
		}


		this.state = {
			taskDetails: { taskNum, taskType },
			dateSelected,
			minTime: new Date(),
			showErrorMessage: false,
			showErrorMessageText: '',
			showErrorMessageIcon: null,
			rescheduleSuccess: rescheduleSuccess,
			rescheduleFail: rescheduleFail,
			dateChanged: false,
		};
	}

	getType(type) {
		return this.props.lan['tasktype' + type];
	}

	manageGetRescheduleTasks = () => this.props.onClose(this.state.rescheduleSuccess, this.state.rescheduleFail, this.props.idTasks.length);

	validateDate = () => {
		var currentDate = new Date();
		if (!this.state.dateChanged || this.state.dateSelected >= currentDate) {
			document.getElementById('executionDate-alert-icon').style.display = 'none';
			document.getElementById('executionDate-red-input').style.borderColor = '#ced4da';
			return true;
		}

		document.getElementById('executionDate-alert-icon').style.display = 'inline';
		document.getElementById('executionDate-red-input').style.borderColor = '#c70101';
		return false;
	};

	rescheduleTask = (e) => {
		e.preventDefault();
		var comment = document.getElementById('comment').value;
		var isValid = true;

		if (!this.validateDate()) {
			isValid = false;
		}

		if (comment === '') {
			isValid = false;
			document.getElementById('comment-alert-icon').style.display = 'inline';
			document.getElementById('comment').style.borderColor = '#c70101';
		} else {
			document.getElementById('comment-alert-icon').style.display = 'none';
			document.getElementById('comment').style.borderColor = '#ced4da';
		}

		if (isValid) {
			let dateSelected = this.state.dateSelected;
			const body = {
				taskInfo: {
					hour: dateSelected.getHours(),
					year: dateSelected.getFullYear(),
					month: dateSelected.getMonth(),
					day: dateSelected.getDate(),
					minute: dateSelected.getMinutes(),
					taskReschedule: {
						idTasks: this.props.idTasks,
						dateReschedule: dateSelected.getTime(),
						idUser: this.props.user.idUser,
						comment,
					},
				},
			}

			const loadingConfig = { dispatch: this.props.dispatch }
			const onSuccess = this.manageGetRescheduleTasks;
			const onError = () => {
				let state = this.state;
				state.rescheduleSuccess = false;
				state.rescheduleFail = true;
				this.setState(state);
			};

			rescheduleTaskRequest(body, onSuccess, onError, loadingConfig);
		} else {
			document.getElementById('register-form-hint').style.display = 'block';
		}
	};

	handleProgramDate = (date) => {
		var minTime = new Date();
		if (this.state.dateSelected !== date) {
			if (
				date.getDate() !== new Date().getDate() ||
				date.getMonth() !== new Date().getMonth() ||
				date.getFullYear() !== new Date().getFullYear()
			) {
				minTime = new Date().setHours(0, 0);
			}
			this.setState({
				dateSelected: date,
				minTime: minTime,
				dateChanged: true,
			});
		}
	};

	render() {
		return (
			<Popup
				title={this.props.lan.rescheduleTask + ' - ' + this.state.taskDetails.taskNum}
				icon='calendar-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.save}
				requiredFields={this.props.lan.requiredFields}
				buttonOnClick={this.rescheduleTask}
				size={'auto'}
			>
				<Row>
					<Col sm={12}>
						{/* Task Type */}
						<div className='mt-1'>
							<Row>
								<Col className='small-popup-label'>{this.props.lan.typeTask}</Col>
							</Row>
							<Row>
								<Col className='popup-label my-2 ml-2'>{this.state.taskDetails.taskType}</Col>
							</Row>
						</div>

						<DatePickerFielder
							id='executionDate-red-input'
							selected={this.state.dateSelected}
							handleDate={this.handleProgramDate}
							minDate={new Date()}
							timeFormat='HH:mm'
							minTime={this.state.minTime}
							maxTime={new Date().setHours(23, 59)}
							dateFormat='dd/MM/yyyy h:mm aa' //Formato de fecha
							timeIntervals={30} //Intervalo de tiempo en minutos
							classNamePicker='MyOperation-eschedule form-control mt-1 text-left'
							imgId='executionDate-alert-icon'
							classNameImg='CreateTask-dir-desc-fieldContainer-icon'
						/>

						<div className='mt-3'>
							<Row>
								<Col className='small-popup-label'>{this.props.lan.commentTitle}</Col>
							</Row>
							<Row>
								<div className='form-group col'>
									<textarea className='form-control' rows='3' cols='40' id='comment'></textarea>
									<img
										src={redAlertIcon}
										alt=''
										id='comment-alert-icon'
										className='field-alert-icon' />
								</div>
							</Row>
						</div>
					</Col>
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect()(RescheduleTaskPopup);
