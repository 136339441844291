import React, { Component } from 'react';

import AddAgentsIcon from '@assets/icons/add-agent-icon.svg';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import CancelRouteIcon from '@assets/icons/cancel-route-icon.svg';
import ResetCanceledTasksIcon from '@assets/icons/reset-canceled-tasks-icon.svg';
import ResetRouteTasksIcon from '@assets/icons/reset-route-tasks-icon.svg';
import RestartIcon from '@assets/icons/restart-route-icon.svg';
import routeConst from 'FielderUtils/roleManagementConst/routeConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import ValidateModules, { validatePermissions } from 'FielderUtils/validatePermissions';
import { connect } from 'react-redux';

import { isRoleValid } from '../../../core/utils/roles/validateRoles';
import POPUPNAME from 'FielderUtils/popupName/popupName';

class RouteActionPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canCancelRoutes: false,
			canRestartRouteTasks: false,
			canRestartCancelledTask: false,
			canRestartRoute: false,
			canRescheduleRoute: false,
		};
	}

	componentDidMount = () => {
		let state = this.state;
		state.canCancelRoutes = isRoleValid(
			routeConst.ROUTES,
			routeConst.CANCEL,
			this.props.session.roles
		);
		state.canRestartRouteTasks = isRoleValid(
			routeConst.ROUTES,
			routeConst.RESTART_ROUTE_TASKS,
			this.props.session.roles
		);
		state.canRestartCancelledTask = isRoleValid(
			routeConst.ROUTES,
			routeConst.RESTART_ROUTE_CANCELED_TASKS,
			this.props.session.roles
		);
		state.canRestartRoute = isRoleValid(
			routeConst.ROUTES,
			routeConst.RESTART_ROUTE,
			this.props.session.roles
		);
		state.canRescheduleRoute = isRoleValid(
			routeConst.ROUTES,
			routeConst.RESCHEDULE_ROUTE,
			this.props.session.roles
		);
		this.setState(state);
	};

	changePopupState = (popupName) => {
		this.props.changePopupState(popupName);
	};

	closeRouteAction = () => {
		this.props.closeRouteAction();
	};

	render() {
		return (
			<React.Fragment>
				<div className='taskAction-container full-width mb-4'>
					<div className='d-flex justify-content-center'>
						<div className='row taskAction-background'>
							<div className='col-auto no-padding taskAction-red-rectangle'></div>
							<div className='col-auto mt-1'>
								<span className='taskAction-number-selected'>{this.props.selectedRouteCount}</span>
							</div>
							<div className='col-auto my-auto'>
								<span className='taskAction-text-selected'>{this.props.lan.selectedElements}</span>
							</div>
							{/******** CANCEL ROUTE********/}
							{!RoleUtils.isUserClient(this.props.session.user) && this.state.canCancelRoutes && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto'>
											<img
												id='MyOperation-cancel-route-icon'
												src={CancelRouteIcon}
												className='icon Fielder-icon-button'
												alt={this.props.lan.cancelRoute}
												title={this.props.lan.cancelRoute}
												onClick={() => this.changePopupState(POPUPNAME.CANCEL_ROUTE)}
											/>
										</div>
									</div>
									<div className='row-auto d-flex justify-content-center'>
										<div className='col-auto no-padding d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.cancelRoute}</span>
										</div>
									</div>
								</div>
							)}
							{/******** RESET ROUTE ********/}
							{!RoleUtils.isUserClient(this.props.session.user) && this.state.canRestartRouteTasks && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-reset-route-tasks-icon'
												src={ResetRouteTasksIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.resetRouteTasks}
												onClick={() => this.changePopupState(POPUPNAME.RESET_ROUTE_TASK)}
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.resetRouteTasks}</span>
										</div>
									</div>
								</div>
							)}
							{/******** RESET CANCEL TASK********/}
							{!RoleUtils.isUserClient(this.props.session.user) && this.state.canRestartCancelledTask && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-reset-canceled-tasks-icon'
												src={ResetCanceledTasksIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.resetCanceledTasks}
												onClick={() => this.changePopupState(POPUPNAME.RESET_CAMCELED_TASK)}
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>
												{this.props.lan.resetCanceledTasks}
											</span>
										</div>
									</div>
								</div>
							)}
							{/******** RESTART ROUTE ********/}
							{!RoleUtils.isUserClient(this.props.session.user) && this.state.canRestartRoute && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-unlik-route-icon'
												src={RestartIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.restartRoute}
												onClick={() => this.changePopupState(POPUPNAME.RESTART_ROUTE)}
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.restartRoute}</span>
										</div>
									</div>
								</div>
							)}
							{/******** REASSIGN AGENT ********/}
							{!RoleUtils.isUserClient(this.props.session.user) && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-unlik-route-icon'
												src={AddAgentsIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.agents}
												onClick={() => this.changePopupState(POPUPNAME.REASSIGN_ROUTE_AGENT)}
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.agents}</span>
										</div>
									</div>
								</div>
							)}
							{/******** RESCHEDULE ROUTE ********/}
							{!RoleUtils.isUserClient(this.props.session.user) && this.state.canRescheduleRoute && (
								<div className='col-auto my-auto pl-1 pr-1'>
									<div className='row-auto'>
										<div className='col-auto d-flex justify-content-center'>
											<img
												id='MyOperation-reschedule-route-icon'
												src={CalendarIcon}
												className='icon Fielder-icon-button'
												title={this.props.lan.rescheduleRoute}
												onClick={() => this.changePopupState(POPUPNAME.RESCHEDULE_ROUTE)}
											/>
										</div>
									</div>
									<div className='row-auto'>
										<div className='col-auto no-padding  d-flex justify-content-center'>
											<span className='taskAction-text-icon'>{this.props.lan.rescheduleRoute}</span>
										</div>
									</div>
								</div>
							)}

							<div className='col-auto my-auto pl-1 pr-1 colsize-cross'>
								<div className='row-auto'>
									<div className='col-auto'>
										<a
											href='#'
											className='closeTaskAction'
											onClick={() => this.closeRouteAction()}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(RouteActionPopup);
