import React, { useState } from 'react';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { useLanguage } from 'Context/LanguageContext';
import { getModifyStatus, validateModifyStatus, getTaskRemoteType } from 'FielderUtils/task/modifyStatus';
import { Modal } from 'react-bootstrap';
import { modifyTaskStatusRequest } from '@core/api/task/task.service';
import { useDispatch } from 'react-redux';
export default function ModifyTaskStatus(props) {
	const lan = useLanguage();
	const dispatch = useDispatch()
	const remoteTaskIds = getTaskRemoteType(props.taskTypes);
	const statusTaskModify = getModifyStatus(lan, props.statusTask, props.taskTypeId, remoteTaskIds);
	const [statusTask, setStatusTask] = useState(statusTaskModify);
	const [statusToUpdate, setStatusToUpdate] = useState(null);
	const [showStatusValidation, setShowStatusValidation] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [comment, setComment] = useState(null);
	const validateCheckStatus = (excelColumn, index) => {
		statusTaskModify[index].checked = true;
		setStatusTask(statusTaskModify);
		setStatusToUpdate(excelColumn.value);
	};
	const disabledAllCheckStatus = () => {
		for (let i = 0; i < statusTaskModify.length; i++) {
			document.getElementById(`checkboxTaskStatus${i}`).checked = false;
			statusTaskModify[i].checked = false;
		}
		setStatusToUpdate(null);
	};
	const validateDescription = (commentValid) => {
		if (commentValid != null && commentValid != '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			document.getElementById('description-red-input').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('description-alert-icon').style.display = 'inline';
		document.getElementById('description-red-input').style.borderColor = '#c70101';
		return false;
	};
	const validateStatus = (statusValid) => {
		if (statusValid != null) {
			setShowStatusValidation(false);
			return true;
		}
		setShowStatusValidation(true);
		return false;
	};
	const handleRequestModifyStatus = () => {
		const validDescription = validateDescription(comment);
		const validStatus = validateStatus(statusToUpdate);
		if (validDescription && validStatus) {
			const body = {
				taskStatus: statusToUpdate,
				idTask: props.idTask,
				dateStatus: new Date().getTime(),
				comment,
			};
			const loadingConfig = { dispatch }
			const onSuccess = () => {
				setShowErrorMessage(false);
				props.handleCloseModifyStatus(true);
			};
			const onError = ({ status }) => {
				const message = validateModifyStatus(status, lan);
				setErrorMessage(message);
				setShowErrorMessage(true);
			}
			modifyTaskStatusRequest(body, onSuccess, onError, loadingConfig);
		}
	};
	return (
		<>
			<Modal
				id='ErrorMessage-modal'
				size='md'
				show
				onHide={() => props.handleCloseModifyStatus(false)}
				className='Fielder-modals-header-border Fielder-modals-content-border'
			>
				<Modal.Header className='Fielder-modals-create-edit-detail-header-border' closeButton>
					<div className='col d-flex justify-content-center'>
						<Modal.Title style={{ fontSize: '20px' }}>{lan.modifyTaskStatus}</Modal.Title>
					</div>
				</Modal.Header>

				<Modal.Body className='error-message-modal-body text-center'>
					{statusTask.map((excelColumn, index) => (
						<>
							<div className='col-12' key={`check-${index}`}>
								<div className='row'>
									<div className='col-auto pr-1'>
										<input
											className='form-check-input labelCheckbox d-none'
											type='checkbox'
											value={excelColumn.value}
											checked={excelColumn.checked}
											id={`checkboxTaskStatus${index}`}
											onClick={() => {
												const validateChecked = document.getElementById(`checkboxTaskStatus${index}`).checked;
												if (validateChecked == true) {
													validateCheckStatus(excelColumn, index);
												} else {
													disabledAllCheckStatus();
												}
											}}
										/>

										<label key={excelColumn.key} htmlFor={`checkboxTaskStatus${index}`} className='round-checkbox' />
									</div>
									<div className='col-auto'>
										<span className='textFormat-taskGroupDetail-task'>{excelColumn.name}</span>
									</div>
								</div>
							</div>
						</>
					))}
					{showStatusValidation && (
						<div className='col-auto mt-2' style={{ color: '#dc5c87' }}>
							<div>{`*${lan.selectAStatus}`}</div>
						</div>
					)}

					{/* Agregar campo comentario */}
					<div className='col my-3 pr-5'>
						<textarea
							className='form-control  text-left mt-1'
							maxLength='255'
							rows='5'
							cols='20'
							id='description-red-input'
							placeholder={lan.reasonForChange}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
						<img src={redAlertIcon} alt='' id='description-alert-icon' className='modify-status-icon' />
					</div>

					{showErrorMessage && (
						<div className='col-auto mt-2' style={{ color: '#dc5c87' }}>
							<div>{`*${errorMessage}`}</div>
						</div>
					)}
				</Modal.Body>

				<Modal.Footer>
					<div className='col mt-3 ml-5 pl-0'>
						<button
							id='cancel-modify'
							type='submit'
							className='btn btn-outline-primary btn-success error-message-btn-margin'
							onClick={() => props.handleCloseModifyStatus(false)}
							style={{ width: '160px' }}
						>
							{lan.cancel}
						</button>
					</div>
					<div className='col mt-3'>
						<button
							id='update-modify-status'
							type='submit'
							className='btn btn-outline-primary btn-success error-message-btn-margin'
							onClick={() => handleRequestModifyStatus()}
							style={{ width: '160px' }}
						>
							{lan.modifyStatus}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}
