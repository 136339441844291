const ANALYTICS = 'analytics';
const ISOCHRONOUS = 'iso';
const CREATE_TASK = 'createTask';
const ROUTE = 'route';
const ROUTE_EXPENSES = 'routeExpenses';
const RESOURCE = 'resource';
const BILLING = 'billing';
const DYNAMIC_ALLOCATION = 'dynamicAllocation';
const ASSET_MANAGEMENT = 'assetManagement';
const INVENTORY = 'inventory';
const FORMS = 'forms';
const AUTO_ASSIGN = 'autoassign';
const SKILLS = 'skillsConfig';
const TASK_TYPES = 'taskTypes';

const PERMISSION_NAMES = {
	ANALYTICS,
	ISOCHRONOUS,
	CREATE_TASK,
	ROUTE,
	ROUTE_EXPENSES,
	RESOURCE,
	BILLING,
	DYNAMIC_ALLOCATION,
	ASSET_MANAGEMENT,
	INVENTORY,
	FORMS,
	AUTO_ASSIGN,
	SKILLS,
	TASK_TYPES,
};

export default PERMISSION_NAMES;
