import React from 'react';

import { useLanguage } from 'Context/LanguageContext';
import { Row, Col } from 'react-bootstrap';

import { getFieldQuestion } from '../util';

const CreateFieldsAssetInventory = ({
	assetInventories,
	activeAssetInventory,
	timezone,
	showFormFile,
	getTextFileHeader
}) => {
	const lan = useLanguage();

	const Fields = () => {
		let fields = assetInventories[activeAssetInventory].questions;

		return fields.map((field, i) => (
			<div key={'Forms-row-' + i} className='mt-4'>
				{getFieldQuestion(field, showFormFile, timezone, lan, getTextFileHeader,false)}
			</div>
		));
	};

	return (
		<div>
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col sm={'auto'}>
							<b>{lan.questions}</b>
						</Col>
						<Col>
							<hr />
						</Col>
					</Row>
				</Col>
			</Row>
			<Fields />
		</div>
	);
};

export default CreateFieldsAssetInventory;
