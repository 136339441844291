import React from 'react';

import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useLanguage } from 'Context/LanguageContext';

export default function MobileInformation(props) {
	const lan = useLanguage();

	const BatterIcon = () => {
		if (props.userMobileInformation.battery <= 10) {
			return <BatteryAlertIcon />;
		}
		if (props.userMobileInformation.battery >= 11 && props.userMobileInformation.battery <= 20) {
			return <Battery20Icon />;
		}
		if (props.userMobileInformation.battery >= 21 && props.userMobileInformation.battery <= 30) {
			return <Battery30Icon />;
		}
		if (props.userMobileInformation.battery >= 31 && props.userMobileInformation.battery <= 50) {
			return <Battery50Icon />;
		}
		if (props.userMobileInformation.battery >= 51 && props.userMobileInformation.battery <= 60) {
			return <Battery60Icon />;
		}
		if (props.userMobileInformation.battery >= 61 && props.userMobileInformation.battery <= 80) {
			return <Battery80Icon />;
		}
		if (props.userMobileInformation.battery >= 81 && props.userMobileInformation.battery <= 90) {
			return <Battery90Icon />;
		}
		if (props.userMobileInformation.battery >= 91) {
			return <BatteryFullIcon />;
		}
	};

	return (
		<div>
			<div className='row-12 mt-3 mb-1'>
				<div className='row d-flex justify-content-center'>
					<div className='col-auto mt-3 pt-3 pl-1'>
						<PhoneAndroidIcon className='sizePhone' />
					</div>
					<div className='col-9'>
						<div className='row mt-3'>
							<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
								{lan.deviceName}
							</div>
							<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
								{lan.deviceSystemVersion}
							</div>
						</div>
						<div className='row mb-2'>
							<div className='col' style={{ height: '20px' }}>
								{props.userMobileInformation.deviceName}
							</div>
							<div className='col' style={{ height: '20px' }}>
								{props.userMobileInformation.deviceSystemVersion}
							</div>
						</div>

						<div className='row'>
							<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
								{lan.fielderVersion}
							</div>
							<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
								{lan.battery}
							</div>
						</div>
						<div className='row mb-2'>
							<div className='col' style={{ height: '20px' }}>
								{props.userMobileInformation.fielderVersion}
							</div>
							<div className='col' style={{ height: '20px' }}>
								<BatterIcon />
								{`${props.userMobileInformation.battery} %`}
							</div>
						</div>
						{props.isAgent && (
							<>
								<div className='row'>
									<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
										{lan.locationPrecisePermission}
									</div>
									<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
										{lan.locationApproximatePermission}
									</div>
								</div>
								<div className='row'>
									<div className='col mt-1' style={{ height: '20px' }}>
										{props.userMobileInformation.locationPrecisePermission}
									</div>
									<div className='col mt-1' style={{ height: '20px' }}>
										{props.userMobileInformation.locationApproximatePermission}
									</div>
								</div>

								<div className='row'>
									<div className='col' style={{ fontWeight: 'bold', height: '20px' }}>
										{lan.locationBackgroundPermission}
									</div>
								</div>
								<div className='row'>
									<div className='col' style={{ height: '20px' }}>
										{props.userMobileInformation.locationBackgroundPermission}
									</div>
								</div>
							</>	
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
