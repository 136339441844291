import { REQUEST_TYPES } from '../../constants/profile/profile.requests';
import { LoadingDispatcherConfig } from '@core/models/loading.types';
import { ProfileBody } from '@core/models/profile.types';

import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// POST

// PUT
export const updateProfile = async (
	body: ProfileBody,
	onSuccess: any,
	onError: any,
	loadingConfig: LoadingDispatcherConfig
) => {
	
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

// GET

// DELETE
