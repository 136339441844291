export const REQUEST_TYPES = {
	DO_ACTION_ASSIGN_AGENT_TASK: 'DO_ACTION_ASSIGN_AGENT_TASK',
	DO_ACTION_ASSIGN_TEAM_TASK: 'DO_ACTION_ASSIGN_TEAM_TASK',
	DO_ACTION_CREATE_TASK: 'DO_ACTION_CREATE_TASK',
	DO_ACTION_MODIFY_TASK_STATUS: 'DO_ACTION_MODIFY_TASK_STATUS',
	GET_VIEW_DETAIL_TASK: 'GET_VIEW_DETAIL_TASK',
	GET_TASK_DETAIL_TASK_FOR_MY_OPERATION: 'GET_TASK_DETAIL_TASK_FOR_MY_OPERATION',
	DO_ACTION_UPDATE_TASK: 'DO_ACTION_UPDATE_TASK',
	DO_ACTION_RATE_TASK: 'DO_ACTION_RATE_TASK',
	GET_TASK_INFO_REALTIME: 'GET_TASK_INFO_REALTIME',
	GET_TASK_PHOTOS: 'GET_VIEW_PHOTO_TASK',
	GET_TASK_DOCUMENTS: 'GET_VIEW_DOCUMENTS_TASK',
	UPDATE_TASK_DOCUMENT: 'DO_ACTION_UPDATE_DOCUMENT_TASK',
	UPLOAD_TASK_DOCUMENT: 'DO_ACTION_UPLOAD_DOCUMENT_TASK',
	DELETE_TASK_DOCUMENT: 'DO_ACTION_DELETE_DOCUMENT_TASK',
	DELETE_TASKS: 'DO_ACTION_TRASH_TASK',
	DO_ACTION_RE_AUTO_ASSIGN_PROCESS: 'DO_ACTION_RE_AUTO_ASSIGN_PROCESS',
	DISABLE_TASKS: 'DO_ACTION_DELETE_TASK',
	DO_ACTION_RESTART_TASK: 'DO_ACTION_RESTART_TASK',
	DO_ASSIGN_ASSET_TO_TASK: 'DO_ASSIGN_ASSET_TO_TASK',
	DO_ADD_ASSETS_TO_TASK: 'DO_ADD_ASSETS_TO_TASK',
	DO_ASSIGN_INVENTORY_TO_TASK: 'DO_ASSIGN_INVENTORY_TO_TASK',
	DO_ASSIGN_FORM_TO_TASK: 'DO_ASSIGN_FORM_TO_TASK',
	GET_ASSETS_BY_TASK: 'GET_ASSETS_BY_TASK',
	GET_INVENTORIES_BY_TASK: 'GET_INVENTORIES_BY_TASK',
	GET_FORMS_BY_TASK: 'GET_FORMS_BY_TASK',
	GET_ALL_FORMS_TEMPLATES: 'GET_ALL_FORMS_TEMPLATES',
	COUNT_TASKS_BY_MAIL: 'GET_COUNT_TASK_BY_MAIL',
	RESCHEDULE_TASK: 'DO_ACTION_RESCHEDULE_TASK',
	DUPLICATE_TASKS: 'DO_ACTION_DUPLICATE_TASKS',
	GET_TASK_TO_DYNAMIC_ALLOCATION_VIEW: 'GET_TASK_TO_DYNAMIC_ALLOCATION_VIEW',
	GET_TASKS_BY_BRANCH_SUMMARY: 'GET_RESUME_TASKS_BY_BRANCH',
	GET_TASKS_BY_ACCOUNT_SUMMARY: 'GET_RESUME_TASKS_BY_ACCOUNT',
	ASSIGN_TASK_TO_GROUP: 'DO_ASSIGN_GROUP_TASK',
	CREATE_TASK_GROUP: 'DO_ACTION_CREATE_GROUP_TASK',
	EDIT_TASK_GROUP: 'DO_ACTION_UPDATE_GROUP_TASK',
	GET_TASKS_IN_GROUP: 'GET_TASKS_BY_ID_GROUP',
	GET_TASKS_BY_FILTER_FORM:'GET_TASKS_BY_FILTER_FORM',
	GET_GROUP_TASKS: 'GET_VIEW_GROUP_TASKS',
	GET_GROUP_TASK: 'GET_VIEW_DETAIL_GROUP_TASK',
	GET_TASK_GROUP_INFO_REALTIME: 'GET_TASK_GROUP_INFO_REALTIME',
	DISABLE_TASK_GROUP: 'DO_ACTION_TRASH_GROUP_TASK',
	REFUSE_TASK_GROUP: 'DO_REFUSE_GROUP_TASK',
	DO_ACTION_ASSIGN_PARTNER_TASK: 'DO_ACTION_ASSIGN_PARTNER_TASK',
	DO_ACTION_REASSIGN_PARTNER_TASK: 'DO_ACTION_REASSIGN_PARTNER_TASK',
	GET_TASK_IDS_BY_DATE: 'GET_TASK_IDS_BY_DATE',
	DO_ACTION_DELETE_PHOTO_TASK: 'DO_ACTION_DELETE_PHOTO_TASK',
	DO_ACTION_REMOVE_FORM_FROM_TASK: 'DO_ACTION_REMOVE_FORM_FROM_TASK',
};
  