import React, { Component } from 'react';

import CheckBoxIconOpen from '@assets/icons/checkbox-open-icon.svg';
import Grid from '@mui/material/Grid'
import Checkbox from './Checkbox';

import './HeaderCheckbox.css';

class HeaderCheckboxes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkboxes: this.props.totalColums.reduce(
				//reduce transforms ["User name","Estado"] array to { "User name": false, "Estado": false }
				(options, option) => ({
					...options,
					[option.key]: this.ObtainKeys(option.key),
					//[option.name]: this.ObtainKeys(option.key)
				}),
				{}
			),

			totalColums: this.props.totalColums,
		};
	}
	/**
	 * @desc change the state of all checkboxes to isSelected
	 *
	 **/

	selectAllCheckboxes = (isSelected) => {
		var i = 0;

		Object.keys(this.state.checkboxes).forEach((checkbox) => {
			var keys = Object.keys(this.state.checkboxes);

			if (keys[i] != 'iconWarning' && keys[i] != 'icon') {
				this.setState((prevState) => ({
					checkboxes: {
						...prevState.checkboxes,

						[checkbox]: isSelected,
					},
				}));
			}
			i++;
		});
	};

	ObtainKeys(keyValue) {
		let cookieObtain = this.props.cookieTable.get(this.props.cookieName);

		if (cookieObtain) {
			return cookieObtain.includes(keyValue);
		} else {
			return undefined;
		}
	}

	/**
	 * @desc Select all Checkboxes and pass true argument (not used but can be uselful)
	 *
	 **/
	selectAll = () => this.selectAllCheckboxes(true);

	/**
	 * @desc It calls this.setState to request an update of the state and passes a function
	 * that gets a previous state and returns a new state that is created based on the previous state
	 *
	 **/

	handleCheckboxChange = (changeEvent) => {
		const { name } = changeEvent.target;

		const id = document.getElementsByClassName(name)[0].id;

		this.setState((prevState) => ({
			checkboxes: {
				...prevState.checkboxes,
				[id]: !prevState.checkboxes[id],
			},
		}));
	};

	/**
	 * @desc This function check all checkboxes that are selected,
	 *  create the cookie that will contain the labels selected,
	 *  set the values in the cookie and execute the function
	 * on the parent component that will update the colums that
	 * will be show
	 *
	 **/

	//Check all check boxes that are selected and show the name in the console
	handleFormSubmit = (formSubmitEvent) => {
		//prevents the default behavior
		formSubmitEvent.preventDefault();
		let conca = '';

		Object.keys(this.state.checkboxes)
			.filter((checkbox) => this.state.checkboxes[checkbox])
			.forEach((checkbox) => {
				conca = conca.concat(checkbox + ' ');
			});

		this.props.cookieTable.set(this.props.cookieName, conca.trimRight(), { path: '/' });

		this.props.updateColums();
	};

	/**
	 * @desc This function execute for each element in the array
	 * also returns an array of three instances of Checkbox component
	 *
	 *
	 * @param {Object} option //Object
	 **/

	createCheckbox = (option) => {
		if (option.key != 'iconWarning' && option.key != 'icon') {
			return (
				<div className='checkbox col-sm-3 d-flex justify-content-start'>
					<Checkbox
						label={option.name} //text rendered next to a checkbox
						isSelected={this.state.checkboxes[option.key]} //tells Checkbox component whether it should render selected or deselected
						onCheckboxChange={this.handleCheckboxChange} //Every time user selects/deselects a checkbox React calls this.handleCheckboxChange function passing change event to it.
						keyN={option.key} // key property that React uses to uniquely identify that instance.
						key={option.key}
					/>
				</div>
			);
		}
	};

	/**
	 * @desc This function iterates over totalColums
	 * header and calls this.createCheckbox
	 * function for each item in that array.
	 *
	 * @param {array} totalColums //array with strings
	 **/

	createCheckboxes = () => this.props.totalColums.map(this.createCheckbox);

	/**
	 * @desc This function show the checkboxes
	 * when the botton is clicked.
	 *
	 **/

	showModal() {
		this.setState({
			showCheckBoxes: !this.state.showCheckBoxes,
		});
	}

	actionButtons = () => {
		return (
			<div className='row justify-content-end'>
				{this.props.customActions != null
					? this.props.customActions.map((action, key) => {
							return (
								<button
									key={key}
									id={'id-table-action-' + key}
									type='button'
									onClick={action.action}
									className='btn'
									title={action.toolTip}
									style={{ border: 'none'}}
								>
									<img src={action.icon} alt='' />
								</button>
							);
					  })
					: ''}

				{this.props.showArrangeOptions == true ? (
					<button
						id='id-table-showArrangeOptions'
						type='button'
						onClick={() => this.showModal()}
						className='btn margin-btnshowTable'
					>
						<img src={CheckBoxIconOpen} alt='' />
					</button>
				) : (
					''
				)}
			</div>
		);
	};

	render() {
		return (
			<React.Fragment>
				<Grid container direction='row' justifyContent='flex-end'>
					<Grid item xs='auto'>
						<this.actionButtons />
					</Grid>
				</Grid>

				{this.state.showCheckBoxes ? (
					<form onSubmit={this.handleFormSubmit} className='margin-form'>
						<p className='text-left form-txtQuestion'>{this.props.lan.questionWhatToSee}</p>

						<div className='row'>{this.createCheckboxes()}</div>
						<div className='form-group'>
							<div className='row d-flex justify-content-end'>
								<div className='col d-flex justify-content-end'>
									{
										<button
											type='submit'
											className='btn btn-outline-primary bntAll'
											onClick={this.selectAll}
										>
											{this.props.lan.selectAll}
										</button>
									}
								</div>
								<div className='margin-btnSave'>
									<button type='submit' className='btn btn-outline-primary btnSave btn-success'>
										{this.props.lan.save}
									</button>
								</div>
							</div>
						</div>
					</form>
				) : null}
			</React.Fragment>
		);
	}
}

export default React.memo(HeaderCheckboxes);
