import React, { Component } from 'react';

import alertIcon from '@assets/icons/danger-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { doActionCancelRouteRequest } from '@core/api/route/route.service';
import model from 'Object/model/model';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Popup from '../../Popup';
import '../MyOperation.css';

/**
 * TODO:
 *  Popup used to Cancel routes
 */
class CancelRoutesPopup extends Component {
	constructor(props) {
		super(props);

		let idUserAssigned = [];
		let cancelSuccess = true;

		let idRoutes = [];
		let idsSelected = [];

		for (var r = 0; r < this.props.routes.length; r++) {
			idRoutes.push(this.props.routes[r].id);
		}

		if (props.routes !== undefined && props.routes !== []) {
			for (var i = 0; i < props.routes.length; i++) {
				for (var j = 0; j < props.myIdRoutes.length; j++) {
					if (props.routes[i].id == props.myIdRoutes[j]) {
						idsSelected.push(props.idAgents[j]);
						break;
					}
				}
			}
		}

		this.state = {
			idAgents: idsSelected,
			idRoutes: idRoutes,
			idUserAssigned: idUserAssigned,
			showErrorMessage: false,
			showErrorMessageText: '',
			showErrorMessageIcon: null,
			cancelSuccess: cancelSuccess,
			comment: '',
		};
	}

	manageCancelRoutes = (res) => {
		let request = res;

		if (request.success === true) {
			this.props.onClose(true);
		} else {
			this.props.onClose(false);
		}
	};

	cancelRoutes = (e) => {
		e.preventDefault();

		let body = model.getRequestCancelRoutes(
			this.state.idRoutes,
			this.state.idAgents,
			this.state.comment
		);
		
		const onSuccess = (response) => {
			this.manageCancelRoutes(response);
		}

		const onError = (response) => {
			this.props.onClose(false);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		doActionCancelRouteRequest(body, onSuccess, onError, loadingConfig);

	};

	render() {
		return (
			<React.Fragment>
				<Popup
					closeButton
					onClose={this.props.onClose}
					requiredFields={this.props.lan.requiredFields}
					buttonText={this.props.lan.accept}
					buttonOnClick={this.cancelRoutes}
					size={'md'}
				>
					<Row>
						<Col sm={12}>
							<div className='mt-1'>
								<Row className='d-flex justify-content-center mb-4'>
									<img src={alertIcon} className='danger'></img>
								</Row>
							</div>
							<div className='mt-1 '>
								<Row>
									<Col className='confirmation-text'>{this.props.lan.cancelRouteConfirmation} </Col>
								</Row>
							</div>
							<div className='mt-1 '>
								<Row>
									<Col className='confirmation-text'>{this.props.lan.cancelRouteMessage} </Col>
								</Row>
							</div>

							<div className='mt-1 '>
								<Row>
									<Col className='confirmation-text'>
										<textarea
											className='form-control  text-left mt-1 mb-1'
											maxLength='255'
											rows='5'
											cols='20'
											id='description-red-input'
											placeholder={this.props.lan.cancelComment}
											value={this.state.comment}
											onChange={(e) => {
												this.setState({
													comment: e.target.value,
												});
											}}
										></textarea>
									</Col>
								</Row>
							</div>

							<div className='mt-3'>
								<Row>
									<img
										src={redAlertIcon}
										alt=''
										id='comment-alert-icon'
										className='field-alert-icon'
									></img>
								</Row>
							</div>
						</Col>
					</Row>
				</Popup>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(CancelRoutesPopup);
