const LANGUAGE_EN	= "en_US";
const LANGUAGE_ES	= "es_ES";
const LANGUAGE_MX	= "es_MX";
const LANGUAGE_PT   = "pt_BR";
const LANGUAGE_LATAM = "es_419";

const LANGUAGE = {
	en:LANGUAGE_EN,
	es:LANGUAGE_ES,
	mx:LANGUAGE_MX,
	pt: LANGUAGE_PT,
	latam: LANGUAGE_LATAM
};

export default LANGUAGE;
