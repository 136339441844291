import React from 'react';
import { Row, Col } from 'react-bootstrap';
import TextareaAutosize from 'react-autosize-textarea';

class AprovedTextInfo extends React.Component {
  	
	state = {
        shouldRenderForm: false,
    };

	componentDidMount() {

        this.updateShouldRenderForm();
    }

	componentDidUpdate(prevProps) {
		if (prevProps.form !== this.props.form || prevProps.question !== this.props.question) {
            this.updateShouldRenderForm();
        }
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.form !== this.props.form ||
			nextProps.question !== this.props.question ||
			nextState.shouldRenderForm !== this.state.shouldRenderForm
		);
	}

    updateShouldRenderForm() {
        const { form, question } = this.props;
        const shouldRenderForm = form && form.reviewable && (question.approved === null || question.approved === false);
        this.setState({ shouldRenderForm });
    }

	render() {
        const { form, question, index, updateQuestionApprovedReason, lan, id } = this.props;
		const { shouldRenderForm } = this.state;

        return (
            shouldRenderForm && (
				<Row id={id}>
					<Col sm={2} className="reason-label" style={{color:'red'}}>{lan.reasonForNotApproved}</Col>
                    <Col className="mt-2">
                        <TextareaAutosize
                            type='text'
                            className='task-details-input'
                            defaultValue={question.notApprovedReason}
                            onChange={(e) => updateQuestionApprovedReason(index, question, e.target.value)}
							style={{	
								width: '100%',
								paddingTop: '5px',
								paddingLeft: '10px',
								paddingBottom: '5px',
								paddingRight: '10px',
							}}
						/>
                    </Col>
                </Row>
            )
        );
    }
}
export default AprovedTextInfo;



