import { DeleteRedColor, Gainsboro, IndianRed } from '@core/theme/baseTheme';
import { OutlinedInput, FormHelperText, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const ERROR_COLOR = DeleteRedColor;

export const FielderTextField = styled(OutlinedInput)`
	border-radius: 12px !important;
	&.Mui-error {
		border-color: ${ERROR_COLOR} !important;
		&.MuiOutlinedInput-notchedOutline {
			border-color: ${ERROR_COLOR} !important;
		}
	}
`

export const HelperText = styled(FormHelperText)`
	&.Mui-error {
		color: ${ERROR_COLOR} !important;
	}
`;

export const FielderInputLabel = styled(InputLabel)`
	&.Mui-error {
		color: ${ERROR_COLOR} !important;
	}
`;