import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';


class LayeredColumn extends Graph{
    constructor(data,props){
        super(props);
        this.props = props;
        this.data = data;
    }

    createGraph(params){
        let chart = am4core.create(params.id, am4charts.XYChart);


        let category = params.category;

        let valueY1= params.valueY1;
        let valueY2= params.valueY2;
        let text1= params.text1;
        let text2=params.text2;

        chart.data = this.data;

		// Create axes
		super.setAxesLayeredColumn(chart, category,text1,text2);

        //Series

		super.setLayeredColumnSeriesParams(chart, category,valueY1,valueY2,text1,text2);


		var legend = params.legend;
		super.setChartProperties(chart, legend, true,false);

        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default LayeredColumn