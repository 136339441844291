import {
	SuperAdmin,
	SuperAdminViewer,
	Admin,
	Manager,
	Client,
	RootGlobalAccountUser,
	ClientGlobalAccountUser,
	InternalAdmin,
} from './roles';

const getRolePages = (role) => {
	switch (role) {
		case SuperAdmin.name:
			return SuperAdmin.pages;
		case SuperAdminViewer.name:
			return SuperAdminViewer.pages;
		case Admin.name:
			return Admin.pages;
		case Manager.name:
			return Manager.pages;
		case RootGlobalAccountUser.name:
			return RootGlobalAccountUser.pages;
		case ClientGlobalAccountUser.name:
			return ClientGlobalAccountUser.pages;
		case InternalAdmin.name:
			return InternalAdmin.pages;
		case Client.name:
			return Client.pages;
		default:
			return [];
	}
};

const isPageInRole = (role, page) => validateRole(role, page);

const validateRole = (role, page) => {
	const pages = getRolePages(role);

	return {
		isValid: pages.includes(page),
		forbidden: role !== Client.name,
	};
};

function isUserSuperAdministrator(user) {
	return user !== null && user !== undefined && user.role === SuperAdmin.name;
}

function isUserAdministrator(user) {
	return user !== null && user !== undefined && user.role === Admin.name;
}

function isUserSuperAndAdministrator(user) {
	return user !== null && user !== undefined && (user.role === Admin.name || user.role === SuperAdmin.name);
}

function isUserManager(user) {
	return user !== null && user !== undefined && user.role === Manager.name;
}

function isUserClient(user) {
	return user !== null && user !== undefined && user.role === Client.name;
}

function isRootGlobalAccountUser(user) {
	return user !== null && user !== undefined && user.role === RootGlobalAccountUser.name;
}

function isClientGlobalAccountUser(user) {
	return user !== null && user !== undefined && user.role === ClientGlobalAccountUser.name;
}

function isInternalAdminUser(user) {
	return user !== null && user !== undefined && user.role === InternalAdmin.name;
}

function isUserSuperAdministratorAndSuperAdminViewer(user) {
	return user !== null && user !== undefined && (user.role === SuperAdminViewer.name || user.role === SuperAdmin.name);
}

function isUserSuperAdministratorViewer(user) {
	return user !== null && user !== undefined && user.role === SuperAdminViewer.name;
}
function isUserSuperAndSuperViewerAndAdministrator(user) {
	return (
		user !== null &&
		user !== undefined &&
		(user.role === Admin.name || user.role === SuperAdmin.name || user.role === SuperAdminViewer.name)
	);
}

export default {
	getRolePages,
	isPageInRole,
	validateRole,
	isUserSuperAdministrator,
	isUserAdministrator,
	isUserManager,
	isUserClient,
	isUserSuperAndAdministrator,
	isRootGlobalAccountUser,
	isClientGlobalAccountUser,
	isInternalAdminUser,
	isUserSuperAdministratorViewer,
	isUserSuperAndSuperViewerAndAdministrator,
	isUserSuperAdministratorAndSuperAdminViewer,
};
