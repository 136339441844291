export default ({
		USD: {
		  name: 'US Dollar',
		  symbol: '$',
		  symbolNative: '$',
		  code: 'USD',
		  namePlural: 'US dollars'
		},
		CAD: {
		  name: 'Canadian Dollar',
		  symbol: 'CA$',
		  symbolNative: '$',
		  code: 'CAD',
		  namePlural: 'Canadian dollars'
		},
		EUR: {
		  name: 'Euro',
		  symbol: '€',
		  symbolNative: '€',
		  code: 'EUR',
		  namePlural: 'euros'
		},
		AED: {
		  name: 'United Arab Emirates Dirham',
		  symbol: 'AED',
		  symbolNative: 'د.إ.‏',
		  code: 'AED',
		  namePlural: 'UAE dirhams'
		},
		AFN: {
		  name: 'Afghan Afghani',
		  symbol: 'Af',
		  symbolNative: '؋',
		  code: 'AFN',
		  namePlural: 'Afghan Afghanis'
		},
		ALL: {
		  name: 'Albanian Lek',
		  symbol: 'ALL',
		  symbolNative: 'Lek',
		  code: 'ALL',
		  namePlural: 'Albanian lekë'
		},
		AMD: {
		  name: 'Armenian Dram',
		  symbol: 'AMD',
		  symbolNative: 'դր.',
		  code: 'AMD',
		  namePlural: 'Armenian drams'
		},
		ARS: {
		  name: 'Argentine Peso',
		  symbol: 'AR$',
		  symbolNative: '$',
		  code: 'ARS',
		  namePlural: 'Argentine pesos'
		},
		AUD: {
		  name: 'Australian Dollar',
		  symbol: 'AU$',
		  symbolNative: '$',
		  code: 'AUD',
		  namePlural: 'Australian dollars'
		},
		AZN: {
		  name: 'Azerbaijani Manat',
		  symbol: 'man.',
		  symbolNative: 'ман.',
		  code: 'AZN',
		  namePlural: 'Azerbaijani manats'
		},
		BAM: {
		  name: 'Bosnia-Herzegovina Convertible Mark',
		  symbol: 'KM',
		  symbolNative: 'KM',
		  code: 'BAM',
		  namePlural: 'Bosnia-Herzegovina convertible marks'
		},
		BDT: {
		  name: 'Bangladeshi Taka',
		  symbol: 'Tk',
		  symbolNative: '৳',
		  code: 'BDT',
		  namePlural: 'Bangladeshi takas'
		},
		BGN: {
		  name: 'Bulgarian Lev',
		  symbol: 'BGN',
		  symbolNative: 'лв.',
		  code: 'BGN',
		  namePlural: 'Bulgarian leva'
		},
		BHD: {
		  name: 'Bahraini Dinar',
		  symbol: 'BD',
		  symbolNative: 'د.ب.‏',
		  code: 'BHD',
		  namePlural: 'Bahraini dinars'
		},
		BIF: {
		  name: 'Burundian Franc',
		  symbol: 'FBu',
		  symbolNative: 'FBu',
		  code: 'BIF',
		  namePlural: 'Burundian francs'
		},
		BND: {
		  name: 'Brunei Dollar',
		  symbol: 'BN$',
		  symbolNative: '$',
		  code: 'BND',
		  namePlural: 'Brunei dollars'
		},
		BOB: {
		  name: 'Bolivian Boliviano',
		  symbol: 'Bs',
		  symbolNative: 'Bs',
		  code: 'BOB',
		  namePlural: 'Bolivian bolivianos'
		},
		BRL: {
		  name: 'Brazilian Real',
		  symbol: 'R$',
		  symbolNative: 'R$',
		  code: 'BRL',
		  namePlural: 'Brazilian reals'
		},
		BWP: {
		  name: 'Botswanan Pula',
		  symbol: 'BWP',
		  symbolNative: 'P',
		  code: 'BWP',
		  namePlural: 'Botswanan pulas'
		},
		BYN: {
		  name: 'Belarusian Ruble',
		  symbol: 'Br',
		  symbolNative: 'руб.',
		  code: 'BYN',
		  namePlural: 'Belarusian rubles'
		},
		BZD: {
		  name: 'Belize Dollar',
		  symbol: 'BZ$',
		  symbolNative: '$',
		  code: 'BZD',
		  namePlural: 'Belize dollars'
		},
		CDF: {
		  name: 'Congolese Franc',
		  symbol: 'CDF',
		  symbolNative: 'FrCD',
		  code: 'CDF',
		  namePlural: 'Congolese francs'
		},
		CHF: {
		  name: 'Swiss Franc',
		  symbol: 'CHF',
		  symbolNative: 'CHF',
		  code: 'CHF',
		  namePlural: 'Swiss francs'
		},
		CLP: {
		  name: 'Chilean Peso',
		  symbol: 'CL$',
		  symbolNative: '$',
		  code: 'CLP',
		  namePlural: 'Chilean pesos'
		},
		CNY: {
		  name: 'Chinese Yuan',
		  symbol: 'CN¥',
		  symbolNative: 'CN¥',
		  code: 'CNY',
		  namePlural: 'Chinese yuan'
		},
		COP: {
		  name: 'Colombian Peso',
		  symbol: 'CO$',
		  symbolNative: '$',
		  code: 'COP',
		  namePlural: 'Colombian pesos'
		},
		CRC: {
		  name: 'Costa Rican Colón',
		  symbol: '₡',
		  symbolNative: '₡',
		  code: 'CRC',
		  namePlural: 'Costa Rican colóns'
		},
		CVE: {
		  name: 'Cape Verdean Escudo',
		  symbol: 'CV$',
		  symbolNative: 'CV$',
		  code: 'CVE',
		  namePlural: 'Cape Verdean escudos'
		},
		CZK: {
		  name: 'Czech Republic Koruna',
		  symbol: 'Kč',
		  symbolNative: 'Kč',
		  code: 'CZK',
		  namePlural: 'Czech Republic korunas'
		},
		DJF: {
		  name: 'Djiboutian Franc',
		  symbol: 'Fdj',
		  symbolNative: 'Fdj',
		  code: 'DJF',
		  namePlural: 'Djiboutian francs'
		},
		DKK: {
		  name: 'Danish Krone',
		  symbol: 'Dkr',
		  symbolNative: 'kr',
		  code: 'DKK',
		  namePlural: 'Danish kroner'
		},
		DOP: {
		  name: 'Dominican Peso',
		  symbol: 'RD$',
		  symbolNative: 'RD$',
		  code: 'DOP',
		  namePlural: 'Dominican pesos'
		},
		DZD: {
		  name: 'Algerian Dinar',
		  symbol: 'DA',
		  symbolNative: 'د.ج.‏',
		  code: 'DZD',
		  namePlural: 'Algerian dinars'
		},
		EEK: {
		  name: 'Estonian Kroon',
		  symbol: 'Ekr',
		  symbolNative: 'kr',
		  code: 'EEK',
		  namePlural: 'Estonian kroons'
		},
		EGP: {
		  name: 'Egyptian Pound',
		  symbol: 'EGP',
		  symbolNative: 'ج.م.‏',
		  code: 'EGP',
		  namePlural: 'Egyptian pounds'
		},
		ERN: {
		  name: 'Eritrean Nakfa',
		  symbol: 'Nfk',
		  symbolNative: 'Nfk',
		  code: 'ERN',
		  namePlural: 'Eritrean nakfas'
		},
		ETB: {
		  name: 'Ethiopian Birr',
		  symbol: 'Br',
		  symbolNative: 'Br',
		  code: 'ETB',
		  namePlural: 'Ethiopian birrs'
		},
		GBP: {
		  name: 'British Pound Sterling',
		  symbol: '£',
		  symbolNative: '£',
		  code: 'GBP',
		  namePlural: 'British pounds sterling'
		},
		GEL: {
		  name: 'Georgian Lari',
		  symbol: 'GEL',
		  symbolNative: 'GEL',
		  code: 'GEL',
		  namePlural: 'Georgian laris'
		},
		GHS: {
		  name: 'Ghanaian Cedi',
		  symbol: 'GH₵',
		  symbolNative: 'GH₵',
		  code: 'GHS',
		  namePlural: 'Ghanaian cedis'
		},
		GNF: {
		  name: 'Guinean Franc',
		  symbol: 'FG',
		  symbolNative: 'FG',
		  code: 'GNF',
		  namePlural: 'Guinean francs'
		},
		GTQ: {
		  name: 'Guatemalan Quetzal',
		  symbol: 'GTQ',
		  symbolNative: 'Q',
		  code: 'GTQ',
		  namePlural: 'Guatemalan quetzals'
		},
		HKD: {
		  name: 'Hong Kong Dollar',
		  symbol: 'HK$',
		  symbolNative: '$',
		  code: 'HKD',
		  namePlural: 'Hong Kong dollars'
		},
		HNL: {
		  name: 'Honduran Lempira',
		  symbol: 'HNL',
		  symbolNative: 'L',
		  code: 'HNL',
		  namePlural: 'Honduran lempiras'
		},
		HRK: {
		  name: 'Croatian Kuna',
		  symbol: 'kn',
		  symbolNative: 'kn',
		  code: 'HRK',
		  namePlural: 'Croatian kunas'
		},
		HUF: {
		  name: 'Hungarian Forint',
		  symbol: 'Ft',
		  symbolNative: 'Ft',
		  code: 'HUF',
		  namePlural: 'Hungarian forints'
		},
		IDR: {
		  name: 'Indonesian Rupiah',
		  symbol: 'Rp',
		  symbolNative: 'Rp',
		  code: 'IDR',
		  namePlural: 'Indonesian rupiahs'
		},
		ILS: {
		  name: 'Israeli New Sheqel',
		  symbol: '₪',
		  symbolNative: '₪',
		  code: 'ILS',
		  namePlural: 'Israeli new sheqels'
		},
		INR: {
		  name: 'Indian Rupee',
		  symbol: 'Rs',
		  symbolNative: 'টকা',
		  code: 'INR',
		  namePlural: 'Indian rupees'
		},
		IQD: {
		  name: 'Iraqi Dinar',
		  symbol: 'IQD',
		  symbolNative: 'د.ع.‏',
		  code: 'IQD',
		  namePlural: 'Iraqi dinars'
		},
		IRR: {
		  name: 'Iranian Rial',
		  symbol: 'IRR',
		  symbolNative: '﷼',
		  code: 'IRR',
		  namePlural: 'Iranian rials'
		},
		ISK: {
		  name: 'Icelandic Króna',
		  symbol: 'Ikr',
		  symbolNative: 'kr',
		  code: 'ISK',
		  namePlural: 'Icelandic krónur'
		},
		JMD: {
		  name: 'Jamaican Dollar',
		  symbol: 'J$',
		  symbolNative: '$',
		  code: 'JMD',
		  namePlural: 'Jamaican dollars'
		},
		JOD: {
		  name: 'Jordanian Dinar',
		  symbol: 'JD',
		  symbolNative: 'د.أ.‏',
		  code: 'JOD',
		  namePlural: 'Jordanian dinars'
		},
		JPY: {
		  name: 'Japanese Yen',
		  symbol: '¥',
		  symbolNative: '￥',
		  code: 'JPY',
		  namePlural: 'Japanese yen'
		},
		KES: {
		  name: 'Kenyan Shilling',
		  symbol: 'Ksh',
		  symbolNative: 'Ksh',
		  code: 'KES',
		  namePlural: 'Kenyan shillings'
		},
		KHR: {
		  name: 'Cambodian Riel',
		  symbol: 'KHR',
		  symbolNative: '៛',
		  code: 'KHR',
		  namePlural: 'Cambodian riels'
		},
		KMF: {
		  name: 'Comorian Franc',
		  symbol: 'CF',
		  symbolNative: 'FC',
		  code: 'KMF',
		  namePlural: 'Comorian francs'
		},
		KRW: {
		  name: 'South Korean Won',
		  symbol: '₩',
		  symbolNative: '₩',
		  code: 'KRW',
		  namePlural: 'South Korean won'
		},
		KWD: {
		  name: 'Kuwaiti Dinar',
		  symbol: 'KD',
		  symbolNative: 'د.ك.‏',
		  code: 'KWD',
		  namePlural: 'Kuwaiti dinars'
		},
		KZT: {
		  name: 'Kazakhstani Tenge',
		  symbol: 'KZT',
		  symbolNative: 'тңг.',
		  code: 'KZT',
		  namePlural: 'Kazakhstani tenges'
		},
		LBP: {
		  name: 'Lebanese Pound',
		  symbol: 'LB£',
		  symbolNative: 'ل.ل.‏',
		  code: 'LBP',
		  namePlural: 'Lebanese pounds'
		},
		LKR: {
		  name: 'Sri Lankan Rupee',
		  symbol: 'SLRs',
		  symbolNative: 'SL Re',
		  code: 'LKR',
		  namePlural: 'Sri Lankan rupees'
		},
		LTL: {
		  name: 'Lithuanian Litas',
		  symbol: 'Lt',
		  symbolNative: 'Lt',
		  code: 'LTL',
		  namePlural: 'Lithuanian litai'
		},
		LVL: {
		  name: 'Latvian Lats',
		  symbol: 'Ls',
		  symbolNative: 'Ls',
		  code: 'LVL',
		  namePlural: 'Latvian lati'
		},
		LYD: {
		  name: 'Libyan Dinar',
		  symbol: 'LD',
		  symbolNative: 'د.ل.‏',
		  code: 'LYD',
		  namePlural: 'Libyan dinars'
		},
		MAD: {
		  name: 'Moroccan Dirham',
		  symbol: 'MAD',
		  symbolNative: 'د.م.‏',
		  code: 'MAD',
		  namePlural: 'Moroccan dirhams'
		},
		MDL: {
		  name: 'Moldovan Leu',
		  symbol: 'MDL',
		  symbolNative: 'MDL',
		  code: 'MDL',
		  namePlural: 'Moldovan lei'
		},
		MGA: {
		  name: 'Malagasy Ariary',
		  symbol: 'MGA',
		  symbolNative: 'MGA',
		  code: 'MGA',
		  namePlural: 'Malagasy Ariaries'
		},
		MKD: {
		  name: 'Macedonian Denar',
		  symbol: 'MKD',
		  symbolNative: 'MKD',
		  code: 'MKD',
		  namePlural: 'Macedonian denari'
		},
		MMK: {
		  name: 'Myanma Kyat',
		  symbol: 'MMK',
		  symbolNative: 'K',
		  code: 'MMK',
		  namePlural: 'Myanma kyats'
		},
		MOP: {
		  name: 'Macanese Pataca',
		  symbol: 'MOP$',
		  symbolNative: 'MOP$',
		  code: 'MOP',
		  namePlural: 'Macanese patacas'
		},
		MUR: {
		  name: 'Mauritian Rupee',
		  symbol: 'MURs',
		  symbolNative: 'MURs',
		  code: 'MUR',
		  namePlural: 'Mauritian rupees'
		},
		MXN: {
		  name: 'Mexican Peso',
		  symbol: 'MX$',
		  symbolNative: '$',
		  code: 'MXN',
		  namePlural: 'Mexican pesos'
		},
		MYR: {
		  name: 'Malaysian Ringgit',
		  symbol: 'RM',symbolNative: 'RM',
		  code: 'MYR',
		  namePlural: 'Malaysian ringgits'
		},
		MZN: {
		  name: 'Mozambican Metical',
		  symbol: 'MTn',
		  symbolNative: 'MTn',
		  code: 'MZN',
		  namePlural: 'Mozambican meticals'
		},
		NAD: {
		  name: 'Namibian Dollar',
		  symbol: 'N$',
		  symbolNative: 'N$',
		  code: 'NAD',
		  namePlural: 'Namibian dollars'
		},
		NGN: {
		  name: 'Nigerian Naira',
		  symbol: '₦',
		  symbolNative: '₦',
		  code: 'NGN',
		  namePlural: 'Nigerian nairas'
		},
		NIO: {
		  name: 'Nicaraguan Córdoba',
		  symbol: 'C$',
		  symbolNative: 'C$',
		  code: 'NIO',
		  namePlural: 'Nicaraguan córdobas'
		},
		NOK: {
		  name: 'Norwegian Krone',
		  symbol: 'Nkr',
		  symbolNative: 'kr',
		  code: 'NOK',
		  namePlural: 'Norwegian kroner'
		},
		NPR: {
		  name: 'Nepalese Rupee',
		  symbol: 'NPRs',
		  symbolNative: 'नेरू',
		  code: 'NPR',
		  namePlural: 'Nepalese rupees'
		},
		NZD: {
		  name: 'New Zealand Dollar',
		  symbol: 'NZ$',
		  symbolNative: '$',
		  code: 'NZD',
		  namePlural: 'New Zealand dollars'
		},
		OMR: {
		  name: 'Omani Rial',
		  symbol: 'OMR',
		  symbolNative: 'ر.ع.‏',
		  code: 'OMR',
		  namePlural: 'Omani rials'
		},
		PAB: {
		  name: 'Panamanian Balboa',
		  symbol: 'B/.',
		  symbolNative: 'B/.',
		  code: 'PAB',
		  namePlural: 'Panamanian balboas'
		},
		PEN: {
		  name: 'Peruvian Nuevo Sol',
		  symbol: 'S/.',
		  symbolNative: 'S/.',
		  code: 'PEN',
		  namePlural: 'Peruvian nuevos soles'
		},
		PHP: {
		  name: 'Philippine Peso',
		  symbol: '₱',
		  symbolNative: '₱',
		  code: 'PHP',
		  namePlural: 'Philippine pesos'
		},
		PKR: {
		  name: 'Pakistani Rupee',
		  symbol: 'PKRs',
		  symbolNative: '₨',
		  code: 'PKR',
		  namePlural: 'Pakistani rupees'
		},
		PLN: {
		  name: 'Polish Zloty',
		  symbol: 'zł',
		  symbolNative: 'zł',
		  code: 'PLN',
		  namePlural: 'Polish zlotys'
		},
		PYG: {
		  name: 'Paraguayan Guarani',
		  symbol: '₲',
		  symbolNative: '₲',
		  code: 'PYG',
		  namePlural: 'Paraguayan guaranis'
		},
		QAR: {
		  name: 'Qatari Rial',
		  symbol: 'QR',
		  symbolNative: 'ر.ق.‏',
		  code: 'QAR',
		  namePlural: 'Qatari rials'
		},
		RON: {
		  name: 'Romanian Leu',
		  symbol: 'RON',
		  symbolNative: 'RON',
		  code: 'RON',
		  namePlural: 'Romanian lei'
		},
		RSD: {
		  name: 'Serbian Dinar',
		  symbol: 'din.',
		  symbolNative: 'дин.',
		  code: 'RSD',
		  namePlural: 'Serbian dinars'
		},
		RUB: {
		  name: 'Russian Ruble',
		  symbol: 'RUB',
		  symbolNative: '₽.',
		  code: 'RUB',
		  namePlural: 'Russian rubles'
		},
		RWF: {
		  name: 'Rwandan Franc',
		  symbol: 'RWF',
		  symbolNative: 'FR',
		  code: 'RWF',
		  namePlural: 'Rwandan francs'
		},
		SAR: {
		  name: 'Saudi Riyal',
		  symbol: 'SR',
		  symbolNative: 'ر.س.‏',
		  code: 'SAR',
		  namePlural: 'Saudi riyals'
		},
		SDG: {
		  name: 'Sudanese Pound',
	  symbol: 'SDG',
		  symbolNative: 'SDG',
		  code: 'SDG',
		  namePlural: 'Sudanese pounds'
		},
		SEK: {
		  name: 'Swedish Krona',
		  symbol: 'Skr',
		  symbolNative: 'kr',
		  code: 'SEK',
		  namePlural: 'Swedish kronor'
		},
		SGD: {
		  name: 'Singapore Dollar',
		  symbol: 'S$',
		  symbolNative: '$',
		  code: 'SGD',
		  namePlural: 'Singapore dollars'
		},
		SOS: {
		  name: 'Somali Shilling',
		  symbol: 'Ssh',
		  symbolNative: 'Ssh',
		  code: 'SOS',
		  namePlural: 'Somali shillings'
		},
		SYP: {
		  name: 'Syrian Pound',
		  symbol: 'SY£',
		  symbolNative: 'ل.س.‏',
		  code: 'SYP',
		  namePlural: 'Syrian pounds'
		},
		THB: {
		  name: 'Thai Baht',
		  symbol: '฿',
		  symbolNative: '฿',
		  code: 'THB',
		  namePlural: 'Thai baht'
		},
		TND: {
		  name: 'Tunisian Dinar',
		  symbol: 'DT',
		  symbolNative: 'د.ت.‏',
		  code: 'TND',
		  namePlural: 'Tunisian dinars'
		},
		TOP: {
		  name: 'Tongan Paʻanga',
		  symbol: 'T$',
		  symbolNative: 'T$',
		  code: 'TOP',
		  namePlural: 'Tongan paʻanga'
		},
		TRY: {
		  name: 'Turkish Lira',
		  symbol: 'TL',
		  symbolNative: 'TL',
		  code: 'TRY',
		  namePlural: 'Turkish Lira'
		},
		TTD: {
		  name: 'Trinidad and Tobago Dollar',
		  symbol: 'TT$',
		  symbolNative: '$',
		  code: 'TTD',
		  namePlural: 'Trinidad and Tobago dollars'
		},
		TWD: {
		  name: 'New Taiwan Dollar',
		  symbol: 'NT$',
		  symbolNative: 'NT$',
		  code: 'TWD',
		  namePlural: 'New Taiwan dollars'
		},
		TZS: {
		  name: 'Tanzanian Shilling',
		  symbol: 'TSh',
		  symbolNative: 'TSh',
		  code: 'TZS',
		  namePlural: 'Tanzanian shillings'
		},
		UAH: {
		  name: 'Ukrainian Hryvnia',
		  symbol: '₴',
		  symbolNative: '₴',
		  code: 'UAH',
		  namePlural: 'Ukrainian hryvnias'
		},
		UGX: {
		  name: 'Ugandan Shilling',
		  symbol: 'USh',
		  symbolNative: 'USh',
		  code: 'UGX',
		  namePlural: 'Ugandan shillings'
		},
		UYU: {
		  name: 'Uruguayan Peso',
		  symbol: '$U',
		  symbolNative: '$',
		  code: 'UYU',
		  namePlural: 'Uruguayan pesos'
		},
		UZS: {
		  name: 'Uzbekistan Som',
		  symbol: 'UZS',
		  symbolNative: 'UZS',
		  code: 'UZS',
		  namePlural: 'Uzbekistan som'
		},
		VEF: {
		  name: 'Venezuelan Bolívar',
		  symbol: 'Bs.F.',
		  symbolNative: 'Bs.F.',
		  code: 'VEF',
		  namePlural: 'Venezuelan bolívars'
		},
		VND: {
		  name: 'Vietnamese Dong',
		  symbol: '₫',
		  symbolNative: '₫',
		  code: 'VND',
		  namePlural: 'Vietnamese dong'
		},
		XAF: {
		  name: 'CFA Franc BEAC',
		  symbol: 'FCFA',
		  symbolNative: 'FCFA',
		  code: 'XAF',
		  namePlural: 'CFA francs BEAC'
		},
		XOF: {
		  name: 'CFA Franc BCEAO',
		  symbol: 'CFA',
		  symbolNative: 'CFA',
		  code: 'XOF',
		  namePlural: 'CFA francs BCEAO'
		},
		YER: {
		  name: 'Yemeni Rial',
		  symbol: 'YR',
		  symbolNative: 'ر.ي.‏',
		  code: 'YER',
		  namePlural: 'Yemeni rials'
		},
		ZAR: {
		  name: 'South African Rand',
		  symbol: 'R',
		  symbolNative: 'R',
		  code: 'ZAR',
		  namePlural: 'South African rand'
		},
		ZMK: {
		  name: 'Zambian Kwacha',
		  symbol: 'ZK',
		  symbolNative: 'ZK',
		  code: 'ZMK',
		  namePlural: 'Zambian kwachas'
		},
		ZWL: {
		  name: 'Zimbabwean Dollar',
		  symbol: 'ZWL$',
		  symbolNative: 'ZWL$',
		  code: 'ZWL',
		  namePlural: 'Zimbabwean Dollar'
		}
	  })