import en_US_locale from 'antd/lib/locale/en_US';
import es_ES_locale from 'antd/lib/locale/es_ES';
import pt_BR_locale from 'antd/lib/locale/pt_BR';

const getMomentLocale = (lan) => {
	switch (lan) {
		case 'es_MX':
			return es_ES_locale;
		case 'pt_BR':
			return pt_BR_locale;
		default:
			return en_US_locale;
	}
};

export default getMomentLocale;
