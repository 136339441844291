// ROLE MANAGEMENT
const PAGE_NAME = 'SMS';
const SMS = 'sms';
const NAME = 'sms';
const ACCOUNT_PREFERENCES = 'showSms';
const VIEW = 0;
const EDIT = 1;

export default {
	PAGE_NAME,
	NAME,
	SMS,
	ACCOUNT_PREFERENCES,
	VIEW,
	EDIT,
};
