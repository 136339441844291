import React, { Component } from 'react';

import loadingLottie from '@assets/lotties/loadingFielder.json';
import Lottie from 'react-lottie';

import GraphComponent from '../Graph/GraphComponent';

class TaskCompanyBranchGraph extends Component {
	render() {
		return (
			<div
				className={
					this.props.isClient
						? 'Dashboard-taskCompanyBranchGraph-Client Dashboard-box'
						: 'Dashboard-taskCompanyBranchGraph Dashboard-box'
				}
			>
				<div className='Dashboard-box-content-graph mt-4'>
					<div className='col-auto'>
						<p className='Dashboard-box-title'>{this.props.lan.serviceStatus}</p>
					</div>

					{this.props.renderGraphPieComponent ? (
						<>
							<GraphComponent
								{...this.props}
								type='pieDashboard'
								data={this.props.chartDataPie}
								params={this.props.tasksCompanyPieParams}
							/>
							<div className='row d-flex justify-content-center mt-2'>
								<p className='textTotalCount-graph mb-0'>{`${this.props.totalCountGraph} ${this.props.lan.tasks}`}</p>
							</div>
						</>
					) : (
						<div className='row'>
							<div className='col-12 d-flex justify-content-center no-padding marginLoadingGraphTask'>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: loadingLottie,
									}}
									height='40px'
									width='40px'
								/>
							</div>
							<div className='col d-flex justify-content-center no-padding'>{this.props.lan.loading}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default TaskCompanyBranchGraph;
