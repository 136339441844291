import React from 'react';

import { InfoWindowF as GoogleMapsInfoWindow } from '@react-google-maps/api';

const InfoWindow = ({ infoWindowData, ...props }) => {
	if (infoWindowData) {
		const { content, position, onCloseClick } = infoWindowData;
		return (
			<GoogleMapsInfoWindow position={position} onCloseClick={onCloseClick}>
				{content}
			</GoogleMapsInfoWindow>
		);
	}

	return null;
};

export default React.memo(InfoWindow);
