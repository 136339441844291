import { DarkCultured, DeepSpaceGrey } from "@core/theme/baseTheme";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextDetailField = styled(Typography)`
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	min-height: ${({ theme }) => `calc(0.6rem +  ${theme.typography.body1.lineHeight}*${theme.typography.body1.fontSize})`};
	background-color: ${DarkCultured};
	color: ${DeepSpaceGrey};
	padding-left: 0.5rem;
	font-size: ${({ theme }) => theme.typography.body1.fontSize};
`
export const TextDetailLabel = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.caption.fontSize};
	color: ${DeepSpaceGrey};
	padding-left: 0.5rem;
`;