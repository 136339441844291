import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Grid, TableBody, TableHead, TableRow } from '@mui/material';
import TreeTableRow from './components/TreeTableRow';
import { HeaderCell, NoItemsInTableTitle } from './TreeTable.styles';
import PropTypes from 'prop-types';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const TreeTable = ({ columns, data, CellComponent, width, noItemsText }) => {
	const [expandedRows, setExpandedRows] = useState(new Set());
	const lan = useLanguage();

	const handleToggleParent = (parentId) => {
		let newExpandedRows = expandedRows;

		if (newExpandedRows.has(parentId)) {
			newExpandedRows.delete(parentId);
		} else {
			newExpandedRows.add(parentId);
		}

		setExpandedRows(new Set(newExpandedRows));
	};

	const Rows = React.useMemo(() => {
		return (
			<AnimatePresence key='tree-table-rows'>
				{data.map((row) => (
					<TreeTableRow
						key={`${row.id}-row`}
						row={row}
						columns={columns}
						CellComponent={CellComponent}
						onToggleParent={handleToggleParent}
						expandedRows={expandedRows}
						level={0}
						isOpen={expandedRows.has(row.id)}
					/>
				))}
			</AnimatePresence>
		);
	}, [data, expandedRows]);

	if (data.length === 0) {
		return (
			<Grid container justifyContent='center' className='my-2'>
				<Grid item xs='auto'>
					<NoItemsInTableTitle>
						<WebAssetOffIcon className='mr-2' />
						{noItemsText ? noItemsText : lan.noData}
					</NoItemsInTableTitle>
				</Grid>
			</Grid>
		);
	}

	return (
		<table cellPadding={0} style={{ overflow: 'hidden', minWidth: width }}>
			<TableHead>
				<TableRow>
					{columns.map(({ id, label, width }) => (
						<HeaderCell key={`${id}-column`} width={width}>
							{label}
						</HeaderCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody sx={{ position: 'relative' }}>{Rows}</TableBody>
		</table>
	);
};

export default React.memo(TreeTable);

TreeTable.propTypes = {
	data: PropTypes.array,
};

TreeTable.defaultValues = {
	data: [],
};
