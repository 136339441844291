import React, { useState, useEffect, useCallback } from 'react';

import Logo from '@assets/icons/logo-login-icon.svg';
import MenuIcon from '@mui/icons-material/Menu';
import {Grid, Toolbar } from '@mui/material'
import { useLanguage } from 'Context/LanguageContext';
import { useSideBar, useSideBarUpdate } from 'Context/SideBarContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { removeSession } from 'FielderUtils/session/Session';
import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLocalUser } from 'Utils/account/utils';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import { selectAccount, selectUser } from '../../core/store/modules/session/sessionSelectors';
import { setUserInfo, setLogOutUser } from '../../core/store/modules/session/sessionSlice';
import AccountSelect from './components/AccountSelect';
import NonPaymentWarning from './components/NonPaymentWarning';
import SearchBar from './components/SearchBar';
import TopMenuAddUser from './components/TopMenuAddUser';
import TopMenuCreateTask from './components/TopMenuCreateTask';
import TopMenuNotification from './components/TopMenuNotification';
import TopMenuProfile from './components/TopMenuProfile/TopMenuProfile';
import TopMenuShare from './components/TopMenuShare';
import { AppBar, LogoImage, MenuButton } from './styled';
import { getTopMenuInfo } from '@core/api/user/user.service';

const LoggedMenu = () => {
	const user = useSelector(selectUser);
	const account = useSelector(selectAccount);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const lan = useLanguage();
	const sideBarInfo = useSideBar();
	const setSideBar = useSideBarUpdate();

	const [logo, setLogo] = useState(Logo);
	const [nonPaymentCounter, setNonPaymentCounter] = useState(-1);
	const [notificationCount, setNotificationCount] = useState(0);
	const [isInternalAdmin, setIsInternalAdmin] = useState(false);

	useEffect(() => {
		if (isLocalUser(user)) {
			const onSuccess = ({ user, notificationsCount }) => {
				setNotificationCount(notificationsCount);

				const nextStatus = user.status;

				if (nextStatus == null || nextStatus.status === 1) {
					dispatch(setLogOutUser());
					removeSession();
					navigate(ENDPOINTS.login);
				} else {
					const profileImage = user?.profileImage?.scalePath;
					const { users } = user;

					dispatch(
						setUserInfo({
							status: nextStatus,
							profileImage,
							users,
						})
					);
				}
			};

			const onError = (e) => {
				console.error('[FIELDER - ERROR - LOGGED MENU]', e);
				// dispatch(setLogOutUser());
				// removeSession();
				// history.push(ENDPOINTS.login);
			};

			getTopMenuInfo(onSuccess, onError)
		}
	}, []);

	useEffect(() => {
		setIsInternalAdmin(RoleUtils.isInternalAdminUser(user));
	}, [user]);

	const setAccountInfo = useCallback((account) => {
		if (account) {
			if (account.logo) {
				setLogo(account.logo);
			}

			if (account.nonPayment > 0) {
				setNonPaymentCounter(account.nonPayment);
			}
		}
	}, []);

	useEffect(() => {
		setAccountInfo(account);
	}, [setAccountInfo]);

	useEffect(() => {
		if (account && account.logo) {
			setLogo(account.logo);
		}
	}, [account]);

	const openDrawer = () => {
		setSideBar(true);
	};

	const updateNotificationCount = (number) => {
		setNotificationCount(number);
	};

	return (
		<>
			<AppBar
				position='fixed'
				open={sideBarInfo.open}
			>
				<Toolbar>
					<MenuButton
						onClick={openDrawer}
						edge='start'
						open={sideBarInfo.open}
					>
						<MenuIcon color='disabled' />
					</MenuButton>

					<Navbar.Brand>
						<LogoImage src={logo} alt='' />
					</Navbar.Brand>

					{isInternalAdmin ? (
						<>
							<Grid container direction='row' justifyContent='flex-end' alignItems='center'>
								<Grid item>
									<TopMenuProfile lan={lan} user={user} />
								</Grid>
							</Grid>
						</>
					) : (
						<>
							<Grid container direction='row' justifyContent='space-around' alignItems='center'>
								<Grid item className='mx-1' xs={3}>
									<SearchBar />
								</Grid>

								<TopMenuCreateTask />
								<TopMenuAddUser history={history} user={user} />
								<TopMenuNotification user={user} />
								<TopMenuShare user={user} lan={lan} />

								<AccountSelect lan={lan} user={user} />
								<Grid item>
									<TopMenuProfile />
								</Grid>
							</Grid>
						</>
					)}
				</Toolbar>
			</AppBar>

			{nonPaymentCounter > 0 && <NonPaymentWarning user={user} nonPaymentCounter={nonPaymentCounter} />}
		</>
	);
};

export default LoggedMenu;
