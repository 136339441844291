import {utils as xlsxUtils, writeFile as xlsxWriteFile} from 'xlsx'

export function downloadXlsxFromTaskTypeTasksSummary(taskTypeTasksSummary, headers, filename) {
	const summaryWorkbook = generateXlsxWorkbookFromTaskTypeTasksSummary(taskTypeTasksSummary, headers);
	downloadWorkbook(summaryWorkbook, filename)
}

function generateXlsxWorkbookFromTaskTypeTasksSummary(taskTypeTasksSummary, headers) {
	const filteredData = taskTypeTasksSummary.filter(item => item !== null);
	const worksheet = xlsxUtils.json_to_sheet(filteredData, {origin: 'A2', skipHeader: true});
	const workbook = xlsxUtils.book_new();

	xlsxUtils.sheet_add_aoa(worksheet, [headers], {origin: 'A1'})
	xlsxUtils.book_append_sheet(workbook, worksheet, 'Sheet1');
	return workbook;
}

export function downloadWorkbook(workbook, filename) {
	xlsxWriteFile(workbook, filename);
}