import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import { Image } from 'react-bootstrap';

const HeaderChatBot = ({botName, handleOpenCloseBotChat}) => {
	return (
		<div className='flex-container' style={{ 
			backgroundColor: 'rgb(239 239 239)', 
			color: 'rgb(45, 45, 45)', 
			height: "40px",
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'}}>

				<div className="" style={{display:'flex', flex: '0 0 auto'}}>
					<Image src={`${process.env.AWS_FIELDER_ICONS}maya.svg`} alt='' style={{ width:'35px', height:'35px'}}   />
				</div>
				<div className="" style={{display:'flex', flex:3, alignItems: 'center'}}>
					{`${botName}`}
				</div>
				<div className="" style={{display:'flex', flex:1, alignItems: 'start', justifyContent: 'end'}}>
					<IconButton className="no-padding pt-1" onClick={handleOpenCloseBotChat}>
						<RemoveIcon style={{ color: 'black' }}/>
					</IconButton>
				</div>
		</div>
	);
  };
  export default HeaderChatBot;