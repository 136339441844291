import React, { useState, useEffect } from 'react';

import { getStatus } from '@core/utils/taskGroup/taskGroupStatusToShow';
import { Typography } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import { useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';

import { StatusContainer, TaskStatusTooltip } from './styled';

const TaskGroupStatus = ({ taskGroup }) => {
	const lan = useLanguage()
	const controls = useAnimation();

	const [statusInfo, setStatusInfo] = useState(null);
	const [background, setBackground] = useState(null);
	const [color, setColor] = useState(null);
	const [firstTime, setFirstTime] = useState(true);

	useEffect(() => {
		if (taskGroup) {
			const statusInformation = getStatus(taskGroup.statusValue);

			if (statusInformation) {
				setStatusInfo(lan[statusInformation.text]);
				setBackground(statusInformation.background);
				setColor(statusInformation.color);
			}
		}
	}, [taskGroup, lan]);

	useEffect(() => {
		if (statusInfo == null) return;
		if (firstTime) {
			setFirstTime(false);
			return;
		}

		controls.start({
			scale: [1, 2, 2, 1, 1],
			rotate: [0, 0, 15, 15, 0],
		});
	}, [statusInfo, controls]);

	if (statusInfo === null) {
		return null;
	}

	return (
		<TaskStatusTooltip title={statusInfo} background={background} color={color}>
			<StatusContainer
				background={background}
				color={color}
				key={`taskGroup-status-${taskGroup.id}-${statusInfo}`}
				animate={controls}
			>
				<Typography noWrap style={{ fontFamily: 'Lato', fontSize: 14 }}>
					{statusInfo}
				</Typography>
			</StatusContainer>
		</TaskStatusTooltip>
	);
};

TaskGroupStatus.propTypes = {
	taskGroup: PropTypes.object.isRequired,
};

export default TaskGroupStatus;
