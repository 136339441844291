import { StatusColor } from '../../constants/taskGroup/colorCodes.constants';
import Status from '../../constants/taskGroup/TaskGroupStatus.constants';

const managingStatus = () => ({
	text: 'taskGroup_' + Status.CREATED,
	...StatusColor[Status.CREATED],
});

const startRouteStatus = () => ({
	text: 'taskGroup_' + Status.ON_WAY,
	...StatusColor[Status.ON_WAY],
});

const arrivedStatus = () => ({
	text: 'taskGroup_' + Status.ARRIVED,
	...StatusColor[Status.ARRIVED],
});

const executingStatus = () => ({
	text: 'taskGroup_' + Status.EXECUTING,
	...StatusColor[Status.EXECUTING],
});

const pausedStatus = () => ({
	text: 'taskGroup_' + Status.PAUSED,
	...StatusColor[Status.PAUSED],
});

const finishStatus = () => ({
	text: 'taskGroup_' + Status.FINISH,
	...StatusColor[Status.FINISH]
});

const cancelStatus = () => ({
	text: 'taskGroup_' + Status.CANCELED,
	...StatusColor[Status.CANCELED]
});


const deletedStatus = () => ({
	text: 'taskGroup_' + Status.ON_TRASH,
	...StatusColor[Status.ON_TRASH],
});


export const getStatus = (status) => {
	switch (status) {
		case Status.CREATED:
			return managingStatus();
		case Status.ON_WAY:
			return startRouteStatus();
		case Status.ARRIVED:
			return arrivedStatus();
		case Status.EXECUTING:
			return executingStatus();
		case Status.PAUSED:
			return pausedStatus();
		case Status.FINISH:
			return finishStatus();
		case Status.CANCELED:
			return cancelStatus();
		case Status.ON_TRASH:
			return deletedStatus();
		default:
			return null;
	}
};
