import React, { Component } from 'react';

import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MotorcycleIcon from '@mui/icons-material/TwoWheeler';

class VehicleTypeContainer extends Component {
	render() {
		return (
			<>
				<div className='row mx-2'>
					<div className='col-sm-12 lato-font-label mb-1'>{this.props.lan.createAgentVehicleTypeField}</div>
					<div className='col-12 mb-2'>
						<div className='row Agent-fieldContainer'>
							<img
								src={redAlertIcon}
								alt=''
								id='Agent-vehicleType-alert-icon'
								className='Agent-fieldContainer-icon-esp'
							/>
							<div className='mb-1 mr-1 Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='1'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '1'}
										disabled={this.props.disabledInput}
									/>
									<DirectionsBikeIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
							<div className='mb-1 mr-1 Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='2'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '2'}
										disabled={this.props.disabledInput}
									/>
									<MotorcycleIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
							<div className='mb-1 mr-1 Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='3'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '3'}
										disabled={this.props.disabledInput}
									/>
									<DriveEtaIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
							<div className='mb-1 mr-1  Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='4'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '4'}
										disabled={this.props.disabledInput}
									/>
									<AirportShuttleIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
							<div className='mb-1 mr-1 Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='5'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '5'}
										disabled={this.props.disabledInput}
									/>
									<LocalShippingIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
							<div className='mb-1 mr-1  Agent-body-row-radiobox-container'>
								<label className='Agent-options-list-container TemplateAA-body-row-radiobox'>
									<input
										onClick={this.props.updateVehicleType}
										type='radio'
										value='6'
										name='vehicleType'
										checked={this.props.userInfo.vehicleType === '6'}
										disabled={this.props.disabledInput}
									/>
									<DirectionsWalkIcon />
									<span className='Agent-options-list-container-checkmark' />
								</label>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default VehicleTypeContainer;
