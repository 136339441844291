import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { recordLoginRequest } from '@core/api/account/account.service';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import { selectSession, selectRequest, selectUser } from '@core/store/modules/session/sessionSelectors';
import { loginUser, setResetSelectRequest } from '@core/store/modules/session/sessionSlice';
import { useLanguage } from 'Context/LanguageContext';
import FielderButton from 'FielderComponents/FielderButton';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { getLoginAccountRedirect, getErrorMessage } from '../LoginPage.utils';
import LoginCard from './LoginCard';
import { FielderFormFieldLogin } from '~/FielderElements/FielderElements.component';
import { FIELDER } from '@core/constants/base.constants';

const Login = ({ setShowExternalMessage }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const session = useSelector(selectSession);
	const { isLoading, isSuccess, isError, error } = useSelector(selectRequest);
	const fielderMsg = useFielderSnackBar();
	const lan = useLanguage();
	const fielderId = FIELDER.idElement;
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (isSuccess && user && user.acceptPrivacyPolicy === true) {
			recordLoginRequest();
			const linkToRedirect = getLoginAccountRedirect(user, location);
			let welcomeMsg = `${lan.welcome} ${user.name || ''} ${user.lastName || ''}`;
			fielderMsg({ message: welcomeMsg, variant: 'success', closeButton: true, duration: 6000 });
			navigate(linkToRedirect);
		}
		if (isSuccess && user && user.acceptPrivacyPolicy === false) {
			const errorMessage = {
				acceptPrivacyPolicy: true,
				session,
			};
			setShowExternalMessage(errorMessage);
		}

		if (isError) {
			if (error) {
				fielderMsg({
					message: getErrorMessage(error.status, lan),
					variant: 'error',
					closeButton: true,
					duration: 6000,
				});
			}

			dispatch(setResetSelectRequest());
		}
	}, [isSuccess, isError, error]);

	useEffect(() => {
		if (isLoading) {
			dispatch(loadingStart({ name: 'LOGIN' }));
		} else {
			dispatch(loadingStop('LOGIN'));
		}
	}, [isLoading]);
	const handleLogin = async (data) => {
		dispatch(loginUser(data));
	};

	return (
		<LoginCard show={!isLoading}>
			<form onSubmit={handleSubmit(handleLogin)}>
				<Grid item xs={8} className='mx-auto'>
					<FielderFormFieldLogin
						name='username'
						label={lan.user}
						id={fielderId.txt.username}
						control={control}
						rules={{ required: `${lan.user} ${lan.isRequired}` }}
						error={errors.username}
						className='my-3'
						
					/>
					<FielderFormFieldLogin
						name='password'
						label={lan.password}
						id={fielderId.txt.password}
						type='password'
						control={control}
						rules={{
							required: `${lan.password} ${lan.isRequired}`,
							minLength: { value: 3, message: lan.minimunCharacters.replace('{0}', 3) },
						}}
						error={errors.password}
						className='my-3'
					/>
				</Grid>

				<Grid item xs={8} className='mx-auto' sx={{ width: '50%', p: 2 }}>
					<FielderButton id='login' type='submit'>
						{lan.login_button}
					</FielderButton>
				</Grid>
			</form>
		</LoginCard>
	);
};

export default Login;
