import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

class PyramidGraph extends Graph{
    constructor(data,props){
		super(props);
        this.data = data;
    }

    createGraph(params){
        //am4core.useTheme(am4themes_animated);
		let chart = am4core.create(params.id, am4charts.SlicedChart);

		let category = params.category;
		let value = params.series[0].value;
        let name = params.series[0].name;
        let color = params.color;
        let labels = params.labels;
        let ticks = params.ticks;
        let tooltip = params.tooltip;
        let legendPosition = params.legendPosition;

        chart.data = this.data;

        super.setPyramidSeriesParams(value, category, chart, color, labels, ticks, tooltip, legendPosition );


        this.chart = chart;

    }

    getGraph(){
        return this.chart;
    }
}

export default PyramidGraph