import React from 'react';

import { Tooltip } from '@mui/material';

import { BranchChip } from '../TaskToCashTable.styles';
import { RowTitle, RowSubtitle } from '../TaskToCashTable.styles';
import { getMissingChipsText } from '../TaskToCashTable.utils';

const BranchCell = ({ row }) => {
	const { branch, taskType } = row;

	const TooltipChip = () => {
		const missingBranchText = getMissingChipsText(branch);
		return (
			<Tooltip key={`${row.id}-more-branch-chip`} title={missingBranchText}>
				<BranchChip label={`${branch.length - 2} +`} />
			</Tooltip>
		);
	};

	const BranchTags = () => {
		if (branch.length > 3) {
			const branchTags = [];

			for (let i = 0; i < 2; i++) {
				branchTags.push(<BranchChip key={`${row.id}-${branch[i]}-chip`} label={branch[i]} />);
			}

			branchTags.push(<TooltipChip />);
			return branchTags;
		}

		return branch.map((b) => <BranchChip key={`${row.id}-${b}-chip`} label={b} />);
	};

	if (Array.isArray(branch)) {
		return <BranchTags key={`${row.id}-branch-tags`} />;
	} else if (!Array.isArray(taskType)) {
		return <RowSubtitle variant='subtitle'>{branch.name}</RowSubtitle>;
	} else {
		return <RowTitle>{branch.name}</RowTitle>;
	}
};

export default BranchCell;
