import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getAgentsDashboardRequest } from '@core/api/dashboard/dashboard.service';
import AgentStatusCountCard from './AgentStatusCountCard';
import AgentTotalNumberCard from './AgentTotalNumberCard';
import AgentTableCard from './AgentTableCard';
import AgentStatus from './AgentStatus';
import AgentLastUpdate from './AgentLastUpdate';
import { getAgentLastUpdate, getAgentStatus } from '../Dashboard.utils';
import AgentMap from './AgentMap';
import { AnimatePresence } from 'framer-motion';
const AgentDashboard = () => {
	const [isLoading, setLoading] = useState(false);
	const [agents, setAgents] = useState([]);
	const [rowsSelected, setRowsSelected] = useState([]);
	const [totalAgents, setTotalAgents] = useState(0);
	const [agentsStatus, setAgentsStatus] = useState({
		online: 0,
		offline: 0,
		inactive: 0,
		noData: 0,
	});
	const queryAgents = (status, filter) => {
		const body = {
			agentCondition: status ? status : 1,
			filter,
		};
		setLoading(true);
		const onSuccess = (res) => {
			const {
				totalAgents,
				agentsOnlineCount,
				agentsNoInformation,
				agentsInactiveCount,
				agentsOfflineCount,
				agentsTable,
			} = res;
			setTotalAgents(totalAgents);
			setAgentsStatus({
				online: agentsOnlineCount,
				offline: agentsOfflineCount,
				inactive: agentsInactiveCount,
				noData: agentsNoInformation,
			});
			const agentsInformation = agentsTable.map(
				({ idAgent, name, lastName, email, geolocation, companyName, ...agent }) => {
					const { onDuty, offDutyCauseId, privacyPolicy, agentCondition, pinIcon } = agent;
					const status = getAgentStatus(onDuty, offDutyCauseId, privacyPolicy, agentCondition);
					const lastUpdate = getAgentLastUpdate(geolocation);
					const statusComponent = <AgentStatus status={status} />;
					const lastUpdateComponent = <AgentLastUpdate lastUpdate={lastUpdate} />;
					return {
						idAgent,
						fullName: `${name} ${lastName}`,
						company: companyName,
						pin: pinIcon,
						email,
						status,
						statusComponent,
						lastUpdate,
						lastUpdateComponent,
						geolocation,
					};
				}
			);
			setAgents(agentsInformation);
		};
		getAgentsDashboardRequest(body, onSuccess).then(() => setLoading(false));
	};
	const handleRowSelected = (_, selectedRows) => setRowsSelected(selectedRows);
	useEffect(() => {
		queryAgents();
	}, []);
	return (
		<>
			<Grid item xs={12} md={6} lg={3}>
				<Grid container direction='column' spacing={1}>
					<Grid item xs>
						<AgentTotalNumberCard total={totalAgents} isLoading={isLoading} />
					</Grid>
					<Grid item xs>
						<AgentStatusCountCard agentsStatus={agentsStatus} isLoading={isLoading} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6} lg={8}>
				<AnimatePresence>
					<AgentTableCard
						agents={agents}
						queryAgents={queryAgents}
						isLoading={isLoading}
						onAgentSelected={handleRowSelected}
						agentsSelected={rowsSelected}
					/>
					{rowsSelected.length > 0 && <AgentMap agents={rowsSelected} />}
				</AnimatePresence>
			</Grid>
		</>
	);
};
export default AgentDashboard;
