import React, { useEffect, useState } from 'react';

import { postAddSkillsRequest } from '@core/api/skills/skills.service';
import { Grid, IconButton, Chip, Paper } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AnimatePresence, motion } from 'framer-motion';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import { ChevronIconMotion, CompleteMessageMotion, CreateSkillsTitle, ErrorMessageMotion } from './Agents.syles';

const iconVariants = {
	open: { rotate: 90 },
	close: { rotate: 0 },
	hide: {
		size: 0,
		opacity: 0,
		transition: {
			duration: 0.1,
		},
	},
	show: {
		size: 'auto',
		opacity: 1,
		transition: {
			duration: 2,
			delay: 0.2,
		},
	},
};

const CreateSkillsCard = ({ disabled, getSkillsFromAccount }) => {
	const lan = useLanguage();
	const dispatch = useDispatch();

	const [isSaved, setIsSaved] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [skillsToAdd, setSkillsToAdd] = useState([]);

	useEffect(() => {
		if (isSaved) {
			setTimeout(() => setIsSaved(false), 3000);
		} else if (isError) {
			setTimeout(() => setIsError(false), 3000);
		}
	}, [isSaved, isError]);

	const handleExpand = () => setIsExpanded((prev) => !prev);

	const saveSkills = () => {
		const loadingConfig = { dispatch };
		const body = { skillNames: skillsToAdd };

		const onError = () => setIsError(true);
		const onSuccess = () => {
			setIsSaved(true);
			setIsExpanded(false);
			getSkills();
		};

		postAddSkillsRequest(body, onSuccess, onError, loadingConfig);
	};

	const removeSkill = (index) => {
		const skills = [...skillsToAdd];
		skills.splice(index, 1);
		setSkillsToAdd(skills);
	};

	const addSkillToBuffer = () => {
		const input = document.getElementById('input-add-skill');
		const newSkill = input.value;
		const newSkillsToAdd = [...skillsToAdd];

		// Lists of skills
		if (!newSkillsToAdd.includes(newSkill) && newSkill != '') {
			newSkillsToAdd.push(newSkill);
			setSkillsToAdd(newSkillsToAdd);
		} else {
			// Show the repeated skill popup TODO:
			// let state = this.state;
			// state.popups.showRepeatedPopup = true;
			// this.setState(state);
		}

		input.value = '';
	};

	/**
	 *  Function used in the Personalization tab to add a new type of task
	 *  entered by the user. It adds it to a new array and updates the state.
	 *  Receives the task by reading the input value
	 */
	const getSkills = () => {
		setSkillsToAdd([]);
		getSkillsFromAccount();
	};

	const CardButtonIcon = () => {
		let icon;
		if (isSaved) {
			icon = (
				<CompleteMessageMotion
					key='complete-message-icon'
					variants={iconVariants}
					exit='hide'
					animate='show'
					className='p-2'
				>
					{lan.savedCorrectly}
					<CheckCircleOutlineIcon />
				</CompleteMessageMotion>
			);
		} else if (isError) {
			icon = (
				<ErrorMessageMotion key='error-message-icon' variants={iconVariants} exit='hide' animate='show' className='p-2'>
					{lan.errorGralFail}
					<ErrorOutlineIcon />
				</ErrorMessageMotion>
			);
		} else {
			icon = (
				<IconButton id='expand-create-skills-button' onClick={handleExpand} disabled={disabled}>
					<ChevronIconMotion
						key='chevron-right-icon'
						initial='show'
						variants={iconVariants}
						animate={isExpanded ? 'open' : 'close'}
						exit='hide'
					/>
				</IconButton>
			);
		}

		return <AnimatePresence>{icon}</AnimatePresence>;
	};

	return (
		<>
			<Grid container alignItems='center' alignContent='center' className='p-1'>
				<Grid item xs className='pl-2'>
					<CreateSkillsTitle>{lan.createSkills}</CreateSkillsTitle>
				</Grid>

				<Grid item xs='auto'>
					<CardButtonIcon />
				</Grid>

				<AnimatePresence>
					{isExpanded && (
						<motion.div key='skills-card-conent' style={{ width: '100%' }}>
							{/* TODO: MISSING REFACTORIZATION (only separated from createDetailAgent file) */}
							<Grid container className='px-3'>
								<Grid item sm={10}>
									<input
										type='text'
										key='input-add-skill'
										id='input-add-skill'
										placeholder={lan.skillsToAdd}
										className='form-control'
									/>
								</Grid>
								<Grid item sm={2}>
									<IconButton onClick={addSkillToBuffer}>
										<AddCircleIcon />
									</IconButton>
								</Grid>
							</Grid>

							<Grid item xs={12} className='mb-3 mx-2'>
								<Grid container spacing={1}>
									{skillsToAdd.map((type, i) => (
										<Grid item>
											<Chip key={`${type}-skills-to-add`} label={type} onDelete={() => removeSkill(i)} />
										</Grid>
									))}
								</Grid>
							</Grid>

							<Row className='my-4 justify-content-center'>
								<Col sm={6} className='text-center'>
									<button className='Fielder-button' onClick={saveSkills}>
										{lan.save}
									</button>
								</Col>
							</Row>
						</motion.div>
					)}
				</AnimatePresence>
			</Grid>
			{/* <div>
				<Button onClick={handleSave}>Save</Button>
			</div> */}
		</>
	);
};

const CreateSkillsCardContainer = ({ isPopup, ...otherSkillProps }) => {
	if (isPopup) {
		return <CreateSkillsCard disabled={disabled} />;
	}

	return (
		<Paper elevation={1} className='mx-4' style={{ width: '100%' }}>
			<CreateSkillsCard {...otherSkillProps} />
		</Paper>
	);
};

export default CreateSkillsCardContainer;
