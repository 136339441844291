import React, { Component } from 'react';

//Library
import StarRatings from 'react-star-ratings';

//Icons
import infoIcon from '@assets/icons/information-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Modal } from 'react-bootstrap';
import 'FielderUtils/style.css';
import { rateTaskRequest } from '@core/api/task/task.service';
import { connect } from 'react-redux';

class RateTask extends Component {
	constructor(props, context) {
		super(props, context);
		this.handleClose = this.handleClose.bind(this);

		var description = '';

		this.state = {
			rating: 0,
			userComment: '',
			taskInfo: {
				description: description,
			},
		};
	}

	handleClose() {
		this.props.closeErrorMessage();
	}

	setValidateDescription() {
		if (this.state.taskInfo.description !== '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			document.getElementById('description-red-input').style.borderColor = '#ced4da';
		}
	}

	setFieldValue(value, field) {
		if (field === 'description') {
			this.setState((prevState) => ({
				taskInfo: {
					// object that we want to update
					...prevState.taskInfo, // keep all other key-value pairs
					description: value, // update the value of specific key
				},
			}));
			this.setValidateDescription();
		}
	}

	handleRateTask = () => {
		if (this.state.taskInfo.description !== '') {
			const idTasks = this.props.idTasks[0];

			const body = {
				ratingObject: {
					rating: this.state.rating,
					comment: this.state.taskInfo.description,
					idTask: idTasks,
					idUser: this.props.user.idUser,
					idAccount: this.props.user.idAccount,
				},
			};

			const loadingConfig = { dispatch: this.props.dispatch };
			const onSuccess = this.props.onCloseModalSuccess;
			const onError = this.props.onCloseModalError;

			rateTaskRequest(body, onSuccess, onError, loadingConfig);

		} else {
			document.getElementById('description-alert-icon').style.display = 'inline';
			document.getElementById('description-red-input').style.borderColor = '#c70101';
		}
	};

	handleClose() {
		this.props.closeErrorMessage();
	}

	changeRating = (newRating, name) => {
		this.setState({ rating: newRating });
	};

	render() {
		return (
			<React.Fragment>
				<Modal
					id='ErrorMessage-modal'
					size='md'
					show={true}
					onHide={this.handleClose}
					className='Fielder-modals-header-border Fielder-modals-content-border'
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border' closeButton>
						<Modal.Title>
							<div className='row-auto'>
								<div className='col-auto ml-4 pl-2'>
									<span className='rateTask-title-size'> {this.props.lan.rateTask} </span>
								</div>
							</div>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body className='error-message-modal-body text-center'>
						<div className='row'>
							<div className='col-auto ml-5 pr-0'>
								<span className='rate-title-size'> {this.props.lan.rate} </span>
							</div>

							<div className='col-auto'>
								<div className='tooltipCustom'>
									<img src={infoIcon} />
									<span className='tooltiptextCustom'>{this.props.lan.rateTooltipText}</span>
								</div>
							</div>
						</div>
						<div className='row ml-5 mt-3'>
							<StarRatings
								rating={this.state.rating}
								starRatedColor='yellow'
								starHoverColor='yellow'
								changeRating={this.changeRating}
								numberOfStars={5}
								name='rating'
							/>
						</div>

						<div className='row'>
							<div className='col-auto ml-5'>
								<span className='rate-title-size'> {this.props.lan.clientComment} </span>
							</div>
						</div>

						<div className='row-auto pl-5 pr-5'>
							<textarea
								className='form-control  text-left mt-1'
								maxLength='255'
								rows='3'
								cols='20'
								id='description-red-input'
								value={this.state.taskInfo.description}
								onChange={(e) => this.setFieldValue(e.target.value, 'description')}
							></textarea>
							<img
								src={redAlertIcon}
								alt=''
								id='description-alert-icon'
								className='myoperation-icon'
							></img>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<div className='col d-flex justify-content-center mt-3'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size error-message-btn-margin'
								onClick={this.handleRateTask}
							>
								{this.props.lan.sendTicket}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user
});

export default connect(mapStateToProps)(RateTask);
