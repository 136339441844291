import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import {DatePicker, ConfigProvider} from 'antd';
import localeEs from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_US';
import localePt from 'antd/lib/locale/pt_BR';

const { RangePicker } = DatePicker;

function range(start, end) {
    const result = [];
    for(let i=start; i<end; i++) {
        result.push(i);
    }

    return result;
}

const DateTimePicker = ({id, onChange, enablePastDates, disabled, defaultValue, placeholder, lan}) => {

    const [localeLang, setLocaleLang] = useState('en_US')
    useEffect(() => {
        switch(lan._language) {
            case 'en_US':
                setLocaleLang(localeEn);
                break;
            case 'es_ES':
                setLocaleLang(localeEs);
                break;
            case 'pt_BR':
                setLocaleLang(localePt);
                break;
        }
    })

    const getDisabledDate = current => {
        if(enablePastDates) {
            return null;
        }

        const yesterday = moment().endOf('day').subtract(1, 'days');
        return current && current < yesterday;
    }

    const getDisabledTime = current => {
        if(enablePastDates) {
            return null;
        }

        let hours = moment().hours();
        let minutes = moment().minutes();

        if(current && moment().isSameOrBefore(current)) {
            hours = 0;
            minutes = 0;
        }

        return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, minutes)
        }
    }

    return (
        <ConfigProvider locale={localeLang}>
            <DatePicker
                disabled={disabled}
                id={id}
                format="YYYY-MM-DD HH:mm"
                disabledDate={getDisabledDate}
                disabledTime={getDisabledTime}
                defaultValue={defaultValue}
                onChange={onChange}
                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
            />
        </ConfigProvider>
    )
}

DateTimePicker.defaultProps = {
    id: 'dateTimePicker',
    enablePastDates: false,
    disabled: false,
    onChange: () => {},
    defaultValue: null,
    placeholder: ''
}

DateTimePicker.prototype = {
    id: PropTypes.string,
    enablePastDates: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
}


export default DateTimePicker;