import React from 'react';
import { LightGreySubtitleColor } from '@core/theme/baseTheme';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CardTitle = styled(Typography)`
	font-family: 'Lato';
	font-size: 1.2em;
	color: ${LightGreySubtitleColor};
`;

export default CardTitle;
