import React from 'react';

import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { validatePermissions } from 'FielderUtils/validatePermissions';
import AssignResourcesIcon from '@assets/icons/assign-resources-icon.svg';
import CreateGroupTask from '@assets/icons/create-group-tasks.svg';
import POPUPNAME from 'FielderUtils/popupName/popupName';

const SELECTION_TASK = 1;
const SELECTION_TASKGROUP = 3;

const MyOperationResourceAllocationAndGroupTaskIconMenu = ({
	showDisabledTasks, showTaskByEmail, options,
	showRouteOpt, changePopupState, resetGroupTaskSectionView,
	state, canGenerateResourceAllocation,canCreateGroupTasks
}) => {
    const lan = useLanguage();
	const user = useSelector(selectUser);

    return (
        <>
           {/* Icon Options */}
		   {!showDisabledTasks &&
				!showTaskByEmail &&
					options == SELECTION_TASK && (
					<div className={RoleUtils.isUserClient(user) ? 'row-auto mx-2 ' : 'row mx-2'}>
						{showRouteOpt &&
							validatePermissions(user, state, 'route') &&
								canGenerateResourceAllocation && (
								<div className='my-1'>
									{validatePermissions(user, state, 'resource') && (
										<div className='pl-1 pr-3 col-auto'>
											<img
												id='MyOperation-assign-resources-icon'
												src={AssignResourcesIcon}
												className='icon Fielder-icon-button'
												alt='Asignar Recursos'
												title={lan.assignResources}
												onClick={() => changePopupState(POPUPNAME.ASSIGN_RESOURCES)}
											/>
										</div>
									)}
									{!RoleUtils.isUserClient(user) && (
										<div className='col-auto myoperacion-icon-separator' />
									)}
								</div>
							)}
					</div>
				)}

			{options == SELECTION_TASKGROUP && canCreateGroupTasks && (
				<div className='pl-1 pr-1 col-auto'>
					<img
						src={CreateGroupTask}
						id='MyOperation-export-icon'
						className='icon Fielder-icon-button'
						alt='Exportar'
						title={lan.titleIconGroup}
						onClick={() => {
							changePopupState(POPUPNAME.CREATE_GROUP_TASK);
							resetGroupTaskSectionView();
						}}
					/>
				</div>
			)}
        </>
    );
}

export default MyOperationResourceAllocationAndGroupTaskIconMenu;