import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

var THEMEONE = "THEMEONE";
var THEMETWO = "THEMETWO";

function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color("#242C39"), //9013fe
			am4core.color("#8A989C"),
			am4core.color("#6D8F97"),
			am4core.color("#B6C3D0"), //ce2b66
            am4core.color("#4A5865"),
		];
	}
}

//am4core.useTheme(am4themes_myTheme);


function am4themes_millenial(target) {

  if (target instanceof am4core.ColorSet) {

      target.list = [
          am4core.color("#9013fe"),
          am4core.color("#51a5de"),
          am4core.color("#f52394"),
          am4core.color("#ce2b66"),
          am4core.color("#dc5c87")

      ];

  }

}


class ColumnWithRotated extends Graph{
    constructor(data,props){
        super(props);
        this.props = props;
        this.data = data;
    }

    createGraph(params){

        let theme = params.theme
        am4core.unuseAllThemes();

        if(theme == THEMEONE ){
            am4core.useTheme(am4themes_empresarial);
          }
        else if(theme == THEMETWO ){
            am4core.useTheme(am4themes_millenial);
        }

        let chart = am4core.create(params.id, am4charts.XYChart);

        let category = params.category;
		let valueY = params.valueY;

        let text1=params.text1;

        let textTasksFinished = params.textTasksFinished
        let dateFromDateTo = params.dateFromDateTo;
        let textTotalTask = params.textTotalTask;
        let total= params.total;
        let agentObjective=params.agentObjective;

        let textTotalFinished=params.textTotalFinished

        chart.data = this.data;

		// Create axes
		super.setAxesColumnWithRotated(chart, category,text1,textTasksFinished,dateFromDateTo,textTotalTask,total,textTotalFinished,agentObjective);

        //Series

		super.setColumnWithRotatedSeriesParams(chart, category,valueY);


		var legend = params.legend;
		super.setChartProperties(chart, legend, true,false);

        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default ColumnWithRotated