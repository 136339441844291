import React, { useState, useEffect } from 'react';

import { getBranchesByFilterAsyncSelect } from '@core/utils/branch/branchAsyncSelect.utils';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

import AsyncSelect from '~/AsyncSelect/AsyncSelect.component';

const AsyncSelectBranches = ({ value, onSelect, ...selectProps }) => {
	const lan = useLanguage();

	const [selectValue, setSelectValue] = useState([]);

	useEffect(() => {
		if (value) {
			setSelectValue(value);
		}
	}, [value]);

	const getBranches = (e) => getBranchesByFilterAsyncSelect(e);

	const handleSelect = (e, option) => {
		setSelectValue(e);

		// Filter the ids to only send to the parent component
		const newValue = e.map((select) => select.value.id);
		onSelect(newValue, option);
	};

	return (
		<AsyncSelect
			multiple
			getOptions={getBranches}
			onChange={handleSelect}
			value={selectValue}
			label={`${lan.select} ${lan.branches}`}
			{...selectProps}
		/>
	);
};

export default AsyncSelectBranches;
