import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useLanguage } from 'Context/LanguageContext';
import PropTypes from 'prop-types';

import { AutoCompleteInput, StyledAutocomplete as Autocomplete } from './AsyncSelect.styles';
import SelectTag from '~/SelectTag/SelectTag.component';

const handleOptionComparison = (option, value) => {
	// Desired value for everything, others could be deleted
	if (typeof value.value === 'number') {
		return option.value === value.value;
	}

	if (option === value) {
		return true;
	}

	if (JSON.stringify(option) === JSON.stringify(value)) {
		return true;
	}

	return option.value.id === value.value.id;
};

/**
 *  Asynchronous search input component enhanced by a suggested options
 *  panel
 *
 *  @param {object} lan - the language object used to translate the texts
 *
 *  @param {function} getOptions - the function to retrieve the data from
 *                                  must return an array of objects with a
 *                                  label (the name to be displayed) and a value
 *                                  keys
 *
 *  @param {?boolean} [multiple=false] - Set the parameter if you want to receive a
 *                                       multiple options selected input
 *
 *  @param {?string} [label=''] - The label to be shown in the input element
 *
 *  @param {?string} [id='asyncSearch'] - The component id
 *
 * @param {?number} [limitTags=3] - Maximum number of elements to be shown for the AsyncSearch element
 */
const AsyncSearch = ({
	id,
	onChange,
	getOptions,
	defaultOptions,
	multiple,
	label,
	className,
	disabled,
	value,
	limitTags,
	getInitialOptions,
	showInitialOptions,
	isOptionEqualToValue,
	size,
	error,
	...props
}) => {
	const lan = useLanguage();

	const [open, setOpen] = useState(false);
	const [lastSearch, setLastSearch] = useState('');
	const [options, setOptions] = useState(defaultOptions || []);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (defaultOptions) {
			setOptions(defaultOptions);
		}
	}, [defaultOptions]);

	const fetchOptions = async () => {
		setLoading(true);
		const searchingInitialOptions = await getInitialOptions();
		setLoading(false);
		setOptions(searchingInitialOptions);
	};

	useEffect(() => {
		if (getInitialOptions && options.length === 0) {
			fetchOptions();
		}
	}, [getInitialOptions]);

	const search = (event) => {
		let active = true;
		setLoading(true);

		if (event.target.value !== lastSearch) {
			setOptions([]);
		}

		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		const searchInfo = async () => {
			if (event.target.value !== '' && active) {
				let searchingOptions = await getOptions(event.target.value);

				if (!searchingOptions) {
					searchingOptions = [];
				}

				setLastSearch(event.target.value);
				setOptions(searchingOptions);
				setLoading(false);
			}
		};

		setTypingTimeout(setTimeout(searchInfo, 250));

		return () => {
			active = false;
		};
	};

	const getOptionLabel = (option) => {
		if (typeof option === 'object' && option && 'label' in option) {
			return option.label;
		}
		return '';
	};

	const onChangeAutocomplete = (event, newInput, reason, details) => {
		if (reason === 'clear') {
			onChange(newInput, null, reason);
		} else if (reason === 'selectOption' || reason === 'removeOption') {
			onChange(newInput, details.option, reason);
		}
	};

	const handleShowOptions = async (val) => setOpen(val);
	const handleOpen = () => handleShowOptions(true);
	const handleClose = () => handleShowOptions(false);

	const Tags = (tagValue, getTagProps) =>
		tagValue.map((option, index) => (
			<SelectTag {...option} size={size} key={`${option.label}-${index}`} {...getTagProps({ index })} />
		));

	const Input = (params) => (
		<AutoCompleteInput
			variant='outlined'
			label={label}
			onChange={search}
			error={!!error}
			helperText={error?.message}
			{...params}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{loading ? <CircularProgress color='inherit' size={20} /> : null}
						{params.InputProps.endAdornment}
					</>
				),
			}}
			{...props}
		/>
	);

	return (
		<Grid item xs className={className}>
			<Autocomplete
				{...props}
				autoComplete
				autoHighlight
				fullWidth
				loadingText={lan.loading}
				noOptionsText={lan.noResultFound}
				multiple={multiple}
				disableCloseOnSelect={multiple}
				id={id}
				open={open}
				disabled={disabled}
				onOpen={handleOpen}
				onClose={handleClose}
				getOptionLabel={getOptionLabel}
				options={options}
				loading={loading}
				value={value}
				onChange={onChangeAutocomplete}
				limitTags={limitTags}
				// renderGroup={(params) => <AnimatePresence {...params} />}
				renderTags={Tags}
				renderInput={Input}
				isOptionEqualToValue={isOptionEqualToValue}
				size={size}
			/>
		</Grid>
	);
};

AsyncSearch.defaultProps = {
	multiple: false,
	label: '',
	id: 'asyncSearch',
	className: '',
	disabled: false,
	limitTags: 3,
	getOptions: () => [],
	isOptionEqualToValue: handleOptionComparison,
};

AsyncSearch.prototype = {
	multiple: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	getOptions: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default React.memo(AsyncSearch);
