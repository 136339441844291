import React, { Component } from 'react';

import { Modal, Button, Image, Container, Row, Col } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

// Stylesheets
import './Popup.css';
import 'FielderUtils/style.css';
import { DeepSpaceGrey } from '@core/theme/baseTheme';

/**
 * Popup-container a wrapper around Bootstrap Modal, with several
 * customizations made to work correctly with the Fielder requirements.
 *
 * @extends Component
 *
 * @param {?string} [title] - The title for the popup that will be inside the
 *                            [Header]{@link Popup#Header}
 * @param {?string} [icon] - Name of the icon to be looked for in the icon folder
 *                           (found in /assets/icons/ folder) that will be added
 *                           to the popup's header [Header]{@link Popup#Header}
 * @param {?string} [image] - Name of the icon to be looked for in the image folder
 *                           (found in /assets/images/ folder) that will be added
 *                           to the popup's header [Header]{@link Popup#Header}
 * @param {boolean} [closeButton=false] - Button added to the header for it to close
 *                                        (Requires the onClose parameter to work
 *                                        correctly)
 * @param {function} [onClose] - Function used when the closeButton is activated to
 *                               handle the popup closing (this function must be
 *                               defined in the parent component).
 * @param {?string} [size='md'] - Size of the [Header]{@link Popup#Header} to be used
 *                                in the popup (can be either sm, md, lg, xl) and
 *                                defaults md if not found. [Body]{@link Popup#Body}
 * @param {?string} [buttonText] - When found, the [Footer]{@link Popup#Footer} creates
 *                                 a button with that text inside (requires the onClick
 *                                 parameter to work correctly)
 * @param {?string} [requiredFields] - When found, the [Footer]{@link Popup#Footer} show
 *                                 a required fields label
 *
 * @param {function} [buttonOnClick] - Function Handler for the button at the [Footer]{@link Popup#Footer}
 *                                     that must be defined in the parent method
 * @param {?string} [width='md'] - Popup width to be generated (options can be sm, md,
 *                                 lg, xl)
 *
 *
 * @example
 *  <Popup
 *  size='sm'
 *  closeButton
 *  title='Test Popup'
 *  icon='add-filled-icon.svg'
 *  buttonText='Aceptar'
 *  buttonOnClick={()=> this.buttonHandler()}>
 *      <div>
 *          <h2>Example Popup</h2>
 *          <input type='text' placeholder='text'/>
 *      </div>
 * </Popup>
 *
 */

class Popup extends Component {
	constructor(props) {
		super(props);

		if (this.props.centerprop != null) {
			var center = false;
		} else {
			var center = true;
		}

		this.state = {
			headerData: {
				title: this.props.title,
				icon: this.props.icon,
				image: this.props.image,
				closeButton: this.props.closeButton,
			},
			bodyData: {
				size: this.props.size,
			},
			footerData: {
				buttonText: this.props.buttonText,
				requiredFields: this.props.requiredFields,
				onClick: this.props.buttonOnClick,
			},
			closeButton: true,
			onHide: this.props.onClose,
			width: this.props.width,
			show: this.props.show,
			centerprop: center,
			autoHeightMax:this.props.autoHeightMax ? this.props.autoHeightMax :'70vh',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.title != prevState.headerData.title) {
			return {
				headerData: {
					title: nextProps.title,
					icon: nextProps.icon,
					image: nextProps.image,
					closeButton: nextProps.closeButton,
				},
			};
		}

		if (nextProps.buttonText != prevState.footerData.buttonText) {
			return {
				footerData: {
					buttonText: nextProps.buttonText,
					requiredFields: nextProps.requiredFields,
					onClick: nextProps.buttonOnClick,
				},
			};
		}

		return null;
	}

	/**
	 * Contains the header from the popup
	 */
	Header = (props) => {
		let image = null;
		if (props.data.icon && typeof props.data.icon === 'string') {
			const iconUrl = require('../../assets/icons/' + props.data.icon).default;
			image = <Image src={iconUrl} className='icon mr-2' />
		} else if (props.data.image && typeof props.data.image === 'string') {
			const imageUrl = require('../../assets/images/' + props.data.image).default;
			image = <Image src={imageUrl} className='mr-2' />
		} else {
			image = props.data.icon || props.data.image;
		}


		return (
			<Modal.Header closeButton={props.data.closeButton} className='no-border my-auto'>
				<div className='container mx-2'>
					<Row>
						{image}
						{props.data.title && <span className='small-title my-auto' style={{color: DeepSpaceGrey}}>{props.data.title}</span>}
					</Row>
				</div>
			</Modal.Header>
		);
	};

	/**
	 *  Contains the Body from the Popup
	 */
	Body = (props) => (
		<Modal.Body className='no-border'>
			<div className={'mx-2 overflow-hidden ' + props.data.size ? props.data.size : 'md'} >
				{props.data.size === 'xl' || props.data.size === 'lg' ? (
					<Scrollbars style={{ overflowX: 'hidden !important' }} autoHeight autoHeightMax={props.heightMax}>
						{props.children}
					</Scrollbars>
				) : (
					props.children
				)}
			</div>
		</Modal.Body>
	);

	/**
	 *  Contains the Footer from the Popup
	 */
	Footer = (props) => {
		return (
			<Modal.Footer className='no-border'>
				<Container>
					{props.data.requiredFields && (
						<Row>
							<Col sm={12}>
								<div
									id='register-form-hint'
									className='popup-required-fields Register-form-hint mt-3'
								>
									<p>*{props.data.requiredFields}</p>
								</div>
							</Col>
						</Row>
					)}
					{props.data.buttonText && (
						<Row>
							<Col sm={12}>
								<div className='popup-footer-div'>
									<Button
										id={`popup-button-${props.data.buttonText}`}
										className='popup-footer-btn Fielder-button mx-auto my-auto'
										onClick={props.data.onClick}
									>
										{props.data.buttonText}
									</Button>
								</div>
							</Col>
						</Row>
					)}
				</Container>
			</Modal.Footer>
		);
	};

	render() {
		return (
			<Modal
				size={this.state.width ? this.state.width : 'md'}
				aria-labelledby='contained-modal-title-vcenter'
				centered={this.state.centerprop}
				show={true}
				onHide={this.state.onHide}
				dialogClassName={this.props.dialogClassName}
				scrollable={this.props.letScroll}
				style={{ overflowX: 'hidden !important' }}
			>
				<this.Header data={this.state.headerData} />

				<this.Body data={this.state.bodyData} heightMax={this.state.autoHeightMax}>
					{this.state.bodyData.size != 'Fielder-ultra-wide' ? (
						<Container className={this.props.propsContainer}  style={{ overflowX: 'hidden !important' }}>{this.props.children}</Container>
					) : (
						<div className='Fielder-ultra-wide'>{this.props.children}</div>
					)}
				</this.Body>

				<this.Footer data={this.state.footerData} />
			</Modal>
		);
	}
}

export default Popup;
