import React, { useEffect, useRef } from 'react';
import { AgentStatusChart, DashboardPaper } from '../Dashboard.styles';
import { create as createChart, percent, color } from '@amcharts/amcharts4/core';
import { PieChart, PieSeries } from '@amcharts/amcharts4/charts';
import { AgentStatus } from '@core/constants/agent/colorCodes.constants';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { Container } from '@mui/material';
import { SmallTitle } from '~/SmallTitle/SmallTitle.component';
import CircleIcon from '@mui/icons-material/Circle';
import SensorsIcon from '@mui/icons-material/Sensors';

const getAgentCountSeries = (chart) => {
	const countSeries = chart.series.push(new PieSeries());
	countSeries.innerRadius = 10;
	countSeries.dataFields.value = 'value';
	countSeries.dataFields.category = 'category';
	countSeries.slices.template.stroke = color('#fff');
	countSeries.slices.template.fillOpacity = 0.8;
	countSeries.labels.template.disabled = true;
	countSeries.slices.template.propertyFields.fill = 'fill';
	countSeries.slices.template.propertyFields.disabled = 'labelDisabled';
	countSeries.ticks.template.propertyFields.disabled = 'labelDisabled';

	// Start and end of the radius
	countSeries.adapter.add('innerRadius', () => percent(30));
	countSeries.adapter.add('radius', () => percent(100));
	return countSeries;
};

const ListElement = ({ text, color }) => {
	return (
		<Grid container spacing={1}>
			<Grid item>
				<CircleIcon sx={{ width: '10px', color }} />
			</Grid>
			<Grid item>
				<Typography sx={{ fontSize: '15px' }}>{text}</Typography>
			</Grid>
		</Grid>
	);
};

const AgentStatusCountCard = ({ agentsStatus, isLoading }) => {
	const chartRef = useRef();
	const lan = useLanguage();

	const prepareChart = () => {
		let chart = createChart('agent-status-chart', PieChart);
		chartRef.current = chart;
		chart.innerRadius = percent(20);
		const agentSeries = getAgentCountSeries(chart);

		agentSeries.data = [
			{
				category: lan.online,
				value: agentsStatus.online,
				fill: AgentStatus.online.chart,
			},
			{
				category: lan.offline,
				value: agentsStatus.offline,
				fill: AgentStatus.offline.chart,
			},
			{
				category: lan.inactive,
				value: agentsStatus.inactive,
				fill: AgentStatus.inactive.chart,
			},
			{
				category: lan.noData,
				value: agentsStatus.noData,
				fill: AgentStatus.noData.chart,
			},
		];

		return chart;
	};

	useEffect(() => {
		if (!isLoading) {
			const chart = prepareChart();
			return () => chart.dispose();
		}
	}, [isLoading]);

	const Chart = React.useMemo(() => {
		if (isLoading) {
			return <Skeleton variant='circular' height='12.1rem' width='12.1rem' className='mx-auto my-4' />;
		}
		return <AgentStatusChart id='agent-status-chart' />;
	}, [isLoading]);

	return (
		<DashboardPaper>
			<Container className='py-2'>
				<Grid item xs={12}>
					<SmallTitle>
						<SensorsIcon className='mr-2' />
						{lan.agentsStatus}
					</SmallTitle>
				</Grid>
				<Grid item xs={12}>
					{Chart}
				</Grid>
				<Grid item xs={12} className='mt-2'>
					<Grid container>
						<Grid item xs={6}>
							<ListElement color={AgentStatus.online.chart} text={lan.online} />
							<ListElement color={AgentStatus.offline.chart} text={lan.offline} />
						</Grid>
						<Grid item xs={6}>
							<ListElement color={AgentStatus.inactive.chart} text={lan.inactive} />
							<ListElement color={AgentStatus.noData.chart} text={lan.noData} />
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</DashboardPaper>
	);
};

export default AgentStatusCountCard;
