import React, { useEffect, useState } from 'react';

import InventoriesIcon from '@assets/icons/inventory-icon.svg';
import AssetsIcon from '@assets/icons/assets-icon.svg';
import FormsFinishedIcon from '@assets/icons/form-finished-icon.svg';
import FormsIconGreen from '@assets/icons/form-green-icon.svg';
import FormsIcon from '@assets/icons/form-icon.svg';
import FormsIconYellow from '@assets/icons/form-yellow-icon.svg';
import { Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const STATUS_FINISHED = 'FINISHED';
const STATUS_NOT_APPROVED = 'NOT_APPROVED';
const STATUS_IN_REVIEW = 'IN_REVIEW';

const Icon = styled('img')`
	height: 24px;
	width: 24px;
`;

const TaskFunctionalities = ({ forms, assets, inventory }) => {
	const lan = useLanguage();

	const [functionalityIcons, setFunctionalityIcons] = useState([]);

	const getFormsFunctionality = () => {
		let icon;
		let tooltipTitle = null;

		switch (forms.status) {
			case STATUS_FINISHED:
				icon = <Icon src={FormsIconGreen} />;
				tooltipTitle = lan.AllFormsFinished;
				break;
			case STATUS_NOT_APPROVED:
				icon = <Icon src={FormsFinishedIcon} />;
				tooltipTitle = lan.hasNotApprovedForms;
				break;
			case STATUS_IN_REVIEW:
				icon = <Icon src={FormsIconYellow} />;
				tooltipTitle = lan.inReviewForms;
				break;
			default:
				icon = <Icon src={FormsIcon} />;
		}

		let text = (
			<React.Fragment>
				{tooltipTitle && (
					<React.Fragment>
						<b className='mx-auto'>{tooltipTitle}</b>
						<br />
					</React.Fragment>
				)}
				<span className='mx-auto'>{`${lan.finishedForms}: ${forms?.finishedCount || 0} ${lan.of} ${
					forms.totalCount
				}`}</span>
			</React.Fragment>
		);

		return { text, icon };
	};

	useEffect(() => {
		let taskIcons = [];

		if (forms && forms.totalCount) {
			const { icon, text } = getFormsFunctionality();
			taskIcons.push({ text, icon, type: 'forms' });
		}

		if (assets && assets.totalCount) {
			taskIcons.push({ text: lan.assets, icon: <Icon src={AssetsIcon} />, type: 'assets' });
		}

		if (inventory && inventory.totalCount) {
			taskIcons.push({ text: lan.inventories, icon: <Icon src={InventoriesIcon} />, type: 'inventory' });
		}

		setFunctionalityIcons(taskIcons);
	}, [forms, assets, inventory, lan]);

	return (
		<Grid container>
			{functionalityIcons.map(({ text, icon, type }) => (
				<Grid item lg={3} key={type}>
					<Tooltip title={text}>{icon}</Tooltip>
				</Grid>
			))}
		</Grid>
	);
};

export default TaskFunctionalities;
