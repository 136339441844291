import React, { useState, useEffect } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import { Col, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { selectSession } from '../../core/store/modules/session/sessionSelectors';
import AssetInventoryHeader from './components/AssetInventoryHeader';
import AssetInventoryTitles from './components/AssetInventoryTitles';
import CreateFieldsAssetInventory from './components/CreateFieldsAssetInventory';
import TemplateFilesGenerator from './components/TemplateFilesGenerator';
import EditForm from './EditForm';
import { checkCustomTemplateType } from './util';

const TaskAssets = ({
	assets,
	activeAsset,
	assetHasImages,
	images,
	changeActiveAsset,
	client,
	timezone,
	reloadData,
	taskData,
	taskAgent,
	idAccountTask
}) => {
	const lan = useLanguage();
	const navigate = useNavigate();
	const session = useSelector(selectSession);
	const dispatch = useDispatch();

	const [editAsset, setEditAsset] = useState(false);
	const [customTemplateType, setCustomTemplateType] = useState({ xslx: false, html: false });
	const [showAssetInventoryFileField, setShowAssetFieldFile] = useState({});
	const [showAssetInventoryFileQuestion, setShowAssetFieldQuestion] = useState({});

	useEffect(() => {
		const customTypes = checkCustomTemplateType(assets[activeAsset]);
		setCustomTemplateType(customTypes);
	}, [activeAsset, assets]);

	const viewAsset = () => {
		const asset = assets[activeAsset];
		navigate(`${ENDPOINTS.assetManagement}?id=${asset.id}`);
	};

	const editAssetVisibility = () => {
		setEditAsset(!editAsset);
	};

	const getTextFile = async (question, isField) => {
		let getTextFromFile = () => {
			return new Promise((res, rej) => {
				let request = new XMLHttpRequest();
				request.open('GET', question.file, true);
				request.send();
				request.onreadystatechange = () => {
					if (request.readyState === 4 && request.status == '200') {
						let type = request.getResponseHeader('Content-Type');
						if (type.indexOf('text') != 1) {
							res(request.response);
						}
					}
				};
			});
		};
		let text = await getTextFromFile();
		
		if (isField) {
			let showAssetField = {}
			showAssetField[question.id] = text
			setShowAssetFieldFile(Object.assign(showAssetField))
		} else {
			let showAssetQuestion = {}
			showAssetQuestion[question.id] = text
			setShowAssetFieldQuestion(Object.assign(showAssetQuestion))
		 }
		
	};

	const Header = (
		<AssetInventoryHeader
			isAsset
			showFormFile={showAssetInventoryFileField}
			assetInventories={assets}
			activeAssetInventory={activeAsset}
			timezone={timezone}
			getTextFileHeader={getTextFile}
		/>
	);

	return (
		<div style={{ height: '50%' }} className='my-5'>
			{editAsset && (
				<EditForm
					idAccountTask={idAccountTask}
					asset={assets[activeAsset]}
					onClose={editAssetVisibility}
					Header={Header}
					taskId={taskData.idFielder}
					reloadData={reloadData}
					session={session}
					client={client}
					lan={lan}
					taskData={taskData}
				/>
			)}

			<Row style={{ height: '70%', minHeight: '300px' }}>
				<Col sm={3}>
					<Scrollbars renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}>
						<div className='mt-1 taskModules-label'>
							<span className='mb-1'>{lan.assetList}</span>
						</div>
						<AssetInventoryTitles
							assetInventories={assets}
							activeAssetInventory={activeAsset}
							changeActiveAssetInventory={changeActiveAsset}
							client={client}
							editAssetInventoryVisibility={editAssetVisibility}
							isAsset
						/>
					</Scrollbars>
				</Col>
				<Col>
					<Scrollbars renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }} />}>
						<AssetInventoryHeader
							isAsset
							showFormFile={showAssetInventoryFileField}
							assetInventories={assets}
							activeAssetInventory={activeAsset}
							timezone={timezone}
							getTextFileHeader={getTextFile}
						/>
						<CreateFieldsAssetInventory
							assetInventories={assets}
							activeAssetInventory={activeAsset}
							timezone={timezone}
							showFormFile={showAssetInventoryFileQuestion}
							getTextFileHeader={getTextFile}
						/>
					</Scrollbars>
				</Col>
			</Row>
			<div className='mb-5'>
				{assets && (
					<div className='row'>
						<Col sm={4}>
							<button className='Fielder-button-clear' onClick={viewAsset}>
								{lan.viewAsset}
							</button>
						</Col>
						<TemplateFilesGenerator
							customTemplateType={customTemplateType}
							taskData={taskData}
							taskAgent={taskAgent}
							asset={assets[activeAsset]}
							hasImages={assetHasImages}
							images={images}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaskAssets;
