import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';
import { REQUEST_TYPES } from '@core/constants/notification/notification.request.constants';

export const getNotificationsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_SYSTEM_NOTIFICATIONS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.topMenuNotifications, requestBody, onSuccess, onError, loadingConfig);
};

export const updateSetAsShowNotify = async (notificationId, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_SET_AS_SHOW_NOTIFY,
		idUser: user.idUser,
		notificationId: notificationId
	};

	return handlePostRequest(API_LINKS.topMenuNotifications, requestBody, onSuccess, onError, loadingConfig);
};

export const updateNotificationsRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		idUser: user.idUser,
	};

	return handlePostRequest(API_LINKS.topMenuNotifications, requestBody, onSuccess, onError, loadingConfig);
};
