import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './ApprovedCheckbox.css';
class ApprovedCheckbox extends React.Component {
  

	componentDidMount() {
		const { id, checked } = this.props;
		const checkbox = document.getElementById(id);
		if (checkbox && checked) {
			checkbox.checked = true;
		}
		}

		componentDidUpdate(prevProps) {
		const { id, checked } = this.props;
		const checkbox = document.getElementById(id);
		if (checkbox && checked !== prevProps.checked) {
			checkbox.checked = checked;
		}
		}
	  
	
	
	
render() {
    const { checked, onChange, name, id, label } = this.props;

 
	  return (
		<Col sm={3}>
			<Row className='ml-3'>  
				<label className="custom-checkbox">
					<input
						type="checkbox"
						checked={checked}
						onChange={onChange}
						name={name}
						id={id}
					/>
					<span className="checkmark"></span>
					{label} 	  
				</label>
			</Row>
		</Col>
    );
  }
}

export default ApprovedCheckbox;