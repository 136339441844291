import { REQUEST_TYPES as USER_REQUEST_TYPES } from '@core/constants/users/users.request.constants';
import { REQUEST_TYPES as MANAGER_REQUEST_TYPES } from '@core/constants/manager/request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import { handlePostRequest } from '@core/api/request';

import API_LINKS from '../apiGateWayLinks';
import { Manager } from 'FielderUtils/roles/roles';

// POST
export const createManagerRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_CREATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

// GET
export const getManagers = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.GET_USERS_TABLE,
		...body,
	};
	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

// PUT
export const updateManager = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_UPDATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


export const changeManagerStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_CHANGE_USER_STATUS,
		role: Manager.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


// DELETE
export const deleteManagerRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: USER_REQUEST_TYPES.DO_ACTION_DELETE_USER,
		role: Manager.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};
