import React, { Component } from 'react';

import loadingLottie from '@assets/lotties/loadingFielder.json';
import Lottie from 'react-lottie';
import { Row, Col } from 'react-bootstrap';
import AsyncSearch from '../AsyncSearch/AsyncSearch';
import ReactSelect from 'react-select';
import ReactExport from 'react-export-excel';
import { DatePicker } from 'antd';
import moment from 'moment';
import TextField from '~/TextField/TextField.component';
import FielderButton from 'FielderComponents/FielderButton';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;

const CompanyTasksBrief = (props) => {
	const elements = props.dailyTaskByCompanies.map((company, index) => (
		<tr key={'TaskCompanyBranchResume-'+index}>
			<td className='text-center'>{company.companyName}</td>
			<td className='text-center'>{company.totalManaging}</td>
			<td className='text-center'>{company.totalAssignedToTeam}</td>
			<td className='text-center'>{company.totalAssignedToPartner}</td>
			<td className='text-center'>{company.totalWaitingAgent}</td>
			<td className='text-center'>{company.totalInProgress}</td>
			<td className='text-center'>{company.totalStartRoute}</td>
			<td className='text-center'>{company.totalArrived}</td>
			<td className='text-center'>{company.totalExecuting}</td>
			<td className='text-center'>{company.totalPause}</td>
			<td className='text-center'>{company.totalFinish}</td>
			<td className='text-center'>{company.totalCancel}</td>
			<td className='text-center'>{company.totalCount}</td>
		</tr>
	));
	return <tbody>{elements}</tbody>;
}

const getDisabledDates = (current) => {
	const A_WEEK_OLD = moment().clone().subtract(10, 'days').startOf('day');
	const A_WEEK_IN_FUTURE = moment().clone().add(10, 'days').endOf('day');
	return current && !current.isBetween(A_WEEK_OLD, A_WEEK_IN_FUTURE);
};


class TaskCompanyBranchResume extends Component {
	constructor(props) {
		super(props);
		this.initialState = {};
		this.state = this.initialState;
	}




	CookieFilter = (props) =>
		this.props.cookieStorageFilter.map((filter, i) => (
			<Col sm={'auto'} className='m-1' key={filter.name + '-tag'}>
				<Row className='tag-button my-auto'>
					<div className='col-auto mb-1 pr-1' key={i+ 'close-tag-container'}>
						<button
							key={'btn-close-' + i}
							id={'btn-close-' + i}
							className='close'
							aria-label='Close'
							onClick={() => this.props.removeFilter(i)}

						>
							<span style={{ fontSize: '20px' }} aria-hidden='true'>
								&times;
							</span>
						</button>
					</div>

					<div className='col pl-0 pt-1'  key={i+ 'load-tag-container'}>
						<button
								key={'btn-close-' + i}
								id={'btn-close-' + i}
								className='close pt-1 pl-1 font-sizeAvancedFilter'
								aria-label='Close'
								onClick={() => this.props.loadAdvancedFilters(i)}

							>
							{filter.name}
						</button>
					</div>
				</Row>
			</Col>
		))




	render() {
		return (
			<div className='col-12 col-md-9 mb-2 mt-4 pr-3'>
				<div className={'Dashboard-tasksBrief Dashboard-box'}>
					<div className='Dashboard-box-content pr-1'>
						<div className='row'>
							<div className='col-auto'>
								<p className='Dashboard-box-title'>{this.props.lan.summaryOfTask}</p>
							</div>

							<ExcelFile
								filename={this.props.lan.taskCompanyDashboard}
								element={<button id='myExcellCompany' hidden></button>}
							>
								<ExcelSheet
									data={this.props.exportableDataCompany}
									name={this.props.lan.task}
								>
									{this.props.exportableDataCompanyHeaders.map((col) => <ExcelColumn key={col.label} label={col.label} value={col.key} />)}
								</ExcelSheet>
							</ExcelFile>
							{this.props.renderDailyCompanyTask && (
								<div className='col-auto mt-4 pt-1'>
									<img
										src={`${process.env.AWS_FIELDER_ICONS}import-icon.svg`}
										className='icon Fielder-icon-button'
										alt='Exportar'
										title={this.props.lan.export}
										style={{ marginTop: '-5px' }}
										onClick={this.props.downloadExcelDailyCompany}
										id='id-export-agent'
									/>
								</div>
							)}

							<div className='col mt-3'>
								{this.props.showAlertTextFilter && (
									<div className='alert alert-success alert-dismissible fade show' role='alert'>
										{this.props.alertFilterText}
										<button
											type='button'
											className='close'
											data-dismiss='alert'
											aria-label='Close'
											onClick={this.props.closeFilterAlert}
										>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-2'>
							<div className='col-auto'>
								<p className='mt-1 ml-3'>{this.props.lan.date.toUpperCase()+': '}</p>
							</div>
							<div className='col-auto'>
								<RangePicker
									format='YYYY-MM-DD'
									placeholder={[this.props.lan.initialDate, this.props.lan.finalDate]}
									disabledDate={getDisabledDates}
									value={this.props.filterDates}
									onChange={this.props.changeDates}
									{...this.props}
								/>
							</div>
						</div>

						<div className='row' style={{alignItems: 'center'}}>
							<div className='col-auto ml-2 pl-3'>
								{`${this.props.lan.searchByCompany}:`}
							</div>
							<div className='col-5'>
								{this.props.updateViewSelect && (
									<AsyncSearch
										id='select-add-company'
										lan={this.props.lan}
										className='react-select__control full-width'
										getOptions={this.props.getCompanyList}
										onChange={this.props.setCompanyIds}
										defaultValue={this.props.defaultCompanies}
										label={this.props.lan.placeHolderCompany}
										showInitialOptions
										getInitialOptions={this.props.getInitialOptionsCompany}
										multiple
										/>
								)}
							</div>

							<div className='col-3'>
								<TextField id='txt-filter-name' label={this.props.lan.filterName} size='small' />
							</div>

							<div className='col-auto'>
								<FielderButton id='btn-save-filter' onClick={this.props.saveFilter} >
									{this.props.lan.saveFilter}
								</FielderButton>
							</div>

						</div>
						{this.props.showCookies &&
							<div className='row ml-2'>
								<this.CookieFilter cookieStorageFilter={this.props.cookieStorageFilter} />
							</div>
						}

						{this.props.showBranches && (
							<div className='row ml-2 mt-2'>
								<div className='col-auto pt-1 mb-2 ml-2 pl-3'>
									{`${this.props.lan.searchByBranch}:`}
								</div>
								<div className='col-5 px-2'>
										<ReactSelect
											variant='outlined'
											options={this.props.branchesByCompany}
											placeholder={this.props.lan.placeHolderBranch}
											onChange={this.props.handleSelectBranch}
											isMulti
											fullWidth
										/>
								</div>
							</div>
						)}



						{this.props.renderDailyCompanyTask ? (
							<>
								<div className='Dashboard-tasksBrief-table mt-2'>
										<div className='table-responsive-sm'>
											<table className='table table-sm table-hover'>
												<thead>
													<tr>
														<th scope='col-auto'>{this.props.lan.company}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_0}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_9}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_8}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_3}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_4}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_6}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_10}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_5}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_15}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_2}</th>
														<th scope='col-auto'>{this.props.lan.taskStatus_1}</th>
														<th scope='col-auto'>{this.props.lan.grandTotal}</th>
													</tr>
												</thead>
													<CompanyTasksBrief dailyTaskByCompanies={this.props.dailyTaskByCompanies} />
											</table>
										</div>
								</div>
							</>
						) : (
							<div className='row'>
								<div className='col-12 d-flex justify-content-center no-padding marginLoadingTaskBrief'>
									<Lottie
										options={{
											loop: true,
											autoplay: true,
											animationData: loadingLottie,
										}}
										height='40px'
										width='40px'
									/>
								</div>
								<div className='col d-flex justify-content-center no-padding'>
									{this.props.lan.loading}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default TaskCompanyBranchResume;
