import moment from 'moment';

import { TIME } from '../../constants/time/time.constants';
import { isManager } from '../roles/isRole';

const getSLAValue = (value) => {
	switch (value) {
		case TIME.TODAY:
			return moment().endOf('day').diff(moment(), 'seconds');
		case TIME.WEEK:
			return moment().add(1, 'week').diff(moment(), 'seconds');
		case TIME['1HOUR']:
			return 3600;
		case TIME['3HOURS']:
			return 10800;
		case TIME['5HOURS']:
			return 18000;
		case TIME.ALL:
		default:
			return null;
	}
};

export const getTaskRequest = (filter, selectedAgents, assignTask, user) => {
	const sla = getSLAValue(filter.task.sla);

	let companies = [];
	let branches = [];
	let teams = [];
	let agents = null;
	let priority = null;

	if (filter.task.branches && filter.task.companies) {
		branches = filter.task.branches;
	}

	if (branches.length === 0 && filter.task.companies) {
		companies = filter.task.companies.map((company) => company.value.id);
	}

	if (isManager(user)) {
		if (user.idTeams && user.idTeams.length > 0) {
			teams = user.idTeams;
		} else {
			teams = [-1];
		}
	}

	if (filter.task.priority >= 0) {
		priority = filter.task.priority;
	}

	if (assignTask === 0 && selectedAgents.length > 0) {
		agents = selectedAgents.map((agent) => agent.id);
	}

	return {
		startDate: sla == null ? moment(filter.startDate).startOf('day').valueOf() : Date.now(),
		endDate: sla == null ? moment(filter.endDate).endOf('day').valueOf() : Date.now() + sla * 1000,
		priority,
		status: filter.task.status,
		companyIds: companies,
		branchIds: branches,
		teamIds: teams,
		idAgents: selectedAgents.length > 0 ? agents : null,
	};
};

/**
 * Search for the agents filters and creates the query params for
 * the get request to return agents
 *
 * @param filters - Command Center filters (such as teams, status, task count, etc)
 * @param selectedAgents - Agents to be searched for (if the user selects specific ones)
 * @param assignTask
 * @param user - Logged user
 * @param page - Page to be requested, when using pagination
 *
 * @returns object containing the necessary filters and info for the request
 */
export const getTasksQueryParams = (filter, selectedAgents, assignTask, user, page) => {
	const taskFilter = filter.task;

	const queryFilters = {
		startDate: moment(filter.startDate).startOf('day').valueOf(),
		endDate: moment(filter.endDate).endOf('day').valueOf(),
		userId: user.idUser,
		page,
	};

	if (taskFilter.sla && taskFilter.sla !== TIME.ALL) {
		// To avoid problems with timezone, end of the day would be handled by timeToComplete
		if (taskFilter.sla === TIME.TODAY) {
			queryFilters.completeBy = moment().endOf('day').valueOf();
		} else {
			queryFilters.sla = taskFilter.sla;
		}
	}

	if (taskFilter.status) {
		queryFilters.status = taskFilter.status;
	}

	if (taskFilter.branches && taskFilter.companies) {
		queryFilters.branchIds = taskFilter.branches;
	}

	if (queryFilters.branchIds.length === 0 && taskFilter.companies) {
		queryFilters.companyIds = taskFilter.companies.map((company) => company.value.id);
	}

	if (isManager(user)) {
		if (user.idTeams && user.idTeams.length > 0) {
			queryFilters.teamIds = user.idTeams;
		} else {
			queryFilters.teamIds = [-1];
		}
	}

	if (taskFilter.priority >= 0) {
		queryFilters.priority = taskFilter.priority;
	}

	if (assignTask === 0 && selectedAgents.length > 0) {
		queryFilters.idAgents = selectedAgents.map((agent) => agent.id);
	}

	if (taskFilter.search) {
		queryFilters.search = taskFilter.search;
	}

	return queryFilters;
};
