// ROLE MANAGEMENT
const PAGE_NAME = 'ROUTES';
const ROUTES = 'routes';
const NAME = 'routes';
const VIEW = 0;
const EDIT = 1;
const CANCEL = 2;
const RESTART_ROUTE_TASKS = 3;
const RESTART_ROUTE_CANCELED_TASKS = 4;
const RESTART_ROUTE = 5;
const RESCHEDULE_ROUTE = 6;
const REORGANIZE_ROUTE = 7;
const ADD_TASK_BY_FOLIO = 8;
const DELETE_TASK_FROM_ROUTE = 9;

export default {
	PAGE_NAME,
	NAME,
	ROUTES,
	VIEW,
	EDIT,
	CANCEL,
	RESTART_ROUTE_TASKS,
	RESTART_ROUTE_CANCELED_TASKS,
	RESTART_ROUTE,
	RESCHEDULE_ROUTE,
	REORGANIZE_ROUTE,
	ADD_TASK_BY_FOLIO,
	DELETE_TASK_FROM_ROUTE,
};
