import React, { useState, useEffect } from 'react';

import Card from '~/Card/Card.component';
import { Grid } from '@mui/material';

import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import CardTitle from '~/CardTitle/CardTitle.component';

const CardTaskCount = ({ data }) => {
	const lan = useLanguage();
	const [total, setTotal] = useState(0);

	useEffect(() => {
		if (data.length > 0) {
			let currentTotal = data[0].total + data[1].totalMyOperation + data[1].totalClientsOperation;
			setTotal(currentTotal);
		}
	}, [data]);

	return (
		<Grid container justifyContent='center'>
			<Grid item xs={12}>
				<Card sx={{ height: '7.5rem' }}>
					<Grid container justifyContent='center' spacing={2} className='p-3'>
						<Grid item xs={12} className='py-0'>
							<CardTitle>{lan.totalTasks}</CardTitle>
						</Grid>
						<Grid item xs={10} className='py-0' justifyContent='center'>
							<span style={{ fontFamily: 'Lato', fontSize: '3rem', fontWeight: 'bold' }}>{total}</span>
							<span style={{ fontFamily: 'Lato', fontSize: '0.8rem', marginLeft: '0.2rem' }}>{lan.tasks}</span>
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</Grid>
	);
};

export default CardTaskCount;
