import React, { Component } from 'react';

import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Col, Row } from 'react-bootstrap';
import './TaskDetails.css';
import { connect } from 'react-redux';

/**
 * Contains the Agent content from the {@link TaskDetails} component
 */
class TaskAgent extends Component {
	/**
	 * Formats the telephone to be displayed
	 *
	 * @param {string} phone - Phone to be formated
	 *
	 * @returns {string} - Formated Phone
	 */
	formatPhone = (phone) => {
		var countryCode = '+' + phone.slice(0, 2) + ' ';
		var part1 = '(' + phone.slice(2, 5) + ') ';
		var part2 = phone.slice(5, 8) + '-' + phone.slice(8);

		return [countryCode, part1, part2].join('');
	};

	/**
	 * Sets the meters into kilometers to be displayed
	 * into the Agent compoenent
	 *
	 * @param {string} meters - Meters to be formated into
	 *                          kilometers
	 *
	 * @returns {string} - Kilometers found
	 */
	formatDistance = (meters) => {
		if (meters !== '') {
			var km = parseInt(meters) / 1000;
			return km.toFixed(1) + ' km';
		}
		return '';
	};

	/**
	 * Sets the seconds into hours to be displayed
	 * into the Agent compoenent
	 *
	 * @param {string} seconds - Seconds to be formated into
	 *                           hours
	 *
	 * @returns {string} - Hours and minutes found
	 */
	formatTime = (seconds) => {
		var hrs = Math.floor(parseInt(seconds) / 3600);
		var min = Math.floor(parseInt(seconds) % 3600);

		return hrs + ' hrs ' + min + ' min';
	};

	/**
	 * Helper for the Labels and text components to reduce
	 * code written
	 *
	 * @param {*} props - Text for the label and text
	 */
	LabelText = (props) => {
		var text = props.text;
		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={'td-label-input ' + props.labelClass}>{props.label}</Col>
				</Row>
				<Row>
					<Col className={'td-default-text align-left ' + props.textClass}>{text}</Col>
				</Row>
			</Col>
		);
	};

	render() {
		return (
			<div className='container' style={{ height: '350px%' }}>
				<React.Fragment>
					<Row>
						<Col sm={3} className='my-auto '>
							<img
								src={this.props.taskAgent.profilePicture}
								className=' pofile-pic '
								alt='Imagen de Perfil'
							/>
						</Col>

						{RoleUtils.isUserClient(this.props.user) ? (
							<Col>
								<Col>
									<this.LabelText
										label={this.props.lan.assignedAgent}
										text={this.props.taskAgent.name}
										className='my-3'
										textClass='td-task-number taskAgent-color'
									/>
								</Col>
							</Col>
						) : (
							<Col className='my-auto'>
								<Col>
									<this.LabelText
										label={this.props.lan.assignedAgent}
										text={this.props.taskAgent.name}
										className='my-3'
										textClass='td-task-number taskAgent-color'
									/>
								</Col>
								<Col>
									<this.LabelText
										label={this.props.lan.assignedWork}
										text={this.props.taskAgent.workSchedule}
										className='my-3'
										textClass='agent-text taskAgent-color'
									/>
								</Col>
								<Col>
									<this.LabelText
										label={this.props.lan.phoneAgent}
										text={this.formatPhone(this.props.taskAgent.phone)}
										className='my-3'
										textClass='agent-text taskAgent-color'
									/>
								</Col>
								<Col>
									<this.LabelText
										label={this.props.lan.emailAgent}
										text={this.props.taskAgent.email}
										className='my-3'
										textClass='agent-text taskAgent-color'
									/>
								</Col>
							</Col>
						)}
						<Col>
							<Col>
								<this.LabelText
									label={this.props.lan.typeVehicle}
									text={this.props.taskAgent.vehicleType}
									className='my-3'
									textClass='agent-text taskAgent-color'
								/>
							</Col>
							<Col>
								<this.LabelText
									label={this.props.lan.idVehicle}
									text={this.props.taskAgent.vehicleId}
									className='my-3'
									textClass='agent-text taskAgent-color'
								/>
							</Col>
							{!RoleUtils.isUserClient(this.props.user) ? (
								<Col>
									<this.LabelText
										label={this.props.lan.timeTraveled}
										text={this.props.taskAgent.timeTaken}
										className='my-3'
										textClass='agent-text taskAgent-color'
									/>
								</Col>
							) : null}
							{!RoleUtils.isUserClient(this.props.user) ? (
								<Col>
									<this.LabelText
										label={this.props.lan.distanceTraveled + ' (kms)'}
										text={this.formatDistance(this.props.taskAgent.distanceTraveled)}
										className='my-3'
										textClass='agent-text taskAgent-color'
									/>
								</Col>
							) : null}
						</Col>
					</Row>
				</React.Fragment>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(TaskAgent);
