import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loadingReducer from '../modules/loading/loading.slice';
import sessionReducer from '../modules/session/sessionSlice';
import { TransformEncrypt, TransformCompress } from '../persistTransforms';

// Things to be persisted in the localStorage (only set the most important things)
const whitelist = ['session'];
const transforms = [TransformEncrypt]; // , TransformCompress];

const persistConfig = {
	key: 'utils',
	storage,
	whitelist,
	transforms,
};

const utilsReducers = combineReducers({
	session: sessionReducer,
	loading: loadingReducer,
});

export default persistReducer(persistConfig, utilsReducers);
