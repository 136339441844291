import React from 'react';

import Box from '@mui/material/Box';

import Chip from '../Chip/Chip.component';

const Chips = ({ elements, getLabel, onDelete, chipProps, maxChips }) => (
	<Box
		sx={{
			display: 'flex',
			flexWrap: 'wrap',
			listStyle: 'none',
			p: 0.5,
			m: 0,
		}}
		component='ul'
	>
		{elements.map((element) => (
			<Chip element={element} getLabel={getLabel} onDelete={onDelete} chipProps={chipProps} isArray />
		))}
	</Box>
);

export default Chips;
