import React from 'react';

import { Button, Hidden, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Gainsboro, LightGreySubtitleColor } from '@core/theme/baseTheme';
import { styled } from '@mui/material/styles';

const OptionButton = styled(Button)`
	font-family: 'Lato';
	color: ${LightGreySubtitleColor};
	border: 1px solid ${LightGreySubtitleColor};
	&:hover {
		color: black;
		border: 1px solid ${Gainsboro};
		background: ${Gainsboro};
	}
`
const ResponsiveButton = ({ component, label, icon, ...props }) => {
	const ButtonComponent = () => {
		if (component) {
			return <component {...props}>{label}</component>;
		}

		return (
			<OptionButton {...props} endIcon={icon}>
				{label}
			</OptionButton>
		);
	};

	return (
		<Tooltip title={`${label} label`}>
			<>
				<Hidden mdDown>
					<ButtonComponent />
				</Hidden>
				<Hidden mdUp>
					<IconButton sx={{fontSize: '0.8rem'}} {...props}>{icon}</IconButton>
				</Hidden>
			</>
		</Tooltip>
	);
};

export default ResponsiveButton;

ResponsiveButton.prototype = {
	component: PropTypes.node,
	label: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
};
