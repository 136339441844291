/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { getCommandCenterAgents } from '../../../api/agents/agents.commandCenter.service';
import Priority from '../../../constants/task/TaskPriority.constants';
import Status from '../../../constants/task/TaskStatus.constants';
import { TIME } from '../../../constants/time/time.constants';
import { handleAgentDetails } from '../../../utils/agent/agentCommandCenter';

const initialState = {
	view: {
		agents: true,
		tasks: true,
		selectedAgents: [],
		showAgentDetail: false,
		agentsByTaskNumber: false,
		bouncingPins: true,
		realTime: true,
		assignTask: 0,
	},
	agents: {
		list: [],
		totalCount: null,
		status: {
			online: null,
			away: null,
			offline: null,
			inactive: null,
			noData: null,
		},
		request: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: null,
		},
		singleAgent: null,
		unsettledUpdates: 0,
	},
	tasks: {
		list: [],
		status: {
			[Status.MANAGING]: 0,
			[Status.WAITING_FOR_AGENT]: 0,
			[Status.IN_PROGRESS]: 0,
			[Status.EXECUTING]: 0,
			[Status.FINISH]: 0,
			[Status.CANCEL]: 0,
			[Status.START_ROUTE]: 0,
			[Status.CANCEL_BY_PARTNER]: 0,
			[Status.ASSIGNED_TO_PARTNER]: 0,
			[Status.ASSIGNED_TO_TEAM]: 0,
			[Status.ARRIVED]: 0,
			[Status.DELETED]: 0,
			[Status.PAUSED]: 0,
			[Status.AUTO_ASSIGNING]: 0,
			[Status.AUTO_ASSIGNED_FAILED]: 0,
		},
		request: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: null,
		},
		pageRequest: {
			isLoading: false,
			isSuccess: false,
		},
		pageNumber: 1,
		hasMore: true,
		totalCount: 0,
		unsettledUpdates: 0,
	},
	assign: {
		tasks: {},
		tasksToAdd: {},
	},
	filters: {
		startDate: moment().startOf('day').valueOf(),
		endDate: moment().endOf('day').valueOf(),
		agent: {
			status: null,
			teamIds: [],
			taskCount: [],
		},
		task: {
			status: [],
			teamIds: [],
			sla: TIME.ALL,
			companies: [],
			branches: [],
			branchOptions: [],
			priority: Priority.ALL,
			search: null,
		},
		request: {
			isLoadingBranches: false,
		},
	},
	taskGroup: null,
};

export const getAgentDetails = createAsyncThunk('commandCenter/getAgentDetails', async (agentId, thunkAPI) => {
	const onSuccess = (response) => {
		const data = handleAgentDetails(response);
		return thunkAPI.fulfillWithValue(data);
	};

	const onError = (error) => thunkAPI.rejectWithValue(error);

	if (!agentId) {
		return thunkAPI.fulfillWithValue(null);
	}

	const state = thunkAPI.getState();
	const commandCenterFilters = state.commandCenter.filters;
	const filters = {
		startDate: moment(commandCenterFilters.startDate).startOf('day').valueOf(),
		endDate: moment(commandCenterFilters.endDate).endOf('day').valueOf(),
	};

	return getCommandCenterAgents(filters, agentId, onSuccess, onError);
});

const commandCenterSlice = createSlice({
	name: 'commandCenter',
	initialState,
	reducers: {
		setAgents: (state, action) => {
			state.agents = {
				...state.agents,
				status: action.payload.status,
				list: action.payload.list,
				totalCount: action.payload.totalCount,
				orderedList: action.payload.orderedList,
				unsettledUpdates: 0,
			};
		},
		setViewAssignTask: (state, action) => {
			state.view.assignTask = action.payload;
		},
		setSelectedAgents: (state, action) => {
			state.view.selectedAgents = action.payload;
		},
		setShowAgentDetail: (state, action) => {
			state.view.showAgentDetail = action.payload;
		},
		setView: (state, action) => {
			state.view = action.payload;
		},
		setDates: (state, action) => {
			[state.filters.startDate, state.filters.endDate] = action.payload;
			state.tasks.pageNumber = 1;
		},
		setAgentTaskCount: (state, action) => {
			state.filters.agent.taskCount = action.payload;
		},
		setAgentStatus: (state, action) => {
			state.filters.agent.status = action.payload;
		},
		setAgentTeams: (state, action) => {
			state.filters.agent.teamIds = action.payload;
		},
		setResetAgentRequest: (state) => {
			state.agents = {
				request: {
					isLoading: false,
					isSuccess: false,
					isError: false,
					error: null,
				},
				...state.agents,
			};
		},
		setTaskStatus: (state, action) => {
			state.filters.task.status = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTaskSLA: (state, action) => {
			state.filters.task.sla = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTaskPriority: (state, action) => {
			state.filters.task.priority = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTaskCompanies: (state, action) => {
			state.filters.task.companies = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTaskBranches: (state, action) => {
			state.filters.task.branches = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTaskSearch: (state, action) => {
			state.filters.task.search = action.payload;
			state.tasks.pageNumber = 1;
		},
		setTasksPageNumber: (state, action) => {
			state.tasks.pageNumber = action.payload;
		},
		setBranchOptions: (state, action) => {
			state.filters.task.branchOptions = action.payload;
			state.tasks.pageNumber = 1;
		},
		setClearClientBranch: (state) => {
			state.filters.task.branch = null;
			state.filters.task.company = null;
			state.tasks.pageNumber = 1;
		},
		setLoadingBranches: (state, action) => {
			state.filters.request.isLoadingBranches = action.payload;
		},
		setTasksToAssign: (state, action) => {
			state.assign.tasks = action.payload;
		},
		setTasksToAdd: (state, action) => {
			state.assign.tasksToAdd = action.payload;
		},
		setAgentsByTaskNumber: (state, action) => {
			state.view.agentsByTaskNumber = action.payload;
		},
		setBouncingPins: (state, action) => {
			state.view.bouncingPins = action.payload;
		},
		setRealTime: (state, action) => {
			state.view.realTime = action.payload;
		},
		setTaskList: (state, action) => {
			state.tasks.list = action.payload;
		},
		setTasks: (state, action) => {
			if (!action.payload) return;
			state.tasks = {
				...state.tasks,
				list: action.payload.commandCenterTasks,
				status: action.payload.tasksAnalytics,
				totalCount: action.payload.totalCount,
				hasMore: action.payload.totalCount > action.payload.commandCenterTasks.length,
				offset: action.payload.offset,
				unsettledUpdates: 0,
			};
		},
		setTaskUnsettledUpdates: (state, action) => {
			state.tasks = {
				...state.tasks,
				unsettledUpdates: action.payload,
			};
		},
		setAgentUnsettledUpdates: (state, action) => {
			state.agents = {
				...state.agents,
				unsettledUpdates: action.payload,
			};
		},
		setSingleAgent: (state, action) => {
			state.agents = {
				...state.agents,
				singleAgent: action.payload,
			};
		},
		setTaskGroup: (state, action) => {
			state.taskGroup = action.payload;
		},
	},
	extraReducers: {
		[getAgentDetails.fulfilled]: (state, action) => {
			state.agents = {
				...state.agents,
				singleAgent: action.payload,
				request: {
					isSuccess: true,
					isLoading: false,
					isError: false,
					error: null,
				},
			};
		},
		[getAgentDetails.pending]: (state) => {
			state.agents.request = {
				isLoading: true,
				isSuccess: false,
				isError: false,
				error: null,
			};
		},
		[getAgentDetails.rejected]: (state, action) => {
			state.agents.request = {
				isSuccess: false,
				isError: true,
				error: action.payload,
			};
		},
	},
});

export const {
	setTasks,
	setAgents,
	setTaskList,
	setViewAssignTask,
	setSelectedAgents,
	setShowAgentDetail,
	setView,
	setDates,
	setAgentStatus,
	setAgentTaskCount,
	setAgentTeams,
	setResetAgentRequest,
	setTaskSLA,
	setTaskPriority,
	setTaskStatus,
	setTaskCompanies,
	setTaskBranches,
	setTaskSearch,
	setTasksPageNumber,
	setBranchOptions,
	setClearClientBranch,
	setLoadingBranches,
	setTasksToAssign,
	setTasksToAdd,
	setAgentsByTaskNumber,
	setBouncingPins,
	setRealTime,
	setTaskUnsettledUpdates,
	setAgentUnsettledUpdates,
	setSingleAgent,
	setTaskGroup
} = commandCenterSlice.actions;

export default commandCenterSlice.reducer;
