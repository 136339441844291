// 4xx - Client errors
export const ERROR_CODE_STATUS_UNAUTHORIZED = 401;

// 5xx - Server Errors

// TO MIGRATE ERRORS
export const ERROR_CODE_USER_NOT_FOUND = 540;
export const ERROR_CODE_NOT_A_USER = 543;
export const ERROR_CODE_USER_DISABLED = 305;
export const ERROR_CODE_ACCOUNT_DISABLED = 402;
