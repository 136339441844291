import React from "react";
import view from "@assets/icons/view.svg";
import {Button} from "react-bootstrap";

class ViewFormatter extends React.Component {

  render() {
    return (<div align="center" className="task-view" ><Button className="task-view" onClick={this.props.value}><img src={view}/></Button></div>);
  }
}

export default ViewFormatter;
