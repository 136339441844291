import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { notifyItemStyled } from '../StyleComponents';

const NotificationItemSkeleton = () => {
	const classes = notifyItemStyled();

	const skeletons = [...Array(3)].map((_, index) => {
		const key = `notifyItem-skeleton-${index}`;
		return (
			<Box key={key} className={classes.skeletonBox} padding={3}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={2}>
					<Box>
						<Skeleton 
							variant='circle' className={classes.skeletonAvatar} 
							width='60px' height='60px' />
					</Box>
				</Grid>
				<Grid item xs={12} md={10}>
					<Box className={classes.skeletonTitle}>
						<Skeleton variant='text' width='50px' height='20px' />
						<Skeleton variant='text' width='50px' height='20px' />
					</Box>
					<Box className={classes.skeletonDescription}>
						<Skeleton variant='text' width='80%' height='20px' />
						<Skeleton variant='text' width='50%' height='10px' />
					</Box>
					<Box className={classes.skeletonFooter}>
						<Skeleton variant='text' width='70px' height='30px' />
					</Box>
				</Grid>
			</Grid>
    	</Box>
		);
	});

	return (
		<Box display='flex' flexDirection='column' padding={2}>
			{skeletons}
		</Box>
	);
};

export default NotificationItemSkeleton;
