import React, { Component } from 'react';

//Library
import Logo from '@assets/icons/logo-login-icon.svg';
import PrivacyPolicyUtil from 'FielderUtils/PrivacyPolicyUtil';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Modal, Image } from 'react-bootstrap';
import { connect } from 'react-redux';

import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { setLogOutUser, setUserPolicyTrue } from '../../core/store/modules/session/sessionSlice';

//Css
import './PrivatePolicy.css';
import 'FielderUtils/style.css';
import withRouter from '~/withRouter/withRouter.component';
import { acceptPrivacyPolicyRequest, recordLoginRequest } from '@core/api/account/account.service';

class PrivatePolicy extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			acceptPolicy: false,
		};
	}

	handleClose = () => {
		const { dispatch } = this.props;
		dispatch(setLogOutUser());
		//removeSession();
		this.props.closePrivatePolicy();
	};

	componentCleanup = () => {
		if (
			this.props.session !== undefined &&
			this.props.session !== null &&
			this.props.session.user !== null &&
			this.props.session.user.acceptPrivacyPolicy === false
		) {
			const { dispatch } = this.props;

			dispatch(setLogOutUser());
		}
	};

	componentDidMount = () => {
		window.addEventListener('beforeunload', this.componentCleanup);
	};

	componentWillUnmount = () => {
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup);
	};

	handleAcceptPolicy = () => {
		if (this.state.acceptPolicy === true) {
			const { dispatch } = this.props;

			const onSuccess = (res) => {
				const session = this.props.session;

				dispatch(setUserPolicyTrue());
				recordLoginRequest()

				if (RoleUtils.isRootGlobalAccountUser(session.user)) {
					this.props.navigate(ENDPOINTS.globalAccountDashboard);
				} else if (RoleUtils.isClientGlobalAccountUser(session.user)) {				
					this.props.navigate(ENDPOINTS.globalAccountClientDashboard);
				} else if (RoleUtils.isInternalAdminUser(session.user)) {
					this.props.navigate(ENDPOINTS.internalAdminDashboard);
				} else {
					if (
						this.props.location &&
						this.props.location.state &&
						this.props.location.state.from &&
						this.props.location.state.from.pathname &&
						this.props.location.state.from.pathname === ENDPOINTS.partners
					) {
						this.props.navigate(ENDPOINTS.partners);
					} else {
						this.props.navigate(ENDPOINTS.dashboard);
					}
				}
			};

			const loadingConfig = { dispatch }
			acceptPrivacyPolicyRequest(onSuccess, null, loadingConfig);

		} else {
			this.setState({ showAlertAccept: true });
		}
	};

	render() {
		return (
			<React.Fragment>
				<Modal
					id='ErrorPrivatePolicy'
					size='md'
					show={true}
					onHide={this.handleClose}
					dialogClassName='shadow-lg'
					className='Fielder-modals-header-border Fielder-modals-content-border shadow-lg'
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border'>
						<Modal.Title>
							<div className='d-flex justify-content-center ml-5 mb-2 mt-2'>
								<div className='col d-flex justify-content-center pl-0'>
									<Image src={Logo} />
								</div>
							</div>
							<div className='row-12 ml-5'>
								<div className='col pl-0'>{this.props.lan.privacyPolicyTilte}</div>
							</div>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body className='error-message-modal-body text-center'>
						<div className='row mt-5 mb-5'>
							<div className='col mx-5'>
								<span>{this.props.lan.privacyPolicyText1}</span>
							</div>
						</div>
						<div className='row mx-2'>
							<div className='ml-3 col-1 no-padding'>
								<input
									type='checkbox'
									className='form-check-input labelCheckbox d-none'
									id='checkbox-policy'
									name='checkbox-policy'
									ref='cbbranch'
									defaultChecked={false}
								/>

								<label
									htmlFor='checkbox-policy'
									className='square-checkbox ml-2'
									onChange={() => {}}
									onClick={() => {
										var validateChecked = document.getElementById('checkbox-policy').checked;
										if (validateChecked == false) {
											this.setState({
												acceptPolicy: true,
												showAlertAccept: false,
											});
										} else {
											this.setState({ acceptPolicy: false });
										}
									}}
								></label>
							</div>
							<div className='col no-padding'>
								{this.props.lan.privacyPolicyCheckbox1}{' '}
								<a
									className='href-color-and-size'
									href={PrivacyPolicyUtil.TERMS_OF_SERVICE_LINK}
									target='_blank'
									rel='noreferrer'
								>
									{' '}
									{this.props.lan.privacyPolicyCheckboxLink1}{' '}
								</a>{' '}
								{this.props.lan.privacyPolicyText2}{' '}
								<a
									className='href-color-and-size'
									href={PrivacyPolicyUtil.PRIVACY_POLICY_LINK}
									target='_blank'
									rel='noreferrer'
								>
									{' '}
									{this.props.lan.privacyPolicyCheckboxLink2}{' '}
								</a>
							</div>
						</div>
					</Modal.Body>

					<Modal.Footer>
						{this.state.showAlertAccept && (
							<div className='d-flex justify-content-center mx-2'>
								<div className='col d-flex justify-content-center mr-5 pr-2 mt-3 mb-3'>
									<div className='alert alert-warning mb-0' role='alert' id='showAlert'>
										{this.props.lan.acceptTermsAndConditions}
									</div>
								</div>
							</div>
						)}
						<div className='d-flex justify-content-center mx-2'>
							<div className='col d-flex justify-content-center mr-5 pr-5 mt-1 mb-3'>
								<button
									id='ErrorMessage-btn'
									type='submit'
									className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size error-message-btn-margin'
									onClick={this.handleAcceptPolicy}
								>
									{this.props.lan.accept}
								</button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(withRouter(PrivatePolicy));
