import { ParadisePink, OrchidCrayola, CottonCandy } from '@core/theme/baseTheme';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

const backgroundColor = [CottonCandy, OrchidCrayola, ParadisePink];

export const LoadingContainer = styled(motion.div)`
	margin-bottom: auto !important;
	margin-top: auto !important;
	margin-left: 0.5rem !important;
	margin-right: 0.5rem !important;
	width: 2rem;
	height: 1rem;
	display: flex;
	justify-content: space-around;
`;

export const BouncingCircle = styled(motion.span)`
	display: block;
	width: 0.5rem;
	height: 0.5rem;
	background-color: ${({ index }) => backgroundColor[index % 3]};
	border-radius: 0.25rem;
`;
