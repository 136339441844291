import React from 'react';

import AgentsIcon from '@assets/icons/agents-icon.svg';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const AccountsSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.accounts;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={() => goToPage(ENDPOINTS.accounts)}>
				<ListItemIcon>
					<img src={AgentsIcon} alt='accounts' />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default AccountsSideMenu;
