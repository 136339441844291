import { getFielderSession } from 'FielderUtils/session/Session';

export const isRoleValid = (name, type, roles) => {
	if (!roles) {
		const session = getFielderSession();
		if (session?.roles) {
			const rolesS = session.roles;
			if (name in rolesS && type in rolesS[name]) {
				return rolesS[name][type];
			}
		}
	} else {
		if (roles) {
			if (name in roles && type in roles[name]) {
				return roles[name][type];
			}
		}
	}

	return false;
};
