import React, { useState } from 'react';

import { FielderTextField, HelperText, FielderInputLabel } from './TextField.styles';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { validatePhone } from '@core/constants/base.constants';
import validator from 'validator';

const TextField = ({
	label,
	variant,
	id,
	className,
	InputProps,
	size,
	margin,
	onEnter,
	isRequired,
	type,
	onChange,
	shouldValidate,
	helperText,
	error: fieldError,
	...props
}) => {
	const lan = useLanguage();
	const [error, setError] = useState(null);

	const validate = (value) => {
		if (!value && isRequired) {
			return `${label} ${lan.isRequired}`;
		}

		if (!type) return;

		switch (type) {
			case 'phone':
				return !validatePhone(value) && `${lan.phone}: ${lan.messageErrorPhone}`;
			case 'email':
				return !validator.isEmail(value) && `${lan.email}: ${lan.messageErrorEmail}`;
		}
	};

	const hanldeKeyDown = (event) => {
		if (onEnter && event.key === 'Enter') {
			onEnter(event);
		}
	};

	const handleChange = (event) => {
		if (!onChange) return;
		onChange(event);
	};

	React.useEffect(() => {
		setError(fieldError);
	}, [fieldError]);

	return (
		<FormControl fullWidth className={className} size={size} margin={margin}>
			<FielderInputLabel htmlFor={id} margin={margin} size={size} error={error}>
				{label}
			</FielderInputLabel>
			<FielderTextField
				id={id}
				label={label}
				variant={variant}
				size={size}
				margin={margin}
				fullWidth
				onKeyDown={hanldeKeyDown}
				onChange={handleChange}
				error={error}
				{...props}
				{...InputProps}
			/>
			{helperText && <HelperText error={error}>{helperText}</HelperText>}
		</FormControl>
	);
};

export default TextField;

TextField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
	type: PropTypes.oneOf(['email', 'phone', 'text']),
};

TextField.defaultValues = {
	variant: 'outlined',
	isRequired: false,
};
