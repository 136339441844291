import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '~/TextField/TextField.component';
import { shareAppRequest } from '@core/api/mail/mail.service';
import { useDispatch } from 'react-redux';

const ShareModal = ({ closeDialog, lan }) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [sentSuccessfully, setSentSuccessfully] = useState(false);
	const [open, setOpen] = useState(true);

	const updateMail = (e) => {
		setEmail(e.target.value);
	};

	const validateEmail = () => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

	const sendMail = () => {
		const isValidEmail = validateEmail();
		setInvalidEmail(!isValidEmail);

		if (isValidEmail) {
			setOpen(false);

			const body = {
				email,
				subject: lan.meetFielder,
			};

			const onSuccess = () => {
				setEmail('');
				setSentSuccessfully(true);
			}

			const onError = ( error ) => {
				console.log(error)
			};

			const loadingConfig = { name: "SHARE_APP", dispatch };
			shareAppRequest(body, onSuccess, onError, loadingConfig);

			setOpen(true);
		}
	};

	
	return (
		<Dialog open={open} onClose={closeDialog}>
			<div className='row-12'>
				<DialogTitle>
					{lan.share}
					<IconButton
						aria-label='close'
						style={{ position: 'absolute', top: '0px', right: '0px' }}
						onClick={closeDialog}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
			</div>
			<DialogContent>
				<DialogContentText>{lan.shareText}</DialogContentText>
				<TextField id='txt-update-email' onChange={updateMail} label={lan.email} value={email} className='full-width my-3' />
				
				{(invalidEmail || sentSuccessfully) &&(				
					<Alert severity={invalidEmail ? 'error' : 'success'} className='mt-3'>
						{invalidEmail ? lan.sendEmailError : lan.sendEmail}
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={sendMail}>
					{lan.sendEmailAction}
				</Button>
				<Button onClick={closeDialog}>
					{lan.cancel}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ShareModal;
