import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import NoCellIcon from '@mui/icons-material/NoCell';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import moment from 'moment';
import { LastUpdateText } from '../Dashboard.styles';
import { Grid } from '@mui/material';

const AgentLastUpdate = ({ lastUpdate }) => {
	const lan = useLanguage();

	return (
		<Grid container alignItems='center'>
			<Grid item xs='auto'>
				{lastUpdate ? <MobileFriendlyIcon /> : <NoCellIcon />}
			</Grid>
			<Grid item xs>
				<LastUpdateText>{lastUpdate ? lastUpdate : lan.notAvailable}</LastUpdateText>
			</Grid>
		</Grid>
	);
};

export default React.memo(AgentLastUpdate);
