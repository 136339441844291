import React from 'react'

import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const MapContainer = styled('div')`
	width: 100%;
	height: ${(props) => (props.height ? props.height : '400px')};
`;

const LocationSearchTabs = styled(Tabs)({
	indicator: {
		backgroundColor: '#DC5C87',
	},
});

const LocationSearchTab = styled(Tab)({
	textColorPrimary: {
		color: '#878E88 !important',
	},
	selected: {
		color: '#070707 !important',
	},
});

const LocationSearchBody = styled('div')`
	background: #f0f2f5 !important;
`;

export { MapContainer, LocationSearchTabs, LocationSearchTab, LocationSearchBody };
