import RealTime from '@core/constants/realTime/actions.constants';

export const rowUpdateActions = new Set([
	RealTime.TASK_AGENT_ASSIGN,
	//RealTime.TASK_GROUP_ASSIGN
	RealTime.TASK_TEAM_ASSIGN,
	RealTime.TASK_TAGS_CREATE,
	RealTime.TASK_FUNCTIONALITIES_ASSIGN,
	RealTime.TASK_EXECUTE_AUTO_ASSIGN,
	RealTime.TASK_STATUS_UPDATE_AUTO_ASSIGN,
	RealTime.TASK_CANCEL,
	RealTime.TASK_GROUP_REFUSE,
	RealTime.TASK_GROUP_UPDATE,
	RealTime.TASK_PHOTO_DELETE,
	RealTime.TASK_RESCHEDULE,
	RealTime.TASK_FORM_ASSIGN,
	RealTime.TASK_ASSET_ASSIGN,
	RealTime.TASK_INVENTORY_ASSIGN,
	RealTime.TASK_ROUTE_ADD,
	RealTime.TASK_PARTNER_ASSIGN,
	RealTime.TASK_UPDATE,
	RealTime.TASK_FINISH,
	RealTime.TASK_MOBILE_STATUS_UPDATE,
	RealTime.TASK_MOBILE_TASK_TYPE_UPDATE,
	RealTime.TASK_MOBILE_RESET_EXECUTION,
	RealTime.TASK_GROUP_STATUS_UPDATE,
	RealTime.TASK_MOBILE_REJECT,
	RealTime.TASK_MOBILE_COMMENT_ADD,
	RealTime.TASK_MOBILE_PHOTO_UPLOAD,
	RealTime.TASK_MOBILE_DOCUMENT_UPLOAD,
	RealTime.TASK_MOBILE_FORM_ASSIGN,
	RealTime.TASK_MOBILE_ASSET_ASSIGN,
	RealTime.TASK_MOBILE_INVENTORY_ASSIGN,
	RealTime.TASK_MOBILE_AGENT_ASSIGN,
	RealTime.TASK_MOBILE_TEAM_ASSIGN,
	RealTime.TASK_MOBILE_CANCEL,
	RealTime.TASK_MOBILE_RESCHEDULE,
	RealTime.TASK_MOBILE_GROUP_ASSIGN,
	RealTime.TASK_MOBILE_UPDATE
]);
	

export const rowAddActions = new Set([RealTime.TASK_CREATE]);
export const rowDeleteActions = new Set([RealTime.TASK_DELETE]);
export const rowDisableActions = new Set([RealTime.TASK_DISABLE]);
export const rowEnableActions = new Set([RealTime.TASK_ENABLE]);
export const agentUpdateActions = new Set([RealTime.AGENT_POSITION_UPDATE]);
export const agentStatusUpdateActions = new Set([RealTime.AGENT_STATUS_UPDATE]);
export const mailCounterUpdateActions = new Set([RealTime.MAIL_COUNTER_UPDATE]);
export const imageDeleteActions = new Set([RealTime.IMAGE_DELETE]);

//Task Group
export const rowUpdateActionsTaskOnGroup = new Set([RealTime.TASK_GROUP_ASSIGN]);
export const rowAddActionsTaskGroup = new Set([RealTime.CREATE_TASK_GROUP]);
export const rowUpdateActionsTaskGroup = new Set([RealTime.UPDATE_TASK_GROUP, RealTime.UNLINK_TASK_FROM_GROUP]);
export const rowUpdateStatusActionsTaskGroup = new Set([RealTime.UPDATE_TASK_GROUP_STATUS ]);
