import React, { Component, createRef } from 'react';

import RoleUtils from 'FielderUtils/roles/roleUtils';
import { GOOGLE_URL } from 'FielderUtils/session/Session';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	Polygon,
	Circle,
	DirectionsRenderer,
	InfoWindow,
} from 'react-google-maps';
import { connect } from 'react-redux';
import './Map.css';

import { getPinIcon } from './Map-pin-import';

const randomColor = require('randomcolor');
/**
 *  Google Maps wrapper which can be customized according to Fielder App
 *  needs
 *
 * (Currently Supporting Creation of Circles, Polygons, and Markers)
 *
 * @extends Component
 *
 * @param {string} googleMapUrl - Google Maps Api URL (https://maps.googleapis.com/maps/api/js?key=[API_KEY])
 * @param {component} loadingElement - Element to be used while loading the map
 * @param {component} containerElement - Element to contain the map component,
 *                                      (define the size, the style or any
 *                                      configuration)
 * @param {component} mapElement - Map inside the container (define the size, style
 *                                 or any configuraition)
 * @example
 *      // Simple Map Invocation
 *      <Map
 *           googleMapURL="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&v=3.exp&libraries=geometry,drawing,places"
 *           loadingElement={ <div style={{ height: `100%` }} /> }
 *           containerElement={ <div style={{ height: `400px` }} /> }
 *           mapElement={ <div style={{ height: `100%` }} /> }
 *          />
 *
 * @param {?MarkerData} [markersData=null] - Information about the markers to be
 *                                           added to the map {@link MarkerData}
 * @param {?function} [markerChanged=null] - function that will handle the markerChanged
 *                                           action (receives coordinates as parameter)
 * @param {?CircleData} [circleData=null] - Information about the circles to be
 *                                          added to the map {@link CircleData}
 * @param {?function} [circleUpdateRadius=null] - Function defined by the parent
 *                                               method that must update the radius
 *                                               in the circle info, and then forceUpdate
 * @param {array} polygonData - Array of {@link Coordinates} that sets a polygon according
 *                              to the number of coordinates objects found
 *
 * @example
 *      // Creating Markers map
 *      <Map
 *           googleMapURL="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&v=3.exp&libraries=geometry,drawing,places"
 *           loadingElement={ <div style={{ height: `100%` }} /> }
 *           containerElement={ <div style={{ height: `400px` }} /> }
 *           mapElement={ <div style={{ height: `100%` }} /> }
 *           markersData={ markersInfo }
 *           markerChanged={this.markerChanged}
 *          />
 *
 * @example
 *      // Creating Circles map
 *      <Map
 *           googleMapURL="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&v=3.exp&libraries=geometry,drawing,places"
 *           loadingElement={ <div style={{ height: `100%` }} /> }
 *           containerElement={ <div style={{ height: `400px` }} /> }
 *           mapElement={ <div style={{ height: `100%` }} /> }
 *           circleData={ circleInfo }
 *           circleUpdateRadius={this.updateRadius}
 *          />
 *
 * @example
 *      // Creating Polygons map
 *      <Map
 *           googleMapURL="https://maps.googleapis.com/maps/api/js?key=[API_KEY]&v=3.exp&libraries=geometry,drawing,places"
 *           loadingElement={ <div style={{ height: `100%` }} /> }
 *           containerElement={ <div style={{ height: `400px` }} /> }
 *           mapElement={ <div style={{ height: `100%` }} /> }
 *           polygonData={ polygonsInfo }
 *           onRef={ref => (this.child) = ref} // <--- To Handle Polygons info
 *          />
 *
 *      // To Create A polygon by function calling
 *       onClickCreatePolygon = () => {
 *          // *** Ref called child must be created ***
 *       };
 *
 *      // To Get the polygon paths
 *       onClickSave = () => {
 *          // *** Ref called child must be created ***
 *          this.child.getPolyPaths();
 *       };
 *
 */
class Map extends Component {
	/**
	 * Coordinates on which an element will be placed inside the map
	 *
	 * @typedef {Object} Coordinates
	 * @property {number} lat - Latitude coordinates value
	 * @property {number} lng - Longitude coordinates value
	 *
	 * @example
	 *      const coords = {lat: 19.365485, lng:-99.84699}
	 *
	 */

	/**
	 * Dict object or Array of objects that contains the information about
	 * the location and radius of the circle to be place
	 * @typedef {Object} CircleData
	 * @property {Coordinates} coordinates - The {@link Coordinates} to
	 *                                       be inserted in the map.
	 * @property {number} radius - Radius of the circle in meters
	 *
	 * @example
	 *      const circleInfo = {
	 *         coordinates: {lat: 19.3941, lng:-99.2846922 },
	 *         radius: 200
	 *       };
	 *
	 * @example
	 *      const circleInfo = [{
	 *         coordinates: {lat: 19.3941, lng:-99.2846922 },
	 *         radius: 200
	 *       }, {
	 *          coordinates: {lat: 19.3941, lng:-99.2846922 },
	 *          radius: 100
	 *       }];
	 */

	/**
	 * Dict object or Array of objects that contains the information about
	 * the location and color of the marker(s) to be placed
	 *
	 * @typedef {Object} MarkerData
	 * @property {Coordinates} coordinates - The {@link Coordinates} to
	 *                                       be inserted in the map
	 * @property {?string} [pin=pink] -  Marker pin color keyword (can
	 *                                   be either pink or gray)
	 * @property {?boolean} [draggable=false] - Indicates whether the
	 *                                          marker is draggable or not
	 * @example
	 *      const markerInfo = {
	 *          coordinates: { lat: 19.3978499, lng:-99.2855734 },
	 *          pin:  'pink',
	 *          draggable: true};
	 *
	 * @example
	 *      const markersInfo = [{
	 *              coordinates: { lat: 19.3978499, lng:-99.2855734 },
	 *              pin:  'pink',
	 *              draggable: true
	 *          }, {
	 *            coordinates: { lat: 19.3967797, lng:-99.284817 },
	 *            pin: 'green',
	 *            draggable: false
	 *          }, {
	 *            coordinates: { lat: 19.3941, lng:-99.2846922 },
	 *            pin: 'pink',
	 *          }];
	 */

	/**
	 * @typedef {Object} Direction
	 * @property {Coordinates} origin - The origin {@link Coordinates}
	 *                                  from which a path will start
	 * @property {Coordinates} destination - The destination as {@link Coordinates}
	 *                                        which will end a direction
	 */

	/**
	 * @typedef {Object} ColorData
	 * @property {Number} fillColor - Hexadecimal number containing a color code
	 *                                for the fill of a map element
	 * @property {Number} strokeColor - Hexadecimal number containing a color code
	 *                                  for the stroke of a map element
	 * @property {Number} fillOpacity - Real number which contains the alpha code
	 *                                  for opacity
	 */

	constructor(props) {
		super(props);
		this.onChangeInitialMap = this.onChangeInitialMap.bind(this);
		var polyNum = Array.isArray(props.polygonData) ? props.polygonData.length : 1;
		var circleNum = Array.isArray(props.circleData) ? props.circleData.length : 1;
		var markerNum = Array.isArray(props.markersData) ? props.markersData.length : 1;
		var zoom = props.zoom ? props.zoom : 15;

		this.state = {
			polyNum: polyNum,
			circleNum: circleNum,
			markerNum: markerNum,
			markers: props.markersData,
			loading: true,
			zoom: zoom,
			timeAgent: null,
			distanceAgent: null,
			coordinates: {
				lat: 38.4857183,
				lng: -1.3748281,
			},
			circleData: null,
			polygonData: null,
			isochronous: null,
			showPolygonInfoBox: null,

			user: this.props.session.user,
		};

		this.mapRef = React.createRef();
	}

	getLocationsFromPoints = (routePoints) => {
		let directionsLocation = {};
		if (routePoints.length > 1) {
			directionsLocation.origin = new window.google.maps.LatLng(
				routePoints[0].coordinates.lat,
				routePoints[0].coordinates.lng
			);
			directionsLocation.destination = new window.google.maps.LatLng(
				routePoints[routePoints.length - 1].coordinates.lat,
				routePoints[routePoints.length - 1].coordinates.lng
			);
		}
		let newDirections = [];
		for (let index1 = 1; index1 < routePoints.length - 1; index1++) {
			const origin = routePoints[index1];
			if (origin) {
				stop = new window.google.maps.LatLng(origin.coordinates.lat, origin.coordinates.lng);
				newDirections.push({
					location: stop,
					stopover: true,
				});
			}
		}
		directionsLocation.waypoints = newDirections;
		return directionsLocation;
	};

	/**
	 *  @description - Gets a Random color
	 *  @returns {ColorData} - Information of the color
	 *                         for the map
	 */
	getColor = () => {
		var color = randomColor({ alpha: 0.55 });
		return {
			fillColor: color,
			strokeColor: color,
			fillOpacity: 0.45,
		};
	};

	/**
	 * @desc - Creates an editable polygon or a set of polygon components
	 *         for the map
	 *
	 * @param {polygonData} polygonPath - The {@link polygonData} whichs holds
	 *                                    the specifications of the polygon to be
	 *                                    created
	 *
	 * @returns {component} - Polygon component to be inserted in the map
	 */
	createPolygon = (polygonData, onChangeEvent, isochronous) => {
		if (Array.isArray(polygonData)) {
			return polygonData.map((vertex, i) => {
				return (
					<Polygon
						paths={vertex.path}
						editable={vertex.editable}
						dragable={false}
						key={vertex.id}
						ref={vertex.id}
						onClick={
							this.props.onClickArea
								? (e) => this.props.onClickArea(vertex, vertex.path[0])
								: () => {}
						}
						defaultOptions={vertex.color}
						onLoad={(poly) => {
							polyLoadHandler(poly, vertex);
						}}
					/>
				);
			});
		} else {
			var iconPin = getPinIcon('pink');

			return (
				<Polygon
					paths={polygonData.path}
					editable={polygonData.editable}
					dragable={false}
					icon={iconPin}
					key={polygonData.id}
					ref={polygonData.id}
					defaultOptions={polygonData.color}
					onClick={
						this.props.onClickArea
							? (e) => this.props.onClickArea(polygonData, polygonData.path[0])
							: () => {}
					}
					onMouseUp={(event) => {
						this.updatePolygon(onChangeEvent, polygonData);
					}}
				/>
			);
		}
	};

	updatePolygon = (onChangeEvent, isochronous) => {
		if (onChangeEvent && isochronous) {
			onChangeEvent(this.getPolyArray(isochronous.id));
		}
	};

	/**
	 * @desc - Creates a Circle or a set of circles components  in the
	 *         map with given center coordinates and radius size
	 *
	 * @param {CircleData} circle - The {@link CircleData} added to the
	 *                              circle component
	 *
	 * @returns {component} - Circle component to be inserted in the map
	 */
	createCircle = (circlesData) => {
		if (Array.isArray(circlesData)) {
			return circlesData.map((circle, i) => {
				return (
					<Circle
						center={circle.coordinates}
						radius={circle.radius}
						key={'circle' + i}
						onClick={
							this.props.onClickArea
								? (e) => this.props.onClickArea(circle, circle.coordinates)
								: () => {}
						}
						defaultOptions={circle.color}
					/>
				);
			});
		} else {
			return (
				<Circle
					center={circlesData.coordinates}
					radius={circlesData.radius}
					key={'circle' + 1}
					onClick={
						this.props.onClickArea
							? (e) => this.props.onClickArea(circlesData, circlesData.coordinates)
							: () => {}
					}
					defaultOptions={circlesData.color}
				/>
			);
		}
	};

	/* Function which updates the map radius */
	updateRadius() {
		this.forceUpdate();
	}

	/**
	 * @desc - Creates the required Marker Components according to
	 *         the data sent, if array of objects received, it process
	 *         them and returns multiple marker componets, or else it
	 *         returns a single component.
	 *
	 * @param {MarkerData} props - Array of objects or single object
	 *                             containing   the marker information
	 *                             ({@link MarkerData})
	 * @param onChange - (Nullable) Function to handle the changing
	 *                   position event from a draggable marker
	 *
	 * @returns {component} - The marker Component to be inserted into
	 *                        the map
	 */
	createMarkers = (props, onChange) => {
		if (Array.isArray(props)) {
			// Multiple Markers sent
			return props.map((marker, i) => {
				return this.createMarker(marker, onChange, i);
			});
		} else {
			// Single Marker
			if (props.coordinates === 'auto') {
				props.coordinates = this.state.coordinates;
				let x = {
					coordinates: this.state.coordinates,
					draggable: props.draggable,
					pin: props.pin,
				};
				return this.createMarker(x, onChange, 1);
			} else {
				return this.createMarker(props, onChange, 1);
			}
		}
	};

	/**
	 * @desc - The function returns a Marker component for the map
	 *          with required specs.
	 *
	 * @param {MarkerData} marker - Array of objects or single object
	 *                              containing the marker information\
	 *                              ({@link MarkerData})
	 * @param onChange - (Nullable) Function to handle the changing
	 *                   position event from a draggable marker
	 *
	 * @example
	 *        markerChanged = (coordinates) => {
	 *               // do sth
	 *         }
	 *
	 * @param key = (Nullable) The key index parameters for array
	 *                creating markers
	 *
	 * @returns {component} - The marker Component to be inserted into
	 *                        the map
	 */

	round(num, decimales = 2) {
		var signo = num >= 0 ? 1 : -1;
		num = num * signo;
		if (decimales === 0)
			//con 0 decimales
			return signo * Math.round(num);
		// round(x * 10 ^ decimales)
		num = num.toString().split('e');
		num = Math.round(+(num[0] + 'e' + (num[1] ? +num[1] + decimales : decimales)));
		// x * 10 ^ (-decimales)
		num = num.toString().split('e');
		return signo * (num[0] + 'e' + (num[1] ? +num[1] - decimales : -decimales));
	}

	getTitle = (marker) => {
		if (marker.showAgent && marker.title) {
			return (
				parseInt(this.state.timeAgent / 3600) +
				' Hrs ' +
				this.round(this.state.timeAgent / 60) +
				' min, ' +
				this.round(this.state.distanceAgent) +
				'Km'
			);
		} else if (marker.description) {
			return marker.description;
		}

		return '';
	};
	createMarker = (marker, onChange, i) => {
		var data;
		if (marker.dataAgent !== undefined && marker.dataAgent !== null) {
			data = marker.dataAgent;
		} else {
			data = marker.id;
		}

		let title = this.getTitle(marker);

		if (RoleUtils.isUserClient(this.state.user)) {
			if (
				marker.pin == 'autoassigning' ||
				marker.pin == 'autoassigning-success' ||
				marker.pin == 'autoassigning-failed' ||
				marker.pin == 'waiting'
			) {
				marker.pin = 'managing';
			}
		}
		var iconPin = getPinIcon(marker.pin);

		return (
			<Marker
				position={marker.coordinates}
				key={'marker' + i}
				icon={iconPin}
				draggable={marker.draggable ? true : false}
				onClick={marker.onMarkerClick !== undefined ? () => marker.onMarkerClick(data) : null}
				title={title}
				onDragEnd={(element) => {
					if (onChange) onChange(element.latLng.toJSON());
					else
						console.error(
							'To do something with the Information you must define markerChanged property on Map Component'
						);
				}}
			/>
		);
	};

	/**
	 * @desc - This function resolves all the polygons in the map
	 *         into array of coordinates
	 *
	 * @returns {array} - Array of coordinates from each polygon
	 *                     found in the map
	 */
	getPolyPaths = () => {
		// To store the array of polygon paths
		var pathsArray = [];

		// Iterate through each polygon
		for (var j = 0; j < this.state.polyNum; j++) {
			// Polygon referenced

			pathsArray.push(this.getPolyArray('poly' + j));
		}

		return pathsArray;
	};

	getPolyArray = (reference) => {
		var poly = this.refs[reference];
		var len = poly.getPath().getLength(); // Length of the polygon (number of sides)
		var arr = []; // Array to store the this polygon path

		// Iterate trhough each polygon coordinate
		for (var i = 0; i < len; i++) {
			arr.push(poly.getPath().getAt(i).toJSON());
		}
		return arr;
	};

	/**
	 * @desc - This functions gets the direction between two points
	 *         received from the google api, and sets it into the map
	 *         as a polyline
	 *
	 * @param {Direction} directions - Object type {@link Direction}
	 *                                 which holds the  information of
	 *                                 the origin and destination to be
	 *                                 resolved into a polyline
	 *
	 * @returns {component} - The polyline which shows the direction
	 */
	getDirections = (directions) => {
		// Get the Correct Format of the direction
		var origin = directions.origin.lat + ',' + directions.origin.lng;
		var destination = directions.destination.lat + ',' + directions.destination.lng;

		// Set The correct URL parameters
		const url = GOOGLE_URL;

		// Call The google api
		var formatedUrl = url + '&origin=' + origin + '&destination=' + destination;
		fetch(formatedUrl, {
			headers: {
				method: 'GET',
				'Cache-Control': 'no-cache',
				'Access-Control-Request-Headers': 'cache-control,method',
				'Access-Control-Request-Method': 'GET',
			},
		})
			.then((response) => {
				response.json().then((data) => {
				});
			})
	};

	getActualLocation = () => {
		if (!this.props.center.force) {
			// Set the Default Coordinates for the map
			if (navigator.geolocation && !this.props.center.generic) {
				navigator.geolocation.getCurrentPosition(
					(pos) => {
						this.setState({
							coordinates: {
								lat: pos.coords.latitude,
								lng: pos.coords.longitude,
							},
						});
					},
					(err) => {
						this.setState({
							coordinates: {
								lat: 20.5532108,
								lng: -59.8989981,
							},
							zoom: this.props.smallMap ? 1 : 3,
						});
					}
				);
			} else {
				this.setState({
					coordinates: {
						lat: 20.5532108,
						lng: -59.8989981,
					},
					zoom: this.props.smallMap ? 1 : 3,
				});
			}
		} else {
			this.setState({ coordinates: this.props.center });
		}

		this.setState({ loading: false });
	};

	async componentDidMount() {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
		await new Promise((res) => res(this.getActualLocation()));
	}

	onChangeInitialMap() {
		this.props.onChangeInitialMap();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.origin === 'routeDetail') {
			if (nextProps.changeMap) {
				this.onChangeInitialMap();
				return true;
			} else {
				return (
					this.props.markersData !== nextProps.markersData ||
					this.props.routePoints !== nextProps.routePoints ||
					this.props.center !== nextProps.center ||
					this.props.zoom !== nextProps.zoom ||
					nextState.polyNum !== this.state.polyNum ||
					nextState.circleNum !== this.state.circleNum ||
					nextState.coordinates !== this.state.coordinates ||
					nextState.distanceAgent !== this.state.distanceAgent ||
					nextState.timeAgent !== this.state.timeAgent ||
					nextState.markers !== this.state.markers ||
					nextState.markerNum !== this.state.markerNum ||
					nextState.zoom !== this.state.zoom ||
					nextState.circleData !== this.state.circleData ||
					nextState.polygonData !== this.state.polygonData ||
					nextState.isochronous !== this.state.isochronous
				);
			}
		} else {
			return true;
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.changedMapPosition && nextProps.center != 'auto') {
			if (
				nextProps.center.lat != prevState.coordinates.lat ||
				nextProps.center.lng != prevState.coordinates.lng
			) {
				return { coordinates: nextProps.center };
			}
			if (nextProps.zoom && nextProps.zoom !== prevState.zoom) {
				return { zoom: nextProps.zoom };
			}
		}

		if (nextProps.infoWindow) {
			return { infoWindow: nextProps.infoWindow.data };
		}

		/*if(nextProps.circleData && nextProps.circleData.length!= prevState.circleData) {
        return {circleData: nextProps.circleData}
      }

      if(nextProps.polygonData && nextProps.polygonData.length!= prevState.polygonData) {
        return {polygonData: nextProps.polygonData}
      }*/

		/*if(nextProps.isochronous && prevState.isochronous && nextProps.isochronous.length !== prevState.isochronous.length) {
        return ({ isocrhonous: nextProps.isochronous })
      }*/

		return null;
	}

	getRoutes = (location) => {
		const directionsService = new window.google.maps.DirectionsService();

		var request = {
			origin: location.origin,
			destination: location.destination,
			waypoints: location.waypoints,
			optimizeWaypoints: true,
			travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
		};

		directionsService.route(request, (response, status) => {
			if (status == window.google.maps.DirectionsStatus.OK) {
				this.setState({
					directions: response,
					distanceAgent: response.routes[0].legs[0].distance.value / 1000,
					timeAgent: response.routes[0].legs[0].duration.value,
				});
			} else {
				console.error(`error fetching directions ${response}`);
			}
		});
	};

	createRoutes = (props, preserveViewport) => {
		var preserveViewValidate = false;
		if (preserveViewport != undefined && preserveViewport != null) {
			preserveViewValidate = preserveViewport;
		}
		const options = {
			suppressMarkers: true,
			preserveViewport: preserveViewValidate,
			polylineOptions: {
				strokeColor: '#ce2b66',
			},
		};

		let locations = this.getLocationsFromPoints(props);

		this.getRoutes(locations);

		return <DirectionsRenderer options={options} directions={this.state.directions} />;
	};

	getForm = (isochronous) => {
		if (isochronous.type === 'circle') {
			return this.createCircle({
				coordinates: isochronous.marker.coordinates,
				radius: isochronous.radius,
				color: isochronous.color,
			});
		} else {
			return this.createPolygon(isochronous.polygon, isochronous.handlePolygonChange, isochronous);
		}
	};

	getIsochronous = (isochronous, onChange) => {
		if (isochronous.marker.coordinates === 'auto') {
			isochronous.marker.coordinates = this.state.coordinates;
		}

		if (isochronous.polygon.path === 'auto') {
			let lat = this.state.coordinates.lat;
			let lng = this.state.coordinates.lng;

			isochronous.polygon.path = [
				{ lat: lat, lng: lng + 0.005 },
				{ lat: lat - 0.005, lng: lng - 0.005 },
				{ lat: lat + 0.005, lng: lng - 0.005 },
			];
		}

		return [this.createMarker(isochronous.marker, onChange), this.getForm(isochronous)];
	};

	getCenterLocation() {
		return this.state.coordinates;
	}

	posChanged = () => {
		if (this.props.changeOnDragEnd) {
			let lat = this.mapRef.current.getCenter().lat();
			let lng = this.mapRef.current.getCenter().lng();

			this.setState({
				coordinates: {
					lat: lat,
					lng: lng,
				},
			});
		}
	};

	render() {
		var { loading } = this.state;
		if (this.props.pinMyLocation) {
			var myLocationData = {
				coordinates: this.state.coordinates,
				draggable: this.props.draggable,
			};
		}
		if (!loading && this.state.coordinates) {
			return (
				<GoogleMap
					ref={this.mapRef}
					defaultZoom={this.state.zoom}
					zoom={this.state.zoom}
					defaultCenter={this.state.coordinates}
					onClick={this.props.onClick}
					onDragEnd={this.posChanged}
					center={this.state.coordinates}
					keyboardShortcuts={false}
				>
					{this.props.routePoints &&
						this.createRoutes(this.props.routePoints, this.props.preserveViewport)}
					{this.props.markersData &&
						this.createMarkers(this.props.markersData, this.props.markerChanged)}
					{this.props.polygonData && this.createPolygon(this.props.polygonData)}
					{this.props.circleData && this.createCircle(this.props.circleData)}
					{this.props.directionsData && this.getDirections(this.props.directionsData)}
					{this.props.pinMyLocation && this.createMarkers(myLocationData, this.props.onChange)}
					{this.props.isochronous &&
						this.getIsochronous(this.props.isochronous, this.props.onChange)}
					{this.state.infoWindow}
				</GoogleMap>
			);
		} else return <p></p>;
	}
}

const MapComponent = withScriptjs(withGoogleMap(Map));

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(MapComponent);
