import { getFielderSession } from 'FielderUtils/session/Session';
import { pipe } from 'lodash/fp';

import API_LINKS from '../apiGateWayLinks';
import { get, requestHandler } from '../request';

// Get export count
export const getExportAgentCount = async (params, onSuccess, onError) => {
	const path = API_LINKS.exportAgents;
	const session = await getFielderSession();
	params.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(params);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};

// Get export task data
export const getExportAgentList = async (body, onSuccess, onError) => {
	const path = API_LINKS.exportAgents;
	const session = await getFielderSession();

	body.idUser = session.user.idUser;

	const queryParams = new URLSearchParams(body);

	const doRequest = pipe(get, requestHandler(onSuccess, onError));
	return doRequest(path, null, queryParams.toString(), {});
};
