import API_LINKS from 'FielderAPILinks';
import { format } from 'libphonenumber-js';

/**
 *
 * @param {string} filter       - Filter to search the groups by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the partners found (returns empty if error)
 */
export const getGroupsByFilter = (filter, idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_GROUPS_BY_FILTER',
		idUser: idUser,
		idAccount: idAccount,
		filter: filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.groupTask;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the groups by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all groups by the filter send and sets the information and format for the asyncSelect component
 */
export const getGroupsByFilterAsyncSelect = async (filter, idAccount, idUser, idToken) => {
	let groups = await getGroupsByFilter(filter, idAccount, idUser, idToken);
	return formatGroups(groups);
};

export const formatGroups = (groups) => {
	let groupList = [];

	groups.forEach((group) => {
		groupList.push({
			label: `[${group.id}] - ${group.idCustomGroup} ${group.name}`,
			value: group,
		});
	});

	return groupList;
};

export const getGroupsInitialOptionsAsyncSelect = async (idAccount, idUser, idToken) => {
	let groups = await getGroupsInitialOptions(idAccount, idUser, idToken);
	return formatGroups(groups);
};

export const getGroupsInitialOptions = (idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_GROUPS_DEFAULT_OPTIONS',
		idUser: idUser,
		idAccount: idAccount,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.groupTask;
			} else {
				console.error(res);
				return [];
			}
		});
};
