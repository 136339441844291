import React from 'react';

import RoleUtils from 'FielderUtils/roles/roleUtils';
import { connect } from 'react-redux';

//Utility
import 'FielderUtils/style.css';

const MANAGING = 0;
const CANCEL = 1;
const FINISH = 2;
const WAITING_FOR_AGENT = 3;
const IN_PROGRESS = 4;
const EXECUTING = 5;
const START_ROUTE = 6;
const CANCEL_BY_PARTNER = 7;
const ASSIGNED_TO_PARTNER = 8;
const ASSIGNED_TO_TEAM = 9;
const ARRIVED = 10;
const DELETED = 11;
const AUTOASSIGNING = 12;
const AUTOASSIGNED_SUCCESS = 13;
const AUTOASSIGNED_FAILED = 14;
const PAUSED = 15;
const REVIEW = 16;

class StatusFormatter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: this.props.user,
			isOwner: this.props.row ? this.props.row.isOwner : false,
			isClient: this.props.row ? this.props.row.isClient : false,
			isExecutor: this.props.row ? this.props.row.isExecutor : false,
			hasPartnerRelation: this.props.row ? this.props.row.hasPartnerRelation : false,
		};
	}

	getManagingInfo = () => {
		return { color: 'Fielder-task-managing', text: this.props.lan.taskStatus_0 };
	};

	getWaitingForAgentInfo = () => {
		return { color: 'Fielder-task-waitting-for-agent', text: this.props.lan.taskStatus_3 };
	};

	getAssignedForParentInfo = () => {
		return { color: 'Fielder-task-assigned_to_partner', text: this.props.lan.taskStatus_8 };
	};

	getCancelInfo = () => {
		return { color: 'Fielder-task-cancel', text: this.props.lan.taskStatus_1 };
	};

	getCancelPartnerInfo = () => {
		return { color: 'Fielder-task-cancel-by-partner', text: this.props.lan.taskStatus_7 };
	};

	getAssignedTeamInfo = () => {
		return { color: 'Fielder-task-assigned-to-team', text: this.props.lan.taskStatus_9 };
	};

	getAutoAssigningInfo = () => {
		return { color: 'Fielder-task-autoassigning', text: this.props.lan.taskStatus_12 };
	};

	getAutoAssignedSuccessInfo = () => {
		return { color: 'Fielder-task-autoassigning-success', text: this.props.lan.taskStatus_13 };
	};

	getAutoAssignedFailedInfo = () => {
		return { color: 'Fielder-task-autoassigning-failed', text: this.props.lan.taskStatus_14 };
	};

	getPausedInfo = () => {
		return { color: 'Fielder-task-paused-failed', text: this.props.lan.taskStatus_15 };
	};

	getRevewInfo = () => {
		return { color: 'Fielder-task-waitting-for-agent', text: this.props.lan.review };
	};

	getWaitingForAgentStatus = () => {
		if (this.state.isClient) {
			return this.getManagingInfo();
		}
		if (!this.state.hasPartnerRelation) {
			return this.getWaitingForAgentInfo();
		}
		if (this.state.isOwner || !this.state.isExecutor) {
			return this.getAssignedForParentInfo();
		}
		return this.getWaitingForAgentInfo();
	};

	getCancelStatus = () => {
		if (this.state.hasPartnerRelation && this.state.isClient) {
			return this.getManagingInfo();
		}
		return this.getCancelInfo();
	};

	getCancelPartnerStatus = () => {
		if (this.state.hasPartnerRelation && this.state.isClient) {
			return this.getManagingInfo();
		}
		return this.getCancelPartnerInfo();
	};

	getAssignedPartnerStatus = () => {
		if (this.state.hasPartnerRelation && this.state.isClient) {
			return this.getManagingInfo();
		}
		return this.getAssignedForParentInfo();
	};

	getAssignedTeamStatus = () => {
		if (
			!this.state.hasPartnerRelation ||
			(this.state.hasPartnerRelation && this.state.isExecutor)
		) {
			return this.getAssignedTeamInfo();
		}
		return this.getManagingInfo();
	};

	getAutoAssignedStatus = () => {
		if (
			!this.state.hasPartnerRelation ||
			(this.state.hasPartnerRelation && this.state.isExecutor)
		) {
			return this.getAutoAssigningInfo();
		}
		return this.getManagingInfo();
	};

	getAutoAssignedSuccessStatus = () => {
		if (
			!this.state.hasPartnerRelation ||
			(this.state.hasPartnerRelation && this.state.isExecutor)
		) {
			return this.getAutoAssignedSuccessInfo();
		}
		return this.getManagingInfo();
	};

	getAutoAssignedFailedStatus = () => {
		if (
			!this.state.hasPartnerRelation ||
			(this.state.hasPartnerRelation && this.state.isExecutor)
		) {
			return this.getAutoAssignedFailedInfo();
		}
		return this.getManagingInfo();
	};

	getStatusColor = (value) => {
		var color = '',
			text = '';

		if (RoleUtils.isUserClient(this.props.user)) {
			if (value === AUTOASSIGNING || value === AUTOASSIGNED_FAILED) {
				value = MANAGING;
			}
		}

		switch (value) {
			case MANAGING:
				color = 'Fielder-task-managing';
				text = this.props.lan['taskStatus_' + MANAGING];
				return { color: color, text: text };
			case WAITING_FOR_AGENT:
				return this.getWaitingForAgentStatus();
			case IN_PROGRESS:
				color = 'Fielder-task-in-progress';
				text = this.props.lan['taskStatus_' + IN_PROGRESS];
				return { color: color, text: text };
			case EXECUTING:
				color = 'Fielder-task-executing';
				text = this.props.lan['taskStatus_' + EXECUTING];
				return { color: color, text: text };
			case FINISH:
				color = 'Fielder-task-finish';
				text = this.props.lan['taskStatus_' + FINISH];
				return { color: color, text: text };
			case CANCEL:
				return this.getCancelStatus();
			case START_ROUTE:
				color = 'Fielder-task-start-route';
				text = this.props.lan['taskStatus_' + START_ROUTE];
				return { color: color, text: text };
			case CANCEL_BY_PARTNER:
				return this.getCancelPartnerStatus();
			case ASSIGNED_TO_PARTNER:
				return this.getAssignedPartnerStatus();
			case ASSIGNED_TO_TEAM:
				return this.getAssignedTeamStatus();
			case ARRIVED:
				color = 'Fielder-task-arrived';
				text = this.props.lan['taskStatus_' + ARRIVED];
				return { color: color, text: text };
			case DELETED:
				color = 'Fielder-task-deleted';
				text = this.props.lan['taskStatus_' + DELETED];
				return { color: color, text: text };
			case AUTOASSIGNING:
				return this.getAutoAssignedStatus();
			case AUTOASSIGNED_SUCCESS:
				return this.getAutoAssignedSuccessStatus();
			case AUTOASSIGNED_FAILED:
				return this.getAutoAssignedFailedStatus();
			case PAUSED:
				return this.getPausedInfo();
			case REVIEW:
				return this.getRevewInfo();
		}
	};

	render() {
		let info = this.getStatusColor(this.props.value);

		if (!info) {
			info = { color: '', text: '' };
		}
		return (
			<div className='row no-padding'>
				<div className='col-sm-1 my-auto'>
					<div className={'SmartTable-circle-texture text-center ' + info.color}> </div>
				</div>
				<div className='my-auto col-auto pl-1'>{info.text}</div>
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	user: state.utils.session.user,
});

export default connect(mapStateToProps)(StatusFormatter);
