import React from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const parse = require('autosuggest-highlight/parse');

const PartText = ({ parts }) =>
	parts.map((part, index) => (
		<span
			key={`part-${index}`}
			style={{
				fontWeight: part.highlight ? 700 : 400,
			}}
		>
			{part.text}
		</span>
	));

const Options = (props, option) => {
	const {
		structured_formatting: { main_text, secondary_text, main_text_matched_substrings: matches },
	} = option;

	if (!matches) return null;

	const parts = parse(
		main_text,
		matches.map((match) => [match.offset, match.offset + match.length])
	);

	return (
		<li {...props}>
			<Grid container alignItems='center'>
				<Grid item>
					<Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
				</Grid>
				<Grid item xs>
					<PartText parts={parts} />
					<Typography variant='body2' color='text.secondary'>
						{secondary_text}
					</Typography>
				</Grid>
			</Grid>
		</li>
	);
};

export default Options;
