import React, { useState, useEffect } from 'react';

import { InputAdornment, InputBase } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';
import { selectUser } from '../../../core/store/modules/session/sessionSelectors';

const SearchInput = styled(InputBase)({
	width: '60%',
	background: '#efefef',
	borderRadius: '8px',
	transition: 'width 0.5s',
	paddingTop: '0.2%',
	paddingBottom: '0.2%',
	'&.Mui-focused': {
		width: '100%',
	},
});

const SearchBar = ({ searchBy, pagType }) => {
	const user = useSelector(selectUser);
	const lan = useLanguage();
	const navigate = useNavigate();
	const location = useLocation();

	const [showSearch, setShowSearch] = useState(true);
	const [searchQuery, setSearchQuery] = useState(location.state && location.state.filter && location.state.filter !== ''
		? location.state.filter : '');
	const searchInfo = {
		searchBy: searchBy !== undefined ? searchBy : 'tasks',
		pagType: pagType !== undefined ? pagType : 'BY_TASKS',
	};

	useEffect(() => {
		const isAbleToSearch = !(
			RoleUtils.isRootGlobalAccountUser(user) ||
			RoleUtils.isClientGlobalAccountUser(user) ||
			RoleUtils.isUserClient(user)
		);

		setShowSearch(isAbleToSearch);
	}, []);

	const onChangeQuery = (e) => {
		setSearchQuery(e.target.value);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {

			const state = {
				filter: searchQuery,
				paginationType: searchInfo.pagType,
				searchBy: searchInfo.searchBy,
				offset: 0,
				limit: 5,
				lastEvaluatedKey: 0,
				queryForward: true,
			};
			navigate(ENDPOINTS.globalSearch, { state });
		}
	};

	return showSearch ? (
		<SearchInput
			placeholder={lan.search}
			id='search-bar'
			onChange={onChangeQuery}
			onKeyPress={handleKeyPress}
			defaultValue={searchQuery}
			startAdornment={
				<InputAdornment position='start' className='ml-2'>
					<img src={`${process.env.AWS_FIELDER_ICONS}search-icon.svg`} alt='' />
				</InputAdornment>
			}
		/>
	) : null;
};

export default SearchBar;
