// ROLE MANAGEMENT
const PAGE_NAME = 'SKILLS';
const NAME = 'skills';
const SKILLS = 'skills';
const ACCOUNT_PREFERENCES = 'showSkills';
const VIEW = 0;
const DELETE = 1;
const CREATE = 2;

export default {
	PAGE_NAME,
	NAME,
	SKILLS,
	ACCOUNT_PREFERENCES,
	VIEW,
	DELETE,
	CREATE,
};
