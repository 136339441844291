import React from 'react'
import { Typography } from '@mui/material'
import { LightGreySubtitleColor } from '@core/theme/baseTheme'
import { styled } from '@mui/material/styles';

const Subtitle = styled(Typography)`
	color: ${LightGreySubtitleColor};
	font-size: 1rem;
	font-family: 'Lato'
`

const SubtitleFormInput = ({children, ...props}) => {
  return (
	<Subtitle {...props}>{children}</Subtitle>
  )
}

export default SubtitleFormInput