import Priority from './TaskPriority.constants';
import Status from './TaskStatus.constants';

export const StatusColor = {
	[Status.MANAGING]: {
		background: '#9BC5F1',
		color: '#0F5099',
	},
	[Status.WAITING_FOR_AGENT]: {
		background: '#D1F7EF',
		color: '#20AD8F',
	},
	[Status.IN_PROGRESS]: {
		background: '#FBC6E9',
		color: '#DC4BAC',
	},
	[Status.EXECUTING]: {
		background: '#CBB6EE',
		color: '#870DB9',
	},
	[Status.FINISH]: {
		background: '#A2EAC9',
		color: '#0BA25F',
	},
	[Status.CANCEL]: {
		background: '#F6ABAB',
		color: '#C21010',
	},
	[Status.START_ROUTE]: {
		background: '#96A3E5',
		color: '#314095',
	},
	[Status.CANCEL_BY_PARTNER]: {
		background: '#F47675',
		color: '#9C0606',
	},
	[Status.ASSIGNED_TO_PARTNER]: {
		background: '#C69B8A',
		color: '#8A400A',
	},
	[Status.ASSIGNED_TO_TEAM]: {
		background: '#E1EC82',
		color: '#94A504',
	},
	[Status.ARRIVED]: {
		background: '#FFF188',
		color: '#E39C1F',
	},
	[Status.DELETED]: {
		background: '#DFDEDE',
		color: '#3B3434',
	},
	[Status.PAUSED]: {
		background: '#FDBB70',
		color: '#D87014',
	},
	[Status.AUTO_ASSIGNING]: {
		background: '#A8EDEF',
		color: '#259F9B',
	},
	[Status.AUTO_ASSIGNED_FAILED]: {
		background: '#F6ABAB',
		color: '#C21010',
	},
	[Status.REVIEW]: {
		background: '#98dbed',
		color: '#2e94b0',
	}
};

export const PriorityColors = {
	[Priority.LOW]: {
		background: '#B7D078',
		text: '#537403',
	},
	[Priority.MEDIUM]: {
		background: '#F9DD7D',
		text: '#e19b31',
	},
	[Priority.HIGH]: {
		background: '#FA706A',
		text: '#b50c05',
	},
};

export const DoneMissingChart = {
	done: {
		background: '#DEDEDE',
	},
	missing: {
		background: '#C6D2ED',
	},
};
