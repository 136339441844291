import { Gainsboro, Glaucous } from "@core/theme/baseTheme";
import { styled } from '@mui/material/styles';
import { DatePicker as DatePickerAntd } from 'antd';

const { RangePicker } = DatePickerAntd;

export const DateRangePicker = styled(RangePicker)`
	border-radius: 12px;
	border-color: ${Gainsboro};
	font-family: 'Lato';

	.ant-picker-input > input {
		color: ${Glaucous};
	}

	&.ant-picker-range:hover .ant-picker-input:not(.ant-picker-input-disabled),
	.ant-picker-range:hover .ant-picker-cell-in-view,
	.ant-picker-range:hover .ant-picker-footer button {
		border-color: red;
	}
}
`

export const DatePicker = styled(DatePickerAntd)`
	border-radius: 12px;
	border-color: ${Gainsboro};
	font-family: 'Lato';

	.ant-picker-input > input {
		color: ${Glaucous};
	}

	&.ant-picker-range:hover .ant-picker-input:not(.ant-picker-input-disabled),
	.ant-picker-range:hover .ant-picker-cell-in-view,
	.ant-picker-range:hover .ant-picker-footer button {
		border-color: red;
	}
}
`