// ROLE MANAGEMENT
const PAGE_NAME = 'CALENDAR';
const CALENDAR = 'calendar';
const NAME = 'calendar';
const VIEW = 0;

export default {
	PAGE_NAME,
	NAME,
	CALENDAR,
	VIEW,
};
