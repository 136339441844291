// ROLE MANAGEMENT
const PAGE_NAME = 'TASK_TYPES';
const NAME = 'tasktypepreferences';
const TASK_TYPE_PREFERENCES = 'tasktypepreferences';
const VIEW = 0;
const EDIT = 1;

export default {
	PAGE_NAME,
	NAME,
	TASK_TYPE_PREFERENCES,
	VIEW,
	EDIT,
};
