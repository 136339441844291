import React from 'react';
import Popup from '../Popup'
import {Col, Row} from 'react-bootstrap';

export default function ReactivateForm(props) {
    return (
        <Popup
            closeButton
            onClose={()=>props.onClose(false)}
            title={props.lan.reactivateForm}
            icon='alert-icon.svg'
            buttonText={props.lan.reactivateForm}
            buttonOnClick={props.reactivateForm}>
                <Col sm={12} className='mt-2 Fielder-pink-color'>
                    {props.lan.reactivateFormWarning}  
                </Col>
        </Popup>
    )
}