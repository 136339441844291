import SeverityConst from 'FielderUtils/severity/severity';

export const getSeverityText = (severity, lan, severityName) => {
	var severityText = '';
	switch (severity) {
		case SeverityConst.HIGH:
			return lan.highPriority;
		case SeverityConst.MEDIUM:
			return lan.medPriority;
		case SeverityConst.LOW:
			return lan.lowPriority;
		default:
			severityText = severityName;
	}
	return severityText;
};

export const getSeverityClassName = (severity) => {
	var severityClassName = '';

	switch (severity) {
		case SeverityConst.HIGH:
			return 'severity-high';
		case SeverityConst.MEDIUM:
			return 'severity-med';
		case SeverityConst.LOW:
			return 'severity-low';
		default:
			severityClassName = '';
	}
	return severityClassName;
};
