export const chatBotLinks = (lan) => {
	return [
		{
			text: 'createnewtask',
			link: 'https://documentation.appfielder.com/tasks/createnewtask',
			name: lan.createTaskDocumentation
		},
		{
			text: 'createnewtaskdetailed',
			link: 'https://documentation.appfielder.com/tasks/createnewtaskdetailed',
			name: lan.taskCreationDetailDocumantation
		},
	]
}
