import React from 'react';

import { OrchidCrayola, CadetBlueCrayola } from '@core/theme/baseTheme';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { Doughnut } from 'react-chartjs-2';

const formatPercentageLabel = (item) => {
	let sum = 0;
	item.dataset.data.map((data) => (sum += Number(data)));
	const result = (item.parsed * 100) / sum;
	return (item.parsed * 100) % sum === 0 ? result + '%' : result.toFixed(2) + '%';
};

const ChartOptions = {
	offset: true,
	responsive: true,
	width: 95,
	height: 95,
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: true,
			titleFont: {
				size: 9, 
			  },
			callbacks: {
				label: formatPercentageLabel,
			},
		},
	},
};

const PercentageCell = ({ row }) => {
	const lan = useLanguage();

	const percentageData = {
		id: 'percentage',
		labels: [lan.tasks, lan.restOfTasksWeight],
		datasets: [
			{
				backgroundColor: [OrchidCrayola, CadetBlueCrayola],
				data: [row.percentageFromTotalTasks, 100 - row.percentageFromTotalTasks],
			},
		],
	};

	return (
		<div style={{ height: '95px', width: '95px' }}>
			<Doughnut data={percentageData} options={ChartOptions} />
		</div>
	);
};

export default PercentageCell;
