/* eslint-disable no-undef */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { convertGoogleLatLng } from '@core/utils/location/addressHandler.utils';
import { fitMapBounds } from '@core/utils/map/mapBounds';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { selectRoute, selectCenter, selectZoom } from '../../core/store/modules/map/mapSelectors';
import { setCenter, setZoom } from '../../core/store/modules/map/mapSlice';
import Circles from './components/Circles';
import InfoWindow from './components/InfoWindow';
import Markers from './components/Markers';
import PinCluster from './components/PinCluster';
import Polygons from './components/Polygons';
import Route from './components/Route';
import { mapConfig } from '@core/utils/map/googleMaps.config';
import { addMapLogs } from '@core/api/account/account.service';
import { useLocation, } from 'react-router-dom';

const containerStyle = {
	// width: '100%',
	height: '100%',
	borderRadius: '20px',
};

const Map = forwardRef((props, ref) => {
	const { id, zoom, center, autoCenter, fitBounds, options, initMap, enableAnimation, ...mapProps } = props;
	// Props to be drawn in the map
	const { markers, marker, agentMarkers, polygons, circles, onCircleClick, onPolygonClick, infoWindow } = mapProps;

	const mapRef = useRef();
	const dispatch = useDispatch();
	const route = useSelector(selectRoute);
	const mapZoom = useSelector(selectZoom);
	const mapCenter = useSelector(selectCenter);
	const location = useLocation();

	const [googleMap, setGoogleMap] = useState(null);
	const [mapOptions, setMapOptions] = useState({
		zoom: mapZoom,
		center: mapCenter,
		streetViewControl: false,
		mapTypeControlOptions: {
			mapTypeIds: [], // hide the map controls
		},
		...options,
	});

	const { isLoaded, loadError } = useJsApiLoader(mapConfig);

	useImperativeHandle(ref, () => ({ fitMap, fitBoundsMap, getCurrentZoom, getCurrentCenter }));

	useEffect(() => {
		// If center change, update center
		if (center) {
			dispatch(setCenter(center));
		}
		// If auto center, search for the browser geolocation and set the center
		else if (autoCenter) {
			if (navigator.geolocation && Object.keys(navigator.geolocation).length !== 0) {
				navigator.geolocation.getCurrentPosition((pos) => {
					const centerCoords = {
						lat: pos.coords.latitude,
						lng: pos.coords.longitude,
					};
					dispatch(setCenter(centerCoords));
				});
			} else {
				dispatch(setZoom(2));
			}
		}
	}, [autoCenter, center, dispatch]);

	useEffect(() => {
		if (zoom && zoom !== mapZoom) {
			dispatch(setZoom(zoom));
		}
	}, [zoom, mapZoom, dispatch]);

	React.useEffect(() => {
		fitMap();
	}, [googleMap, polygons, circles, marker, markers, agentMarkers]);

	useEffect(() => {
		setMapOptions({
			zoom: mapZoom,
			center: mapCenter,
			streetViewControl: false,
			mapTypeControlOptions: {
				mapTypeIds: [], // hide the map controls
			},
			...options,
		});
	}, [mapCenter, mapZoom, options]);

	const MapMarkers = () => {
		let normalMarkers = null;
		let agMarkers = null;

		// Set the normal markers (into a cluster for visualization)
		if (marker || markers) {
			normalMarkers = <PinCluster marker={marker} markers={markers} enableAnimation={enableAnimation} />;
		}

		// Agent Markers outside the cluster
		if (agentMarkers) {
			agMarkers = <Markers markers={agentMarkers} enableAnimation={enableAnimation} />;
		}
		// when the map exists and the markers change, fit to screen (if option enabled)
		if (fitBounds && googleMap != null) {
			fitMapBounds(googleMap, markers, agentMarkers, marker);
		}

		// Return markers if exist
		if (normalMarkers || agMarkers) {
			return (
				<>
					{normalMarkers}
					{agMarkers}
				</>
			);
		}

		return null;
	};

	const Routes = () => {
		if (route) {
			return <Route />;
		}
		return null;
	};

	const onLoad = (map) => {
		// Save the map in redux
		if (map != null) {
			setGoogleMap(map);
			if (initMap) {
				initMap(map);
			}
		}

		const body = {pageName: location.pathname.substring(1)}
		addMapLogs(body)
	};

	const getCurrentZoom = () => {
		if (mapRef.current) {
			return mapRef.current.state.map.zoom;
		}
		return null;
	};
	const getCurrentCenter = () => {
		if (mapRef.current) {
			const { center: mapRefCenter } = mapRef.current.state.map;
			return convertGoogleLatLng(mapRefCenter);
		}
		return null;
	};

	const fitMap = () => fitMapBounds(googleMap, markers, agentMarkers, marker, polygons, circles);
	const fitBoundsMap = (bounds) => {
		if (googleMap) {
			googleMap.fitBounds(bounds);
			dispatch(setCenter(getCurrentCenter()));
			dispatch(setZoom(getCurrentZoom()));
		}
	};

	if (loadError) {
		return <p> Error Loading the Map </p>;
	}

	return isLoaded ? (
		<GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad} ref={mapRef}>
			<MapMarkers />
			<Routes />
			<Polygons polygonData={polygons} onPolygonClick={onPolygonClick} />
			<Circles circleData={circles} onCircleClick={onCircleClick} />
			<InfoWindow infoWindowData={infoWindow} />
		</GoogleMap>
	) : (
		<></>
	);
});

Map.prototype = {
	id: PropTypes.string,
	zoom: PropTypes.number,
	center: PropTypes.object,
	autoCenter: PropTypes.bool,
};

Map.defaultProps = {
	id: null,
};

export default React.memo(Map);
