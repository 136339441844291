import React, { useEffect, useState } from 'react';

import MainTable from 'OldComponents/MainTable';
import { SmallTitle } from '~/SmallTitle/SmallTitle.component';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { MotionDashboardPaper, MotionGrid } from '../Dashboard.styles';
import { Container, Grid, IconButton } from '@mui/material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ExportIcon from '@mui/icons-material/GetApp';
import { buildAgentsJsonToExcelDashboard } from 'FielderUtils/excel/builderJsonExcel';
import { utils as XLSXUtils, writeFileXLSX } from 'xlsx';
import TextFieldWithSelect from '~/TextFieldWithSelect/TextFieldWithSelect.component';
import Loading from '~/Loading/Loading';
import { getWSService } from '../../../services/WebSocket';
import WS_ROUTE_NAME from '../../../services/WebSocketRouteNames';
import pages from 'FielderUtils/pages/pages';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { agentUpdateActions } from '@core/constants/realTime/pages/dashboard';

const updateSessionInfoWebSocket = (idUser, idAccount) => {
	setTimeout(() => {
		getWSService().updateConnectionUserInfo(WS_ROUTE_NAME.UPDATE_CONNECTION, idUser, idAccount, pages.Dashboard);
	}, 3000);
};

const COLUMNS = [
	{ id: 'fullName', name: 'name', padding: 'none' },
	{ id: 'email', padding: 'none' },
	{ id: 'statusComponent', name: 'status', padding: 'none' },
	{ id: 'lastUpdateComponent', name: 'lastDataObtained', padding: 'none' },
];

const NoAgentsAnimation = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
};

const cardVariants = {
	growTable: { height: '80vh', background: 'white' },
	shrinkTable: { height: '25rem' },
};

const AgentTableCard = ({ agents, queryAgents, isLoading, onAgentSelected, agentsSelected }) => {
	const lan = useLanguage();
	const user = useSelector(selectUser);

	const SearchOptions = [
		{ label: lan.all, value: 1 },
		{ label: lan.online, value: 2 },
		{ label: lan.offline, value: 3 },
		{ label: lan.dashboardInactive, value: 4 },
		{ label: lan.noData, value: 5 },
	];

	const [dataToExport, setDataToExport] = useState([]);
	const [searchParams, setSearchParams] = useState({ filter: null, status: 1 });

	const animateCard = () => {
		if (agentsSelected.length > 0) {
			return 'shrinkTable';
		}
		return 'growTable';
	};

	const realTimeActionHandler = (message) => {
		const { action } = message;
		if (agentUpdateActions.has(action)) {
			queryAgents(searchParams.status, searchParams.filter);
		}
	};

	useEffect(() => {
		getWSService(WS_ROUTE_NAME.MY_OPERATION);
		getWSService().addRealTimeListener(realTimeActionHandler);
		updateSessionInfoWebSocket(user.idUser, user.idAccount);
	}, [user]);

	const handleChangeFilter = (filter, status) => {
		queryAgents(status, filter);
		setSearchParams({ status, filter });
	};
	const exportAgents = () => {
		const agentsCSV = buildAgentsJsonToExcelDashboard(agents, lan);
		const agentsCSVWithHeaders = agentsCSV.map((row) => ({
			[lan.id]: row.idAgent,
			[lan.name]: row.name,
			[lan.email]: row.email,
			[lan.status]: row.status,
			[lan.lastDataObtained]: row.lastUpdate,
			[lan.location]: row.geolocation,
		}));
		const ws = XLSXUtils.json_to_sheet(agentsCSVWithHeaders);
		const wb = XLSXUtils.book_new();
		XLSXUtils.book_append_sheet(wb, ws, lan.agents);
		writeFileXLSX(wb, `${lan.agentsDashboard}.xlsx`);
	};

	const Table = React.useMemo(() => {
		if (!agents || agents.length === 0) {
			return (
				<MotionGrid
					key='noLocationMessage'
					initial='hidden'
					exit='hidden'
					animate='visible'
					variants={NoAgentsAnimation}
					justifyContent='center'
					alignItems='center'
					className='my-5 text-center'
				>
					<SmallTitle>{lan.noAgentsFound}</SmallTitle>
				</MotionGrid>
			);
		}

		return (
			<MainTable
				cols={COLUMNS}
				rows={agents}
				updateSelected={onAgentSelected}
				height={agentsSelected.length > 0 ? '18rem' : '65vh'}
			/>
		);
	}, [agents, agentsSelected]);

	return (
		<MotionDashboardPaper variants={cardVariants} animate={animateCard} sx={{ overflow: 'hidden' }}>
			{/* sx={{ overflow: 'scroll' }}> */}
			<Container className='py-2'>
				<Grid container alignContent='center' alignItems='center'>
					<Grid item xs='auto'>
						<SmallTitle>
							<RecentActorsIcon className='mr-2' />
							{lan.summaryOfAgent}
						</SmallTitle>
					</Grid>

					<Grid item xs={4} className='ml-auto mr-2'>
						<TextFieldWithSelect options={SearchOptions} onChange={handleChangeFilter} selectedValue={1} />
					</Grid>

					<Grid item xs='auto'>
						<IconButton onClick={exportAgents}>
							<ExportIcon />
						</IconButton>
					</Grid>

					<Grid item xs={12}>
						{isLoading ? (
							<div className='py-3' style={{ height: '100%' }}>
								<Loading />
							</div>
						) : (
							Table
						)}
					</Grid>
				</Grid>
			</Container>
		</MotionDashboardPaper>
	);
};

export default AgentTableCard;
