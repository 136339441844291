export const REQUEST_TYPES = {
	GET_COMPANIES_TABLE: 'GET_COMPANIES_TABLE',
	DO_ACTION_CHANGE_COMPANY_STATUS: 'DO_ACTION_CHANGE_COMPANY_STATUS',
	//OLD
	GET_COMPANY_BY_ID: 'GET_COMPANY_BY_ID',
	GET_ALL_COMPANIES_BY_FILTER: 'GET_ALL_COMPANIES_BY_FILTER',
	GET_ALL_COMPANIES_BY_FILTER_SELECT: 'GET_ALL_COMPANIES_BY_FILTER_SELECT',
	GET_COMPANIES_BY_FILTER: 'GET_COMPANIES_BY_FILTER',
	GET_COMPANIES_DEFAULT_OPTIONS: 'GET_COMPANIES_DEFAULT_OPTIONS',
	GET_BRANCHES_BY_COMPANIES: 'GET_BRANCHES_BY_COMPANIES',
	GET_COMPANIES_VIEW: 'GET_COMPANIES_VIEW',
	GET_COMPANIES_VIEW_OPTIMIZE: 'GET_COMPANIES_VIEW_OPTIMIZE',
	GET_COMPANIES_SELECT: 'GET_COMPANIES_SELECT',
	GET_DISABLED_COMPANIES_VIEW: 'GET_DISABLED_COMPANIES_VIEW',
	GET_DISABLED_COMPANIES_VIEW_OPTIMIZE: 'GET_DISABLED_COMPANIES_VIEW_OPTIMIZE',
	DO_ACTION_CREATE_COMPANY: 'DO_ACTION_CREATE_COMPANY',
	DO_ACTION_UPDATE_COMPANY: 'DO_ACTION_UPDATE_COMPANY',
	DO_ACTION_DISABLE_COMPANY: 'DO_ACTION_DISABLE_COMPANY',
	DO_ACTION_ENABLE_COMPANY: 'DO_ACTION_ENABLE_COMPANY',
	DO_ACTION_DELETE_COMPANY: 'DO_ACTION_DELETE_COMPANY',
};
