import './wdyr';

import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import './index.css';
import { store, persistor } from './core/store/store';
import FontStyles from './core/theme/globals';

import { createTheme } from '@mui/material/styles';

const ProviderComponent = () => (
	<Provider store={store}>
		<PersistGate persistor={persistor} loading={null}>
			<App className={FontStyles} />
		</PersistGate>
	</Provider>
);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ProviderComponent />
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
