import API_LINKS from 'FielderAPILinks';
import { forEach } from 'lodash';

/**
 *
 * @param {string} filter       - Filter to search the teams by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the teams found (returns empty if error)
 */
export const getTeamsByFilter = (filter, idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_TEAMS_BY_FILTER',
		idUser: idUser,
		idAccount: idAccount,
		filter: filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.teams;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the team by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all teams by the filter send and sets the information and format for the asyncSelect component
 */
export const getTeamsByFilterAsyncSelect = async (filter, idAccount, idUser, idToken, lan) => {
	let teams = await getTeamsByFilter(filter, idAccount, idUser, idToken);
	return formatTeam(teams,lan);
};

const formatTeam = (teams, lan) => {
	let teamsList = [];
	let extraInfo = '';
	let value = 0;

	teams.forEach((team) => {
		let managerName = '';
		if (team.users) {
			const users = team.users;
			for (let i = 0; i < users.length; i++) {
				if (i == 0) {
					managerName = `(${users[i].name} ${users[i].lastName})`;
				} else {
					value++;
					extraInfo = value !=0 ? ` [ ${value} ${lan.plus} ]`:'';
				}	
			}
		}
		teamsList.push({
			label: `[${team.id}] - ${team.name} ${managerName} ${extraInfo}`,
			value: team,
		});
	});

	return teamsList;
};

/**********************  DEFATULT OPTIONS  ****************/
export const getTeamsInitialOptionsAsyncSelect = async (idAccount, idUser, idToken, lan) => {
	let teams = await getTeamsInitialOptions(idAccount, idUser, idToken);
	let teamsList = [];
	let extraInfo = '';
	let value=0;

	teams.forEach((team) => {
		let managerName = '';
		if (team.users) {
			const users = team.users;
			for (let i = 0; i < users.length; i++) {
				if (i == 0) {
					managerName = `(${users[i].name} ${users[i].lastName})`;
				} else {
					value++;
					extraInfo = value !=0 ? ` [ ${value} ${lan.plus} ]`:'';
				}	
			}
		}
		teamsList.push({
			label: `[${team.id}] - ${team.name} ${managerName} ${extraInfo}`,
			value: team,
		});
	});

	return teamsList;
};

export const getTeamsInitialOptions = (idAccount, idUser, idToken) => {
	let body = {
		type: 'GET_TEAMS_DEFAULT_OPTIONS',
		idUser: idUser,
		idAccount: idAccount,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.teams;
			} else {
				console.error(res);
				return [];
			}
		});
};
