import adminConst from 'FielderUtils/roleManagementConst/adminConst';
import agentConst from 'FielderUtils/roleManagementConst/agentConst';
import branchConst from 'FielderUtils/roleManagementConst/branchConst';
import clientConst from 'FielderUtils/roleManagementConst/clientConst';
import companyConst from 'FielderUtils/roleManagementConst/companyConst';
import managerConst from 'FielderUtils/roleManagementConst/managerConst';
import partnerConst from 'FielderUtils/roleManagementConst/partnerConst';
import teamConst from 'FielderUtils/roleManagementConst/teamConst';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

export const getNotManagerNorClientItems = (lan, goToPage, validPages) => {
	const items = [];

	if (validPages && validPages[adminConst.PAGE_NAME] && validPages[adminConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.administrators),
			title: lan.administrators,
			img: `${process.env.AWS_FIELDER_ICONS}new-admin-icon.svg`,
		});
	}

	if (validPages && validPages[managerConst.PAGE_NAME] && validPages[managerConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.managers),
			title: lan.managers,
			img: `${process.env.AWS_FIELDER_ICONS}manager-icon.svg`,
		});
	}
	return items;
};

export const getSuperAdminAdminOrViewerItems = (lan, goToPage, validPages) => {
	const items = [];

	if (validPages && validPages[teamConst.PAGE_NAME] && validPages[teamConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.teams),
			title: lan.teams,
			img: `${process.env.AWS_FIELDER_ICONS}teams-icon.svg`,
		});
	}

	

	if (validPages && validPages[partnerConst.PAGE_NAME] && validPages[partnerConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.partners),
			title: lan.partners,
			img: `${process.env.AWS_FIELDER_ICONS}partner-icon.svg`,
		});
	}
	return items;
};

export const getNotClientItems = (lan, goToPage, validPages) => {
	const items = [];
	
	if (validPages && validPages[agentConst.PAGE_NAME] && validPages[agentConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.agents),
			title: lan.agents,
			img: `${process.env.AWS_FIELDER_ICONS}agents-icon.svg`,
		});
	}

	if (validPages && validPages[clientConst.PAGE_NAME] && validPages[clientConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.clients),
			title: lan.clients,
			img: `${process.env.AWS_FIELDER_ICONS}new-client-icon.svg`,
		});
	}

	if (validPages && validPages[companyConst.PAGE_NAME] && validPages[companyConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.companies),
			title: lan.companiesLowerCase,
			img: `${process.env.AWS_FIELDER_ICONS}company-icon.svg`,
		});
	}

	if (validPages && validPages[branchConst.PAGE_NAME] && validPages[branchConst.PAGE_NAME].isAllowed) {
		items.push({
			isValid: true,
			goToPage: goToPage(ENDPOINTS.branches),
			title: lan.branches,
			img: `${process.env.AWS_FIELDER_ICONS}branch-icon.svg`,
		});
	}

	return items;
};
