const MULTIPLE_CHOICE = 1;
const TEXT_FIELD = 2;
const NUMERIC_RATING = 3;
const BINARY_RATING = 4;
const STARS_RATING = 5;
const ELECTRONIC_SIGNATURE = 6;
const FILES = 7;
const CODEBAR_IMG = 8;
const CODEBAR_TEXT = 9;
const DATE_HOUR = 10;
const TEXT_FILE = 11;
const CATALOGUE = 12;

const CHECKBOX = 0;
const RADIOBUTTON = 1;

export default {
	MULTIPLE_CHOICE,
	TEXT_FIELD,
	NUMERIC_RATING,
	BINARY_RATING,
	STARS_RATING,
	ELECTRONIC_SIGNATURE,
	FILES,
	CODEBAR_IMG,
	CODEBAR_TEXT,
	DATE_HOUR,
	TEXT_FILE,
	CATALOGUE,
	CHECKBOX,
	RADIOBUTTON,
};
