// ROLE MANAGEMENT
const PAGE_NAME = 'INVENTORY';
const INVENTORY = 'inventory';
const NAME = 'inventory';
const ACCOUNT_PREFERENCES = 'showInventory';
const VIEW = 0;
const EDIT_CATEGORY = 1;
const EDIT_INVENTORY = 2;
const IMPORT_AND_EXPORT = 3;

const INVENTORY_CONSTANTS = {
	PAGE_NAME,
	NAME,
	INVENTORY,
	ACCOUNT_PREFERENCES,
	VIEW,
	EDIT_CATEGORY,
	EDIT_INVENTORY,
	IMPORT_AND_EXPORT,
};

export default INVENTORY_CONSTANTS;
