import React from 'react';

import DashboardIcon from '@assets/icons/dashboard-icon.svg';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';

const DashboardSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const title = lan.desktop;

	return (
		<Tooltip title={title} placement='right'>
			<ListItemButton onClick={() => goToPage(ENDPOINTS.internalAdminDashboard)}>
				<ListItemIcon>
					<img className='' src={DashboardIcon} alt='dashboard' />
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItemButton>
		</Tooltip>
	);
};

export default DashboardSideMenu;
