import { REQUEST_TYPES } from '@core/constants/dashboard/request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'

import API_LINKS from '../apiGateWayLinks';

export const getDailyCompaniesTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_DAILY_COMPANY_TASK,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};
	return handlePostRequest(API_LINKS.dashboardManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getDailyBranchesTasksRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_DAILY_BRANCH_TASK,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};
	return handlePostRequest(API_LINKS.dashboardManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getDailyCompanySLATasks = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_DAILY_COMPANY_TASK_SLA,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};
	return handlePostRequest(API_LINKS.dashboardManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentsDashboardRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_AGENTS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};
	return handlePostRequest(API_LINKS.dashboardManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getMonthlyTasksDashboardRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_MONTH_TASKS,
		idAccount: user.idAccount,
		idUser: user.idUser,
	};
	return handlePostRequest(API_LINKS.dashboardManager, requestBody, onSuccess, onError, loadingConfig);
};

