import Status from '../../constants/task/TaskStatus.constants';
import { getTaskPinCode } from './pinIcon';

const taskMarkersStructure = {
	[Status.MANAGING]: {
		config: { draggable: false, icon: getTaskPinCode(Status.MANAGING) },
		markers: [],
	},
	[Status.CANCEL]: {
		config: { draggable: false, icon: getTaskPinCode(Status.CANCEL) },
		markers: [],
	},
	[Status.FINISH]: {
		config: { draggable: false, icon: getTaskPinCode(Status.FINISH) },
		markers: [],
	},
	[Status.WAITING_FOR_AGENT]: {
		config: { draggable: false, icon: getTaskPinCode(Status.WAITING_FOR_AGENT) },
		markers: [],
	},
	[Status.IN_PROGRESS]: {
		config: { draggable: false, icon: getTaskPinCode(Status.IN_PROGRESS) },
		markers: [],
	},
	[Status.EXECUTING]: {
		config: { draggable: false, icon: getTaskPinCode(Status.EXECUTING) },
		markers: [],
	},
	[Status.START_ROUTE]: {
		config: { draggable: false, icon: getTaskPinCode(Status.START_ROUTE) },
		markers: [],
	},
	[Status.CANCEL_BY_PARTNER]: {
		config: { draggable: false, icon: getTaskPinCode(Status.CANCEL_BY_PARTNER) },
		markers: [],
	},
	[Status.ASSIGNED_TO_PARTNER]: {
		config: { draggable: false, icon: getTaskPinCode(Status.ASSIGNED_TO_PARTNER) },
		markers: [],
	},
	[Status.ASSIGNED_TO_TEAM]: {
		config: { draggable: false, icon: getTaskPinCode(Status.ASSIGNED_TO_TEAM) },
		markers: [],
	},
	[Status.ARRIVED]: {
		config: { draggable: false, icon: getTaskPinCode(Status.ARRIVED) },
		markers: [],
	},
	[Status.DELETED]: {
		config: { draggable: false, icon: getTaskPinCode(Status.DELETED) },
		markers: [],
	},
	[Status.AUTO_ASSIGNING]: {
		config: { draggable: false, icon: getTaskPinCode(Status.AUTO_ASSIGNING) },
		markers: [],
	},
	[Status.AUTO_ASSIGNED]: {
		config: { draggable: false, icon: getTaskPinCode(Status.AUTO_ASSIGNED) },
		markers: [],
	},
	[Status.AUTO_ASSIGNED_FAILED]: {
		config: { draggable: false, icon: getTaskPinCode(Status.AUTO_ASSIGNED_FAILED) },
		markers: [],
	},
	[Status.PAUSED]: {
		config: { draggable: false, icon: getTaskPinCode(Status.PAUSED) },
		markers: [],
	},
	[Status.REVIEW]: {
		config: { draggable: false, icon: getTaskPinCode(Status.REVIEW) },
		markers: [],
	},
};

export const getTaskStatusStructure = () => {
	return JSON.parse(JSON.stringify(taskMarkersStructure));
};
