export const getDefaultPriorities = (lan) => {
	return [
		{
			id: 1,
			name: lan.severity_1,
		},
		{
			id: 2,
			name: lan.severity_2,
		},
		{
			id: 3,
			name: lan.severity_3,
		},

	]
};

export const getDefaultPrioritiesLabel = (lan) => {
	return [
		{
			value: 1,
			label: lan.severity_1,
		},
		{
			value: 2,
			label: lan.severity_2,
		},
		{
			value: 3,
			label: lan.severity_3,
		},

	]
}