import React from 'react';
import { selectAccount } from '@core/store/modules/session/sessionSelectors';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import currenciesConstants from '@core/constants/currency/currencies.constants';

const TotalIncomeCell = ({ row }) => {
	const { price } = row;
	const { currency } = useSelector(selectAccount);
	const currencySymbol = currenciesConstants[currency].symbolNative;

	return (
		<Typography sx={{ fontWeight: 'bold' }}>
			{currencySymbol}
			{price.toLocaleString('en')}
		</Typography>
	);
};

export default TotalIncomeCell;
