import POPUPNAME from 'FielderUtils/popupName/popupName';
import TASK_STATUS from '@core/constants/task/TaskStatus.constants';

export const popupValidateName = (popupName) => {
	if (
		popupName === POPUPNAME.RESCHEDULE_TASK ||
		popupName === POPUPNAME.TASK_DETAIL ||
		popupName === POPUPNAME.CANCEL_TASK ||
		popupName === POPUPNAME.CREATE_GROUP_TASK ||
		popupName === POPUPNAME.EDIT_GROUP_TASK ||
		popupName === POPUPNAME.ASSIGN_GROUP_TASK ||
		popupName === POPUPNAME.ROUTE_DETAIL ||
		popupName === POPUPNAME.ASSIGN_AGENT ||
		popupName === POPUPNAME.ASSIGN_TEAM ||
		popupName === POPUPNAME.ASSIGN_PARTNER ||
		popupName === POPUPNAME.RE_ASSIGN_PARTNER ||
		popupName === POPUPNAME.ASSIGN_ASSETS ||
		popupName === POPUPNAME.ASSIGN_INVENTORIES ||
		popupName === POPUPNAME.ASSIGN_FORMS ||
		popupName === POPUPNAME.RATE_TASK ||
		popupName === POPUPNAME.GENERATE_ROUTE ||
		popupName === POPUPNAME.DUPLICATE_TASK ||
		popupName === POPUPNAME.PARENT_TASK_DETAIL
	) {
		return true;
	}

	return false;
};

export const getSmartTable = (popupName, options, smartTable, selectedRows, SELECTION_TASK) => {
	if (popupName === POPUPNAME.ROUTE_DETAIL) {
		return smartTable.current.getState();
	} else {
		return { selectedRows: selectedRows };
	}
};

export const validateNoRowSelected = (stateTable, popupName) => {
	if (
		stateTable.selectedRows.length === 0 &&
		popupName !== POPUPNAME.TASK_DETAIL &&
		popupName !== POPUPNAME.CREATE_GROUP_TASK
	) {
		return true;
	}
	return false;
};

export const validateTasksInSameTeams = (tasks) => {
	const team = tasks[0].taskIcons.team;

	for (let i = 0; i < tasks.length; i++) {
		if (tasks[i].taskIcons.team !== team) {
			return false;
		}
	}

	return true;
};

export const validateStartTimeForRoutes = (rows) => {
	for (let i = 0; i < rows.length; i++) {
		if (rows[i].startTime == '') {
			return false;
		}
	}
	return true;
};
