import ky from 'ky';

export const getAddressFromCoordinates = async (coordinates) => {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${process.env.GOOGLE_API_KEY}`;

	const response = await ky.get(url).json();
	if (response.status === 'OK') {
		const { results } = response;
		const address = {};
		address.formatted_address = results[0].formatted_address;

		for (const addressComponent of results[0].address_components) {
			if (addressComponent.types[0] === 'administrative_area_level_1') {
				address.state = addressComponent.long_name;
			}

			if (addressComponent.types[0] === 'country') {
				address.country = addressComponent.long_name;
			}

			if (addressComponent.types[0] === 'postal_code') {
				address.postalCode = addressComponent.long_name;
			}
		}

		return address;
	}

	return null;
};

export const convertGoogleLatLng = (latLng) => ({ lat: latLng.lat(), lng: latLng.lng() });
