import React, { Component } from 'react';

import LogoLogin from '@assets/icons/logo-login-main.svg';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Row } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import withRouter from '~/withRouter/withRouter.component'

import { recoverPasswordRequest} from '@core/api/password/password.service';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';

import 'FielderUtils/style.css';
import './RecoverPassword.css';
class RecoverPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
		};
	}

	componentDidMount = () => {
		scroll.scrollToTop();
	};

	setFieldValue(value) {
		this.setState((prevState) => ({
			email: value,
		}));
	}

	handleRecoverPassword = (e) => {
		e.preventDefault();
		let body = {
			email: this.state.email,
		};
	

		const onSuccess = (response) => {
			let state = this.state;
			this.setState(state);
			this.props.fielderMsg({message: this.props.lan.recoverPasswordSuccessText, variant: 'success', closeButton: true, duration: 5000});
			this.props.navigate("/");
		}

		const onError = ({ status }) => {			
			if (status && status == 540) {
				let state = this.state;
				this.setState(state);
				this.props.fielderMsg({message: this.props.lan.recoverPasswordUserNotFound, variant: 'error', closeButton: true, duration: 5000});
			} else {
				let state = this.state;
				this.setState(state);
				this.props.fielderMsg({message: this.props.lan.errorGralFail, variant: 'error', closeButton: true, duration: 5000});
			}
		}; 


		const loadingConfig = { dispatch: this.props.dispatch }
		recoverPasswordRequest(body, onSuccess, onError, loadingConfig)

	};

	/**
	 * @description: Makes the RecoverPassword
	 * @autor: jortega
	 */
	render() {
		return (
			<React.Fragment>
				<div className='container mt-4'>
					<Row className='justify-content-center'>
						<div className='RecoverPassword mt-5'>
							<Row className='justify-content-center mt-3'>
								<img className='Login-img' src={LogoLogin} alt='' />
							</Row>
							<Form>
								<Row className='justify-content-center'>
									<Form.Label className='RecoverPassword-label text-center'>
										{this.props.lan.recoverPassword}
									</Form.Label>
								</Row>
								<FormGroup className='RecoverPassword-input'>
									<Form.Control
										type='email'
										name='email'
										id='correo'
										placeholder={this.props.lan.email}
										onChange={(e) => this.setFieldValue(e.target.value)}
									/>
								</FormGroup>
								<Row className='justify-content-center'>
									<Button
										className='Fielder-button RecoverPassword-button'
										onClick={this.handleRecoverPassword}
									>
										{this.props.lan.recoverPasswordBtn}
									</Button>
								</Row>
								<Row className='justify-content-center mt-4'>
									<p className='Login-color-text-privacy Fielder-mainpage-addtext-link-color'>
										&nbsp;
										{`© ${process.env.FIELDER_APP_YEAR} v${process.env.FIELDER_APP_VERSION}`}
										<a
											className='Register-ref Fielder-mainpage-addtext-link-color ml-1'
											href='https://www.appfielder.com/policy'
										>
											Privacy Policy
										</a>
										.
									</p>
								</Row>
							</Form>
						</div>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

export default connect()(withRouter(withSnackbar(RecoverPassword)));

export function withSnackbar(Component) {
    return function WrappedComponent(props) {
        const fielderMsg = useFielderSnackBar();
        return <Component {...props} fielderMsg={fielderMsg} />;
    }
}