import { CadetBlueCrayola, FielderPink, LightGreySubtitleColor } from '@core/theme/baseTheme';
import { Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled(Typography)({
	fontFamily: 'Lato',
	color: LightGreySubtitleColor,
});

export const FielderCheckbox = styled(Checkbox)(() => ({
	'&.MuiCheckbox-root': {
		color: CadetBlueCrayola
	},
	'&.Mui-checked': {
		color: FielderPink,
	},
	'&.MuiCheckbox-indeterminate': {
		color: CadetBlueCrayola
	}
}));
