import React from 'react';
import { Col, Navbar, Nav  } from 'react-bootstrap';
import { useLanguage } from 'Context/LanguageContext';
import NotificationBadge, { Effect } from 'react-notification-badge';
import { validatePermissions } from 'FielderUtils/validatePermissions';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import RoleUtils from 'FielderUtils/roles/roleUtils';

const SELECTION_TASK = 1;
const SELECTION_ROUTE = 2;
const SELECTION_TASKGROUP = 3;
const SELECTION_AUTOASSIGN = 4;

const MyOperationNavigationBar = ({
	canViewTasks, options, showSearchByForms,
	validateTabView, notificationCount, notificationCountGroup,
	showRouteOpt, canViewRoutes, changeLoadingFilter,
	resetTaskSection, resetRouteSection, resetGroupTaskSection,
	resetAutoAssignSection, state, groupTaskFunctionality, canViewTaskGroups,
	autoassign, selectedRows
}) => {
	
	const lan = useLanguage();
	const user = useSelector(selectUser);

	return (	
		<Col className='mt-2'>
			<Navbar className='myoperation-navbar mb-3' expand='sm' style={{ width: '100%' }}>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse>
					<Nav style={{ width: '100%' }}>
						{canViewTasks && (
							<Nav.Item
								className={
									options == SELECTION_TASK
										? 'myoperation-nav-item text-center myoperation-nav-active '
										: 'myoperation-nav-item text-center'
								}
							>
								<Nav.Link
									className='myoperation-title'
									onClick={() => {
										validateTabView(SELECTION_TASK);
										if (showSearchByForms) { 
											changeLoadingFilter(true)
										}
										resetTaskSection();
									}}
								>
									<div className='row d-flex justify-content-center'>
										<div className='col-auto no-padding'> {lan.tasks} </div>
										<div
											className='col-auto pl-1'
											title={lan.newTasks}
											style={{ height: '24px' }}
										>
											<NotificationBadge
												count={notificationCount}
												effect={Effect.SCALE}
												className='Fielder-icon-button'
												style={{
													backgroundColor: '#dc5c87',
													top: '-7px',
													right: '-16px',
												}}
											/>
										</div>
									</div>
								</Nav.Link>
							</Nav.Item>
						)}

						{showRouteOpt &&
						validatePermissions(user, state, 'route') &&
						canViewRoutes ? (
							<Nav.Item
								className={
									options == SELECTION_ROUTE
										? 'myoperation-nav-item text-center myoperation-nav-active'
										: 'myoperation-nav-item text-center'
								}
							>
								<Nav.Link
									className='myoperation-title'
									onClick={() => {
										validateTabView(SELECTION_ROUTE);
										resetRouteSection();
									}}
								>
									{lan.routes}
								</Nav.Link>
							</Nav.Item>
						) : (
							<div></div>
						)}
						{!RoleUtils.isUserClient(user) &&
							groupTaskFunctionality &&
							canViewTaskGroups && (
								<Nav.Item
									className={
										options == SELECTION_TASKGROUP
											? 'myoperation-nav-item text-center myoperation-nav-active'
											: 'myoperation-nav-item text-center'
									}
								>
									<Nav.Link
										className='myoperation-title'
										onClick={() => {
											selectedRows = [];
											validateTabView(SELECTION_TASKGROUP);
											resetGroupTaskSection();
										}}
									>
										<div className='row d-flex justify-content-center'>
											<div className='col-auto no-padding'> {lan.taskGroupUpperCase} </div>
											<div
												className='col-auto pl-1'
												title={lan.newTaskGroups}
												style={{ height: '24px' }}
											>
												<NotificationBadge
													count={notificationCountGroup}
													effect={Effect.SCALE}
													className='Fielder-icon-button'
													style={{
														backgroundColor: '#dc5c87',
														top: '-7px',
														right: '-16px',
													}}
												/>
											</div>
										</div>
									</Nav.Link>
								</Nav.Item>
							)}
						{autoassign.hasFunctionality && !RoleUtils.isUserClient(user) && (
							<Nav.Item
								className={
									options == SELECTION_AUTOASSIGN
										? 'myoperation-nav-item text-center myoperation-nav-active'
										: 'myoperation-nav-item text-center'
								}
							>
								<Nav.Link
									className='myoperation-title'
									onClick={() => {
										validateTabView(SELECTION_AUTOASSIGN);
										resetAutoAssignSection();
									}}
								>
									{lan.autoassignation}
								</Nav.Link>
							</Nav.Item>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</Col>			
	);

}
export default React.memo(MyOperationNavigationBar);