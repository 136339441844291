import Status from './taskStatus';

export const getTaskStatus = (status, lan) => {
	var statusString = '';
	switch (status) {
		case Status.MANAGING:
		case '0':
			statusString = lan.taskStatus_0;
			break;
		case Status.CANCEL:
		case '1':
			statusString = lan.taskStatus_1;
			break;
		case Status.FINISH:
		case '2':
			statusString = lan.taskStatus_2;
			break;
		case Status.WAITING_FOR_AGENT:
		case '3':
			statusString = lan.taskStatus_3;
			break;
		case Status.IN_PROGRESS:
		case '4':
			statusString = lan.taskStatus_4;
			break;
		case Status.EXECUTING:
		case '5':
			statusString = lan.taskStatus_5;
			break;
		case Status.START_ROUTE:
		case '6':
			statusString = lan.taskStatus_6;
			break;
		case Status.CANCEL_BY_PARTNER:
		case '7':
			statusString = lan.taskStatus_7;
			break;
		case Status.ASSIGNED_TO_PARTNER:
		case '8':
			statusString = lan.taskStatus_8;
			break;
		case Status.ASSIGNED_TO_TEAM:
		case '9':
			statusString = lan.taskStatus_9;
			break;
		case Status.ARRIVED:
		case '10':
			statusString = lan.taskStatus_10;
			break;
		case Status.DELETED:
		case '11':
			statusString = lan.taskStatus_11;
			break;
		case Status.AUTOASSIGNING:
		case '12':
			statusString = lan.taskStatus_12;
			break;
		case Status.AUTOASSIGNED:
		case '13':
			statusString = lan.taskStatus_13;
			break;
		case Status.AUTOASSIGNED_FAILED:
		case '14':
			statusString = lan.taskStatus_0;
			break;
		case Status.PAUSED:
		case '15':
			statusString = lan.taskStatus_15;
			break;
		default:
			statusString = '';
			break;
	}

	return statusString;
};
