import { css } from '@emotion/react';

const FontStyles = css`
	:global() {
		body {
			font-family: 'Lato', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
			background-color: #f8f9fd !important;
		}

		* {
			font-family: 'Lato';
			box-sizing: border-box;
		}
	}
`;

export default FontStyles;
