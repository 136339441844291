import React from 'react';

import { Tooltip } from '@mui/material';
import ProgressBar from '~/ProgressBar/ProgressBar.component';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const ProgressCell = ({ row }) => {
	const lan = useLanguage();

	const { progress, taskCount, totalFinished } = row;
	const tooltipTitle = `${totalFinished} ${lan.of} ${taskCount} ${lan.tasks}`;

	return (
		<Tooltip title={tooltipTitle}>
			<div>
				<ProgressBar value={progress} includePercentage />
			</div>
		</Tooltip>
	);
};

export default ProgressCell;
