import React, { forwardRef } from 'react';

import { Button, ClickAwayListener, Grow, ListItemText, MenuItem, MenuList, Paper, Popper, Stack} from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Check from '@mui/icons-material/Check';
import { useLanguage } from 'Context/LanguageContext';

const SlaMenuDashboard = (props) => {
	const lan = useLanguage();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleCloseMenu = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleSlaChange = (value) => {
		props.handleTaskSLA(value);

		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	};

	return (
		<>
			<div className='row'>
				<div className='col-auto'>
					<Stack direction='row' spacing={2}>
						<div>
							<Button
								ref={anchorRef}
								className='color-attach-button'
								id='composition-button'
								aria-controls={open ? 'composition-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-haspopup='true'
								onClick={handleToggle}
							>
								<AccessAlarmIcon />
								SLA
							</Button>
							<Popper
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement='bottom-start'
								transition
								disablePortal
								style={{
									zIndex: '10',
								}}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleCloseMenu}>
												<MenuList
													autoFocusItem={open}
													id='composition-menu'
													aria-labelledby='composition-button'
													onKeyDown={handleListKeyDown}
												>
													<MenuItem onClick={() => handleSlaChange(1)}>
														<ListItemText>
															{' '}
															{props.slaTime == 1 && <Check />} {`1 ${lan.hour}`}
														</ListItemText>
													</MenuItem>
													<MenuItem onClick={() => handleSlaChange(2)}>
														<ListItemText>
															{' '}
															{props.slaTime == 2 && <Check />} {`3 ${lan.hours}`}
														</ListItemText>
													</MenuItem>
													<MenuItem onClick={() => handleSlaChange(3)}>
														<ListItemText>
															{' '}
															{props.slaTime == 3 && <Check />} {`5 ${lan.hours}`}
														</ListItemText>
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default SlaMenuDashboard;
