import React, {Component} from 'react';
import { Row, Col,} from 'react-bootstrap';

// Custom components
import Popup from '../../Popup';
import '../MyOperation.css';

import redAlertIcon from "@assets/icons/redAlert-icon.svg";
import alertIcon from '@assets/icons/danger-icon.svg'
/***** SERVERLESS IMPORT  *****/
import { resetCancelledTasksRequest } from '@core/api/route/route.service';
import { connect } from 'react-redux';

class ResetCanceledTasksPopup extends Component {

    constructor (props) {
        super(props);

        var idRoute;

        if(props.route!==undefined) {
            idRoute= (this.props.idRoute)
        }

        this.state = {
            idRoute:idRoute,
            showErrorMessage:false,
            showErrorMessageText:"",
            showErrorMessageIcon:null,
        }
    }

    showMessage = () => {
        this.props.onClose();
    }


    resetCanceledTasks = () => {
        const body = { idRoute: this.state.idRoute }
        const loadingConfig = { dispatch: this.props.dispatch };
        const onSuccess = () => this.props.onClose(true, false);
        const onError = (error) => this.props.onClose(false, true);

        resetCancelledTasksRequest(body, onSuccess, onError, loadingConfig);
    }

    render() {
        return (
            <React.Fragment>

            <Popup
                closeButton
                onClose={this.props.onClose}
                requiredFields={this.props.lan.requiredFields}
                buttonText={this.props.lan.accept}
                buttonOnClick={this.resetCanceledTasks}
                size={'ps2'}
                >
                    <Row>
                        <Col sm={12}>
                            <div className='mt-1'>
                                <Row className='d-flex justify-content-center mb-4'>
                                    <img src={alertIcon} className="danger" ></img>
                                </Row>

                            </div>
                            <div className='mt-1 '>
                                <Row >
                                    <Col className='confirmation-text'>{this.props.lan.resetCanceledTasksConfirmation} <br></br><br></br>{this.props.lan.resetCanceledTasksMessage}</Col>
                                </Row>
                            </div>

                            <div className='mt-3'>
                                <Row >
                                    <img src={redAlertIcon} alt="" id="comment-alert-icon" className="field-alert-icon"></img>
                                </Row>

                            </div>

                        </Col>
                    </Row>

                </Popup>
                </React.Fragment>
            );
    }
}

export default connect()(ResetCanceledTasksPopup);