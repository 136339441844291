import React from 'react';

import { useLanguage } from 'Context/LanguageContext';
import StarRatings from 'react-star-ratings';
import { StatusColor } from '@core/constants/task/colorCodes.constants';
import TaskDetailIcons from './TaskDetailIcons';
import Tags from '~/Tags/Tags.component';
import TaskStatus from '~/Status/TaskStatus';
import TaskFunctionalities from './TaskFunctionalities';
import Priority from '@core/constants/task/TaskPriority.constants';
import { Button, Grid } from '@mui/material';
import { CottonCandy, DeepSpaceGrey } from '@core/theme/baseTheme';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TASK_STATUS from '@core/constants/task/TaskStatus.constants';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import moment from 'moment';

export const StarRatingCell = ({ value }) => <StarRatings value={value} readOnly />;
export const TaskIconCell = ({ value }) => <TaskDetailIcons icons={value} />;
export const TaskTagsCell = ({ value }) => (
	<Grid container alignContent='center'>
		<Tags tags={value} />
	</Grid>
);

export const DateCell = ({ value, row, idKey }) => {
	if (!value) return '';
	
	const lan = useLanguage();
	const language = lan._language.replace('_', '-');
	
	const tz = row.location?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
	const date = new Date(value);
	try {
		const localDate = date.toLocaleString(language, { timeZone: tz });
		return <span>{localDate}</span>;
	} catch (error) {
		console.error(error);
		const localDateFallback = date.toLocaleString(language, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
		return <span>{localDateFallback}</span>;
	}
};

const isOwner = (user, task) => user.idAccount === task.accountId;
const isLastOwner = (user, task) => {
	if (task.partnerTaskRelations.length) {
		const lastElement = task.partnerTaskRelations.length - 1;
		const lastRelation = task.partnerTaskRelations[lastElement];
		return lastRelation.idAccountOwner === user.idAccount;
	}

	return false;
};

export const StatusCell = ({ value: status, row: task }) => {
	const user = useSelector(selectUser);
	let text = `taskStatus_${status}`;

	if (status === TASK_STATUS.ASSIGNED_TO_PARTNER && !isOwner(user, task) && !isLastOwner(user, task)) {
		text = 'taskStatus_8_1';
	}

	const statusInfo = { text, ...StatusColor[status] };
	return <TaskStatus status={statusInfo} />;
};

export const TaskTypeCell = ({ value }) => {
	const lan = useLanguage();
	switch (value) {
		case 'Volume':
			return lan.volume;
		case 'Pickup':
			return lan.pickup;
		case 'Workforce':
			return lan.workforce;
		case 'Delivery':
			return lan.delivery;
		case 'Group Task':
			return lan.grouptask;
		case 'Remote':
			return lan.remote;
		default:
			return value;
	}
};

export const PriorityCell = ({ value: priority, row }) => {
	const lan = useLanguage();
	switch (priority) {
		case Priority.HIGH:
			return lan.high;
		case Priority.MEDIUM:
			return lan.medium;
		case Priority.LOW:
			return lan.low;
		default:
			return row.priorityName;
	}
};

export const AgentCell = ({ value: agent }) => {
	if (agent && agent.name) {
		return `${agent.name} ${agent.lastName}`;
	}
	return '';
};

export const ParentTaskCell = ({ value: parentTask }) => {
	if (parentTask) {
		return parentTask;
	}
	return '';
};

export const TaskFunctionalitiesCell = ({ value }) => {
	if (!value) return '';
	const { forms, assets, inventory } = value;
	return <TaskFunctionalities forms={forms} assets={assets} inventory={inventory} />;
};

export const TaskDetailButton = ({ value: taskId, onClick }) => {
	return (
		<Button
			onClick={onClick}
			sx={{ background: CottonCandy, color: DeepSpaceGrey, borderRadius: '12px', px: 2 }}
			startIcon={<VisibilityIcon />}
		>
			{taskId}
		</Button>
	);
};
