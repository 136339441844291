import React, { useState, forwardRef } from 'react';

import DeleteIcon from '@assets/icons/delete-icon.svg';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Stack } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { useLanguage } from 'Context/LanguageContext';
import TextField from '~/TextField/TextField.component';
import './UploadFileMenu.css';

const ACCEPT_FILES =
	'.xls,.xlsx,.txt,.csv,.pdf,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png,.ods,.odt,.odp,.msg,.mp4,.gif,.tif,.tiff';

const UploadFileMenu = ({
	fileSelectedHandler,
	linkSelectedHandler,
	handlerDelete,
	handleLink,
	filesTask,
	showFileSizeMessage,
	showMaxSizeFileTask,
	showButtonAddFiles,
	handleDoActionUploadFiles,
	handlerEventKey,
	onBlurLink,
}) => {
	const lan = useLanguage();
	const [showInputFile, setShowInputFile] = useState(true);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleDeleteWithIndex = (index) => {
		handlerDelete(index);
	};

	const handleCloseLink = (event) => {
		linkSelectedHandler();
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	};

	return (
		<div className='mx-3'>
			<div className='row mt-2'>
				<div className='col-auto'>
					<Stack direction='row' spacing={2}>
						<div>
							<Button
								ref={anchorRef}
								id='composition-button'
								aria-controls={open ? 'composition-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-haspopup='true'
								onClick={handleToggle}
							>
								<AttachmentIcon />
								{lan.linkFiles}
							</Button>
							<Popper
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement='bottom-start'
								transition
								disablePortal
								style={{
									zIndex: '10',
								}}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList
													autoFocusItem={open}
													id='composition-menu'
													aria-labelledby='composition-button'
													onKeyDown={handleListKeyDown}
												>
													<MenuItem onClick={handleClose}>
														<label
															htmlFor='file-upload'
															style={{
																border: 'none',
															}}
															className='custom-file-upload pl-0'
														>
															<CloudUploadIcon /> {lan.uploadFiles}
														</label>
													</MenuItem>
													<MenuItem onClick={handleCloseLink}>
														<LinkIcon /> {lan.link}
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
					</Stack>
				</div>
				{showMaxSizeFileTask && (
					<div className='col-auto mt-2' style={{ color: '#dc5c87' }}>
						<div>{lan.maxSizeFileTask}</div>
					</div>
				)}
				{filesTask.length > 0 && showButtonAddFiles && (
					<button
						type='submit'
						className='btn btn-outline-primary btn-success upload-btnsave-size mb-2'
						onClick={handleDoActionUploadFiles}
					>
						{lan.save}
					</button>
				)}
			</div>
			{showFileSizeMessage && (
				<div className='row mx-2'>
					<div className='col-auto no-padding' style={{ color: '#dc5c87', fontWeight: 'bold', fontSize: '13px' }}>
						<div>{lan.fileSizeExceeded}</div>
					</div>
				</div>
			)}
			<div className='row mx-2'>
				{filesTask.map((fileTask, index) => (
					<>
						{fileTask.originType ? (
							<div className='col-4' key={index}>
								<div className='row'>
									<div className='col-9 pt-1 px-0 mr-1'>
										<TextField
											id={`txt-file-link-${index}`}
											value={fileTask.link}
											size='small'
											label={lan.linkDocument}
											onChange={(e) => handleLink(e.target.value, index)}
											fullWidth
											onKeyPress={(e) => handlerEventKey(e, index)}
											onBlur={() => onBlurLink(index)}
										/>
									</div>
									{fileTask.showCheck === true && (
										<div className='col-1 pt-2 pr-0 ml-0 pl-0'>
											<CheckIcon />
										</div>
									)}

									<div className='col-auto pt-2 pr-0 pl-1'>
										<img
											className='icon Fielder-icon-button mr-2'
											title={lan.delete}
											src={DeleteIcon}
											onClick={() => handleDeleteWithIndex(index)}
											alt=''
										/>
									</div>
								</div>
							</div>
						) : (
							<div className='col-4' key={index}>
								<div className='row'>
									<div className='col-auto pt-1 pl-0 pr-1'>
										<img
											className='icon Fielder-icon-button mr-2'
											title={fileTask.fileName}
											src={fileTask.thumbnail}
											alt=''
										/>
									</div>
									<div className='col-8 pl-0 pr-0 pt-2 pr-1'>{fileTask.fileName}</div>
									<div className='col-auto pt-2 pl-0 pr-1'>
										<img
											className='icon Fielder-icon-button mr-2'
											title={lan.delete}
											src={DeleteIcon}
											onClick={() => handleDeleteWithIndex(index)}
											alt=''
										/>
									</div>
								</div>
							</div>
						)}
					</>
				))}
			</div>

			{showInputFile === true && (
				<input
					id='file-upload'
					multiple
					style={{ display: 'none' }}
					className='task-upload-btn-wrapper'
					type='file'
					onChange={fileSelectedHandler}
					accept={ACCEPT_FILES}
				/>
			)}
		</div>
	);
};

export default UploadFileMenu;
