export const getExportTasksQueryParams = (
	fromDay,
	fromMonth,
	fromYear,
	toDay,
	toMonth,
	toYear,
	filter,
	tagsFilter,
	datesFilterType,
	dataToExport,
	avancedFilter,
	offset,
	limit,
	idUser,
	bringTasks,
	moduleToDownload
) => {
	const queryFilter = {
		fromDay,
		fromMonth: fromMonth + 1,
		fromYear,
		toDay,
		toMonth: toMonth + 1,
		toYear,
		idUser,
	};

	if (dataToExport != undefined && dataToExport != null) {
		queryFilter.dataToExport = dataToExport;
	}

	if (offset != undefined && offset != null) {
		queryFilter.offset = offset;
	}

	if (limit != undefined && limit != null) {
		queryFilter.limit = limit;
	}

	if (bringTasks != undefined && bringTasks != null) {
		queryFilter.bringTasks = bringTasks;
	}
	if (tagsFilter) {
		queryFilter.tagsFilter = tagsFilter;
	}
	if (datesFilterType) {
		queryFilter.datesFilterType = datesFilterType;
	}

	if (filter) {
		queryFilter.filter = filter;
	}
	if (avancedFilter) {
		queryFilter.avancedFilter = JSON.stringify(avancedFilter);
	}
	if (moduleToDownload) {
		queryFilter.moduleToDownload = moduleToDownload;
	}

	return queryFilter;
};



export const getExportGroupTasksQueryParams = (
	idGroupTask,
	filter,
	dataToExport,
	offset,
	limit,
	idUser,
	bringTasks,
	idsTask,
) => {
	const queryFilter = { idUser, idGroupTask };

	if (filter) {
		queryFilter.filter = filter;
	}

	if (dataToExport != undefined && dataToExport != null) {
		queryFilter.dataToExport = dataToExport;
	}

	if (offset != undefined && offset != null) {
		queryFilter.offset = offset;
	}

	if (limit != undefined && limit != null) {
		queryFilter.limit = limit;
	}

	if (bringTasks != undefined && bringTasks != null) {
		queryFilter.bringTasks = bringTasks;
	}

	if (idsTask != undefined && idsTask != null) {
		queryFilter.idsTask = idsTask;
	}
	
	return queryFilter;
};

