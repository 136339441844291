// ROLE MANAGEMENT
const PAGE_NAME = 'COMMAND_CENTER';
const NAME = 'commandCenter';
const ACCOUNT_PREFERENCES = 'showCommandCenter';
const VIEW = 0;
const EDIT = 1;

export default {
	PAGE_NAME,
	NAME,
	ACCOUNT_PREFERENCES,
	VIEW,
	EDIT,
};
