import React from 'react';

import GoogleMapsAutocomplete from '~/GoogleMapsAutocomplete/GoogleMapsAutocomplete.component';

const SearchByPlace = ({ address, selectLocation, disabled, label, error }) => {
	return (
		<div>
			<GoogleMapsAutocomplete
				id='branch-googleMapsAutocomplete'
				value={address}
				onSelect={selectLocation}
				disabled={disabled}
				label={label}
				error={error}
			/>
		</div>
	);
};

export default SearchByPlace;
