import React from 'react';

import { LightGreySubtitleColor, DeepSpaceGrey } from '@core/theme/baseTheme';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import MuiEditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';


export const TemplateRemovedText = styled((props) => <Typography align='left' {...props} />)`
	color: ${LightGreySubtitleColor};
	font-size: 10pt;
	font-family: 'Lato';
`;

export const ValueText = styled((props) => <Typography align='right' {...props} />)`
	color: ${DeepSpaceGrey};
	font-size: 10pt;
	font-family: 'Lato';
`;

export const DeleteIcon = styled(MuiDeleteIcon)`
	color: ${DeepSpaceGrey};
`;

export const EditIcon = styled(MuiEditIcon)`
	color: ${DeepSpaceGrey};
`;

export const MotionGrid = motion(Grid);
