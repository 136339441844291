import React from 'react';

import DashboardComponent from 'OldComponents/Dashboard/DashboardComponent';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const TaskDashboard = () => {
	const lan = useLanguage();
	return <DashboardComponent lan={lan} />;
};

export default TaskDashboard;
