module.exports = {
	getRequestCreateTemplateAA() {
		const data = {
			requestType: 'DO_ADD_ACCOUNT_TEMPLATES',
		};

		return { data };
	},
	getRequestUpdateTemplateAA() {
		const data = {
			requestType: 'DO_UPDATE_ACCOUNT_TEMPLATE',
		};

		return { data };
	},
	// Create ticket support
	getRecuestCreateRecuest() {
		const data = {
			type: '',
		};
	},
	// Create do action create company
	getRequestDoActionCreateCompany() {
		const data = {
			type: 'DO_ACTION_CREATE_COMPANY',
		};
		return { data };
	},

	/*  *   *   *   *   *  Forms  *    *   *   *   *   */
	getRequestDoGetFormTemplates: () => ({ requestType: 'DO_GET_ALL_FORM_TEMPLATES' }),
	getRequestDoCreateFormTemplates: () => ({ requestType: 'DO_CREATE_FORM_TEMPLATE' }),
	getRequestDoUpdateFormTemplate: () => ({ requestType: 'DO_EDIT_FORM_TEMPLATE' }),
	getRequestDoGetSpecificFormTemplate: () => ({ requestType: 'DO_GET_SPECIFIC_FORM_TEMPLATE' }),
	getRequestDeleteFormTemplate: () => ({ requestType: 'DO_DELETE_FORM_TEMPLATE' }),
	getRequestDoUpdateForm: () => ({ requestType: 'DO_UPDATE_FORM' }),
	getRequestDoDeleteFormImage: () => ({ requestType: 'DO_REMOVE_FORM_IMAGE' }),
	getRequestUpdateFormsStatus: () => ({ requestType: 'DO_UPDATE_FORMS_STATUS' }),
	getRequestDoFilterForms: () => ({ requestType: 'DO_FILTER_FORMS' }),
	getRequestDoReactivateForm: () => ({ requestType: 'DO_REACTIVATE_FORM' }),
	/*  *   *   *   *   *  Asset Management  *    *   *   *   *   */
	getRequestGetViewCategories: () => ({ requestType: 'GET_VIEW_CATEGORIES' }),
	getAssetCategory: () => ({ requestType: 'GET_CATEGOTY_TEMPLATE' }),
	getRequestGetViewAssets: () => ({ requestType: 'GET_VIEW_ASSETS' }),
	getRequestGetViewAssetsTable: () => 'GET_VIEW_ASSETS_TABLE',
	getAllAssets: () => 'GET_ALL_ASSETS',
	getAssetsToExport: () => 'GET_ASSETS_TO_EXPORT',
	getSpecificAsset: () => ({ requestType: 'GET_SPECIFIC_ASSET' }),
	getRequestDoUpdateAssetCategory: () => ({ requestType: 'DO_UPDATE_ASSET_MGMT_CATEGORIES' }),
	getRequestDoCreateAssetCategory: () => ({ requestType: 'DO_CREATE_ASSET_MGMT_CATEGORIES' }),
	getRequestDoDeleteAssetCategory: () => ({ requestType: 'DO_DELETE_ASSET_MGMT_CATEGORY' }),
	getRequestDoImportAsset: () => ({ requestType: 'DO_IMPORT_ASSET' }),
	getRequestDoImportAssets: () => ({ requestType: 'DO_IMPORT_CATEGORY_ASSETS' }),
	getRequestDoCreateAsset: () => ({ requestType: 'DO_CREATE_ASSET' }),
	getRequestDoUpdateAsset: () => ({ requestType: 'DO_UPDATE_ASSET' }),
	getRequestDoUpdateTaskAsset: () => ({ requestType: 'DO_UPDATE_TASK_ASSET' }),
	getRequestDoDeleteAsset: () => ({ requestType: 'DO_DELETE_ASSET' }),
	changeAssetStatus: () => 'CHANGE_ASSET_STATUS',
	getRequestGetAssetTemplate: () => ({ requestType: 'GET_CATEGOTY_TEMPLATE' }),
	getRequestDoDeleteAssetImage: () => ({ requestType: 'DO_REMOVE_ASSET_IMAGE' }),
	doUploadAssetCategoryTemplate: 'DO_UPLOAD_ASSET_CATEGORY_TEMPLATE',

	/*  *   *   *   *   *  ModulesConfig  *    *   *   *   *   */
	getAssetsConfig: () => ({ requestType: 'GET_ASSET_CONFIG' }),
	getInventoryConfig: () => ({ requestType: 'GET_INVENTORY_CONFIG' }),
	getNotificationsConfig: () => ({ requestType: 'GET_NOTIFICATIONS_CONFIG' }),
	updateUsersNotifications: () => ({ requestType: 'DO_UPDATE_USERS_NOTIFICATIONS' }),
	getMyNotificationsConfig: () => ({ requestType: 'GET_MY_NOTIFICATIONS_CONFIG' }),
	updateMyNotifications: () => ({ requestType: 'DO_UPDATE_MY_NOTIFICATIONS' }),
	getModulesConfig: () => ({ requestType: 'GET_MODULES_CONFIG' }),
	updateAssetConfig: () => 'DO_UPDATE_ASSET_CONFIG',
	updateInventoryConfig: () => 'DO_UPDATE_INVENTORY_CONFIG',
	updateModulesConfig: () => 'DO_UPDATE_MODULES_CONFIG',
	getInventoriesToExport: () => 'GET_INVENTORIES_TO_EXPORT',
	/*  *   *   *   *   *  Inventory  *    *   *   *   *   */
	getRequestGetViewCategoriesInventory: () => ({ requestType: 'GET_VIEW_INVENTORY_CATEGORIES' }),
	getRequestGetViewInventory: () => ({ requestType: 'GET_VIEW_INVENTORIES' }),
	getRequestGetViewInventoryTable: () => 'GET_VIEW_INVENTORIES_TABLE',
	getSpecificInventory: () => ({ requestType: 'GET_SPECIFIC_INVENTORY' }),
	getRequestDoDeleteInventoryImage: () => ({ requestType: 'DO_REMOVE_INVENTORY_IMAGE' }),
	getRequestGetInventoryTemplate: () => ({ requestType: 'GET_CATEGORY_INVENTORY_TEMPLATE' }),
	getRequestDoCreateInventoryCategory: () => ({ requestType: 'DO_CREATE_INVENTORY_CATEGORIES' }),
	getRequestDoUpdateInventoryCategory: () => ({ requestType: 'DO_UPDATE_INVENTORY_CATEGORY' }),
	getRequestDoDeleteInventoryCategory: () => ({ requestType: 'DO_DELETE_INVENTORY_CATEGORY' }),
	getRequestDoImportInventory: () => ({ requestType: 'DO_IMPORT_INVENTORY' }),
	getRequestDoImportCategoryInventory: () => ({ requestType: 'DO_IMPORT_CATEGORY_INVENTORY' }),
	getRequestDoCreateInventory: () => ({ requestType: 'DO_CREATE_INVENTORY' }),
	getRequestDoUpdateInventory: () => ({ requestType: 'DO_UPDATE_INVENTORY' }),
	getRequestDoDeleteInventory: () => ({ requestType: 'DO_DELETE_INVENTORY' }),
	changeInventoryStatus: () => 'CHANGE_INVENTORY_STATUS',
	doUploadInventoryCategoryTemplate: 'DO_UPLOAD_INVENTORY_CATEGORY_TEMPLATE',

	/*  *   *   *   *   *  Autoassign  *    *   *   *   *   */
	getRequestDoFilterAutoAssignTemplates: () => 'DO_FILTER_AUTO_ASSIGN_TEMPLATES',
	getRequestIsSkillsElegible: () => ({ requestType: 'GET_SKILLS_ACTIVE_IN_ACCOUNT' }),
	getRequestGetAccountSkills: () => ({ requestType: 'GET_ACCOUNT_SKILLS' }),
	getRequestDoRemoveAccountSkills: () => ({ requestType: 'DO_REMOVE_ACCOUNT_SKILLS' }),
	getRequestDoActionCreateBranch() {
		const data = {
			type: 'DO_ACTION_CREATE_BRANCH',
		};
		return { data };
	},
	// Delete photo
	getRequestDoActionDeletePhotoTask(imagePath, scalePath, id, idTask, idAccount) {

		return { taskInfo: {
			idTask,
			idAccount
		},
		photo: {
			imagePath,
			scalePath,
			id,
		} };
	},

	getRequestDoActionUpdateBranch() {
		const data = {
			type: 'DO_ACTION_UPDATE_BRANCH',
		};
		return { data };
	},
	getRouteById(idRoute) {
		return {
			requestType: 'GET_ROUTE_BY_ID',
			idRoute,
		};
	},
	getRouteExpensesPreferences() {
		return {
			requestType: 'GET_VIEW_ROUTE_EXPENSES_PREFERENCE',
		};
	},
	getRequestDoUpdateRouteExpenses(idRoute) {
		return {
			requestType: 'UPDATE_ROUTE_EXPENSES',
			idRoute,
		};
	},
	getTaskById(idTask) {
		return {
			requestType: 'GET_TASK_BY_ID',
			idTask,
		};
	},
	getTaskByIdAndFolio(idTask) {
		return {
			requestType: 'GET_TASK_BY_ID_AND_FOLIO',
			folio: idTask,
		};
	},
	getOrderRoute(route) {
		return {
			requestType: 'REORGANIZE_ROUTE',
			route,
		};
	},
	getChangePassword(oldPassword, newPassword) {
		return {
			oldPassword,
			newPassword,
		};
	},
	getCalendarRequest(from, to, userRole, idTeams) {
		return {
			from,
			to,
			offset: 0,
			limit: 100,
			userRole,
			idTeams,
		};
	},
	getRequestDoAddAssetTask(idAssets, idTask) {
		return {
			type: 'DO_ADD_ASSETS_TO_TASK',
			idAssets,
			idTask,
		};
	},
	getRequestDoActionTrashRoute(idRoute) {
		return {
			requestType: 'DELETE_ROUTE',
			idRoute,
		};
	},

	getDataProfile(userName, name, lastName, email, phoneNumber, mobileNumber, language, jobPosition, bin) {
		return {
			userName,
			name,
			lastName,
			email,
			phoneNumber,
			mobileNumber,
			language,
			jobPosition,
			imageProfile: bin,
		};
	},

	getDataToGetNotification(offset, limit) {
		return {
			user: {},
			offset,
			limit,
		};
	},

	getDefaultPermissions() {
		return {
			requestType: 'GET_DEFAULT_PERMISSIONS',
		};
	},

	getCustomPermissions() {
		return {
			requestType: 'GET_CUSTOM_PERMISSIONS',
		};
	},

	getDefaultRoles() {
		return {
			requestType: 'GET_DEFAULT_ROLES',
		};
	},

	getCustomRoles() {
		return {
			requestType: 'GET_CUSTOM_ROLES',
		};
	},

	duplicateRole: () => ({ requestType: 'DUPLICATE_ROLE' }),

	deleteRole: () => ({ requestType: 'DELETE_ROLE' }),

	deletePermission: () => ({ requestType: 'DELETE_PERMISSION' }),

	deleteNotification: () => ({ requestType: 'DELETE_NOTIFICATIONS' }),

	getDefaultNotifications() {
		return {
			requestType: 'GET_DEFAULT_NOTIFICATIONS',
		};
	},

	getCustomNotifications() {
		return {
			requestType: 'GET_CUSTOM_NOTIFICATIONS',
		};
	},

	getRequestDoCreatePermission: () => ({ requestType: 'CREATE_PERMISSION' }),
	getRequestDoUpdatePermission: () => ({ requestType: 'UPDATE_PERMISSION' }),

	getRequestDoCreateNotification: () => ({ requestType: 'CREATE_NOTIFICATION' }),
	getRequestDoUpdateNotification: () => ({ requestType: 'UPDATE_NOTIFICATION' }),

	getRequestDoCreateRole: () => ({ requestType: 'CREATE_ROLE' }),
	getRequestDoUpdateRole: () => ({ requestType: 'UPDATE_ROLE' }),

	getPermissionPolicies: () => ({ requestType: 'GET_POLICIES' }),
	getNotificationPolicies: () => ({ requestType: 'GET_NOTIFICATION_POLICIES' }),

	getCompleteRoleById: () => ({ requestType: 'GET_COMPLETE_ROLE_BY_ID' }),

	getDefaultUserByName() {
		return {
			requestType: 'GET_DEFAULT_USER_BY_NAME',
		};
	},

	getSpecificUsers() {
		return {
			requestType: 'GET_SPECIFIC_USERS',
		};
	},
	getSpecificUsersCount() {
		return {
			requestType: 'GET_SPECIFIC_USERS_COUNT',
		};
	},
	getRequestDoCreateUser: () => ({ requestType: 'CREATE_USER' }),

	getSpecificUserById() {
		return {
			requestType: 'GET_SPECIFIC_USER_BY_ID',
		};
	},
	getRequestDoUpdateUser: () => ({ requestType: 'UPDATE_USER' }),
};
