// ROLE MANAGEMENT
const PAGE_NAME = 'ANALYTICS';
const ANALYTICS = 'analytics';
const NAME = 'analytics';
const VIEW = 0;

export default {
	PAGE_NAME,
	NAME,
	ANALYTICS,
	VIEW,
};
