import { ICoordinates } from '@core/models/coordinates.model';
import React, { Children, FC, useEffect, useState } from 'react';

import { useLanguage } from '../../client/utils/Contexts/LanguageContext';
import { GOOGLE_API_KEY } from '../../client/utils/session/Session';
import { generateMarker, generateMarkerGroup, generateRoute, getCenterParam, urlBuilder } from './MapStatic.utils';
import { BaseMapProps, BasicMapProps } from './MapStatic.types';
import StaticMarker from './components/StaticMarker';
import StaticRoute from './components/StaticRoute';

const STATIC_GOOGLE_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';

type MapStaticProps = BasicMapProps | BaseMapProps;

const mapStyle = {
	borderRadius: '20px',
};

const MapStatic: FC<MapStaticProps> = (props) => {
	const [mapUrl, setMapUrl] = useState<string | undefined>();
	const { _language } = useLanguage();

	const generateStaticMapUrl = () => {
		const { size, scale, mapStyle, mapId, format, client, region, visible, channel, maptype, signature } = props;
		const urlParams = [];

		urlParams.push(urlBuilder('key', GOOGLE_API_KEY));
		urlParams.push(urlBuilder('language', _language));
		urlParams.push(urlBuilder('size', size));
		urlParams.push(urlBuilder('scale', scale));
		urlParams.push(urlBuilder('style', mapStyle));
		urlParams.push(urlBuilder('map_id', mapId));
		urlParams.push(urlBuilder('format', format));
		urlParams.push(urlBuilder('client', client));
		urlParams.push(urlBuilder('region', region));
		urlParams.push(urlBuilder('visible', visible));
		urlParams.push(urlBuilder('channel', channel));
		urlParams.push(urlBuilder('maptype', maptype));
		urlParams.push(urlBuilder('signature', signature));

		const childrenUrlParams =
			Children.map(props.children, (child) => {
				if (!React.isValidElement(child)) {
					return null;
				}

				switch (child.type) {
					case StaticMarker:
						return generateMarker(child);
					case StaticMarker.Group:
						return generateMarkerGroup(child);
					case StaticRoute:
						return generateRoute(child);
					case StaticRoute.Group:
						return;
				}
			}) || [];

		if (!childrenUrlParams || childrenUrlParams.length === 0) {
			const { center, zoom } = props as BasicMapProps;
			urlParams.push(getCenterParam(center));
			urlParams.push(urlBuilder('zoom', zoom));
		}

		urlParams.push(childrenUrlParams.filter((childUrlParam) => childUrlParam).join('&'));
		const MAP_URL_WITH_PARAMS = `${STATIC_GOOGLE_MAP_URL}?${urlParams.filter((param) => param).join('&')}`;
		setMapUrl(MAP_URL_WITH_PARAMS);
	};

	useEffect(() => generateStaticMapUrl(), [props]);

	return <img style={{ ...mapStyle, ...props.style }} src={mapUrl} alt='Map' />;
};

const defaultProps: BaseMapProps = {
	maptype: 'roadmap',
	size: '500x500',
};

MapStatic.defaultProps = defaultProps;

export default React.memo(MapStatic);
