import API_LINKS from 'FielderAPILinks';
import { REQUEST_TYPES } from '@core/constants/users/users.request.constants';

export const getClientsByFilterAsyncSelect = async (filter, idAccount, user, idToken, filterBranches,dontAddId) => {
	const clients = await getClientsByFilter(filter, idAccount, user.id, idToken);
	return formatClients(clients, filterBranches,null,dontAddId);
};

export const getClientsByFilter = (filter, idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_ALL_CLIENTS_BY_FILTER,
		idUser,
		idAccount,
		filter,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.clients;
			}
			return [];
		});
};

export const getClientsPartnerByFilterAsyncSelect = async (filter, idAccount, user, idToken) => {
	const clients = await getClientsPartnerByFilter(filter, idAccount, user.id, idToken);
	return formatClients(clients);
};

export const getClientsPartnerByFilter = (filter, idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_CLIENTS_BY_FILTER,
		idUser,
		idAccount,
		filter,
	};

	return fetch(API_LINKS.apiUserManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.users;
			}
			console.error(res);
			return [];
		});
};

export const formatClients = (clients, filterBranches, onlyId, dontAddId) =>
	clients.map((client) => {
		if (filterBranches) {
			client.id = client.idUser;
			return {
				label: `[${client.idUser}] - ${client.name} ${client.lastName} (${client.email})`,
				value: client,
			};
		}
		if (onlyId) {
			return {
				label: `[${client.idUser}] - ${client.name} ${client.lastName} (${client.email})`,
				value: client.idUser,
			};
		}
		if (dontAddId == undefined) {
			client.id = client.idUser;
		}

		return {
			label: `[${client.idUser}] - ${client.name} ${client.lastName} (${client.email})`,
			value: client,
		};
	});

/** ********************  DEFATULT OPTIONS  *************** */
export const getClientInitialOptionsAsyncSelect = async (idAccount, user, idToken, dontAddId) => {
	const clients = await getClientsInitialOptions(idAccount, user.id, idToken);
	return formatClients(clients,null,null,dontAddId);
};

export const getClientsInitialOptions = (idAccount, idUser, idToken) => {
	const body = {
		type: REQUEST_TYPES.GET_CLIENTS_DEFAULT_OPTIONS,
		idUser,
		idAccount,
	};

	return fetch(API_LINKS.taskManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.clients;
			}
			return [];
		});
};
