import React, { useState, useEffect } from 'react';

import { IconButton, Grid, Tooltip } from '@mui/material';
import { isLocalUser } from 'Utils/account/utils';

import ShareModal from './ShareModal';

const TopMenuShare = ({ user, lan }) => {
	const [show, setShow] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setShow(isLocalUser(user));
	}, [user]);

	const handleShowModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			{show && (
				<Grid item>
					<Tooltip title={lan.share}>
						<IconButton onClick={handleShowModal}>
							<img
								src={
									showModal
										? `${process.env.AWS_FIELDER_ICONS}share-red-icon.svg`
										: `${process.env.AWS_FIELDER_ICONS}share-icon.svg`
								}
								alt=''
								className={showModal ? 'Fielder-TopMenu-icon-active' : 'Fielder-TopMenu-icon'}
							/>
						</IconButton>
					</Tooltip>
					{showModal && <ShareModal closeDialog={handleShowModal} lan={lan} />}
				</Grid>
			)}
		</>
	);
};

export default TopMenuShare;
