import React from 'react';

import { ICoordinates } from './../../core/models/coordinates.model';
import { MarkerConfig } from './MapStatic.types';
import invariant from 'invariant';

const instanceOfCoordinates = (coords: any): coords is ICoordinates => {
	return 'lat' in coords;
};

export function locationBuilder(
	location: string | number | ICoordinates | string[] | number[] | ICoordinates[]
): string | null {
	const urlParts = [];

	if (Array.isArray(location)) {
		const arrParts = location.map(locationBuilder);
		urlParts.push(...arrParts);
	} else if (typeof location === 'string' || typeof location === 'number') {
		urlParts.push(location);
	} else if (instanceOfCoordinates(location)) {
		urlParts.push(`${location.lat},${location.lng}`);
	}

	return urlParts.join('|');
}

export const urlBuilder = (
	property: string,
	value: string | number | undefined | null,
	separator = '='
): string | null => {
	if (value) {
		return `${property}${separator}${value}`;
	}

	return null;
};

export const getCenterParam = (center: ICoordinates | string): string | null => {
	if (!center) return null;

	const centerValue = typeof center == 'string' ? center : `${center.lat},${center.lng}`;
	return urlBuilder('center', centerValue);
};

export const getMarkers = (markers: MarkerConfig) => {
	const urlParams = [];

	urlParams.push(urlBuilder('color', markers.color, ':'));
	urlParams.push(urlBuilder('icon', markers.icon, ':'));
	urlParams.push(urlBuilder('label', markers.label, ':'));
	urlParams.push(urlBuilder('size', markers.size, ':'));

	const url = urlParams.filter((x) => x).join('|');
	const markerData = url + '|' + markers.markers.map(({ lat, lng }) => `${lat},${lng}`).join('|');
	return urlBuilder('markers', markerData);
};

export const generateMarker = ({ props }: React.ReactPortal | React.ReactElement) => {
	invariant(props.location, 'Marker expects a valid location prop');

	let urlParts = [];

	urlParts.push(urlBuilder('size', props.size, ':'));
	urlParts.push(urlBuilder('color', props.color, ':'));
	urlParts.push(urlBuilder('label', props.label, ':'));
	urlParts.push(urlBuilder('anchor', props.anchor, ':'));
	urlParts.push(urlBuilder('scale', props.scale, ':'));
	urlParts.push(urlBuilder('icon', props.icon, ':'));
	urlParts.push(urlBuilder('', locationBuilder(props.location), ''));
	const url = urlParts.filter((x) => x).join('|');
	return urlBuilder('markers', url);
};

export const generateMarkerGroup = ({ props, type, key }: React.ReactPortal | React.ReactElement) => {
	const location = React.Children.map(props.children, (child) => child.props.location);
	const nextProps = { props: { ...props, location }, key, type };
	return generateMarker(nextProps);
};

export const generateRoute = ({ props }: React.ReactPortal | React.ReactElement) => {
	invariant(props.points, 'Marker expects a valid location prop');
	let urlParts = [];

	urlParts.push(urlBuilder('color', props.color, ':'));
	urlParts.push(urlBuilder('weight', props.weight, ':'));
	urlParts.push(urlBuilder('fillcolor', props.fillcolor, ':'));
	urlParts.push(urlBuilder('geodesic', props.geodesic, ':'));
	urlParts.push(urlBuilder('', locationBuilder(props.points), ''));
	const url = urlParts.filter((x) => x).join('|');
	return urlBuilder('path', url);
};
