import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';

import ClockIcon from '@assets/icons/clock-icon.svg';
import ProfileImage from '@assets/icons/default-profile-icon.svg';

class EditGroupTaskActivity extends Component {
	Label = (props) => (
		<Row className={'my-2 ' + props.className}>
			<Col className={'label-input ' + props.labelClass}>{props.label}</Col>
		</Row>
	);

	ActivityTable = (props) => {
			var activityLog = props.groupActivity.activity;

			function compare(a, b) {
				// Use toUpperCase() to ignore character casing
				const activityA = a.id;
				const activityB = b.id;

				let comparison = 0;
				if (activityA < activityB) {
					comparison = 1;
				} else if (activityA > activityB) {
					comparison = -1;
				}
				return comparison;
			}

			activityLog.sort(compare);

			// Get the Rows
			var rows = activityLog.map((activity, i) => {
				let user = '';
				if (activity.user !== undefined && activity.user != null &&  activity.user !="") {
					user = activity.user;
				} else if (activity.agent !== undefined && activity.agent != null &&  activity.agent !="") {
					user = activity.agent;
				}
			
				return (
					<tr key={'task-activity-' + i} style={{ height: '45px', border: '1px solid #e6ebf0' }}>
						<td>
							<img
								src={ClockIcon}
								key={activity.time + '-table-icon'}
								className='td-activity-table-pic mr-3 ml-5'
								alt=''
							/>
							<span className='td-activity-user-text my-auto' key={activity.time + '-time'}>
								{activity.time}
							</span>
						</td>
						<td>{activity.date}</td>
						<td>{activity.activity}</td>
						<td>
							<Row className='my-auto'>
								<img
									src={activity.userImage}
									key={user + '-table-img' + i}
									className='td-activity-table-pic mr-3'
									alt=''
								/>
								<span className='td-activity-user-text my-auto' key={user + '-text' + i}>
									{user}
								</span>
							</Row>
						</td>
					</tr>
				);
			});
		// Return the table
		return (
			<table className='td-activity-table'>
				<tbody>{rows}</tbody>
			</table>
		);
	};

	render() {
		return (
			<React.Fragment>
				{this.props.tab === 'taskGroupActivity' && (
					<div className='container' style={{ height: '350px' }}>
						<React.Fragment>
							<this.Label label={this.props.lan.history} className='my-5' />
							<Scrollbars style={{ height: '50%' }}>
								<Row className='mx-auto' style={{ height: '60%', width: '95%' }}>
									{<this.ActivityTable groupActivity={this.props.groupActivity} />}
								</Row>
							</Scrollbars>
						</React.Fragment>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default EditGroupTaskActivity;
