import React from 'react';
import dislikeIcon from '@assets/icons/bad-icon-png.png';
import likeIcon from '@assets/icons/good-icon-png.png';
import greyStarIcon from '@assets/icons/grey-star-icon.png';
import sadIcon from '@assets/icons/not-satisfactory-icon-png.png';
import happyIcon from '@assets/icons/satisfactory-icon-png.png';
import yellowStarIcon from '@assets/icons/yellow-star-icon.png';
import Types from 'FielderUtils/forms/types';
import { toDataURLWithJsZip } from 'FielderUtils/s3Proxy';
import { saveAs } from 'file-saver';
import moment from 'moment';
import MainTable from 'OldComponents/MainTable';
import TextareaAutosize from 'react-autosize-textarea';
import { Col, Row } from 'react-bootstrap';
import ImgOrient from 'react-fix-image-orientation';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

/**
 * Looks if a certain asset, inventory or form has a custom template
 * to be generated
 *
 * @param {*} template - Asset, Inventory or Forms template
 * @returns {object} - containing the custom types to generate the
 * 						asset, inventory or forms template
 */
export const checkCustomTemplateType = (template) => {
	const customTemplateTypes = {
		xlsx: false,
		html: false,
	};

	if (template.category && template.category.templatePath) {
		switch (template.category.templateType) {
			case 'xlsx':
				customTemplateTypes.xlsx = true;
				break;
			case 'html':
				customTemplateTypes.html = true;
				break;
			default:
				break;
		}
	}

	return customTemplateTypes;
};

/**
 * Handles the image download from inside the modules (asset, inventory or forms)
 * @param {*} images
 * @param {*} index
 * @param {*} zip
 * @param {*} templateType
 * @param {*} dispatch
 */

const downloadZipFile = (zip, templateType, dispatch, taskData) => {
	const name = `${taskData.idFielder}-${templateType}`;

	zip.generateAsync({ type: 'blob' }).then((content) => {
		saveAs(content, `${name}.zip`);
		dispatch(
			loadingStop({ name:"ZIP_DOWNLOAD" })
		);
	});
};

export const getImagesFromGroup = async (images, index, zip, templateType, dispatch, taskData) => {
	if (index < images.length) {
		const image = images[index];
		if (image) {
			const zipAux = await toDataURLWithJsZip(image, zip);
			const newIndex = index + 1;
			getImagesFromGroup(images, newIndex, zipAux, templateType, dispatch, taskData);
		} else {
			downloadZipFile(zip, templateType, dispatch, taskData);
		}
	} else {
		downloadZipFile(zip, templateType, dispatch, taskData);
	}
};

export const getCustomPDFBody = (templates, taskData, customTemplateType, language) => {
	const body = {
		idTask: taskData.task.idFielder,
		task: taskData.task,
		agent: {
			name: taskData.agent.name,
		},
	};

	if (templates.form) {
		body.form = templates.form;
		//body.request = 'DO_ACTION_GET_HTML';
	} else if (templates.asset) {
		const bodyAsset = JSON.parse(JSON.stringify(templates.asset));
		bodyAsset.logs = [];
		bodyAsset.category = templates.asset.category.name;
		body.asset = bodyAsset;
		///body.request = 'DO_ACTION_GET_ASSET_HTML';
	} else if (templates.inventory) {
		const bodyInventory = JSON.parse(JSON.stringify(templates.inventory));
		bodyInventory.logs = [];
		bodyInventory.category = templates.inventory.category.name;
		body.inventory = bodyInventory;
		///body.request = 'DO_ACTION_GET_INVENTORY_HTML';
	} else {
		return;
	}

	if (!customTemplateType.html) {
		body.lan = language;
	}

	return body;
};

const getDate = (question, timezone) => {
	if (question.date) {
		question.dateFormat.replace('dd', 'DD');
		return moment(question.date).tz(timezone).format('DD/MM/YYYY hh:mm');
	}

	return '';
};



export const getFieldQuestion = (question, showFormFile, timezone, lan, getTextFile, isField) => {
	try {
		let text = '';

	switch (parseInt(question.type)) {
		case Types.MULTIPLE_CHOICE:
			const selected = [];
			question.options.some((opt) => {
				if (opt.checked === true) {
					selected.push(opt.name);

					// Check if checkbox or radio button
					if (question.singleAnswer === true) {
						return true;
					}
				}
			});

			text = selected.join(', ');
			break;
		case Types.TEXT_FIELD:
			text = question.text;
			break;
		case Types.NUMERIC_RATING:
			text = question.value;
			break;
		case Types.BINARY_RATING:
			if (question.iconType === 'FINGERS') {
				question.value === true ? (text = likeIcon) : (text = dislikeIcon);
			} else {
				question.value === true ? (text = happyIcon) : (text = sadIcon);
			}
			break;
		case Types.STARS_RATING:
			text = question.value;
			break;
		case Types.ELECTRONIC_SIGNATURE:
			text = question.file;
			break;
		case Types.FILES:
			text = question.file;
			break;
		case Types.CODEBAR_IMG:
			text = question.file;
			break;
		case Types.CODEBAR_TEXT:
			text = question.value;
			break;
		case Types.DATE_HOUR:
			text = question.parsedDate ? question.parsedDate : getDate(question, timezone);
			if (text != null) {
				text = text.replace('January', lan.january);
				text = text.replace('February', lan.february);
				text = text.replace('March', lan.march);
				text = text.replace('April', lan.april);
				text = text.replace('May', lan.may);
				text = text.replace('June', lan.june);
				text = text.replace('July', lan.july);
				text = text.replace('August', lan.august);
				text = text.replace('September', lan.september);
				text = text.replace('October', lan.october);
				text = text.replace('November', lan.november);
				text = text.replace('December', lan.december);
			} else {
				text = '';
			}
			break;
		case Types.TEXT_FILE:
			if (question.file) {
				if (showFormFile[question.id]) {
					text = (
						<TextareaAutosize
							type='text'
							readOnly
							className='task-details-input'
							value={showFormFile[question.id]}
							style={{
								width: '100%',
								paddingTop: '5px',
								paddingLeft: '10px',
								paddingBottom: '5px',
								paddingRight: '10px',
							}}
						/>
					);
				} else {
					text = (
						<button className='Fielder-button-clear' onClick={()=>getTextFile(question, isField)}>
							{lan.viewFileContent}
						</button>
					);
				}
			} else {
				text = <button className='Fielder-button-clear-disabled'>{lan.noFileFound}</button>;
			}
			break;
		case Types.CATALOGUE:
			const columns = [
				{ id: 'id', padding: '' },
				{ id: 'option', padding: '' },
			];

			if (question.includeValue) {
				columns.push({
					id: 'value',
					name: lan.value,
					padding: '',
				});
			}

			if (question.includeComment) {
				columns.push({
					id: 'comment',
					name: lan.comment,
					padding: '',
				});
			}

			const rows = question.options
				.map((opt) => {
					if (opt.checked) {
						return {
							id: opt.id,
							option: opt.name,
							value: opt.value ? opt.value : '',
							comment: opt.comment ? opt.comment : '',
						};
					}
				})
				.filter((n) => n);

			return (
				<Row className='mt-4'>
					<Col sm={10} className='ml-auto mr-5'>
						<Row>
							<Col sm={3}>{question.title}</Col>
							<Col sm={9} className='dashed-box-border p-3'>
								{rows && rows.length > 0 && (
									<MainTable dense disabled fullItemsHeight cols={columns} rows={rows} lan={lan} height={500} />
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			);

		default:
			break;
	}

	// delete null spaces
	if (text === null) {
		text = '';
	}

	if (parseInt(question.type) === Types.ELECTRONIC_SIGNATURE || parseInt(question.type) === Types.CODEBAR_IMG) {
		return (
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col sm={3}>{question.title}</Col>
						<Col sm={9}>
							<div className='dashed-box-border'>
								{text !== '' ? (
									<img
										src={text}
										className='mx-auto d-block my-4'
										style={{ maxWidth: '100%', maxHeight: '400px' }}
										alt=''
									/>
								) : (
									''
								)}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	if (parseInt(question.type) === Types.FILES) {
		return (
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col style={{ height: '400px' }} sm={3}>
							{question.title}
						</Col>
						<Col style={{ height: '400px' }} sm={9}>
							<div className='task-details-input-photo' style={{ height: '400px' }}>
								{text !== '' ? <ImgOrient style={{ maxWidth: '400px', maxHeight: '400px' }} src={text} /> : ''}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	if (parseInt(question.type) === Types.STARS_RATING) {
		const stars = [];
		for (let i = 0; i < 5; i++) {
			if (i < text) {
				stars.push(
					<div className='ml-2'>
						{' '}
						<img src={yellowStarIcon} style={{ maxHeight: '25px' }} alt='' />{' '}
					</div>
				);
			} else {
				stars.push(
					<div className='ml-2'>
						{' '}
						<img src={greyStarIcon} style={{ maxHeight: '25px' }} alt='' />{' '}
					</div>
				);
			}
		}

		return (
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col sm={3}>{question.title}</Col>
						<Col sm={9}>
							<div
								className='task-details-input-photo row ml-1'
								style={{ maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px' }}
							>
								{stars}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	if (parseInt(question.type) === Types.BINARY_RATING) {
		return (
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col sm={3}>{question.title}</Col>
						<Col sm={9}>
							<div
								className='task-details-input-photo row ml-1'
								style={{ maxWidth: '100%', paddingTop: '10px', paddingBottom: '10px' }}
							>
								<div className='ml-3'>
									<img src={text} style={{ maxHeight: '25px' }} alt='' />
								</div>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	if (parseInt(question.type) === Types.TEXT_FILE) {
		return (
			<Row className='mt-4'>
				<Col sm={10} className='ml-auto mr-5'>
					<Row>
						<Col sm={3}>{question.title}</Col>
						<Col sm={9}>
							<div className='ml-3'>{text}</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
	return (
		<Row className='mt-4'>
			<Col sm={10} className='ml-auto mr-5'>
				<Row>
					<Col sm={3}>{question.title}</Col>
					<Col sm={9}>
						<TextareaAutosize
							type='text'
							readOnly
							className='task-details-input'
							value={text}
							style={{
								width: '100%',
								paddingTop: '5px',
								paddingLeft: '10px',
								paddingBottom: '5px',
								paddingRight: '10px',
							}}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
	} catch (error) {
		console.log(error)
	}
};
