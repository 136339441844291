import React from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import Graph from './Graph';

const THEMEONE = 'THEMEONE';
const THEMETWO = 'THEMETWO';

function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color('#242C39'), // 9013fe
			am4core.color('#8A989C'),
			am4core.color('#6D8F97'),
			am4core.color('#B6C3D0'), // ce2b66
			am4core.color('#4A5865'),
		];
	}
}

function am4themes_millenial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color('#9013fe'),
			am4core.color('#51a5de'),
			am4core.color('#f52394'),
			am4core.color('#ce2b66'),
			am4core.color('#dc5c87'),
		];
	}
}

class PieGraphAnalitycs extends Graph {
	constructor(data, props) {
		super(props);
		this.props = props;
		this.data = data;
	}

	createGraph(params) {
		const { theme } = params;
		am4core.unuseAllThemes();

		if (theme == THEMEONE) {
			am4core.useTheme(am4themes_empresarial);
		} else if (theme == THEMETWO) {
			am4core.useTheme(am4themes_millenial);
		}

		const chart = am4core.create(params.id, am4charts.PieChart);
		chart.maskBullets = false;
		const { category } = params;
		const { valueY } = params;
		let textServiceStatus = null;

		if (params.textServiceStatus != undefined) {
			textServiceStatus = params.textServiceStatus;
		} else if (params.textTasksFinished != undefined) {
			textServiceStatus = params.textTasksFinished;
		}

		const { dateFromDateTo } = params;
		const { textTotalTask } = params;

		const { total } = params;

		const { textTotalFinished } = params;

		chart.data = this.data;

		// Series
		super.setPieSeriesParamsAnalytics(
			chart,
			category,
			valueY,
			textServiceStatus,
			dateFromDateTo,
			total,
			textTotalTask,
			textTotalFinished,
			this.props
		);

		const { legend } = params;
		super.setChartProperties(chart, legend, true, true, false);

		this.chart = chart;
	}

	getGraph() {
		return this.chart;
	}
}

export default PieGraphAnalitycs;
