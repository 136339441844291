import React, { useState, useEffect, useRef } from 'react';

import CalendarIcon from '@assets/icons/calendar-icon.svg';
import DropDown from '@assets/icons/dropdown.svg';
import { GOOGLE_URL_WITH_LIBRARIES } from 'FielderUtils/session/Session';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';

import Map from '../Map';
import MapComponent from '~/Map/Map.component';
import { getAgentLastLocationRequest, getAgentLocationsRequest } from '@core/api/agents/agents.service';

var globalMarker = [];
const moment = require('moment-timezone');

export default function AgentRoute(props) {
	const [initialMap, setInitialMap] = useState(true);
	const [initialMarker, setInitialMarker] = useState(null);
	const [markers, setMarkers] = useState([]);
	const [mapCenter, setMapCenter] = useState(null);
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const mapRef = useRef();
	const dispatch = useDispatch();

	const getAgentMarkerDescription = (agent, dateTime) => {
		let description = '';
		if (agent) {
			const name = agent.name ? agent.name : '';
			const lname = agent.lastName ? agent.lastName : '';
			description = `${name} ${lname}`;
			if (dateTime) {
				const dateFormated = moment(dateTime).format('DD-MM-YYYY HH:mm');
				description = `${description} ${dateFormated}`;
			}
		}
		return description;
	};

	const getAgentOrigin = async () => {
		const markersAux = [];
		const body = { idAgent: props.agent ? props.agent.idAgent : 0 };

		const onSuccess = async ({ agentLastLocation }) => {
			if (agentLastLocation) {

				let initalMarker = {
					id: agentLastLocation.technicianId,
					position: {
						lat: parseFloat(agentLastLocation.latitude),
						lng: parseFloat(agentLastLocation.longitud),
					},
					title: getAgentMarkerDescription(props.agent),
					icon: 'agent',
					draggable: false,
				};
				markersAux.push(initalMarker)
				let arrayMarkers = [{ markers: markersAux }];
				globalMarker = arrayMarkers;
				setMarkers(arrayMarkers);
				setInitialMarker(initalMarker);
			}
		};

		const loadingConfig = {
			dispatch: dispatch,
			config: {
				allowTopBar: true,
			},
		};

		await getAgentLastLocationRequest(body, onSuccess, null, loadingConfig);
	};

	const onChangeInitialMap = () => {
		if (initialMap) {
			setInitialMap(false);
		}
	};

	const handleFromDate = (date) => {
		if (date == null) {
			globalMarker = [];	
			setMarkers([]);
		}
		setFrom(date);
	};

	const handleToDate = (date) => {
		if (date && from && date.getTime() >= from.getTime()) {
			setTo(date);
			globalMarker=[];
			setMarkers([]);
		}
	};

	const fitBounds = () => {
		if (mapRef.current) {
			mapRef.current.fitMap();
		}
	};

	useEffect(() => {
		fitBounds()
	}, [markers]);

	useEffect(() => {
		if (to != null && from != null) {
			getAgentRoute();
		}
	}, [to, from]);

	const getAgentRoute = async () => {
		const markersAux = [initialMarker];

		const body = {
			fromYear: from.getFullYear(),
			fromMonth: from.getMonth(),
			fromDay: from.getDate(),
			toYear: to.getFullYear(),
			toMonth: to.getMonth(),
			toDay: to.getDate(),
			idAgent: props.agent ? props.agent.idAgent : 0,
		};

		const loadingConfig = {
			dispatch,
			config: {
				allowTopBar: true,
			},
		};

		const onSuccess = async ({ agentGeolocations, ...res }) => {

			try {				
		
				if (agentGeolocations) {
					let agentLocation = null;
					let pin = 'other';

					agentGeolocations.forEach((location, index) => {		
						markersAux.push({
							id: location.technicianId,
							position: {
								lat: parseFloat(location.latitude),
								lng: parseFloat(location.longitud),
							},
							title: getAgentMarkerDescription(props.agent, location.dateTime),
							icon: 'inactiveAgent',
							draggable: false,
						})
						
					});
					let arrayMarkers = [{ markers: markersAux }];
					globalMarker = arrayMarkers;
					setMarkers(markersAux);
				}
			} catch (error) {
				console.log("error")
				console.log(error)
			}
		};

		await getAgentLocationsRequest(body, onSuccess, null, loadingConfig);
	};

	useEffect(() => {
		getAgentOrigin();
		let mapCenterAux = { lat: parseFloat(19.3901284), lng: parseFloat(-99.2896576) };

		if (props.agent && props.agent.location) {
			mapCenterAux = {
				lat: parseFloat(props.agent.location.latitude),
				lng: parseFloat(props.agent.location.longitud),
				//force: true,
			};
		}
		setMapCenter(mapCenterAux);
	}, []);

	return (
		<div className='container' style={{ height: '450px' }}>
			<Row>
				<Col sm={7}>
					<Row className='Fielder-input-border'>
						<Col sm='auto'>
							<img src={CalendarIcon} className='icon my-2' alt='' />
						</Col>
						<Col>
							<DatePicker
								selected={from} // seleccionada fecha
								onChange={handleFromDate}
								id='input-date-from'
								showMonthDropdown
								showYearDropdow
								maxTime={new Date().setHours(23, 59)}
								dateFormat='dd/MM/yyyy' // Formato de fecha
								className='MyOperation-eschedule form-control mt-1 text-left'
								placeholderText='De'
							/>
							<img src={DropDown} className='icon my-2 dropDownCalendar' alt='' />
						</Col>
						<Col sm='auto' className={from ? '' : 'rd-disable'}>
							<img src={CalendarIcon} className='icon my-2 MyOperation-img-input Fielder-icon-button' alt='' />
						</Col>
						<Col className={from ? '' : 'rd-disable'}>
							<DatePicker
								selected={to} // seleccionada fecha
								onChange={handleToDate}
								showMonthDropdown
								showYearDropdow
								maxTime={new Date().setHours(23, 59)}
								dateFormat='dd/MM/yyyy' // Formato de fecha
								className='MyOperation-eschedule form-control mt-1 text-left'
								placeholderText='A'
							/>
							<img src={DropDown} className='icon my-2 dropDownCalendar Fielder-icon-button' alt='' />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col sm={2}>
					<Row>
						<Col />
					</Row>
				</Col>
		
				<Col sm={8} style={{ height: '350px' }}>
					<MapComponent
						markers={globalMarker}
						ref={mapRef}
						zoom={14}
						mapZoom={14}
						center={mapCenter}
					/>
				</Col>
			</Row>
		</div>
	);
}
