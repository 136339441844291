import React, { Component } from 'react';

import DangerIcon from '@assets/icons/danger-icon.svg';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Col, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import StatusFormatter from '../SmartTable/StatusFormatter';
import MapStaticComponent from '~/MapStatic/MapStatic.component';
import StaticMarker from '~/MapStatic/components/StaticMarker';


const COMMENT_AGENT = 0;
const RESET_ROUTE_TASK = 1;

const RejectComments = (props) => {
	const elements = props.rejectReason.map((rejectReason, index) => {
		return (
			<tr key={index + 'rejAgent'}>
				<td key={index + 'rejAgentComment'} className='text-left'>
					{rejectReason.name}
				</td>
				<td key={index + 'rejAgentTime'} className='text-left'>
					{rejectReason.date}
				</td>
				<td key={index + 'rejAgentName'} className='text-left'>
					{rejectReason.dependency}
				</td>
				<td key={index + 'rejAgentName'} className='text-left'>
					{rejectReason.reason}
				</td>
				<td key={index + 'rejAgentName'} className='text-left'>
					{rejectReason.comment}
				</td>
			</tr>
		);
	});

	return <tbody>{elements}</tbody>;
};

const PauseComments = (props) => {
	const elements = props.pauseReason.map((pauseReason, index) => {
		return (
			<tr key={index + 'pauseAgent'}>
				<td key={index + 'ccAgentComment'} className='text-left'>
					{pauseReason.name}
				</td>
				<td key={index + 'pauseAgentComment'} className='text-left'>
					{pauseReason.date}
				</td>
				<td key={index + 'pauseAgentComment'} className='text-left'>
					{pauseReason.dependency}
				</td>
				<td key={index + 'pauseAgentComment'} className='text-left'>
					{pauseReason.reason}
				</td>
				<td key={index + 'pauseAgentTime'} className='text-left'>
					{pauseReason.comment}
				</td>
			</tr>
		);
	});

	return <tbody>{elements}</tbody>;
};

const ModifyStatusComments = (props) => {
	const elements = props.modifyReason.map((modifyReason, index) => {
		return (
			<tr key={index + 'pauseAgent'}>
				<td key={index + 'ccAgentComment'} className='text-left'>
					{modifyReason.name}
				</td>
				<td key={index + 'pauseAgentComment'} className='text-left'>
					{modifyReason.date}
				</td>
				<td key={index + 'pauseAgentTime'} className='text-left'>
					{modifyReason.comment}
				</td>
			</tr>
		);
	});

	return <tbody>{elements}</tbody>;
};

const CancelCommentsAgent = (props) => {
	const elements = props.cancelReason.map((cancelReason, index) => {
		return (
			<tr key={index + 'ccAgent'}>
				<td key={index + 'ccAgentComment'} className='text-left'>
					{cancelReason.fullAgentName}
				</td>
				<td key={index + 'cAgentTime'} className='text-left'>
					{cancelReason.commentTime}
				</td>
				<td key={index + 'cAgentName'} className='text-left'>
					{cancelReason.comment}
				</td>
			</tr>
		);
	});

	return <tbody>{elements}</tbody>;
};

const CancelCommentsUser = (props) => {
	const elements = props.cancelCommentsUser.map((cancelCommentsUser, index) => {
		return (
			<tr key={index + 'cCancelUserComment'}>
				<td key={index + 'ccUserComment'} className='text-left'>
					{cancelCommentsUser.fullClienttName}
				</td>
				<td key={index + 'ccUserTime'} className='text-left'>
					{cancelCommentsUser.commentTime}
				</td>
				<td key={index + 'ccUserFillNmae'} className='text-left'>
					{cancelCommentsUser.comment}
					
				</td>
			</tr>
		);
	});

	return <tbody>{elements}</tbody>;
};

/**
 * Contains the Execution content from the {@link TaskDetails} component
 */
class TaskExecution extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editable: false,
		};
	}

	/**
	 * Helper  the Labels and text components to reduce
	 * code written
	 *
	 * @param {*} props - Text  the label and text
	 */
	LabelText = (props) => {
		var text = props.text;

		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={'td-label-input ' + props.labelClass}>{props.label}</Col>
				</Row>
				<Row>
					<Col className={'td-default-text align-left ' + props.textClass}>
						<input
							type='text'
							className={'task-details-input '}
							defaultValue={text}
							disabled={!this.state.editable}
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
			</Col>
		);
	};

	/**
	 * This function sets the destination marker  the task required
	 *  the map to show a pin in that area
	 * @returns the coordinates of the destination found in the database
	 *          to be entered in the map
	 */
	getExecutionOrigin() {
		return {
			coordinates: {
				lat: parseFloat(this.props.taskData.lat),
				lng: parseFloat(this.props.taskData.lng),
			},
			pin: 'pink',
		};
	}

	render() {
		return (
			<div className='container'>
				<React.Fragment>
					{this.props.showUtcMessage && (
						<Row className='ml-3 pl-2'>
							<Alert severity="warning">{this.props.lan.dateInUTCFormat}</Alert>
						</Row>
					)}
					<Row>
						<Col sm={6}>
							<Col>
								<Row>
									<Col sm={7}>
										<this.LabelText
											label={this.props.lan.idCustom}
											text={this.props.taskData.number}
											className='my-3'
											textClass='td-task-number'
										/>
									</Col>
									<Col className='my-auto'>
										<StatusFormatter {...this.props} value={this.props.taskData.status} />
									</Col>
								</Row>
							</Col>
							<Col>
								<this.LabelText
									label={this.props.lan.scheduledDate}
									text={this.props.taskData.programmedDate}
									className='my-3'
								/>
							</Col>
							<Col>
								<this.LabelText label={this.props.lan.endDate} text={this.props.taskData.endDate} className='my-3' />
							</Col>
							<Col>
								<div className='row'>
									<div className='col'>
										<this.LabelText
											label={this.props.lan.timeExecution}
											text={this.props.taskData.timeExecution}
											className='my-3'
										/>
									</div>
									<div className='col'>
										<this.LabelText label={this.props.lan.until} text={this.props.taskData.until} className='my-3' />
									</div>
								</div>
							</Col>

							{!RoleUtils.isUserClient(this.props.session.user) ? (
								<Col>
									<this.LabelText
										label={this.props.lan.assignedTeam}
										text={this.props.taskData.assignedTeam}
										className='my-3'
									/>
								</Col>
							) : null}
						</Col>
						<Col sm={6}>
							<div style={{ height: '80%' }}>
								<MapStaticComponent size='500x500'>
									{this.props.taskData.coords && (
										<StaticMarker location={this.props.taskData.coords} icon={this.props.taskData.taskPin} />
									)}
								</MapStaticComponent>
							</div>
							<div className='row'>
								<this.LabelText
									label={this.props.lan.addressTask}
									text={this.props.taskData.address}
									className='my-3'
								/>
							</div>
						</Col>
					</Row>

					{/** REJECT REASONS */}
					{this.props.taskData.taskRejectionReason && this.props.taskData.taskRejectionReason.length > 0 && (
						<Scrollbars className='mt-2' style={{ height: '180px', width: '100%' }}>
							<React.Fragment>
								<div className='row ml-3 mt-2'>
									<Col className={'td-label-input ' + this.props.lan.taskRejectionReason}>
										{this.props.lan.taskRejectionReason}
									</Col>
								</div>
								<div className='row mt-4'>
									<div className='col ml-4'>
										<div className='AgentCancel-comments-table'>
											<Scrollbars style={{ height: '180px', width: '100%' }}>
												<div className='table-responsive-sm'>
													<table className='table table-sm table-hover'>
														<thead>
															<tr>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.name}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.date}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.dependency}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.reason}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.comment}
																</th>
															</tr>
														</thead>
														<RejectComments rejectReason={this.props.taskData.taskRejectionReason} />
													</table>
												</div>
											</Scrollbars>
										</div>
									</div>
								</div>
							</React.Fragment>
						</Scrollbars>
					)}

					{/** PAUSE REASONS */}
					{this.props.taskData.taskPauseReason && this.props.taskData.taskPauseReason.length > 0 && (
						<Scrollbars className='mt-2' style={{ height: '180px', width: '100%' }}>
							<React.Fragment>
								<div className='row ml-3 mt-2'>
									<Col className={'td-label-input ' + this.props.lan.taskPausedReason}>
										{this.props.lan.taskPausedReason}
									</Col>
								</div>
								<div className='row mt-4'>
									<div className='col ml-4'>
										<div className='AgentCancel-comments-table'>
											<Scrollbars style={{ height: '180px', width: '100%' }}>
												<div className='table-responsive-sm'>
													<table className='table table-sm table-hover'>
														<thead>
															<tr>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.name}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.date}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.dependency}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.reason}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.comment}
																</th>
															</tr>
														</thead>
														<PauseComments pauseReason={this.props.taskData.taskPauseReason} />
													</table>
												</div>
											</Scrollbars>
										</div>
									</div>
								</div>
							</React.Fragment>
						</Scrollbars>
					)}
					{/** MODIFY STATUS ADMIN */}
					{this.props.taskData.modifyTaskStatusCommentAdmin &&
						this.props.taskData.modifyTaskStatusCommentAdmin.length > 0 && (
							<Scrollbars className='mt-2' style={{ height: '180px', width: '100%' }}>
								<React.Fragment>
									<div className='row ml-3 mt-2'>
										<Col className={'td-label-input ' + this.props.lan.taskPausedReason}>
											{this.props.lan.taskStatusModificationCommentByAdministrator}
										</Col>
									</div>
									<div className='row mt-4'>
										<div className='col ml-4'>
											<div className='AgentCancel-comments-table'>
												<Scrollbars style={{ height: '180px', width: '100%' }}>
													<div className='table-responsive-sm'>
														<table className='table table-sm table-hover'>
															<thead>
																<tr>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.name}
																	</th>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.date}
																	</th>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.comment}
																	</th>
																</tr>
															</thead>
															<ModifyStatusComments modifyReason={this.props.taskData.modifyTaskStatusCommentAdmin} />
														</table>
													</div>
												</Scrollbars>
											</div>
										</div>
									</div>
								</React.Fragment>
							</Scrollbars>
						)}

					{/** MODIFY STATUS MANAGER */}
					{this.props.taskData.modifyTaskStatusCommentManager &&
						this.props.taskData.modifyTaskStatusCommentManager.length > 0 && (
							<Scrollbars className='mt-2' style={{ height: '180px', width: '100%' }}>
								<React.Fragment>
									<div className='row ml-3 mt-2'>
										<Col className={'td-label-input ' + this.props.lan.taskPausedReason}>
											{this.props.lan.taskStatusModificationCommentByManager}
										</Col>
									</div>
									<div className='row mt-4'>
										<div className='col ml-4'>
											<div className='AgentCancel-comments-table'>
												<Scrollbars style={{ height: '180px', width: '100%' }}>
													<div className='table-responsive-sm'>
														<table className='table table-sm table-hover'>
															<thead>
																<tr>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.name}
																	</th>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.date}
																	</th>
																	<th scope='col-auto' style={{ textAlign: 'left' }}>
																		{this.props.lan.comment}
																	</th>
																</tr>
															</thead>
															<ModifyStatusComments modifyReason={this.props.taskData.modifyTaskStatusCommentManager} />
														</table>
													</div>
												</Scrollbars>
											</div>
										</div>
									</div>
								</React.Fragment>
							</Scrollbars>
						)}

					{this.props.taskData.cancelReason && this.props.taskData.cancelReason.length > 0 && (
						<Scrollbars className='mt-2' style={{ height: '180px', width: '100%' }}>
							<React.Fragment>
								<div className='row ml-3 mt-2'>
									<Col className={'td-label-input ' + this.props.lan.commentsAgent}>{this.props.lan.cancelComment}</Col>
								</div>
								<div className='row mt-4'>
									<div className='col ml-4'>
										<div className='AgentCancel-comments-table'>
											<Scrollbars style={{ height: '180px', width: '100%' }}>
												<div className='table-responsive-sm'>
													<table className='table table-sm table-hover'>
														<thead>
															<tr>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.name}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.date}
																</th>
																<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.comment}
																</th>
															</tr>
														</thead>
														<CancelCommentsAgent cancelReason={this.props.taskData.cancelReason} />
													</table>
												</div>
											</Scrollbars>
										</div>
									</div>
								</div>
							</React.Fragment>
						</Scrollbars>
					)}

					{this.props.taskData.cancelCommentsUser && this.props.taskData.cancelCommentsUser.length > 0 && (
						<React.Fragment>
							<div className='row ml-3 mt-2'>
								<Col className={'td-label-input ' + this.props.lan.commentsUser}>
									{this.props.lan.userCancelComment}
								</Col>
							</div>
							<div className='row mt-4'>
								<div className='col ml-4'>
									<div className='AgentCancel-comments-table'>
										<Scrollbars style={{ height: '180px', width: '100%' }}>
											<div className='table-responsive-sm'>
												<table className='table table-sm table-hover'>
													<thead>
														<tr>
															<th scope='col-auto' style={{ textAlign: 'left' }}>
																{this.props.lan.name}
															</th>
															<th scope='col-auto' style={{ textAlign: 'left' }}>
																{this.props.lan.date}
															</th>
															<th scope='col-auto' style={{ textAlign: 'left' }}>
																{this.props.lan.comment}
															</th>
														</tr>
													</thead>
													<CancelCommentsUser cancelCommentsUser={this.props.taskData.cancelCommentsUser} />
												</table>
											</div>
										</Scrollbars>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}

					<Scrollbars style={{ height: '180px', width: '100%' }}>
						<Row className='ml-2 mx-2'>
							{this.props.taskData.comments && this.props.taskData.comments.length > 0 && (
								<div className='col no-padding'>
									<Row className='my-2 ml-1'>
										<Col className={'td-label-input ' + this.props.lan.commentsAgent}>
											{this.props.lan.commentsAgent}
										</Col>
									</Row>

									<Scrollbars className='ml-3' style={{ height: '180px' }}>
										<div className='table-responsive-sm mr-4 pr-2'>
											<table className='table table-sm table-hover '>
												<thead>
													<tr>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.agentName}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.date}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.countTask}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.comment}
														</th>
													</tr>
												</thead>
												<tbody>
													{this.props.taskData.comments.map((elem, index) => {
														return (
															<React.Fragment>
																{elem.type == COMMENT_AGENT && (
																	<tr key={index + 'acoments'}>
																		<td key={index + 'auserName'} className='text-left'>
																			{elem.userComentName}
																		</td>
																		<td key={index + 'aDate'} className='text-left'>
																			{elem.commentDate}
																		</td>
																		<td key={index + 'anumb'} className='text-left'>
																			{this.props.lan.commentTitle + ' ' + elem.number}
																		</td>
																		<td key={index + 'acomment'} className='text-left'>
																			{elem.comment}
																		</td>
																	</tr>


																)}

																{elem.type == RESET_ROUTE_TASK && (
																	<tr key={index + 'crComments'}>
																		<td key={index + 'crUserName'} className='text-left'>
																			{elem.userComentName}
																		</td>
																		<td key={index + 'crDate'} className='text-left'>
																			{elem.commentDate}
																		</td>
																		<td key={index + 'crReset'} className='text-left'>
																			{this.props.lan.resetedTask}{' '}
																			<img className='imageSize-danger-icon' src={DangerIcon} alt='' />{' '}
																		</td>
																		<td key={index + 'crComent'} className='text-left'>
																			{elem.comment}
																		</td>
																	</tr>
																)}
															</React.Fragment>
														);
													})}
												</tbody>
											</table>
										</div>
									</Scrollbars>
								</div>
							)}
							{!RoleUtils.isUserClient(this.props.session.user) &&
								this.props.taskData.routeVariables != null &&
								this.props.taskData.routeVariables.length > 0 && (
									<Col sm={6}>
										<Row>
											<Col className='mt-2'>
												<span className='text-left'>{this.props.lan.routeTaskVariables.toUpperCase()}</span>
											</Col>
										</Row>
										{this.props.taskData.routeVariables.map((element, index) => (
											<Row>
												<this.LabelText
													label={element.name}
													text={element.value}
													className='my-3'
													textClass='td-route-variable'
												/>
											</Row>
										))}
									</Col>
								)}
						</Row>
					</Scrollbars>




						<Row className='ml-2 mx-2'>
							{this.props.taskData.rescheduleComments && (
								<div className='col no-padding'>
									<Row className='my-2 ml-1'>
										<Col className={'td-label-input ' + this.props.lan.rescheduleComment}>
											{this.props.lan.rescheduleComment}
										</Col>
									</Row>


										<div className='table-responsive-sm mr-4 pr-2'>
											<table className='table table-sm table-hover '>
												<thead>
													<tr>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
																{this.props.lan.name}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
																	{this.props.lan.role}
																</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.date}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.comment}
														</th>
														<th scope='col-auto' style={{ textAlign: 'left' }}>
															{this.props.lan.dateOfRescheduleComment}
														</th>
													</tr>
												</thead>
												<tbody>


													<tr key={ 'racoments'}>
														<td key={ 'rauserName'} className='text-left'>
																{this.props.taskData.rescheduleComments.rescheduledUserName}
														</td>
														<td key={ 'rauserName'} className='text-left'>
															{this.props.taskData.rescheduleComments.rescheduleTypeOfUser}
														</td>
														<td key={'rauserName'} className='text-left'>
															{this.props.taskData.rescheduleComments.rescheduledDate}
														</td>
														<td key={  'raDate'} className='text-left'>
															{this.props.taskData.rescheduleComments.commentReschedule}
														</td>

														<td key={'racomment'} className='text-left'>
															{this.props.taskData.rescheduleComments.dateOfReschedule}
														</td>
													</tr>





												</tbody>
											</table>
										</div>

								</div>
							)}

						</Row>






				</React.Fragment>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(TaskExecution);
