import { DeepSpaceGrey, LightGreySubtitleColor, PersianGreen, Sunglow, Verdigris, YellowOrange } from '@core/theme/baseTheme';
import { Avatar, Chip, IconButton, Typography } from '@mui/material'
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

export const RowTitle = styled(Typography)`
	color: ${DeepSpaceGrey};
	font-size: 0.9rem;
`;

export const RowSubtitle = styled(Typography)`
	color: ${LightGreySubtitleColor};
	font-size: 0.9rem;
`
export const CompanyIcon = styled(Avatar)`
	display: inline-flex;
	vertical-align: middle;
	width: 50px;
	height: 50px;
`;

export const MotionIconButton = motion(IconButton);

export const TaskTypeChip = styled(Chip)`
	background-color: ${Verdigris}1c;
	color: ${PersianGreen};
	margin: 1px;
`;

export const BranchChip = styled(Chip)`
	background-color: ${Sunglow}1c;
	color: ${YellowOrange};
	margin: 1px;
`;

export const FilterTitle = styled(Typography)`
	font-family: 'Lato';
	font-size: 0.8rem;
	color: ${LightGreySubtitleColor}
`;

export const CompletenessStatusTitle = styled(Typography)`
	font-family: 'Lato';
	font-size: 1.2rem;
	color: ${LightGreySubtitleColor}
`;