import React from 'react';

import FielderLogoImage from '@assets/icons/logo-login-icon.svg';
import { Grid } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import UnderlinedButton from 'FielderComponents/UnderlinedButton';
import { useNavigate } from 'react-router-dom';
import {motion, AnimatePresence} from 'framer-motion'

import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';
import { FielderLogo, TermsAndServiceButton, LoginSecondaryText, LoginPaper } from '../LoginPage.styles';

const PRIVACY_POLICY_LINK = 'https://www.appfielder.com/policy';
const TERMS_OF_SERVICE_LINK = 'https://www.appfielder.com/terms-of-service/';

const MotionLoginPaper = motion(LoginPaper);
const x = motion(LoginCard)

const LoginCard = ({ children, show, setShow }) => {
	const lan = useLanguage();
	const navigate = useNavigate();

	const handleForgotPassword = () => navigate(ENDPOINTS.recoverPassword);
	const openPage = (page) => () => {
		let link;
		if (page === 'termsOfService') {
			link = TERMS_OF_SERVICE_LINK;
		} else if (page === 'privacyPolicy') {
			link = PRIVACY_POLICY_LINK;
		} else {
			return;
		}

		window.open(link, '_blank');
	};

	return (
		<div style={{ flexGrow: 1 }}>
			<AnimatePresence>
				{show &&
					<motion.div
						initial={{ scale: 1, opacity: 1 }}
						exit={{ scale: 0, opacity: 0 }}
						transition={{ duration: 0.3 }}>
					<MotionLoginPaper
					>
						<Grid
							container
							spacing={4}
							wrap='nowrap'
							direction='column'
							justifyContent='center'
							alignItems='stretch'
							sx={{textAlign: 'center'}}
						>
							<Grid item xs={12} sx={{ pb: 4 }}>
								<FielderLogo src={FielderLogoImage} alt=''/>
							</Grid>

							{children}

							<Grid item xs={8} className='mx-auto'>
								<UnderlinedButton onClick={handleForgotPassword}>{lan.forgotPass}</UnderlinedButton>
							</Grid>

							<Grid item xs={10} className='mx-auto'>
								<LoginSecondaryText>
									{lan.termsAndConditionsLogin}

									<TermsAndServiceButton onClick={openPage('termsOfService')}>
										{lan.termsAndConditions2}
									</TermsAndServiceButton>

									{lan.and}
									<TermsAndServiceButton onClick={openPage('privacyPolicy')}>{lan.privacyPolicy}</TermsAndServiceButton>
								</LoginSecondaryText>
							</Grid>

							<Grid>
								<LoginSecondaryText>
									{`© ${process.env.FIELDER_APP_YEAR} v${process.env.FIELDER_APP_VERSION}`}
								</LoginSecondaryText>
							</Grid>
						</Grid>
						</MotionLoginPaper>
						</motion.div>
				}
			</AnimatePresence>

		</div>
	);
};

export default LoginCard;
