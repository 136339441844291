import { REQUEST_TYPES } from '@core/constants/cookie/cookie.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';

export const getCookiesRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_GET_COOKIES_CONFIG,
		...body,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateCookiesRequest = async (body) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_COOKIES_CONFIG,
		...body,
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, null, null, null);
};