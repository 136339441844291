import { composeWithDevTools } from '@redux-devtools/extension';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import rootReducer, { reducerMiddleware } from './rootReducer';
/**
 * Configure store sets the store for redux
 *
 * SessionStorage part is for the Fielder Session stored inside the browser,
 * everything else is pretty straightforward
 *
 * If do not understand anything, recommended videos
 * -> https://www.youtube.com/watch?v=iBUJVy8phqw
 * -> https://www.youtube.com/watch?v=poQXNp9ItL4
 * -> https://www.youtube.com/watch?v=FqSabub_yNI
 */

const composedEnhancers = composeWithDevTools({
	trace: true,
	traceLimit: 25,
});

export const store = configureStore(
	{
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== 'production',
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}).concat(reducerMiddleware),
	},
	{},
	composedEnhancers
);

/* optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
setupListeners(store.dispatch)
*/

export const persistor = persistStore(store);

export const getPersistedStore = () =>
	new Promise((res) => {
		persistStore(store, null, () => res(store));
	});
