import PrivacyPolicyUtil from 'FielderUtils/PrivacyPolicyUtil';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { buildSession } from 'FielderUtils/session/Session';

import { getAccountPreferences } from '../../services/customHooks/accountPreferencesUtils';
import { getUserRoles } from '../roles/utils';
import { getValidPages } from './pagePermissions';

export const handleLogin = (res) => {
	const { user, account, taskTypes, idToken, accessToken, refreshToken } = res;
	
	// Verify if Allow Access Client
	if (account && account.preferences) {
		user.allowAccessClient = account.preferences.allowAccessClient;
	} else {
		user.allowAccessClient = false;
	}
	const session = saveSession({ user, account });
	buildSession(idToken, accessToken, refreshToken);
	return { session, taskTypes };
};

const saveSession = (session) => {
	const { user } = session;

	if (
		user.acceptPrivacyPolicy === PrivacyPolicyUtil.ACCEPT_PRIVACY_POLICY ||
		RoleUtils.isUserClient(user) ||
		RoleUtils.isUserSuperAdministrator(user) ||
		RoleUtils.isClientGlobalAccountUser(user) ||
		RoleUtils.isRootGlobalAccountUser(user)
	) {
		const roles = getUserRoles(user.roles);
		
		const accountPreferences = getAccountPreferences(session);
		const validPages = getValidPages(session, accountPreferences, roles);

		session.validPages = validPages;
		session.accountPreferences = accountPreferences;
		session.roles = roles;

		return session;
	}
	if (user.acceptPrivacyPolicy === PrivacyPolicyUtil.REJECT_PRIVACY_POLICY) {
		// setShowExternalMessage({
		// 	session: session,
		// 	acceptPrivacyPolicy: true,
		// });
		const roles = getUserRoles(user.roles);
		const accountPreferences = getAccountPreferences(session);
		const validPages = getValidPages(session, accountPreferences, roles);

		session.validPages = validPages;
		session.accountPreferences = accountPreferences;
		session.roles = roles;

		return session;
	}
};
