import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import { selectUser, selectValidPages } from '@core/store/modules/session/sessionSelectors';

const PrivateRoute = ({ pageName, children }) => {
	const location = useLocation();
	const user = useSelector(selectUser);
	const validPages = useSelector(selectValidPages);

	if (!user) {
		return <Navigate to={{pathname: ENDPOINTS.login}} />
	}

	if (
		pageName === ENDPOINTS.globalAccountClientDashboard ||
		(pageName === ENDPOINTS.globalAccountClientDashboard && user?.role?.includes('client'))
	) {
		return <Navigate to={{ pathname: ENDPOINTS.myOperation, state: { from: location } }} />;
	} else if (validPages?.[pageName]?.isAllowed) {
		return children
	} else if (validPages?.[pageName]) {
		return <Navigate to={{ pathname: ENDPOINTS.forbidden, state: { from: location } }} replace />;
	} else {
		return <Navigate to={{ pathname: ENDPOINTS.notFound, state: { from: location } }} replace />;
	}
};

export default PrivateRoute;
