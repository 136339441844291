export enum REQUEST_TYPES {
	LOGIN = 'LOGIN',
	REFRESH_TOKEN = 'REFRESH_TOKEN',
	LOGIN_WITH_ID_USER = 'LOGIN_WITH_ID_USER',
	GET_USER_INFO_SWITCH_ACCOUNT = 'GET_USER_INFO_SWITCH_ACCOUNT',
	SEND_USER_PASSWORD = 'SEND_USER_PASSWORD',
	DO_ACTION_UPDATE_USER = 'DO_ACTION_UPDATE_USER',
	DISABLE_USER = 'DISABLE_USER',
	ENABLE_USER = 'ENABLE_USER',
	GET_USERS_TABLE = 'GET_USERS_TABLE',
	DO_ACTION_CHANGE_USER_STATUS = 'DO_ACTION_CHANGE_USER_STATUS',
	GET_USER_BY_ROLE_OPTIMIZE = 'GET_USER_BY_ROLE_OPTIMIZE',
	DO_ACTION_DELETE_USER = 'DO_ACTION_DELETE_USER',
	CREATE_USER = 'CREATE_USER',
	GET_USER_INFO_BY_ROLE = 'GET_USER_INFO_BY_ROLE',
	GET_ALL_CLIENTS_BY_FILTER = 'GET_ALL_CLIENTS_BY_FILTER',
	GET_CLIENTS_BY_FILTER = 'GET_CLIENTS_BY_FILTER',
	GET_CLIENTS_DEFAULT_OPTIONS = 'GET_CLIENTS_DEFAULT_OPTIONS',
	GET_CLIENT_SELECT = 'GET_CLIENT_SELECT',
	DO_ACTION_UPDATE_LOCATION_UPDATE = 'DO_ACTION_UPDATE_LOCATION_UPDATE',
	DO_ACTION_CREATE_USER = 'DO_ACTION_CREATE_USER',
}

