import React, { useState, useEffect } from 'react';

import { getTimezoneFromLocation } from 'FielderUtils/location/location';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import GoogleMapsAutocomplete from '~/GoogleMapsAutocomplete/GoogleMapsAutocomplete.component';
import { Col } from 'react-bootstrap';
import Geocode from 'react-geocode';
import { useDispatch, useSelector } from 'react-redux';

import { selectCenter } from '../../core/store/modules/map/mapSelectors';
import { setZoom, setCenter, setRestartCenter, setRoute } from '../../core/store/modules/map/mapSlice';
import Map from '../Map/Map.component';
import LocationSearch from './components/LocationSearch';
import { MapContainer } from './styled';

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage('en');

const EMPTY_LOCATION = {
	address: '',
	state: '',
	postalCode: '',
	country: '',
	timezone: '',
};

const getGeocode = async (coords) => {
	if (!coords) {
		return null;
	}

	const location = await Geocode.fromLatLng(coords.lat, coords.lng);
	return location.results[0];
};

const handleNewLocation = async (place, coords) => {
	const newLocation = {};

	newLocation.timezone = await getTimezoneFromLocation(coords.lat, coords.lng);
	newLocation.address = place.formatted_address;

	place.address_components.forEach((addressComponent) => {
		if (addressComponent.types[0] === 'administrative_area_level_1') {
			newLocation.state = addressComponent.long_name;
		}

		if (addressComponent.types[0] === 'country') {
			newLocation.country = addressComponent.long_name;
		}

		if (addressComponent.types[0] === 'postal_code') {
			newLocation.postalCode = addressComponent.long_name;
		}
	});

	return newLocation;
};

const Location = (props) => {
	const {
		height,
		label,
		edit,
		locationInfo,
		coordinates,
		locationUpdate,
		error,
		includeCoordinates,
		showDirection,
		markers,
	} = props;

	const dispatch = useDispatch();
	const center = useSelector(selectCenter);

	const updateLocationFromCoords = async (coords) => {
		const geocodeLocation = await getGeocode(coords);

		if (geocodeLocation !== null) {
			setMarker((m) => ({ ...m, marker: { position: { lat: coords.lat, lng: coords.lng } } }));
			const newLocation = await handleNewLocation(geocodeLocation, coords);
			setLocation(newLocation);
			dispatch(setCenter(coords));
		}
	};

	const [markersMap, setMarkersMap] = useState(markers);
	const [location, setLocation] = useState(EMPTY_LOCATION);
	const [marker, setMarker] = useState(null);

	useEffect(() => {
		if (locationInfo) {
			return;
		}

		console.log("markers: ", markers)

		if (markers && markers.length > 0) {
			setMarkersMap(markers);
		} else {
			const markerData = {
				config: {
					draggable: edit,
					onDragEnd: updateLocationFromCoords,
				},
				marker: {
					position: {
						lat: 0,
						lng: 0,
					},
				},
			};

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const { latitude, longitude } = position.coords;
						markerData.marker.position.lat = latitude;
						markerData.marker.position.lng = longitude;
						setMarker(markerData);
					},
					() => {
						setMarker(markerData);
					}
				);
				setMarker(markerData);
			} else {
				setMarker(markerData);
			}
			setMarkersMap([]);
		}
	}, [markers]);

	useEffect(() => {
		if (markers && markers.length > 0 && marker != null) {
			setMarker(null);
		}
	}, [marker]);

	useEffect(() => {
		dispatch(setRoute(null));

		if (!locationInfo) {
			dispatch(setRestartCenter());
			dispatch(setZoom(2));
		} else {
			const coords = {
				lat: parseFloat(locationInfo.lat),
				lng: parseFloat(locationInfo.lng),
			};

			dispatch(setCenter(coords));
			setMarker((m) => ({ ...m, marker: { position: coords } }));

			let address = '';
			if (locationInfo.newDirection) {
				address = locationInfo.newDirection;
			} else if (locationInfo.address) {
				address = locationInfo.address;
			}

			setLocation({
				address,
				state: locationInfo.state,
				postalCode: locationInfo.postalCode,
				country: locationInfo.country,
				timezone: locationInfo.timezone ? locationInfo.timezone : locationInfo.timeZone,
			});
		}
	}, [locationInfo]);

	const locationUpdateFromCoordinates = async (location) => {
		const geocodeLocation = await getGeocode(location.coordinates);
		if (geocodeLocation !== null) {
			const completeLocation = await handleNewLocation(geocodeLocation, location.coordinates);
			locationUpdate(completeLocation, marker.marker.position)
		}
	};

	useEffect(() => {
		if ('address' in location && location.address != '') {
			if (marker && marker.marker) {
				locationUpdate(location, marker.marker.position);
			}
		} else {
			locationUpdateFromCoordinates(location);
		}
	}, [location]);

	useEffect(() => {
		setMarker((m) => ({
			...m,
			config: {
				draggable: edit,
				onDragEnd: updateLocationFromCoords,
			},
		}));
	}, [edit]);

	useEffect(() => {
		if (coordinates && parseFloat(coordinates.lat) !== 0 && parseFloat(coordinates.lng) !== 0) {
			dispatch(
				setCenter({
					lat: parseFloat(coordinates.lat),
					lng: parseFloat(coordinates.lng),
				})
			);
			// dispatch(setZoom(15));
		}
	}, [coordinates]);

	const selectLocation = async (location) => {
		if (location) {
			const { coordinates } = location;
			setMarker((m) => ({ ...m, marker: { position: { lat: coordinates.lat, lng: coordinates.lng } } }));
			location.timezone = await getTimezoneFromLocation(coordinates.lat, coordinates.lng);
			setLocation(location);
			dispatch(setCenter(coordinates));
		} else {
			locationUpdate(EMPTY_LOCATION, { lat: 0, lng: 0 });
		}
	};

	return (
		<>
			<Col sm={12} className='mb-4'>
				<MapContainer height={height}>
					<Map marker={marker} markers={markersMap} />
				</MapContainer>
			</Col>
			{includeCoordinates && showDirection === false && (
				<Col sm={12} className='branch-label-input'>
					{label}
				</Col>
			)}

			<Col>
				{includeCoordinates ? (
					<LocationSearch
						address={location.address}
						selectLocation={selectLocation}
						disabled={!edit}
						label={label}
						error={error}
						coordinates={center}
					/>
				) : showDirection === false ? (
					<GoogleMapsAutocomplete
						id='branch-googleMapsAutocomplete'
						value={location.address}
						onSelect={selectLocation}
						disabled={!edit}
						label={label}
						error={error}
					/>
				) : null}
			</Col>
		</>
	);
};

export default Location;
