// ROLE MANAGEMENT
const PAGE_NAME = 'FORMS';
const FORMS = 'forms';
const NAME = 'forms';
const ACCOUNT_PREFERENCES = 'showForms';
const VIEW = 0;
const VIEW_TEMPLATES = 0;
const EDIT_TEMPLATES = 1;
const CREATE_TEMPLATES = 2;
const DELETE_TEMPLATES = 3;
const EDIT_TASK_FORM = 4;
const DELETE_TASK_FORM = 5;
const DOWNLOAD_TASK_FORM = 6;
const IMPORT_TEMPLATE = 7;

export default {
	PAGE_NAME,
	FORMS,
	NAME,
	ACCOUNT_PREFERENCES,
	VIEW,
	VIEW_TEMPLATES,
	EDIT_TEMPLATES,
	CREATE_TEMPLATES,
	DELETE_TEMPLATES,
	EDIT_TASK_FORM,
	DELETE_TASK_FORM,
	DOWNLOAD_TASK_FORM,
	IMPORT_TEMPLATE,
};
