import { REQUEST_TYPES } from '@core/constants/task/task.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// POST
export const uploadTaskDocumentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.UPLOAD_TASK_DOCUMENT,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// GET
export const getTaskDocumentsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_TASK_DOCUMENTS,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// PUT
export const updateTaskDocumentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.UPDATE_TASK_DOCUMENT,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

// DELETE
export const deleteTaskDocumentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DELETE_TASK_DOCUMENT,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
}

