import React, { useState, useEffect } from 'react';

import { DirectionsRenderer } from '@react-google-maps/api';
import { useLanguage } from 'Context/LanguageContext';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { vehicleToTravelMode } from '../../../core/constants/agent/vehicleTypes.constants';
import { selectRoute } from '../../../core/store/modules/map/mapSelectors';
import { setRoute } from '../../../core/store/modules/map/mapSlice';
import RouteInfoWindow from './RouteInfoWindow';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';

const getDuration = (time) => {
	let duration = moment.utc(moment.duration(time, 'seconds').asMilliseconds()).format('HH:mm');
	return `${duration} hr`;
};

const getDistance = (meters) => {
	let kilometers = meters / 1000;
	return `${kilometers} km`;
};

const Route = () => {
	const route = useSelector(selectRoute);
	const dispatch = useDispatch();
	const lan = useLanguage();
	const fielderMsg = useFielderSnackBar();

	const [routeDirection, setRouteDirection] = useState({
		suppressMarkers: true,
		polylineOptions: {
			strokeColor: '#e1616c',
			strokeOpacity: 0.85,
			strokeWeight: 5,
		},
		directions: null,
	});
	const [infoWindow, setInfoWindow] = useState({
		position: null,
		info: null,
	});

	const handleDirections = async (taskRoute) => {
		try {
			const routeDirections = await getRouteDirections(taskRoute);
			
			if (routeDirections !== null) {
				setRouteDirection({ ...routeDirection, directions: routeDirections.directions });
				setInfoWindow(routeDirections.infoWindow);
			} else {
			}
		} catch (e) {
			fielderMsg({title: lan.route, message: lan.taskRouteReturnedNoResults, 
				variant: 'warning', closeButton: true, duration: 5000});
		}
	};

	useEffect(() => {
		if (route == null) {
			setRouteDirection({ ...routeDirection, directions: null });
			setInfoWindow({ position: null, info: null });
		} else {
			handleDirections(route);
		}
	}, [route]);

	const closeRoute = () => {
		dispatch(setRoute(null));
	};

	const getRouteDirections = async (route) => {
		if (route == null) {
			return null;
		}

		const directionsService = new window.google.maps.DirectionsService();
		const request = {
			origin: route.origin,
			destination: route.destination,
			waypoints: route?.waypoints,
			optimizeWaypoints: true,
			travelMode: vehicleToTravelMode(route.travelMode),
		};

		return new Promise((resolve, reject) =>
			directionsService.route(request, (response, status) => {
				if (status === window.google.maps.DirectionsStatus.OK) {
					let midSteps = Math.floor(response.routes[0].legs[0].steps.length / 2);
					let midRoute = response.routes[0].legs[0].steps[midSteps].start_location;

					let infoWindow = {
						distance: getDistance(response.routes[0].legs[0].distance.value),
						duration: getDuration(response.routes[0].legs[0].duration.value),
						position: {
							lat: midRoute.lat(),
							lng: midRoute.lng(),
						},
					};

					resolve({ directions: response, infoWindow: infoWindow });
				} else {
					reject(Error());
				}
			})
		);
	};

	if (!routeDirection.directions) {
		return null;
	}
	return (
		<>
			<DirectionsRenderer options={routeDirection} />
			{infoWindow.position && (
				<RouteInfoWindow
					infoWindow={infoWindow}
					closeRoute={closeRoute}
					travelMode={route.travelMode}
					task={route.task}
					agent={route.agent}
				/>
			)}
		</>
	);
};

export default Route;
