import { REQUEST_TYPES } from '@core/constants/mail/mail.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';

export const createIntegrationAccountEmailRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ACTIVATE_EMAIL_ACCOUNT,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};
	return handlePostRequest(API_LINKS.accountManager, requestBody, onSuccess, onError, loadingConfig);
};

export const sendImportEmailRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		...body
	};
	return handlePostRequest(API_LINKS.topMenuShareApp, requestBody, onSuccess, onError, loadingConfig);
};


export const shareAppRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		args: [`${user.name} ${user.lastName}`],
		locale: user.language,
		mailTemplate : 'share_app',
		...body
	};
	return handlePostRequest(API_LINKS.topMenuShareApp, requestBody, onSuccess, onError, loadingConfig);
};


