import React from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Graph from './Graph';

var THEMEONE = "THEMEONE";
var THEMETWO = "THEMETWO";


function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color("#242C39"), //9013fe
			am4core.color("#8A989C"),
			am4core.color("#6D8F97"),
			am4core.color("#B6C3D0"), //ce2b66
            am4core.color("#4A5865"),
		];
	}
}

//am4core.useTheme(am4themes_myTheme);


function am4themes_millenial(target) {

  if (target instanceof am4core.ColorSet) {

      target.list = [
          am4core.color("#9013fe"),
          am4core.color("#51a5de"),
          am4core.color("#f52394"),
          am4core.color("#ce2b66"),
          am4core.color("#dc5c87")

      ];

  }

}


class StackedColumn extends Graph{
    constructor(data,props){
        super(props);
        this.props = props;
        this.data = data;
    }

    createGraph(params){

        let theme = params.theme
        am4core.unuseAllThemes();

        if(theme == THEMEONE ){

            am4core.useTheme(am4themes_empresarial);
          }
        else if(theme == THEMETWO ){

            am4core.useTheme(am4themes_millenial);
        }

        let chart = am4core.create(params.id, am4charts.XYChart);
        //chart.hiddenState.properties.opacity = 0;
        //chart.colors.step = 1;
        //chart.padding(30, 30, 10, 30);


        let category = params.category;

        let value1= params.value1;
        let value2= params.value2;
        let value3= params.value3;
        let value4= params.value4;

        let serie1 = params.serie1;
        let serie2 = params.serie2;
        let serie3 = params.serie3;
        let serie4 = params.serie4;


        let textTypeOfTask = params.textTypeOfTask;
        let dateFromDateTo = params.dateFromDateTo;
        let textTotalTask = params.textTotalTask;
        let total= params.total

        chart.data = this.data;

		// Create axes
		super.setAxesStackedColumn(chart, category,textTypeOfTask,dateFromDateTo,textTotalTask,total);

        //Series

		super.setStackedColumnSeriesParams(chart, category,value1,value2,value3,value4,serie1,serie2,serie3,serie4);


		var legend = params.legend;
		super.setChartProperties(chart, legend, true,false);

        this.chart = chart;
    }

    getGraph(){
        return this.chart;
    }
}

export default StackedColumn