import { createSelector } from '@reduxjs/toolkit';

import { store, getPersistedStore } from '../../store';

const sessionState = (state) => state.utils.session;

export const selectSession = createSelector(sessionState, (session) => session);

// Retrieve the Session state specific objects
export const selectUser = createSelector(sessionState, (session) => session.user);
export const selectAccount = createSelector(sessionState, (session) => session.account);
export const selectRequest = createSelector(sessionState, (session) => session.request);
export const selectValidPages = createSelector(sessionState, (session) => session.validPages);
export const selectRoles = createSelector(sessionState, (session) => session.roles);
export const selectAccountPreferences = createSelector(sessionState, (session) => session.accountPreferences);

// TODO: This is until getFielderSession() is removed from all the components
export const getSession = async () => {
	const storeApp = store || await getPersistedStore();
	const state = storeApp.getState();

	if (state) {
		return sessionState(state);
	}
	return null;
};

export const getSelectUser = async () => {
	if (process.env.NODE_ENV !== 'sandbox' || process.env.NODE_ENV !== 'production') {
	}

	return selectUser(store.getState())
};
