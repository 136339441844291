import React, { useState } from 'react';

import exportData from '@assets/lotties/export-loader.json';
import importData from '@assets/lotties/import-loader.json';
import animationData from '@assets/lotties/loadingFielder.json';
import { selectLoadingConfig } from '@core/store/modules/loading/loading.selector';
import { Grid } from '@mui/material';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import LoadingConst from './LoadingConst';
import './Loading.css';
import 'FielderUtils/style.css';

const getAnimationData = (icon) => {
	if (icon === LoadingConst.IMPORT) {
		return importData;
	}
	if (icon === LoadingConst.EXPORT) {
		return exportData;
	}
	return animationData;
};

const getOptions = (icon) => ({
	loop: true,
	autoplay: true,
	animationData: getAnimationData(icon),
});

const Loading = () => {
	const { icon, text, progress, allowTopBar } = useSelector(selectLoadingConfig);

	const [isStopped, setStopped] = useState(false);
	const [isPaused, setPaused] = useState(false);
	const [options, setOptions] = useState({ loop: false, autoplay: false, animationData: null });

	React.useEffect(() => {
		setOptions(getOptions(icon));
	}, [icon]);

	return (
		<div id='fielder-loading' className={allowTopBar ? 'Loading Loading-with-navigation' : 'Loading'}>
			<Grid container direction='column' justifyContent='center' alignItems='center' spacing={8}>
				<Grid container direction='row' justifyContent='center' alignItems='center' className='my-4'>
					<Grid item xs={6}>
						<Lottie options={options} isStopped={isStopped} isPaused={isPaused} width='20%' height='20%' />
					</Grid>
				</Grid>

				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Grid item xs={6} className='text-center'>
						{text && <span>{text}</span>}
					</Grid>
				</Grid>

				{progress !== undefined && progress != null && Math.round(progress) < 100 && (
					<Grid container direction='row' justifyContent='center' alignItems='center'>
						<Grid item xs={8}>
							<div className='col-8 mx-auto'>
								<div className='progress'>
									<div
										className='progress-bar active progress-bar-striped'
										role='progressbar'
										label={`${progress}%`}
										aria-valuenow={progress}
										aria-valuemin='0'
										aria-valuemax='100'
										style={{ width: `${progress}%` }}
									/>
								</div>
							</div>
							<div className='col-8 mx-auto'>
								<div className='d-flex justify-content-center'>
									<span className='percentageFontSize'>{`${Math.round(progress)}%`}</span>
								</div>
							</div>
						</Grid>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

Loading.defaultProps = {
	allowTopBar: false,
	progress: null,
	text: '',
	icon: null,
};

export default Loading;
