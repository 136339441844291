import React, { useEffect, useRef, useState } from 'react';
import { AddAgentIcon, DeepSpaceGrey } from '@core/theme/baseTheme';
import { DashboardPaper, MotionGrid } from '../Dashboard.styles';
import MapComponent from '~/Map/Map.component';
import { SmallTitle } from '~/SmallTitle/SmallTitle.component';
import AgentDetailContainer from 'OldComponents/Agents/AgentDetailContainer';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { motion } from 'framer-motion';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { useModal } from '~/FielderElements/ModalContext';
import Modal from '~/FielderElements/Modal';
import { FIELDER } from '@core/constants/base.constants';

const { pop: POP_ID } = FIELDER.idElement;
const POP_DETAIL_AGENT = POP_ID.agentMap.replace('{0}', 'editAgentModal');
const NoLocationAnimation = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
};
const MapAnimations = {
	hidden: {
		opacity: 0,
		y: 300,
	},
	visible: {
		opacity: 1,
		y: 0,
	},
	exit: {
		opacity: 0,
		y: -300,
	},
	transition: {
		duration: 0.25,
	},
};
const AgentMap = ({ agents }) => {
	const { openModal, closeModal, setContent } = useModal();
	const [markers, setMarkers] = useState([]);
	const lan = useLanguage();
	const mapRef = useRef();
	useEffect(() => {
		if (agents.length > 0) {
			const newMarkers = handleAgentMarkers(agents);
			setMarkers(newMarkers);
			if (mapRef.current != null) {
				mapRef.current.fitMap();
			}
		}
	}, [agents]);
	const hideDetailAgent = () => {
		if (mapRef.current != null) {
			mapRef.current.fitMap();
		}
		closeModal(POP_DETAIL_AGENT);
	};
	const handleAgentMarkers = (agents) => {
		return agents
			.filter(agent => agent.geolocation)
			.map(agent => ({
				markers: [{
					icon: agent.pin,
					title: `${agent.fullName}`,
					onClick: () => {
						const modalContent = (
							<AgentDetailContainer 
								lan={lan} 
								idAgent={agent.idAgent} 
								showAction={hideDetailAgent} 
							/>
						);	
						setContent(POP_DETAIL_AGENT, modalContent);
						openModal(POP_DETAIL_AGENT);
					},
					position: {
						lat: parseFloat(agent.geolocation.latitude),
						lng: parseFloat(agent.geolocation.longitud),
					},
				}],
			}));
	};
	return (
		<>
			<Modal
				id={POP_DETAIL_AGENT}
				title={lan.detailAgentTitle}
				icon={<AddAgentIcon sx={{ color: DeepSpaceGrey, width: '24px' }} />} 
				fullWidth={true}
			/>
			{agents.length > 0 && markers?.length > 0 ? (
				<motion.div
					key='dashboardAgentsMap'
					initial='hidden'
					exit='exit'
					animate='visible'
					transition={MapAnimations.transition}
					variants={MapAnimations}
				>
					<DashboardPaper className='my-3' sx={{ height: '20rem' }}>
						<MapComponent agentMarkers={markers} ref={mapRef} />
					</DashboardPaper>
				</motion.div>
			) : (
				<MotionGrid
					key='noLocationMessage'
					initial='hidden'
					exit='hidden'
					animate='visible'
					variants={NoLocationAnimation}
					justifyContent='center'
					alignItems='center'
					className='my-5 text-center'
				>
					<SmallTitle>
						<LocationOffIcon />
						{lan.agentsSelectedHaveNoLocation}
					</SmallTitle>
				</MotionGrid>
			)}
		</>
	);
};
export default AgentMap;
