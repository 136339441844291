import React, { Component } from 'react';

import ClockIcon from '@assets/icons/clock-icon.svg';
import ProfileImage from '@assets/icons/default-profile-icon.svg';
import ExportIcon from '@assets/icons/import-icon.svg';
import { Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';

import './TaskDetails.css';
import { Grid } from '@mui/material';
/**
 * Contains the Activity content from the {@link TaskDetails} component
 */
class TaskActivity extends Component {
	constructor(props) {
		super(props);

		var exportableData = [];
		var activityTask = this.props.taskActivity;
		var userType = '';
		var userName = '';

		if (activityTask != undefined && activityTask != null && activityTask.length > 0) {
			for (var i = 0; i < activityTask.length; i++) {
				if (activityTask[i].agent) {
					userName = activityTask[i].agent;
					userType = this.props.lan.agent;
				} else if (activityTask[i].user) {
					userName = activityTask[i].user;
					userType = this.props.lan.user;
				}
				exportableData.push({
					hour: activityTask[i].time,
					date: activityTask[i].date,
					activity: activityTask[i].activity,
					user: userName,
					userType: userType,
				});
			}
		}

		this.state = {
			showCVS: false,
			activityHeaders: [
				{ label: this.props.lan.hour, key: 'hour' },
				{ label: this.props.lan.date, key: 'date' },
				{ label: this.props.lan.activity, key: 'activity' },
				{ label: this.props.lan.user, key: 'user' },
				{ label: this.props.lan.type, key: 'userType' },
			],
			exportableData: exportableData,
		};
	}

	isEquivalent(a, b) {
		// Create arrays of property names
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);

		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length != bProps.length) {
			return false;
		}

		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i];

			// If values of same property are not equal,
			// objects are not equivalent
			if (a[propName] !== b[propName]) {
				return false;
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

	/**
	 * Helper for the Labels to reduce code written
	 *
	 * @param {*} props - Text for the label and text
	 */
	Label = (props) => (
		<Row className={'my-2 ' + props.className}>
			<div className={'col-auto label-input ' + props.labelClass}>{props.label}</div>

			<div className='col'>
				<img
					src={ExportIcon}
					className='icon Fielder-icon-button'
					alt='Exportar'
					title={props.lan.export}
					style={{ marginTop: '-5px' }}
					//onClick={props.handleExportClients}
				/>
			</div>
		</Row>
	);

	/**
	 * Generates the user icon, username component
	 * by iterating over every user and removing repeated
	 * users
	 *
	 * @param {*} props - Image and User contained
	 */

	/**
	 * Generates the Activity Table by iterating
	 * over every element in the data found
	 *
	 * @param {*} props - Image and User contained
	 */
	ActivityTable = (props) => {
		var activityLog = props.taskActivity;

		if (activityLog != undefined && activityLog != null && activityLog.length >0) {

			function compare(a, b) {
				// Use toUpperCase() to ignore character casing
				const activityA = a.idActivity;
				const activityB = b.idActivity;

				let comparison = 0;
				if (activityA < activityB) {
					comparison = 1;
				} else if (activityA > activityB) {
					comparison = -1;
				}

				return comparison;
			}

			activityLog.sort(compare);

			// Get the Rows
			var rows = activityLog.map((activity, i) => {

				let user = '';
				let image = ProfileImage;

				if (activity.user) {
					user = activity.user;
					image = activity.userImage ? activity.userImage : ProfileImage;
				} else if (activity.agent) {
					user = activity.agent;
					image = activity.userImage ? activity.userImage : ProfileImage;
				}

				return (
					<tr key={'task-activity-' + i} style={{ height: '45px', border: '1px solid #e6ebf0' }}>
						<td className="task-activity-time-col">
							<img
								src={ClockIcon}
								key={activity.time + '-table-icon'}
								className='td-activity-table-pic mr-3 ml-3'
								alt=''
							/>
							<span className='td-activity-user-text my-auto' key={activity.time + '-time'}>
								{activity.time}
							</span>
						</td>
						<td className="task-activity-date-col" >
								{activity.date}
						</td>
						<td>
							{activity.showTooltip ? (
								<Tooltip title={activity.tooltipInfo}>
								<div className="row">
									<div className="col-auto pr-0">
										{activity.activity}
									</div>
								
									<div className="col-2 no-padding">
										<MoreHorizIcon/>
									</div>
								
								</div>
								</Tooltip>
							) :
							<>
								{activity.activity}
							</>
							}
						</td>
						<td>
							<Row className='my-auto'>
								<img src={image} key={user + '-table-img' + i} className='td-activity-table-pic mr-3' alt='' />
								<span className='td-activity-user-text my-auto' key={user + '-text' + i}>
									{user}
								</span>
							</Row>
						</td>
					</tr>
				);
			});

			// Return the table
			return (
				<table className='td-activity-table'>
					<tbody>{rows}</tbody>
				</table>
			);
		}
		else {
			return (
				<>
				</>
			);
		}
	};

	handleExportActivity = () => {
		this.exportableElement.link.click();
	};

	render() {
		return (
			<Grid container direction='row' spacing={2} className='px-5'>
				<Grid item xs='auto'>
					<div className='label-input'>{this.props.lan.history}</div>
				</Grid>
				<Grid item xs='auto'>
					<img
						src={ExportIcon}
						className='icon Fielder-icon-button'
						title={this.props.lan.export}
						style={{ marginTop: '-5px' }}
						onClick={this.handleExportActivity}
					/>

					<CSVLink
						ref={(input) => (this.exportableElement = input)}
						className='MyOperation-display-none'
						data={this.state.exportableData}
						headers={this.state.activityHeaders}
						filename={this.props.lan.taskActivities}
					/>
				</Grid>

				<Grid item xs={12} >
					<this.ActivityTable taskActivity={this.props.taskActivity} />
				</Grid>
			</Grid>
		);
	}
}

export default TaskActivity;
