/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const defaultUpdatedLocation = {
	center: null,
	radius: null,
	updatedBy: null,
	vertices: null,
	address: null,
	type: 1,
};

const initialState = {
	editing: {
		isochronous: null,
		oldIsochronous: null,
		show: false,
	},
	map: {
		updatedLocationData: defaultUpdatedLocation,
		showAll: true,
		infoWindow: null,
	},
};

const isochronousSlice = createSlice({
	name: 'isochronous',
	initialState,
	reducers: {
		setOldIsochronous: (state, action) => {
			state.editing.oldIsochronous = action.payload.isochronous;
			state.editing.show = action.payload.show;
		},
		setEditing: (state, action) => {
			state.editing.oldIsochronous = action.payload.isochronous;
			state.editing.isochronous = action.payload.isochronous;
			state.editing.show = action.payload.show;
		},
		setIsochronous: (state, action) => {
			state.editing.isochronous = action.payload.isochronous;
		},
		setShowAllIsochronous: (state, action) => {
			state.map.showAll = action.payload;
		},
		setUpdateLocation: (state, action) => {
			state.map.updatedLocationData = action.payload;
		},
		setUpdateLocationCenter: (state, action) => {
			state.map.updatedLocationData.center = action.payload.coordinates;
			state.map.updatedLocationData.address = action.payload.address
		},
		setUpdateLocationRadius: (state, action) => {
			state.map.updatedLocationData.radius = action.payload;
		},
		setUpdateLocationVertices: (state, action) => {
			state.map.updatedLocationData.vertices = action.payload;
		},
		setInfoWindow: (state, action) => {
			state.map.infoWindow = action.payload;
		},
	},
});

export const {
	setEditing,
	setIsochronous,
	setOldIsochronous,
	setShowAllIsochronous,
	setUpdateLocation,
	setUpdateLocationCenter,
	setUpdateLocationRadius,
	setUpdateLocationVertices,
	setInfoWindow,
} = isochronousSlice.actions;

export default isochronousSlice.reducer;
