export function getStatus(status) {
	if (status === 0) {
		return 'managing';
	} else if (status === 1) {
		return 'cancel';
	} else if (status === 2) {
		return 'finish';
	} else if (status === 3) {
		return 'waiting-for-agent';
	} else if (status === 4) {
		return 'in-progress';
	} else if (status === 5) {
		return 'executing';
	} else if (status === 6) {
		return 'start-route';
	} else if (status === 7) {
		return 'cancel-by-partner';
	} else if (status === 8) {
		return 'assigned-to-partner';
	} else if (status === 9) {
		return 'assigned-to-team';
	} else if (status === 10) {
		return 'arrived';
	} else if (status === 11) {
		return 'deleted';
	} else if (status === 12) {
		return 'autoassigning';
	} else if (status === 13) {
		return 'autoassigned';
	} else if (status === 14) {
		return 'autoassigned-failed';
	}
	return '';
}
