import React from 'react'

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DrivingIcon from '@mui/icons-material/DriveEta';
import TrailerIcon from '@mui/icons-material/LocalShipping';
import MotorcycleIcon from '@mui/icons-material/TwoWheeler';
import propTypes from 'prop-types';

export const BICYCLING = 1;
export const MOTORCYCLE = 2;
export const DRIVING = 3;
export const TRANSIT = 4;
export const TRAILER = 5;
export const WALKING = 6;

export const vehicleToTravelMode = (vehicle) => {
	vehicle = parseInt(vehicle);
	switch (vehicle) {
		case BICYCLING:
			return window.google.maps.DirectionsTravelMode.BICYCLING;
		case TRANSIT:
			return window.google.maps.DirectionsTravelMode.TRANSIT;
		case WALKING:
			return window.google.maps.DirectionsTravelMode.WALKING;
		case DRIVING:
		case TRAILER:
		case MOTORCYCLE:
		default:
			return window.google.maps.DirectionsTravelMode.DRIVING;
	}
};

export const VehicleIcon = ({ vehicle = DRIVING, className = '', color = '' }) => {
	const vehicleInt = parseInt(vehicle, 2);
	switch (vehicleInt) {
		case BICYCLING:
			return <DirectionsBikeIcon className={className} color={color} />;
		case TRANSIT:
			return <DirectionsBusIcon className={className} color={color} />;
		case WALKING:
			return <DirectionsRunIcon className={className} color={color} />;
		case TRAILER:
			return <TrailerIcon className={className} color={color} />;
		case MOTORCYCLE:
			return <MotorcycleIcon className={className} color={color} />;
		case DRIVING:
		default:
			return <DrivingIcon className={className} color={color} />;
	}
};

export const getVehicleText = (vehicle) => {
	const vehicleInt = parseInt(vehicle);
	switch (vehicleInt) {
		case BICYCLING:
			return 'bicycle';
		case TRANSIT:
			return 'bus';
		case WALKING:
			return 'walking';
		case TRAILER:
			return 'truck';
		case MOTORCYCLE:
			return 'motorcycle';
		case DRIVING:
		default:
			return 'car';
	}
};

VehicleIcon.defaultProps = {
	className: '',
	color: undefined,
};
VehicleIcon.propTypes = {
	vehicle: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
	className: propTypes.string,
	color: propTypes.string,
};
