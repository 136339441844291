import React, { useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { AccountPreferencesProvider } from 'Context/AccountPreferencesContext';
import { LanguageProvider } from 'Context/LanguageContext';
import { SideBarProvider } from 'Context/SideBarContext';
import { enableMapSet } from 'immer';
import { useSelector, useDispatch } from 'react-redux';

import { selectRoles, selectUser } from './core/store/modules/session/sessionSelectors';
import { setRoles } from './core/store/modules/session/sessionSlice';
import { getUserRoles } from './core/utils/roles/utils';
import FielderRoutes from './FielderRoutes';
import './client/utils/style.css';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack';
import { ModalProvider } from '~/FielderElements/ModalContext';
import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";

enableMapSet();
TagManager.initialize({ gtmId: process.env.GOOGLE_ANALITYCS_ID });
ReactGA.initialize(process.env.GOOGLE_TAG_MANAGER);

const App = () => {
	const user = useSelector(selectUser);
	const roles = useSelector(selectRoles);
	const dispatch = useDispatch();
	const theme = createTheme();

	useEffect(() => {
		if (user && !roles) {
			const newRoles = getUserRoles(user.roles);
			dispatch(setRoles(newRoles));
		}
	}, [user, roles, dispatch]);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={6}>
				<ModalProvider>
					<SideBarProvider>
						<LanguageProvider>
							<AccountPreferencesProvider>
								<CssBaseline />
								<FielderRoutes />
							</AccountPreferencesProvider>
						</LanguageProvider>
					</SideBarProvider>
				</ModalProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
