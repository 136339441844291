import React, { Component } from 'react';

import { GOOGLE_URL_WITH_LIBRARIES, GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';

import MapContainer from '../Map/Map-container';
import Popup from '../Popup';

import 'FielderUtils/style.css';
import './RouteDetails.css';

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

let markerInfo;

class EditRouteEndPoint extends Component {
	constructor(props) {
		super();

		var endPoint = {
			state: '',
			country: '',
			postalCode: '',
			timeZone: '',
			address: '',
			latitude: '',
			longitud: '',
		};

		var latitude = 19.3916915;
		var longitud = -99.2809795;

		if (props.endPoint) {
			endPoint = props.endPoint;
			latitude = endPoint.latitude;
			longitud = endPoint.longitud;
		}

		this.state = {
			endPoint: endPoint,
			changedMapPosition: false,
			latitude: latitude,
			longitud: longitud,
		};
	}

	handleChangeDirection = (event) => {
		let state = this.state;
		state.endPoint.address = event.target.value;
		state.changedMapPosition = true;
		this.setState(state);
	};

	markerChanged = (cordinates) => {
		let state = this.state;

		state.latitude = cordinates.lat;
		state.longitud = cordinates.lng;
		state.endPoint.latitude = cordinates.lat + '';
		state.endPoint.longitud = cordinates.lng + '';
		state.changedMapPosition = true;
		this.setState(state);

		Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
			(response) => {
				const address = response.results[0].formatted_address;

				let state;
				let country;
				let postalCode;

				for (let i = 0; i < response.results[0].address_components.length; i++) {
					if (
						response.results[0].address_components[i].types[0] === 'administrative_area_level_1'
					) {
						state = response.results[0].address_components[i].long_name;
					}
					if (response.results[0].address_components[i].types[0] === 'country') {
						country = response.results[0].address_components[i].long_name;
					}
					if (response.results[0].address_components[i].types[0] === 'postal_code') {
						postalCode = response.results[0].address_components[i].long_name;
					}
				}

				let state2 = this.state;
				state2.endPoint.state = state;
				state2.endPoint.country = country;
				state2.endPoint.postalCode = postalCode;
				state2.endPoint.address = address;
				this.setState(state2);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	/*        Autocomplete cordinates            */
	onPlaceSelected = (place) => {
		const address = place.formatted_address;

		let state;
		let country;
		let postalCode;

		for (let i = 0; i < place.address_components.length; i++) {
			if (place.address_components[i].types[0] === 'administrative_area_level_1') {
				state = place.address_components[i].long_name;
			}

			if (place.address_components[i].types[0] === 'country') {
				country = place.address_components[i].long_name;
			}

			if (place.address_components[i].types[0] === 'postal_code') {
				postalCode = place.address_components[i].long_name;
			}
		}

		var latValue = place.geometry.location.lat();
		var lngValue = place.geometry.location.lng(); // Set these values in the state.

		// Update the state with the new location
		let state2 = this.state;
		state2.latitude = latValue;
		state2.longitud = lngValue;
		state2.endPoint.latitude = latValue + '';
		state2.endPoint.longitud = lngValue + '';
		state2.endPoint.state = state;
		state2.endPoint.country = country;
		state2.endPoint.postalCode = postalCode;
		state2.endPoint.address = address;
		this.setState(state2);
	};

	setMapCoodinates = () => {
		markerInfo = {
			coordinates: 'auto',
			pin: 'pink',
			draggable: !this.state.disabledInput,
		};
	};

	onSaveEndPoint = () => {
		this.props.onSaveEndPoint(this.state.endPoint);
	};

	render() {
		return (
			<React.Fragment>
				<Popup
					title={this.props.lan.routeEndPointTitle}
					icon='route-icon.svg'
					show
					width='lg'
					closeButton
					onClose={this.props.onClose}
					buttonText={this.props.lan.continue}
					buttonOnClick={this.onSaveEndPoint}
					size='lg'
				>
					<Row>
						<Col sm={12} className='TemplateAA-body-row-search'>
							{this.setMapCoodinates()}
							<MapContainer
								googleMapURL={GOOGLE_URL_WITH_LIBRARIES}
								loadingElement={<div style={{ height: `100%` }} />}
								containerElement={<div style={{ height: `350px` }} />}
								mapElement={<div style={{ height: `100%`, width: '100%' }} />}
								markersData={markerInfo}
								markerChanged={this.markerChanged}
								center={{
									lat: parseFloat(this.state.latitude),
									lng: parseFloat(this.state.longitud),
									force: this.props.endPoint != null,
									generic: this.props.endPoint == null,
								}}
								changedMapPosition
								zoom={15}
								smallMap
							/>
						</Col>
						<Col sm={12} className='mt-5 font-inputs'>
							<div className='input-group input-group-sm mb-3 Agent-fieldContainer'>
								<Autocomplete
									className='Fielder-modal-maps-autocomplete-style Fielder-cards-font-inputs'
									style={{ width: '100%' }}
									onPlaceSelected={(p) => this.onPlaceSelected(p)}
									options={{
										types: [],
									}}
									placeholder={' ' + this.props.lan.placeHolderAddDirection}
									value={this.state.endPoint.address}
									onChange={this.handleChangeDirection}
									id='direction-red-input'
									disabled={this.state.disabledInput}
								/>
							</div>
						</Col>
					</Row>
				</Popup>
			</React.Fragment>
		);
	}
}

export default EditRouteEndPoint;
