// ROLE MANAGEMENT
const PAGE_NAME = 'TEAMS';
const NAME = 'teams';
const TEAMS = 'teams';
const VIEW = 0;
const EDIT = 1;
const DISABLE = 2;
const DELETE = 3;
const VIEW_DISABLED = 4;
const CREATE = 5;

export default {
	PAGE_NAME,
	NAME,
	TEAMS,
	VIEW,
	EDIT,
	DISABLE,
	DELETE,
	VIEW_DISABLED,
	CREATE,
};
