
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import { handlePostRequest } from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';
import { REQUEST_TYPES } from '@core/constants/dashboard/request.constants';

export const getCompanyTaskToCashRequest = async (body) => {
	const user = await getSelectUser();

	const req = {
		dashboardRequestType: REQUEST_TYPES.GET_DASHBOARD_TASK_TO_CASH,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};

	return handlePostRequest(API_LINKS.dashboardManager, req, null, null);
};

export const getTaskToCashFlatRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const req = {
		dashboardRequestType: REQUEST_TYPES.GET_TASK_TO_CASH_FLAT,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};

	return handlePostRequest(API_LINKS.dashboardManager, req, onSuccess, onError, loadingConfig);
};