import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Popup from '../Popup';

export default function RemoveFormPopup(props) {
	return (
		<Popup
			closeButton
			onClose={() => props.onClose(false)}
			title={props.lan.removeForm}
			icon='alert-icon.svg'
			buttonText={props.lan.remove}
			buttonOnClick={() => props.removeFormTask()}
		>
			<Col sm={12} className='mt-2 Fielder-pink-color'>
				{props.lan.removeFormWarning}
			</Col>
		</Popup>
	);
}
