import React from 'react';

import { Container, Grid, Skeleton } from '@mui/material';
import { DashboardPaper, TotalNumberText } from '../Dashboard.styles';
import { SmallTitle } from '~/SmallTitle/SmallTitle.component';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const AgentTotalNumberCard = ({ total, isLoading }) => {
	const lan = useLanguage();

	const TotalNumber = React.useMemo(() => {
		if (isLoading) {
			return <Skeleton height={85} width='90%' />;
		}

		return <TotalNumberText>{total}</TotalNumberText>;
	}, [total, isLoading]);

	return (
		<DashboardPaper>
			<Container className='py-2'>
				<Grid item xs={12}>
					<SmallTitle>
						<Groups2Icon className='mr-2' />
						{lan.totalAgents}
					</SmallTitle>
				</Grid>

				<Grid container>
					<Grid item xs={9}>
						{TotalNumber}
					</Grid>
				</Grid>
			</Container>
		</DashboardPaper>
	);
};

export default AgentTotalNumberCard;
