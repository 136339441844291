import React from 'react';

import currenciesConstants from '@core/constants/currency/currencies.constants';
import { selectAccount } from '@core/store/modules/session/sessionSelectors';
import { useSelector } from 'react-redux';

const TaskTypePriceCell = ({ row }) => {
	const { taskType } = row;
	const { currency } = useSelector(selectAccount);
	const currencySymbol = currenciesConstants[currency].symbolNative;

	if (Array.isArray(taskType)) {
		return '';
	}

	return `${currencySymbol}${taskType.price.toLocaleString('en')}`;
};

export default TaskTypePriceCell;
