// ROLE MANAGEMENT
const PAGE_NAME = 'PARTNERS';
const NAME = 'partners';
const PARTNERS = 'partners';
const ADD = 0;
const VIEW = 1;
const DELETE_PARTNERSHIP = 2;
const ACCEPT_INVITATION = 3;

export default {
	PAGE_NAME,
	NAME,
	PARTNERS,
	ADD,
	VIEW,
	DELETE_PARTNERSHIP,
	ACCEPT_INVITATION,
};
