import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';

// ROLE MANAGEMENT
const PAGE_NAME = 'DASHBOARD';
const DASHBOARD = 'dashboard';
const NAME = 'dashboard';
const ENDPOINT = ENDPOINTS.dashboard;
const VIEW = 0;

export default {
	PAGE_NAME,
	NAME,
	DASHBOARD,
	ENDPOINT,
	VIEW,
};
