import React from 'react';

import { Drawer as MuiDrawer, ListItemIcon } from '@mui/material';
import { DRAWER_WIDTH } from '@core/theme/baseTheme';
import { styled } from '@mui/material/styles';

const openedMixin = (theme) => ({
	zIndex: 999,
	width: DRAWER_WIDTH,
	transition: theme.transitions.create('width', {
	  easing: theme.transitions.easing.sharp,
	  duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
  });

const closedMixin = (theme) => ({
	zIndex: 999,
	transition: theme.transitions.create('width', {
	  easing: theme.transitions.easing.sharp,
	  duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
	  width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Toolbar = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar, // necessary for content to be below app bar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: DRAWER_WIDTH,
	flexShrink: 0,
	zIndex: 999,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const SideMenuItem = styled(ListItemIcon)`
	color: #94979b;
`;

export { Drawer, SideMenuItem, Toolbar };
