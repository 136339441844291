import React, { useState, useEffect } from 'react';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { getIdToken } from 'FielderUtils/session/Session';
import { useSelector } from 'react-redux';

import LoggedMenu from './LoggedMenu';
import LoggedOutMenu from './LoggedOutMenu';

const TopMenu = () => {
	const user = useSelector(selectUser);

	const [isValidSession, setValidSession] = useState(false);

	useEffect(() => {
		setValidSession(!!user && getIdToken());
	}, [user]);

	return <>{isValidSession ? <LoggedMenu /> : <LoggedOutMenu />}</>;
};

export default TopMenu;
