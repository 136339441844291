import React, { Component } from 'react';

//Library
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import SearchIcon from '@assets/icons/search-icon.svg';
import SelectedIcon from '@assets/icons/shape.svg';
import { getTaskByIdRouteRequest } from '@core/api/route/route.service';
import { getStatus } from 'FielderUtils/status/statusmapper';
import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';
import { Modal, Row, Image, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import LightSpeed from 'react-reveal/LightSpeed';
import AsyncSelect from 'react-select/async';

import StatusFormatter from '../SmartTable/StatusFormatter';

import '../Managers/AddAdministrator.css';
import 'FielderUtils/style.css';
import 'rc-time-picker/assets/index.css';
import '../Register/RegisterComponentStyles.css';
import './RouteDetails.css';

var Request = require('FielderRequestTypes');

const LOC_TASK_TYPE = 'tasktype';
const LOC_ACTIVITY_TYPE = 'activity_type_';
var moment = require('moment-timezone');

class TaskSearchResultPopup extends Component {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);

		this.state = {
			showPopup: props.showPopup,
			search: props.search,
			items: [],
			markers: [],
			routePoints: [],
			editable: false,
			add: false,
			loading: true,
			position: 0,
			ellipse: 'ellipse-default',
			selected: false,
			itemsLoaded: false,
			indexArray: [],
			indexEllipse: [],
		};
	}

	getListStyle = (isDraggingOver) => ({
		background: 'white',
		padding: 8,
		height: '100%',
		width: '100%',
	});

	getItemStyle = (isDragging, draggableStyle) => ({
		// Cancel Selecting
		userSelect: 'none',
		MozUserSelect: 'none',

		padding: 16,
		margin: `0 0 8px 0`,

		background: isDragging ? '#4a4a4a4a' : 'white',
		...draggableStyle,
	});

	onChangeTaskPosition = (event) => {
		document.getElementById('alert-icon').style.display = 'none';
		this.setState({ position: event.target.value }, () => {this.validateTaskSelected();});
	};

	getTaskNumberSelect = () => {
		var items = [];
		items.push(100);
		for (let index = 0; index < this.props.sourceItems.length; index++) {
			items.push(this.props.sourceItems[index]);
		}
		items.push(this.props.sourceItems[0]);
		return items.map((item, i) => {
			if (item.status !== 2 && item.status !== 5 && item.status !== 6 && item.status !== 10) {
				return this.getOption(i);
			}
		});
	};

	getOption = (elemnt) => {
		if (elemnt == 0) {
			return <option value='0'>{this.props.lan.select}</option>;
		} else {
			const elemnt2 = elemnt;
			return <option value={elemnt2}>{elemnt2}</option>;
		}
	};

	getSeverityText = (severity) => {
		var severityText;

		if (severity == 1) {
			severityText = this.props.lan.highPriority;
		} else if (severity == 2) {
			severityText = this.props.lan.medPriority;
		} else if (severity == 3) {
			severityText = this.props.lan.lowPriority;
		}
		return severityText;
	};

	getSeverityClassName = (severity) => {
		var severityClassName;

		if (severity == 1) {
			severityClassName = 'severity-high';
		} else if (severity == 2) {
			severityClassName = 'severity-med';
		} else if (severity == 3) {
			severityClassName = 'severity-low';
		}
		return severityClassName;
	};

	formatDate = (dateInMillis) => {
		var formattedDate = moment(dateInMillis).format('DD-MM-YYYY HH:mm');

		return formattedDate;
	};

	onIdTaskChange = (event) => {
		let value = event.target.value;

		if (value && value !== '') {
			this.setState({ search: value });
		} else {
			this.setState({ search: undefined });
		}
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.setState({ tasks: [], items: [] });
			this.searchTasks();
		}
	};

	insertTask = (index) => {
		let myIndexArray = [];
		let myIndexEllipse = [];
		for (let i = 0; i < this.state.indexArray.length; i++) {
			myIndexArray.push(false);
			myIndexEllipse.push('ellipse-default');
		}

		if (this.state.position == 0) {
			document.getElementById('alert-icon').style.display = 'inline';
			document.getElementById('mySelect').focus();
		} else if (!this.state.indexArray[index]) {
			this.handleCloseModal(index);
		}

		if (this.state.indexArray[index]) {
			myIndexArray[index] = false;
			myIndexEllipse[index] = 'ellipse-default';
			this.setState({ indexEllipse: myIndexEllipse, indexArray: myIndexArray });
		} else {
			myIndexArray[index] = true;
			myIndexEllipse[index] = 'ellipse-selected';
			this.setState({ indexEllipse: myIndexEllipse, indexArray: myIndexArray });
		}
	};

	validateTaskSelected = () => {
		for (let index = 0; index < this.state.indexArray.length; index++) {
			if (this.state.indexArray[index]==true) {
				this.handleCloseModal(index);
			}
		}
	};

	renderTasks = () => {
		return (
			<div>
				<Row className='mx-3'>
					{/* SearchBar And Tasks */}
					<div className='col-sm-12'>
						{/* Hidden when Can edit position */}
						<Row className='rd-add-task'>
							{
								//this.state.editable && this.state.add &&
								<Col className='col-sm-8'>
									<LightSpeed left>
										<Col className='col-sm-8 Fielder-input-border my-auto '>
											<Row className='mt-1 justify-content-center row-sm-8'>
												<Image
													src={SearchIcon}
													className='fielder-icon mt-1 Fielder-icon-button'
													alt='Search'
												/>
												<input
													type='text'
													key='route-details-search'
													className='Fielder-input no-border ml-2 mt-1'
													placeholder={
														this.props.lan.searchBy +
														' ID ' +
														this.props.lan.and +
														' ' +
														this.props.lan.folio
													}
													style={{ width: '80%' }}
													onChange={this.onIdTaskChange}
													onKeyPress={this.handleKeyPress}
												/>
											</Row>
										</Col>
									</LightSpeed>
								</Col>
							}
							{/* (this.state.editable && (props.status != 4 && props.status != 5)) &&
                    <Col  className='my-auto'>
                        <Image src={AddIcon} className='Fielder-icon mb-1 '  onClick={this.validateAddTask} />
                    </Col>
            */}
						</Row>
						{
							//this.state.editable && this.state.add &&
							<Row>
								<Col className='col-auto'>
									<span>{this.props.lan.label_position_add_task}</span>
								</Col>
								<Col className='col-auto'>
									<select
										className='custom-select '
										id='mySelect'
										onChange={this.onChangeTaskPosition}
										value={this.state.position}
									>
										{this.getTaskNumberSelect()}
									</select>
								</Col>
								<Col className='col-auto mt-2'>
									<img src={redAlertIcon} alt='' id='alert-icon' className='rd-alert-icon'></img>
								</Col>
							</Row>
						}

						<Row className='mt-4'>
							<span className='col-sm-4 '>{this.props.lan.selectTask}</span>
						</Row>

						<Row>
							{/* Draggable space */}
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Scrollbars style={{ height: '550px' }} className='col-sm-12'>
									<Droppable droppableId='droppable' style={{ height: 'inherit' }}>
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={this.getListStyle(snapshot.isDraggingOver)}
												className='rd-droppable-bg2'
											>
												{/* List of elements */}
												<div className='row' style={{ maxWidth: '100%' }}>
													{this.state.items.map((element, i) => (
														<Draggable
															key={i}
															draggableId={element.idTask}
															index={i}
															style={{ width: '100%' }}
															isDragDisabled={!this.state.editable}
														>
															{(provided, snapshot) => (
																<div
																	className={
																		'col-sm-4 ' +
																		getStatus(element.status) +
																		' ' +
																		(this.state.editable && 'route-details-editable ')
																	}
																	ref={provided.innerRef}
																	{...provided.dragHandleProps}
																	{...provided.draggableProps}
																	style={this.getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																>
																	<Row className='col-sm-1 ml-auto no-padding'>
																		<button
																			type='submit'
																			className={this.state.indexEllipse[i]}
																			onClick={() => {
																				this.insertTask(i);
																			}}
																		>
																			{this.state.itemsLoaded && this.state.indexArray[i] && (
																				<img src={SelectedIcon} className='elli' alt='' />
																			)}
																		</button>
																	</Row>
																	<Col xs='auto'>
																		<Row className='ml-1 my-auto'>
																			<Col className='col-auto ml-3 no-padding'>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.idFielder}{' '}
																				</div>
																			</Col>
																			<Col className='col-auto ml-1 no-padding'>
																				<div className='rd-task-element-name'>
																					{' '}
																					{element.idTask}{' '}
																				</div>
																			</Col>
																			{!this.state.editable && (
																				<Col className='col rd-task-status'>
																					<StatusFormatter {...this.props} value={element.status} />
																				</Col>
																			)}
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col className='col-auto ml-3 no-padding'>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.folio}{' '}
																				</div>
																			</Col>
																			<Col className='col-auto no-padding ml-1'>
																				<div className='rd-task-element-name2'>
																					{' '}
																					{element.folio
																						? element.folio
																						: this.props.lan.noFolio}{' '}
																				</div>
																			</Col>
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col className='col-auto no-padding ml-3'>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.priority}{' '}
																				</div>
																			</Col>
																			<Col className='col-auto no-padding ml-1'>
																				<div
																					className={this.getSeverityClassName(element.severity)}
																				>
																					{' '}
																					{this.getSeverityText(element.severity)}{' '}
																				</div>
																			</Col>
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col sm={12} className='rd-task-element-act mt-2'>
																				{this.props.lan[LOC_TASK_TYPE + element.serviceTypeId]}
																			</Col>
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col sm={12} className='rd-task-element-id'>
																				{element.startTimeFormatted}
																			</Col>
																		</Row>

																		{element.user ? (
																			<Row className='ml-1 mt-3'>
																				<Col className='col-auto ml-3 no-padding'>
																					<div className='rd-task-element-id'>
																						{' '}
																						{this.props.lan.client}{' '}
																					</div>
																				</Col>
																				<Col className='col-auto no-padding ml-1'>
																					<div className='rd-task-element-name2'>
																						{' '}
																						{element.user.name + ' ' + element.user.lastName}{' '}
																					</div>
																				</Col>
																			</Row>
																		) : null}
																		{element.branch ? (
																			<Row className='ml-1 mt-3'>
																				<Col className='col-auto ml-3 no-padding'>
																					<div className='rd-task-element-id'>
																						{' '}
																						{this.props.lan.branch}{' '}
																					</div>
																				</Col>
																				<Col className='col-auto no-padding ml-1'>
																					<div className='rd-task-element-name2'>
																						{' '}
																						{(element.branch.idCustom
																							? element.branch.idCustom + ' '
																							: '') + element.branch.name}{' '}
																					</div>
																				</Col>
																			</Row>
																		) : null}
																		<Row className='ml-1 '>
																			<Col sm={12} className='rd-task-element-location'>
																				{element.startLocation.address}
																			</Col>
																		</Row>
																		<Row className='ml-1 mt-3'>
																			<Col className='col-auto '>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.originalDates}{' '}
																				</div>
																			</Col>
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col className='col'>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.startedFormattedDate}{' '}
																				</div>
																			</Col>
																			<Col className='col'>
																				<div className='rd-task-element-id'>
																					{' '}
																					{this.props.lan.finishedFormattedDate}{' '}
																				</div>
																			</Col>
																		</Row>
																		<Row className='ml-1 my-auto'>
																			<Col className='col'>
																				<div className='rd-task-element-name2'>
																					{' '}
																					{element.startDateTimeRange
																						? this.formatDate(element.startDateTimeRange)
																						: 'No date'}{' '}
																				</div>
																			</Col>
																			<Col className='col'>
																				<div className='rd-task-element-name2'>
																					{' '}
																					{element.endDateTimeRange
																						? this.formatDate(element.endDateTimeRange)
																						: 'No date'}{' '}
																				</div>
																			</Col>
																		</Row>
																	</Col>

																	{this.state.editable && (
																		<div className='col-sm-1 my-auto'>
																			<Image src={RepositionIcon} className='my-1' />
																			<Image
																				src={DeleteIcon}
																				className='my-1'
																				onClick={() => {
																					this.deleteTaskFromRoute(i);
																				}}
																			/>
																		</div>
																	)}
																</div>
															)}
														</Draggable>
													))}
												</div>
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</Scrollbars>
							</DragDropContext>
						</Row>
					</div>
				</Row>
			</div>
		);
	};

	setTaskToRouteResponse = (res) => {
		const routeResponse = res;
		var indexArray = [];
		var indexEllipse = [];

		let tasks = this.state.items;
		if (routeResponse.services) {
			for (let i = 0; i < routeResponse.services.length; i++) {
				routeResponse.services[i].added = true;
				tasks.push(routeResponse.services[i]);
				indexArray.push(false);
				indexEllipse.push('ellipse-default');
			}
			this.setState({
				loading: false,
				items: tasks,
				indexArray: indexArray,
				indexEllipse: indexEllipse,
				itemsLoaded: true,
				showTasksResult: true,
			});
		}
	};

	searchTasks = () => {
		const request = {
			method: 'POST',
			body: JSON.stringify(Request.getTaskByIdAndFolio(this.state.search)),
			headers: {
				'Content-type': 'application/json',
			},
		};

		let body = Request.getTaskByIdAndFolio(this.state.search);

		const onSuccess = (response) => {
			this.setTaskToRouteResponse(response);
		}

		const loadingConfig = { dispatch: this.props.dispatch }
		getTaskByIdRouteRequest(body, onSuccess, null, loadingConfig);

	};

	componentDidMount = () => {
		this.searchTasks();
	};

	/**
	 * @desc This function execute the funtion setDefaultFalse()
	 * in the parent component when the modal is closed
	 *
	 **/

	handleClose() {
		this.props.setDefaultFalse();
	}

	handleCloseModal(index) {
		this.props.setDefaultFalseModal(this.state.items[index], this.state.position - 1);
	}

	render() {
		return (
			<React.Fragment>
				<Modal
					show={this.state.showPopup}
					size='xl'
					className='Fielder-modals-header-border Fielder-modals-content-border'
					onHide={this.handleClose}
				>
					<Modal.Header
						className='Fielder-modals-header-border no-padding-bottom Fielder-modals-content-border'
						closeButton
					>
						<Modal.Title className='full-width'>
							{/*
                            <Row className="full-width ml-2 ">
                                <div className="col-auto padding-right">
                                    <Image src={AddManagerIcon} alt="" />
                                </div>
                                <div className="col-auto padding-lef mt-2">
                                    <p className="Fielder-modal-popUp-title padding-lef-title "> &nbsp; {this.state.titleCompany}</p>
                                </div>
                                <div className="col-auto padding-lef ">
                                    <a href="#Edit" onClick={this.handleEdit}>  <Image src={editImageIcon} className='align-center' /></a>
                                </div>
                            </Row> */}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='Fielder-body'>
							<div className=' ml-1'>
								<Row>
									<Col sm={'auto'} className='Fielder-cards-colum'></Col>
								</Row>
							</div>
						</div>
						{this.state.loading && <div className='route-details-search-loading-view'></div>}
						{this.state.showTasksResult && this.renderTasks()}
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
}

export default TaskSearchResultPopup;
