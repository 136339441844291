import React, { Component } from 'react';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { Row, Container, Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import AgentRoute from './AgentRoute';
import EditCreateDetailAgent from './EditCreateDetailAgent';
import MobileInformation from '../MobileInformation/MobileInformation';
import './EditCreateDetailAgent.css';
import 'FielderUtils/style.css';
class AgentDetailContainer extends Component {
	constructor(props) {
		super(props);
		var idAgent = 0;
		if (this.props.agent) {
			idAgent = this.props.agent.idAgent;
		} else {
			idAgent = this.props.idAgent;
		}

		var session = this.props.session;

		this.state = {
			tab: 'info',
			agent: this.props.agent,
			idAgent: idAgent,
			session: session,
			user: session.user,
			agentMobileInformation: {
				deviceName: '',
				deviceSystemVersion: '',
				fielderVersion: '',
				battery: '',
				locationPrecisePermission: '',
				locationApproximatePermission: '',
				locationBackgroundPermission: '',
			},
			displayModal:true
		};
	}
	changeDisplayModal = () => this.setState({displayModal:!this.state.displayModal})
	changeTab = (tab) => this.setState({ tab });
	isActive = (tab) => (this.state.tab === tab ? 'td-active' : '');
	handlerAgent = (agent) => this.setState({ agent });
	passMobileInformation = (agentMobileInformation) => {
		let state = this.state;
		state.agentMobileInformation.deviceName = agentMobileInformation
			? agentMobileInformation.deviceName
			: this.props.lan.noData;
		state.agentMobileInformation.deviceSystemVersion = agentMobileInformation
			? agentMobileInformation.deviceSystemVersion
			: this.props.lan.noData;
		state.agentMobileInformation.fielderVersion = agentMobileInformation
			? agentMobileInformation.fielderVersion
			: this.props.lan.noData;
		state.agentMobileInformation.battery = agentMobileInformation
			? agentMobileInformation.battery
			: 0;

		state.agentMobileInformation.locationPrecisePermission = this.props.lan.noData;
		state.agentMobileInformation.locationApproximatePermission = this.props.lan.noData;
		state.agentMobileInformation.locationBackgroundPermission = this.props.lan.noData;
		if (
			agentMobileInformation !== undefined &&
			agentMobileInformation !== null &&
			agentMobileInformation.locationPrecisePermission != null
		) {
			state.agentMobileInformation.locationPrecisePermission = this.props.lan.withoutAccepting;

			if (agentMobileInformation.locationPrecisePermission === true) {
				state.agentMobileInformation.locationPrecisePermission = this.props.lan.accepted;
			}
		}

		if (
			agentMobileInformation !== undefined &&
			agentMobileInformation !== null &&
			agentMobileInformation.locationApproximatePermission != null
		) {
			state.agentMobileInformation.locationApproximatePermission = this.props.lan.withoutAccepting;

			if (agentMobileInformation.locationApproximatePermission === true) {
				state.agentMobileInformation.locationApproximatePermission = this.props.lan.accepted;
			}
		}

		if (
			agentMobileInformation !== undefined &&
			agentMobileInformation !== null &&
			agentMobileInformation.locationBackgroundPermission != null
		) {
			state.agentMobileInformation.locationBackgroundPermission = this.props.lan.withoutAccepting;

			if (agentMobileInformation.locationBackgroundPermission === true) {
				state.agentMobileInformation.locationBackgroundPermission = this.props.lan.accepted;
			}
		}

		this.setState(state);
	};

	render() {
		try {
			return (
				<>
				<Row className='mx-3'>
					<Navbar className='td-navbar mb-3' style={{ zIndex: 9999, backgroundColor: 'white' }}>
						<Nav.Item
							id='nav-info'
							className={'td-nav-item text-center ' + this.isActive('info')}
							style={{ width: '50%' }}
						>
							<Nav.Link className='td-nav-title' onClick={() => this.changeTab('info')}>
								{this.props.lan.information}
							</Nav.Link>
						</Nav.Item>
						{!RoleUtils.isUserClient(this.state.user) && (
							<Nav.Item
								id='nav-act'
								className={'td-nav-item text-center ' + this.isActive('route')}
								style={{ width: '50%' }}
							>
								<Nav.Link className='td-nav-title' onClick={() => this.changeTab('route')}>
									{this.props.lan.geolocations}
								</Nav.Link>
							</Nav.Item>
						)}

						{!RoleUtils.isUserClient(this.state.user) && (
							<Nav.Item
								id='nav-act'
								className={'td-nav-item text-center ' + this.isActive('mobileInfo')}
								style={{ width: '50%' }}
							>
								<Nav.Link className='td-nav-title' onClick={() => this.changeTab('mobileInfo')}>
									{this.props.lan.mobileInformation}
								</Nav.Link>
							</Nav.Item>
						)}
					</Navbar>
				</Row>
				{this.state.tab === 'info' && (
					<Container style={{ minHeight: '400px' }}>
						<EditCreateDetailAgent
							{...this.props}
							showAction={this.props.showAction}
							editAgentAction={this.props.editAgentAction}
							skillsElegible={this.props.skillsElegible}
							agent={this.props.agent}
							lan={this.props.lan}
							updateParentAgent={this.handlerAgent}
							idAgent={this.state.idAgent}
							showEdit={this.props.showEdit}
							passMobileInformation={this.passMobileInformation}
							displayModal={this.state.displayModal}
							changeDisplayModal={this.changeDisplayModal}
						/>
					</Container>
				)}
				{this.state.tab === 'route' && !RoleUtils.isUserClient(this.state.user) && (
					<Container style={{ minHeight: '400px' }}>
						<AgentRoute {...this.props} agent={this.state.agent} />
					</Container>
				)}
				{this.state.tab === 'mobileInfo' && !RoleUtils.isUserClient(this.state.user) && (
					<Container style={{ minHeight: '300px' }}>
						<MobileInformation
							userMobileInformation={this.state.agentMobileInformation}
							isAgent
						/>
					</Container>
				)}
				</>
			);
		} catch(error) { console.log(error)}
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AgentDetailContainer);
