export const mapConfig = {
	id: `google-map-script`,
	googleMapsApiKey: process.env.GOOGLE_API_KEY,
	libraries: ['geometry', 'places'],
};

export const defaultCenter = {
	lat: 28.6659874,
	lng: -41.0840058,
};
