import React, { Component } from 'react';

import CalendarIcon from '@assets/icons/calendar-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';

class DatePickerFielder extends Component {
	updateDateInit = (dateSelected) => {
		this.props.updateDateInit(dateSelected);
	};

	updateDateEnd = (dateSelected) => {
		this.props.updateDateEnd(dateSelected);
	};

	render() {
		return (
			<Row style={{ border: 'solid 1px #e8e8e8d0 !important', borderRadius: '12px' }}>
				<Col sm={'auto'}>
					<img src={CalendarIcon} className='icon my-2' alt='' />
				</Col>
				<Col>
					<DatePicker
						id={this.props.id}
						selected={this.props.selected} //seleccionada fecha
						onChange={this.props.handleDate}
						minDate={this.props.minDate} //fecha minima para deshabilitar dias
						timeFormat={this.props.timeFormat}
						showTimeSelect
						showMonthDropdown
						showYearDropdow
						minTime={this.props.minTime}
						maxTime={this.props.maxTime}
						dateFormat={this.props.dateFormat} //Formato de fecha
						timeIntervals={this.props.timeIntervals} //Intervalo de tiempo en minutos
						className={this.props.classNamePicker}
						readOnly={this.props.readOnly}
					/>
					<img src={redAlertIcon} alt='' id={this.props.imgId} className={this.props.classNameImg}></img>
				</Col>
			</Row>
		);
	}
}

export default DatePickerFielder;
