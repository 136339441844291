const POPUPNAME = {
	RESCHEDULE_TASK: 'rescheduleTask',
	TASK_DETAIL: 'taskdetail',
	CANCEL_TASK: 'cancelTask',
	CREATE_GROUP_TASK: 'createGroupTask',
	EDIT_GROUP_TASK: 'editGroupTask',
	ASSIGN_GROUP_TASK: 'assignGroupTask',
	ROUTE_DETAIL: 'routedetail',
	ASSIGN_AGENT: 'assignAgent',
	ASSIGN_TEAM: 'assignTeam',
	ASSIGN_PARTNER: 'assignPartner',
	RE_ASSIGN_PARTNER: 'reAssignPartner',
	ASSIGN_ASSETS: 'assignAssets',
	ASSIGN_INVENTORIES: 'assignInventories',
	ASSIGN_FORMS: 'assignForms',
	RATE_TASK: 'rateTask',
	GENERATE_ROUTE: 'generateRoute',
	DUPLICATE_TASK: 'duplicateTask',
	PARENT_TASK_DETAIL: 'parenttaskdetail',
	CANCEL_ROUTE: 'cancelRoute',
	RESET_ROUTE_TASK: 'resetRouteTask',
	RESET_CAMCELED_TASK:'resetCanceledTask',
	RESTART_ROUTE: 'restartRoute',
	REASSIGN_ROUTE_AGENT: 'reassignRouteAgent',
	RESCHEDULE_ROUTE: 'rescheduleRoute',
	ASSIGN_RESOURCES: 'assignResources',
	IMPORT_TASK: 'importTask',
	ERROR_MESSAGE: 'errormessage',
	SUCCESS_MESSAGE: 'successmessage',
	EDIT_TASK: 'editTask',
	AGENT_DETAIL:'agentdetail'
};

export default POPUPNAME;