import Status from './TaskGroupStatus.constants';

export const StatusColor = {
	[Status.CREATED]: {
		background: '#9BC5F1',
		color: '#0F5099',
	},
	[Status.ON_WAY]: {
		background: '#96A3E5',
		color: '#314095',
	},
	[Status.ARRIVED]: {
		background: '#FFF188',
		color: '#E39C1F',
	},
	[Status.EXECUTING]: {
		background: '#CBB6EE',
		color: '#870DB9',
	},
	[Status.PAUSED]: {
		background: '#FDBB70',
		color: '#D87014',
	},
	[Status.FINISH]: {
		background: '#A2EAC9',
		color: '#0BA25F',
	},
	[Status.CANCELED]: {
		background: '#F6ABAB',
		color: '#C21010',
	},
	[Status.ON_TRASH]: {
		background: '#D1F7EF',
		color: '#20AD8F',
	},
};
