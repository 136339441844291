import { IndianRed, LightGreySubtitleColor, PersianGreen } from '@core/theme/baseTheme';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const CreateSkillsTitle = styled(Typography)`
	font-size: 14px !important;
	color: ${LightGreySubtitleColor};
`;

export const ChevronIconMotion = motion(ChevronRightIcon);
export const CompleteMessageMotion = styled(motion(Typography))`
	color: ${PersianGreen};
	font-size: 11px;
`;

export const ErrorMessageMotion = styled(motion(Typography))`
	color: ${IndianRed};
	font-size: 11px;
`;

export const SkillInputGrid = motion(Grid)
