import React from 'react';

import { Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '~/IconButton/IconButton.component';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingBouncingDots from '~/LoadingBouncingDots/LoadingBouncingDots.component';
import { getTaskToCashFlatRequest } from '@core/api/taskToCash/taskToCash.service';
import { useDispatch } from 'react-redux';
import { downloadXlsxFromTaskTypeTasksSummary } from '../utils';

const TaskToCashOptions = ({ onClickFilter, isLoading, filters }) => {
	const lan = useLanguage();
	const dispatch = useDispatch();

	const TaskToCashFilename = `${lan.taskToCashReportFilename}.xlsx`;

	const TaskSummaryHeaders = [
		lan.idCompany,
		lan.company,
		lan.idBranch,
		lan.branch,
		lan.taskType,
		lan.taskQuantity,
		lan.pricePerTask,
		lan.currency,
		lan.amountToCash,
		lan.tasksFinished,
		lan.progressOfTheProject,
		lan.contributionPercentage,
	];

	const handleDownloadXlsx = () => {
		const loadingConfig = { dispatch };

		const onError = console.error;
		const onSuccess = ({ taskTypeTasksToExport }) => {
			downloadXlsxFromTaskTypeTasksSummary(taskTypeTasksToExport, TaskSummaryHeaders, TaskToCashFilename);
		};
		return getTaskToCashFlatRequest(filters, onSuccess, onError, loadingConfig);
	};

	return (
		<Grid container justifyContent='end' spacing={2} alignItems='end' alignContent='end'>
			<Grid item xs='auto' className='mt-5'>
				{isLoading && <LoadingBouncingDots />}
			</Grid>
			<Grid item xs='auto' className='mt-5'>
				<IconButton id='download-button' label={lan.download} onClick={handleDownloadXlsx} icon={<DownloadIcon />} />
			</Grid>
			<Grid item xs='auto' className='mt-5'>
				<IconButton id='show-filters-button' label={lan.filter} icon={<FilterListIcon />} onClick={onClickFilter} />
			</Grid>
		</Grid>
	);
};

export default TaskToCashOptions;
