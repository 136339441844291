import React from 'react';

//Components
import './SmartTable.css';

const Checkbox = ({label,isSelected,onCheckboxChange,keyN}) =>(
    <React.Fragment >
        <input //render the checkbox
            type="checkbox"
            name= {label} //name of the checkbox and label
            id= {keyN}
            checked= {isSelected}  //the checkbox should be rendered selected or deselected
            onChange = {onCheckboxChange} //callback funtion that React call when the user select or deselects the checkbox
            className={label+" form-check-input labelCheckbox"}
            
        />
         <label htmlFor={keyN} className="active" ></label>
         <div className="tag" >{label}</div>

    </React.Fragment>);

export default Checkbox; 