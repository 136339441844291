import React, { useState } from 'react';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import { Col } from 'react-bootstrap';
import { useLanguage } from 'Context/LanguageContext';
import { DatePicker } from 'antd';
import moment from 'moment';

const MyOperationRangeDatePicker = (props) => {
	
	const lan = useLanguage();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const handleStartDateChange = (date, dateString) => {
		setStartDate(date);
		setEndDate(null);
		props.handleFromDateFromForm(date);
	};

	const handleEndDateChange = (date, dateString) => {
		setEndDate(date);
		props.handleToDateFromForm(date);
	}

	const disabledEndDate = (current) => {
		if (!startDate) {
		  return true;
		}
		const startDatePlus30Days = moment(startDate).add(30, 'days');
		return current && (current < startDate || current > startDatePlus30Days);
	};

	return (
		<>	
			<Col sm='auto'>
				<img
					src={CalendarIcon}
					className='icon my-2'
					alt=''
					onClick={() => showDayPickerInput('daypickerone')}
				/>
			</Col>
			<Col>
				<label>
				<DatePicker
					onChange={handleStartDateChange}
					placeholder={lan.initialDate}
					style={{ marginRight: '10px' }}
					
				/>
				<DatePicker
					value={endDate}
					disabledDate={disabledEndDate}
					onChange={handleEndDateChange}
					placeholder={lan.finalDate}
					style={{ marginLeft: '10px' }}
			
				/>
								
				</label>
			</Col>
	
		
		</>		
	);

}
export default MyOperationRangeDatePicker;