import React, { Component } from 'react';

import loadingLottie from '@assets/lotties/loadingFielder.json';
import { Grid, Tooltip } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import Lottie from 'react-lottie';
import { styled } from '@mui/material/styles';

const BarChart = styled(Bar)`
	max-height: 4rem !important;
	height: 4rem;
	width: 100% !important;
	z-index: 99;
`

class TasksMonthComponent extends Component {

	render() {
		return (
			<div className='Dashboard-tasksMonth Dashboard-box mt-3'>
				<div className='Dashboard-box-content'>
					<p className='Dashboard-box-title mb-2'>{this.props.lan.taskPlan}</p>

					{this.props.renderMonthTask ? (
						<Grid>
							<div className='row d-flex justify-content-center'>
								{this.props.showAlertMonthTask ? (
									<Tooltip title={this.props.lan.taskPlanAlert}>
										<p className='textTotalCount-Month mb-0 color-text-alert-month'>
											{`${this.props.tasksMonth.countTotalMonthTask} / ${this.props.tasksMonth.countGeneratedMonthTask} ${this.props.lan.total}`}
										</p>
									</Tooltip>
								) : (
									<p className='textTotalCount-Month mb-0'>
										{`${this.props.tasksMonth.countTotalMonthTask} / ${this.props.tasksMonth.countGeneratedMonthTask} ${this.props.lan.total}`}
									</p>
								)}
							</div>
							<BarChart
								data={this.props.taskPlanData}
								options={{
									offset: true,
									// height: '10',
									indexAxis: 'y',
									responsive: true,
									scales: {
										x: {
											stacked: true,
											display: false,
										},
										y: {
											stacked: true,
											display: false,
										},
									},
									plugins: {
										legend: {
											display: false,
										},
									},
								}}
							/>
						</Grid>
					) : (
						<div className='row'>
							<div className='col-12 d-flex justify-content-center no-padding marginLoadingTaskMini'>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: loadingLottie,
									}}
									height='40px'
									width='40px'
								/>
							</div>
							<div className='col d-flex justify-content-center no-padding'>{this.props.lan.loading}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default TasksMonthComponent;
