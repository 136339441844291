import React from 'react';

import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const ButtonUnderlined = styled(Button)(() => ({
	background: 'transparent',
	border: 'none',
	boxShadow: 'none',
	color: '#354052',
	fontFamily: 'Lato',
	fontSize: '12px',
	opacity: 0.8,
	textTransform: 'none',
	'text-decoration-line': 'underline',
	'&:hover': {
		color: 'red',
		'text-decoration-line': 'underline',
		opacity: 1,
	},
	'&:focus': {
		color: 'blue',
	},
}));

const UnderlinedButton = ({ children, onClick }) => {
	return (
		<ButtonUnderlined onClick={onClick} disableRipple>
			{children}
		</ButtonUnderlined>
	);
};

UnderlinedButton.prototype = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default UnderlinedButton;
