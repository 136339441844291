import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import FielderMarker from './FielderMarker';

const Markers = ({ markers, clusterer, enableAnimation }) => {
	const MarkerGroup = () =>
		// Get separated marker groups
		markers.map((markerGroup) => {
			if (markerGroup?.markers) {
				// Map the markers inside each group
				return markerGroup.markers.map((marker, i) => (
					<FielderMarker
						config={markerGroup?.config}
						marker={marker}
						clusterer={clusterer}
						key={'fielderMarker' + i}
						enableAnimation={enableAnimation}
					/>
				));
			}
			return null;
		});

	if (markers) {
		if (markers.length > 0) {
			return <MarkerGroup />;
		} else {
			return markers.markers.map((marker, i) => (
				<FielderMarker
					config={markers?.config}
					marker={marker}
					clusterer={clusterer}
					key={'fielderMarker-' + marker.id}
					enableAnimation={enableAnimation}
				/>
			));
		}

	}

	return null;
};

export default React.memo(Markers);
