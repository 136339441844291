import React, { useState, useEffect, useCallback } from 'react';

import { useLanguage } from 'Context/LanguageContext';
import Status from 'FielderUtils/status/routeStatus';
import { Col } from 'react-bootstrap';

//Utility
import 'FielderUtils/style.css';
import './style.css';

const RouteStatus = ({ value }) => {
	const lan = useLanguage();

	const [text, setText] = useState('');
	const [color, setColor] = useState('');

	const getStatusData = useCallback(() => {
		switch (value) {
			case Status.MANAGING:
				return {
					text: `taskStatus_0`,
					color: 'Fielder-task-managing',
				};
			case Status.WAITING_FOR_AGENT:
				return {
					text: `taskStatus_3`,
					color: 'Fielder-task-waiting-for-agent',
				};
			case Status.IN_PROGRESS:
				return {
					text: `taskStatus_4`, //In progress
					color: 'Fielder-task-in-progress',
				};
			case Status.EXECUTING:
				return {
					text: `taskStatus_5`,
					color: 'Fielder-task-executing',
				};
			case Status.CANCELLED:
				return {
					text: `taskStatus_1`,
					color: 'Fielder-task-cancel',
				};
			case Status.FINISHED:
				return {
					text: `taskStatus_2`,
					color: 'Fielder-task-finish',
				};
			case Status.REJECTED:
				return {
					text: `taskStatus_17`,
					color: 'Fielder-task-rejected',
				};
			default:
				return;
		}
	}, []);

	const setStatus = useCallback(() => {
		let status = getStatusData(value);
		if (status) {
			setColor(status.color);
			setText(lan[status.text]);
		}
	}, [value, getStatusData, lan]);

	useEffect(() => {
		setStatus(value);
	}, [value, setStatus]);

	return (
		<Col className={'mx-auto status-square text-center status-' + color}>
			<div>
				<span className='status-text '>{text}</span>
			</div>
		</Col>
	);
};

export default RouteStatus;
