import React from 'react';

import { Tooltip } from '@mui/material';
import { RowTitle, TaskTypeChip } from '../TaskToCashTable.styles';
import { getMissingChipsText } from '../TaskToCashTable.utils';

const TaskTypeCell = ({ row }) => {
	const { taskType } = row;

	const TooltipChip = () => {
		const missingTypesText = getMissingChipsText(taskType);
		return (
			<Tooltip key={`${row.id}-more-taskType-chip`} title={missingTypesText}>
				<TaskTypeChip label={`${taskType.length - 2} +`} />
			</Tooltip>
		);
	};

	const TaskTypeTags = () => {
		if (taskType.length > 3) {
			const taskTypeTags = [];

			for (let i = 0; i < 2; i++) {
				taskTypeTags.push(<TaskTypeChip key={`${row.id}-${taskType[i]}-chip`} label={taskType[i]} />);
			}

			taskTypeTags.push(<TooltipChip />);
			return taskTypeTags;
		}

		return taskType.map((type) => <TaskTypeChip key={`${row.id}-${type}-chip`} label={type} />);
	};

	if (Array.isArray(taskType)) {
		return <TaskTypeTags />;
	} else {
		return <RowTitle>{taskType.name}</RowTitle>;
	}
};

export default TaskTypeCell;
