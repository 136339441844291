import cloneDeep from 'lodash/cloneDeep';

const defaultComparisonValue = (element) => element.id || element;

const binarySearchElement = (arr, id, options) => {
	if (!arr || arr.length === 0) {
		return -1;
	}

	const mid = Math.floor(arr.length / 2);
	const val = options.customComparisonValue
		? options.customComparisonValue(arr[mid])
		: defaultComparisonValue(arr[mid]);

	// eslint-disable-next-line eqeqeq
	if (id == val) {
		return arr[mid];
	}

	if (id < val && arr.length > 1) {
		if (options.reversed) {
			return binarySearchElement(arr.splice(mid, arr.length), id, options);
		}
		return binarySearchElement(arr.splice(0, mid), id, options);
	}

	if (id > val && arr.length > 1) {
		if (options.reversed) {
			return binarySearchElement(arr.splice(0, mid), id, options);
		}
		return binarySearchElement(arr.splice(mid, arr.length), id, options);
	}

	return -1;
};

export const binarySearch = (arr = [], id = 0, options = {}) => binarySearchElement(cloneDeep(arr), id, options);

const binarySearchPosition = (arr, id, min, max, options) => {
	if (!arr || arr.length === 0 || min >= max) {
		return -1;
	}

	const mid = Math.floor(min + (max - min) / 2);
	const val = options.customComparisonValue
		? options.customComparisonValue(arr[mid])
		: defaultComparisonValue(arr[mid]);

	if (id === val) {
		return mid;
	}

	if (id < val && arr.length > 1) {
		if (options.reversed) {
			return binarySearchPosition(arr, id, mid + 1, max, options);
		}

		return binarySearchPosition(arr, id, min, mid, options);
	}

	if (id > val && arr.length > 1) {
		if (options.reversed) {
			return binarySearchPosition(arr, id, min, mid, options);
		}
		return binarySearchPosition(arr, id, mid + 1, max, options);
	}

	return -1;
};

export const binarySearchIndex = (arr = [], id = 0, options = {}) =>
	binarySearchPosition(arr, id, 0, arr.length, options);
