import React, { useRef, useCallback } from 'react';

import { convertGoogleLatLng } from '@core/utils/location/addressHandler.utils';
import { Circle as GoogleMapCircle } from '@react-google-maps/api';

const Circle = ({ value, onClick, globalVisible, globalEditable, defaultShow }) => {
	const { id, info, options } = value;
	const circleRef = useRef();

	const clickHandler = options.onClick ? options.onClick : onClick;
	const isVisible = options.visible ? options.visible : globalVisible.has(id) || defaultShow;
	const isEditable = options.editable ? options.editable : globalEditable.has(id);

	const handleClick = () => clickHandler(info, options);

	const radiusChanged = () => {
		if (circleRef.current && options.onChangeRadius) {
			const { circle } = circleRef.current.state;
			const { radius } = circle;

			if (radius !== options.radius) {
				options.onChangeRadius(radius);
			}
		}
	};

	const centerChanged = () => {
		if (circleRef.current && options.onChangeCenter) {
			const { circle } = circleRef.current.state;
			const circleCenter = convertGoogleLatLng(circle.center);
			if (circleCenter.lat !== options.center.lat && circleCenter.lng !== options.center.lng) {
				options.onChangeCenter(circleCenter);
			}
		}
	};

	return (
		<GoogleMapCircle
			editable={isEditable}
			onClick={handleClick}
			options={options}
			visible={isVisible}
			onCenterChanged={centerChanged}
			onRadiusChanged={radiusChanged}
			ref={circleRef}
		/>
	);
};

const Circles = ({ circleData, ...props }) => {
	if (circleData) {
		const { circles, onClick, defaultShow, visible, editable } = circleData;
		const { onCircleClick } = props;

		return Object.keys(circles).map((key) => (
			<Circle
				key={`circle-${key}`}
				value={circles[key]}
				onClick={onClick || onCircleClick}
				defaultShow={defaultShow}
				globalVisible={visible}
				globalEditable={editable}
			/>
		));
	}

	return null;
};

export default React.memo(Circles);
