import React from 'react';

import LogoBackground from '@assets/icons/fielder-logo-404.svg';
import LogoFielder from '@assets/icons/logo-login-main.svg';
import { Grid, Typography, Button } from '@mui/material';
import useStyles from './Style';

const Forbidden403 = ({lan, redirect}) => {
    const classes = useStyles();
	const goBack = () => { window.history.back() };
    return (
        <Grid container justifyContent="center" alignItems="stretch" spacing={3}>
            <Grid container direction="column" className={classes.container}>
				<Grid item>
					<img className={classes.logo} src={LogoFielder} alt="" />
				</Grid>
				<Grid item>
					<Typography variant="h2" className={classes.errorCode}>
						{lan.pageNoAvailable}
					</Typography>
				</Grid>
				<Grid item>
					<div className={classes.line} />
				</Grid>
				<Grid item>
					<Typography variant="h2" className={classes.errorText}>
						{lan.textNotAvailable}
					</Typography>
				</Grid>
				{redirect && (<Grid item className={classes.button}>
					<Button onClick={goBack} size="small" className='Fielder-button' >
						{lan.return}
					</Button>
					</Grid>)
				}
            </Grid>
			<Grid containter justifyContent="flex-start" alignItems="flex-end">
				<Grid item>
					<img className={classes.logoBackground} src={LogoBackground} alt="" />
				</Grid>
			</Grid>
        </Grid>
    );
};

export default Forbidden403;
