// ROLE MANAGEMENT
const PAGE_NAME = 'BILLING_SUMMARY';
const BILLING = 'billing';
const NAME = 'billing';
const VIEW = 0;

export default {
	PAGE_NAME,
	NAME,
	BILLING,
	VIEW,
};
