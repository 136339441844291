const INCORRECT_REQUIRED_FIELDS = 301;
const DUPLICATED_USERNAME = 300;
const USER_DISABLED = 305;
const DUPLICATED_BRANCH = 350;
const NO_ID_COMPANY = 352;
const DUPLICATED_COMPANY = 353;

const ACCOUNT_DISABLED = 402;
const ATTENTION_TIME_ERROR = 407;

const UNKNOWN = 520;
const IS_NOT_AN_USER = 543;

const TASK_NOT_FOUND = 580;
const TASK_STATUS_NOT_ALLOWED = 589;
const TASK_WAS_ASSIGNED_TO_ANOTHER_AGENT = 582;
const TASK_WAS_CANCELED = 583;
const TASK_INACTIVE = 584;
const NO_TASKS_WERE_AUTO_ASSIGNED = 595;
const BRANCH_NOT_FOUND = 715;
const EMPTY_FILE = 716;

const ROUTE_MODIFIED_BY_AGENT = 951;
const FAIL_DUPLICATE_COGNITO_USER = 960;
const FAIL_TO_CREATE_USER = 961;

const USER_DOES_NOT_HAVE_PERMISSION = 990;
const NOTIFICATION_HAS_ROLE = 992;

const INCORRECT_FORMAT = 1001;

const USER_NAME_CHARACTER_LIMIT =310;
const EMAIL_CHARACTER_LIMIT = 311;
const NAME_CHARACTER_LIMIT = 312;
const LAST_NAME_CHARACTER_LIMIT =313;
const PHONE_NUMBER_CHARACTER_LIMIT =314;
const MOBILE_NUMBER_CHARACTER_LIMIT = 315;
const JOB_POSITION_CHARACTER_LIMIT = 316;

export default {
	INCORRECT_REQUIRED_FIELDS,
	ACCOUNT_DISABLED,
	USER_DISABLED,
	ATTENTION_TIME_ERROR,
	DUPLICATED_USERNAME,
	ROUTE_MODIFIED_BY_AGENT,
	FAIL_DUPLICATE_COGNITO_USER,
	UNKNOWN,
	IS_NOT_AN_USER,
	DUPLICATED_BRANCH,
	DUPLICATED_COMPANY,
	NO_ID_COMPANY,
	INCORRECT_FORMAT,
	FAIL_TO_CREATE_USER,
	BRANCH_NOT_FOUND,
	EMPTY_FILE,
	USER_DOES_NOT_HAVE_PERMISSION,
	NOTIFICATION_HAS_ROLE,
	TASK_NOT_FOUND,
	TASK_STATUS_NOT_ALLOWED,
	TASK_WAS_ASSIGNED_TO_ANOTHER_AGENT,
	TASK_WAS_CANCELED,
	TASK_INACTIVE,
	NO_TASKS_WERE_AUTO_ASSIGNED,
	USER_NAME_CHARACTER_LIMIT,
	EMAIL_CHARACTER_LIMIT,
	NAME_CHARACTER_LIMIT,
	LAST_NAME_CHARACTER_LIMIT,
	PHONE_NUMBER_CHARACTER_LIMIT,
	MOBILE_NUMBER_CHARACTER_LIMIT,
	JOB_POSITION_CHARACTER_LIMIT
};
