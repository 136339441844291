import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLanguage } from 'Context/LanguageContext';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import useValidatePermission from 'FielderUtils/Hooks/useValidatePermission';
import dynConst from 'FielderUtils/roleManagementConst/dynamicAllocationConst';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser, selectAccountPreferences } from '../../../../core/store/modules/session/sessionSelectors';
import useValidateRoles from '../../../../core/utils/roles/useValidateRoles';
import SubMenu from '../SubMenu';

const PlanningAndOptimizingSideMenu = () => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const accountPreferences = useSelector(selectAccountPreferences);
	const navigate = useNavigate();
	const { isRoleValid } = useValidateRoles();

	const [hasAnyModule, setHasAnyModule] = useState(false);
	const [hasIsochronous, setHasIsochronous] = useState(false);
	const [hasDynamicAllocation, setHasDynamicAllocation] = useState(false);
	const [isDynamicAllocationPermitted, setDynamicAllocationPermitted] = useState(false);
	const [hasRouteExpenses, setHasRouteExpenses] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const [isoAllowed, validIsoPermission] = useValidatePermission(user, Permission.ISOCHRONOUS);
	const [dynAllocAllowed, validDynAllocPermission] = useValidatePermission(user, Permission.DYNAMIC_ALLOCATION);
	const [routeExpAllowed, validRouteExpPermission] = useValidatePermission(user, Permission.ROUTE_EXPENSES);

	const title = lan.billingPlanningOptimization;

	useEffect(() => {
		setHasAnyModule(
			accountPreferences &&
				(accountPreferences.showIsochronous ||
					accountPreferences.showDynamicAllocation ||
					accountPreferences.showRouteExpenses)
		);

		// Allowed to enter the pages
		setHasIsochronous(accountPreferences && accountPreferences.showIsochronous);
		setHasDynamicAllocation(accountPreferences && accountPreferences.showDynamicAllocation);
		setHasRouteExpenses(accountPreferences && accountPreferences.showRouteExpenses);
	}, [accountPreferences]);

	useEffect(() => {
		// Permissions by User Type
		validIsoPermission(user, Permission.ISOCHRONOUS);
		validDynAllocPermission(user, Permission.DYNAMIC_ALLOCATION);
		validRouteExpPermission(user, Permission.ROUTE_EXPENSES);
	}, [user, validIsoPermission, validDynAllocPermission, validRouteExpPermission]);

	useEffect(() => {
		const isDynamicAllocPermitted = isRoleValid(dynConst.DYNAMICALLOCATION, dynConst.GENERATE);
		setDynamicAllocationPermitted(isDynamicAllocPermitted);
	}, [user]);

	const openMenu = (e) => setAnchor(e.currentTarget);
	const handleClose = () => setAnchor(null);

	const goToPage = (page) => () => {
		handleClose();
		navigate(page);
	};

	const menuOptions = [
		{
			isValid: isoAllowed && hasIsochronous,
			goToPage: goToPage(ENDPOINTS.isochronous),
			title: lan.isochronous,
			img: `${process.env.AWS_FIELDER_ICONS}isochrone-icon.svg`,
		},
		{
			isValid: isDynamicAllocationPermitted && dynAllocAllowed && hasDynamicAllocation,
			goToPage: goToPage(ENDPOINTS.dynamicAllocation),
			title: lan.dynamicAllocation,
			img: `${process.env.AWS_FIELDER_ICONS}dynamicAllocation-icon.svg`,
		},
		{
			isValid: routeExpAllowed && hasRouteExpenses,
			goToPage: goToPage(ENDPOINTS.expenses),
			title: lan.expenses,
			img: `${process.env.AWS_FIELDER_ICONS}route-preferences.svg`,
		},
	];

	return (
		<>
			{hasAnyModule ? (
				<>
					<Tooltip title={title} placement='right'>
						<ListItemButton onClick={openMenu}>
							<ListItemIcon>
								<img className='icon' src={`${process.env.AWS_FIELDER_ICONS}planning-icon.svg`} alt='analytics' />

								<ChevronRightIcon color='disabled' />
							</ListItemIcon>
							<ListItemText primary={title} />
						</ListItemButton>
					</Tooltip>
					<SubMenu anchor={anchor} onClose={handleClose}>
						{menuOptions}
					</SubMenu>
				</>
			) : null}
		</>
	);
};

export default PlanningAndOptimizingSideMenu;
