import React from 'react';

import { motion, AnimatePresence, useIsPresent } from 'framer-motion';
import { RowAnimationVariants, RowTransitions } from '../TreeTable.utils';
import { RowBackground, TreeTableCell } from '../TreeTable.styles';

const Row = (props) => {
	const isPresent = useIsPresent();

	const { columns, CellComponent, expandedRows, ...rowData } = props;
	const { row, level, isOpen } = rowData;

	const rowStyles = {
		position: isPresent ? 'relative' : 'absolute',
		display: isPresent ? 'table-row' : 'flex',
		alignItems: isPresent ? '' : 'center',
		background: level <= RowBackground.length ? RowBackground[level] : RowBackground[RowBackground.length - 1],
	};

	const ChildrenRows = React.useMemo(() => {
		return (
			<AnimatePresence key={`${row.id}-animatedPresence`}>
				{row.children &&
					isOpen &&
					row.children.map((childRow) => (
						<Row
							key={`row-${childRow.id ? childRow.id : `${row.id}-${childRow.taskType.name}`}`}
							row={childRow}
							onToggleParent={rowData.onToggleParent}
							expandedRows={expandedRows}
							level={rowData.level + 1}
							CellComponent={CellComponent}
							columns={columns}
							isOpen={expandedRows.has(childRow.id)}
						/>
					))}
			</AnimatePresence>
		);
	}, [isOpen, expandedRows]);

	return (
		<>
			<motion.tr
				layout
				initial='initialRow'
				exit='hideRow'
				animate='showRow'
				style={rowStyles}
				variants={RowAnimationVariants}
				transition={RowTransitions}
				className='full-width'
			>
				{columns.map(
					({ id: columnId }) =>
						columnId in CellComponent && (
							<TreeTableCell key={`${row.id}-${columnId}-cell`}>{CellComponent[columnId](rowData)}</TreeTableCell>
						)
				)}
			</motion.tr>
			{ChildrenRows}
		</>
	);
};
export default React.memo(Row);
