import TaskSatus from 'FielderUtils/status/taskStatus';

export const getPinColor = (tasks, index) => {
	var color = '';
	switch (tasks[index].status) {
		case TaskSatus.MANAGING:
			return 'managing';
		case TaskSatus.WAITING_FOR_AGENT:
			return 'waiting';
		case TaskSatus.IN_PROGRESS:
			return 'in-progress';
		case TaskSatus.EXECUTING:
			return 'executing';
		case TaskSatus.FINISH:
			return 'finish';
		case TaskSatus.CANCEL:
			return 'cancel';
		case TaskSatus.START_ROUTE:
			return 'start-route';
		case TaskSatus.CANCEL_BY_PARTNER:
			return 'cancel-by-partner';
		case TaskSatus.ASSIGNED_TO_PARTNER:
			return 'assigned-to-partner';
		case TaskSatus.ASSIGNED_TO_TEAM:
			return 'assigned-to-team';
		case TaskSatus.ARRIVED:
			return 'arrived';
		case TaskSatus.DELETED:
			return 'deleted';
		case TaskSatus.AUTOASSIGNING:
			return 'autoassigning';
		case TaskSatus.AUTOASSIGNED_SUCCESS:
			return 'autoassigning-success';
		case TaskSatus.AUTOASSIGNED_FAILED:
			return 'autoassigning-failed';
		case TaskSatus.PAUSED:
			return 'paused';
		default:
			color = 'pink';
	}
	return color;
};
