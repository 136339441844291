import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';

// ROLE MANAGEMENT
const PAGE_NAME = 'ADMINISTRATORS';
const NAME = 'administrators';
const ADMINISTRATORS = 'administrators';
const ENDPOINT = ENDPOINTS.administrators;
const CREATE = 0;
const VIEW = 1;
const EDIT = 2;
const VIEW_DISABLED = 3;
const DISABLE = 4;
const DELETE = 5;
const IMPORT = 6;
const EXPORT = 7;

export default {
	PAGE_NAME,
	NAME,
	ADMINISTRATORS,
	ENDPOINT,
	CREATE,
	VIEW,
	EDIT,
	VIEW_DISABLED,
	DISABLE,
	DELETE,
	IMPORT,
	EXPORT,
};
