import API_LINKS from '../../../api/apiGateWayLinks';
import { handlePostRequest } from '@core/api/request'
import { getSelectUser } from '../../../store/modules/session/sessionSelectors';
import moment from 'moment';

export default async function sendMessageToLexLambda(userInput, session, connectionId) {

	const lan = session.user.language;
	const user = await getSelectUser();

	try {
		if (session && session.user && session.user.language && session.user.role) {
			const requestBody = {
				clientUserId: user.idUser,
				username: user.userName,
				accountId: user.idAccount,
				name: user.name,
				lastName: user.lastName,
				email: user.email,
				phoneNumber: "" + user.phoneNumber,
				mobileNumber: "" + user.mobileNumber,
				jobPosition: user.jobPosition,
				role: user.role,
				language: lan,
				timezone: moment.tz.guess(),
				userInput: userInput,
				connectionId, connectionId
			};

			const onSuccess = (res) => {
				return res
			};

			const onError = (err) => {
				alert("Sorry I couldn't answer that question")
				return res
			};

			return await handlePostRequest(API_LINKS.lexMessageSender, requestBody, onSuccess, onError, null);
		}
		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
}