import React from 'react';

// Utility
import 'FielderUtils/style.css';

class TaskGroupStatusFormatter extends React.Component {
	render() {
		let color = '';
		let text = '';
		switch (this.props.value) {
			case 'CREATED':
				color = 'Fielder-task-new';
				text = this.props.lan.CREATED;
				break;
			case 'ON_WAY':
				color = 'Fielder-task-waitting-for-agent';
				text = this.props.lan.ON_WAY;
				break;
			case 'ARRIVE':
				color = 'Fielder-task-arrive';
				text = this.props.lan.ARRIVE;
				break;
			case 'EXECUTING':
				color = 'Fielder-task-executing';
				text = this.props.lan.EXECUTING;
				break;
			case 'PAUSE':
				color = 'Fielder-task-paused-failed';
				text = this.props.lan.PAUSE;
				break;
			case 'FINISH':
				color = 'Fielder-task-finish';
				text = this.props.lan.FINISH;
				break;
			case 'CANCELED':
				color = 'Fielder-task-cancel';
				text = this.props.lan.CANCELED;
				break;
		}
		// return (<div className={"row mx-0 task-status "+color}><span className="my-auto">{text}</span></div>);
		return (
			<div className='row no-padding'>
				<div className='col-auto no-padding align-self-center'>
					<div className={`SmartTable-circle-texture text-center ${color}`} />
				</div>
				<div className='my-auto col-auto no-padding'>{text}</div>
			</div>
		);
	}
}

export default TaskGroupStatusFormatter;
