import React, { Component } from 'react';

import { Col, Row } from 'react-bootstrap';

import StatusFormatter from '../SmartTable/StatusFormatter';
import { getAgentTaskRouteRequest } from '@core/api/agents/agents.service';
import { connect } from 'react-redux';
import MapStaticComponent from '~/MapStatic/MapStatic.component';
import StaticMarker from '~/MapStatic/components/StaticMarker';
import StaticRoute from '~/MapStatic/components/StaticRoute';

/**
 * Contains the Execution content from the {@link TaskDetails} component
 */
class TaskRoute extends Component {
	constructor(props) {
		super(props);

		this.state = {
			agentStartLocation: null,
			agentRoute: [],
		};
	}

	componentDidMount = () => this.getAgentRoute();
	componentDidUpdate = (prevProps) => {
		if (prevProps.taskData.idFielder !== this.props.taskData.idFielder) {
			this.getAgentRoute();
		}
	};

	getAgentRoute = () => {
		this.props.changeLoadingState();
		if (this.props.taskAgent && this.props.idTask) {
			const body = {
				idTask: this.props.idTask,
				idAgent: this.props.idAgent ? this.props.idAgent : this.props.taskAgent.idAgent,
			};

			const onSuccess = ({ taskRoute }) => {
				let agentStartLocation;
				const agentRoute = [];

				if (taskRoute) {
					taskRoute.forEach((location, index) => {
						if (index === 0) {
							agentStartLocation = {
								coordinates: {
									lat: location.latitude,
									lng: location.longitud,
								},
							};
						}

						agentRoute.push([
							{
								lat: location.latitude,
								lng: location.longitud,
							},
						]);
					});
				}

				if (this.props.taskData.coords) {
					agentRoute.push(this.props.taskData.coords);
				}

				this.setState({ agentStartLocation, agentRoute });
			};

			const loadingConfig = {
				dispatch: this.props.dispatch,
			};

			getAgentTaskRouteRequest(body, onSuccess, null, loadingConfig).then(() => {
				this.props.changeLoadingState();
			});
		}
	};

	/**
	 * Helper  the Labels and text components to reduce
	 * code written
	 *
	 * @param {*} props - Text  the label and text
	 */
	LabelText = (props) => {
		var text = props.text;

		return (
			<Col sm={12} className={props.className}>
				<Row className='my-2'>
					<Col className={'td-label-input ' + props.labelClass}>{props.label}</Col>
				</Row>
				<Row>
					<Col className={'td-default-text align-left ' + props.textClass}>
						<input
							type='text'
							className={'task-details-input '}
							defaultValue={text}
							disabled={!this.state.editable}
							style={{ width: '100%' }}
						/>
					</Col>
				</Row>
			</Col>
		);
	};

	getMarkerDescription = (task, position) => {
		let description = '';
		if (position) {
			description = description + ' ( ' + position + ' ) ';
		}
		if (task.folio) {
			description = description + ' - ' + task.folio;
		}
		if (task.branch) {
			description = description + ' - ' + task.branch.name;
		} else if (task.client) {
			description = description + ' - ' + task.client.name;
		}

		return description;
	};

	render() {
		return (
			<div className='container' style={{ height: '450px' }}>
				<React.Fragment>
					<Row>
						<Col sm={3}>
							<Row>
								<Col>
									<this.LabelText
										label={this.props.lan.idCustom}
										text={this.props.taskData.number}
										className='my-3'
										textClass='td-task-number'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<StatusFormatter {...this.props} value={this.props.taskData.status} />
								</Col>
							</Row>
							{this.props.taskData.offlineExecution && (
								<Row>
									<div className='col mx-2 mt-5'>
										<span style={{ color: '#dc5c87' }}>{this.props.lan.routeNote}</span>
									</div>
								</Row>
							)}
						</Col>
						<Col sm={8}>
							<div className='text-center'>
								<MapStaticComponent size='800x500'>
									{this.props.taskData.coords && (
										<StaticMarker location={this.props.taskData.coords} icon={this.props.taskData.taskPin} />
									)}

									{this.state.agentStartLocation && this.props.taskData.agent && (
										<StaticMarker
											location={this.state.agentStartLocation.coordinates}
											icon={this.props.taskData.agent.pinIcon}
										/>
									)}
									<StaticRoute points={this.state.agentRoute} color='purple' />
								</MapStaticComponent>
							</div>
							<div className='row'>
								<this.LabelText
									label={this.props.lan.addressTask}
									text={this.props.taskData.address}
									className='my-3'
								/>
							</div>
						</Col>
					</Row>
				</React.Fragment>
			</div>
		);
	}
}

export default connect()(TaskRoute);
