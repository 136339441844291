import React from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import { Button, TableBody, TableCell as MuiTableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles';
import Checkbox from '~/Checkbox/Checkbox.component';

const TableCell = styled(MuiTableCell)`
	font-size: 12px;
	font-family: 'Lato', sans-serif;
`

const Cell = ({ id, idKey, value, row, CustomCell, padding, align, className, onClick }) => (
	<TableCell
		align={align}
		className={className}
		padding={padding}
		style={{ cursor: 'pointer' }}
		key={id}
		onClick={CustomCell ? null : onClick }
	>
		{CustomCell ? <CustomCell value={value} idKey={idKey} row={row} onClick={onClick} /> : value}
	</TableCell>
);

const CellIcon = ({ value, padding, id, align, className, onClick, disabledDetailButton }) => (
	<TableCell align={align} className={className} padding={padding} style={{ cursor: 'pointer' }}>
		<Button
			onClick={onClick ? () => onClick(id) : null}
			variant='contained'
			style={{ backgroundColor: '#f3c6d5', color: 'black' }}
			endIcon={<CallMadeIcon />}
			disabled={disabledDetailButton}
		>
			{value}
		</Button>
	</TableCell>
);

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(sortOrder, sortOrderBy) {
	return sortOrder === 'desc' ? (a, b) => desc(a, b, sortOrderBy) : (a, b) => -desc(a, b, sortOrderBy);
}

const Body = ({ rows, columns, selected, order, orderBy, useToolbarStyles, filteredData, ...props }) => {

	function stableSort(array, cmp) {
		if (props.search && filteredData) {
			array = filteredData;
		}

		if (props.searchWithEnter && filteredData) {
			array = filteredData;
		}

		const stabilizedThis = array.map((el, index) => [el, index]);

		stabilizedThis.sort((a, b) => {
			const orderCmp = cmp(a[0], b[0]);
			if (orderCmp !== 0) return orderCmp;
			return a[1] - b[1];
		});

		return stabilizedThis.map((el) => el[0]);
	}

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleCheckboxClick = (event, name, row) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		if (props.updateSelected) {
			const rowsSelected = rows.filter((row, i) => newSelected.includes(i));
			props.updateSelected(newSelected, rowsSelected);
		}

		if (props.onCheckboxClick) {
			if (props.isMyOperation) {
				props.onCheckboxClick(row, false);
			} else {
				// const rowsSelected = rows.filter((row, i) => newSelected.includes(i));
				props.onCheckboxClick(rows[name], false);
			}
		}

		props.setSelected(newSelected);
	};

	return (
		<TableBody key='table-body'>
			{stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
				const isItemSelected = isSelected(index);
				const labelId = `enhanced-table-checkbox-${index}`;

				return (
					<TableRow
						hover
						aria-checked={isItemSelected}
						tabIndex={-1}
						key={`main-row-${index}`}
						selected={isItemSelected}
					>
						{!props.disabled && (
							<TableCell onClick={(event) => handleCheckboxClick(event, index, row)} role='checkbox' padding='checkbox'>
								<Checkbox
									checked={isItemSelected}
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</TableCell>
						)}

						{columns.map((col) => {
							if (!props.cookie || (props.cookie && props.cookieCols && props.cookieCols[col.id])) {
								let paddingCol = 'normal';
								let onClick = null;

								if (col.onClick) {
									onClick = () => col.onClick(row);
								} else if(props.onRowClick) {
									onClick = () => props.onRowClick(row);
								}

								if (row[col.id] && row[col.id].padding && row[col.id].padding !== 'undefined') {
									paddingCol = row[col.id].padding;
								}

								if (col.id == 'idTask' || col.id ==  'idGroupTask') {
									return (
										<CellIcon
											padding={paddingCol}
											id={rows[index]}
											scope='row'
											align={col.align ? col.align : 'center'}
											className='fielder-cursor-pointer'
											onClick={props.onRowClickButton ? () => props.onRowClickButton(row) : null}
											disabledDetailButton={props.disabledDetailButton != undefined ? props.disabledDetailButton : true}
											value={row[col.id]}
											key={`row-${col.id}`}
										/>
									);
								}

								return (
									<Cell
										id={rows[index]}
										idKey={col.id}
										key={`row-${col.id}`}
										CustomCell={col.customCell}
										padding={paddingCol}
										scope='row'
										align={col.align || 'center'}
										value={row[col.id]}
										row={row}
										className='fielder-cursor-pointer'
										onClick={onClick}
									/>
								);

							}
						})}
					</TableRow>
				);
			})}
		</TableBody>
	);
};

export default React.memo(Body);
