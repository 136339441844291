import React from 'react';

import Avatar from '@mui/material/Avatar';
import MuiChip from '@mui/material/Chip';

import { ListItem } from './Chip.styles';

const ChipAvatar = ({ name, lastName, image }) => {
	if (image) {
		return <Avatar alt={name} src={image} />;
	}
	let avatarLetter = name[0];
	if (lastName) {
		avatarLetter += lastName[0];
	}
	return <Avatar alt={name}>{avatarLetter.toUpperCase()}</Avatar>;
};

const Chip = ({ element, getLabel, onDelete, chipProps, isArray }) => {
	if (typeof element === 'string') {
		return <MuiChip label={element} onDelete={onDelete ? () => onDelete(element) : null} {...chipProps} />;
	}

	const { name, lastName, image } = element;
	const label = getLabel ? getLabel(element) : element;

	const ChipElement = (
		<MuiChip
			avatar={<ChipAvatar image={image} name={name} lastName={lastName} />}
			onDelete={onDelete}
			label={label}
			{...chipProps}
		/>
	);

	if (isArray) {
		return <ListItem key={`${label}-chip-element`}>{ChipElement}</ListItem>;
	}
	return ChipElement;
};

export default Chip;
