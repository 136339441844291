import { useState, useEffect } from 'react';

import { getLoginAccountRedirect } from '@pages/LoginPage/LoginPage.utils';
import { useLanguage } from 'Context/LanguageContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { selectRequest, selectUser } from '../../core/store/modules/session/sessionSelectors';
import { switchAccount } from '../../core/store/modules/session/sessionSlice';

export const useChangeAccount = () => {
	const lan = useLanguage();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const { isLoading, isSuccess, isError, error } = useSelector(selectRequest);

	const navigate = useNavigate();
	const location = useLocation();

	const [willChangeAccount, setWillChangeAccount] = useState(false);
	const [requestData, setData] = useState({
		data: undefined,
		success: undefined,
		isPending: true,
		mustAcceptPrivacyPolicy: false,
	});

	useEffect(() => {
		setData({ ...requestData, isPending: isLoading });
	}, [isLoading]);

	useEffect(() => {
		if (willChangeAccount) {
			if (isSuccess && user) {
				const linkToRedirect = getLoginAccountRedirect(user, history);
				if (location.pathname == '/dashboard') {
					navigate(linkToRedirect);
				}

				navigate(linkToRedirect);
			}

			// if (isSuccess && user && user.acceptPrivacyPolicy === false) {
			// 	setData({ ...requestData, mustAcceptPrivacyPolicy: true });
			// }

			if (isError) {
				setData({
					success: false,
					data: lan.invalidCredentialsForViewOperation,
					isPending: false,
				});
			}

			setWillChangeAccount(false);
		}
	}, [isSuccess, isError, error, willChangeAccount]);

	const changeAccountUser = async (user) => {
		const waitForDispatch = await dispatch(switchAccount(user));
		if (waitForDispatch) {
			setWillChangeAccount(true);
		}
	};

	return { ...requestData, changeAccountUser };
};
