export const getJobOptionsForSelect = (lan) => {
	return [		
		{
			value: '0',
			label: lan.jobDescription,
			disabled: true,
		},
		{
			value: lan.jobDescriptionOps[0],
			label: lan.jobDescriptionOps[0],
		},
		{
			value: lan.jobDescriptionOps[1],
			label: lan.jobDescriptionOps[1],
		},
		{
			value: lan.jobDescriptionOps[2],
			label: lan.jobDescriptionOps[2],
		},
		{
			value: lan.jobDescriptionOps[3],
			label: lan.jobDescriptionOps[3],
		},
	]
};


export const getIndustryOptionsForSelect = (lan) => {
	return [
		{
			value: '0',
			label: lan.industryQuestion,
			disabled: true,
		},
		{
			value: lan.industryQuestionOps[0],
			label: lan.industryQuestionOps[0],
		},
		{
			value: lan.industryQuestionOps[1],
			label: lan.industryQuestionOps[1],
		},
		{
			value: lan.industryQuestionOps[2],
			label: lan.industryQuestionOps[2],
		},
		{
			value: lan.industryQuestionOps[3],
			label: lan.industryQuestionOps[3],
		},
		{
			value: lan.industryQuestionOps[4],
			label: lan.industryQuestionOps[4],
		},
		{
			value: lan.industryQuestionOps[5],
			label: lan.industryQuestionOps[5],
		},
		{
			value: lan.industryQuestionOps[6],
			label: lan.industryQuestionOps[6],
		},
		{
			value: lan.industryQuestionOps[7],
			label: lan.industryQuestionOps[7],
		},
		{
			value: lan.industryQuestionOps[8],
			label: lan.industryQuestionOps[8],
		},
		{
			value: lan.industryQuestionOps[9],
			label: lan.industryQuestionOps[9],
		},
		{
			value: lan.industryQuestionOps[10],
			label: lan.industryQuestionOps[10],
		},
		{
			value: lan.industryQuestionOps[11],
			label: lan.industryQuestionOps[11],
		},
		{
			value: lan.industryQuestionOps[12],
			label: lan.industryQuestionOps[12],
		},
		{
			value: lan.industryQuestionOps[13],
			label: lan.industryQuestionOps[13],
		},
		{
			value: lan.industryQuestionOps[14],
			label: lan.industryQuestionOps[14],
		},
		{
			value: lan.industryQuestionOps[15],
			label: lan.industryQuestionOps[15],
		},
		{
			value: lan.industryQuestionOps[16],
			label: lan.industryQuestionOps[16],
		},
		{
			value: lan.industryQuestionOps[17],
			label: lan.industryQuestionOps[17],
		},
		{
			value: lan.industryQuestionOps[18],
			label: lan.industryQuestionOps[18],
		},
		{
			value: lan.industryQuestionOps[19],
			label: lan.industryQuestionOps[19],
		},
	]
};

export const getFoundOptionsForSelect = (lan) => {
	return [
		{
			value: '0',
			label: lan.findUsQuestion,
			disabled: true,
		},
		{
			value: lan.findUsQuestionOps[0],
			label: lan.findUsQuestionOps[0],
		},
		{
			value: lan.findUsQuestionOps[1],
			label: lan.findUsQuestionOps[1],
		},
		{
			value: lan.findUsQuestionOps[2],
			label: lan.findUsQuestionOps[2],
		},
		{
			value: lan.findUsQuestionOps[3],
			label: lan.findUsQuestionOps[3],
		},
		{
			value: lan.findUsQuestionOps[4],
			label: lan.findUsQuestionOps[4],
		},
		{
			value: lan.findUsQuestionOps[5],
			label: lan.findUsQuestionOps[5],
		},
	]
}


