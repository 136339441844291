const FORMS = 'forms';
const APPROVED_FORMS = 'approved_forms';
const ASSETS = 'assets';
const INVENTORIES = 'inventories';
const FORMS_IMAGES = 'forms_images';

export default {
	FORMS,
	ASSETS,
	APPROVED_FORMS,
	INVENTORIES,
	FORMS_IMAGES,
};
