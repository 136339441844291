const STATUS = {
	MANAGING: 0,
	CANCEL: 1,
	FINISH: 2,
	WAITING_FOR_AGENT: 3,
	IN_PROGRESS: 4,
	EXECUTING: 5,
	START_ROUTE: 6,
	CANCEL_BY_PARTNER: 7,
	ASSIGNED_TO_PARTNER: 8,
	ASSIGNED_TO_TEAM: 9,
	ARRIVED: 10,
	DELETED: 11,
	AUTOASSIGNING: 12,
	AUTOASSIGNED: 13,
	AUTOASSIGNED_FAILED: 14,
	PAUSED: 15,
	REVIEW: 16,
};

export default STATUS;
