import React, {useState } from 'react';

import DangerIcon from '@assets/icons/danger-alert-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { Modal } from 'react-bootstrap';
import 'FielderUtils/style.css';

import { cancelTaskRequest } from '@core/api/task/task.service';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const CancelTask = (props) => {

	const lan = useLanguage();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const [taskInfo, setTaskInfo] = useState({ description: '' });
	const [taskInfoRequest, setTaskInfoRequest] = useState(props.taskInfoRequest);

	const handleClose = () => props.closeErrorMessage();

	const setValidateDescription = () => {
		if (taskInfo.description !== '') {
			document.getElementById('description-alert-icon').style.display = 'none';
			document.getElementById('description-red-input').style.borderColor = '#ced4da';
		}
	};

	const setFieldValue = (value, field) => {
		if (field === 'description') {

			setTaskInfo(prevState => ({
				...prevState, 
				description: value,
			}));

			setValidateDescription();
		}
	};

	const handleCancelTask = () => {
		if (taskInfo.description !== '') {
			const tasks = taskInfoRequest;
			const timeStamp = new Date();
			const timeStampMiliseconds = timeStamp.getTime();

			for (let i = 0; i < tasks.length; i++) {
				const body = {
					type: tasks[i].typeOfRequest,
					cancel: {
						comment: taskInfo.description,
						timeStamp: timeStampMiliseconds,
						idTask: tasks[i].idTask,
						idUser: user.idUser,
						idAccount: user.idAccount,
					},
				};

				const loadingConfig = {
					dispatch,
					name: 'CANCEL_TASK',
				};

				const onSuccess = props.onCloseModalSuccess
				const onError = props.onCloseModalError
				cancelTaskRequest(body, onSuccess, onError, loadingConfig);
			}
		} else {
			document.getElementById('description-alert-icon').style.display = 'inline';
			document.getElementById('description-red-input').style.borderColor = '#c70101';
		}
	};


	return (
			<React.Fragment>
				<Modal
					id='ErrorMessage-modal'
					size='md'
					show
					onHide={handleClose}
					className='Fielder-modals-header-border Fielder-modals-content-border'
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border' closeButton>
					</Modal.Header>

					<Modal.Body className='error-message-modal-body text-center'>
						<img src={DangerIcon} className='error-message-center-icon' alt='' />

						<div
							id='ErrorMessage-message'
							className='text-center ml-2 mr-2 mt-3 mb-3 error-message-margin'
						>
							{lan.messageConfirmaCancelTask}
						</div>

						<div className="row mx-3 mb-4">
						<div className="col" style={{ textAlign: 'justify', fontSize: '15px', color:'red' }}>
								{lan.cancelInformation}
							</div>	
						</div>

						<div className='row-auto pl-5 pr-5'>
							<textarea
								className='form-control  text-left mt-1'
								maxLength='255'
								rows='5'
								cols='20'
								id='description-red-input'
								placeholder={lan.cancelComment}
								value={taskInfo.description}
								onChange={(e) => setFieldValue(e.target.value, 'description')}
							></textarea>
							<img
								src={redAlertIcon}
								alt=''
								id='description-alert-icon'
								className='myoperation-icon'
							/>
						</div>
					</Modal.Body>

					<Modal.Footer>
						<div className='col d-flex justify-content-center mt-3'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size error-message-btn-margin'
								onClick={handleCancelTask}
							>
								{lan.cancel}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	
}

export default CancelTask;