import { baseQueryGet } from '@core/api/request';
import {
	setTasks,
	setTaskUnsettledUpdates,
	setAgents,
	setAgentUnsettledUpdates,
	setSingleAgent,
} from '@core/store/modules/commandCenter/commandCenterSlice';
import { taskHandler, agentHandler } from '@core/utils/realTime/handlers/commandCenterHandler';
import { binarySearch } from '@core/utils/searchAlgorithms/binarySearch';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import pages from '../../../../client/utils/pages/pages';
import { getWSService } from '../../../../services/WebSocket';
import WS_ROUTE_NAME from '../../../../services/WebSocketRouteNames';
import API_LINKS from '../../../api/apiGateWayLinks';

const mutex = new Mutex();

const agentRealTimeListener = (session, getState, dispatch) => async (data) => {
	if (getState().commandCenter.view.realTime) {
		// wait until the mutex is available without locking it
		await mutex.waitForUnlock();

		// Check whether the mutex is locked
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				const { commandCenter } = getState();
				const newAgents = await agentHandler(data, commandCenter, session.user);

				if (newAgents !== -1) {
					dispatch(setAgents(newAgents));

					const { view } = commandCenter;
					if (view.showAgentDetail && view.selectedAgents.length === 1) {
						if (view.selectedAgents[0].id === data.idUser) {
							const binarySearchOptions = { customComparisonValue: (element) => element.id };
							const agent = binarySearch(newAgents.orderedList, data.idUser, binarySearchOptions);
							dispatch(setSingleAgent(agent));
						} else if (view.selectedAgents[0].id === data.idAgent) {
							const binarySearchOptions = { customComparisonValue: (element) => element.id };
							const agent = binarySearch(newAgents.orderedList, data.idAgent, binarySearchOptions);

							dispatch(setSingleAgent(agent));
						}
					}
				}
			} finally {
				// release must be called once the mutex should be released again
				release();
			}
		} else {
			await mutex.waitForUnlock();
			agentRealTimeListener(session, getState, dispatch)(data);
		}
	} else {
		const { commandCenter } = getState();
		const { unsettledUpdates } = commandCenter.agents;
		dispatch(setAgentUnsettledUpdates(unsettledUpdates + 1));
	}
};

const taskRealTimeListener = (session, getState, dispatch) => async (data) => {
	if (getState().commandCenter.view.realTime) {
		// wait until the mutex is available without locking it
		await mutex.waitForUnlock();

		// Check whether the mutex is locked
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				const { commandCenter } = getState();

				const newTasks = await taskHandler(data, commandCenter, session.user);

				if (newTasks !== -1) {
					dispatch(setTasks(newTasks));
				}
			} finally {
				// release must be called once the mutex should be released again
				release();
			}
		} else {
			await mutex.waitForUnlock();
			taskRealTimeListener(session, getState, dispatch)(data);
		}
	} else {
		const { commandCenter } = getState();
		const { unsettledUpdates } = commandCenter.tasks;
		dispatch(setTaskUnsettledUpdates(unsettledUpdates + 1));
	}
};

export const commandCenterApi = createApi({
	keepUnusedDataFor: 0,
	reducerPath: 'commandCenterApi',
	baseQuery: baseQueryGet({ baseUrl: API_LINKS.commandCenter }),
	refetchOnReconnect: true,
	tagTypes: ['tasks', 'agents'],
	endpoints: (builder) => ({
		getAgents: builder.query({
			providesTags: ['agents'],
			query: (args) => ({ url: 'agents', params: args }),
			async onCacheEntryAdded(args, { getState, dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				// open websocket connection
				const { utils: { session } } = getState();

				// Wait for the initial query to resolve before proceeding
				await cacheDataLoaded;
				// When the data is received from the socket,
				// If the message is for command center Tasks,
				// update the query result with the new message
				getWSService().addRealTimeListener(agentRealTimeListener(session, getState, dispatch));
				// // cacheEntryRemoved will resolve when the cache subscription is no longer active
				// await cacheEntryRemoved;
			},
		}),
		getTasks: builder.query({
			keepUnusedDataFor: 0,
			providesTags: (result) =>
				result ? [...result.commandCenterTasks.map(({ idTask }) => ({ type: 'tasks', idTask })), 'tasks'] : ['tasks'],
			query: (args) => ({ url: 'tasks', params: args }),
			async onCacheEntryAdded(args, { getState, dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				// open websocket connection
				const { utils: { session } } = getState();

				// Wait for the initial query to resolve before proceeding
				await cacheDataLoaded;

				// When the data is received from the socket,
				// If the message is for command center Tasks,
				// update the query result with the new message
				getWSService().addRealTimeListener(taskRealTimeListener(session, getState, dispatch));

				getWSService().updateConnectionUserInfo(
					WS_ROUTE_NAME.UPDATE_CONNECTION,
					session.user.idUser,
					session.user.idAccount,
					pages.CommandCenter
				);

				// // cacheEntryRemoved will resolve when the cache subscription is no longer active
				// await cacheEntryRemoved;
			},
		}),
	}),
});

export const { useGetAgentsQuery, useGetTasksQuery } = commandCenterApi;
