import { combineReducers } from 'redux';

import utilsReducers from './reducers/utils.reducers';
import autoAssignReducer from './modules/autoAssign/autoAssign.slice';
import { commandCenterApi } from './modules/commandCenter/commandCenterApiSlice';
import commandCenterReducer from './modules/commandCenter/commandCenterSlice';
import isochronousReducer from './modules/isochronous/isochronous.slice';
import mapReducer from './modules/map/mapSlice';
import messageReducer from './modules/message/messageSlice';
import taskTypesReducer from './modules/taskTypes/taskTypes.slice';
import notificationsReducer from './modules/notifications/notificationsSlice';
import profileReducer from './modules/profile/profileSlice';

const rootReducer = combineReducers({
	map: mapReducer,
	message: messageReducer,
	commandCenter: commandCenterReducer,
	isochronous: isochronousReducer,
	autoAssign: autoAssignReducer,
	taskTypes: taskTypesReducer,
	notifications: notificationsReducer,
	profile: profileReducer,
	[commandCenterApi.reducerPath]: commandCenterApi.reducer,
	utils: utilsReducers,
});

export const reducerMiddleware = [commandCenterApi.middleware];

export default rootReducer;
