import React, { createContext, useState, useContext } from 'react';

interface ModalState {
  isOpen: boolean;
  content: JSX.Element;
}

interface ModalContextProps {
  modals: Record<string, ModalState>;
  openModal: (id: string, content: JSX.Element) => void;
  closeModal: (id: string) => void;
  closeAllModals: () => void;
  content: Record<string, JSX.Element>;
  setContent?: (id: string, content: JSX.Element) => void;
}

const ModalContext = createContext<Partial<ModalContextProps>>({});

export const ModalProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<Record<string, ModalState>>({});
  const [content, setContent] = useState<Record<string, JSX.Element>>({});

  const setModalContent = (id: string, newContent: JSX.Element) => {
    setContent((prev) => ({
      ...prev,
      [id]: newContent,
    }));
  };
  
  const openModal = (id: string, content: JSX.Element) => {
    setModals(prev => ({
      ...prev,
      [id]: {
        isOpen: true,
        content,
      },
    }));
  };

  const closeModal = (id: string) => {
    setModals(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        isOpen: false,
      },
    }));
  };

  const closeAllModals = () => {
    const closedModals = Object.keys(modals).reduce((acc, curr) => {
      acc[curr] = { ...modals[curr], isOpen: false };
      return acc;
    }, {} as Record<string, ModalState>);
    setModals(closedModals);
  };

  return (
    <ModalContext.Provider value={{ modals, content, openModal, closeModal, closeAllModals, setContent: setModalContent }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context || !context.closeModal || !context.openModal || !context.setContent) {
    throw new Error('useModal must be used within a ModalProvider that provides required functionalities');
  }
  return context;
};
