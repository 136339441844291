import { REQUEST_TYPES } from '@core/constants/form/form.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest, post, requestHandler} from '@core/api/request'
import API_LINKS from '../apiGateWayLinks';
import { pipe } from 'lodash/fp';

export const getFormsTableRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_FORMS_TABLE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getFormsFilterRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_FILTER_FORMS,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getFormByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_GET_SPECIFIC_FORM_TEMPLATE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getLogsTemplateFileRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_LOGS_TEMPLATE_FILE,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateTemplateFileRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPLOAD_TEMPLATE_FILE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const createTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_CREATE_FORM_TEMPLATE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_EDIT_FORM_TEMPLATE,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateFormStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_FORMS_STATUS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const deleteFormTemplateRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_DELETE_FORM_TEMPLATE,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getTemplateFormsListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_ALL_FORMS_TEMPLATES,
		idAccount: user.idAccount,
		bringPartnerForms: body.bringPartnerForms
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getTaskFormsReport = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_FORMS_TO_REPORT,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};


export const generateFormReportRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GENERATE_FORMS_REPORT,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};


export const reactivateFormRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_REACTIVATE_FORM,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateTaskFormPdfRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_TASK_FORM_PDF,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};


export const uploadTaskFormPdfRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPLOAD_TASK_FORM_PDF,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body
	};
	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getXLSXOrHTMLFromFormRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_XLSX_OR_HTML_FROM_FILE,
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};

export const getFormHTMLRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		request: REQUEST_TYPES.DO_ACTION_GET_HTML,
		idAccount: user.idAccount,
		...body
	};

	return handlePostRequest(API_LINKS.formsXlsxParser, requestBody, onSuccess, onError, loadingConfig);
};


export const getPdfFromHtmlRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsPdfParser, requestBody, onSuccess, onError, loadingConfig);
};


export const deleteFormImageRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_REMOVE_FORM_IMAGE,
		idAccount: user.idAccount,
		//accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};


export const updateFormFromTaskRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DO_UPDATE_FORM,
		idAccount: user.idAccount,
		accountId: user.idAccount,
		...body
	};
	return handlePostRequest(API_LINKS.formsManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getFormTemplatesToAutoAssingRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		idAccount: user.idAccount,
		type: REQUEST_TYPES.GET_FORM_TEMPLATES_TO_AUTOASSIGN,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager ,requestBody, onSuccess, onError, loadingConfig);
};

export const getAssistedFormRequest = async (body, onSuccess, onError, loadingConfig) => {	
	const user = await getSelectUser();

	body.idUser = user.idUser;

	return handlePostRequest(API_LINKS.assistedForm ,body, onSuccess, onError, loadingConfig);

	//const doRequest = pipe(post, requestHandler(onSuccess, onError));
	//return doRequest(API_LINKS.assistedForm, body, queryParams.toString(), {});
};

