import React, { useState, useEffect } from 'react';

import { IconButton, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useLanguage } from 'Context/LanguageContext';
import Permission from 'FielderUtils/Constants/PermissionConstants';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isLocalUser } from 'Utils/account/utils';

import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import { isRoleValid } from '../../../core/utils/roles/validateRoles';
import { ENDPOINTS } from '../../../core/constants/pageEndpoints.constants';
import { getAccountPreferences } from '../../../core/services/customHooks/accountPreferencesUtils';
import { selectUser, selectSession } from '../../../core/store/modules/session/sessionSelectors';

const TopMenuCreateTask = () => {
	const lan = useLanguage();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector(selectUser);
	const session = useSelector(selectSession);
	const accountPreferences = getAccountPreferences(session);
	const [isAbleToShow, setIsAbleToShow] = useState(true);

	const checkValidations = (user) => {
		if (user) {
			validatePermission(user, Permission.CREATE_TASK);
		}
	};

	useEffect(() => {
		// checkValidations(user);
		let isAllowed = true;
		let isAllowToCreateTask = isRoleValid(taskConst.NAME, taskConst.CREATE, session.roles)

		if ((RoleUtils.isUserManager(user) && (!accountPreferences.allowManagerCreate || !isAllowToCreateTask))
				|| !isAllowToCreateTask) {
			isAllowed = false;
		}
	
		const localUser = isLocalUser(user);
		setIsAbleToShow(localUser && isAllowed);
	}, [user]);

	const goToCreateTask = () => navigate(ENDPOINTS.createTask, {state: {pathname: location.pathname}});

	return (
		<>
			{isAbleToShow && (
				<Grid item>
					<Tooltip title={lan.createTask}>
						<IconButton onClick={goToCreateTask}>
							<img src={`${process.env.AWS_FIELDER_ICONS}create-task.svg`} alt='' className='Fielder-TopMenu-icon' />
						</IconButton>
					</Tooltip>
				</Grid>
			)}
		</>
	);
};

export default TopMenuCreateTask;
