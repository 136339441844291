import { pipe } from 'lodash/fp';

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { get, requestHandler } from '../request';

const prepareQueryString = (user, filters, page) => {
	const queryParams = new URLSearchParams();

	queryParams.set('userId', user.idUser);
	queryParams.set('startDate', filters.startDate);
	queryParams.set('endDate', filters.endDate);

	if (page) {
		queryParams.set('page', page);
	}

	if ('status' in filters && filters.status !== null) {
		queryParams.set('status', filters.status);
	}

	if ('taskCount' in filters && filters.taskCount !== null) {
		queryParams.set('taskCount', filters.taskCount);
	}

	if (filters.teamIds) {
		queryParams.set('teamIds', filters.teamIds);
	}

	if (filters.idAgents) {
		queryParams.set('agentIds', filters.idAgents);
	}

	if (filters.filter) {
		queryParams.set('filter', filters.filter);
	}

	return queryParams.toString();
};

export const getCommandCenterAgents = async (filters, agentId, onSuccess, onError, loading) => {
	const path = API_LINKS.commandCenterAgents;
	const user = await getSelectUser();
	const queryString = prepareQueryString(user, filters);
	const doRequest = pipe(get, requestHandler(onSuccess, onError, loading, true));

	if (agentId) {
		return doRequest(path, agentId, queryString, {});
	}

	return doRequest(path, null, queryString, {});
};
