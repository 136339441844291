import React from 'react';
import { TextDetailField, TextDetailLabel } from './TextDetail.styles';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const TextDetail = ({ label, text }) => {
	return (
		<Grid container sx={{ width: '100%' }}>
			<Grid item xs={12}>
				<TextDetailLabel>{label}</TextDetailLabel>
			</Grid>
			<Grid item xs={12}>
				<TextDetailField>{text}</TextDetailField>
			</Grid>
		</Grid>
	);
};

TextDetail.propTypes = {
	label: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default TextDetail;
