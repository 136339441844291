import { getCompaniesByFilter as searchCompaniesByFilter } from '@core/api/company/company.service';

export const formatCompanies = (companies) =>
	companies.map((company) => {
		const customId = company.idCustom ? ` (${company.idCustom})` : '';
		return {
			label: `${company.name}${customId}`,
			value: company,
		};
	});

export const getCompaniesByFilter = (filter) => {
	const onError = (error) => [];
	const onSuccess = (res) => res.companies;
	return searchCompaniesByFilter(filter, onSuccess, onError);
};

export const getCompaniesByFilterAsyncSelect = async (filter) => {
	const companies = await getCompaniesByFilter(filter);
	return formatCompanies(companies);
};
