import adminConst from 'FielderUtils/roleManagementConst/adminConst';
import agentConst from 'FielderUtils/roleManagementConst/agentConst';
import analyticsConst from 'FielderUtils/roleManagementConst/analyticsConst';
import assetConst from 'FielderUtils/roleManagementConst/assetConst';
import autoassignConst from 'FielderUtils/roleManagementConst/autoassignConst';
import billingConst from 'FielderUtils/roleManagementConst/billingConst';
import branchConst from 'FielderUtils/roleManagementConst/branchConst';
import calendarConst from 'FielderUtils/roleManagementConst/calendarConst';
import clientConst from 'FielderUtils/roleManagementConst/clientConst';
import commandCenterConst from 'FielderUtils/roleManagementConst/commandCenterConst';
import companyConst from 'FielderUtils/roleManagementConst/companyConst';
import taskConst from 'FielderUtils/roleManagementConst/taskConst';
import createTaskConst from 'FielderUtils/roleManagementConst/createTaskConst';
import dashboardConst from 'FielderUtils/roleManagementConst/dashboardConst';
import dynamicAllocationConst from 'FielderUtils/roleManagementConst/dynamicAllocationConst';
import expensesConst from 'FielderUtils/roleManagementConst/expensesConst';
import formConst from 'FielderUtils/roleManagementConst/formConst';
import inventoryConst from 'FielderUtils/roleManagementConst/inventoryConst';
import isochronousConst from 'FielderUtils/roleManagementConst/isochronousConst';
import managerConst from 'FielderUtils/roleManagementConst/managerConst';
import partnerConst from 'FielderUtils/roleManagementConst/partnerConst';
import resourceAllocationConst from 'FielderUtils/roleManagementConst/resourceAllocationConst';
import roleManagementConst from 'FielderUtils/roleManagementConst/roleManagementConst';
import routeOptimizationConst from 'FielderUtils/roleManagementConst/routeOptimizationConst';
import skillsConst from 'FielderUtils/roleManagementConst/skillsConst';
import smsConst from 'FielderUtils/roleManagementConst/smsConst';
import taskTypePreferencesConst from 'FielderUtils/roleManagementConst/taskTypePreferencesConst';
import teamConst from 'FielderUtils/roleManagementConst/teamConst';
import massiveDownloadConst from 'FielderUtils/roleManagementConst/taskFilesMassiveDownloadConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { validatePermissions } from 'FielderUtils/validatePermissions';

import { ENDPOINTS } from '../../constants/pageEndpoints.constants';
import taskFilesMassiveDownloadConst from 'FielderUtils/roleManagementConst/taskFilesMassiveDownloadConst';

const getPageConst = (pathname, user) => {
	switch (pathname) {
		case dashboardConst.PAGE_NAME:
			return {
				validateRoles: [dashboardConst.DASHBOARD, dashboardConst.VIEW],
				redirect: ENDPOINTS.myOperation,
				endpoint: ENDPOINTS.dashboard,
			};
		case agentConst.PAGE_NAME:
			return { validateRoles: [agentConst.AGENTS, agentConst.VIEW], endpoint: agentConst.ENDPOINT };
		case adminConst.PAGE_NAME:
			return {
				validateRoles: [adminConst.ADMINISTRATORS, adminConst.VIEW],
				endpoint: ENDPOINTS.administrators,
			};
		case managerConst.PAGE_NAME:
			return {
				validateRoles: [managerConst.MANAGERS, managerConst.VIEW],
				endpoint: ENDPOINTS.managers,
			};
		case partnerConst.PAGE_NAME:
			return {
				validateRoles: [partnerConst.PARTNERS, partnerConst.VIEW],
				endpoint: ENDPOINTS.partners,
			};
		case teamConst.PAGE_NAME:
			return { validateRoles: [teamConst.TEAMS, teamConst.VIEW], endpoint: ENDPOINTS.teams };
		case companyConst.PAGE_NAME:
			return {
				validateRoles: [companyConst.COMPANIES, companyConst.VIEW],
				endpoint: ENDPOINTS.companies,
			};
		case commandCenterConst.PAGE_NAME:
			return {
				endpoint: ENDPOINTS.commandCenter,
				accountPreferences: commandCenterConst.ACCOUNT_PREFERENCES,
			};
		case branchConst.PAGE_NAME:
			return {
				validateRoles: [branchConst.BRANCHES, branchConst.VIEW],
				endpoint: ENDPOINTS.branches,
			};
		case clientConst.PAGE_NAME:
			return {
				validateRoles: [clientConst.CLIENTS, clientConst.VIEW],
				endpoint: ENDPOINTS.clients,
			};
		case createTaskConst.PAGE_NAME:
			return {
				validateRoles: [taskConst.NAME, taskConst.CREATE],
				validatePermissions: createTaskConst.NAME,
				endpoint: ENDPOINTS.createTask
			};
		case calendarConst.PAGE_NAME:
			return {
				validateRoles: [calendarConst.CALENDAR, calendarConst.VIEW],
				endpoint: ENDPOINTS.calendar,
			};
		case analyticsConst.PAGE_NAME: ///////////// TODO: Review
			return {
				validateRoles: [analyticsConst.ANALYTICS, analyticsConst.VIEW],
				validatePermissions: analyticsConst.ANALYTICS,
				extraValidation: RoleUtils.isUserClient(user) && user?.users.length > 0,
				endpoint: ENDPOINTS.analytics,
			};
		case formConst.PAGE_NAME:
			return {
				validateRoles: [formConst.FORMS, formConst.VIEW],
				accountPreferences: formConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.forms,
			};
		case assetConst.PAGE_NAME:
			return {
				validateRoles: [assetConst.ASSETS, assetConst.VIEW],
				accountPreferences: assetConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.assetManagement,
			};
		case inventoryConst.PAGE_NAME:
			return {
				validateRoles: [inventoryConst.INVENTORY, inventoryConst.VIEW],
				accountPreferences: inventoryConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.inventory,
			};
		case autoassignConst.PAGE_NAME:
			return {
				validateRoles: [autoassignConst.AUTOASSIGN, autoassignConst.VIEW_TEMPLATES],
				accountPreferences: autoassignConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.autoAssign,
			};
		case skillsConst.PAGE_NAME:
			return {
				validateRoles: [skillsConst.SKILLS, skillsConst.VIEW],
				accountPreferences: skillsConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.skills,
			};
		case isochronousConst.PAGE_NAME:
			return {
				validateRoles: [isochronousConst.ISOCHRONOUS, isochronousConst.VIEW],
				validatePermissions: 'iso',
				accountPreferences: isochronousConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.isochronous,
			};
		case taskTypePreferencesConst.PAGE_NAME:
			return {
				validateRoles: [
					taskTypePreferencesConst.TASK_TYPE_PREFERENCES,
					taskTypePreferencesConst.VIEW,
				],
				endpoint: ENDPOINTS.taskTypes,
			};
		case routeOptimizationConst.PAGE_NAME:
			return {
				validateRoles: [routeOptimizationConst.ROUTE_OPTIMIZATION, routeOptimizationConst.GENERATE],
				validatePermissions: 'route',
				accountPreferences: routeOptimizationConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.routeOptimization,
			};
		case resourceAllocationConst.PAGE_NAME:
			return {
				validateRoles: [
					resourceAllocationConst.RESOURCEALLOCATION,
					resourceAllocationConst.GENERATE,
				],
				validatePermissions: 'resource',
				accountPreferences: resourceAllocationConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.resourceAllocation,
			};
		case smsConst.PAGE_NAME:
			return {
				validateRoles: [smsConst.SMS, smsConst.VIEW],
				accountPreferences: smsConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.sms,
			};
		case dynamicAllocationConst.PAGE_NAME:
			return {
				validateRoles: [dynamicAllocationConst.DYNAMICALLOCATION, dynamicAllocationConst.GENERATE],
				accountPreferences: dynamicAllocationConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.dynamicAllocation,
			};
		case expensesConst.PAGE_NAME:
			return {
				accountPreferences: expensesConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.expenses,
			};
		case billingConst.PAGE_NAME:
			return {
				validateRoles: [billingConst.BILLING, billingConst.VIEW],
				validatePermissions: billingConst.BILLING,
				endpoint: ENDPOINTS.billingSummary,
			};
		case roleManagementConst.PAGE_NAME:
			return {
				accountPreferences: roleManagementConst.ACCOUNT_PREFERENCES,
				extraValidation: RoleUtils.isUserSuperAdministrator(user),
				endpoint: ENDPOINTS.roleManagement,
			};
		case massiveDownloadConst.PAGE_NAME:
			return {
				accountPreferences: taskFilesMassiveDownloadConst.ACCOUNT_PREFERENCES,
				endpoint: ENDPOINTS.massiveDownload,
			};
		default:
			return null;
	}
};

const pageIsValid = (page, user, accountPreferences, roles) => {
	const pagePermissionsInfo = getPageConst(page);
	let isAllowed = true;

	if (pagePermissionsInfo === null) {
		return null;
	}

	if (pagePermissionsInfo?.validateRoles) {
		if (user.roles) {
			const [name, roleType] = pagePermissionsInfo.validateRoles;
			isAllowed &&= name in roles && roleType in roles[name] && roles[name][roleType] ===true;
		}
		if(pagePermissionsInfo.endpoint === ENDPOINTS.agents && RoleUtils.isUserManager(user)){
			isAllowed = true;
		}
	}

	if (pagePermissionsInfo?.validatePermissions) {
		isAllowed &&= validatePermissions(
			user,
			accountPreferences,
			pagePermissionsInfo.validatePermissions
		);
	}

	if (pagePermissionsInfo?.extraValidation) {
		isAllowed &&= pagePermissionsInfo.extraValidation;
	}

	if (pagePermissionsInfo?.accountPreferences) {
		isAllowed &&= accountPreferences[pagePermissionsInfo.accountPreferences];
	}
	
	return {
		isAllowed: isAllowed,
		redirect: pagePermissionsInfo?.redirect ? pagePermissionsInfo.redirect : false,
		endpoint: pagePermissionsInfo.endpoint,
	};
};

export const getValidPages = (session, accountPreferences, roles) => {
	const user = session?.user;

	if (user) {
		// Get the User Pages to get their permissions
		const userPages = RoleUtils.getRolePages(user.role);
		let userPagePermissions = {};

		for (let i = 0; i < userPages.length; i++) {
			const page = userPages[i];

			const pageValidInfo = pageIsValid(page, user, accountPreferences, roles);

			if (pageValidInfo !== null) {
				userPagePermissions[page] = {
					isAllowed: pageValidInfo.isAllowed,
					redirect: pageValidInfo.redirect,
				};
			} else {
				userPagePermissions[page] = {
					isAllowed: true,
				};
			}
		}

		return userPagePermissions;

		// If user is Client don't show Forbidden redirect to 404 --- For Private Route
	} else {
		// REDIRECT TO LOGIN, not user loaded
		// Clean session to handle errors
	}
};
