import React, { useState, useEffect } from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectUser } from '../../../../core/store/modules/session/sessionSelectors';

const MarketPlaceSideMenu = ({ goToPage }) => {
	const user = useSelector(selectUser);
	const lan = useLanguage();
	const title = lan.marketPlace;

	const [isPermitted, setPermitted] = useState(false);

	useEffect(() => {
		const isUserPermitted = RoleUtils.isUserSuperAndSuperViewerAndAdministrator(user);
		setPermitted(isUserPermitted);
	}, [user]);

	return (
		<>
			{isPermitted ? (
				<Tooltip title={title} placement='right'>
					<ListItemButton onClick={goToPage(ENDPOINTS.marketplace)}>
						<ListItemIcon>
							<img className='' src={`${process.env.AWS_FIELDER_ICONS}marketplace-icon.svg`} alt='marketplace' />
						</ListItemIcon>
						<ListItemText primary={title} />
					</ListItemButton>
				</Tooltip>
			) : null}
		</>
	);
};

export default MarketPlaceSideMenu;
