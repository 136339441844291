import { REQUEST_TYPES } from '@core/constants/users/users.request.constants';
import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import {handlePostRequest} from '@core/api/request'

import API_LINKS from '../apiGateWayLinks';
import { Admin } from 'FielderUtils/roles/roles';



export const getAdmins = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.GET_USERS_TABLE,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const updateAdministrator = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_UPDATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};

export const changeAdministratorStatusRequest = async (body, onSuccess, onError, loadingConfig) => {
	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CHANGE_USER_STATUS,
		role: Admin.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};




// DELETE
export const deleteAdministratorRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_DELETE_USER,
		role: Admin.name,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};



export const createAdministratorRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		type: REQUEST_TYPES.DO_ACTION_CREATE_USER,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};


