import React from "react";

import PendingIcon from  "@assets/icons/pending-icon.svg";
import {Button} from "react-bootstrap";

class InvitationFormatter extends React.Component {

  render() {
    const type = this.props.value;
    let text = "";

    if(type === "INVITATION_PENDING"){
      text = this.props.lan.pendentingInvitation;
    }

    return (<React.Fragment><img src={PendingIcon}/> {text}</React.Fragment>);
  }
}


export default InvitationFormatter;