
import React from 'react';
import { useLanguage } from 'Context/LanguageContext';
import STATIC_LINK from 'FielderUtils/staticLinks/staticLinks';
import FielderButton from 'FielderComponents/FielderButton';
import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils';


const RegisterFooter = ({ validateRegister }) => {
	const lan = useLanguage();

	return (
		<>
			<div className='row justify-content-center'>
				<div className='col-12 align-self-center'>
					<div className="row d-flex justify-content-center ">
						<p className='Register-info-text'>
							{lan.termsAndConditions1}
							<a
							className='Register-ref Fielder-mainpage-addtext-link-color'
							href={STATIC_LINK.tearmsAndConditions}
							>
								{lan.termsAndConditions2} and{' '}
							</a>{' '}
							<a
								className='Register-ref Fielder-mainpage-addtext-link-color'
								href={STATIC_LINK.policy}
							>
								{lan.privacyPolicy}.
							</a>

						</p>
					</div>

					<div className="row d-flex justify-content-center">
						<div className='col-6 no-padding'> 
							<FielderButton
								id='register-button'
								size="large" 
								style={{height:'80px !important'}}
								onClick={validateRegister} >
								{lan.requestTrial}
							</FielderButton>
						</div>	
						
					</div>
				</div>
			</div>

			<div className='Register-info'>
				<div className="row d-flex justify-content-center ">
					<p className='Register-info-text'>
						{`© ${process.env.FIELDER_APP_YEAR} v${process.env.FIELDER_APP_VERSION}`}
					</p>
				</div>
			</div>
		
		</>
	);
}

export default RegisterFooter;