import { REQUEST_TYPES } from '@core/constants/agent/agent.request.constants';
import { REQUEST_TYPES as USERS_REQUEST_TYPES } from '@core/constants/users/users.request.constants';
import { Agent } from 'FielderUtils/roles/roles';

import { getSelectUser } from '../../store/modules/session/sessionSelectors';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

// POST
export const createAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	body.agent.idAccount = user.idAccount;
	if (body.agent.location && body.agent.location.latitude == '') {
		delete body.agent.location;
	} else if (body.agent.location) {
		body.agent.location.accountId = user.idAccount;
	}

	const requestBody = {
		requestType: REQUEST_TYPES.CREATE_AGENT,
		modifiedBy: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
}

export const congratulateAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.SEND_CONGRATULATION,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
}

export const sendPasswordToAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.SEND_PASSWORD_TO_AGENT,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
}

export const hideTaskTypeToAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.HIDE_TASK_TYPE_TO_AGENT,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.automationManager, requestBody, onSuccess, onError, loadingConfig);
}

export const showTaskTypeToAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.SHOW_TASK_TYPE_TO_AGENT,
		idUser: user.idUser,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.automationManager, requestBody, onSuccess, onError, loadingConfig);
}

// GET
export const getAgentsListRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_ALL_AGENTS,
		idAccount: user.idAccount,
		modifiedBy: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentsListBySkillRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_AGENTS_LIST_BY_SKILL,
		idAccount: user.idAccount,
		modifiedBy: user.idUser,
		...body
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getMyOperationAgentsRequest = async (onSuccess, onError, loadingConfig, idTeam) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_MY_OPERATION_AGENTS,
		idAccount: user.idAccount,
		idUser: user.idUser,
		idTeam
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentsForSelectRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_AGENT_FOR_SELECT,
		idAccount: user.idAccount,
		idUser: user.idUser,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentLastLocationRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_AGENT_LAST_LOCATION,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentLocationsRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_AGENT_LOCATIONS,
		idAccount: user.idAccount,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentsStartLocationRequest = async (onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.GET_AGENT_START_LOCATION,
		idAccount: user.idAccount,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};


export const getAgentByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_AGENT_BY_ID,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getOptimizedAgentByIdRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_AGENT_BY_ID_OPTIMIZED,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

export const getAgentTaskRouteRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: REQUEST_TYPES.GET_AGENT_TASK_ROUTE,
		idAccount: user.idAccount,
		idUser: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.taskManager, requestBody, onSuccess, onError, loadingConfig);
};

// PUT
export const updateAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.UPDATE_AGENT,
		modifiedBy: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
}

export const disableAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.DISABLE_AGENT,
		idAccount: user.idAccount,
		modifiedBy: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

export const enableAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		requestType: REQUEST_TYPES.ENABLE_AGENT,
		idAccount: user.idAccount,
		modifiedBy: user.idUser,
		...body,
	};

	return handlePostRequest(API_LINKS.agentManager, requestBody, onSuccess, onError, loadingConfig);
};

// DELETE
export const deleteAgentRequest = async (body, onSuccess, onError, loadingConfig) => {
	const user = await getSelectUser();

	const requestBody = {
		type: USERS_REQUEST_TYPES.DO_ACTION_DELETE_USER,
		role: Agent.name,
		...body
	};

	return handlePostRequest(API_LINKS.apiUserManager, requestBody, onSuccess, onError, loadingConfig);
};