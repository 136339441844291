//import createCompressor from 'redux-persist-transform-compress';
import { encryptTransform } from 'redux-persist-transform-encrypt';

// TODO: Get the secret key from the backend unique for client
const SECRET_KEY = process.env.SECRET_KEY;

export const TransformEncrypt = encryptTransform({
	secretKey: SECRET_KEY,
});

//export const TransformCompress = createCompressor();

// TODO: Implement the expire reducer for expiring localStorage
// yarn add expire-reducer
