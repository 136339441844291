import React, { useRef, useState, useEffect } from 'react';
import Chatbot from 'react-chatbot-kit'
import { Image } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';

import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';

import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';


import { createChatBotMessage } from 'react-chatbot-kit';
import HeaderChatBot from './HeaderChatBot'
import WidgetLinks from  './WidgetLinks'

import 'react-chatbot-kit/build/main.css'
import './ChatContainer.css'

const EmptyComponent = () => {
	return (
		<div></div>
	)
}

const ChatbotContainer = () => {
	const lan = useLanguage();
	const [showBotChat, setShowBotChat] = useState(false);
	const [chatContainerWidth, setChatContainerWidth] = useState(275);
	const [messages, setMessages] = useState([]);
	const resizableChatRef = useRef(null);

	const botName = lan.botName;
	const config = {
		initialMessages: [createChatBotMessage(`${lan.howCanIHelpYou}`,
		{ delay: 200 }
		)],
		botName: botName,
		customStyles: {
		  botMessageBox: {
			backgroundColor: '#ED617F',
		  },
		  chatButton: {
			backgroundColor: '#ED617F',
		  },
		},
		customComponents: {
			header: () => <HeaderChatBot botName={botName} handleOpenCloseBotChat={handleOpenCloseBotChat} /> ,
			botAvatar: () => <EmptyComponent />,
			userAvatar: () => <EmptyComponent />,
		},
		widgets: [
			{
				widgetName: 'linkComponent',
				widgetFunc: (props) => <WidgetLinks {...props} />,
			},
		]
	};

	//TODO: esto sólo cambia cuando navego a otra ruta, no inmediatamente al cambiar el lenguaje en el perfil
	// los mensajes sólo se cargan cuando el chat se minimiza
	let greetingMessage = lan.howCanIHelpYou;

	useEffect(() => {
		setMessages([createChatBotMessage(greetingMessage)]);
	}, [greetingMessage]);

	const handleOpenCloseBotChat = () => {
		setShowBotChat(prev => !prev)
	}

	const validateInput = (message) => {
		return message != null && message.trim().length != 0;
	}

	const rsMouseDownHandler = (e) => {
		const x = e.clientX;
		const sbWidth = window.getComputedStyle(resizableChatRef.current).width;
		const initialWidth = parseInt(sbWidth, 10);
	
		const mouseMoveHandler = (e) => {

			const diffX = x - e.clientX; // This line remains unchanged for resizing from left to right

			// Determine the direction of the mouse movement
			const direction = diffX > 0 ? -1 : 1; // -1 for left to right, 1 for right to left
	  
			// Apply a scaling factor to slow down the width change when moving to the right
			const scalingFactor = direction === -1 ? 1 : 0.5; // Adjust the scaling factor as needed
	  
			// Calculate the new width, applying the scaling factor
			const newWidth = initialWidth + (diffX * scalingFactor);
		
			if (newWidth >= 275) {
				setChatContainerWidth(newWidth);
			}
		};
	
		const mouseUpHandler = () => {
			document.removeEventListener('mouseup', mouseUpHandler);
			document.removeEventListener('mousemove', mouseMoveHandler);
		};
	
		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};

	const saveMessages = (messages, HTMLString) => {
		setMessages(messages);
	};
	
	const loadMessages = () => {
		return messages;
	};

	return (
		<div style={{width: `${chatContainerWidth}px`, height: '100%'}}>
			
			{showBotChat ?
				<div className='d-flex' ref={resizableChatRef} style={{height: '100%'}}>
					<div className='d-flex align-items-center' style={{backgroundColor: 'rgb(239 239 239)', borderRadius: '5px 0 0 5px', cursor: 'ew-resize'}} onMouseDown={rsMouseDownHandler}>
						<svg focusable="false" style={{transform: 'rotate(90deg)', 
							stroke: '#545b64'}} 
							xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
								<line strokeWidth="2" x1="2" y1="5" x2="14" y2="5"></line>
								<line strokeWidth="2" x1="14" y1="10" x2="2" y2="10"></line>
						</svg>
					</div>
					<div style={{width: '100%', height:'100%'}}>
						<Chatbot
							config={config}
							messageParser={MessageParser}
							actionProvider={ActionProvider}
							placeholderText={lan.writeYourMessageHere}
							validator={validateInput}
							saveMessages={saveMessages}
							messageHistory={loadMessages()}
						/>
					</div>
				</div>
			:
			<div style={{position: 'absolute', bottom: 0, right: 0}}>
				<IconButton onClick={handleOpenCloseBotChat}>
					<Image src={`${process.env.AWS_FIELDER_ICONS}maya.svg`} alt='' style={{ width:'60px'}}   />
				</IconButton>
			</div>
			}
    </div>
	);
  };
  export default ChatbotContainer;