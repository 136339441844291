import React, { useEffect, useState } from 'react';

import { getCompanyTaskToCashRequest } from '@core/api/taskToCash/taskToCash.service';
import { Grid } from '@mui/material';
import { AnimatePresence } from 'framer-motion';

import TaskToCashTable from '~/TaskToCashTable/TaskToCashTable.component';
import TaskToCashOptions from './components/TaskToCashOptions';
import TaskToCashFilters from './components/TaskToCashFilters';
import TaskToCashCompletionGraph from '~/TaskToCashCompletionGraph/TaskToCashCompletionGraph.component';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import moment from 'moment';
import { getWSService } from '../../services/WebSocket';
import WS_ROUTE_NAME from '../../services/WebSocketRouteNames';
import { useSelector } from 'react-redux';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { taskToCashUpdateActions } from '@core/constants/realTime/pages/taskToCash';
import pages from '../../client/utils/pages/pages';
import CardTaskCount from '~/CardTaskCount/CardTaskCount.component';

const todaysDate = moment().startOf('day').valueOf();
const todaysEndDate = moment().endOf('day').valueOf();

const removeWebSocket = (user) => {
	getWSService().deleteConnectionUserInfo(
		WS_ROUTE_NAME.DELETE_CONNECTION,
		user.idUser,
		user.idAccount,
		pages.TaskToCash
	);
};

const TaskToCashDashboard = () => {
	const lan = useLanguage();
	const user = useSelector(selectUser);

	const [tasksCompletion, setTasksCompletion] = useState([]);
	const [companyTasksToCash, setCompanyTasksToCash] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [filters, setFilters] = useState({
		startDate: todaysDate,
		endDate: todaysEndDate,
		idCompanies: [],
		idBranches: [],
	});

	const realTimeHandler = (msg) => {
		const { action } = msg;
		if (taskToCashUpdateActions.has(action)) {
			fetchTaskToCash();
		}
	};

	useEffect(() => {
		getWSService();
		getWSService().addRealTimeListener(realTimeHandler);
		getWSService().updateConnectionUserInfo(
			WS_ROUTE_NAME.UPDATE_CONNECTION,
			user.idUser,
			user.idAccount,
			pages.TaskToCash
		);

		return () => {
			removeWebSocket(user);
		};
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchTaskToCash();
	}, [filters]);

	const fetchTaskToCash = () => {
		const body = filters;

		getCompanyTaskToCashRequest(body).then((res) => {
			const { companyTaskSummaries, taskCompletionStatus } = res;

			setCompanyTasksToCash(companyTaskSummaries);

			const taskCompletionStatusGraphData = [
				{
					status: lan.completed,
					total: taskCompletionStatus.completedTask,
				},
				{
					status: lan.pending,
					totalMyOperation: taskCompletionStatus.pendingTaskMyOperation,
					totalClientsOperation: taskCompletionStatus.pendingTaskClientsOperation,
				},
			];

			setTasksCompletion(taskCompletionStatusGraphData);
			setLoading(false);
		});
	};

	const handleChangeDates = (start, end) => {
		const newFilters = { ...filters };
		newFilters.startDate = start.valueOf();
		newFilters.endDate = end.endOf('day').valueOf();
		setFilters(newFilters);
	};

	const handleChangeCompany = (companyIds) => {
		const newFilters = { ...filters };
		newFilters.idCompanies = companyIds;
		newFilters.idBranches = [];
		setFilters(newFilters);
	};

	const handleChangeBranches = (branchIds) => {
		const newFilters = { ...filters };
		newFilters.idBranches = branchIds;
		newFilters.idCompanies = [];
		setFilters(newFilters);
	};

	const handleShowFilters = () => setShowFilters((filterStatus) => !filterStatus);

	return (
		<Grid container spacing={1} className='mt-2'>
			<Grid item xs={4}>
				<TaskToCashCompletionGraph data={tasksCompletion} />
			</Grid>
			<Grid item xs={2}>
				<CardTaskCount data={tasksCompletion} />
			</Grid>

			<Grid item xs alignItems='end' className='mt-auto'>
				<TaskToCashOptions onClickFilter={handleShowFilters} isLoading={isLoading} filters={filters} />
			</Grid>

			<AnimatePresence>
				{showFilters && (
					<TaskToCashFilters
						tasksToCash={companyTasksToCash}
						filters={filters}
						onChangeDates={handleChangeDates}
						onChangeBranches={handleChangeBranches}
						onChangeCompanies={handleChangeCompany}
					/>
				)}
			</AnimatePresence>

			<TaskToCashTable tasksToCash={companyTasksToCash} />
		</Grid>
	);
};

export default TaskToCashDashboard;
