import React, { Component } from 'react';

import { sanitizeInput } from '@core/utils/dompurify/dompurify.js';
import editIcon from '@assets/icons/edit-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import searchIcon from '@assets/icons/search-icon.svg';
import agentConst from 'FielderUtils/roleManagementConst/agentConst';
import skillsConst from 'FielderUtils/roleManagementConst/skillsConst';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { GOOGLE_API_KEY } from 'FielderUtils/session/Session';
import { hasSkillFunctionality } from 'FielderUtils/skill/skill';
import { getCountryCallingCode } from 'libphonenumber-js';
import moment from 'moment';
import { Row, Col, Container } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Geocode from 'react-geocode';
import { formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber 
} from 'react-phone-number-input';
import { connect } from 'react-redux';
import Select from 'react-select';
import withRouter from '~/withRouter/withRouter.component';
import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';
import { isRoleValid } from '../../core/utils/roles/validateRoles';
import ErrorPopup from '../Autoassign/popups/ErrorPopup';
import RepeatedSkillPopup from '../Autoassign/popups/RepeatedSkillPopup';
import VehicleTypeContainer from './VehicleTypeContainer';
import Location from '~/Location/Location';
import { FIELDER } from '@core/constants/base.constants';

import './EditCreateDetailAgent.css';
import 'FielderUtils/style.css';
import { getAllActionsSkillsRequest, getSkills } from '@core/api/skills/skills.service';
import { getOptimizedAgentByIdRequest, sendPasswordToAgentRequest } from '@core/api/agents/agents.service';
import { assignAgentToTaskRequest } from '@core/api/task/task.service';
import CreateSkillsCard from './CreateSkillsCard';
import ResendPasswordButton from '~/ResendPasswordButton/ResendPasswordButton.component';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import defaultProfilePicture from '@assets/icons/topMenuThumbnailProfile-icon.png';
import { Avatar } from '@mui/material';
import Chips from '~/Chips/Chips.component';
import TextField from '~/TextField/TextField.component';
import FielderButton from 'FielderComponents/FielderButton';
import { validateName, validateLastname, validateEmail } from '@core/utils/validations/FieldValidations'
import {FielderPhoneField} from '~/FielderElements/FielderElements.component';

let request = require('../../client/object/request/request');

let beginningTime;
let endTime;

let control = false;
let validTime;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.enableDebug();

const { txt: TXT_ID, btn: BTN_ID, dtpckr: DTPCK_ID } = FIELDER.idElement;

class EditCreateDetailAgent extends Component {
	constructor(props) {
		super(props);

		var exampleMap = require('@assets/icons/edit-icon.svg');
		var autoSkillFunctionality = hasSkillFunctionality(props.session.account.funcionalityPurchases);

		this.state = {
			userInfo: {
				idAgent: '',
				userName: '',
				password: '',
				name: '',
				lastName: '',
				email: '',
				phone: '',
				status: 0,
				companyName: '',
				licencePlate: '',
				vehicleType: null,
				workScheduleFrom: null,
				workScheduleTo: null,
				idPointLocation: null,
				location: {
					state: 'Ciudad de México',
					country: 'Mexico',
					postalCode: '08220',
					timeZone: '',
					address: '',
					lat: '19.3916915',
					lng: '-99.2809795',
				},
				teams: [],
				skills: [],
			},

			errors: {
				location: false,
			},

			agentCountry: '',
			phoneNumberInitialValue: '',
			skillsToAdd: [],
			skills: [],
			skillsRendered: [],

			UserImage: null,
			exampleMap: exampleMap,

			showCreateSkll: false,
			showPhoneInput: false,
			activateAlert: false,
			changedMapPosition: false,
			renderPicker: false,
			disabledInput: true,

			userName: null,
			password: null,
			name: null,
			lastName: null,
			email: null,
			phone: null,
			status: null,
			companyName: null,
			licencePlate: null,
			vehicleType: 1,

			workScheduleFrom: null,
			workScheduleTo: null,

			location: null,
			skillsH: null,
			dataAgent: this.props.dataAgent ? this.props.dataAgent : null,

			message: '',

			countryCallingCode: this.props.lan.phoneNumberInput,
			showSkills: autoSkillFunctionality,
			session: this.props.session,
			popups: {
				showRepeatedPopup: false,
				showErrorPopup: false,
			},
			canCreateSkills: false,
			canDeleteSkills: false,
			canEditAgents: false,
			errorMobilePhone: ''
		};
	}

	componentDidMount = () => {
		this.setDefaultDataAgent();

		console.log(this.state.showSkills)

		const body = { idAgent: this.props.idAgent };
		const loadingConfig = { dispatch: this.props.dispatch };

		const onSuccess = ({ agent }) => {
			this.props.updateParentAgent(agent);
			this.renderAgent(agent);
		};

		getOptimizedAgentByIdRequest(body, onSuccess, console.log, loadingConfig);

		let state = this.state;
		state.canEditAgents = isRoleValid(agentConst.AGENTS, agentConst.EDIT, this.props.session.roles) || RoleUtils.isUserManager(this.props.session.user);
		state.canCreateSkills = isRoleValid(skillsConst.SKILLS, skillsConst.CREATE, this.props.session.roles);
		this.setState(state);
	};

	sendPasswordEmail = () => {
		const body = {
			agent: {
				idAgent: this.props.idAgent,
			},
		};

		const loadingConfig = { dispatch: this.props.dispatch };

		const onSuccess = () => {
			this.props.fielderMsg({
				title: this.props.lan.agents,
				message: this.props.lan.emailPasswordSuccess,
				variant: 'success',
				closeButton: true,
				duration: 5000,
			});
		};

		sendPasswordToAgentRequest(body, onSuccess, null, loadingConfig);
	};

	hidePopup = (name) => {
		let state = this.state;
		state.popups[name] = false;
		this.setState(state);
	};

	updateLocation = (newLocation, coordinates) => {
		let location = this.state.userInfo.location;

		if (coordinates.lat != location.lat && coordinates.lng != location.lng) {
			location.lat = coordinates.lat;
			location.lng = coordinates.lng;
			location.address = newLocation.address;
			location.postalCode = newLocation.postalCode;
			location.state = newLocation.state;
			location.timeZone = newLocation.timezone;
			location.country = newLocation.country;

			let state = this.state;
			state.userInfo.location = location;
			this.setState(state);
		}
	};

	renderAgent(agent) {
		let state = this.state;
		let agentInfo = agent;

		state.userInfo.userName = agentInfo.userName;
		state.userInfo.idAgent = agentInfo.idAgent;
		state.userInfo.password = agentInfo.password;
		state.userInfo.name = agentInfo.name;
		state.userInfo.lastName = agentInfo.lastName;
		state.userInfo.email = agentInfo.email;
		state.userInfo.phone = agentInfo.phone;
		state.userInfo.companyName = agentInfo.companyName;
		state.userInfo.licencePlate = agentInfo.licencePlate;
		state.userInfo.vehicleType = agentInfo.vehicleType;
		state.userInfo.idPointLocation = agentInfo.idPointLocation;
		state.userInfo.teams = agentInfo.teams;

		this.props.passMobileInformation(agentInfo.agentMobileInformation);

		var countryCallingCode = '';
		if (agentInfo && agentInfo.isoName) {
			countryCallingCode = '+' + getCountryCallingCode(agentInfo.isoName);
		}

		state.phoneNumberInitialValue = countryCallingCode + '' + agentInfo.phone;
		if (agentInfo.profileImage) {
			state.UserImage = agentInfo.profileImage;
		}

		if (agentInfo.location != null) {
			let location = {
				address: agentInfo.location.address,
				country: agentInfo.location.country,
				postalCode: agentInfo.location.postalCode,
				state: agentInfo.location.state,
				timeZone: agentInfo.location.timeZone,
				lat: agentInfo.location.latitude,
				lng: agentInfo.location.longitud,
			};
			state.userInfo.location = location;
		}

		if (agentInfo.skillsObj != null) {
			agentInfo.skillsObj.forEach((skill) => {
				state.skillsRendered.push(skill.title);
				state.userInfo.skills.push(skill.id);
			});
		}

		if (agentInfo.workScheduleFrom && agentInfo.workScheduleTo) {
			state.userInfo.workScheduleFrom = moment(agentInfo.workScheduleFrom, 'h:mm a');
			state.userInfo.workScheduleTo = moment(agentInfo.workScheduleTo, 'h:mm a');
		}

		state.agentCountry = agentInfo.isoName;
		state.countryIsoName = agentInfo.isoName;
		state.showPhoneInput = true;
		state.renderPicker = true;
		this.setState(state);
	}

	/**
	 * @desc This function change the state of the inputs,
	 * enabled/disabled on click
	 *
	 **/
	enableEdit = () => {
		var edit = this.state.disabledInput;
		this.setState({ disabledInput: !edit });

		if (this.props.skillsElegible) {
			this.getAccountSkills();
		}
	};

	addTagSkills = (newTag) => {
		var tagList = this.state.userInfo.skills;
		var tagListRendered = this.state.skillsRendered;

		if (!tagList.includes(newTag.value)) {
			// Add new tag
			tagList.push(newTag.value);
			tagListRendered.push(newTag.label);

			// Reset input and send new type to state
			let state = this.state;
			state.userInfo.skills = tagList;
			state.skillsRendered = tagListRendered;
			this.setState(state);
		}
	};

	updateSkills = (event) => this.addTagSkills(event);

	removeTagSkills = (id) => {
		let state = this.state;
		var index = state.skillsRendered.indexOf(id);
		state.userInfo.skills.splice(index, 1);
		state.skillsRendered.splice(index, 1);
		this.setState(state);
	};

	getAccountSkills = () => {
		const onSuccess = ({ skills }) => {
			if (skills && skills.length > 0) {
				const skillOptions = skills.map(({ title, id }) => ({
					label: title,
					value: id,
				}));

				// Add the skills to the state
				let state = this.state;
				state.skills = skillOptions;
				this.setState(state);
			}
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		getSkills(onSuccess, null, loadingConfig);
	};


	updateName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.name = sanitizedInput;
		this.setState(state);
	};

	updateLastName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.lastName = sanitizedInput;
		this.setState(state);
	};

	updateEmail = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.email = sanitizedInput;
		this.setState(state);
	};

	updatePassword = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.password = sanitizedInput;
		this.setState(state);
	};

	updateCompanyName = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.companyName = sanitizedInput;
		this.setState(state);
	};

	updatePhone = (event) => {
		let state = this.state;
		state.userInfo.phone = event.target.value;
		this.setState(state);
	};

	updateEndingTime = (event) => {
		let state = this.state;
		state.userInfo.workScheduleTo = event.target.value;
		this.setState(state);
	};

	updateStartingTime = (event) => {
		let state = this.state;
		state.userInfo.workScheduleFrom = event.target.value;
		this.setState(state);
	};

	updateVehicleType = (event) => {
		let state = this.state;
		state.userInfo.vehicleType = event.target.value;
		if (event.target.value === '6') {
			state.userInfo.licencePlate = '';
		}
		this.setState(state);
	};

	updateLicensePlate = (event) => {
		const sanitizedInput = sanitizeInput(event.target.value);
		let state = this.state;
		state.userInfo.licencePlate = sanitizedInput;
		this.setState(state);
	};

	validateCellPhone = (cellphone) => {
		if(cellphone.length > 0 && isValidPhoneNumber(cellphone)) {
			return true;
		} else {
			const state = this.state;
			state.errorMobilePhone = this.props.lan.messageErrorPhone
			return false; 
		}
	};

	validateSchedule = () => {
		var from = this.state.userInfo.workScheduleFrom;
		var to = this.state.userInfo.workScheduleTo;

		if (!from || !to) {
			document.getElementById('Agent-schedule-alert-icon').style.display = 'inline-block';
			return false;
		}

		validTime = moment(from, 'h:mm a').isBefore(moment(to, 'h:mm a'));

		if (!validTime) {
			document.getElementById('Agent-schedule-alert-icon').style.display = 'inline-block';
			return false;
		}

		document.getElementById('Agent-schedule-alert-icon').style.display = 'none';
		return true;
	};

	validateLicensePlate = () => {
		var name = this.state.userInfo.licencePlate;
		var nameRegex = new RegExp('^\\s+$');

		if (name !== null && !nameRegex.test(name) && name.length >= 1 && this.state.userInfo.vehicleType !== '6') {
			return true;
		} else if (this.state.userInfo.vehicleType === '6') {
			return true;
		}

		return false;
	};

	setWorkingHours = () => {
		let state = this.state;
		state.userInfo.workScheduleFrom = moment(this.state.userInfo.workScheduleFrom, 'hh:mm a').format('HH:mm');
		state.userInfo.workScheduleTo = moment(this.state.userInfo.workScheduleTo, 'hh:mm a').format('HH:mm');
		this.setState(state);
	};

	validateLocation = () => {
		let state = this.state;
		let location = state.userInfo.location;
		let isValid = location.lat !== 0 && location.lng !== 0 && location.address !== '';
		state.errors.location = !isValid;
		this.setState(state);
		return isValid;
	};

	handleCreateAgent = () => {
		const isValidName = validateName(this.state.userInfo.name);
		const isValidLastName = validateLastname(this.state.userInfo.lastName);
		const isValidEmail = validateEmail(this.state.userInfo.email);
		const isValidPhone = this.validateCellPhone(this.state.userInfo.phone);
		const isValidSchedule = this.validateSchedule();
		const isValidLicensePlate = this.validateLicensePlate();
		const isValidLocation = this.validateLocation();

		let state = this.state;
		state.name = isValidName;
		state.lastName = isValidLastName;
		state.email = isValidEmail;
		state.phone = isValidPhone;
		state.activateAlert = !isValidSchedule;
		state.licencePlate = isValidLicensePlate;
		state.errors.location = !isValidLocation;
		this.setState(state);

		this.setWorkingHours();

		if (
			isValidName &&
			isValidLastName &&
			isValidEmail &&
			isValidPhone &&
			isValidSchedule &&
			isValidLicensePlate &&
			isValidLocation
		) {
			document.getElementById('register-form-hint').style.display = 'none';

			var countryIsoName = this.state.countryIsoName;
			let userInfo = this.state.userInfo;
			userInfo.latitude = userInfo.lat;
			userInfo.longitud = userInfo.lng;
			delete userInfo.lat;
			delete userInfo.lng;

			this.props.editAgentAction(userInfo, countryIsoName);
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};

	InputInfo = (props) => {
		return (
			<div className='Agent-input-help'>
				<p>*{props.info}</p>
			</div>
		);
	};

	assignAgent = () => {
		if (this.state.dataAgent.id !== 0 && !this.state.dataAgent.assignAgent) {
			const body = {
				taskInfo: {
					technicianId: this.state.dataAgent.id,
					idTask: this.state.dataAgent.idTask,
					idUser: this.props.session.user.idUser,
					idAccount: this.props.session.user.idAccount,
				},
			};

			const onSuccess = () => {
				this.props.fielderMsg({
					title: this.props.lan.agents,
					message: this.props.lan.messageAssignAgentSuccessfully,
					variant: 'success',
					closeButton: true,
					duration: 5000,
				});
			};

			const onError = () => {
				this.props.fielderMsg({
					title: this.props.lan.agents,
					message: this.props.lan.messageErrorAssignAgent,
					variant: 'error',
					closeButton: true,
					duration: 5000,
				});
			};

			const loadingConfig = {
				dispatch: this.props.dispatch,
				name: this.props.pageName,
			};

			assignAgentToTaskRequest(body, onSuccess, onError, loadingConfig);
		} else {
			this.props.fielderMsg({
				title: this.props.lan.agents,
				message: this.props.lan.messageErrorAssignAgent,
				variant: 'error',
				closeButton: true,
				duration: 5000,
			});
		}
	};

	setDefaultDataAgent = () => {
		if (this.props.dataAgent === null) {
			this.state.dataAgent = {
				id: 0,
				idTask: 0,
				agentAssign: true,
			};
		}
	};

	checkAlert = () => {
		if (endTime != null) {
			if (validTime === false && control === false) {
				control = true;
				this.setState({ activateAlert: !this.state.activateAlert });
			}

			if (validTime === true && control === true) {
				control = false;
				this.setState({ activateAlert: !this.state.activateAlert });
			}
		}
	};

	onChangeTimeOne = (value) => {
		if (value != undefined) {
			var timeFrom = new Date(value._d);
			beginningTime = timeFrom;

			this.validateTime();
		}
	};

	onChangeTimeTwo = (value) => {
		if (value != undefined) {
			var timeFromTo = new Date(value._d);
			endTime = timeFromTo;
			this.validateTime();
		}
	};

	//Check if a moment is before another moment.
	validateTime = () => {
		if (beginningTime != null && endTime != null) {
			let initialTime = beginningTime;
			let finalTime = endTime;

			validTime = moment(initialTime, 'h:mm a').isBefore(moment(finalTime, 'h:mm a'));

			if (validTime == true) {
				var formatInitialTime = moment(initialTime, 'hh:mm a').format('HH:mm');
				var formatEndTime = moment(finalTime, 'hh:mm a').format('HH:mm');

				let state = this.state;
				state.activateAlert = false;
				state.userInfo.workScheduleFrom = formatInitialTime;
				state.userInfo.workScheduleTo = formatEndTime;
				this.setState(state);
			} else {
				let state = this.state;
				state.activateAlert = true;
				this.setState(state);
			}
		}
	};

	saveSkills = () => {
		// TODO: REMOVE
		const body = {
			requestType: request.getRequestDoAddAccountSkills().requestType,
			skillNames: this.state.skillsToAdd,
		};

		const onSuccess = (response) => {
			this.getAccountSkills();
		};

		const onError = () => {
			let state = this.state;
			state.popups.showErrorPopup = true;
			this.setState(state);
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		getAllActionsSkillsRequest(body, onSuccess, onError, loadingConfig);
	};

	removeSkill = (index, skill) => {
		let skills = this.state[skill];
		skills.splice(index, 1);

		switch (skill) {
			case 'skillsToAdd':
				this.setState({ skillsToAdd: skills });
				break;
		}
	};

	addSkill = () => {
		let input = document.getElementById('input-add-skill');
		let newSkill = input.value;

		// Lists of skills
		let skillList = this.state.skillsToAdd;

		if (!skillList.includes(newSkill) && newSkill != '') {
			// Add new skill
			skillList.push(newSkill);

			// Reset input and send new type to state
			this.setState({ skillsToAdd: skillList });
		} else {
			// Show the repeated skill popup
			let state = this.state;
			state.popups.showRepeatedPopup = true;
			this.setState(state);
		}

		input.value = '';
	};

	
	createTemsLabels = () => {
		const { userInfo } = this.state;
		if (!userInfo || !Array.isArray(userInfo.teams) || userInfo.teams.includes(null)) {
			return null;
		} 
		
		return userInfo.teams.map((type, i) => (
			<Col sm={'auto'} className='m-1' key={type.name + '-tag'}>
			<Row className='tag-button my-auto'>
				<div className='mt-1'>
				<Container key={type.name + '-tag-container'}>
					<span className='mr-3'>{type.name} </span>
				</Container>
				</div>
			</Row>
			</Col>
		));
		};

	render() {
		this.setDefaultDataAgent();

		return (
			<React.Fragment>
				{this.state.popups.showRepeatedPopup && <RepeatedSkillPopup hidePopup={this.hidePopup} {...this.props} />}
				{this.state.popups.showErrorPopup && <ErrorPopup hidePopup={this.hidePopup} {...this.props} />}

				<div className='row mb-5'>
					<div className='col-12 col-lg-6 px-4'>
						<div className='row mt-2 mx-1'>
							<div className='col-sm-3'>
								<Avatar src={this.state.UserImage || defaultProfilePicture} sx={{ width: '100%', height: '100%' }} />
							</div>
							<div className='col my-auto'>
								<div className='row'>
									<div className='col'>
										<TextField label={`${this.props.lan.userName}*`} value={this.state.userInfo.userName} disabled />
									</div>
									{this.props.showEdit && this.state.canEditAgents && (
										<div className='col-sm-1 my-auto'>
											<button className='Agent-fieldContainer-edit-btn' onClick={this.enableEdit}>
												<img src={editIcon} alt='' id='Agent-name-alert-icon' title={this.props.lan.editAgent} />
											</button>
										</div>
									)}

									<div className='col-sm-12 mt-1'>
										<ResendPasswordButton onClick={this.sendPasswordEmail} disabled={!this.state.disabledInput} />
									</div>
								</div>
							</div>
						</div>

						<div className='row my-3'>
							<div className='col-sm-12'>
								<TextField
									id={TXT_ID.name}
									value={this.state.userInfo.name}
									onChange={this.updateName}
									label={`${this.props.lan.name}*`}
									disabled={this.state.disabledInput}
									error={this.state.name === false}
									helperText={this.state.name === false && this.props.lan.nameFieldHelp}
								/>
							</div>
						</div>

						<div className='row my-3'>
							<div className='col-sm-12'>
								<TextField
									id={TXT_ID.lastName}
									value={this.state.userInfo.lastName}
									onChange={this.updateLastName}
									label={`${this.props.lan.lastName}*`}
									disabled={this.state.disabledInput}
									error={this.state.lastName === false}
									helperText={this.state.lastName === false && this.props.lan.lastNameFieldHelp}
								/>
							</div>
						</div>

						<div className='row my-3'>
							<div className='col-sm-12'>
										<TextField
											id={TXT_ID.email}
											value={this.state.userInfo.email}
											onChange={this.updateEmail}
											label={`${this.props.lan.email}*`}
											disabled={this.state.disabledInput}
											error={this.state.email === false}
											helperText={this.state.email === false && this.props.lan.emailFieldHelp}
										/>
							</div>
						</div>

						<div className='row my-3'>
							<div className='col-sm-12'>
								<TextField
									id={TXT_ID.companyName}
									value={this.state.userInfo.companyName}
									onChange={this.updateCompanyName}
									label={this.props.lan.company}
									disabled={this.state.disabledInput}
								/>
							</div>
						</div>

						<div className='row my-3'>
							{this.state.showPhoneInput && (
								<div className='col-sm-12'>
									<FielderPhoneField 
										id={TXT_ID.mobile}
										label={this.props.lan.mobilePhone}
										value={this.state.userInfo.phone}
										onChange={value => {
											const valueNumber = value || '';
											const formattedPhone = formatPhoneNumberIntl(valueNumber);
											let parsedNumber;
											try {
												parsedNumber = parsePhoneNumber(valueNumber);
											} catch (err) {
												console.warn('Failed to parse phone number:', err);
											}
											this.setState(prevState => ({
												...prevState,
												errorMobilePhone: '',
												userInfo: {
													...prevState.userInfo,
													phone: formattedPhone
												},
												countryIsoName: parsedNumber?.country,
												countryCallingCode: parsedNumber?.countryCallingCode
											}));
										}}
										lan={this.props.lan}
										isRequired={true}
										externalError={this.state.errorMobilePhone}
									/>
								</div>
							)}
						</div>

						{this.state.renderPicker && (
							<div className='row my-3'>
								<div className='col-sm-12 lato-font-label mb-1'>{`${this.props.lan.workingHours}*`}</div>
								<div className='col-sm-11'>
									<Row className='ml-2'>
										<Col sm={4}>
											<Datetime
												id={DTPCK_ID.workingHoursFrom}
												inputProps={{
													placeholder: this.props.lan.from,
													readOnly: true,
													disabled: this.state.disabledInput,
												}}
												dateFormat={false}
												initialValue={this.state.userInfo.workScheduleFrom}
												onChange={this.onChangeTimeOne}
											/>
										</Col>
										<Col sm='auto'>
											<p> &nbsp; - &nbsp; </p>
										</Col>
										<Col sm={4}>
											<Datetime
												id={DTPCK_ID.workingHoursTo}
												inputProps={{
													placeholder: this.props.lan.to,
													readOnly: true,
													disabled: this.state.editData,
												}}
												initialValue={this.state.userInfo.workScheduleTo}
												dateFormat={false}
												onChange={this.onChangeTimeTwo}
											/>
										</Col>
										<Col sm={1}>
											<img
												id='Agent-schedule-alert-icon'
												src={redAlertIcon}
												alt=''
												className='Agent-schedule-alert-icon'
											/>
										</Col>
									</Row>
								</div>

								{this.state.activateAlert && (
									<div className='col-sm-12 pl-4'>
										<this.InputInfo info={this.props.lan.createAgentWorkingHoursFieldHelp} />
									</div>
								)}
							</div>
						)}

						{this.state.showSkills && (
							<div className='row mt-3 mx-2'>
								<div className='col-sm-12 lato-font-label mb-1'>{this.props.lan.skills}</div>
								{this.state.skillsH === false && <this.InputInfo info='Selecciona almenos un skill para agente' />}
								<div className='col-11 TemplateAA-body-row-search'>
									<div className='Agent-fieldContainer ml-3'>
										<img src={redAlertIcon} alt='' id='Agent-skills-alert-icon' className='Agent-fieldContainer-icon' />

										<Row>
											<Col sm='auto'>
												<img src={searchIcon} alt='' className='TemplateAA-search-img-edit' />
											</Col>
											<Col>
												<Select
													className='TemplateAA-body-row-skills-search'
													options={this.state.skills}
													onChange={this.updateSkills}
													placeholder={this.props.lan.search}
													isDisabled={this.state.disabledInput}
												/>
											</Col>
										</Row>
									</div>
								</div>

								<div className='col-12 mt-2'>
									{!RoleUtils.isUserClient(this.props.session.user) && (
										<Chips
											elements={this.state.skillsRendered}
											onDelete={this.state.disabledInput ? null : this.removeTagSkills}
										/>
									)}
								</div>
							</div>
						)}

						{this.state.showSkills && this.state.canCreateSkills && (
							<div className='row mx-1'>
								<CreateSkillsCard
									canDelete={this.props.canDelete}
									isPopup={this.props.popup}
									disabled={this.state.disabledInput}
									getSkillsFromAccount={this.getAccountSkills}
								/>
							</div>
						)}
					</div>

					<div className='col-12 col-lg-6 px-4'>
						<VehicleTypeContainer
							lan={this.props.lan}
							updateVehicleType={this.updateVehicleType}
							userInfo={this.state.userInfo}
							disabledInput={this.state.disabledInput}
						/>
						{this.state.userInfo.vehicleType !== '6' && (
							<div className='row my-3'>
								<div className='col-sm-12 font-inputs'>
									<TextField
										id={TXT_ID.licensePlate}
										value={this.state.userInfo.licencePlate}
										label={`${this.props.lan.plate}*`}
										onChange={this.updateLicensePlate}
										error={this.state.showLicencePlate === false}
										disabled={this.state.disabledInput}
										helperText={
											this.state.showLicencePlate === false && this.props.lan.createAgentLicensePlateFieldHelp
										}
									/>
								</div>
							</div>
						)}

						<div className='row mx-2'>
							<div className='col-12'>
								<Location
									edit={!this.state.disabledInput}
									includeCoordinates
									height='200px'
									label={this.props.lan.createAgentStartingPointField}
									locationUpdate={this.updateLocation}
									error={this.state.errors.location}
									locationInfo={this.state.userInfo.location}
								/>
							</div>
						</div>

						{this.state.userInfo.teams != undefined && this.state.userInfo.teams.length > 0 && (
							<div className='row mt-2 mx-2'>
								<div className='col-sm-12 lato-font-label mb-1'>{this.props.lan.teams}</div>
								<this.createTemsLabels />
								{RoleUtils.isUserSuperAndAdministrator(this.props.session.user) && (
									<div className='col-sm-12 mt-2 d-flex justify-content-center lato-font-label mb-1'>
										<a onClick={() => this.props.navigate(ENDPOINTS.teams)}> {this.props.lan.goTeams}</a>
									</div>
								)}
							</div>
						)}

						<div id='register-form-hint' className='TemplateAA-form-hint'>
							<p>{this.props.lan.createAgentCreateButtonHelp}</p>
						</div>

						<div className='row mb-3 mt-5 Agent-btn-container'>
							{!this.state.disabledInput && (
								<button
									type='submit'
									id={BTN_ID.update}
									className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size '
									onClick={this.handleCreateAgent}
									disabled={this.state.disabledInput}
								>
									{this.props.lan.update}
								</button>
							)}
							{this.state.dataAgent && !this.state.dataAgent.agentAssign && (
								<FielderButton id={BTN_ID.update} onClick={this.assignAgent}>
									{this.props.lan.assign}
								</FielderButton>
							)}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});

export default connect(mapStateToProps)(withRouter(withSnackbar(EditCreateDetailAgent)));

export function withSnackbar(Component) {
	return function WrappedComponent(props) {
		const fielderMsg = useFielderSnackBar();
		return <Component {...props} fielderMsg={fielderMsg} />;
	};
}
