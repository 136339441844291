import RoleUtils from 'FielderUtils/roles/roleUtils';

import {
	ERROR_CODE_USER_NOT_FOUND,
	ERROR_CODE_NOT_A_USER,
	ERROR_CODE_USER_DISABLED,
	ERROR_CODE_ACCOUNT_DISABLED,
} from '../../core/constants/errorCodes.constants';
import { ENDPOINTS } from '../../core/constants/pageEndpoints.constants';

const getErrorMessage = (code, lan) => {
	switch (code) {
		case ERROR_CODE_ACCOUNT_DISABLED:
			return lan.loginDisabledAccount;
		case ERROR_CODE_USER_DISABLED:
			return lan.loginDisabledUser;
		case ERROR_CODE_USER_NOT_FOUND:
			return lan.loginInvalidUserPassword;
		case ERROR_CODE_NOT_A_USER:
			return lan.loginAgentAccesMessage;
		default:
			return lan.loginInvalidUserPassword;
	}
};

const getLoginAccountRedirect = (user, location) => {
	if (RoleUtils.isRootGlobalAccountUser(user)) {
		return ENDPOINTS.globalAccountDashboard;
	}
	if (RoleUtils.isClientGlobalAccountUser(user)) {
		return ENDPOINTS.globalAccountClientDashboard;
	}
	if (RoleUtils.isInternalAdminUser(user)) {
		return ENDPOINTS.internalAdminDashboard;
	}
	if (
		location &&
		location.state &&
		location.state.from &&
		location.state.from.pathname &&
		location.state.from.pathname === ENDPOINTS.partners
	) {
		return ENDPOINTS.partners;
	}

	return ENDPOINTS.dashboard;
};

export { getLoginAccountRedirect, getErrorMessage };
