import React from 'react';

import ArrowLeft from '@assets/icons/arrow-left-icon.svg';
import ArrowRight from '@assets/icons/arrow-right-icon.svg';
import { Row } from 'react-bootstrap';

import './Pagination.css';
class Pagination extends React.Component {
	options = [
		{ value: '10', label: '10' },
		{ value: '25', label: '25' },
		{ value: '50', label: '50' },
		{ value: '100', label: '100' },
	];

	render() {
		return (
			<div className='fielder-table-pagination'>
				<Row>
					<div className='col-4'>
						<div className='range-label'>{this.props.lan.show}</div>
						<div className='range-select'>
							<select
								id='pagination'
								onChange={this.props.onClick}
								className='custom-select'
								defaultValue={this.props.selected}
							>
								{this.options.map((element, i) => (
									<option key={element.value + 'pag' + i} value={element.value}>
										{element.label}
									</option>
								))}
							</select>
						</div>
					</div>
					<span className='col-6 ' />
					<div className='col d-flex justify-content-end'>
						<button
							type='button'
							className='btn btn-light Pagination-buttom-size'
							disabled={this.props.noPrev}
							onClick={this.props.onClickPrev}
						>
							<img src={ArrowLeft} className='Pagination-icon-margin' alt='' />{' '}
						</button>
					</div>
					<div className='col'>
						<button
							type='button'
							className='btn btn-light Pagination-buttom-size'
							disabled={this.props.noNext}
							onClick={this.props.onClickNext}
						>
							<img src={ArrowRight} className='Pagination-icon-margin' alt='' />{' '}
						</button>
					</div>
				</Row>
			</div>
		);
	}
}

export default React.memo(Pagination);
