import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import dashboardConst from 'FielderUtils/roleManagementConst/dashboardConst';
import { useSelector } from 'react-redux';

import { ENDPOINTS } from '../../../../core/constants/pageEndpoints.constants';
import { selectValidPages } from '../../../../core/store/modules/session/sessionSelectors';

const DashboardSideMenu = ({ goToPage }) => {
	const lan = useLanguage();
	const validPages = useSelector(selectValidPages);

	const title = lan.desktop;

	return (
		<>
			{validPages && validPages[dashboardConst.PAGE_NAME] && validPages[dashboardConst.PAGE_NAME].isAllowed ? (
				<Tooltip title={title} placement='right'>
					<ListItemButton onClick={goToPage(ENDPOINTS.dashboard)}>
						<ListItemIcon>
							<img className='' src={`${process.env.AWS_FIELDER_ICONS}dashboard-icon.svg`} style={{height:'25px'}} alt='dashboard' />
						</ListItemIcon>
						<ListItemText primary={title} />
					</ListItemButton>
				</Tooltip>
			) : null}
		</>
	);
};

export default DashboardSideMenu;
