import React from 'react';

import { Grid } from '@mui/material';
import { InfoWindow } from '@react-google-maps/api';

import { RouteDuration, RouteDistance, RouteVehicleIcon } from '../styled';

const RouteInfoWindow = ({ infoWindow, travelMode, task, agent, closeRoute }) => {
	return (
		<InfoWindow position={infoWindow.position} onCloseClick={closeRoute}>
			<>
				<Grid container alignItems="center">
					<Grid item xs={2}>
						<RouteVehicleIcon vehicle={travelMode} />
					</Grid>
					<Grid item>
						<RouteDuration>{infoWindow.duration}</RouteDuration>
					</Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid item xs={12}>
						<RouteDistance>{infoWindow.distance}</RouteDistance>
					</Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid item xs="auto">
						<RouteDistance>{task}</RouteDistance>
					</Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid item xs='auto'>
						<RouteDistance>{agent}</RouteDistance>
					</Grid>
				</Grid>
			</>
		</InfoWindow>
	);
};

export default React.memo(RouteInfoWindow);
