import React, { useState } from 'react';

import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const RowMenuOptions = (props) => {
	const [menuAnchor, setMenuAnchor] = useState(null);
	const open = Boolean(menuAnchor);
	const teams = props.value;

	const handleClose = () => setMenuAnchor(null);
	const handleMenu = (event) => setMenuAnchor(event);

	const onRowMenuClick = (idTeam, name) => {
		props.onRowMenuClick(idTeam, name);
		handleClose();
	};

	return (
		<React.Fragment>
			<Button
				className='button-style-row-menu'
				onClick={(e) => handleMenu(e.currentTarget)}
				style={
					teams && teams.length > 0
						? { backgroundColor: '#fcd6e8', width: '86px' }
						: { backgroundColor: 'lightgrey', width: '86px' }
				}
			>
				<KeyboardArrowDownIcon style={teams && teams.length > 0 ? { color: '#dc5c87' } : { color: 'darkgrey' }} />
				<Typography
					style={
						teams && teams.length > 0
							? {
									fontSize: '11px',
									fontFamily: 'Lato',
									textTransform: 'capitalize',
									color: '#dc5c87',
							  }
							: {
									fontSize: '11px',
									fontFamily: 'Lato',
									textTransform: 'capitalize',
									color: 'darkgrey',
							  }
					}
				>
					{teams && teams.length > 0 ? props.lan.team : props.lan.noTeam}
				</Typography>
			</Button>

			<Menu id='simple-menu' anchorEl={menuAnchor} open={open} onClose={handleClose}>
				{teams && teams.length > 0 ? (
					teams.map((team) => (
						<MenuItem onClick={() => onRowMenuClick(team.id, null)}>{`${team.id}-${team.name}`}</MenuItem>
					))
				) : (
					<MenuItem onClick={() => onRowMenuClick(props.row.idAgent, props.row.name)}>{props.lan.addTeam}</MenuItem>
				)}
			</Menu>
		</React.Fragment>
	);
};

export default RowMenuOptions;
