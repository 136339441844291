import React, { Component } from 'react';

import DangerIcon from '@assets/icons/danger-icon.svg';
import {Modal} from 'react-bootstrap';

import './ConfirmationDialog.css';
import 'FielderUtils/style.css';

class ConfirmationDialog extends Component {
	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<React.Fragment>
				<Modal
					size='sm'
					show={true}
					onHide={this.props.handleClose}
					className={
						'Fielder-modals-header-border Fielder-modals-content-border ' + `${this.props.cssModal}`
					}
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border'>
						<Modal.Title> </Modal.Title>
					</Modal.Header>

					<Modal.Body className='confirmation-message-modal-body text-center'>
						<img src={DangerIcon} className='confirmation-message-ceter-icon mb-1' alt='' />
						<div className='text-center ml-2 mr-2 mt-2 confirmation-message-margin'>
							{this.props.message}
						</div>
						{this.props.alertMessage && (
							<div className='text-center ml-2 mr-2 mt-2 confirmation-message-margin confirmation-alert-message'>
								<i>{'*' + this.props.alertMessage}</i>
							</div>
						)}
					</Modal.Body>

					<Modal.Footer>
						<div className='mt-3'>
							<button
								type='submit'
								id='id-confirmation-accept'
								className='btn btn-outline-primary btn-success Fielder-modal-btnsave-size confirmation-message-btn-margin'
								onClick={this.props.handleAction}
							>
								{this.props.lan.accept}
							</button>
							<button
								type='button'
								id='id-confirmation-cancel'
								className='btn Fielder-modal-btnsave-size confirmation-message-btn-margin confirmation-cancel-btn'
								onClick={this.props.handleClose}
							>
								{this.props.lan.cancel}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	}
}

export default ConfirmationDialog;
