import React from 'react'

import { styled } from '@mui/material/styles';
import { NavDropdown } from 'react-bootstrap';

const ProfileNavDropdown = styled(NavDropdown)`
	display: flex;
	font-size: 16px;
	line-height: 1.19;
	text-align: left;
	color: #1b253a;
	flex-direction: row-reverse !important;
	margin-top: auto;
	margin-bottom: auto;
`;

export { ProfileNavDropdown };
