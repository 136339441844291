import React from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import Graph from './Graph';

const THEMEONE = 'THEMEONE';
const THEMETWO = 'THEMETWO';

function am4themes_empresarial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color('#242C39'), // 9013fe
			am4core.color('#8A989C'),
			am4core.color('#6D8F97'),
			am4core.color('#B6C3D0'), // ce2b66
			am4core.color('#4A5865'),
		];
	}
}

// am4core.useTheme(am4themes_myTheme);

function am4themes_millenial(target) {
	if (target instanceof am4core.ColorSet) {
		target.list = [
			am4core.color('#9013fe'),
			am4core.color('#51a5de'),
			am4core.color('#f52394'),
			am4core.color('#ce2b66'),
			am4core.color('#dc5c87'),
		];
	}
}

class CurvedColumns extends Graph {
	constructor(data, props) {
		super(props);
		this.props = props;
		this.data = data;
	}

	createGraph(params) {
		const { theme } = params;
		am4core.unuseAllThemes();

		if (theme == THEMEONE) {
			am4core.useTheme(am4themes_empresarial);
		} else if (theme == THEMETWO) {
			am4core.useTheme(am4themes_millenial);
		}

		const chart = am4core.create(params.id, am4charts.XYChart);
		chart.maskBullets = false;
		const { category } = params;
		const { valueY } = params;

		const { text1 } = params;
		const { textServiceStatus } = params;

		const { dateFromDateTo } = params;
		const { textTotalTask } = params;

		const { textTotalFinished } = params;

		const { total } = params;

		chart.data = this.data;

		// Create axes
		super.setAxesCurvedColums(
			chart,
			category,
			text1,
			textServiceStatus,
			dateFromDateTo,
			textTotalTask,
			total,
			textTotalFinished
		);

		// Series

		super.setCurvedColumsSeriesParams(chart, category, valueY, this.props);

		const { legend } = params;
		super.setChartProperties(chart, legend, true, false);

		this.chart = chart;
	}

	getGraph() {
		return this.chart;
	}
}

export default CurvedColumns;
