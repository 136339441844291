import React, { useState, useEffect } from 'react';
import sendMessageToLex from '@core/utils/aws/lexBotV2/sendMessageToLex';
import validateLinksForDocumentation from '@core/constants/chatBot/validateLinksForDocumentation';
import { useSelector } from 'react-redux';
import { selectSession } from '@core/store/modules/session/sessionSelectors';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { getChatBotCountMessageByUser, updateChatBotCountMessage } from '@core/api/account/account.service';
import { BedorckWsService } from './BedorckWsService';

const SPLIT_CONST = '**/+';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
	const session = useSelector(selectSession);
	const lan = useLanguage();
	const [webSocket, setWebSocket] = useState(null);
	const [connectionId, setConnectionId] = useState(null);
	const [currentQuestion, setCurrentQuestion] = useState('');
	const DEFAULT_DOCUMENTATION = [
		{
			text: 'createnewtask',
			link: 'https://documentation.appfielder.com/intro',
			name: lan.documentation,
		},
	];

	const handleMessageToLex = async (userInput) => {

		setCurrentQuestion(userInput)
		const response = await sendMessageToLex(userInput, session, connectionId);
		const botMessagesResponse = response.messages;

		if (botMessagesResponse && botMessagesResponse.length > 0) {
			botMessagesResponse.forEach((message) => {
				const botText = message.content;
				if(botText !== 'dummy content'){
					setCurrentQuestion('')
					if (botText.includes(SPLIT_CONST)) {
						let documentationLinks = validateLinksForDocumentation(botText, lan);
						handleDocumentation(lan.documentationLink, documentationLinks);
					} else {
						createMessage(botText);
					}
				}
			});
			updateChatBotCountMessage();
		} else {
			createMessage(lan.sorryIcantHelp);
		}
	};

	const createMessage = (content) => {
		const botMessage = createChatBotMessage(content);

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, botMessage],
		}));
	};

	const handleDocumentation = (botText, documentationLinks) => {
		const botMessage = createChatBotMessage(botText, { widget: 'linkComponent', withAvatar: false });

		setState((prev) => ({
			...prev,
			messages: [...prev.messages, botMessage],
			documentationLinks,
		}));
	};

	useEffect(() => {
		const ws = new BedorckWsService(setConnectionId, createMessage, session, setCurrentQuestion)
		ws.initSocket()
		setWebSocket(ws);

		return () => {
			ws.onConnClose();
		}
	}, [])

	useEffect(() => {
		if (currentQuestion !== '') {
			webSocket.setCurrentQuestion(currentQuestion)
		}
	}, [currentQuestion])	

	
	return (
		<div>
			{React.Children.map(children, (child) => {
				return React.cloneElement(child, {
					actions: {
						handleMessageToLex,
						handleDocumentation,
					},
				});
			})}
		</div>
	);
};

export default ActionProvider;
