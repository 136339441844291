// ROLE MANAGEMENT
const TASKS = 'tasks';
const NAME = 'tasks';
const VIEW = 0;
const EDIT = 1;
const RESCHEDULE = 2;
const CANCEL = 3;
const ASSIGN_TEAMS = 4;
const ASSIGN_AGENT = 5;
const ASSIGN_PARTNERS = 6;
const ASSIGN_ASSETS = 7;
const ASSIGN_INVENTORY = 8;
const ASSIGN_FORMS = 9;
const IMPORT = 10;
const EXPORT = 11;
const CREATE = 12;

export default {
	NAME,
	TASKS,
	VIEW,
	EDIT,
	RESCHEDULE,
	CANCEL,
	ASSIGN_TEAMS,
	ASSIGN_AGENT,
	ASSIGN_PARTNERS,
	ASSIGN_ASSETS,
	ASSIGN_INVENTORY,
	ASSIGN_FORMS,
	IMPORT,
	EXPORT,
	CREATE
};
