import React, { useEffect, useRef } from 'react';

import Card from '~/Card/Card.component';
import { Grid } from '@mui/material';

import { useTheme } from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
	createChartWithData,
	addChartCursor,
	addChartCategoryAndValueAxis,
	addSeriesToChart,
} from './TaskToCashCompletionGraph.utils';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import CardTitle from '~/CardTitle/CardTitle.component';

const TaskToCashCompletenessStatusChart = ({ data }) => {
	const chartRef = useRef();
	const lan = useLanguage();

	useEffect(() => {
		const chart = createChartWithData(data);
		useTheme(am4themes_animated);
		addChartCursor(chart);
		addChartCategoryAndValueAxis(chart);
		addSeriesToChart(chart);

		chartRef.current = chart;
		return () => chart.dispose();
	}, [data]);

	const Chart = React.useMemo(
		() => <div id='taskCompletenessChart' style={{ width: '100%', height: '100%' }} className='mx-auto' />,
		[]
	);
	return (
		<Grid container justifyContent='center'>
			<Grid item xs={12}>
				<Card sx={{ height: '15rem' }}>
					<Grid container justifyContent='center' spacing={2} className='p-3'>
						<Grid item xs={12} className='pt-0'>
							<CardTitle>{lan.generalTaskStatus}</CardTitle>
						</Grid>
						<Grid item xs={10}>
							{Chart}
						</Grid>
					</Grid>
				</Card>
			</Grid>
		</Grid>
	);
};

export default TaskToCashCompletenessStatusChart;
