const PICKUP = 1;
const WORKFORCE = 2;
const DELIVERY = 3;
const REMOTE = 100000;
const MAIL = 100001;

const TASK_TYPES = {
	PICKUP,
	WORKFORCE,
	DELIVERY,
	REMOTE,
	MAIL,
};

export default TASK_TYPES;
