import { REQUEST_TYPES } from '@core/constants/register/register.request.constants';
import API_LINKS from '../apiGateWayLinks';
import { handlePostRequest } from '../request';

export const registerRequest = async (body, onSuccess, onError, loadingConfig) => {

	const requestBody = {
		...body
	};

	return handlePostRequest(API_LINKS.register, requestBody, onSuccess, onError, loadingConfig);
}