import React from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material'
import { Droppable, DragDropContext } from '@hello-pangea/dnd';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import ColumnCell from './ColumnCell';
import { styled } from '@mui/material/styles';
import Checkbox from '~/Checkbox/Checkbox.component';

const TableCellHeader = styled(TableCell)`
	font-size: 14px;
	font-family: 'Lato', sans-serif;
	font-weight: bold;
`

const getListStyle = (isDraggingOver) => ({
	padding: 4,
	overflow: 'none',
});

const TableHeader = (props) => {
	const { columns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, disabled } = props;
	const lan = useLanguage();

	return (
		<DragDropContext onDragEnd={props.onDragEnd}>
			<TableHead>
				<TableRow component={Droppable} droppableId='droppable' direction='horizontal' style={{ padding: 0 }}>
					{(provided, snapshot) => (
						<tr
							key={snapshot.toString()}
							ref={provided.innerRef}
							style={{
								...getListStyle(snapshot.isDraggingOver),
								padding: 0,
							}}
							{...provided.droppableProps}
						>
							{!disabled && (
								<TableCellHeader padding='checkbox'>
									<Checkbox
										indeterminate={numSelected > 0 && numSelected < rowCount}
										checked={rowCount > 0 && numSelected === rowCount}
										onChange={onSelectAllClick}
									/>
								</TableCellHeader>
							)}

							{columns.map((col, index) => (
								<ColumnCell
									column={col}
									index={index}
									onRequestSort={onRequestSort}
									titleColBig={props.titleColBig}
									cookie={props.cookie}
									cookieCols={props.cookieCols}
									dragDisabled={props.dragDisabled}
									orderBy={orderBy}
									order={order}
									/>
							))}
							{provided.placeholder}
						</tr>
					)}
				</TableRow>
			</TableHead>
		</DragDropContext>
	);
};

export default React.memo(TableHeader);
