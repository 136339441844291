import React, { Component } from 'react';

import editImageIcon from '@assets/icons/edit-icon.svg';
import AddIcon from '@assets/icons/red-add-icon.svg';
import { FormControlLabel, Checkbox } from '@mui/material';
import API_LINKS from 'FielderAPILinks';

import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col, Image, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from '~/Switch/Switch.component';

import Popup from '../../Popup';
import EditRouteEndPoint from '../../RouteDetails/EditRouteEndPoint';
import model from "Object/model/model.js";
import '../MyOperation.css';
import { doActionCreateRouteRequest } from '@core/api/route/route.service';
import MoreInformation from '~/MoreInformation/MoreInformation.component';
import TextDetail from '~/TextDetail/TextDetail.component';

const HIGH = 1;
const MEDIUM = 2;
const LOW = 3;

const DISTANCE = 'distance';
const PRIORITY = 'priority';
const SCHEDULE = 'schedule';

class GenerateRoutePopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;
		var switches = null;

		if (!switches) {
			switches = {
				priority: HIGH,
				distance: MEDIUM,
				schedule: LOW,
			};
		}

		this.state = {
			selectedAgents: [],
			showErrorMessage: false,
			showInvalidTypeErrorMessage: false,
			switches: {
				...switches,
			},
			//Session
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			generateRouteWithFarthest: false,
			showEditRouteEndPoint: false,
			endLocation: null,
		};
	}

	renderAgents = (response) => {
		let agents = [];
		if (response != null) {

			if (response.agents) {
				response.agents.forEach((element) => {
					let dutyText = '';
					let loc = '* ' + this.props.lan.noAgentLocation.toUpperCase() + ' *';
					if (element.location && element.location.address) {
						loc = '* ' + this.props.lan.address.toUpperCase() + ' *: ' + element.location.address;
					}
					if (element.location && element.location.tag) {
						loc = '* ' + this.props.lan.tag.toUpperCase() + ' *: ' + element.location.tag;
					}

					if (element.onDuty === false) {
						dutyText = ' / ' + this.props.lan.status + ': ' + this.props.lan.offDuty;
					}

					agents.push({
						label:
							this.props.lan.agent.toUpperCase() +
							': ' +
							element.name +
							' ' +
							element.lastName +
							' ' +
							loc +
							' ' +
							dutyText,
						value: element,
					});
				});
			}
			this.setState({ agents: agents });
		}
		return agents;
	};

	componentDidMount = () => {
		this.getAvailableAgents();
	};

	getAvailableAgents = () => {
		var idToken = getIdToken();
		var bodySend = model.getAgentsForRoute(12, 7, 2019, 12, 7, 2019, 0);
		bodySend.idUser = this.state.idUser;
		bodySend.idAccount = this.state.idAccount;

		const request = {
			method: 'POST',
			body: JSON.stringify(bodySend),
			headers: {
				'Content-type': 'application/json',
				Authorization: idToken,
			},
		};

		return fetch(API_LINKS.taskManager, request)
			.then((res) => res.json())
			.then((res) => {
				return this.renderAgents(res);
			});
	};

	/**
	 *  This function handles the change on the switches (turning on or off)
	 *  and updates the state
	 *
	 *  @param {string} type - Type of optimization to be done (priority, schedule or
	 *                         distance)
	 *  @param {string} level - The level of importance to be activated or deactivated
	 *                          (low, med, high)
	 */
	handleSwitch = (type, level) => {

			let switches = { ...this.state.switches };
			switches[type] = level;
			this.setState({ switches });

	};

	validatePriorities = () => this.validateByLevel(LOW) && this.validateByLevel(MEDIUM) && this.validateByLevel(HIGH);
	validateByLevel = (level) => {
		let switches = { ...this.state.switches };

		if (switches[DISTANCE] === level && switches[SCHEDULE] === level) {
			return false;
		}
		if (switches[DISTANCE] === level && switches[PRIORITY] === level) {
			return false;
		}
		if (switches[SCHEDULE] === level && switches[PRIORITY] === level) {
			return false;
		}
		return true;
	};
	addAgent = () => {
		const agent = this.state.agent;
		let selectedAgents = this.state.selectedAgents ? this.state.selectedAgents : [];

		if (agent && !selectedAgents.includes(agent)) {
			// Add new tag
			selectedAgents.push(agent);

			// Reset input and send new type to state
			this.setState({ selectedAgents: selectedAgents });
		}
	};

	removeAgent = (index) => {
		var agents = this.state.selectedAgents;
		agents.splice(index, 1);
		this.setState({ selectedAgents: agents });
	};

	createAgents = () =>
		this.state.selectedAgents.map((agent, i) => (
			<Col sm={'auto'} className='m-1' key={agent.idAgent + '-tag'}>
				<Row className='tag-button my-auto'>
					<div className='mt-1'>
						<Container key={agent.idAgent + '-tag-container'}>
							<span className='mr-3'>
								{agent.name +
									' ' +
									agent.lastName +
									' ' +
									(agent.location
										? ''
										: ' * ' + this.props.lan.noAgentLocation.toUpperCase() + ' * ')}
							</span>
							<button
								key={'btn-close-' + i}
								id={'btn-close-' + i}
								className='close'
								aria-label='Close'
								onClick={() => this.removeAgent(i)}
							>
								<span style={{ fontSize: '20px' }} aria-hidden='true'>
									&times;
								</span>
							</button>
						</Container>
					</div>
				</Row>
			</Col>
		));

	validateAgentsLocation = () => {
		const agents = this.state.selectedAgents;

		for (var i = 0; i < agents.length; i++) {
			const agent = agents[i];
			if (!agent.location) {
				return false;
			}
		}
		return true;
	};

	validateRoute = () => {
		let tasks = this.props.tasks;

		if (
			tasks &&
			this.state.selectedAgents &&
			this.state.selectedAgents.length > 0 &&
			this.validateAgentsLocation() &&
			this.validatePriorities()
		) {
			this.setState({ showErrorMessage: false });
			this.createRoutes();
		} else {
			this.setState({ showErrorMessage: true });
		}
	};

	getIdsAgents = () => {
		const agents = this.state.selectedAgents;
		let ids = [];

		for (var i = 0; i < agents.length; i++) {
			const agent = agents[i];
			ids.push(agent.idAgent);
		}
		return ids;
	};

	createRoutes = () => {
		const taskIds = this.props.tasks.map(({ taskId }) => taskId);
		let team = null;

		if (this.props.idTeam) {
			team = this.props.idTeam.split(' ')[0];
		}

		const request = {
			type: 'CREATE_ROUTES',
			idTasks: taskIds,
			idAgents: this.getIdsAgents(),
			routeInfo: {
				priorities: this.state.switches,
				executionDay: this.props.routeDate.date(),
				executionMonth: this.props.routeDate.month(),
				executionYear: this.props.routeDate.year(),
				idTeam: team,
			},
			endLocation: this.state.endLocation,
			generateRouteWithFarthest: this.state.generateRouteWithFarthest,
		};

		const loadingConfig = { dispatch: this.props.dispatch }
		const onSuccess = (response) => this.props.onClose({ statusCode: 200, response});
		const onError = ({ errorCode }) => {
			if (errorCode === 566) {
				this.setState({ showInvalidTypeErrorMessage: true });
			}
		}

		doActionCreateRouteRequest(request, onSuccess, onError, loadingConfig);
	};

	render() {
		return (
			<Popup
				title={this.props.lan.routeOptimization}
				icon='route-icon.svg'
				show
				width='lg'
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.optimizeRoute}
				buttonOnClick={this.validateRoute}
				size='lg'
				autoHeightMax='90vh'
			>
				<Row>
					<Col sm={12} className='mt-3 ml-1 mb-4'>
						<span className='title-padding text-title'>{this.props.lan.addAgents}</span>
					</Col>

					<Col sm={10}>
						<Select
							className='react-select__control full-width mt-1 mb-3'
							options={this.state.agents}
							onChange={(event) => {
								this.setState({ agent: event.value });
							}}
							placeholder={this.props.lan.placeHolderClient}
						/>
					</Col>
					<Col sm={2} className='d-flex justify-content-end'>
						<Image
							className='ml-3'
							src={AddIcon}
							alt={this.props.lan.addAgents}
							onClick={this.addAgent}
						/>
					</Col>
				</Row>
				<Row className='ml-3'>
					<this.createAgents />
				</Row>
				<Row className='ml-0 mt-2'>
					<FormControlLabel
						control={
							<Checkbox
								value='circle'
								id='dates-checkbox-filter'
								checked={this.state.generateRouteWithFarthest}
								onChange={(e) => {
									this.setState({
										generateRouteWithFarthest: !this.state.generateRouteWithFarthest,
									});
								}}
								color='secondary'
							/>
						}
						label={this.props.lan.generateRoutesWithTheFarthest}
						name='dates-checkbox-control'
						id='dates-checkbox-control'
					/>
					<MoreInformation title={this.props.lan.generateRoutesWithTheFarthestROInfo} placement='right' />
				</Row>
				<Row>
					<Col className='mt-2'>
						<table>
							<tbody>
								<tr>
									<td></td>
									<td className='small-popup-label text-center color-pink'>1</td>
									<td className='small-popup-label text-center color-pink'>2</td>
									<td className='small-popup-label text-center color-pink'>3</td>
								</tr>
								<tr>
									<td style={{ width: '40%' }} className='small-popup-label'>
										{this.props.lan.priority}
									</td>
									<td>
										<Switch
											id='ro-priority-high'
											useRawValue={this.state.switches.priority === HIGH}
											onChange={() => this.handleSwitch(PRIORITY, HIGH)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
									<td>
										<Switch
											id='ro-priority-medium'
											useRawValue={this.state.switches.priority === MEDIUM}
											onChange={() => this.handleSwitch(PRIORITY, MEDIUM)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
									<td>
										<Switch
											id='ro-priority-low'
											useRawValue={this.state.switches.priority === LOW}
											onChange={() => this.handleSwitch(PRIORITY, LOW)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
								</tr>
								<tr>
									<td style={{ width: '40%' }} className='small-popup-label'>
										{this.props.lan.distance}
									</td>
									<td>
										<Switch
											id='ro-distance-high'
											useRawValue={this.state.switches.distance === HIGH}
											onChange={() => this.handleSwitch(DISTANCE, HIGH)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
									<td>
										<Switch
											id='ro-distance-medium'
											useRawValue={this.state.switches.distance === MEDIUM}
											onChange={() => this.handleSwitch(DISTANCE, MEDIUM)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
									<td>
										<Switch
											id='ro-distance-low'
											useRawValue={this.state.switches.distance === LOW}
											onChange={() => this.handleSwitch(DISTANCE, LOW)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
								</tr>
								{<tr>
									<td style={{ width: '40%' }} className='small-popup-label'>
										{this.props.lan.schedule}
									</td>
									<td>
										<Switch
											id='ro-schedule-high'
											useRawValue={this.state.switches.schedule === HIGH}
											onChange={() => this.handleSwitch(SCHEDULE, HIGH)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>

									<td>
										<Switch
											id='ro-schedule-medium'
											useRawValue={this.state.switches.schedule === MEDIUM}
											onChange={() => this.handleSwitch(SCHEDULE, MEDIUM)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>

									<td>
										<Switch
											id='ro-schedule-low'
											useRawValue={this.state.switches.schedule === LOW}
											onChange={() => this.handleSwitch(SCHEDULE, LOW)}
											disabled={this.state.generateRouteWithFarthest}
										/>
									</td>
								</tr>}
							</tbody>
						</table>
					</Col>
				</Row>

				<Row className='mt-3 mb-2'>
					<Col sm>
						<TextDetail
							label={this.props.lan.routeEndPointTitle}
							text={this.state.endLocation?.address || this.state.routeEndPointDefault}
						/>
					</Col>
					<Col sm='auto' className='my-auto'>
						<img
							src={editImageIcon}
							className='icon'
							alt=''
							onClick={() => {
								this.setState({
									showEditRouteEndPoint: !this.state.showEditRouteEndPoint,
								});
							}}
						/>
					</Col>
				</Row>

				{this.state.showErrorMessage && (
					<Row>
						<Col sm={12} className='MyOperation-form-hint'>
							<p>{this.props.lan.validateAgentsAndPriority}</p>
						</Col>
					</Row>
				)}
				{this.state.showInvalidTypeErrorMessage && (
					<Row>
						<Col sm={12} className='MyOperation-form-hint'>
							<p>{this.props.lan.validateTaskTypes}</p>
						</Col>
					</Row>
				)}

				{this.state.showEditRouteEndPoint && (
					<EditRouteEndPoint
						{...this.props}
						onClose={() => {
							this.setState({
								showEditRouteEndPoint: false,
							});
						}}
						onSaveEndPoint={(endPoint) => {
							this.setState({
								endLocation: endPoint,
								showEditRouteEndPoint: false,
							});
						}}
					/>
				)}
			</Popup>
		);
	}
}
const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(GenerateRoutePopup);
