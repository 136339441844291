export enum REQUEST_TYPES {
	REASSIGN_AGENT = 'REASSIGN_AGENT',
	RESET_CANCELED_TASKS = 'RESET_CANCELED_TASKS',
	DO_RESCHEDULE_ROUTE = 'DO_RESCHEDULE_ROUTE',
	CANCEL_ROUTES = 'CANCEL_ROUTES',
	RESET_ROUTES_TASKS = 'RESET_ROUTES_TASKS',
	RESTART_ROUTE = 'RESTART_ROUTE',
	GET_ROUTE_PREFERENCES = 'GET_ROUTE_PREFERENCES',
	GET_VIEW_ROUTE_EXPENSES_PREFERENCE = 'GET_VIEW_ROUTE_EXPENSES_PREFERENCE',
	UPDATE_ROUTE_EXPENSES_PREFERENCE = 'UPDATE_ROUTE_EXPENSES_PREFERENCE',
	GET_TASK_BY_ID_AND_FOLIO = 'GET_TASK_BY_ID_AND_FOLIO'
}
