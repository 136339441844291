const STATUS = {
	CREATED: 0,
	ON_WAY: 1,
	ARRIVED: 2,
	EXECUTING: 3,
	PAUSED: 4,
	FINISH: 5,
	CANCELED: 6,
	ON_TRASH: 7
};

export default STATUS;
