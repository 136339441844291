import React, { Component } from 'react';

import { Modal, Row, Col, Container } from 'react-bootstrap';

import InfoTaskVariables from './InfoTaskVariables';

import './InfoNewModules.css';
import 'FielderUtils/style.css';
const _ = require('lodash');

class InfoTaskVariablesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: props.showModal,
		};
	}

	handleClose=()=> {
		this.props.closeModal();
	}

	render() {
		return (
			<React.Fragment>
				<Modal
					show={this.state.showModal}
					size={'md'}
					className='Fielder-modals-header-border Fielder-modals-content-border'
					onHide={this.handleClose}
					style={{ overlay: { zIndex: 1000 } }}
				>
					<Modal.Header className='Fielder-modals-create-edit-detail-header-border' closeButton>
						<div className='col ml-3 mt-3'>{this.props.lan.routeTaskVariables.toUpperCase()} </div>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row className='justify-content-md-center'>
								<Col sm={12}>
									<InfoTaskVariables {...this.props} />
									{/* Save and Cancel Buttons */}
									<Row>
										<Col sm={6} className='mx-auto mt-5'>
											<button
												className='popup-footer-btn Fielder-button'
												onClick={this.props.closeModal}
											>
												{this.props.lan.continue}
											</button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
}

export default InfoTaskVariablesModal;
