import React, { useEffect, useState } from 'react';

import AssetsIcon from '@assets/icons/taskGroup-assets-icon.svg';
import FormIcon from '@assets/icons/taskGroup-form-icon.svg';
import ImagesIcon from '@assets/icons/taskGroup-image-icon.svg';
import InventoryIcon from '@assets/icons/taskGroup-inventory-icon.svg';
import OfflineIcon from '@assets/icons/offline-agent-icon.svg';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';

import { IconButton, Tooltip } from '@mui/material';
import TaskStatus from 'FielderUtils/status/taskStatus';
import StatusFormatter from '../../SmartTable/StatusFormatter';
import { useLanguage } from 'Context/LanguageContext';
import TextField from '~/TextField/TextField.component';
import { Gainsboro } from '@core/theme/baseTheme';
import FielderButton from 'FielderComponents/FielderButton';
import CardComponent from '~/Card/Card.component';
import Checkbox from '~/Checkbox/Checkbox.component';
import LoadingConst from 'OldComponents/Loading/LoadingConst';
import { getTasksInGroupRequest, getTotalTaskFromGroup ,getTasksFormFilterRequest } from '@core/api/task/task.group.service';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';

var LIMIT = 100;
var CHUNK_SIZE = 6;

const GroupTaskSectionTask = (props) => {
	const lan = useLanguage();
	const dispatch = useDispatch();

	const [selectedCount, setSelectedCount] = useState(0);
	const [taskOfTheGroupOriginal, setTaskOfTheGroupOriginal] = useState([]);
	const [taskOfTheGroup, setTaskOfTheGroup] = useState([]);
	const [numberOfTask, setNumberOfTask] = useState(0);
	const [showTasksFromGroup, setShowTasksFromGroup] = useState(true);
	const [filter, setFilter] = useState(null);
	const [filterForm, setFilterForm] = useState(null);

	const getGroupTaskBody = () => {
		let body = {};
		if (props.idGroup) {
			body.idGroup = props.idGroup;
		} else if (props.tasksFromGroup) {
			body.idGroup = props.tasksFromGroup[0];
		} else if (props.customId) {
			body.idCustom = props.customId;
		}
		return body;
	};

	useEffect(() => {
		executeRequestTaskGroup();
	}, []);

	const executeRequestTaskGroup = async () => {
		props.setLoadingActive(true)

		const body = getGroupTaskBody();
		body.bringTask = false;
		body.filter = filter;

		const totalTasks = await getTotalTaskFromGroup(body);
		if (totalTasks > 0) {

			const totalRequests = Math.ceil(totalTasks / LIMIT);
			const requestsArray = Array.from({ length: totalRequests }, (v, k) => k);
			let data = [];
			let progress = 0;

			for (let i = 0; i < requestsArray.length; i += CHUNK_SIZE) {
				const chunk = requestsArray.slice(i, i + CHUNK_SIZE);

				await Promise.all(
					chunk.map((i) => {
						const offset = i * LIMIT;
						progress = (offset * 100) / totalTasks;
						dispatch(
							loadingStart({
								name: 'GET_TASK_BY_GROUP_TASK',
								config: {
									icon: LoadingConst.EXPORT,
									text: lan.getTaskFromGroup,
									progress,
								},
							})
						);

						const onSuccess = ({ tasks }) => data.push(...tasks);
						const onError = () => dispatch(loadingStop('GET_TASK_BY_GROUP_TASK'));
						const bodyGroup = getGroupTaskBody();
						bodyGroup.bringTask = true;
						bodyGroup.offset = offset;
						bodyGroup.limit = LIMIT;
						return getTasksInGroupRequest(bodyGroup, onSuccess, onError, null);

					})
				);

			}
			getTaskByIdGroupTask(data)
		} else {
			props.setLoadingActive(false);
			setShowTasksFromGroup(false);
		}
	}


	const getTaskByIdGroupTask = (tasks) => {
		setShowTasksFromGroup(false);

		let countNumberFinish = 0;
		let taskOfTheGroupAux = [];

		for (const task of tasks) {

			if (task.status === TaskStatus.FINISH) {
				countNumberFinish++;
			}
			let typeOfTask = task.taskTypeInfo;
			if (task.serviceTypeId >= 1 && task.serviceTypeId <= 3) {
				typeOfTask = lan['tasktype'+task.serviceTypeId];
			}

			taskOfTheGroupAux.push({
				idTask: task.idTask,
				idCustom: task.idCustom,
				folio: task.folio,
				taskStatus: task.status,
				typeOfTask: typeOfTask,
				checked: false,
				hasImage: task.countImages > 0 ? true : false,
				hasAssets: task.taskFunctionalities.assets ? task.taskFunctionalities.assets.totalCount > 0 ? true : false : false,
				hasInventory: task.taskFunctionalities.inventory ?  task.taskFunctionalities.inventory.totalCount > 0 ? true : false : false,
				hasForms: task.taskFunctionalities.forms ? task.taskFunctionalities.forms.totalCount > 0 ? true : false : false,
				listForms: task.taskFunctionalities.forms ? task.taskFunctionalities.forms.listForms ? task.taskFunctionalities.forms.listForms : null : null,
				offlineExecution: task.offlineExecution
			});
		}


		setNumberOfTask(tasks.length)
		setTaskOfTheGroupOriginal([...taskOfTheGroupAux])
		setTaskOfTheGroup([...taskOfTheGroupAux])

		dispatch(loadingStop('GET_TASK_BY_GROUP_TASK'));
		props.setCountNumberFinish(countNumberFinish)
		props.setLoadingActive(false)
	};


	const handleFilter = () => {
		let input = document.getElementById('txt-filter-task');
		let filterString = null;

		if (input != null && input.value !== '') {
			filterString = input.value.trim();

			const result = taskOfTheGroupOriginal.filter(task => {
				for (const field in task) {
					const fieldValue = task[field];
					const stringValue = typeof fieldValue === "number" ? fieldValue.toString() : fieldValue;

					if (typeof stringValue === "string" && stringValue.includes(filterString)) {
						return true;
					}
				}
				return false;
			});

		
			setTaskOfTheGroup([...result])
		}
		else {
			setTaskOfTheGroup([...taskOfTheGroupOriginal])
		}
	};

	const handleFilterForm = () => {
		let input = document.getElementById('txt-filter-forms');
		let filterString = null;

		if (input != null && input.value !== '') {
			filterString = input.value.trim();
			const result = [];

			taskOfTheGroupOriginal.forEach(task => {

				const forms = task.listForms
				const formsJSON = JSON.stringify(forms);			

				if (formsJSON.includes(filterString)) {

					result.push({
						idTask: task.idTask,
						idCustom: task.idCustom,
						folio: task.folio,
						taskStatus: task.taskStatus,
						typeOfTask: task.typeOfTask,
						checked: task.checked,
						hasImage: task.hasImage,
						hasAssets: task.hasAssets,
						hasInventory: task.hasInventory,
						hasForms: task.hasForms,
						listForms: task.listForms,
						offlineExecution: task.offlineExecution
					});
				}

			});
			
			setTaskOfTheGroup([...result])

		}else {
			setTaskOfTheGroup([...taskOfTheGroupOriginal])
		}

	};



	const handlerEventKey = (target) => {
		if (target.charCode == 13) {
			handleFilter();
		}
	};

	const handlerEventKeyForm = (target) => {
		if (target.charCode == 13) {
			handleFilterForm();
		}
	};

	const handleResetFilter = () => {
		document.getElementById('txt-filter-task').value = '';
		setFilter(null);
		props.setFilterToExport(null);
		handleFilter();
	};

	const handleResetFilterForm = () => {
		document.getElementById('txt-filter-forms').value = '';
		setFilterForm(null);
		props.setFilterIdsTask(null);
		handleFilter();
	};

	const addOrRemoveTask = (idTask, status, index, e) => {
		const validateChecked = e.target.checked;
		let taskOfTheGroupAux = taskOfTheGroup;
		taskOfTheGroupAux[index].checked = validateChecked;

		let taskSelected = props.taskSelected;
		if (validateChecked) {
			taskSelected.push({ idTask, status });
			setSelectedCount(selectedCount + 1);
		} else {
			const auxIndex = taskSelected.findIndex((task) => task.idTask === idTask);
			taskSelected.splice(auxIndex, 1);
			setSelectedCount(selectedCount - 1);
		}
		props.setTaskSelected([...taskSelected]);
		setTaskOfTheGroup([...taskOfTheGroupAux]);
	};

	const handleSelectAll = () => {
		let allTaskSelected = [];
		let taskOfTheGroupAux = taskOfTheGroup;

		for (let i = 0; i < taskOfTheGroupAux.length; i++) {
			taskOfTheGroupAux[i].checked = true;
			allTaskSelected.push({ idTask: taskOfTheGroupAux[i].idTask, status: taskOfTheGroupAux[i].taskStatus });
		}

		setSelectedCount(taskOfTheGroupAux.length);
		props.setTaskSelected([...allTaskSelected]);
		setTaskOfTheGroup([...taskOfTheGroupAux]);
	};

	const handleuncheckSelectAll = () => {
		let taskOfTheGroupAux = taskOfTheGroup;

		for (let i = 0; i < taskOfTheGroupAux.length; i++) {
			taskOfTheGroupAux[i].checked = false;
		}

		setSelectedCount(0);
		props.setTaskSelected([...[]]);
		setTaskOfTheGroup([...taskOfTheGroupAux]);
	};

	const TasksBrief = () =>
		taskOfTheGroup.map((row, index) => (
			<Grid key={row.idTask} xs={12} md={3} className='p-1'>
				<CardComponent sx={{ p: 3 }}>
					<div className='row'>
						<div className='col-sm-auto'>
							<span style={{fontWeight: 'bold', fontSize: '16px'}}>{lan.task}</span>
							<span className='textFormat-taskGroupDetail-task ml-2' style={{fontSize: '16px'}}>{row.idTask}</span>
						</div>

						<div className='col-sm-auto'>
							{row.offlineExecution && (
								<Tooltip title={lan.offlineExecution}>
									<img src={OfflineIcon} />
								</Tooltip>
							)}
						</div>

						<div className='col text-right'>
							<Checkbox
								id={`cbx-task-${row.idTask}`}
								checked={row.checked}
								onClick={(val) => addOrRemoveTask(row.idTask, row.taskStatus, index, val)}
							/>
							<label htmlFor={`checkboxTaskGroup${index}`} className='round-checkbox' style={{ cursor: 'pointer' }} />
						</div>
					</div>
					{/** ID Custom * */}
					<div className='row'>
						<div className='col'>
							<span className='textFormat-taskGroupDetail-idCustom'> {lan.customId} </span>
							<span className='textFormat-taskGroupDetail-task'>{row.idCustom}</span>
						</div>
					</div>
					{/** Folio * */}
					<div className='row'>
						<div className='col'>
							<span className='textFormat-taskGroupDetail-idCustom'> {lan.folio} </span>
							<span className='textFormat-taskGroupDetail-task'>{row.folio}</span>
						</div>
						<div className='col text-right'>
							{row.hasAssets ? <img src={AssetsIcon} className='img-fluid ml-1' alt='' /> : null}
							{row.hasInventory ? <img src={InventoryIcon} className='img-fluid ml-1' alt='' /> : null}
							{row.hasImage ? <img src={ImagesIcon} className='img-fluid ml-1' alt='' /> : null}
							{row.hasForms ? <img src={FormIcon} className='img-fluid ml-1' alt='' /> : null}
						</div>
					</div>

					<div className='row'>
						<div className='col'>
							<span className='textFormat-taskGroupDetail-idCustom'> {row.typeOfTask} </span>
						</div>
					</div>

					{/** Status * */}
					<div className='row pl-2'>
						<div className='col-auto no-padding'>
							<div className='row pl-3'>
								<div className='col-auto pl-2 my-auto'>
									<span className='textFormat-taskGroupDetail-idCustom'> {lan.status} </span>
								</div>
								<div className='col no-padding'>
									<input type='hidden' id={`taskStatusValue${index}`} value={row.taskStatus} />
									<StatusFormatter lan={lan} value={row.taskStatus} />
								</div>
							</div>
						</div>

					</div>
					<div className='row mt-2'>
							<FielderButton id={`btn-task-detail-${row.idTask}`} size='small' onClick={() => props.showTaskDetail(row.idTask)}>
								{lan.showDetail}
							</FielderButton>
					</div>
				</CardComponent>
			</Grid>
		));

	return (
		<Grid container spacing={2} className='px-3'>
			<Grid item xs={'auto'}>
				<Grid container>
					<Grid item xs={12}>{`${lan.selectedElements}: ${selectedCount}`}</Grid>
					<Grid item xs={12}>{`${lan.totalTasks}: ${numberOfTask}`}</Grid>
				</Grid>
			</Grid>
			<Grid item xs>
				<Grid container spacing={1}>
					<Grid item>
						<FielderButton size='small' onClick={handleSelectAll}>
							{lan.selectAll}
						</FielderButton>
					</Grid>
					<Grid item>
						<FielderButton size='small' onClick={handleuncheckSelectAll}>
							{lan.uncheckEverything}
						</FielderButton>
					</Grid>
				</Grid>
			</Grid>

			<Grid item sm={12} md={3} lg>
				<TextField
					id='txt-filter-task'
					size='small'
					label={lan.searchByTask}
					onEnter={handleFilter}
					startAdornment={<SearchIcon sx={{ color: Gainsboro }} />}
					endAdornment={
						filter && (
							<IconButton onClick={handleResetFilter}>
								<ClearIcon />
							</IconButton>
						)
					}
				/>
			</Grid>

			<Grid item sm={12} md={3} lg>
				<TextField
					id='txt-filter-forms'
					size='small'
					label={lan.searchByForm}
					onEnter={handleFilterForm}
					startAdornment={<SearchIcon sx={{ color: Gainsboro }} />}
					endAdornment={
						filterForm && (
							<IconButton onClick={handleResetFilterForm}>
								<ClearIcon />
							</IconButton>
						)
					}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sx={{ height: '400px', overflowY: 'auto', borderRadius: '15px', mt: 2 }}
				className='Fielder-body'
			>
				<Grid container className='p-2' spacing={2}>
					{showTasksFromGroup ? (
						<Grid item xs={12} className='my-5' align='center' style={{ fontSize: '20px', fontWeight: 'bold' }}>
							{lan.loading}
						</Grid>
					) : (
						<TasksBrief />
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GroupTaskSectionTask;
