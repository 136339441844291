import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useModal } from '../ModalContext';
import { StyledImage, FlexContainer, TitleContainer, IconBox, StyledTitle } from './styles';

interface ModalProps {
  id: string;
  title?: string;
  icon?: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  dialogActions?: React.ReactNode[];
}


const Transition = React.forwardRef<unknown, TransitionProps & { children?: React.ReactElement }>(
    (props, ref) => {
      const { children, ...otherProps } = props;
      return children ? <Slide direction="up" ref={ref} {...otherProps}>{children}</Slide> : null;
    }
  );

const Modal: React.FC<ModalProps> = ({ id, title, icon, size = 'lg', fullWidth = false, dialogActions }) => {
  const { modals, closeModal, content } = useModal();
  const currentModalState = modals?.[id];
  const currentContent = content?.[id];

  const renderIcon = () => {
    if (React.isValidElement(icon)) {
      const elementType = icon.type;
      if (typeof elementType === 'string') {
        if (elementType === 'img') {
          return <StyledImage src={icon.props.src} alt={icon.props.alt} />;
        }
      } else if ('displayName' in elementType) {
        if ((elementType.displayName as string)?.startsWith("SvgIcon")) {
          return icon;
        }
      }
    }
    return icon;
  };

  return (
    <Dialog
      open={Boolean(currentModalState?.isOpen || false)}
      onClose={() => { if (closeModal) { closeModal(id); } }}
      maxWidth={size}
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
    >
        <DialogTitle id="form-dialog-title">
            <FlexContainer>
                <TitleContainer>
                    <IconBox>{renderIcon()}</IconBox>
                    <StyledTitle>{title || 'Modal Title'}</StyledTitle>
                </TitleContainer>
                <IconButton onClick={() => { if (closeModal) closeModal(id); }}>
                  <CloseIcon />
                </IconButton>
            </FlexContainer>
        </DialogTitle>
        <DialogContent>
            {currentContent}
        </DialogContent>
        {dialogActions && dialogActions.length > 0 && (
          <DialogActions>
            {dialogActions.map((action, index) => (
              <React.Fragment key={index}>
                {action}
              </React.Fragment>
            ))}
          </DialogActions>
        )}
    </Dialog>
  );
};

export default Modal;
