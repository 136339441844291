import React, { useState } from 'react';
import { Grid, Container } from '@mui/material';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import PageTitle from '~/PageTitle/PageTitle.component';
import { CategoryButtonGroup, CategoryButton, MotionGrid } from './Dashboard.styles';
import { AnimatePresence } from 'framer-motion';
import TaskDashboard from './components/TaskDashboard.old';
import AgentDashboard from './components/AgentDashboard';
import TaskToCashDashboard from '~/TaskToCashDashboard/TaskToCashDashboard.component';
import { CATEGORIES } from './Dashboard.constants';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { useSelector } from 'react-redux';
import { selectAccountPreferences, selectUser } from '@core/store/modules/session/sessionSelectors';

const CategoryAnimations = {
	transition: { duration: 0.2 },
	initial: { y: 10, opacity: 0 },
	animate: { y: 0, opacity: 1 },
	exit: { y: -10, opacity: 0 },
};

const Dashboard = () => {
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const { allowAdminToViewTaskToCash } = useSelector(selectAccountPreferences);
	const isClient = React.useMemo(() => RoleUtils.isUserClient(user), [user]);
	const isAdmin = React.useMemo(() => RoleUtils.isUserAdministrator(user), [user]);
	const isSuperAdmin = React.useMemo(() => RoleUtils.isUserSuperAdministrator(user), [user]);
	const dashboardTitle = lan.dashboard.toUpperCase();

	const [selectedCategory, setSelectedCategory] = useState(CATEGORIES.tasks);

	const handleChangeCategory = (e, newCategory) => {
		if (!newCategory) return;
		setSelectedCategory(newCategory);
	};

	const DashboardCategory = React.useMemo(() => {
		if (selectedCategory === CATEGORIES.tasks) {
			return <TaskDashboard />;
		} else if (selectedCategory === CATEGORIES.agents) {
			return <AgentDashboard />;
		} else if (selectedCategory === CATEGORIES.taskToCash) {
			return <TaskToCashDashboard />;
		}
		return null;
	}, [selectedCategory]);

	return (
		<Container maxWidth='xl'>
			<Grid container direction='row' spacing={2} alignContent='center' alignItems='center'>
				<Grid item xs='auto'>
					<PageTitle>{dashboardTitle}</PageTitle>
				</Grid>
				<Grid item xs>
					<CategoryButtonGroup size='medium' value={selectedCategory} onChange={handleChangeCategory} exclusive>
						<CategoryButton value={CATEGORIES.tasks}>{lan.tasks}</CategoryButton>
						{!isClient && <CategoryButton value={CATEGORIES.agents}>{lan.agents}</CategoryButton>}
						{(isSuperAdmin || (isAdmin && allowAdminToViewTaskToCash)) && (
							<CategoryButton value={CATEGORIES.taskToCash}>{lan.taskToCash}</CategoryButton>
						)}
					</CategoryButtonGroup>
				</Grid>
			</Grid>
			<AnimatePresence exitBeforeEnter>
				<MotionGrid
					container
					direction='row'
					alignItems='stretch'
					spacing={1}
					key={selectedCategory}
					variants={CategoryAnimations}
					initial='initial'
					animate='animate'
					exit='exit'
					transition={CategoryAnimations.transition}
					className='mt-2'
				>
					{DashboardCategory}
				</MotionGrid>
			</AnimatePresence>
		</Container>
	);
};

export default Dashboard;
