export enum REQUEST_TYPES {
	GET_FORMS_TABLE = 'GET_FORMS_TABLE',
	DO_FILTER_FORMS = 'DO_FILTER_FORMS',
	DO_GET_SPECIFIC_FORM_TEMPLATE = 'DO_GET_SPECIFIC_FORM_TEMPLATE',
	DO_UPLOAD_TEMPLATE_FILE = 'DO_UPLOAD_TEMPLATE_FILE',
	GET_LOGS_TEMPLATE_FILE = 'GET_LOGS_TEMPLATE_FILE',
	DO_CREATE_FORM_TEMPLATE = 'DO_CREATE_FORM_TEMPLATE',
	DO_EDIT_FORM_TEMPLATE = 'DO_EDIT_FORM_TEMPLATE',
	DO_UPDATE_FORMS_STATUS = 'DO_UPDATE_FORMS_STATUS',
	DO_DELETE_FORM_TEMPLATE = 'DO_DELETE_FORM_TEMPLATE',
	GET_ALL_FORMS_TEMPLATES = 'GET_ALL_FORMS_TEMPLATES',
	GET_FORMS_TO_REPORT = 'GET_FORMS_TO_REPORT',
	DO_ACTION_GENERATE_FORMS_REPORT = 'DO_ACTION_GENERATE_FORMS_REPORT',
	DO_REACTIVATE_FORM = 'DO_REACTIVATE_FORM',
	DO_ACTION_UPDATE_TASK_FORM_PDF = 'DO_ACTION_UPDATE_TASK_FORM_PDF',
	DO_ACTION_UPLOAD_TASK_FORM_PDF = 'DO_ACTION_UPLOAD_TASK_FORM_PDF',
	DO_ACTION_GET_XLSX_OR_HTML_FROM_FILE = 'DO_ACTION_GET_XLSX_OR_HTML_FROM_FILE',
	DO_ACTION_GET_HTML = 'DO_ACTION_GET_HTML',
	DO_REMOVE_FORM_IMAGE = 'DO_REMOVE_FORM_IMAGE',
	DO_UPDATE_FORM = 'DO_UPDATE_FORM',
	GET_FORM_TEMPLATES_TO_AUTOASSIGN = 'GET_FORM_TEMPLATES_TO_AUTOASSIGN'
}