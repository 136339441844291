export const AGENT_STATUS = {
	offline: 0,
	online: 1,
	inactive: 2,
	noData: 3,
	away: 4,
};

export const OFF_DUTY_CAUSES = {
	sickness: 1,
	vacation: 2,
	permission: 3,
	disability: 4,
	endOfWorkDay: 5,
};

export const AGENT_STATUS_NAMES = ['offline', 'online', 'inactive', 'noData', 'away'];

export const STATUS_SORT_ORDER = { 1: 0, 4: 1, 0: 2, 2: 3, 3: 4 };
