// ROLE MANAGEMENT
const PAGE_NAME = 'ASSET_MANAGEMENT';
const ASSETS = 'assets';
const NAME = 'assets';
const ACCOUNT_PREFERENCES = 'showAsset';
const VIEW = 0;
const EDIT_CATEGORY = 1;
const EDIT_ASSET = 2;
const IMPORT_AND_EXPORT = 3;
const DOWNLOAD_TASK_ASSET = 4;

export default {
	PAGE_NAME,
	NAME,
	ASSETS,
	ACCOUNT_PREFERENCES,
	VIEW,
	EDIT_CATEGORY,
	EDIT_ASSET,
	IMPORT_AND_EXPORT,
	DOWNLOAD_TASK_ASSET,
};
