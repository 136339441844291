import { STATUS_SORT_ORDER, AGENT_STATUS_NAMES } from '@core/constants/agent/agentStatus.constants';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { isManager } from '../roles/isRole';

export const sortAgentsByStatus = (arr) => {
	if (!arr || arr.length === 0) return null;
	const sortedAgents = arr.slice();
	sortedAgents.sort((a, b) => STATUS_SORT_ORDER[a.status] - STATUS_SORT_ORDER[b.status]);
	return sortedAgents;
};

export const updateAgentsStatusCount = (status, oldAgent, newAgent, totalCount) => {
	const newStatus = cloneDeep(status);
	newStatus.totalCount = totalCount;

	if (oldAgent.status === newAgent.status) {
		return status;
	}

	newStatus[AGENT_STATUS_NAMES[oldAgent.status]] -= 1;
	newStatus[AGENT_STATUS_NAMES[newAgent.status]] += 1;

	return newStatus;
};

/**
 * Handles the response from the API to sort and set the correct,
 * format to the command center
 *
 * @param {*} response
 * @returns
 */
export const handleViewCommandCenterAgents = (response) => {
	// Response is constant, create a copy of the constant object
	const agents = JSON.parse(JSON.stringify(response.agentsCommandCenter));

	return {
		status: response.agentStatusCount,
		list: sortAgentsByStatus(agents),
		orderedList: agents,
		totalCount: response.totalAgents,
		hasMore: response.totalAgents > agents.length,
	};
};

/**
 * Sets the teams to use for the request if selected, or in the
 * case of a manager, sets the filter to only the teams from his
 * operation.
 *
 * @param {*} teamIds - list of team ids
 * @param {*} user - logged user
 * @returns
 */
const getTeamsFilter = (teamIds, user) => {
	let teams = [];
	if (teamIds && teamIds > 0) {
		teams = teamIds;
	} else if (isManager(user)) {
		if (user.idTeams && user.idTeams.length > 0) {
			teams = user.idTeams;
		} else {
			teams = [-1];
		}
	}
	return teams;
};

/**
 * Search for the agents filters and creates the query params for
 * the get request to return agents
 *
 * @param filters - Command Center filters (such as teams, status, task count, etc)
 * @param selectedAgents - Agents to be searched for (if the user selects specific ones)
 * @param user - Logged user
 * @param page - Page to be requested, when using pagination
 *
 * @returns object containing the necessary filters and info for the request
 */
export const getAgentsQueryParams = (filters, selectedAgents, user, page) => {
	const teams = getTeamsFilter(filters?.agent.teamIds, user);

	const queryFilters = {
		startDate: moment(filters.startDate).startOf('day').valueOf(),
		endDate: moment(filters.endDate).endOf('day').valueOf(),
		userId: user.idUser,
	};

	if (selectedAgents && selectedAgents.length > 0) {
		queryFilters.agentIds = selectedAgents.map((agent) => agent.id);
	}

	if (filters.agent.status != null) {
		queryFilters.status = filters.agent.status;
	}

	if (filters.agent.taskCount) {
		queryFilters.taskCount = filters.agent.taskCount;
	}

	if (teams) {
		queryFilters.teamIds = teams;
	}

	if (page) {
		queryFilters.page = page;
	}

	return queryFilters;
};

/**
 * When looking for a specific agent, returns the agent object
 *
 * @param {*} response - API response
 * @returns the agent object
 */
export const handleAgentDetails = (response) => {
	const { agent } = response;

	if (agent) {
		return agent;
	}
};
