import React from 'react';
import { styled } from '@mui/material/styles';
import { VehicleIcon } from '@core/constants/agent/vehicleTypes.constants';

const RouteDuration = styled('span')`
	color: #fb9ca4;
	font-family: 'Lato';
	font-size: 16px;
	font-weight: bold;
	margin-left: 0.3rem;
`;

const RouteDistance = styled('span')`
	font-family: 'Lato';
	font-size: 12px;
`;

const RouteVehicleIcon = styled(({ vehicle }) => <VehicleIcon vehicle={vehicle} />)`
	color: #e1616c;
`;


export { RouteDistance, RouteDuration, RouteVehicleIcon };
