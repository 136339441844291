import React, { Component } from 'react';

import PartnerIcon from '@assets/icons/partner-icon.svg';
import {
	getPartnersByFilterAsyncSelect,
	getPartnerInitialOptionsAsyncSelect,
} from 'FielderUtils/partner/partner';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { reassignPartnerTaskRequest } from '@core/api/task/task.service';
import AsyncSearch from '../../AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import '../MyOperation.css';

/**
 * Assign Partner Popup
 */
class ReAssignPartnerPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		let categories = [];
		categories.push(this.getFailOption());
		categories.push(this.getOtherOption());

		this.state = {
			idPartnerSelected: null,

			//Session
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			reason: '',
			categories: categories,
			category: 0,
		};
	}

	onChangeReason = (event) => {
		this.setState({
			reason: event.target.value,
		});
	};

	onChangeCategory = (event) => {
		let value = null;
		if (event != null) {
			value = event.value;
		}

		this.setState({
			category: value,
		});
	};

	getFailOption = () => {
		return {
			value: 0,
			label: this.props.lan.fail,
		};
	};
	getOtherOption = () => {
		return {
			value: 1,
			label: this.props.lan.other,
		};
	};

	reAssignPartner = () => {
		if (this.state.idPartnerSelected === null) {
			this.setState({ errorMessage: this.props.lan.selectOnePartner });
			return;
		}

		if (this.state.category === null) {
			this.setState({ errorMessage: this.props.lan.setCategory });
			return;
		}

		if (this.state.reason === '' || this.state.reason === null) {
			this.setState({ errorMessage: this.props.lan.setOneReason });
			return;
		}

		this.setState({ errorMessage: null });

		if (this.state.idPartnerSelected !== null) {
			for (let i = 0; i < this.props.idTasks.length; i++) {
				const body = {
					taskInfo: {
						idTask: this.props.idTasks[i],
						idAccountPartner: this.state.idPartnerSelected,
						idUser: this.props.session.user.idUser,
						idAccount: this.props.session.user.idAccount,
					},
					reason: this.state.reason,
					category: this.state.category,
					
				};


				const onSuccess = (response) => {
					this.props.onCloseResponse(true);
				}

				const onError = (error) => {
					console.log(error)
					this.props.onCloseResponse(false);
				}

				const loadingConfig = { dispatch: this.props.dispatch }
				reassignPartnerTaskRequest(body, onSuccess, onError, loadingConfig)


				
			}
		} else {
			this.props.onCloseResponse(false);
		}
	};

	setPartner = (event) => {
		this.setState({
			idPartnerSelected: event.value,
		});
	};

	getPartnerList = async (event) => {
		if (event.length > 0) {
			let assignList = await getPartnersByFilterAsyncSelect(
				event,
				this.state.idAccount,
				this.state.idUser,
				getIdToken()
			);

			return assignList;
		}
	};

	getInitialOptions = async () => {
		return await getPartnerInitialOptionsAsyncSelect(
			this.state.idAccount,
			this.state.idUser,
			getIdToken()
		);
	};

	render() {
		return (
			<Popup
				title={this.props.lan.reassign + ' ' + this.props.lan.partner}
				icon='partner-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.reassign}
				buttonOnClick={this.reAssignPartner}
			>
				<div style={{ color: '#DC5C87' }} className='row d-flex justify-content-end mr-3 mb-1'>
						{this.props.lan.partnerWarningAssing}
				</div>
				<div className='reassign-input-row'>
					<Col sm={'auto'} className='my-auto pl-0'>
						<img src={PartnerIcon} className='icon mr-auto' alt='' />
					</Col>
					<Col className='pr-0'>
						<AsyncSearch
							className='react-select__control full-width borderNone'
							getOptions={(e) => this.getPartnerList(e)}
							onChange={(e) => this.setPartner(e)}
							label={this.props.lan.search}
							lan={this.props.lan}
							id='all-AsyncSearch'
							showInitialOptions
							getInitialOptions={() => this.getInitialOptions()}
						/>
					</Col>
				</div>
				<Row>
					<Col sm={12}>
						<div style={{ marginTop: '15px' }}>{this.props.lan.category} :</div>
						<Select
							className='react-select__control mt-1 mb-3 reassign-row'
							multi={'false'}
							cacheOptions
							options={this.state.categories}
							onChange={this.onChangeCategory}
							defaultValue={this.getFailOption()}
							placeholder={this.props.lan.selectPlaceHolder}
							isClearable={true}
						/>
					</Col>
					<Col sm={12}>
						<div style={{ marginTop: '15px' }}>{this.props.lan.reason} :</div>
						<textarea
							onChange={this.onChangeReason}
							value={this.state.reason}
							className='reassign-partner-textarea'
						/>
					</Col>
				</Row>

				<Row>
					{this.state.errorMessage && (
						<Col sm={12} className='MyOperation-form-hint'>
							<p>{this.state.errorMessage}</p>
						</Col>
					)}
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(ReAssignPartnerPopup);
