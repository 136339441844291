import React, { useState, useEffect } from 'react';
import { Fade, Grid, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import AddUserModals from './AddUserModals';
const TopMenuAddUser = ({ history, user }) => {
	const lan = useLanguage();
	const [anchor, setAnchor] = useState(null);
	const [show, setShow] = useState(true);
	const [showModal, setShowModal] = useState({
		newAdministrator: false,
		newManager: false,
		newClient: false,
		newAgent: false,
		newBranch: false,
	});
	const menuOpen = Boolean(anchor);
	useEffect(() => {
		const isNotClientOrGlobalUser = !(
			RoleUtils.isRootGlobalAccountUser(user) ||
			RoleUtils.isClientGlobalAccountUser(user) ||
			RoleUtils.isUserClient(user)
		);

		setShow(isNotClientOrGlobalUser);
	}, [user]);

	const hideModals = () => {
		setShowModal({
			newAdministrator: false,
			newManager: false,
			newClient: false,
			newAgent: false,
			newBranch: false,
		});
	};
	const handleOpenMenu = (e) => {
		setAnchor(e.currentTarget);
	};
	const handleClose = () => {
		setAnchor(null);
	};
	const handleShowModals = (modal) => () => {
		handleClose();
		setShowModal({ ...showModal, [modal]: true });
	};
	const AddUserMenu = () => {
		const addUserItems = ['newAdministrator', 'newManager', 'newAgent','newClient', 'newBranch'];
		if (RoleUtils.isUserManager(user)) {
			addUserItems.splice(0, 3);
		}
		const MenuItems = addUserItems.map((item) => (
			<MenuItem id={`TopMenuDropdown-${item}`} key={`TopMenuDropdown-${item}`} onClick={handleShowModals(item)}>
				{lan[item]}
			</MenuItem>
		));
		return (
			<Menu id='TopMenuAddUser-Menu' anchorEl={anchor} open={menuOpen} onClose={handleClose} TransitionComponent={Fade}>
				{MenuItems}
			</Menu>
		);
	};
	return (
		<>
			{show && (
				<Grid item>
					<>
						<IconButton onClick={handleOpenMenu}>
							<Tooltip title={lan.addUser}>
								<img
									src={
										menuOpen
											? `${process.env.AWS_FIELDER_ICONS}add-user-red-icon.svg`
											: `${process.env.AWS_FIELDER_ICONS}add-user-icon.svg`
									}
									alt=''
									className={showModal ? 'Fielder-TopMenu-icon-active' : 'Fielder-TopMenu-icon'}
								/>
							</Tooltip>
						</IconButton>
						<AddUserMenu />
					</>
					<AddUserModals history={history} showModal={showModal} hideModals={hideModals} />
				</Grid>
			)}
		</>
	);
};

export default TopMenuAddUser;
