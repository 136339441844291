// ROLE MANAGEMENT
const PAGE_NAME = 'ISOCHRONOUS';
const ISOCHRONOUS = 'isochronoes';
const NAME = 'isochronoes';
const ACCOUNT_PREFERENCES = 'showIsochronous';
const VIEW = 0;
const EDIT = 1;
const DELETE = 2;
const CREATE = 3;

export default {
	PAGE_NAME,
	NAME,
	ISOCHRONOUS,
	ACCOUNT_PREFERENCES,
	VIEW,
	EDIT,
	DELETE,
	CREATE,
};
