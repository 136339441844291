const locationOf = (element, array, options = {}, start = 0, end = array.length) => {
	start = start || 0;
	end = end || array.length;

	const pivot = parseInt(start + (end - start) / 2, 10);
	const getValue = options.customComparisonValue ? options.customComparisonValue : (el) => el;

	const arrVal = getValue(array[pivot]);
	const elVal = getValue(element);

	if (arrVal === elVal) return pivot;

	if (end - start <= 1) {
		if (options.reversed) return arrVal < elVal ? pivot - 1 : pivot;
		return arrVal > elVal ? pivot - 1 : pivot;
	}

	if (arrVal < elVal) {
		if (options.reversed) return locationOf(element, array, options, start, pivot);
		return locationOf(element, array, options, pivot, end);
	}

	if (options.reversed) return locationOf(element, array, options, pivot, end);
	return locationOf(element, array, options, start, pivot);
};

const insertSorted = (element, array, options) => {
	const loc = locationOf(element, array, options);
	array.splice(loc + 1, 0, element);
	return array;
};

export default insertSorted;
