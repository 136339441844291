import React from 'react';

import { Chip, Grid } from '@mui/material';

const Tags = ({ tags }) => {
	return (
		<Grid container spacing={1}>
			{tags.map((tag) => (
				<Grid item xs='auto' key={tag}>
					<Chip size='small' label={`#${tag}`} sx={{ fontFamily: 'Lato' }} />
				</Grid>
			))}
		</Grid>
	);
};

export default Tags;
