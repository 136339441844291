import React from 'react';

import recivedInvitationIcon from '@assets/icons/recived-invitation-icon.svg';
import sendInvitationIcon from '@assets/icons/send-invitation-icon.svg';
import { Image } from 'react-bootstrap';

class IconFormatterInvitation extends React.Component {
	render() {
		const type = this.props.value;
		let icon = null;
		if (type === 0) {
			icon = recivedInvitationIcon;
		} else if (type === 1) {
			icon = sendInvitationIcon;
		}

		return (
			<div className='row no-padding'>
				{icon !== null && <Image src={icon} alt='' className='SmartTable-icon-image' />}
			</div>
		);
	}
}

export default IconFormatterInvitation;
