import React, { Component } from 'react';

import AssignGroupTaskIcon from '@assets/icons/edit-group-tasks-icon.svg';
import { getGroupsByFilterAsyncSelect, getGroupsInitialOptionsAsyncSelect } from 'FielderUtils/group/group';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import AsyncSearch from '../../AsyncSearch/AsyncSearch';
import Popup from '../../Popup';
import '../MyOperation.css';
import { assignTaskToGroupRequest } from '@core/api/task/task.group.service';

var request = require('FielderRequestTypes');
/**
 * TODO:
 * Assign Agent Popup
 */
class AssignGroupTaskPopup extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;
		this.state = {
			idGroupSelected: null,
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
		};
	}

	assignGroupTasks = () => {
		if (!this.state.idGroupSelected) return;

		const body = {
			idGroup: this.state.idGroupSelected,
			tasks: this.props.idTasks,
		};

		const onSuccess = () => {
			this.handleCloseModal();
			this.props.onClose();
		};

		const loadingConfig = { dispatch: this.props.dispatch };
		assignTaskToGroupRequest(body, onSuccess, null, loadingConfig);
	};

	setFieldAssign = (event) => {
		let idGroupSelected = null;
		if (event) {
			idGroupSelected = event.value.id
		}
		this.setState({ idGroupSelected:idGroupSelected });	
	}

	handleCloseModal() {
		this.props.setDefaultFalseAssign();
	}

	getAssignList = async (event) => {
		if (event.length > 0) {
			let assignList = [];
			assignList = await getGroupsByFilterAsyncSelect(event, this.state.idAccount, this.state.idUser, getIdToken());
			return assignList;
		}
	};

	getInitialOptions = async () => {
		return await getGroupsInitialOptionsAsyncSelect(this.state.idAccount, this.state.idUser, getIdToken());
	};

	render() {
		return (
			<Popup
				title={this.props.lan.assignedGroupTaskTitle}
				icon='edit-group-tasks-icon.svg'
				show
				closeButton
				onClose={this.props.onClose}
				buttonText={this.props.lan.assign}
				buttonOnClick={this.assignGroupTasks}
			>
				<Row className=''>
					<Col sm={'auto'} className='my-auto'>
						<p style={{ color: '#dc5c87' }}> {this.props.lan.taskGroupNotification}</p>
					</Col>
				</Row>
				<Row className=''>
					<Col sm={'auto'} className='my-auto'>
						<img src={AssignGroupTaskIcon} className='icon mr-auto' alt='' />
					</Col>
					<Col>
						<AsyncSearch
							className='react-select__control full-width'
							getOptions={(e) => this.getAssignList(e)}
							onChange={(e) => this.setFieldAssign(e)}
							label={this.props.lan.createAgentSkillsFieldPlaceHolder}
							lan={this.props.lan}
							id='all-AsyncSearch'
							showInitialOptions
							getInitialOptions={() => this.getInitialOptions()}
						/>
					</Col>
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AssignGroupTaskPopup);
