import React, { useCallback, useEffect, useRef, useState } from 'react';

import ModifyTask from '@assets/icons/arrow-modify.svg';
import lottie from '@assets/lotties/loadingFielder.json';
import {
	Checkbox,
	Fade,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Table,
	Toolbar,
	Tooltip,
	Typography,
	Grid,
} from '@mui/material';
import { alpha } from '@mui/material/styles'
import { styled } from '@mui/material/styles';
import TextField from '~/TextField/TextField.component';
import ReorderIcon from '@mui/icons-material/Reorder';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLanguage } from 'Context/LanguageContext';
import { getObjectCookie } from 'FielderUtils/cookie/cookieUtil';
import { Col, Row, Image } from 'react-bootstrap';
import { getCookiesRequest, updateCookiesRequest } from '@core/api/cookie/cookie.service';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import Lottie from 'react-lottie';

import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { isEqual } from 'lodash';

let tableScrollPositionY = 0;
let tableScrollPositionX = 0;

const ToolbarTitle = styled(Typography)`
	flex: 1 1 100%;
`;

const TableToolbar = styled(Toolbar)(({ theme, hasSelected }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1),
	...(hasSelected && theme.palette.type === 'light'
		? {
				color: theme.palette.secondary.main,
				backgroundColor: alpha('#FFE2EC', 0.85),
		  }
		: {
				color: theme.palette.text.primary,
				backgroundColor: '#FFE2EC',
		  }),
}));

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const MainTable = (props) => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');
	const [selected, setSelected] = useState([]);
	const [hasMore, setHasMore] = useState(props.hasMore);
	const [cookies, setCookies] = useState(null);
	const [cookieCols, setCookieCols] = useState({});
	const [filteredData, setFilteredData] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	const [rows, setRows] = useState(props.rows);
	const [rowsLength, setRowsLength] = useState(0);
	const [columns, setColumns] = useState([]);
	const [changedOrder, setChangedOrder] = useState(0);
	const [hideCols, setHideCols] = useState(false);
	const [hideReorder, setHideReorder] = useState(false);
	const tableRef = useRef(null);
	const [refreshCheck, setRefreshCheck] = useState(false);

	const lan = useLanguage();

	const selectRow = (row) => setSelected(row);

	const HideCols = () => (
		<Menu anchorEl={hideCols} keepMounted open={Boolean(hideCols)} onClose={hideColsAction} TransitionComponent={Fade}>
			{columns.map((col) => {
				let name = '';
				if (!col.empty) {
					if (col.name) {
						if (col.name in lan) {
							name = lan[col.name];
						} else {
							name = col.name;
						}
					} else {
						name = lan[col.id];
					}
				}

				return (
					<MenuItem key={`menuItem-${col.id}`}>
						<ListItem>
							<ListItemIcon>
								<Checkbox checked={cookieCols[col.id]} onChange={() => changeCookie(col.id, !cookieCols[col.id])}  sx={{
          "&.Mui-checked": {
			  color: "#ed1039 !important",
          }
        }}  />
							</ListItemIcon>
							<ListItemText primary={name} />
						</ListItem>
					</MenuItem>
				);
			})}
		</Menu>
	);

	const hideColsAction = () => {
		setHideCols(null);

		const cookieColsObj = cookieCols;

		const cookieName = cookies.tableCookieName;
		const body = getObjectCookie(cookieName, cookieColsObj);
		updateCookiesRequest(body);
	};

	const reorderModify = () => {
		setHideReorder(false);
		props.reorderModify();
	};

	const reorderFormSignature = () => {
		setHideReorder(false);
		props.reorderFormSignature();
	};

	const HideReorder = () => (
		<Menu
			anchorEl={hideReorder}
			keepMounted
			open={Boolean(hideReorder)}
			onClose={() => setHideReorder(null)}
			TransitionComponent={Fade}
		>
			<MenuItem>
				<ListItem>
					<ListItemIcon>
						<Image src={ModifyTask} alt='' className='SmartTable-more-icon-info' />
					</ListItemIcon>
					<ListItemText primary={lan.reorderByModifyStatus} onClick={reorderModify} />
				</ListItem>
			</MenuItem>
			<MenuItem>
				<ListItem>
					<ListItemIcon>
						<DriveFileRenameOutlineIcon />
					</ListItemIcon>
					<ListItemText primary={lan.reorderBySignature} onClick={reorderFormSignature} />
				</ListItem>
			</MenuItem>
		</Menu>
	);

	const fixedTooltips = () => {
		const fixedTooltipsArray = [
			{
				title: lan.showTableColumns,
				index: 0,
				action: (e) => {},
				icon: (
					<>
						<VisibilityIcon onClick={(e) => setHideCols(e.currentTarget)} />
						<HideCols />
					</>
				),
			},
		];

		if (props.isMyOperation) {
			fixedTooltipsArray.push({
				title: lan.filterByStatusModification,
				index: 1,
				action: (e) => {},
				icon: (
					<>
						<ReorderIcon onClick={(e) => setHideReorder(e.currentTarget)} />
						<HideReorder />
					</>
				),
			});
		}

		return fixedTooltipsArray;
	};

	useEffect(() => {
		scrollToTop();
	}, [tableRef, rows]);

	const scrollToTop = () => {
		const scrollbars = tableRef.current;
		if (scrollbars != null && rows.length > 0) {
			scrollbars.scrollTop(tableScrollPositionY);
			scrollbars.scrollLeft(tableScrollPositionX);
		}
	};

	useEffect(() => {
		setRows(props.rows);

		if (props.cookie) {
			setCookies(props.cookie);

			const cookieObj = {};

			// Check for Visibility columns cookie Cookie
			if (props.getDynamoCookies != undefined && props.getDynamoCookies === true) {
				const cookieName = props.cookie.tableCookieName;

				const body = { filterCookie: { cookieName } };

				const onSuccess = ({ cookiesTable }) => {
					const columnOrder = props.cols;
					const newOrderByCookie = [];

					cookiesTable.forEach((cookie) => {
						cookieObj[cookie.label] = cookie.active;

						for (let n = 0; n < columnOrder.length; n++) {
							if (columnOrder[n].id == cookie.label) {
								newOrderByCookie.push(columnOrder[n]);
								break;
							}
						}
					});

					if (props.reorderCols != undefined) {
						props.reorderCols(newOrderByCookie);
						setChangedOrder(changedOrder + 1);
					}

					props.cookie.cookie.set(cookieName, cookieObj);
				};

				const onError = (error) => {
					props.cols.forEach((col) => {
						cookieObj[col.id] = true;
					});

					props.cookie.cookie.set(cookieName, cookieObj);
				};

				getCookiesRequest(body, onSuccess, onError, null);
			} else if (props.cookie.cookie.get(props.cookie.tableCookieName) == undefined) {
				// Default columns
				props.cols.forEach((col) => (cookieObj[col.id] = true));
				props.cookie.cookie.set(props.cookie.tableCookieName, cookieObj);
			} else {
				const cookieInfo = props.cookie.cookie.get(props.cookie.tableCookieName);

				const columnOrder = props.cols;
				const newOrderByCookie = [];

				for (const key in cookieInfo) {
					cookieObj[key] = cookieInfo[key];

					for (let n = 0; n < columnOrder.length; n++) {
						if (columnOrder[n].id == key) {
							newOrderByCookie.push(columnOrder[n]);
							break;
						}
					}
				}

				if (props.reorderCols != undefined) {
					props.reorderCols(newOrderByCookie);
					setChangedOrder(changedOrder + 1);
				}
			}
			setCookieCols(cookieObj);
		}
	}, []);

	useEffect(() => setColumns(props.cols), [changedOrder]);
	useEffect(() => {
		if (!isEqual(props.rows, rows)) {
			setRows(props.rows);
		}

		if (props.selectAllRowsInitially) {
			const newSelected = props.rows.map((n, i) => i);
			setSelected(newSelected);

			if (props.updateSelected) {
				const rowsSelected = rows.filter((row, i) => newSelected.includes(i));
				props.updateSelected(newSelected, rowsSelected);
			}
		} else if (props.dontResetSelected) {
			setSearchValue(null);
		} else {
			setSearchValue(null);
		}
	}, [props.updateSelected, props.dontResetSelected, props.selectAllRowsInitially, rows, props.rows]);

	const changeCookie = (title, value) => {
		const cookieColsObj = { ...cookieCols };
		cookieColsObj[title] = value;

		cookies.cookie.set(cookies.tableCookieName, cookieColsObj);
		setCookieCols(cookieColsObj);
		setRefreshCheck(!refreshCheck);
	};

	useEffect(() => {
		setSelected([]);
	}, [props.refreshSelected]);

	useEffect(() => {
		if (rows.length != rowsLength) {
			setRowsLength(rows.length);
		}
	}, [rows]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n, i) => i);

			if (props.returnAllSelectedRows === true) {
				for (let i = 0; i < newSelected.length; i++) {
					props.onCheckboxClick(rows[i], true);
				}
			}
			setSelected(newSelected);

			const rowsSelected = rows.filter((row, i) => newSelected.includes(i));

			if (props.updateSelected) {
				props.updateSelected(newSelected, rowsSelected);
			}

			return;
		}

		if (event.target.checked === false && props.returnAllSelectedRows === true) {
			props.onCheckboxClick(null, false);
		}

		setSelected([]);
		props.updateSelected([], []);
	};

	const Loader = () => {
		if (props.hasMore) {
			return (
				<Row>
					<Col sm={2} className='mx-auto mt-3'>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: lottie,
							}}
							height='40%'
							width='40%'
						/>
					</Col>
				</Row>
			);
		}
		return <div />;
	};

	const onDragEnd = useCallback(
		(e) => {
			// Dropped outside
			if (!e.destination) {
				return;
			}

			const list = reorder(columns, e.source.index, e.destination.index);

			const cookieColsObjTemp = cookieCols;
			const cookieColsObjReorder = {};

			for (let x = 0; x < list.length; x++) {
				cookieColsObjReorder[list[x].id] = cookieColsObjTemp[list[x].id];
			}

			const cookieName = props.cookie.tableCookieName;
			const body = getObjectCookie(cookieName, cookieColsObjReorder);

			updateCookiesRequest(body);

			cookies.cookie.set(cookies.tableCookieName, cookieColsObjReorder);
			setCookieCols(cookieColsObjReorder);

			props.reorderCols(list);
			setChangedOrder(changedOrder + 1);
		},
		[columns]
	);

	const tooltipClicked = (action) => {
		const rowsSelected = rows.filter((row, i) => selected.includes(i));
		action(selected, rowsSelected);
		setSelected([]);
	};

	/**
	 * @param {int} numSelected - number of element selected
	 * @example
	 *
	 * const tooltips = [{
	 *   title: props.lan.delete,
	 *   action: ()=>{},
	 *   icon: <Delete />
	 * }, {
	 *   title: props.lan.delete,
	 *   action: ()=>{},
	 *   icon: <img src={ViewMapIcon} />
	 * }];
	 *
	 * <MainTable
	 *           InfiniteScroll
	 *           loadMore={loadMore}
	 *           hasMore={hasMore}
	 *           reorderCols={(cols)=>setColumns(cols)}
	 *           cols={columns}
	 *           rows={rows}
	 *           onRowClick={(e)=>setDetail(e)}
	 *           tooltips={tooltips}
	 *           {...props}
	 *       />
	 */
	const ActionToolbar = ({ numSelected }) => {

		return (
			<TableToolbar key='tool-action' hasSelected={numSelected > 0}>
				{numSelected > 0 && (
					<ToolbarTitle color='inherit' variant='subtitle1'>
						{numSelected} {lan.selectedMasc}
					</ToolbarTitle>
				)}

				{numSelected > 0 &&
					props.tooltips &&
					props.tooltips.map(({ title, icon, action }) => (
						<Tooltip key={title} title={title}>
							<IconButton aria-label={title} className='ml-auto' onClick={() => tooltipClicked(action)}>
								{icon}
							</IconButton>
						</Tooltip>
					))}
			</TableToolbar>
		);
	};

	const autoMinHeight = React.useMemo(() => {
		if (props.height) {
			return props.height;
		}
	}, [props.height]);

	const handleSearch = (event) => {
		let newFilteredData = [];
		newFilteredData = rows.filter((item) => {
			const matchesItems = Object.values(item);
			let retVal = null;

			const found = matchesItems.some((e) => {
				const regex = new RegExp(event.target.value, 'gi');
				if (typeof e === 'string') {
					retVal = e.match(regex);
				} else if (typeof e === 'number') {
					retVal = `${e}`.match(regex);
				} else if (e != null && typeof e === 'object') {
					if ('key' in e && e.key != null) {
						retVal = e.key.match(regex);
					} else if ('value' in e && e.value != null) {
						retVal = e.value.match(regex);
					}
				}

				if (retVal) {
					return true;
				}
			});

			return found;
		});

		setFilteredData([...newFilteredData]);
		setSearchValue(event.target.value);
	};

	const onEnter = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			props.executeTableSearch(e);
		}
	};

	const MainTableComponent = () => (
		<Table
			stickyHeader={props.stickyHeader}
			aria-label='sticky table'
			key='table'
			size={props.dense ? 'small' : 'medium'}
		>
			<TableHeader
				cookie={props.cookie}
				cookieCols={cookieCols}
				columns={columns}
				onDragEnd={onDragEnd}
				titleColBig={props.titleColBig}
				numSelected={selected.length}
				order={order}
				orderBy={orderBy}
				onSelectAllClick={handleSelectAllClick}
				onRequestSort={handleRequestSort}
				rowCount={rowsLength}
				disabled={props.disabled}
				key='table-header'
			/>

			<TableBody
				search={props.search}
				searchWithEnter={props.searchWithEnter}
				cookie={props.cookie}
				cookieCols={cookieCols}
				onRowClick={props.onRowClick}
				rows={rows}
				order={order}
				orderBy={orderBy}
				selected={selected}
				columns={columns}
				disabled={props.disabled}
				setSelected={selectRow}
				onCheckboxClick={props.onCheckboxClick}
				updateSelected={props.updateSelected}
				filteredData={filteredData}
				onRowClickButton={props.onRowClickButton}
				disabledDetailButton={props.disabledDetailButton}
				isMyOperation={props.isMyOperation}
			/>
		</Table>
	);

	const loadMore = () => {
		if (hasMore) {
			props.loadMore();
		}
	};

	const scrollTable = () => {
		if (tableRef.current != null) {
			tableScrollPositionY = tableRef.current.getValues().scrollTop;
			tableScrollPositionX = tableRef.current.getValues().scrollLeft;
		}
	};

	return (
		<>
			{!props.hideTooltips && !props.disabled && (
				<Grid container style={{ width: '630px' }} className='mt-2'>
					<Grid item xs='auto'>
						<Toolbar key='tool-search'>
							<ToolbarTitle key='typegraphy-key' />
							{fixedTooltips().map(({ title, icon, action, index }) => (
								<Tooltip placement='left-end' key={index} title={title}>
									<IconButton aria-label={title} key='icon-title' onClick={(e) => action(e)}>
										{icon}
									</IconButton>
								</Tooltip>
							))}
						</Toolbar>
					</Grid>

					<Grid item xs='auto'>
						{selected.length > 0 && !props.hideToolbar && <ActionToolbar numSelected={selected.length} />}
					</Grid>

					{props.search && (
						<Grid item xs className='pl-1'>
							<TextField id='txt-table-search' label={lan.search} onChange={handleSearch} value={searchValue} />
						</Grid>
					)}

					{props.searchWithEnter && (
						<Grid item xs>
							<TextField id='txt-table-search-enter' label={lan.pressEnterToSearch} onKeyPress={onEnter}/>
						</Grid>
					)}
				</Grid>
			)}

			<Scrollbars autoHeight autoHeightMin={autoMinHeight} ref={tableRef} onScrollStop={scrollTable}>
				{props.InfiniteScroll ? (
					<InfiniteScroll // can change to a self-made with intersectionObserver
						pageStart={0}
						loadMore={loadMore}
						loader={<Loader key='loader' />}
						useWindow={false}
						initialLoad={false}
						threshold={50}
						hasMore={hasMore}
					>
						<MainTableComponent />
					</InfiniteScroll>
				) : (
					<MainTableComponent />
				)}
			</Scrollbars>
		</>
	);
};

export default React.memo(MainTable);
