import React from 'react';

import { Grid } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';

import { OpenCellIconButtonVariants } from '../TaskToCashTable.utils';
import { MotionIconButton } from '../TaskToCashTable.styles';

const ToggleIcon = ({ row, onToggleParent, isOpen }) => {
	const { id, children } = row;
	const isParent = Boolean(children && children.length > 0);

	if (!isParent) {
		return null;
	}
	const handleOpenToggle = () => onToggleParent(id);

	return (
		<Grid item xs='auto'>
			<MotionIconButton
				id={`${id}-toggle-button`}
				aria-label='expand row'
				size='small'
				onClick={handleOpenToggle}
				variants={OpenCellIconButtonVariants}
				animate={isOpen ? 'open' : 'close'}
				initial='close'
			>
				<KeyboardArrowRight />
			</MotionIconButton>
		</Grid>
	);
};

export default ToggleIcon;
