import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CottonCandy, OrchidCrayola } from '@core/theme/baseTheme';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const LinearProgressBar = styled(LinearProgress)`
	height: 8px;
	border-radius: 5px;
	background-color: ${CottonCandy};

	& .${linearProgressClasses.bar1Determinate} {
		background-color: ${OrchidCrayola}
	}
`;

export const PercentageTitle = styled(Typography)`
	font-family: 'Lato';
	font-size: 0.75rem;
`;

export const FlexBox = styled(Box)`
	display: flex;
	align-items: center;
`;
