import React from 'react';

import KeyIcon from '@mui/icons-material/Key';

import { PasswordButton } from './ResendPasswordButton.styles';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';
import { FIELDER } from '@core/constants/base.constants';

const { btn: BTN_ID } = FIELDER.idElement;

const ResendPasswordButton = ({ onClick, disabled }) => {
	const lan = useLanguage();

	return (
		<PasswordButton id={BTN_ID.resendPasssword} onClick={onClick} disabled={disabled} startIcon={<KeyIcon />}>
			{lan.resendPassword}
		</PasswordButton>
	);
};

export default ResendPasswordButton;
