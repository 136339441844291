import ky from 'ky';

const getTimeZoneFromGoogle = async (lat, lng) => {
	const timestamp = Date.now() / 1000;
	const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.GOOGLE_API_KEY}`;
	const googleResponse = await ky.post(url);

	if (googleResponse) {
		return await googleResponse.json();
	}

	return null;
};
export const getTimezoneFromLocation = async (lat, lng) => {
	return await getTimeZoneFromGoogle(lat, lng)
		.then((response) => {
			if (response && response.timeZoneId) {
				return response.timeZoneId;
			} else {
				return Intl.DateTimeFormat().resolvedOptions().timeZone;
			}
		})
		.catch((error) => {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		});
};
