import { AGENT_STATUS } from '../../constants/agent/agentStatus.constants';

export const getAgentMarker = (agent, onClick, iconsByTask, lan) => {
	let agentData;

	if (agent?.agentInfo) {
		agentData = {
			id: agent.agentInfo.idAgent,
			company: agent.agentInfo.companyName,
			idTask: agent.tasks ? agent.tasks[agent.tasks.length - 1] : null,
			agentAssign: true, // TODO: make sure it works
		};
	} else {
		agentData = {
			id: agent.id,
			company: agent.company,
		};
	}

	let geolocation = agent.agentInfo ? agent.agentInfo.geolocation : agent.geolocation;

	return {
		id: agentData.id,
		tasks: agent?.tasks,
		position: {
			lat: parseFloat(geolocation.latitude),
			lng: parseFloat(geolocation.longitud),
		},
		title: getAgentMarkerTitle(agent, lan),
		onClick: () => onClick(agentData),
		icon: agent?.agentInfo?.pinIcon || getAgentIcon(agent, iconsByTask, geolocation),
	};
};

const getAgentIcon = (agent, iconsByTask, geolocation) => {
	if (iconsByTask) {
		if (agent.pinTaskCount) {
			return agent.pinTaskCount;
		}

		if (agent.tasks.length <= 5) {
			return `agent${agent.tasks.length}Tasks`
		} else {
			return `agentMultipleTasks`
		}
	}
	else if (agent.status != null) {

		if (agent.pin) {
			return agent.pin;
		}

		switch (agent.status) {
			case AGENT_STATUS.online:
				return 'agentOnline';
			case AGENT_STATUS.away:
				return 'agentAway';
			case AGENT_STATUS.offline:
				return 'agentOffline';
			case AGENT_STATUS.inactive:
				return 'agentInactive';
			case AGENT_STATUS.noData:
				return 'agentNoData';
			default:
				return 'agentDefault';
		}
	} else {
		return geolocation.activeIcon ? 'agent' : 'inactiveAgent';
	}
};

const getAgentMarkerTitle = (agent, lan) => {
	let agentName;
	let agentTasks = '';
	let agentCompany = '';

	if (agent?.agentInfo) {
		agentName = `- ${lan.agent}: ${agent.agentInfo.name} ${agent.agentInfo.lastName}`;

		if (agent.agentInfo.companyName) {
			agentCompany = `\n- ${lan.company}: ${agent.agentInfo.companyName}`;
		}
	} else {
		agentName = `- ${lan.agent}: ${agent.name} ${agent.lastName}`;
		if (agent.companyName) {
			agentCompany = `\n- ${lan.company}: ${agent.company}`;
		}
	}

	if (agent.tasks && agent.tasks.length > 0) {
		agentTasks += `\n- ${lan.tasks}: (${agent.tasks.length} ${lan.tasks}) `;
		agentTasks += `${agent.tasks.join(', ')}`;
	}

	return `${agentName} ${agentCompany} ${agentTasks}`;
};
