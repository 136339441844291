import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateProfile } from '@core/api/profile/profile.service';

// Thunk for updating profile
export const updateProfileThunk = createAsyncThunk(
    'profile/update',
    async ({ body, onSuccess, onError, loadingDispatchConfig }, { rejectWithValue }) => {
        try {
            return await updateProfile(body, onSuccess, onError, loadingDispatchConfig);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

// Slice
export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        user: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateProfileThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfileThunk.fulfilled, (state, action) => {
                state.user = action.payload; 
                state.loading = false;
            })
            .addCase(updateProfileThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default profileSlice.reducer;
