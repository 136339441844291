import React from 'react';

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import Graph from './Graph';

class PartitionedBar extends Graph {
	constructor(data, props) {
		super(props);
		this.props = props;
		this.data = data;
	}

	createGraph(params) {
		const chart = am4core.create(params.id, am4charts.XYChart);
		const category = params.category;
		const valueX = params.valueX;
		const seriesParams = params.series;

		chart.data = this.data.data;

		// Create axes
		let yAAxis = super.setAxesPartitionedBar(chart, category);
		super.setPartitionedBarSeriesParams(chart, category, valueX, this.data.ranges);

		if (this.data.ranges) {
			for (let i = 0; i < this.data.ranges.length; i++) {
				super.addRange(
					this.data.ranges[i].region,
					this.data.ranges[i].startRange,
					this.data.ranges[i].endRange,
					chart.colors.getIndex(i),
					yAAxis
				);
			}
		}

		let {legend} = params;
		super.setChartProperties(chart, legend, true, false);

		this.chart = chart;
	}

	getGraph() {
		return this.chart;
	}
}

export default PartitionedBar;
