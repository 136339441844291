import React, { useState, useEffect } from 'react';
import { useLanguage } from 'Context/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { SuperAdmin } from 'FielderUtils/roles/roles';
import LANGUAGE from 'FielderUtils/languages/fielderLanguages';
import RegisterFooter from './RegisterFooter.js';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { useSelector, useDispatch } from 'react-redux';
import AlertIcon from '@assets/icons/danger-icon.svg';
import Message from '../ErrorMessage/ErrorMessage';
import FielderLogo from '@assets/icons/logo-icon.svg';
import RedAlertIcon from '@assets/icons/redAlert-icon.svg';
import SuccessIcon from '@assets/icons/success-alert-icon.svg';
import { registerRequest } from '@core/api/register/register.service';
import { emailSet } from '@core/constants/mail/mailList';
import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber  } from 'react-phone-number-input';
import {FielderPhoneField, FielderTextComponent , FielderSelectComponent} from '~/FielderElements/FielderElements.component';
import { FIELDER } from '@core/constants/base.constants';
import { FormControl } from '@mui/material';
import { getFoundOptionsForSelect, getIndustryOptionsForSelect, getJobOptionsForSelect } from './SelectOptionsForRegister.js';

import './RegisterComponentStyles.css';


const RegisterComponent = () => {
	
	const { txt: TXT_ID } = FIELDER.idElement;
	const dispatch = useDispatch();
	const lan = useLanguage();
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const [userName, setUserName] = useState(null);
	const [name, setName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [secondEmail, setSecondEmail] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [mobileNumber, setMobileNumber] = useState(null);
	const [language, setLanguage] = useState(LANGUAGE.en);
	const [contactName, setContactName] = useState(null);
	const [contactPhone, setContactPhone] = useState(null);
	const [contactEmail, setContactEmail] = useState(null);
	const [countryISOName, setCountryISOName] = useState(null);
	const [foundOption, setFoundOption] = useState('0');
	const [industryOption, setIndustryOption] = useState('0');
	const [jobDescriptionOption, setJobDescriptionOption] = useState('0');
	const [showOtherIndustry, setShowOtherIndustry] = useState(false);
	const [showOtherFound, setShowOtherFound] = useState(false);
	const [companyName, setCompanyName] = useState(null);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showErrorMessageText, setShowErrorMessageText] = useState('');
	const [showErrorMessageIcon, setShowErrorMessageIcon] = useState(null);
	const [emailValid, setEmailValid] = useState(false);
	const [sameEmailValid, setSameEmailValid] = useState(false);
	const [companyValid, setCompanyValid] = useState(false);
	const [userNameValid, setUserNameValid] = useState(false);
	const [firstNameValid, setFirstNameValid] = useState(false);
	const [lastNameValid, setLastNameValid] = useState(false);
	const [countryValid, setCountryValid] = useState(false);
	const [jobValid, setJobValid] = useState(false);
	const [industryValid, setIndustryValid] = useState(false);
	const [foundValid, setFoundValid] = useState(false);
	const [errorOfficePhone, setErrorOfficePhone] = useState('');
	const [errorMobilePhone, setErrorMobilePhone] = useState('');
	
	
	useEffect(() => { if (user) { navigate(ENDPOINTS.login); } }, []);
	const managePhoneNumber = (value) => {
		var nationalFormatPhone = formatPhoneNumber(value, 'National');
		setMobileNumber(nationalFormatPhone);
		setPhoneNumber(nationalFormatPhone)
		setContactPhone(nationalFormatPhone)
	};
	const manageCountry = (value) => {
		let language = LANGUAGE.en;

		if (value == 'MX' || value == 'CL' || value == 'CO' || value == 'VE') {
			language = LANGUAGE.mx;
		} else if (value == 'BR' || value == 'PT') {
			language = LANGUAGE.pt;
		}
		setLanguage(language);
		setCountryISOName(value);
	};
	const validateEmail = () => {
		if (email != null) {
			const domain = email.split('@')[1];
			if (
				/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
				!emailSet.has(domain)
			) {
				document.getElementById('email-alert-icon').style.display = 'none';
				setEmailValid(false)
				return true;
			}
		}
		document.getElementById('email-alert-icon').style.display = 'inline';
		setEmailValid(true)
		return false;
	};
	const validateSecondEmail = () => {
		if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(secondEmail) &&
			email === secondEmail
		) {
			document.getElementById('secondEmail-alert-icon').style.display = 'none';
			setSameEmailValid(false);
			return true;
		}

		document.getElementById('secondEmail-alert-icon').style.display = 'inline';
		setSameEmailValid(true);
		return false;
	};
	const changeUserName = (value) => {
		const re = /\s/g
		if (!re.test(value)) {
			setUserName(value);
		}
	}
	const changeFirstName = (value) => {
		setName(value)
		setContactName(value);
	}
	const changeLastName = (value) => {
		setLastName(value)
	}
	const chageEmail = (value) => {
		setContactEmail(value)
		setEmail(value)
	}
	const chageComfirmationEmail = (value) => {
		setSecondEmail(value)
	}
	const changeCompany = (value) => {
		setCompanyName(value)
	}
	const validateUserName = () => {
		let userName = document.getElementById('userName').value;
		let userNameRegex = new RegExp('([0-9a-zA-Z]+(-|_)*)*[0-9a-zA-Z]+');
		if (userName !== null && userNameRegex.test(userName) && userName.length >= 8) {
			document.getElementById('userName-alert-icon').style.display = 'none';
			setUserNameValid(false)
			return true;
		}

		document.getElementById('userName-alert-icon').style.display = 'inline';
		setUserNameValid(true)
		return false;
	};
	const validateFirstName = () => {
		let firstName = document.getElementById('firstName').value;
		let nameRegex =  /^(?:[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s?)+$/;

		if (firstName !== null && !nameRegex.test(firstName) && firstName.length >= 1) {
			document.getElementById('firstName-alert-icon').style.display = 'none';
			setFirstNameValid(false)
			return true;
		}

		document.getElementById('firstName-alert-icon').style.display = 'inline';
		setFirstNameValid(true)
		return false;
	};
	const validateLastName = () => {
		let lastName = document.getElementById('lastName').value;
		let lastNameRegex =  /^(?:[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s?)+$/;

		if (lastName !== null && !lastNameRegex.test(lastName) && lastName.length >= 1) {
			document.getElementById('lastName-alert-icon').style.display = 'none';
			setLastNameValid(false);
			return true;
		}

		document.getElementById('lastName-alert-icon').style.display = 'inline';
		setLastNameValid(true);
		return false;
	};
	const validateCountry = () => {
		let country = countryISOName;
		if (country !== null) {
			setCountryISOName(country);
			setCountryValid(false);
			return true;
		}

		setCountryValid(true);
		return false;
	};
	const validatePhone = () => {
		if(mobileNumber !== null && isValidPhoneNumber(mobileNumber)) {
			return true;
		} else {
			setErrorMobilePhone(lan.messageErrorPhone);
			return false; 
		}
	};
	const validateCompany = () => {
		let company = document.getElementById('company').value;
		if (company !== null && company != "" ) {
			document.getElementById('company-alert-icon').style.display = 'none';
			setCompanyValid(false)
			return true;
		}

		document.getElementById('company-alert-icon').style.display = 'inline';
		setCompanyValid(true)
		return false;
	};

	const validateSpace = (chain) => {
		return /^\s*$/.test(chain);
	}

	const validateIndustry = () => {
		if (industryOption == null || industryOption == '' || validateSpace(industryOption)) {
			document.getElementById('industry-alert-icon').style.display = 'none';
			return validateOtherIndustry(
				document.getElementById('otherIndustry').value
			);
		} else {
			if (industryOption != '0') {
				
				if (document.getElementById('otherIndustry-alert-icon')) { 
					document.getElementById('otherIndustry-alert-icon').style.display = 'none';
				}
				document.getElementById('industry-alert-icon').style.display = 'none';
				setIndustryValid(false);
				return true
			} else {
				document.getElementById('industry-alert-icon').style.display = 'inline';
				setIndustryValid(true);
				return false
			}
		}
	};

	const validateFound = () => {
		
		if (foundOption == null || foundOption == '' || validateSpace(foundOption)) {
			document.getElementById('found-alert-icon').style.display = 'none';
			return validateOtherFound(document.getElementById('otherFound').value);
		} else {

			if (foundOption != '0') {
				if (document.getElementById('otherFound-alert-icon')) {
					document.getElementById('otherFound-alert-icon').style.display = 'none';
				}
				document.getElementById('found-alert-icon').style.display = 'none';
				setFoundValid(false)
				return true;
			} else {
				document.getElementById('found-alert-icon').style.display = 'inline';
				setFoundValid(true)
				return false
			}
		}
	};
	const validateJob = () => {
	
		if (jobDescriptionOption != '0') {
			document.getElementById('job-alert-icon').style.display = 'none';
			setJobValid(false)
			return true;
		}
		document.getElementById('job-alert-icon').style.display = 'inline';
		setJobValid(true)
		return false;
	}

	const validateOtherIndustry = (industryName) => {
		const indRegex = /^(?:[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s?)+$/;
		if (industryName !== null && !indRegex.test(industryName) && industryName.length >= 1 && !validateSpace(industryOption)) {
			document.getElementById('otherIndustry-alert-icon').style.display = 'none';
			setIndustryOption(industryName)
			setIndustryValid(false);
			return true;
		}

		document.getElementById('otherIndustry-alert-icon').style.display = 'inline';
		return false;
	};
	const validateOtherFound = (other) => {

		const otherNameRegex = /^(?:[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s[\p{L}\p{Mn}\p{Pd}\'\x{2019}]+\s?)+$/;
		if (other !== null && !otherNameRegex.test(other) && other.length >= 1 && !validateSpace(foundOption)) {
			document.getElementById('otherFound-alert-icon').style.display = 'none';
			setFoundValid(false)
			setFoundOption(other)
			return true;
		}

		document.getElementById('otherFound-alert-icon').style.display = 'inline';
		setJobValid(true)
		return false;
	};
	const validateRegister = () => {
		let isValidEmail = validateEmail();
		let isValidSecondEmail = validateSecondEmail();
		var isValidCompany = validateCompany();
		let isValidUserName = validateUserName();
		let isValidFirstName = validateFirstName();
		let isValidLastName = validateLastName();
		let isValidCountry = validateCountry();
		let isValidPhone = validatePhone();
		let isValidJobDescription = validateJob();
		var isValidOtherIndustry = validateIndustry();
		let isValidOtherFound = validateFound();
		if (
			isValidEmail &&
			isValidSecondEmail &&
			isValidCompany &&
			isValidUserName &&
			isValidFirstName &&
			isValidLastName &&
			isValidCountry &&
			isValidPhone &&
			isValidJobDescription &&
			isValidOtherIndustry &&
			isValidOtherFound
		) {
			const requireBody = getBodyToSubmit();
			submitRegister(requireBody);
			document.getElementById('register-form-hint').style.display = 'none';
		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};
	const getBodyToSubmit = () => {
		return {
			user: {
				userName: userName,
				name: name,
				lastName: lastName,
				email: email,
				phoneNumber: phoneNumber,
				status: 0,
				mobileNumber: mobileNumber,
				role: SuperAdmin.name,
				language: language,
				isoName:countryISOName,
			},
			startFreeTrialDate: new Date().getTime(),
			freeTrial: false,
			contactName: contactName,
			contactPhone: contactPhone,
			contactEmail: contactEmail,
			countryISOName: countryISOName,
			foundOption: foundOption,
			industryOption: industryOption,
			jobDescriptionOption:jobDescriptionOption,
			logo: null,
			companyName: companyName,
		}

	}
	const submitRegister = (requireBody) => {
		document.getElementById('register-button-fielderButton').hidden = true;

		const onSuccess = (response) => {
			manageGetCreateAccount(response);
		}

		const onError = (error) => {
			manageGetCreateAccount(error);
		}

		const loadingConfig = { dispatch }
		registerRequest(requireBody, onSuccess, onError, loadingConfig)
	};
	const manageGetCreateAccount = (res) => {
		if (res.status == 0) {
			setShowErrorMessageText(lan.registerSuccessText);
			setShowErrorMessage(true);
			setShowErrorMessageIcon(SuccessIcon);
		} else if (res.status == 405) {
			setShowErrorMessageText(email + lan.registerFailedUserAlreadyExists);
			setShowErrorMessage(true);
			setShowErrorMessageIcon(AlertIcon);
		} else {
			setShowErrorMessageText(lan.registerFailedText);
			setShowErrorMessage(true);
			setShowErrorMessageIcon(AlertIcon);
		}
	};
	const showMessage = () => {
		setShowErrorMessage(!showErrorMessage );
		navigate("/");
	};
	const InputInfo = (props) => {
		return (
			<div className='Agent-input-help'>
				<p style={{ marginBottom: '1.5rem !important' }}>*{props.info}</p>
			</div>
		);
	};
	const onIndustryChange = (value) => {

		document.getElementById('industry-alert-icon').style.display = 'none';
		let showOtherIndustry = false
		let industryValue = value

		if (value == lan.industryQuestionOps[19]) {
			showOtherIndustry = true;
			industryValue = null
		}
		setIndustryOption(industryValue)
		setShowOtherIndustry(showOtherIndustry)
	}
	const onJobChange = (value) => {
		setJobDescriptionOption(value);
	}
	const onFoundChange = (value) => {
		document.getElementById('found-alert-icon').style.display = 'none';
		let showOtherFound = false;
		let foundValue = value;

		if (value == lan.findUsQuestionOps[5]) {
			showOtherFound = true;
			foundValue = null;
		}
		setFoundOption(foundValue);
		setShowOtherFound(showOtherFound)
	}
	const changeFound = (value) => {
		setFoundOption(value);
	}
	const changeIndustry = (value) => {
		setIndustryOption(value)
	}

	return (
		<>
			<Message
				showErrorMessage={showErrorMessage}
				message={showErrorMessageText}
				icon={showErrorMessageIcon}
				closeErrorMessage={showMessage}
				lan={lan}
			/>

			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-8 col-lg-6'>
						<div className='row'>
							<div className='col-12 text-center'>
								<img src={FielderLogo} alt='' />
							</div>
						</div>
						<form>
							<div>
								<div className='row'>
									<div className='col-12'>
										<div className='col-12 text-center Register-title'>
											<p>{lan.register}</p>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{emailValid && (
													<InputInfo info={lan.emailFieldHelp} />
												)}
											</div>
											
											<div className="row mb-3">
												<FielderTextComponent
													label={lan.emailCompany} 
													type="email"
													id={"email"} 
													setValue={(e) => chageEmail(e)}
													lan={lan}
													
												/>
												
												<img
													src={RedAlertIcon}
													alt=''
													id='email-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{sameEmailValid && (
													<InputInfo info={lan.validateSameEmail} />
												)}
											</div>
											<div className="row mb-3">
												<FielderTextComponent
													label={lan.emailCompanyConfirmation} 
													type="email"
													id={"secondEmail"} 
													setValue={(e) => chageComfirmationEmail(e)}
													lan={lan}
													
												/>
									
												<img
													src={RedAlertIcon}
													alt=''
													id='secondEmail-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{companyValid && (
													<InputInfo info={lan.validateCompany} />
												)}
											</div>
											<div className="row mb-3">

												<FielderTextComponent
													label={lan.company} 
													type="text"
													id={"company"} 
													setValue={(e) => changeCompany(e)}
													lan={lan}
													
												/>
										
												<img
													src={RedAlertIcon}
													alt=''
													id='company-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{userNameValid && (
													<InputInfo info={lan.createAccountUserNameFieldHelp} />
												)}
											</div>
											<div className="row mb-3">

												<FielderTextComponent
													label={lan.userName} 
													type="text"
													id={"userName"} 
													setValue={(e) => changeUserName(e)}
													lan={lan}
													
												/>
												
												<img
													src={RedAlertIcon}
													alt=''
													id='userName-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{firstNameValid && (
													<InputInfo info={lan.pleaseEnterName} />
												)}
											</div>
											<div className="row mb-3">

												<FielderTextComponent
													label={lan.firstName} 
													type="text"
													id={"firstName"} 
													setValue={(e) => changeFirstName(e)}
													lan={lan}
													
												/>

												
												<img
													src={RedAlertIcon}
													alt=''
													id='firstName-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{lastNameValid && (
													<InputInfo info={lan.pleaseEnterLastName} />
												)}
											</div>
											<div className="row mb-3">

												<FielderTextComponent
													label={lan.lastNameClient} 
													type="text"
													id={"lastName"} 
													setValue={(e) => changeLastName(e)}
													lan={lan}
													
												/>
												
												<img
													src={RedAlertIcon}
													alt=''
													id='lastName-alert-icon'
													className='Register-fieldContainer-icon'
												></img>
											</div>
										</div>
									</div>
									<div className='col-12 mb-3 pr-1'>
										<FielderPhoneField 
											id={TXT_ID.mobile}
											label={lan.mobilePhone}
											value={mobileNumber}
											onChange={value => {
												const valueNumber = value || '';
												const formattedPhone = formatPhoneNumberIntl(valueNumber);
												const parsedNumber = isValidPhoneNumber(valueNumber) ? 
													parsePhoneNumber(valueNumber) : null;
												const setAllPhoneNumbers = (phone) => {
													setMobileNumber(phone);
													setPhoneNumber(phone);
													setContactPhone(phone);
												}
												setAllPhoneNumbers(formattedPhone);
												const COUNTRY_TO_LANGUAGE = {
													'MX': LANGUAGE.mx,
													'CL': LANGUAGE.mx,
													'CO': LANGUAGE.mx,
													'VE': LANGUAGE.mx,
													'BR': LANGUAGE.pt,
													'PT': LANGUAGE.pt
												};
												const isoCountry = parsedNumber?.country;
												const language = COUNTRY_TO_LANGUAGE[isoCountry] || LANGUAGE.en;
												setErrorMobilePhone('');
												setLanguage(language);
												setCountryISOName(isoCountry);
											}}
											lan={lan}
											isRequired={true}
											externalError={errorMobilePhone}
										/>



									</div>
									<div className='col-12'>
										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{jobValid && (
													<InputInfo info={lan.pleaseSelectJobDescription} />
												)}
											</div>

											<div className="row mb-3">
												
												<FormControl fullWidth>
													
													<FielderSelectComponent
														id={'selectJobDescription'}
														label={lan.jobDescription}
														defaultValue={'0'}
														setValue={onJobChange}
														options={getJobOptionsForSelect(lan)}
													/>

													
												</FormControl>
											
												<img
													src={RedAlertIcon}
													alt=''
													id='job-alert-icon'
													className='Register-fieldContainer-icon'
												/>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{industryValid && (
													<InputInfo info={lan.pleaseSelectAnIndustry} />
												)}
											</div>

											<div className="row mb-3">

												<FormControl fullWidth>
													<FielderSelectComponent
														id={'selectIndustry'}
														label={lan.industryQuestion}
														defaultValue={'0'}
														setValue={onIndustryChange}
														options={getIndustryOptionsForSelect(lan)}
													/>

													{showOtherIndustry && (
														<>
															<div className='Register-fieldContainer mt-2'>
														
																<FielderTextComponent
																	label={lan.specify} 
																	type="text"
																	id={"otherIndustry"} 
																	setValue={(e) => changeIndustry(e)}
																	lan={lan}													
																/>
																<img
																	src={RedAlertIcon}
																	alt=''
																	id='otherIndustry-alert-icon'
																	className='Register-fieldContainer-specify-icon'
																></img>
															</div>
														</>
													)}
												</FormControl>

												<img
													src={RedAlertIcon}
													alt=''
													id='industry-alert-icon'
													className='Register-fieldContainer-icon'
												/>
											</div>
										</div>

										<div className='Register-fieldContainer'>
											<div className="row pl-2 ">
												{foundValid && (
													<InputInfo info={lan.pleaseSelectWhereYouFoundUs} />
												)}
											</div>

											<div className="row mb-3">
												<FormControl fullWidth>
													
													<FielderSelectComponent
														id={'selectFound'}
														label={lan.findUsQuestion}
														defaultValue={'0'}
														setValue={onFoundChange}
														options={getFoundOptionsForSelect(lan)}
													/>

												{showOtherFound && (
														<>
															<div className='Register-fieldContainer mt-2 '>
													
																<FielderTextComponent
																	label={lan.specify} 
																	type="text"
																	id={"otherFound"} 
																	setValue={(e) => changeFound(e)}
																	lan={lan}													
																/>
																<img
																	src={RedAlertIcon}
																	alt=''
																	id='otherFound-alert-icon'
																	className='Register-fieldContainer-specify-icon'
																></img>
															</div>
														</>
													)}
												</FormControl>

												<img
													src={RedAlertIcon}
													alt=''
													id='found-alert-icon'
													className='Register-fieldContainer-icon'
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id='register-form-hint' className='Register-form-hint'>
								<p>{lan.requiredFields}</p>
							</div>
						</form>

						<RegisterFooter
							validateRegister={validateRegister}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default RegisterComponent;
