import React from 'react';

import { SelectAvatar, SelectChip, ChipAnimationTransition, ChipAnimationVariants } from './SelectTag.styles';

const getAvatar = ({ label, value, image, icon }) => {
	if (typeof value !== 'object') {
		return null;
	}
	const hasImage = value.hasOwnProperty('image') || image;
	const splittedLabel = label.split(' ');
	let letters;

	if (splittedLabel.length > 1) {
		// Get the first letter of the first 2 words
		letters = splittedLabel[0][0] + splittedLabel[1][0];
	} else {
		[letters] = label;
	}

	if (hasImage) {
		return (
			<SelectAvatar alt={label} src={value.image || image}>
				{letters.toUpperCase()}
			</SelectAvatar>
		);
	}
	if (icon) {
		return <SelectAvatar>{icon}</SelectAvatar>;
	}
};

const SelectTag = ({ label, value, image, icon, size, ...tagProps }) => {
	const avatar = getAvatar({ label, value, image, icon });

	return (
		<SelectChip
			label={label}
			exit='exit'
			initial='initial'
			animate='animate'
			transition={ChipAnimationTransition}
			variants={ChipAnimationVariants}
			avatar={avatar}
			size={size}
			{...tagProps}
		/>
	);
};

export default SelectTag;
