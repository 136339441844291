import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';

const COMMENT_CANCEL_TASK_GROUP = 2;

class GroupTaskComments extends Component {
	render() {
		return (
			<React.Fragment>
				{this.props.comments.length > 0 && (
					<div className='col no-padding'>
						<Row className='my-2 ml-1'>
							<Col className={'td-label-input ' + this.props.lan.commentsAgent}>
								{this.props.lan.commentsAgent}
							</Col>
						</Row>

						<Scrollbars className='ml-3' style={{ height: '180px' }}>
							<div className='table-responsive-sm mr-4 pr-2'>
								<table className='table table-sm table-hover '>
									<thead>
										<tr>
											<th scope='col-auto'>{this.props.lan.cancelComment}</th>
											<th scope='col-auto'>{this.props.lan.agent}</th>
										</tr>
									</thead>
									<tbody>
										{this.props.comments.map((elem, index) => {
											var agentName = elem.agent
												? elem.agent.name + ' ' + elem.agent.lastName
												: this.props.lan.agent;

											return (
												<React.Fragment>
													{elem.type === COMMENT_CANCEL_TASK_GROUP && (
														<tr key={index}>
															<td className='text-left'>{elem.comment}</td>
															<td className='text-left'>{agentName}</td>
														</tr>
													)}
												</React.Fragment>
											);
										})}
									</tbody>
								</table>
							</div>
						</Scrollbars>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default GroupTaskComments;
