import { getExportAgentCount as exportAgentCount } from '@core/api/agent/agent';
import { getExportBranchCount as exportBranchCount } from '@core/api/branch/branch';
import { getExportCompanyCount as exportCompanyCount } from '@core/api/company/company.service';

import {
	getExportTaskCount as exportTaskCount,
	getExportTaskModulesCount as exportTaskModulesCount,
	getExportGroupTaslCount as exportGroupTaskCount,
	getExportTaskWithFormsCount as  exportTaskWithFormsCount
} from '@core/api/task/task';
import { getExportUserCount as exportUserCount } from '@core/api/user/user.service';

export const getExportTaskCount = async (paramsUrl) => {
	const onSuccess = (response) => response.totalTasks;
	const onError = () => 0;

	const totalTasks = await exportTaskCount(paramsUrl, onSuccess, onError);
	return totalTasks;
};

export const getExportGroupTaskCount = async (paramsUrl) => {
	const onSuccess = (response) => response.totalTasks;
	const onError = () => 0;

	const totalTasks = await exportGroupTaskCount(paramsUrl, onSuccess, onError);
	return totalTasks;
};

export const getExportTaskModulesCount = async (paramsUrl, moduleName) => {
	const onSuccess = (response) => response.totalTasks;
	const onError = () => 0;

	const totalTasks = await exportTaskModulesCount(paramsUrl, onSuccess, onError, moduleName);
	return totalTasks;
};

export const getExportBranchCount = async (paramsUrl) => {
	const onSuccess = (response) => response.countBranches;
	const onError = () => 0;

	const totalBranches = await exportBranchCount(paramsUrl, onSuccess, onError);
	return totalBranches;
};

export const getExportUserCount = async (paramsUrl) => {
	const onSuccess = (response) => response.countUsers;
	const onError = () => 0;

	const totalBranches = await exportUserCount(paramsUrl, onSuccess, onError);
	return totalBranches;
};

export const getExporAgentCount = async (paramsUrl) => {
	const onSuccess = (response) => response.countAgents;
	const onError = () => 0;

	const totalAgents = await exportAgentCount(paramsUrl, onSuccess, onError);
	return totalAgents;
};

export const getExporCompanyCount = async (paramsUrl) => {
	const onSuccess = (response) => response.countCompanies;
	const onError = () => 0;

	const totalCompanies = await exportCompanyCount(paramsUrl, onSuccess, onError);
	return totalCompanies;
};


export const getTasksWithForms = async (paramsUrl) => {
	const onSuccess = (response) => response.totalTasks;
	const onError = () => 0;

	const totalTasks = await exportTaskWithFormsCount(paramsUrl, onSuccess, onError);
	return totalTasks;
}