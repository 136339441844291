import React from 'react';
import CalendarIcon from '@assets/icons/calendar-icon.svg';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const MyOperationDatePicker = ({ from, to, handleFromDate, handleToDate, minTime,
	showDayPickerInput }) => {
	return (
		<>	
			<Col sm='auto'>
				<img
					src={CalendarIcon}
					className='icon my-2'
					alt=''
					onClick={() => showDayPickerInput('daypickerone')}
				/>
			</Col>
			<Col>
				<label>
					<DatePicker
						selected={from} //seleccionada fecha
						onChange={handleFromDate}
						minTime={minTime}
						id='input-date-from'
						showMonthDropdown
						showYearDropdow
						maxTime={new Date().setHours(23, 59)}
						dateFormat='dd/MM/yyyy' //Formato de fecha
						className='form-control mt-1 text-left'
						placeholderText='De'
						isClearable={true}
					/>
				</label>
			</Col>
			<Col sm='auto'>
				<img
					src={CalendarIcon}
					className='icon my-2 MyOperation-img-input Fielder-icon-button'
					onClick={() => showDayPickerInput('daypickertwo')}
					alt=''
				/>
			</Col>
			<Col>
				<label>
					<DatePicker
						selected={to} //seleccionada fecha
						onChange={handleToDate}
						minTime={minTime}
						showMonthDropdown
						showYearDropdow
						maxTime={new Date().setHours(23, 59)}
						dateFormat='dd/MM/yyyy' //Formato de fecha
						className='form-control mt-1 text-left'
						placeholderText='A'
						isClearable={true}
						shouldCloseOnSelect={false}
					/>
				</label>
			</Col>
			{from != null && to != null && (
				<Col sm='auto' className='mt-1'>
					<button
						key='btn-close'
						id='btn-close'
						className='close'
						aria-label='Close'
						onClick={() => resetDateValues()}
					>
						<span style={{ fontSize: '20px' }} aria-hidden='true'>
							&times;
						</span>
					</button>
				</Col>
			)}
		</>		
	);

}
export default React.memo(MyOperationDatePicker);