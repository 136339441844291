import React, { useRef } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useLanguage } from 'Context/LanguageContext';
import ViewMapIcon from '@assets/icons/view-map-icon.svg';
import ViewTableIcon from '@assets/icons/view-table-icon.svg';
import SelectedTableIcon from '@assets/icons/hide-map-icon.svg';
import DisabledMapIcon from '@assets/icons/non-selected-map-icon.svg';

const MyOperationHeaderTitle = ({ showMap, handleViewTable, handleViewIcon }) => {
    const lan = useLanguage();

    return (
        <>
            <Row className='d-flex mx-2'>
                <Col sm='auto' className='mr-auto'>
                    <h1 className='myoperation-title mt-1'>{lan.myoperationTitle}</h1>
                </Col>
                <Col sm='auto' className='my-2'>
                    <img
                        src={showMap ? ViewTableIcon : SelectedTableIcon}
                        className='icon Fielder-icon-button'
						alt='View Table'
                        onClick={handleViewTable}
                    />
                </Col>
                <Col sm='auto' className='my-2 mr-4'>
                    <img
                        src={showMap ? ViewMapIcon : DisabledMapIcon}
                        className='icon Fielder-icon-button myoperation-selected-icon '
						alt='View Map'
                        onClick={handleViewIcon}
                    />
                </Col>
            </Row>
        </>
    );
}

export default MyOperationHeaderTitle;