import React, { useState, useEffect } from 'react';

import { ENDPOINTS } from '@core/constants/pageEndpoints.constants';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import { setLogOutUser } from '@core/store/modules/session/sessionSlice';
import { Grid } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import { validSession } from 'FielderUtils/session/Session';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import ErrorMessage from '../../oldComponents/ErrorMessage/ErrorMessage';
import PrivatePolicy from '../../oldComponents/PrivatePolicy/PrivatePolicy';
import Login from './components/Login';
import { getLoginAccountRedirect } from './LoginPage.utils';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';

const LoginContainer = () => {
	const fielderMsg = useFielderSnackBar();
	const lan = useLanguage();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const [privacyPolicy, setPrivacyPolicy] = useState({
		acceptPrivacyPolicy: false,
		session: null,
	});
	const [errorMessage, setErrorMessage] = useState({
		showErrorMessage: true,
		message: null,
	});

	useEffect(() => {
		const isSessionValid = validSession();
		if (isSessionValid) {
			getLoginAccountRedirect(user, location);
		} else {
			dispatch(setLogOutUser());
			navigate(ENDPOINTS.login);
		}
	}, [navigate, location]);

	const setShowExternalMessage = (messageInfo) => {
		if (messageInfo.acceptPrivacyPolicy === true) {
			setPrivacyPolicy({
				show: true,
				session: messageInfo.session,
			});
		} else if (messageInfo.showErrorMessage === true) {
			fielderMsg({message: messageInfo.message, variant: 'error', closeButton: true, duration: 5000})
		}
	};

	const closePrivacyPolicy = () => {
		setPrivacyPolicy({
			show: false,
			session: null,
		});
	};

	const closeAcceptPolicy = () => {
		setErrorMessage({
			show: false,
			message: null,
		});
	};

	return (
		<>
			{privacyPolicy.show && (
				<PrivatePolicy
					closePrivatePolicy={closePrivacyPolicy}
					auxSession={privacyPolicy.session}
					lan={lan}
				/>
			)}

			{errorMessage.show && (
				<ErrorMessage
					message={errorMessage.message}
					closeErrorMessage={closeAcceptPolicy}
					showErrorMessage={errorMessage.show}
					lan={lan}
				/>
			)}

			<Grid container justifyContent='center' alignItems='center' className='my-4'>
				<Grid item lg={5} md={6} sm={8} className='py-3'>
					<Login setShowExternalMessage={setShowExternalMessage} />
				</Grid>
			</Grid>
		</>
	);
};

export default LoginContainer;
