import React from 'react';

import { Grid } from '@mui/material';
import { LinearProgressBar, PercentageTitle, FlexBox } from './ProgressBar.styles';

const ProgressBar = ({ value, includePercentage }) => {
	if (!includePercentage) {
		return <LinearProgressBar variant='determinate' color='primary' value={value} />;
	}

	return (
		<Grid container direction='row' justifyContent='center' alignItems='center' className='px-1' spacing={1}>
			<Grid item xs={8} md={10}>
				<LinearProgressBar variant='determinate' color='primary' value={value} />
			</Grid>
			<Grid item xs={4} md={2}>
				<PercentageTitle>{`${Math.round(value)}%`}</PercentageTitle>
			</Grid>
		</Grid>
	);
};

export default ProgressBar;
