import React, {Component} from 'react';

// Custom components
import Popup from '../../Popup';
import {
    Row,
} from 'react-bootstrap';


import AlertIcon from "@assets/icons/alert-icon.svg";

/**
 * Repeated Skill Popup
 */
class RepeatedSkillPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Popup
            show
            closeButton
            onClose={()=>this.props.hidePopup('showRepeatedPopup')}
            >
                <div>
                    <Row className='justify-content-center'>
                        <div className={'mx-auto'}>
                            <img src={AlertIcon} className='mx-auto' alt=''/>
                        </div>
                    </Row>
                    <Row className='justify-content-center mt-5'>
                        <div className={'mx-auto'}>
                            <span className='mx-auto'>{this.props.lan.addedRepeatedSkill}</span>
                        </div>
                    </Row>
                </div>
            </Popup>
        );

    }
}

export default RepeatedSkillPopup;