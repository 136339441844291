import React, { useState } from 'react';
import { MotionGrid } from '~/AutoAssignDetail/AutoAssignDetail.styles';
import { Grid } from '@mui/material';

import DatePickerComponent from '~/DatePicker/DatePicker.component';
import AsyncSelectCompanies from '~/AsyncSelectCompanies/AsyncSelectCompanies.component';
import AsyncSelectBranches from '~/AsyncSelectBranches/AsyncSelectBranches.component';
import { FilterTitle } from '../../TaskToCashTable/TaskToCashTable.styles';
import { useLanguage } from 'FielderUtils/Contexts/LanguageContext';

const FILTER_ANIMATION_VARIANTS = {
	exit: {
		height: 0,
		opacity: 0,
	},
	enter: {
		height: 'auto',
		opacity: 1,
	},
};

const FILTER_ANIMATION_TRANSITION = { duration: 0.1 };

const TaskToCashFilters = ({ filters, onChangeDates, onChangeCompanies, onChangeBranches }) => {
	const lan = useLanguage();
	const { startDate, endDate } = filters;

	const handleChangeCompanies = (newValues) => {
		//setCompanyIds(newValues);
		onChangeCompanies(newValues);
	};

	const handleChangeBranches = (newValues) => {
		//setBranchIds(newValues);
		onChangeBranches(newValues);
	};

	return (
		<MotionGrid
			key='filters'
			item
			xs={12}
			variants={FILTER_ANIMATION_VARIANTS}
			transition={FILTER_ANIMATION_TRANSITION}
			initial='exit'
			animate='enter'
			exit='exit'
		>
			<Grid container spacing={2} justifyContent='end' className='py-3'>
				<Grid item xs={12} sm md={3} className='py-1'>
					<FilterTitle className='mb-1'>{lan.date}</FilterTitle>
					<DatePickerComponent
						id='task-dates-filter-datepicker'
						size='large'
						value={{ startDate, endDate }}
						onChange={onChangeDates}
						range
					/>
				</Grid>
				<Grid item xs={12} sm md={4} className='py-1'>
					<FilterTitle className='mb-1'>{lan.company}</FilterTitle>
					<AsyncSelectCompanies id='companies-filter-select' size='small' onSelect={handleChangeCompanies} />
				</Grid>
				<Grid item xs={12} sm md={4} className='py-1'>
					<FilterTitle className='mb-1'>{lan.branch}</FilterTitle>
					<AsyncSelectBranches id='branches-filter-select' size='small' onSelect={handleChangeBranches} />
				</Grid>
			</Grid>
		</MotionGrid>
	);
};

export default TaskToCashFilters;
