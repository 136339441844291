import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SmallTitle = styled(Typography)(({ theme }) => ({
	fontFamily: 'Lato',
	fontSize: '18px',
	color: '#7D8EA2',
	[theme.breakpoints.down('md')]: {
		fontSize: '14px',
	},
}))
