import moment from 'moment';

export const taskForDowloadModulesFormater = (taskOrig) => {
	const task = taskOrig;
	if (task.user) {
		// Client found
		task.client = {
			clientType: 'client',
			idCustom: task.user.idUser,
			name: task.user.name,
			lasName: task.user.lastName,
		};
		task.company = task.user.company;
	} else if (task.branch) {
		task.client = {
			clientType: 'branch',
			name: task.branch.name,
		};

		if (task.branch.idCustom) {
			task.client.idCustom = task.branch.idCustom;
		}

		if (task.branch.company) {
			task.company = task.branch.company.name;
		}
	}
	var timeZone = task.startLocation ? task.startLocation.timeZone : 'x';

	task.number = task.idCustom;
	task.idFielder = task.idTask;
	task.address = task.startLocation.address;
	task.startDate = task.startAgent ? moment.tz(task.startAgent, timeZone).format('DD-MM-YYYY hh:mm a'):'';
	task.endDate = task.endAgent ? moment.tz(task.endAgent, timeZone).format('DD-MM-YYYY hh:mm a') : '';
	
	return task;
};
