import React from 'react';

import { ListItemIcon, MenuItem, Typography } from '@mui/material';

const ForwardMenuItem = React.forwardRef((props, ref) => <MenuItem {...props} ref={ref} />);

const SubMenuItem = ({ children, image, goToPage }) => {
	return (
		<ForwardMenuItem onClick={goToPage}>
			<ListItemIcon>
				<img src={image} className='icon' alt='' />
			</ListItemIcon>
			<Typography variant='inherit'>{children}</Typography>
		</ForwardMenuItem>
	);
};

export default SubMenuItem;
