import { styled } from '@mui/material/styles';
import { Drawer, AppBar, Toolbar, IconButton, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import { 
    FielderPink, AliceBlue,
    CottonCandyLight, FielderHard,
    White, DeepSpaceGreyDark } from '@core/theme/baseTheme';

export const TopMenuNotifyIconButtonStyled = styled(IconButton)({
    /* transition: 'transform 0.3s ease-in-out', */
    '&:hover': {
      /* backgroundColor: 'white', */
      padding: '0px',
      margin: '8px',
    },
});

export const NotifyDrawerStyled = styled(Drawer)({
    '& .MuiDrawer-paper': {
        width: '30%',
        maxWidth: '460px',
        '@media (min-width:900px)': {
            width: '40%',
        },
        '@media (min-width:1450px)': {
            width: '460px',
        },
    },
});

export const NotifyDrawerAppBarStyled = styled(AppBar)(({ theme }) => ({
    fontFamily: 'Lato, sans-serif !important',
    backgroundColor: theme.palette.background.paper,
    color: DeepSpaceGreyDark
}));

export const NotifyDrawerToolbarStyled = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
}));

export const NotifyDrawerIconButtonStyled = styled(IconButton)({
    color: DeepSpaceGreyDark
});

export const NotifyDrawerBoxStyled = styled(Box)({
    width: '100%',
    bgColor: 'background.paper',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',  // Hide scrollbar for Chrome, Safari and Opera
    },
    '-ms-overflow-style': 'none',  // Hide scrollbar for IE and Edge
    'scrollbar-width': 'none',  // Hide scrollbar for Firefox
});

export const NotifyDrawerBoxLoadingStyled = styled(Box)({
    justifyContent: 'center',
    alignItems: 'center',
});

export const NotifyContainerGridStyled = styled(Grid)({
    height: '90vh',
    boxShadow: '0 2px 28px 0 rgba(195, 193, 193, 0.5)',
    backgroundColor: White,
    position: 'relative',
    overflowY: 'auto',
});

export const NotifyContainerEmptyBoxStyled = styled(Grid)({
    fontFamily: 'Lato, sans-serif !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vw',
    width: '100%'
});

export const NotifyContainerTypoSubTitleStyled = styled(Grid)({
    padding: '10px',
    fontWeight: 'bold',
});

export const notifyItemStyled = makeStyles(() => {
    return {
        skeletonBox: {
            width: '100%',
            borderRadius: 8,
            alignItems: 'center',
            margin: '4px',
            padding: '4px',
            fontFamily: 'Lato, sans-serif !important',
            borderColor: CottonCandyLight,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
            '$settingIconsBox': {
                display: 'flex',
            },
        },
        skeletonAvatar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '30px !important'
        },
        skeletonTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        skeletonDescription: {
            marginTop: '10px',
        },
        skeletonFooter: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
        },
        rootBox: props => ({
            cursor: 'pointer',
            alignItems: 'center',
            margin: '7px',
            padding: '12px',
            fontFamily: 'Lato, sans-serif !important',
            transition: 'all 0.3s ease-in-out',
            borderBottom: '#ECEFEF 1px solid',
            borderRadius: props.read === 0 ? 9 : 0,
            backgroundColor: props.read === 0 ? AliceBlue : undefined,
            '&:hover': {
                borderRadius: 9,
                borderColor: CottonCandyLight,
                backgroundColor: CottonCandyLight,
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
            },
            '&:hover $settingIconsBox': {
                display: 'flex',
                '&:hover $settingIcons': {
                    backgroundColor: 'transparent',
                },
            },
        }),
        titleBox: {
            display: 'flex',
            justifyContent: 'space-between',
            color: DeepSpaceGreyDark,
        },
        titleFolio: {
            color: DeepSpaceGreyDark
        },
        titleId: {
            fontWeight: 'bold',
            color: DeepSpaceGreyDark
        },
        descriptionName: {
            color: FielderPink,
            fontWeight: 'bold'
    
        },
        descriptionTask: {
            color: DeepSpaceGreyDark,
            fontWeight: 'bold',
        },
        footerBox: {
            display: 'flex',
            alignItems: 'center',
            height: '52px',
        },
        settingIconsBox: {
            display: 'none',
            height: '100%',
        },
    };
});

export const StatusIconAvatar = styled(Avatar)(({ theme }) => ({
    border: `1px solid ${theme.palette.background.paper}`,
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    border: `0px solid ${FielderHard}`,
}));